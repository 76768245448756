"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Underline = void 0;
class Underline {
    constructor(uiConfig, isCloudTrinka, editor, editorId, editorOverlay, overlayCardComponent, editorOverlayElements, segment, sentence, alert) {
        this.underlines = [];
        this.listOfBounds = [];
        this.color = {
            borderColor: 'rgba(234, 21, 55, 1)',
            bgColor: 'rgba(234, 21, 55, 17%)',
            opacity: 100
        };
        this.isVisible = true;
        this.alertTypes = {
            1: 'grammar',
            2: 'spelling',
            3: 'advisor',
            4: 'enhancement',
            10: 'inclusiveLanguage'
        };
        this.uiConfig = uiConfig;
        this.isCloudTrinka = isCloudTrinka;
        this.editor = editor;
        this.editorId = editorId;
        this.editorOverlay = editorOverlay;
        this.overlayCardComponent = overlayCardComponent;
        this.editorOverlayElements = editorOverlayElements;
        this.segment = segment;
        this.sentence = sentence;
        this.alert = alert;
        if (!segment)
            return;
        this.listOfBounds = this.prepUnderlineBounds(segment, sentence, alert);
        this.listOfBounds.forEach((bounds) => {
            const newUnderline = this.createNewUnderline(alert, bounds);
            if (newUnderline instanceof Node) {
                this.underlines.push(newUnderline);
            }
        });
    }
    areRectsEqual(rect1, rect2) {
        return (rect1.bottom === rect2.bottom &&
            rect1.height === rect2.height &&
            rect1.left === rect2.left &&
            rect1.right === rect2.right &&
            rect1.top === rect2.top &&
            rect1.width === rect2.width &&
            rect1.x === rect2.x &&
            rect1.y === rect2.y);
    }
    updateUnderlinePosition() {
        const listOfBounds = this.prepUnderlineBounds(this.segment, this.sentence, this.alert);
        if (this.listOfBounds.length === listOfBounds.length && this.areRectsEqual(listOfBounds[0].bound, this.listOfBounds[0].bound))
            return;
        this.listOfBounds = listOfBounds;
        if (this.underlines.length !== this.listOfBounds.length) {
            if (this.underlines.length > this.listOfBounds.length) {
                while (this.underlines.length > this.listOfBounds.length) {
                    const underline = this.underlines.pop();
                    underline.remove();
                }
                this.underlines.forEach((underline, index) => {
                    if (this.listOfBounds[index]) {
                        this.updateUnderline(underline, this.listOfBounds[index]);
                    }
                });
            }
            else {
                let counter = 0;
                this.underlines.forEach((underline, index) => {
                    this.updateUnderline(underline, this.listOfBounds[index]);
                    counter++;
                });
                for (let index = counter; index < this.listOfBounds.length; index++) {
                    const newUnderline = this.createNewUnderline(this.alert, this.listOfBounds[index]);
                    if (newUnderline instanceof Node) {
                        this.editorOverlayElements.appendChild(newUnderline);
                        this.underlines.push(newUnderline);
                    }
                }
            }
        }
        else {
            this.underlines.forEach((underline, index) => {
                this.updateUnderline(underline, this.listOfBounds[index]);
            });
        }
    }
    setVisibility(state) {
        this.isVisible = state;
        this.underlines.forEach((underline) => {
            underline.style.display = `${this.isVisible ? 'block' : 'none'}`;
        });
    }
    selectUnderline() {
        this.underlines.forEach((underline) => {
            const underlineConfig = this.getUnderlineConfig(this.alert.suggestions[0].type);
            underline.style.backgroundColor = underlineConfig.bgColor;
            underline.style.opacity = `${underlineConfig.opacity / 100}`;
            underline.classList.add('selected');
        });
    }
    unselectUnderline() {
        this.underlines.forEach((underline) => {
            underline.style.backgroundColor = '';
            underline.style.opacity = `1`;
            underline.classList.remove('selected');
        });
    }
    remove() {
        while (this.underlines.length > 0) {
            let underline = this.underlines.shift();
            if (!underline)
                continue;
            underline.remove();
        }
    }
    prepUnderlineBounds(segment, sentence, alert) {
        let listOfBounds = [];
        const rangeOffset = sentence.begin;
        const selectedNodes = this.selectStartEndnodes(segment === null || segment === void 0 ? void 0 : segment.textNodes, rangeOffset, alert === null || alert === void 0 ? void 0 : alert.begin, alert === null || alert === void 0 ? void 0 : alert.end);
        const selectNodesBounds = this.generateUnderlineBounds(selectedNodes);
        if (selectNodesBounds.hasWordBreak) {
            listOfBounds = this.generateUnderlineBoundsForMultilineSpan(segment.textNodes, rangeOffset, alert);
        }
        else {
            listOfBounds.push({ bound: selectNodesBounds, selectedNodes: selectedNodes });
        }
        return listOfBounds;
    }
    updateUnderline(underline, bounds) {
        const containerBounds = this.editorOverlay.getBoundingClientRect();
        let newBounds = {
            width: bounds.bound.width,
            height: bounds.bound.height,
            top: bounds.bound.top - containerBounds.top + this.editorOverlay.scrollTop,
            left: bounds.bound.left - containerBounds.left + this.editorOverlay.scrollLeft
        };
        if (newBounds.top < 0 || newBounds.left < 0) {
            return;
        }
        Object.assign(underline.style, {
            'height': `${newBounds.height}px`,
            'width': `${newBounds.width}px`,
            'top': `${newBounds.top - 1}px`,
            'left': `${newBounds.left}px`,
            'display': `${this.isVisible ? 'block' : 'none'}`
        });
    }
    createNewUnderline(alert, bounds) {
        try {
            const underlineConfig = this.getUnderlineConfig(alert.suggestions[0].type);
            const containerBounds = this.editorOverlay.getBoundingClientRect();
            let underline = document.createElement('div');
            underline.id = alert.alertId;
            underline.classList.add('alert');
            let newBounds = {
                width: bounds.bound.width,
                height: bounds.bound.height,
                top: bounds.bound.top - containerBounds.top + this.editorOverlay.scrollTop,
                left: bounds.bound.left - containerBounds.left + this.editorOverlay.scrollLeft
            };
            Object.assign(underline.style, {
                'border-bottom': '2px solid',
                'border-bottom-color': `${underlineConfig.borderColor}`,
                'position': 'absolute',
                'pointer-events': 'all',
                'transition': 'height 0.3s ease, width 0.3s ease, top 0.3s ease, left 0.3s ease, border-bottom-color 0.3s ease',
                'cursor': 'pointer',
                'z-index': '99 !important',
                'height': `${newBounds.height}px`,
                'width': `${newBounds.width}px`,
                'top': `${newBounds.top - 1}px`,
                'left': `${newBounds.left}px`,
                'display': `${this.isVisible ? 'block' : 'none'}`
            });
            return underline;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }
    generateUnderlineBoundsForMultilineSpan(textBlocks, rangeOffset, alert) {
        let arrayOfword = Array.from(alert.text);
        let prevHeight = 0;
        let prevBounds;
        let prevNodes;
        let beginCount = 0;
        let arrayOfRange = [];
        let start = alert.begin;
        let updatedSelectedNodes;
        let arraylength = arrayOfword.length;
        for (let index = 0; index < arraylength; index++) {
            updatedSelectedNodes = this.selectStartEndnodes(textBlocks, rangeOffset, start, start + beginCount);
            let wb = this.generateUnderlineBounds(updatedSelectedNodes);
            if (prevHeight != 0 && wb.height > prevHeight) {
                arrayOfRange.push({ bound: prevBounds, selectedNodes: prevNodes });
                prevHeight = 0;
                start = start + beginCount;
                beginCount = 1;
                continue;
            }
            prevHeight = wb.height;
            prevBounds = wb;
            prevNodes = updatedSelectedNodes;
            beginCount++;
        }
        arrayOfRange.push({ bound: prevBounds, selectedNodes: prevNodes });
        return arrayOfRange;
    }
    acceptAlert(suggestionIndex = 0, setCursorPosition = true) {
        const rangeOffset = this.sentence.begin;
        const selectedNodes = this.selectStartEndnodes(this.segment.textNodes, rangeOffset, this.alert.begin, this.alert.end);
        const textToReplace = this.alert.suggestions[suggestionIndex].suggestion;
        this.replaceTextInEditor(selectedNodes, textToReplace, setCursorPosition);
        this.remove();
    }
    rejectAlert() {
        this.remove();
    }
    setCursorPosition($event, mode, suggestionIndex = 0) {
        try {
            switch (mode) {
                case 'at-start': {
                    const rangeOffset = this.sentence.begin;
                    const selectedNodes = this.selectStartEndnodes(this.segment.textNodes, rangeOffset, this.alert.begin, this.alert.end);
                    let range = document.createRange();
                    range.setStart(selectedNodes.startNode, selectedNodes.startOffset);
                    range.collapse(true);
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    break;
                }
                case 'at-end': {
                    const rangeOffset = this.sentence.begin;
                    const selectedNodes = this.selectStartEndnodes(this.segment.textNodes, rangeOffset, this.alert.begin, this.alert.end);
                    let range = document.createRange();
                    range.setStart(selectedNodes.endNode, selectedNodes.endOffset);
                    range.collapse(true);
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    break;
                }
                case 'at-end-after-replace': {
                    const textToReplace = this.alert.suggestions[suggestionIndex].suggestion;
                    const rangeOffset = this.sentence.begin;
                    const selectedNodes = this.selectStartEndnodes(this.segment.textNodes, rangeOffset, this.alert.begin, this.alert.end);
                    const alertLength = selectedNodes.endOffset - selectedNodes.startOffset;
                    const newOffset = selectedNodes.endOffset + (textToReplace.length - alertLength);
                    let range = document.createRange();
                    range.setStart(selectedNodes.endNode, newOffset);
                    range.collapse(true);
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    break;
                }
                case 'on-coordinates': {
                    const rangeOffset = this.sentence.begin;
                    const selectedNodes = this.selectStartEndnodes(this.segment.textNodes, rangeOffset, this.alert.begin, this.alert.end);
                    let range = document.createRange();
                    range.setStart(selectedNodes.startNode, selectedNodes.startOffset + Math.ceil($event.offsetX / 8));
                    range.collapse(true);
                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    break;
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    replaceTextInEditor(selectedNodes, textToReplace, setCursorPosition = true) {
        let ckeditorInstance = this.editor.ckeditorInstance;
        if (selectedNodes.startNode === selectedNodes.endNode) {
            if (!ckeditorInstance)
                selectedNodes.startNode.replaceData(selectedNodes.startOffset, selectedNodes.endOffset - selectedNodes.startOffset, textToReplace);
            else
                this.replaceTextInCKEditor(ckeditorInstance, textToReplace, selectedNodes.startIndexInSegment, selectedNodes.endIndexInSegment, selectedNodes.startNode, selectedNodes.endNode, selectedNodes.startOffset, setCursorPosition);
        }
        else {
            let filteredList = [];
            let filter = false;
            for (let i = 0; i < selectedNodes.textBlocks.length; i++) {
                let startN = selectedNodes.startNode;
                let endN = selectedNodes.endNode;
                if (startN === selectedNodes.textBlocks[i]) {
                    filter = true;
                }
                if (endN === selectedNodes.textBlocks[i]) {
                    filter = false;
                }
                if (filter && startN !== selectedNodes.textBlocks[i] && endN !== selectedNodes.textBlocks[i]) {
                    filteredList.push(selectedNodes.textBlocks[i]);
                }
            }
            if (!ckeditorInstance) {
                let nodesInBetween = selectedNodes.textBlocks.slice(selectedNodes.textBlocks.indexOf(selectedNodes.startNode), selectedNodes.textBlocks.indexOf(selectedNodes.endNode) + 1);
                const startNode = selectedNodes.startNode;
                startNode.replaceData(selectedNodes.startOffset, startNode.length - selectedNodes.startOffset, textToReplace);
                const endNode = selectedNodes.endNode;
                endNode.replaceData(0, selectedNodes.endNode.length, '');
                for (let i = 0; i < filteredList.length; i++) {
                    filteredList[i].replaceData(0, filteredList[i].length, '');
                }
            }
            else
                this.replaceTextInCKEditor(ckeditorInstance, textToReplace, selectedNodes.startIndexInSegment, selectedNodes.endIndexInSegment, selectedNodes.startNode, selectedNodes.endNode, selectedNodes.startOffset, setCursorPosition);
        }
    }
    getPopupPosition(eventBound) {
        return new Promise((resolve) => {
            var _a;
            let underlineBounds = this.underlines[0].getBoundingClientRect();
            let cardElement = (_a = this.overlayCardComponent.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('#tsdkCardContainer');
            if (!cardElement) {
                console.error("Card container element not found");
                resolve({ top: 0, left: 0 });
                return;
            }
            requestAnimationFrame(() => {
                let updatedBounds = cardElement.getBoundingClientRect();
                let top = 0;
                let left = 0;
                if ((window.innerHeight || document.documentElement.clientHeight) < (underlineBounds.top + updatedBounds.height)) {
                    top = (underlineBounds.top - updatedBounds.height) + 6;
                }
                else {
                    top = (eventBound.bottom - 5);
                }
                if ((underlineBounds.left + updatedBounds.width) > (document.documentElement.clientWidth || window.innerWidth)) {
                    left = (underlineBounds.left + underlineBounds.width - updatedBounds.width + 10);
                }
                else {
                    left = (underlineBounds.left - 10);
                }
                resolve({ top, left });
            });
        });
    }
    selectStartEndnodes(textBlocks, rangeOffset, beginIndex, endIndex) {
        if (!textBlocks || !textBlocks.length)
            return;
        const start = rangeOffset + beginIndex;
        const end = rangeOffset + endIndex;
        let pointer = 0;
        let startNode;
        let endNode;
        let startOffset = 0;
        let endOffset = 0;
        let response;
        startNode = textBlocks[0];
        endNode = textBlocks[textBlocks.length - 1];
        for (let i = 0; i < textBlocks.length; i++) {
            if (pointer <= start && start <= textBlocks[i].length + pointer - 1) {
                startNode = textBlocks[i];
                startOffset = start - pointer;
            }
            if (pointer <= end && end <= textBlocks[i].length + pointer - 1) {
                endNode = textBlocks[i];
                endOffset = end - pointer + 1;
            }
            pointer = pointer + textBlocks[i].length;
        }
        let startIndexInSegment = beginIndex + this.sentence.begin;
        let endIndexInSegment = endIndex + this.sentence.begin + 1;
        response = { startNode, endNode, startOffset, endOffset, textBlocks, startIndexInSegment, endIndexInSegment };
        return response;
    }
    generateUnderlineBounds(selectedNodes) {
        const range = document.createRange();
        try {
            range.setStart(selectedNodes.startNode, selectedNodes.startOffset);
            range.setEnd(selectedNodes.endNode, selectedNodes.endOffset);
        }
        catch (error) { }
        let newRange = range.getBoundingClientRect();
        const startRect = range.getBoundingClientRect();
        range.collapse(false);
        const endRect = range.getBoundingClientRect();
        newRange['hasWordBreak'] = !(startRect.height === endRect.height);
        return newRange;
    }
    getUnderlineConfig(alertType) {
        if (this.isCloudTrinka) {
            return this.color;
        }
        else {
            const type = this.alertTypes[alertType];
            return this.uiConfig.borderColorCoding && this.uiConfig.borderColorCoding[type] ? this.uiConfig.borderColorCoding[type] : this.color;
        }
    }
    replaceTextInCKEditor(editor, newText, startOffset, endOffset, startNode, endNode, actualStartOffset, setCursorPosition = true) {
        var _a, _b;
        const view = editor.editing.view;
        const model = editor.model;
        const domRoot = view.getDomRoot();
        const scrollPosition = getScrollPosition(editor);
        const startViewNode = checkNodeInViewHierarchy(startNode);
        let startTextNode = (_a = startViewNode === null || startViewNode === void 0 ? void 0 : startViewNode._children) === null || _a === void 0 ? void 0 : _a.filter((x) => { var _a, _b; return ((_a = x === null || x === void 0 ? void 0 : x.data) === null || _a === void 0 ? void 0 : _a.replace(/\u00A0/g, ' ')) === ((_b = startNode === null || startNode === void 0 ? void 0 : startNode.textContent) === null || _b === void 0 ? void 0 : _b.replace(/\u00A0/g, ' ')); })[0];
        const endViewNode = checkNodeInViewHierarchy(endNode);
        let endTextNode = (_b = endViewNode === null || endViewNode === void 0 ? void 0 : endViewNode._children) === null || _b === void 0 ? void 0 : _b.filter((x) => { var _a, _b; return ((_a = x === null || x === void 0 ? void 0 : x.data) === null || _a === void 0 ? void 0 : _a.replace(/\u00A0/g, ' ')) === ((_b = endNode === null || endNode === void 0 ? void 0 : endNode.textContent) === null || _b === void 0 ? void 0 : _b.replace(/\u00A0/g, ' ')); })[0];
        if (!startViewNode || !endViewNode) {
            console.log('DOM node is not part of the CKEditor view.');
            return;
        }
        const startViewPos = view.createPositionAt(startTextNode, startOffset);
        const endViewPos = view.createPositionAt(endTextNode, endOffset);
        const viewRange = view.createRange(startViewPos, endViewPos);
        const modelRange = editor.editing.mapper.toModelRange(viewRange);
        let newPosition;
        model.change(writer => {
            replaceTextInNode(writer, modelRange.start.parent, startOffset, endOffset, newText);
            newPosition = findDynamicPositionAcrossChildren(writer, modelRange.start.parent, startOffset, newText.length);
        });
        if (setCursorPosition) {
            setTimeout(() => {
                domRoot.scrollTop = scrollPosition;
                view.scrollToTheSelection();
                model.change(writer1 => {
                    writer1.setSelection(newPosition);
                    editor.editing.view.focus();
                    setScrollPosition(editor, scrollPosition);
                });
            }, 20);
        }
        function getScrollPosition(editor) {
            const editableElement = editor.editing.view.getDomRoot();
            return {
                top: editableElement.scrollTop,
                left: editableElement.scrollLeft
            };
        }
        function setScrollPosition(editor, position) {
            const editableElement = editor.editing.view.getDomRoot();
            editableElement.scrollTop = position.top;
            editableElement.scrollLeft = position.left;
        }
        function checkNodeInViewHierarchy(node) {
            const view = editor.editing.view;
            let viewNode = view.domConverter.mapDomToView(node);
            if (viewNode) {
                return viewNode;
            }
            let ancestor = node.parentNode;
            while (ancestor) {
                viewNode = view.domConverter.mapDomToView(ancestor);
                if (viewNode) {
                    return viewNode;
                }
                ancestor = ancestor.parentNode;
            }
            return null;
        }
        function getNodeText(node) {
            let textContent = '';
            for (const child of node.getChildren()) {
                if (child.is('text')) {
                    textContent += child.data;
                }
                else if (child.is('element')) {
                    textContent += getNodeText(child);
                }
            }
            return textContent;
        }
        function replaceTextInNode(writer, node, startOffset, endOffset, newText) {
            let offsetCounter = 0;
            let remainingText = newText;
            const toInsert = [];
            for (const child of Array.from(node.getChildren())) {
                if (child.is('text')) {
                    const textLength = child.data.length;
                    if (child.data.trim().length > 0) {
                        if (offsetCounter + textLength > startOffset && offsetCounter < endOffset) {
                            const textStart = Math.max(startOffset - offsetCounter, 0);
                            const textEnd = Math.min(endOffset - offsetCounter, textLength);
                            const beforeText = child.data.slice(0, textStart);
                            const afterText = child.data.slice(textEnd);
                            const replaceLength = Math.max(textEnd - textStart, remainingText.length);
                            const replaceText = remainingText.slice(0, replaceLength);
                            remainingText = remainingText.slice(replaceLength);
                            if (beforeText) {
                                toInsert.push({ text: beforeText, attributes: child.getAttributes() });
                            }
                            toInsert.push({ text: replaceText, attributes: child.getAttributes() });
                            if (afterText) {
                                toInsert.push({ text: afterText, attributes: child.getAttributes() });
                            }
                            writer.remove(child);
                        }
                        else {
                            toInsert.push({ text: child.data, attributes: child.getAttributes() });
                            writer.remove(child);
                        }
                    }
                    offsetCounter += textLength;
                }
                else if (child.is('element') && child.name !== "htmlCustomElement") {
                    if (child.name !== "softBreak")
                        replaceTextInNode(writer, child, startOffset - offsetCounter, endOffset - offsetCounter, remainingText);
                    else
                        writer.remove(child);
                    offsetCounter += child.name !== "softBreak" && child.name !== "imageInline" && child.name !== "htmlInput" ? child === null || child === void 0 ? void 0 : child.getChildCount() : 0;
                }
            }
            for (const { text, attributes } of toInsert) {
                writer.insertText(text, attributes, node, 'end');
            }
        }
        function findDynamicPositionAcrossChildren(writer, parent, startOffset, newTextLength) {
            let currentOffset = 0;
            let newPosition = null;
            for (const child of parent.getChildren()) {
                const childMaxOffset = child.is('text') ? child.data.length : child.maxOffset;
                if (currentOffset + childMaxOffset > startOffset) {
                    if (child.is('text')) {
                        const localOffset = actualStartOffset + currentOffset + newTextLength;
                        newPosition = writer.createPositionAt(child.parent, localOffset);
                    }
                    else {
                        const localOffset = startOffset - currentOffset;
                        if (localOffset <= 0) {
                            newPosition = writer.createPositionBefore(child);
                        }
                        else if (localOffset >= childMaxOffset) {
                            newPosition = writer.createPositionAfter(child);
                        }
                        else {
                            if (child.childCount) {
                                newPosition = writer.createPositionAt(child, 0);
                            }
                        }
                    }
                    break;
                }
                currentOffset += childMaxOffset;
            }
            return newPosition;
        }
    }
}
exports.Underline = Underline;
