"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paraphraser = void 0;
const paraphraser_ui_1 = require("./paraphraser-ui");
const WebSocketClient_1 = require("../connection/WebSocketClient");
const paraphraser_right_panel_1 = require("../components/paraphraser/paraphraser-right-panel");
const utils_1 = require("../helpers/utils");
const caching_1 = require("../caching/caching");
const constants_1 = require("../helpers/constants");
class Paraphraser {
    constructor(paraphraserModuleConfig, uiConfig, selector, cloudConfig = undefined) {
        this.name = `paraphraser`;
        this.currSelectionTimeout = null;
        this.paraphraserSocketClient = null;
        this.wsuniqueID = '';
        this.paraphraserWSSResponse = null;
        this.waitForParaphraserWssResponse = false;
        this.boundHandleHighlightEvent = ($event) => this.handleHighlightEvent($event);
        this.boundCallbackForTooltipClick = ($event) => this.callbackForTooltipClick($event);
        this.boundClearParaphraserSentences = ($event) => this.clearParaphraserSentences($event);
        this.boundReplaceSelectedText = ($event) => this.replaceSelectedText($event);
        this.boundCallParaphraserFeedbackAPI = ($event) => this.callParaphraserFeedbackAPI($event);
        this.boundchangeParaphraserTemperature = ($event) => this.changeParaphraserTemperature($event);
        this.boundScrollEvent = ($event) => this.handleScrollEvent($event);
        this.boundSelChangeEvent = ($event) => this.handleSelChangeEvent($event);
        this.boundHandleClickEvent = ($event) => this.handleClickEvent($event);
        this.cloudConfig = undefined;
        this.isPasteEventStarted = false;
        this.paraphraserModuleConfig = paraphraserModuleConfig;
        this.cloudConfig = cloudConfig;
        this.uiConfig = uiConfig;
        this.selector = selector;
        this.paraphraserUI = new paraphraser_ui_1.ParaphraserUI(this.paraphraserModuleConfig, this.uiConfig);
        this.currentFocusedEditable = null;
        this.arrayOfHiglightedSentences = [];
        this.paraphraseRightPanel = null;
        this.currentSelectedNodesArr = [];
        this.cachingService = new caching_1.CachingService();
        this.initialize();
    }
    initialize() {
        const editableElements = document.querySelectorAll(this.selector);
        this.socketClient = new WebSocketClient_1.WebSocketClient(this.paraphraserModuleConfig.url);
        this.paraphraserUI.initialize(editableElements);
        this.registerAllEvents();
        this.registerFocusListenersForAllEditors();
        this.paraphraseRightPanel = new paraphraser_right_panel_1.ParaphraserRightPanel();
        this.paraphraseRightPanel.isCloudEditor = this.paraphraserModuleConfig.isCloudEditor;
        this.paraphraseRightPanel.langCode = this.paraphraserModuleConfig.langCode;
        document.body.appendChild(this.paraphraseRightPanel);
        this.paraphraserUI.paraphraseToolTipDiv = null;
        this.initialAPICallParaphraser();
        setTimeout(() => {
            var _a, _b, _c;
            let ckeditorInstance = ((_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.ckeditorInstance) ? (_b = this.currentFocusedEditable) === null || _b === void 0 ? void 0 : _b.ckeditorInstance : document.querySelector('.ck-editor__main div.ck-editor__editable[contenteditable="true"]') ? (_c = document.querySelector('.ck-editor__main div.ck-editor__editable[contenteditable="true"]')) === null || _c === void 0 ? void 0 : _c.ckeditorInstance : null;
            if (ckeditorInstance) {
                ckeditorInstance === null || ckeditorInstance === void 0 ? void 0 : ckeditorInstance.model.document.on('change:data', (e, batch) => {
                    if (this.isPasteEventStarted)
                        this.isPasteEventStarted = false;
                    else
                        this.handleCKEditorInputWithoutDebounce(ckeditorInstance, batch.isTyping || batch.isUndo);
                });
                ckeditorInstance.editing.view.document.on('clipboardInput', (evt, data) => {
                    this.isPasteEventStarted = true;
                    setTimeout(() => {
                        (0, utils_1.addEventToTracingLog)('paste');
                    }, 200);
                });
            }
        }, 500);
    }
    registerFocusListenersForAllEditors() {
        for (let len = 0; len < this.paraphraserUI.listOfEditors.length; len++) {
            if (this.paraphraserUI.listOfEditors[len] === document.activeElement)
                this.currentFocusedEditable = this.paraphraserUI.listOfEditors[len];
            this.paraphraserUI.listOfEditors[len].addEventListener('focus', () => {
                var _a;
                this.currentFocusedEditable = this.paraphraserUI.listOfEditors[len];
                (_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
            });
            this.paraphraserUI.listOfEditors[len].addEventListener('blur', () => {
                var _a;
                (_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
            });
        }
    }
    registerAllEvents() {
        document.addEventListener('mouseup', this.boundHandleHighlightEvent);
        document.addEventListener('paraphrase-tooltip-clicked', this.boundCallbackForTooltipClick);
        document.addEventListener('hide-paraphrase-tooltip', this.boundClearParaphraserSentences);
        document.addEventListener('replace-text', this.boundReplaceSelectedText);
        document.addEventListener('paraphrase-feedback', this.boundCallParaphraserFeedbackAPI);
        document.addEventListener('paraphrase-temperature-change', this.boundchangeParaphraserTemperature);
        document.addEventListener('scroll', this.boundScrollEvent, true);
        document.addEventListener("selectionchange", this.boundSelChangeEvent);
        document.addEventListener("click", this.boundHandleClickEvent);
    }
    removeAllEvents() {
        document.removeEventListener('mouseup', this.boundHandleHighlightEvent);
        document.removeEventListener('paraphrase-tooltip-clicked', this.boundCallbackForTooltipClick);
        document.removeEventListener('hide-paraphrase-tooltip', this.boundClearParaphraserSentences);
        document.removeEventListener('replace-text', this.boundReplaceSelectedText);
        document.removeEventListener('paraphrase-feedback', this.boundCallParaphraserFeedbackAPI);
        document.removeEventListener('paraphrase-temperature-change', this.boundchangeParaphraserTemperature);
        document.removeEventListener('scroll', this.boundScrollEvent, true);
        document.removeEventListener("selectionchange", this.boundSelChangeEvent);
        document.removeEventListener("click", this.boundHandleClickEvent);
    }
    handleHighlightEvent(event) {
        var _a;
        if (this.isParentCurrentFocusedEditable(event.target)) {
            this.selectionObj = window.getSelection();
            let selectedRange = (_a = this.selectionObj) === null || _a === void 0 ? void 0 : _a.getRangeAt(0);
            let rect = selectedRange.getBoundingClientRect();
            this.paraphraserUI.hideParaPhraserTooltip();
            if (this.selectionObj.toString() &&
                this.selectionObj.toString().trim().length > 0) {
                this.paraphraserUI.showParaPhraserTooltip(this.currentFocusedEditable);
            }
        }
    }
    callbackForTooltipClick(event) {
        var _a;
        let paraIndices = this.getChildIndices();
        (0, utils_1.triggerAmplitudeEvent)('ED_paraphrase_process', {
            'from': 'Right Panel',
            'degree_of_change_setting': this.paraphraseRightPanel.currentParaphraserTemperature,
            'language': (_a = this.paraphraserModuleConfig) === null || _a === void 0 ? void 0 : _a.langCode
        });
        if (paraIndices && paraIndices.length > 0) {
            this.paraphraseRightPanel.open();
            this.paraphraseRightPanel.outputSentenceArr = [];
            this.paraphraseRightPanel.showLoader = true;
            this.paraphraseRightPanel.showErrorTab = false;
            this.paraphraseRightPanel.showWarningtab = false;
            this.startParaphrase(paraIndices);
        }
        else {
            this.paraphraseRightPanel.showWarningtab = true;
        }
    }
    getChildIndices() {
        let selection = window.getSelection();
        let that = this;
        function getParentOfChildNode(currentNode) {
            var _a, _b;
            if (((currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'P' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'DIV' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === '#text' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'PRE' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'SPAN' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'H1' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'H2' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'H3' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'H4' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'H5' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'H6' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'LI' ||
                (currentNode === null || currentNode === void 0 ? void 0 : currentNode.nodeName) === 'TABLE') && ((currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode) === that.currentFocusedEditable ||
                ((_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode) === null || _a === void 0 ? void 0 : _a.nodeName) === 'OL' ||
                ((_b = currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode) === null || _b === void 0 ? void 0 : _b.nodeName) === 'UL')) {
                return currentNode;
            }
            return getParentOfChildNode(currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode);
        }
        function getIndicesForMultipleNodesSelection(range) {
            let startNode = getParentOfChildNode(range.startContainer);
            let endNode = getParentOfChildNode(range.endContainer);
            if ((endNode === null || endNode === void 0 ? void 0 : endNode.nodeName) === 'OL' ||
                (endNode === null || endNode === void 0 ? void 0 : endNode.nodeName) === 'UL' ||
                (endNode === null || endNode === void 0 ? void 0 : endNode.nodeName) === 'TABLE' ||
                (startNode === null || startNode === void 0 ? void 0 : startNode.nodeName) === 'OL' ||
                (startNode === null || startNode === void 0 ? void 0 : startNode.nodeName) === 'UL' ||
                (startNode === null || startNode === void 0 ? void 0 : startNode.nodeName) === 'TABLE')
                return false;
            let startIndex, endIndex;
            let childNodes = that.currentFocusedEditable.childNodes;
            for (let i = 0; i < childNodes.length; i++) {
                if (that.isDescendant(startNode, childNodes[i])) {
                    startIndex = i;
                    break;
                }
            }
            for (let j = 0; j < childNodes.length; j++) {
                if (that.isDescendant(endNode, childNodes[j])) {
                    endIndex = j;
                    break;
                }
            }
            let indices = [];
            if (startIndex <= endIndex) {
                for (let k = startIndex; k <= endIndex; k++) {
                    indices.push(k);
                }
            }
            else {
                for (let l = endIndex; l <= startIndex; l++) {
                    indices.push(l);
                }
            }
            return indices;
        }
        if (selection.rangeCount > 0) {
            let range = selection.getRangeAt(0);
            if (range.startContainer.parentNode !== range.endContainer.parentNode) {
                return getIndicesForMultipleNodesSelection(range);
            }
            else if (range.startContainer !== range.endContainer && range.startContainer.parentNode === range.endContainer.parentNode) {
                return getIndicesForMultipleNodesSelection(range);
            }
            let selectedNode = range.commonAncestorContainer;
            while (selectedNode &&
                (selectedNode.parentNode !== this.currentFocusedEditable
                    && selectedNode.parentNode.nodeName !== 'OL'
                    && selectedNode.parentNode.nodeName !== 'UL')) {
                selectedNode = selectedNode.parentNode;
            }
            if (selectedNode &&
                (selectedNode.nodeName === 'P' ||
                    selectedNode.nodeName === 'DIV' ||
                    selectedNode.nodeName === '#text' ||
                    selectedNode.nodeName === 'PRE' || selectedNode.nodeName === 'SPAN' ||
                    selectedNode.nodeName === 'H1' || selectedNode.nodeName === 'H2' ||
                    selectedNode.nodeName === 'H3' || selectedNode.nodeName === 'H4' ||
                    selectedNode.nodeName === 'H5' || selectedNode.nodeName === 'H6' || selectedNode.nodeName === 'LI')) {
                let childNodes = this.currentFocusedEditable.childNodes;
                for (let m = 0; m < childNodes.length; m++) {
                    if (this.isDescendant(selectedNode, childNodes[m])) {
                        return [m];
                    }
                }
            }
            else if ((selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.nodeName) === 'OL' || (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.nodeName) === 'UL' || (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.nodeName) === 'TABLE') {
                return false;
            }
        }
        return [];
    }
    isParentCurrentFocusedEditable(ele) {
        if (!ele)
            return false;
        else if (ele === this.currentFocusedEditable) {
            return true;
        }
        return this.isParentCurrentFocusedEditable(ele === null || ele === void 0 ? void 0 : ele.parentElement);
    }
    startParaphrase(indicesArr) {
        if (indicesArr) {
            this.scanRecordsToParaphrase(indicesArr);
        }
    }
    async scanRecordsToParaphrase(indecesOfChildNode) {
        var _a, _b, _c, _d, _e, _f;
        try {
            let allChildNodes = this.currentFocusedEditable.childNodes;
            let selectedChildNodesArr = [];
            allChildNodes.forEach((x, index) => {
                var _a, _b;
                if (x.nodeName === 'DIV' && x.getAttribute('data-placeholder') === 'Enter some text here') {
                    for (let i = 0; i < x.childNodes.length; i++) {
                        if ((_b = (_a = this.selectionObj) === null || _a === void 0 ? void 0 : _a.getRangeAt(0)) === null || _b === void 0 ? void 0 : _b.intersectsNode(x.childNodes[i])) {
                            if (x.childNodes[i].nodeName === 'OL' || x.childNodes[i].nodeName === 'UL') {
                                let count = 0;
                                let indexOfSelectedListItem = -1;
                                x.childNodes[i].childNodes.forEach((child, index) => {
                                    var _a, _b;
                                    if ((_b = (_a = this.selectionObj) === null || _a === void 0 ? void 0 : _a.getRangeAt(0)) === null || _b === void 0 ? void 0 : _b.intersectsNode(child)) {
                                        if (indexOfSelectedListItem === -1)
                                            indexOfSelectedListItem = index;
                                        selectedChildNodesArr.push(child);
                                        count++;
                                    }
                                });
                            }
                            else if (x.childNodes[i].nodeName === 'TABLE') {
                                this.paraphraseRightPanel.showWarningtab = true;
                                this.paraphraseRightPanel.showLoader = false;
                                return;
                            }
                            else
                                selectedChildNodesArr.push(x.childNodes[i]);
                        }
                    }
                }
                else {
                    if (x.nodeName !== '#text' && !!(x === null || x === void 0 ? void 0 : x.closest('table'))) {
                        this.paraphraseRightPanel.showWarningtab = true;
                        this.paraphraseRightPanel.showLoader = false;
                        return;
                    }
                    if ((((x === null || x === void 0 ? void 0 : x.nodeName) === 'DIV' && (x === null || x === void 0 ? void 0 : x.childNodes.length) > 1) || (x === null || x === void 0 ? void 0 : x.nodeName) === 'OL' || (x === null || x === void 0 ? void 0 : x.nodeName) === 'UL') && indecesOfChildNode.includes(index)) {
                        for (let i = 0; i < x.childNodes.length; i++) {
                            let sel = window.getSelection();
                            if (sel.getRangeAt(0).intersectsNode(x.childNodes[i]))
                                selectedChildNodesArr.push(x.childNodes[i]);
                        }
                    }
                    else if (indecesOfChildNode.includes(index) && x.textContent.length > 0)
                        selectedChildNodesArr.push(x);
                }
            });
            for (let j = 0; j < selectedChildNodesArr.length; j++) {
                if (selectedChildNodesArr[j].nodeName === 'DIV') {
                    let nodesArr = this.findNodesWithMultipleChildrenAtLevel(selectedChildNodesArr[j]);
                    if (((_a = nodesArr[0]) === null || _a === void 0 ? void 0 : _a.level) > 1) {
                        selectedChildNodesArr.splice(j, 1);
                        for (let i = 0; i < ((_b = nodesArr[0]) === null || _b === void 0 ? void 0 : _b.node.childNodes.length); i++) {
                            if ((_d = (_c = this.selectionObj) === null || _c === void 0 ? void 0 : _c.getRangeAt(0)) === null || _d === void 0 ? void 0 : _d.intersectsNode((_e = nodesArr[0]) === null || _e === void 0 ? void 0 : _e.node.childNodes[i]))
                                selectedChildNodesArr.push((_f = nodesArr[0]) === null || _f === void 0 ? void 0 : _f.node.childNodes[i]);
                        }
                    }
                }
            }
            for (let i = 0; i < selectedChildNodesArr.length; i++) {
                if (selectedChildNodesArr[i].textContent.trim().length > 0) {
                    const searchKey = `${selectedChildNodesArr[i].textContent}#${this.paraphraserModuleConfig.langCode}`;
                    let data = await this.cachingService.split(searchKey);
                    if (!data) {
                        data = await this.socketClient.split({
                            request_id: (0, utils_1.generateRandomId)(10),
                            text: selectedChildNodesArr[i].textContent,
                            type: 'split'
                        });
                        this.cachingService.setSplitResponse(searchKey, data);
                    }
                    this.splitResponseForParaphrase(data, selectedChildNodesArr[i], selectedChildNodesArr.length === 1);
                }
                if (i === selectedChildNodesArr.length - 1) {
                    if (this.arrayOfHiglightedSentences.length > 0) {
                        this.createHighlightSentences(this.arrayOfHiglightedSentences[0].paraNode, this.arrayOfHiglightedSentences[this.arrayOfHiglightedSentences.length - 1].paraNode, this.arrayOfHiglightedSentences[0].begin, this.arrayOfHiglightedSentences[this.arrayOfHiglightedSentences.length - 1].end, selectedChildNodesArr);
                        this.callParaphraserAPI(this.arrayOfHiglightedSentences);
                    }
                    else {
                        this.showErrorTabOnRightPanel();
                    }
                }
            }
        }
        catch (e) {
            this.showErrorTabOnRightPanel();
            console.log('error from scan records: ', e);
        }
    }
    splitResponseForParaphrase(response, currentPara, isSinglePara = false) {
        var _a;
        try {
            let sentenceArr = this.breakSelectedTextToSentences(currentPara);
            let splitSentencesArr = response.response;
            for (let index = 0; index < splitSentencesArr.length; index++) {
                let foundAt = sentenceArr.findIndex(sentenceObj => {
                    return this.normalizeString(splitSentencesArr[index].sentence).includes(this.normalizeString(sentenceObj.sentence)) || this.normalizeString(sentenceObj.sentence).includes(this.normalizeString(splitSentencesArr[index].sentence));
                });
                if (foundAt > -1) {
                    let actualSelectionStart = this.isDescendant(this.selectionObj.anchorNode, currentPara) ? this.getVisibleTextOffset(currentPara, this.selectionObj.anchorNode, this.selectionObj.anchorOffset) : 0;
                    let actualSelectionEnd = this.getVisibleTextOffset(currentPara, this.selectionObj.focusNode, this.selectionObj.focusOffset);
                    if (actualSelectionEnd < actualSelectionStart) {
                        let tempVariable = actualSelectionEnd;
                        actualSelectionEnd = actualSelectionStart;
                        actualSelectionStart = tempVariable;
                    }
                    const isWithinSentence = foundAt > -1 && isSinglePara && !((splitSentencesArr[index].begin < actualSelectionStart && splitSentencesArr[index].end < actualSelectionStart) ||
                        splitSentencesArr[index].begin > actualSelectionEnd);
                    if ((foundAt > -1 && !isSinglePara) ||
                        (isSinglePara && isWithinSentence && foundAt > -1)) {
                        let modeMapping = {
                            'Standard': 'standard',
                            'Formal': 'formal',
                            'Concise': 'concise',
                            'Simple': 'simple',
                            'Academic': 'academic'
                        };
                        const sentenceObj = {
                            sentence: splitSentencesArr[index].sentence,
                            temperature: 1,
                            mode: modeMapping[this.paraphraseRightPanel.currentParaphraserTemperature],
                            begin: splitSentencesArr[index].begin,
                            end: splitSentencesArr[index].end,
                            paraNode: currentPara,
                            file_id: (0, utils_1.generateRandomId)(10),
                            lang_code: (_a = this.paraphraserModuleConfig) === null || _a === void 0 ? void 0 : _a.langCode
                        };
                        this.arrayOfHiglightedSentences.push(sentenceObj);
                    }
                }
            }
        }
        catch (e) {
            this.showErrorTabOnRightPanel();
            console.log('Error from splitResponseForParaphrase is: ', e);
        }
    }
    breakSelectedTextToSentences(currentPara) {
        var _a, _b, _c;
        function splitBySentences(text) {
            var _a, _b;
            return (_b = (_a = text === null || text === void 0 ? void 0 : text.match(/[^\.!\?]+[\.!\?]*/g)) === null || _a === void 0 ? void 0 : _a.map(sentence => sentence === null || sentence === void 0 ? void 0 : sentence.trim())) === null || _b === void 0 ? void 0 : _b.filter(sentence => (sentence === null || sentence === void 0 ? void 0 : sentence.length) > 0);
        }
        let arrayOfSentences = [];
        let tempArr = [];
        const range = (_a = this.selectionObj) === null || _a === void 0 ? void 0 : _a.getRangeAt(0);
        const fragment = range === null || range === void 0 ? void 0 : range.cloneContents();
        const childNodes = Array.from(fragment === null || fragment === void 0 ? void 0 : fragment.childNodes);
        childNodes === null || childNodes === void 0 ? void 0 : childNodes.forEach(node => {
            var _a;
            const text = (_a = node === null || node === void 0 ? void 0 : node.textContent) === null || _a === void 0 ? void 0 : _a.trim();
            if (text.length > 0) {
                tempArr = tempArr.concat(splitBySentences(text));
            }
        });
        for (let i = 0; i < tempArr.length; i++) {
            tempArr[i] = (_b = tempArr[i]) === null || _b === void 0 ? void 0 : _b.replace(/\n/g, '');
            let sentenceToBePushed = /^\s/.test(tempArr[i]) ? tempArr[i].slice(1) : tempArr[i];
            if (((_c = tempArr[i]) === null || _c === void 0 ? void 0 : _c.trim().length) > 0) {
                arrayOfSentences.push({
                    sentence: sentenceToBePushed
                });
            }
        }
        return arrayOfSentences;
    }
    createHighlightSentences(startChildNode, endChildNode, startOffset, endOffset, selectedChildNodesArr = []) {
        var _a, _b, _c;
        try {
            let range = document.createRange();
            const selection = window.getSelection();
            selection.removeAllRanges();
            range.selectNodeContents(this.currentFocusedEditable);
            if (startChildNode === endChildNode) {
                if ((startChildNode.childNodes.length === 1 && startChildNode.firstChild.nodeName !== 'SPAN') || startChildNode.nodeType === Node.TEXT_NODE) {
                    let currentNode = startChildNode.nodeName !== '#text' ? this.findTextNodeOfChildElement(startChildNode.firstChild) : [startChildNode];
                    range.setStart(currentNode[0], startOffset);
                    range.setEnd(currentNode[currentNode.length - 1], endOffset);
                    selection.addRange(range);
                    this.highlightSelection();
                }
                else {
                    startChildNode = ((_a = startChildNode === null || startChildNode === void 0 ? void 0 : startChildNode.firstChild) === null || _a === void 0 ? void 0 : _a.nodeName) === 'SPAN' && startChildNode.childNodes.length === 1 ? startChildNode === null || startChildNode === void 0 ? void 0 : startChildNode.firstChild : startChildNode;
                    let childNodes = [];
                    let prevNodeEnd = 0;
                    if (startChildNode.nodeType !== Node.TEXT_NODE) {
                        for (let i = 0; i < startChildNode.childNodes.length; i++) {
                            let textNodes = this.findTextNodeOfChildElement(startChildNode.childNodes[i]);
                            textNodes.forEach((textNode) => {
                                if (textNode) {
                                    let textNodeStart = this.findTextNodesContainingWord(startChildNode, textNode.textContent, textNode);
                                    let textNodeEnd = textNodeStart + Math.min(textNode.textContent.length, endOffset);
                                    prevNodeEnd = textNodeEnd;
                                    if ((textNodeStart < startOffset && textNodeEnd < startOffset) || (textNodeStart > endOffset)) {
                                    }
                                    else {
                                        childNodes.push({
                                            node: textNode,
                                            startIndex: textNodeStart,
                                            endIndex: textNodeEnd
                                        });
                                    }
                                }
                            });
                        }
                    }
                    if (childNodes.length) {
                        let startOfNode = childNodes[0].startIndex > startOffset ? childNodes[0].startIndex - startOffset : startOffset - childNodes[0].startIndex;
                        range.setStart(childNodes[0].node, startOfNode);
                        let endOfNode = Math.min(endOffset - childNodes[childNodes.length - 1].startIndex, childNodes[childNodes.length - 1].endIndex - childNodes[childNodes.length - 1].startIndex);
                        range.setEnd(childNodes[childNodes.length - 1].node, endOfNode);
                        selection.addRange(range);
                        this.highlightSelection();
                    }
                }
            }
            else {
                startChildNode = ((_b = startChildNode === null || startChildNode === void 0 ? void 0 : startChildNode.firstChild) === null || _b === void 0 ? void 0 : _b.nodeName) === 'SPAN' && startChildNode.childNodes.length === 1 ? startChildNode === null || startChildNode === void 0 ? void 0 : startChildNode.firstChild : startChildNode;
                endChildNode = ((_c = endChildNode === null || endChildNode === void 0 ? void 0 : endChildNode.firstChild) === null || _c === void 0 ? void 0 : _c.nodeName) === 'SPAN' && endChildNode.childNodes.length === 1 ? endChildNode === null || endChildNode === void 0 ? void 0 : endChildNode.firstChild : endChildNode;
                let childNodes = [];
                let prevNodeEndForStart = 0;
                for (let i = 0; i < startChildNode.childNodes.length; i++) {
                    let startTextNodes = this.findTextNodeOfChildElement(startChildNode.childNodes[i]);
                    startTextNodes.forEach((textNode) => {
                        if (textNode) {
                            let textNodeStart = this.findTextNodesContainingWord(startChildNode, textNode.textContent, textNode);
                            let textNodeEnd = textNodeStart + textNode.textContent.length;
                            prevNodeEndForStart = textNodeEnd;
                            if ((textNodeStart < startOffset && textNodeEnd < startOffset) || (textNodeStart > startChildNode.textContent.length)) {
                            }
                            else {
                                childNodes.push({
                                    node: textNode,
                                    startIndex: textNodeStart,
                                    endIndex: textNodeEnd
                                });
                            }
                        }
                    });
                }
                let prevNodeEndForEnd = 0;
                for (let i = 0; i < endChildNode.childNodes.length; i++) {
                    let endTextNodes = this.findTextNodeOfChildElement(endChildNode.childNodes[i]);
                    endTextNodes.forEach((textNode) => {
                        if (textNode) {
                            let textNodeStart = this.findTextNodesContainingWord(endChildNode, textNode.textContent, textNode);
                            let textNodeEnd = textNodeStart + textNode.textContent.length;
                            prevNodeEndForStart = textNodeEnd;
                            if ((textNodeStart < 0 && textNodeEnd < 0) || (textNodeStart > endOffset)) {
                            }
                            else {
                                childNodes.push({
                                    node: textNode,
                                    startIndex: textNodeStart,
                                    endIndex: textNodeEnd
                                });
                            }
                        }
                    });
                }
                if (childNodes.length) {
                    let startOfNode = childNodes[0].startIndex > startOffset ? childNodes[0].startIndex - startOffset : startOffset - childNodes[0].startIndex;
                    range.setStart(childNodes[0].node, startOfNode);
                    let endOfNode = Math.min(endOffset - childNodes[childNodes.length - 1].startIndex, childNodes[childNodes.length - 1].endIndex - childNodes[childNodes.length - 1].startIndex);
                    range.setEnd(childNodes[childNodes.length - 1].node, endOfNode);
                    selection.addRange(range);
                    this.highlightSelection();
                }
            }
            this.currentSelectedNodesArr = selectedChildNodesArr;
        }
        catch (e) {
            this.showErrorTabOnRightPanel();
            console.log('Error from createHighlightSentences is: ', e);
        }
    }
    findTextNodeOfChildElement(element) {
        try {
            let textNodes = [];
            if (element.nodeType === Node.TEXT_NODE)
                return [element];
            for (let i = 0; i < element.childNodes.length; i++) {
                let node = element.childNodes[i];
                if (node.nodeType === Node.TEXT_NODE) {
                    textNodes.push(node);
                }
                else if (node.nodeType === Node.ELEMENT_NODE) {
                    textNodes = textNodes.concat(this.findTextNodeOfChildElement(node));
                }
            }
            return textNodes;
        }
        catch (e) {
            this.showErrorTabOnRightPanel();
            console.log('Error from findTextNodeOfChildElement is: ', e);
        }
    }
    highlightSelection() {
        var _a, _b, _c;
        let that = this;
        let ckeditorInstance = (_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.ckeditorInstance;
        let selectionRangeArr = [];
        (_c = (_b = ckeditorInstance === null || ckeditorInstance === void 0 ? void 0 : ckeditorInstance.conversion) === null || _b === void 0 ? void 0 : _b.for('downcast')) === null || _c === void 0 ? void 0 : _c.attributeToElement({
            model: 'highlight',
            view: (attributeValue, { writer }) => {
                if (attributeValue) {
                    return writer.createAttributeElement('span', {
                        class: 'highlight-class',
                        style: "background-color: #E3EFF4;color: #1E2938;"
                    }, {
                        priority: 5
                    });
                }
            }
        });
        function highlightRange(selectedRange, isEndNodeReached = false) {
            if (ckeditorInstance) {
                wrapRangeInCKEditor(selectedRange, isEndNodeReached);
                setTimeout(() => {
                    window.getSelection().removeAllRanges();
                }, 50);
                return;
            }
            function wrapNode(node) {
                let span = document.createElement("span");
                span.classList.add('highlight-class');
                span.setAttribute("style", "background-color: #E3EFF4;color: #1E2938;");
                node.parentNode.insertBefore(span, node);
                span.appendChild(node);
            }
            let range = selectedRange.cloneRange();
            let fragment = range.extractContents();
            let treeWalker = document.createTreeWalker(fragment, NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT, {
                acceptNode: function (node) {
                    if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() === "") {
                        return NodeFilter.FILTER_REJECT;
                    }
                    return NodeFilter.FILTER_ACCEPT;
                }
            });
            while (treeWalker.nextNode()) {
                let currentNode = treeWalker.currentNode;
                if (!that.currentFocusedEditable.ckeditorInstance) {
                    if (currentNode.nodeType === Node.TEXT_NODE || currentNode.nodeType === Node.ELEMENT_NODE) {
                        wrapNode(currentNode);
                    }
                }
            }
            range.insertNode(fragment);
            let highlightedSpans = document.querySelectorAll('span.highlight-class');
            highlightedSpans.forEach(span => {
                let childHighlights = span.querySelectorAll('span.highlight-class');
                if (childHighlights.length > 0) {
                    span.classList.remove('highlight-class');
                    span.removeAttribute('style');
                }
            });
        }
        function highlightElement(writer, element) {
            const range = writer.createRangeIn(element);
            const walker = range.getWalker({ ignoreElementEnd: true });
            for (const value of walker) {
                const item = value.item;
                if (item.is('textProxy')) {
                    writer.setAttributes({ 'highlight': true }, item);
                }
                else if (item.is('element')) {
                    highlightElement(writer, item);
                }
            }
        }
        function wrapRangeInCKEditor(selectionRange, isEndNodeReached = false) {
            selectionRangeArr.push(selectionRange);
            if (!isEndNodeReached)
                return;
            ckeditorInstance.model.change(writer => {
                selectionRangeArr.forEach(currSelectionRange => {
                    let domConverter = ckeditorInstance.editing.view.domConverter;
                    const viewRange = domConverter.domRangeToView(currSelectionRange);
                    if (viewRange) {
                        const modelRange = ckeditorInstance.editing.mapper.toModelRange(viewRange);
                        for (const item of modelRange.getItems()) {
                            if (item.is('textProxy')) {
                                writer.setAttributes({ 'highlight': true }, item);
                            }
                            else if (item.is('element')) {
                                highlightElement(writer, item);
                            }
                        }
                    }
                });
            });
        }
        let selection = window.getSelection();
        if (!selection.rangeCount)
            return;
        let range = selection.getRangeAt(0);
        let startNode = range.startContainer;
        let endNode = range.endContainer;
        if (startNode === endNode) {
            startNode.childNodes.forEach((node) => {
                let currentRange = document.createRange();
                currentRange.selectNode(node);
                highlightRange(currentRange);
            });
            highlightRange(range, true);
        }
        else {
            let startRange = document.createRange();
            startRange.setStart(range.startContainer, range.startOffset);
            startRange.setEndAfter(startNode);
            highlightRange(startRange);
            let allNodes = getNodesInRange(range);
            for (let i = 1; i < allNodes.length; i++) {
                let currentRange = document.createRange();
                currentRange.setStart(allNodes[i], 0);
                currentRange.setEnd(allNodes[i], allNodes[i].length);
                if (currentRange.toString().length > 0)
                    highlightRange(currentRange);
            }
            let endRange = document.createRange();
            endRange.setStartBefore(endNode);
            endRange.setEnd(range.endContainer, range.endOffset);
            highlightRange(endRange, true);
        }
        selection.removeAllRanges();
        function getNodesInRange(range) {
            const nodes = new Set();
            let startNode = range.startContainer;
            let endNode = range.endContainer;
            if (startNode.nodeType === Node.TEXT_NODE) {
                const startText = startNode.textContent.substring(range.startOffset);
                const tempNode = document.createTextNode(startText);
                nodes.add(tempNode);
            }
            if (endNode.nodeType === Node.TEXT_NODE && startNode !== endNode) {
                const endText = endNode.textContent.substring(0, range.endOffset);
                const tempNode = document.createTextNode(endText);
                nodes.add(tempNode);
            }
            function traverseNodes(node) {
                if (node.nodeType === Node.TEXT_NODE && node !== startNode && node !== endNode && range.intersectsNode(node)) {
                    nodes.add(node);
                }
                if (node.nodeType === Node.ELEMENT_NODE) {
                    let child = node.firstChild;
                    while (child) {
                        traverseNodes(child);
                        child = child.nextSibling;
                    }
                }
            }
            traverseNodes(range.commonAncestorContainer);
            return Array.from(nodes);
        }
    }
    removeHighlight(removeRightPanelData = true, removeHighlightTimeout = 0) {
        const highlightSpans = document.querySelectorAll('span.highlight-class');
        const highlightElements = this.getElementsWithInlineStyle('background-color', 'rgb(227, 239, 244)');
        setTimeout(() => {
            var _a;
            (_a = this.selectionObj) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
            this.selectionObj = null;
        }, 100);
        setTimeout(() => {
            var _a, _b, _c, _d;
            highlightSpans === null || highlightSpans === void 0 ? void 0 : highlightSpans.forEach(span => {
                var _a;
                let fragment = document.createDocumentFragment();
                while (span === null || span === void 0 ? void 0 : span.firstChild) {
                    fragment === null || fragment === void 0 ? void 0 : fragment.appendChild(span === null || span === void 0 ? void 0 : span.firstChild);
                }
                (_a = span === null || span === void 0 ? void 0 : span.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(fragment, span);
            });
            highlightElements === null || highlightElements === void 0 ? void 0 : highlightElements.forEach((span) => {
                span === null || span === void 0 ? void 0 : span.removeAttribute('style');
            });
            let ckeditorInstance = ((_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.ckeditorInstance) ? (_b = this.currentFocusedEditable) === null || _b === void 0 ? void 0 : _b.ckeditorInstance : document.querySelector('.ck-editor__main div.ck-editor__editable[contenteditable="true"]') ? (_c = document.querySelector('.ck-editor__main div.ck-editor__editable[contenteditable="true"]')) === null || _c === void 0 ? void 0 : _c.ckeditorInstance : null;
            if (ckeditorInstance) {
                let model = ckeditorInstance === null || ckeditorInstance === void 0 ? void 0 : ckeditorInstance.model;
                let childEleNodes = [];
                function removeAttributeRecursively(writer, element, attributeName) {
                    if (element.hasAttribute(attributeName)) {
                        childEleNodes.push(element);
                    }
                    if (element && element.name && element.getChildren()) {
                        for (const child of element.getChildren()) {
                            removeAttributeRecursively(writer, child, attributeName);
                        }
                    }
                }
                model.change(writer => {
                    const root = model.document.getRoot();
                    removeAttributeRecursively(writer, root, 'highlight');
                    for (let i = 0; i < childEleNodes.length; i++) {
                        writer.removeAttribute('highlight', childEleNodes[i]);
                    }
                });
            }
            (_d = this.currentFocusedEditable) === null || _d === void 0 ? void 0 : _d.normalize();
        }, removeHighlightTimeout);
        if (this.paraphraseRightPanel && removeRightPanelData) {
            this.paraphraseRightPanel.closeAlternatePopup();
            this.paraphraseRightPanel.outputSentenceArr = [];
            this.paraphraseRightPanel.showErrorTab = false;
            this.paraphraseRightPanel.showWarningtab = false;
            this.paraphraseRightPanel.showLimitExhaustErrorTab = false;
            this.paraphraseRightPanel.showLoader = false;
            this.paraphraseRightPanel.replaceBtnText = 'Replace';
            this.paraphraseRightPanel.classesForReplaceBtn['replaced-btn'] = false;
            this.paraphraseRightPanel.disableReplaceBtn = false;
            this.paraphraseRightPanel.isSelectionLimitReached = false;
        }
    }
    clearParaphraserSentences(event) {
        try {
            if (this.arrayOfHiglightedSentences.length > 0) {
                let range = document.createRange();
                range.selectNodeContents(this.currentFocusedEditable);
                let selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                this.removeHighlight();
                this.arrayOfHiglightedSentences = [];
                selection.removeAllRanges();
            }
            else {
                let selection = window.getSelection();
                if (selection.getRangeAt(0).toString().length === 0) {
                    this.paraphraseRightPanel.showErrorTab = false;
                    this.paraphraseRightPanel.showWarningtab = false;
                    this.paraphraseRightPanel.showLimitExhaustErrorTab = false;
                }
            }
        }
        catch (e) {
            console.log('Error from clearParaphraserSentences is: ', e);
        }
    }
    async callParaphraserAPI(arrayOfSentences = [], currentParaphraserTemperature = null) {
        try {
            let userToken = JSON.parse(localStorage.getItem('meta')).token;
            let modeMapping = {
                'Standard': 'standard',
                'Formal': 'formal',
                'Concise': 'concise',
                'Simple': 'simple',
                'Academic': 'academic'
            };
            let modifiedArray = arrayOfSentences.map(obj => {
                let newObj = Object.assign({}, obj);
                if (newObj.hasOwnProperty('paraNode')) {
                    delete newObj['paraNode'];
                }
                if (newObj.hasOwnProperty('begin')) {
                    delete newObj['begin'];
                }
                if (newObj.hasOwnProperty('end')) {
                    delete newObj['end'];
                }
                if (currentParaphraserTemperature) {
                    newObj.temperature = 1;
                    newObj.mode = modeMapping[currentParaphraserTemperature];
                }
                newObj.source = window.location.host.includes('trinka.ai') ? 'CLOUD_TRINKA' : 'BROWSER_PLUGIN';
                return newObj;
            });
            if (modifiedArray.length > constants_1.PARAPHRASER_SENTENCE_LIMIT) {
                this.paraphraseRightPanel.showWarningtab = false;
                this.paraphraseRightPanel.showErrorTab = false;
                this.paraphraseRightPanel.showLimitExhaustErrorTab = false;
                this.paraphraseRightPanel.showLoader = false;
                this.paraphraseRightPanel.isSelectionLimitReached = true;
                return;
            }
            if (modifiedArray && modifiedArray.length > 0) {
                const response = await fetch(this.paraphraserModuleConfig.sentencesAPI, {
                    "headers": {
                        "accept": "application/json, text/plain, */*",
                        "sec-fetch-site": "same-site",
                        "content-type": "application/json",
                        "authorization": `Bearer ${userToken}`,
                        "wsid": this.wsuniqueID
                    },
                    "body": JSON.stringify(modifiedArray),
                    "method": "POST",
                    "mode": "cors",
                    "credentials": "omit"
                });
                if (!response.ok) {
                    const error = new Error(`HTTP error! status: ${response.status}`);
                    error.status = response.status;
                    error.statusText = response.statusText;
                    error.errorObject = await response.json();
                    throw error;
                }
                const data = await response.json();
                this.waitForParaphraserWssResponse = data === null || data === void 0 ? void 0 : data.status;
                if (!(data === null || data === void 0 ? void 0 : data.status))
                    throw new Error('Some error occurred');
            }
        }
        catch (e) {
            let currentHighlightSpans = document.querySelectorAll('.highlight-class');
            if (currentHighlightSpans.length > 0) {
                this.showErrorTabOnRightPanel();
                if (e.status === 409) {
                    this.paraphraseRightPanel.showLimitExhaustErrorTab = true;
                    (0, utils_1.triggerAmplitudeEvent)('ED_Paraphraser_Limit_reached', {});
                    let tempDate = new Date();
                    tempDate.setDate(e.errorObject.renewalDate.date);
                    tempDate.setMonth(e.errorObject.renewalDate.month);
                    tempDate.setFullYear(e.errorObject.renewalDate.year);
                    this.paraphraseRightPanel.paraLimitRenewalDate = this.formatDate(tempDate);
                }
                console.log('Error from callParaphraserAPI is: ', e);
            }
            else {
                this.paraphraseRightPanel.showLoader = false;
                this.paraphraseRightPanel.showErrorTab = false;
                this.paraphraseRightPanel.showWarningtab = false;
            }
        }
    }
    replaceSelectedText(event) {
        var _a, _b, _c, _d, _e, _f;
        try {
            let arrayOfSentencesToReplace = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.arrayOfSentencesToReplace;
            for (let i = 0; i < arrayOfSentencesToReplace.length; i++) {
                let paraWhoseSentenceToReplace = this.findSentenceInPara(arrayOfSentencesToReplace[i].original_sentence);
                if (!this.currentFocusedEditable.ckeditorInstance) {
                    let textToBeReplaced = (_b = this.normalizeString(paraWhoseSentenceToReplace === null || paraWhoseSentenceToReplace === void 0 ? void 0 : paraWhoseSentenceToReplace.textContent)) === null || _b === void 0 ? void 0 : _b.replace((_c = arrayOfSentencesToReplace[i]) === null || _c === void 0 ? void 0 : _c.original_sentence, (_d = arrayOfSentencesToReplace[i]) === null || _d === void 0 ? void 0 : _d.suggestion);
                    paraWhoseSentenceToReplace.innerHTML = textToBeReplaced;
                }
                else {
                    let editor = (_e = this.currentFocusedEditable) === null || _e === void 0 ? void 0 : _e.ckeditorInstance;
                    let startOffset = (paraWhoseSentenceToReplace === null || paraWhoseSentenceToReplace === void 0 ? void 0 : paraWhoseSentenceToReplace.textContent.indexOf(arrayOfSentencesToReplace[i].original_sentence)) > -1 ? paraWhoseSentenceToReplace === null || paraWhoseSentenceToReplace === void 0 ? void 0 : paraWhoseSentenceToReplace.textContent.indexOf(arrayOfSentencesToReplace[i].original_sentence) : this.getStartIndexWithSpaces(paraWhoseSentenceToReplace === null || paraWhoseSentenceToReplace === void 0 ? void 0 : paraWhoseSentenceToReplace.textContent, arrayOfSentencesToReplace[i].original_sentence);
                    this.replaceTextInCKEditor(editor, (_f = arrayOfSentencesToReplace[i]) === null || _f === void 0 ? void 0 : _f.suggestion, startOffset, startOffset + arrayOfSentencesToReplace[i].original_sentence.length, paraWhoseSentenceToReplace);
                }
                (0, utils_1.addEventToTracingLog)('accept_paraphraser');
            }
            setTimeout(() => {
                this.removeHighlight(false, 2500);
            }, 1000);
        }
        catch (e) {
            this.showErrorTabOnRightPanel();
            console.log('Error from replaceSelectedText is: ', e);
        }
    }
    findSentenceInPara(sentence) {
        let foundChildNode;
        for (let i = 0; i < this.currentSelectedNodesArr.length; i++) {
            if (this.normalizeString(this.currentSelectedNodesArr[i].textContent).includes(this.normalizeString(sentence)))
                foundChildNode = this.currentSelectedNodesArr[i];
        }
        if ((foundChildNode === null || foundChildNode === void 0 ? void 0 : foundChildNode.nodeName) === 'DIV' && (foundChildNode === null || foundChildNode === void 0 ? void 0 : foundChildNode.getAttribute('data-placeholder')) === 'Enter some text here') {
            let placeholderDIV = foundChildNode;
            for (let i = 0; i < placeholderDIV.childNodes.length; i++) {
                if (this.normalizeString(placeholderDIV.childNodes[i].textContent).includes(this.normalizeString(sentence)))
                    foundChildNode = placeholderDIV.childNodes[i];
            }
        }
        return foundChildNode;
    }
    destroy() {
        var _a, _b;
        (_a = this.paraphraseRightPanel) === null || _a === void 0 ? void 0 : _a.remove();
        this.removeHighlight();
        this.removeAllEvents();
        (_b = this.paraphraserUI) === null || _b === void 0 ? void 0 : _b.destroy();
        this.paraphraserSocketClient.close();
        this.currentFocusedEditable = null;
    }
    updateTrinkaParaphraserConfig(config) {
        if (!config)
            return;
        this.paraphraserModuleConfig = Object.assign(Object.assign({}, this.paraphraserModuleConfig), config);
        this.paraphraseRightPanel.langCode = this.paraphraserModuleConfig.langCode;
    }
    updateSocketEndpoint(url) {
        if (!url)
            return;
        this.paraphraserModuleConfig.url = url;
    }
    getVisibleTextOffset(parentNode, node, offset) {
        let visibleOffset = 0;
        const iterator = document.createNodeIterator(parentNode, NodeFilter.SHOW_TEXT);
        let currentNode;
        while (currentNode = iterator.nextNode()) {
            if (currentNode === node) {
                return visibleOffset + offset;
            }
            visibleOffset += currentNode.textContent.length;
        }
        return visibleOffset;
    }
    isDescendant(node, ancestor) {
        while (node) {
            if (node === ancestor) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }
    async callParaphraserFeedbackAPI(event) {
        var _a;
        try {
            let requestBody = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.feedbackRequestBody;
            let userToken = JSON.parse(localStorage.getItem('meta')).token;
            const response = await fetch(this.paraphraserModuleConfig.feedbackAPI, {
                "headers": {
                    "accept": "application/json, text/plain, */*",
                    "sec-fetch-site": "same-site",
                    "content-type": "application/json",
                },
                "body": JSON.stringify(requestBody),
                "method": "POST",
                "mode": "cors",
                "credentials": "omit"
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const paraphraserFeedbackAPIEvent = new CustomEvent("paraphraserFeedbackAPIEvent", {
                detail: {},
            });
            document.dispatchEvent(paraphraserFeedbackAPIEvent);
        }
        catch (e) {
            this.showErrorTabOnRightPanel();
            console.log('Error from callParaphraserFeedbackAPI is: ', e);
        }
    }
    showErrorTabOnRightPanel() {
        this.paraphraseRightPanel.showErrorTab = true;
        this.paraphraseRightPanel.showWarningtab = false;
        this.paraphraseRightPanel.showLoader = false;
        this.paraphraseRightPanel.outputSentenceArr = [];
    }
    normalizeString(str) {
        return str
            .replace(/\s+/g, ' ')
            .replace(/[\u00A0]/g, ' ')
            .trim();
    }
    changeParaphraserTemperature(event) {
        var _a;
        let currentParaphraserTemperature = (_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.currentParaphraserTemperature;
        this.paraphraseRightPanel.outputSentenceArr = [];
        this.paraphraseRightPanel.showLoader = true;
        this.paraphraseRightPanel.showErrorTab = false;
        this.paraphraseRightPanel.showWarningtab = false;
        this.paraphraseRightPanel.showLimitExhaustErrorTab = false;
        this.callParaphraserAPI(this.arrayOfHiglightedSentences, currentParaphraserTemperature);
    }
    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '/' + month + '/' + year;
    }
    findTextNodesContainingWord(paragraph, word, currentFocusNode) {
        const nodes = [];
        let that = this;
        function searchInNode(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                if (node.textContent.includes(word)) {
                    nodes.push(node);
                }
            }
            else if (node.nodeType === Node.ELEMENT_NODE) {
                for (let child of node.childNodes) {
                    searchInNode(child);
                }
            }
        }
        searchInNode(paragraph);
        let offsetsOfNode = -1;
        if (nodes.indexOf(currentFocusNode) > -1)
            offsetsOfNode = calculateNodeOffset(currentFocusNode);
        function calculateNodeOffset(node) {
            let offset = 0;
            let currentNode = paragraph.firstChild;
            while (currentNode) {
                if (currentNode === node || that.isDescendant(node, currentNode)) {
                    break;
                }
                if (currentNode.nodeType === Node.TEXT_NODE) {
                    offset += currentNode.textContent.length;
                }
                else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                    for (let child of currentNode.childNodes) {
                        offset += child.textContent.length;
                    }
                }
                currentNode = currentNode.nextSibling;
            }
            return offset;
        }
        return offsetsOfNode;
    }
    getParentnode(currentNode) {
        if ((currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode) === this.currentFocusedEditable) {
            return currentNode;
        }
        return this.getParentnode(currentNode === null || currentNode === void 0 ? void 0 : currentNode.parentNode);
    }
    getElementsWithInlineStyle(property, value) {
        let allElements = document.querySelectorAll('*');
        let matchedElements = [];
        allElements.forEach((element) => {
            if (element.style[property] === value) {
                matchedElements.push(element);
            }
        });
        return matchedElements;
    }
    replaceTextInSpecificParagraphNode(model, paraNode, searchText, replaceText) {
        model.change(writer => {
            const range = model.createRangeIn(this.findSpecificParagraphNode(model, searchText));
            for (const item of range.getItems()) {
                if (item.is('$textProxy')) {
                    const textNode = item.data;
                    if (textNode.includes(searchText)) {
                        const newText = textNode.replace(new RegExp(searchText, 'g'), replaceText);
                        writer.remove(item);
                        writer.insertText(newText, item.getAttributes(), item);
                    }
                }
            }
        });
    }
    findSpecificParagraphNode(model, searchText) {
        function findTextInChildren(node, searchText) {
            if (node.is('text')) {
                if (node.data.includes(searchText)) {
                    return node;
                }
            }
            else {
                for (const child of node.getChildren()) {
                    const foundNode = findTextInChildren(child, searchText);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        }
        const document = model.document;
        const root = document.getRoot();
        for (const child of root.getChildren()) {
            const foundTextNode = findTextInChildren(child, searchText);
            if (foundTextNode) {
                return child;
            }
        }
        return null;
    }
    replaceTextInCKEditor(editor, newText, startOffset, endOffset, startNode) {
        var _a, _b;
        const view = editor.editing.view;
        const model = editor.model;
        const domRoot = view.getDomRoot();
        const scrollPosition = getScrollPosition(editor);
        const startViewNode = checkNodeInViewHierarchy(startNode);
        let startTextNode = (_b = getTextNode((_a = startViewNode === null || startViewNode === void 0 ? void 0 : startViewNode._children) === null || _a === void 0 ? void 0 : _a.filter((x) => {
            var _a, _b;
            let nodeText = (_a = getNodeText(x)) === null || _a === void 0 ? void 0 : _a.replace(/\u00A0/g, ' ');
            return ((_b = startNode === null || startNode === void 0 ? void 0 : startNode.textContent) === null || _b === void 0 ? void 0 : _b.replace(/\u00A0/g, ' ').includes(nodeText)) && hasHighlightClass(x);
        })[0])) === null || _b === void 0 ? void 0 : _b.parent;
        if (!startViewNode) {
            console.log('DOM node is not part of the CKEditor view.');
            return;
        }
        const startViewPos = view.createPositionAt(getTextNode(startTextNode._children[0]), startOffset);
        const endViewPos = view.createPositionAt(getTextNode(startTextNode._children[startTextNode._children.length - 1]), endOffset);
        const viewRange = view.createRange(startViewPos, endViewPos);
        const modelRange = editor.editing.mapper.toModelRange(viewRange);
        let newPosition;
        model.change(writer => {
            replaceTextInNode(writer, modelRange.start.parent, startOffset, endOffset, newText);
            newPosition = writer.createPositionAt(modelRange.start.parent, startOffset + newText.length);
        });
        setTimeout(() => {
            domRoot.scrollTop = scrollPosition;
            view.scrollToTheSelection();
            model.change(writer1 => {
                writer1.setSelection(newPosition);
                setScrollPosition(editor, scrollPosition);
            });
        }, 20);
        function getScrollPosition(editor) {
            const editableElement = editor.editing.view.getDomRoot();
            return {
                top: editableElement.scrollTop,
                left: editableElement.scrollLeft
            };
        }
        function setScrollPosition(editor, position) {
            const editableElement = editor.editing.view.getDomRoot();
            editableElement.scrollTop = position.top;
            editableElement.scrollLeft = position.left;
        }
        function checkNodeInViewHierarchy(node) {
            const view = editor.editing.view;
            let viewNode = view.domConverter.mapDomToView(node);
            if (viewNode) {
                return viewNode;
            }
            let ancestor = node.parentNode;
            while (ancestor) {
                viewNode = view.domConverter.mapDomToView(ancestor);
                if (viewNode) {
                    return viewNode;
                }
                ancestor = ancestor.parentNode;
            }
            return null;
        }
        function getNodeText(node) {
            let textContent = '';
            if (node.is('text'))
                return node.data;
            for (const child of node.getChildren()) {
                if (child.is('text')) {
                    textContent += child.data;
                }
                else if (child.is('element')) {
                    textContent += getNodeText(child);
                }
            }
            return textContent;
        }
        function replaceTextInNode(writer, node, startOffset, endOffset, newText) {
            let offsetCounter = 0;
            let remainingText = newText;
            const toInsert = [];
            for (const child of Array.from(node.getChildren())) {
                if (child.is('text')) {
                    const textLength = child.data.length;
                    if (child.data.trim().length > 0) {
                        if (offsetCounter + textLength > startOffset && offsetCounter < endOffset) {
                            const textStart = Math.max(startOffset - offsetCounter, 0);
                            const textEnd = Math.min(endOffset - offsetCounter, textLength);
                            const beforeText = child.data.slice(0, textStart);
                            const afterText = child.data.slice(textEnd);
                            const replaceLength = Math.max(textEnd - textStart, remainingText.length);
                            const replaceText = remainingText.slice(0, replaceLength);
                            remainingText = remainingText.slice(replaceLength);
                            if (beforeText) {
                                toInsert.push({ text: beforeText, attributes: child.getAttributes() });
                            }
                            toInsert.push({ text: replaceText, attributes: child.getAttributes() });
                            if (afterText) {
                                toInsert.push({ text: afterText, attributes: child.getAttributes() });
                            }
                            writer.remove(child);
                        }
                        else {
                            toInsert.push({ text: child.data, attributes: child.getAttributes() });
                            writer.remove(child);
                        }
                    }
                    offsetCounter += textLength;
                }
                else if (child.is('element') && child.name !== "htmlCustomElement") {
                    if (child.name !== "softBreak")
                        replaceTextInNode(writer, child, startOffset - offsetCounter, endOffset - offsetCounter, remainingText);
                    else
                        writer.remove(child);
                    offsetCounter += child.name !== "softBreak" && child.name !== "imageInline" && child.name !== "htmlInput" ? child === null || child === void 0 ? void 0 : child.getChildCount() : 0;
                }
                else if (child.name === "htmlCustomElement") {
                    writer.remove(child);
                }
            }
            for (const { text, attributes } of toInsert) {
                writer.insertText(text, attributes, node, 'end');
            }
        }
        function getTextNode(node) {
            var _a;
            if (node === null || node === void 0 ? void 0 : node.is('text'))
                return node;
            else {
                if (((_a = node === null || node === void 0 ? void 0 : node._children) === null || _a === void 0 ? void 0 : _a.length) >= 1)
                    return getTextNode(node._children[0]);
            }
        }
        function hasHighlightClass(node) {
            var _a;
            if ((_a = node === null || node === void 0 ? void 0 : node._classes) === null || _a === void 0 ? void 0 : _a.has('highlight-class')) {
                return true;
            }
            if (node._children) {
                for (let child of node._children) {
                    if (hasHighlightClass(child)) {
                        return true;
                    }
                }
            }
            return false;
        }
    }
    getNextSibling(node) {
        if (node === null || node === void 0 ? void 0 : node.nextSibling)
            return node === null || node === void 0 ? void 0 : node.nextSibling;
        else
            return this.getNextSibling(node === null || node === void 0 ? void 0 : node.parentElement);
    }
    handleScrollEvent($event) {
        if (this.paraphraserUI.paraphraseToolTipDiv && this.paraphraserUI.paraphraseToolTipDiv.style)
            this.paraphraserUI.paraphraseToolTipDiv.style.display = 'none';
    }
    handleSelChangeEvent($event) {
        var _a, _b, _c;
        try {
            const selection = document.getSelection();
            (_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
            if (!document.getSelection().toString().length) {
                if (this.paraphraserUI.paraphraseToolTipDiv && this.paraphraserUI.paraphraseToolTipDiv.style)
                    this.paraphraserUI.paraphraseToolTipDiv.style.display = 'none';
            }
            else {
                const isSelectionWithinDiv = ((_b = this.currentFocusedEditable) === null || _b === void 0 ? void 0 : _b.contains(selection.anchorNode)) ||
                    ((_c = this.currentFocusedEditable) === null || _c === void 0 ? void 0 : _c.contains(selection.focusNode));
                if (isSelectionWithinDiv) {
                    if (!this.currSelectionTimeout) {
                        this.currSelectionTimeout = setTimeout(() => {
                            this.selectionObj = window.getSelection();
                            if (this.selectionObj.toString() &&
                                this.selectionObj.toString().trim().length > 0) {
                                if (this.paraphraseRightPanel.replaceBtnText === 'Replaced') {
                                    this.resetRightPanel();
                                }
                                this.paraphraserUI.showParaPhraserTooltip(this.currentFocusedEditable);
                            }
                            clearTimeout(this.currSelectionTimeout);
                            this.currSelectionTimeout = null;
                        }, 300);
                    }
                }
            }
        }
        catch (e) {
            console.log('Error from handleSelChangeEvent is: ', e);
        }
    }
    handleClickEvent($event) {
        var _a, _b, _c, _d, _e;
        (_a = this.currentFocusedEditable) === null || _a === void 0 ? void 0 : _a.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
        if (((_b = document.querySelectorAll('.highlight-class')) === null || _b === void 0 ? void 0 : _b.length) > 0 && ((_d = (_c = document.querySelector('.paraphrase-tooltip')) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.display) === 'none' && document.querySelector('trinka-paraphrase-popup') !== ($event === null || $event === void 0 ? void 0 : $event.target)) {
            this.removeHighlight();
        }
        if ((_e = $event.target) === null || _e === void 0 ? void 0 : _e.closest("trinka-paraphrase-popup")) {
            return;
        }
        else {
            this.paraphraseRightPanel.showParaphraserModesOptions = false;
        }
    }
    getStartIndexWithSpaces(longStr, shortStr) {
        var _a, _b;
        const normalizedLongStr = (_a = longStr === null || longStr === void 0 ? void 0 : longStr.replace(/\s+/g, ' ')) === null || _a === void 0 ? void 0 : _a.trim();
        const normalizedShortStr = (_b = shortStr === null || shortStr === void 0 ? void 0 : shortStr.replace(/\s+/g, ' ')) === null || _b === void 0 ? void 0 : _b.trim();
        const startIndex = normalizedLongStr === null || normalizedLongStr === void 0 ? void 0 : normalizedLongStr.indexOf(normalizedShortStr);
        if (startIndex === -1) {
            return -1;
        }
        let currentIndex = 0;
        let spacesSeen = 0;
        for (let i = 0; i < (longStr === null || longStr === void 0 ? void 0 : longStr.length); i++) {
            if (longStr[i] !== ' ' || (longStr[i] === ' ' && longStr[i - 1] !== ' ')) {
                if (spacesSeen === startIndex) {
                    return i;
                }
                spacesSeen++;
            }
        }
        return -1;
    }
    findNodesWithMultipleChildrenAtLevel(node, level = 0) {
        let result = [];
        if (!node)
            return result;
        if (node.childNodes.length > 1) {
            result.push({ node, level });
        }
        for (let i = 0; i < node.childNodes.length; i++) {
            let childNode = node.childNodes[i];
            result = result.concat(this.findNodesWithMultipleChildrenAtLevel(childNode, level + 1));
        }
        return result;
    }
    async initialAPICallParaphraser() {
        console.log('wss url is: ', this.paraphraserModuleConfig.paraphraserSocketURL);
        this.paraphraserSocketClient = new WebSocket(this.paraphraserModuleConfig.paraphraserSocketURL);
        this.paraphraserSocketClient.addEventListener("open", (event) => {
        });
        this.paraphraserSocketClient.addEventListener("message", (event) => {
            const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
            if (uuidRegex.test(event.data)) {
                this.wsuniqueID = event.data;
            }
            else {
                try {
                    this.paraphraserWSSResponse = JSON.parse(decodeURIComponent(atob(event.data)));
                    if (this.waitForParaphraserWssResponse)
                        this.handleWssResponse();
                }
                catch (e) {
                    console.log('event data is: ', event.data);
                    if (event.data === 'Request processing failed')
                        this.showErrorTabOnRightPanel();
                }
            }
        });
    }
    handleWssResponse() {
        for (let i = 0; i < this.paraphraserWSSResponse.length; i++) {
            for (let j = 0; j < this.paraphraserWSSResponse[i].output_sentence.length; j++) {
                this.paraphraserWSSResponse[i].output_sentence[j].isSelected = false;
            }
            if (this.paraphraserWSSResponse[i] && this.paraphraserWSSResponse[i].output_sentence[0])
                this.paraphraserWSSResponse[i].output_sentence[0].isSelected = true;
        }
        let currentHighlightSpans = document.querySelectorAll('.highlight-class');
        if (currentHighlightSpans.length > 0) {
            this.paraphraseRightPanel.outputSentenceArr = this.paraphraserWSSResponse;
            this.paraphraseRightPanel.showLoader = false;
        }
        else {
            this.paraphraseRightPanel.outputSentenceArr = [];
            this.paraphraseRightPanel.showLoader = false;
            this.paraphraseRightPanel.showErrorTab = false;
            this.paraphraseRightPanel.showWarningtab = false;
        }
    }
    resetRightPanel() {
        if (this.paraphraseRightPanel) {
            this.paraphraseRightPanel.closeAlternatePopup();
            this.paraphraseRightPanel.outputSentenceArr = [];
            this.paraphraseRightPanel.showErrorTab = false;
            this.paraphraseRightPanel.showWarningtab = false;
            this.paraphraseRightPanel.showLimitExhaustErrorTab = false;
            this.paraphraseRightPanel.showLoader = false;
            this.paraphraseRightPanel.replaceBtnText = 'Replace';
            this.paraphraseRightPanel.classesForReplaceBtn['replaced-btn'] = false;
            this.paraphraseRightPanel.disableReplaceBtn = false;
            this.paraphraseRightPanel.isSelectionLimitReached = false;
            this.arrayOfHiglightedSentences = [];
        }
    }
    handleCKEditorInputWithoutDebounce(ckeditorInstance, isTypingFlag = false) {
        if (!ckeditorInstance)
            return;
        if (isTypingFlag)
            (0, utils_1.addEventToTracingLog)('ckEditorInput');
    }
}
exports.Paraphraser = Paraphraser;
