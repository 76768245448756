"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grammar = void 0;
const grammar_ui_1 = require("./grammar-ui");
const utils_1 = require("../helpers/utils");
const trinka_enums_1 = require("../enums/trinka.enums");
const caching_1 = require("../caching/caching");
const constants_1 = require("../helpers/constants");
const sentence_highlighter_1 = require("../components/sentence-highlighter");
class Grammar {
    constructor(trinkaConfig, grammarModuleConfig, uiConfig, selector, cloudConfig = undefined) {
        this.name = `grammar`;
        this.boundHandleInputEvent = ($event) => this.handleInputEvent($event);
        this.boundHandleUserActionsEvent = ($event) => this.handleUserActionsEvent($event);
        this.boundHandleSentenceWiseUserActionsEvent = ($event) => this.handleSentenceWiseUserActionsEvent($event);
        this.boundHandleTrackChangesUserActionsEvent = ($event) => this.handleTrackChangesUserActionsEvent($event);
        this.boundHandleWritingModeUpdateEvent = ($event) => this.handleWritingModeUpdateEvent($event);
        this.boundHandleDraggablePanelSentenceWiseCardSingleRejectEvent = ($event) => this.handleDraggablePanelSentenceWiseCardSingleRejectEvent($event);
        this.boundGroupedAlertEvent = ($event) => this.handleGroupedAlertEvent($event);
        this.stopRemainingRequests = false;
        this.documentTypeList = ["", "Select", "General", "Academic", "Legal"];
        this.hostPageInfo = {
            supportedEditorsLibraries: constants_1.EDITORS_LIBRARIES_TO_SUPPORT,
            isCloudTrinka: true,
            isEditorLibraryPresent: false,
            isMultipleLibrariesPresent: false,
            detectedLibraries: []
        };
        this.cloudConfig = undefined;
        this.getAllPotentialEditorElements = (doc) => {
            const elements = Array.from(doc.querySelectorAll(this.selector));
            const iframes = Array.from(doc.querySelectorAll('iframe'));
            iframes.forEach(iframe => {
                try {
                    if (iframe.contentDocument) {
                        elements.push(...this.getAllPotentialEditorElements(iframe.contentDocument));
                    }
                }
                catch (e) {
                    console.warn('Could not access iframe content:', iframe, e);
                }
            });
            return elements;
        };
        this.trinkaConfig = trinkaConfig;
        this.grammarModuleConfig = grammarModuleConfig;
        this.uiConfig = uiConfig;
        this.selector = selector;
        this.cloudConfig = cloudConfig;
        this.scanHostPage();
        this.cachingService = new caching_1.CachingService();
        this.grammarUI = new grammar_ui_1.GrammarUI(this.trinkaConfig, this.grammarModuleConfig, this.uiConfig, this.hostPageInfo, this.cloudConfig);
        this.initialize();
    }
    async scanHostPage() {
        this.hostPageInfo.isCloudTrinka = !(this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api');
        Object.keys(this.hostPageInfo.supportedEditorsLibraries).forEach(editor => {
            this.hostPageInfo.supportedEditorsLibraries[editor].detect();
        });
        if (!Object.values(this.hostPageInfo.supportedEditorsLibraries).some(library => library.detected)) {
            this.hostPageInfo.isEditorLibraryPresent = false;
        }
        else {
            this.hostPageInfo.isEditorLibraryPresent = true;
            this.hostPageInfo.detectedLibraries = Object.values(this.hostPageInfo.supportedEditorsLibraries)
                .filter(library => library.detected)
                .map(library => library.editorLibraryName);
            this.hostPageInfo.isMultipleLibrariesPresent = this.hostPageInfo.detectedLibraries.length > 1;
        }
    }
    initialize() {
        var _a;
        let editableElements = [];
        if (this.hostPageInfo.isCloudTrinka && !((_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.isBrowserExtension)) {
            editableElements = Array.from(document.querySelectorAll(this.selector));
        }
        else {
            editableElements = this.getAllPotentialEditorElements(document);
        }
        this.registerEventListeners();
        if (!editableElements.length)
            return;
        this.grammarUI.initialize(editableElements);
        if (this.uiConfig.scanEditorOn === trinka_enums_1.Trinka_ENUMS.ON_EDITOR_FOCUS) {
        }
        else if (this.uiConfig.scanEditorOn === trinka_enums_1.Trinka_ENUMS.ON_PAGE_LOAD) {
            this.startAllEditorScanning();
        }
    }
    rescan() {
        this.destroyAllEditors();
        setTimeout(() => {
            const editableElements = this.getAllPotentialEditorElements(document);
            this.grammarUI.initialize(editableElements);
            this.startAllEditorScanning();
        }, 2000);
    }
    destroy() {
        this.grammarUI.destroy();
        this.removeEventListeners();
        let mirrorDivArr = document.querySelectorAll('[is-mirror-div]');
        mirrorDivArr === null || mirrorDivArr === void 0 ? void 0 : mirrorDivArr.forEach((x) => {
            var _a;
            if ((_a = x === null || x === void 0 ? void 0 : x.previousSibling) === null || _a === void 0 ? void 0 : _a.style) {
                x.previousSibling.style.opacity = '1';
            }
            x.remove();
        });
    }
    destroyAllEditors() {
        this.grammarUI.destroyAllEditors();
    }
    updateSocketEndpoint(url) {
        if (!url)
            return;
        this.grammarModuleConfig.url = url;
        this.grammarUI.updateSocketEndpoint();
    }
    updateTrinkaEditorConfig(config, rescanRequired = true) {
        if (!config)
            return;
        this.grammarModuleConfig.trinkaEditorConfig = Object.assign(Object.assign({}, this.grammarModuleConfig.trinkaEditorConfig), config);
        if (rescanRequired) {
            Object.keys(this.grammarUI.listOfEditors).forEach((editorId) => {
                const editorInstance = this.grammarUI.listOfEditors[editorId];
                Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                    this.removeExistingSegment(editorInstance, text);
                });
                editorInstance.editorDOMTreeNodes = {};
                editorInstance.components.draggablePanel.filters = JSON.parse(JSON.stringify(constants_1.FILTERS));
                editorInstance.components.draggablePanel.trinkaEditorConfig = this.grammarModuleConfig.trinkaEditorConfig;
                editorInstance.components.overlayCardComponent.trinkaEditorConfig = this.grammarModuleConfig.trinkaEditorConfig;
                if (this.grammarModuleConfig.trinkaEditorConfig.style_guide.value !== `NONE`) {
                    editorInstance.components.draggablePanel.filters.styleGuide.title = `${this.grammarModuleConfig.trinkaEditorConfig.style_guide.title} Style`;
                    editorInstance.components.draggablePanel.filters.styleGuide.display = true;
                }
                else {
                    editorInstance.components.draggablePanel.filters.styleGuide.display = false;
                }
                if (this.grammarModuleConfig.trinkaEditorConfig.document_type == 4) {
                    editorInstance.components.draggablePanel.isLegal = true;
                    editorInstance.components.draggablePanel.filters.legalWritingStyle.isSelected = true;
                    editorInstance.components.draggablePanel.filters.legalWritingStyle.display = true;
                    editorInstance.components.draggablePanel.filters.grammar.isSelected = false;
                    editorInstance.components.draggablePanel.filters.grammar.disabled = true;
                    editorInstance.components.draggablePanel.filters.writingAdvisor.isSelected = false;
                    editorInstance.components.draggablePanel.filters.writingAdvisor.disabled = true;
                }
                else {
                    editorInstance.components.draggablePanel.isLegal = false;
                    editorInstance.components.draggablePanel.filters.legalWritingStyle.isSelected = false;
                    editorInstance.components.draggablePanel.filters.legalWritingStyle.display = false;
                    editorInstance.components.draggablePanel.filters.grammar.isSelected = true;
                    editorInstance.components.draggablePanel.filters.grammar.disabled = false;
                    editorInstance.components.draggablePanel.filters.writingAdvisor.isSelected = true;
                    editorInstance.components.draggablePanel.filters.writingAdvisor.disabled = false;
                }
                const InstructionModuleMapping = {
                    'basic': 'Lite Mode',
                    'advanced': 'Power Mode',
                    'offline': 'Offline Mode'
                };
                let prevWritingMode = editorInstance.components.draggablePanel.currentWritingMode;
                editorInstance.components.draggablePanel.currentWritingMode = InstructionModuleMapping[this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module];
                if (editorInstance.components.draggablePanel.currentWritingMode !== prevWritingMode)
                    this.rescanAndDockRightPanelForCloudEditor();
                editorInstance.components.draggablePanel.currenDocumentType = this.grammarModuleConfig.trinkaEditorConfig.document_type;
                this.showInclusiveLanguageFilter(editorInstance);
                this.startEditorScanning(editorInstance);
                const changeModeEvent = new CustomEvent("tsdk-view-mode-changed", {
                    bubbles: true,
                    composed: true,
                    detail: {
                        editorId: editorId,
                        mode: constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL
                    }
                });
                document.dispatchEvent(changeModeEvent);
            });
        }
        else {
            Object.keys(this.grammarUI.listOfEditors).forEach((editorId) => {
                const editorInstance = this.grammarUI.listOfEditors[editorId];
                this.showInclusiveLanguageFilter(editorInstance);
                const changeModeEvent = new CustomEvent("tsdk-view-mode-changed", {
                    bubbles: true,
                    composed: true,
                    detail: {
                        editorId: editorId,
                        mode: constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL
                    }
                });
                document.dispatchEvent(changeModeEvent);
            });
        }
    }
    destroyEditor(editorId) {
        if (!editorId)
            return;
        const editorInstance = this.grammarUI.listOfEditors[editorId];
        if (!editorInstance)
            return;
        this.grammarUI.destroyEditor(editorInstance);
    }
    getAllAlertCount() {
        let alertCount = {};
        Object.keys(this.grammarUI.listOfEditors).forEach((editorId) => {
            alertCount[editorId] = this.getEditorAlertCount(editorId);
        });
        return alertCount;
    }
    getEditorAlertCount(editorId) {
        if (!editorId)
            return {};
        const editorInstance = this.grammarUI.listOfEditors[editorId];
        if (!editorInstance)
            return {};
        return this.grammarUI.getEditorAlertCount(editorInstance);
    }
    dockRightPanelForTrinkaEditor(editorId) {
        if (!editorId)
            return {};
        const editorInstance = this.grammarUI.listOfEditors[editorId];
        if (!editorInstance)
            return {};
        editorInstance.components.draggablePanel.showDraggablePanelHeader = false;
        editorInstance.components.draggablePanel.setRightPanelForCloud();
        editorInstance.components.draggablePanel.open();
        const InstructionModuleMapping = {
            'basic': 'Lite Mode',
            'advanced': 'Power Mode',
            'offline': 'Offline Mode'
        };
        editorInstance.components.draggablePanel.currentWritingMode = InstructionModuleMapping[this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module];
        editorInstance.components.draggablePanel.currenDocumentType = this.grammarModuleConfig.trinkaEditorConfig.document_type;
        this.showInclusiveLanguageFilter(editorInstance);
    }
    handleInputEvent($event) {
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.grammarUI.listOfEditors[$event.detail.editorId];
        if (!editorInstance)
            return;
        this.startEditorScanning(editorInstance);
    }
    hasSimilarCardsToIgnore(editorInstance, alertToCheck) {
        let type = alertToCheck['suggestions'][0]['type'];
        if (type === 2) {
            const findSimilarCards = editorInstance.components.draggablePanel.alertList
                .filter(alert => alert['text'] === alertToCheck['text'])
                .filter(alert => alert['suggestions'][0]['type'] === type);
            return findSimilarCards;
        }
        return [alertToCheck];
    }
    removeAlertById(editorInstance, alertId) {
        const index = editorInstance.components.draggablePanel.alertList.findIndex(alert => alert.alertId === alertId);
        if (index !== -1) {
            editorInstance.components.draggablePanel.alertList.splice(index, 1);
        }
    }
    generateSearchKeyForTrinkaConfig() {
        return `${this.grammarModuleConfig.trinkaEditorConfig.language}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}#${this.grammarModuleConfig.trinkaEditorConfig.style_guide.value}#${this.grammarModuleConfig.trinkaEditorConfig.document_type}#${this.grammarModuleConfig.trinkaEditorConfig.power_mode}#${this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module}#${this.grammarModuleConfig.trinkaEditorConfig.instructionModule.instruction}`;
    }
    handleUserActionsEvent($event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18;
        if (!$event.detail.editorId || !$event.detail.alertId || !$event.detail.action)
            return;
        const editorInstance = this.grammarUI.listOfEditors[$event.detail.editorId];
        const alertId = $event.detail.alertId;
        const action = $event.detail.action;
        const isGroupedAlert = $event.detail.isGroupAlert;
        const groupPosition = $event.detail.groupPosition;
        const alert = editorInstance.components.draggablePanel.alertList.find(alert => alert.alertId === alertId);
        const sentence = editorInstance.segmentToSentencesMap.get(alert.segmentId)[alert.sentenceId];
        const segment = Object.keys(editorInstance.editorDOMTreeNodes)
            .filter((key) => editorInstance.editorDOMTreeNodes[key].segmentId === alert.segmentId)
            .map((key) => editorInstance.editorDOMTreeNodes[key])[0];
        const currentAlertIndex = editorInstance.components.draggablePanel.alertList.findIndex(alert => alert.alertId === alertId);
        if (!segment)
            return;
        switch (action) {
            case 'accept': {
                const suggestionIndex = $event.detail.suggestionIndex;
                let payload = Object.assign({}, alert.suggestions[suggestionIndex]);
                payload.correction = alert.suggestions[suggestionIndex].suggestion;
                payload.text = alert.text;
                payload.sentence = sentence.sentence;
                payload.error_type = alert.suggestions[suggestionIndex].type;
                payload.language = ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language) || 'US';
                payload.langCode = ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode) || 'en';
                if (alert.suggestions[suggestionIndex]["spell_status"] == 'conf') {
                    payload.type = 2;
                }
                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                    editorInstance.socketClient.accept(payload, ((_e = this.trinkaConfig) === null || _e === void 0 ? void 0 : _e.connectionType) === 'api');
                }
                else {
                    editorInstance.socketClient.accept(payload, ((_f = this.trinkaConfig) === null || _f === void 0 ? void 0 : _f.connectionType) === 'api');
                }
                if (editorInstance.underlines.has(alertId)) {
                    editorInstance.underlines.get(alertId).acceptAlert(suggestionIndex);
                    if (!((_g = editorInstance === null || editorInstance === void 0 ? void 0 : editorInstance.editor) === null || _g === void 0 ? void 0 : _g.ckeditorInstance)) {
                        editorInstance.underlines.get(alertId).setCursorPosition({}, 'at-end-after-replace', suggestionIndex);
                        editorInstance.editor.focus();
                    }
                    this.removeAlertById(editorInstance, alertId);
                    this.grammarUI.applyFilter(editorInstance, true);
                    (0, utils_1.addEventToTracingLog)('accept_grammar');
                    this.mappingCleanup(editorInstance);
                }
                if (this.hostPageInfo.isCloudTrinka) {
                    this.sendAmplitudeEvent('grammar_accept', {
                        'category': alert.suggestions[0].error_category,
                        'language': (_j = (_h = this.grammarModuleConfig) === null || _h === void 0 ? void 0 : _h.trinkaEditorConfig) === null || _j === void 0 ? void 0 : _j.language,
                        'lang_code': (_l = (_k = this.grammarModuleConfig) === null || _k === void 0 ? void 0 : _k.trinkaEditorConfig) === null || _l === void 0 ? void 0 : _l.langCode,
                        'strictness': alert.strictness,
                        "doc_type": this.documentTypeList[(_o = (_m = this.grammarModuleConfig) === null || _m === void 0 ? void 0 : _m.trinkaEditorConfig) === null || _o === void 0 ? void 0 : _o.document_type],
                        'model': (_q = (_p = this.grammarModuleConfig.trinkaEditorConfig) === null || _p === void 0 ? void 0 : _p.instructionModule) === null || _q === void 0 ? void 0 : _q.module,
                        'isGroupedAlert': isGroupedAlert
                    });
                }
                const index = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.findIndex(a => a.sentenceId === alert.sentenceId);
                if (index !== -1) {
                    const sentenceHighlighter = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.splice(index, 1)[0];
                    sentenceHighlighter.sentenceHighlighter.destroy();
                    editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList];
                }
                if (!isGroupedAlert || (isGroupedAlert && groupPosition === 0))
                    this.startEditorScanning(editorInstance);
                break;
            }
            case 'reject': {
                let payload = Object.assign({}, alert.suggestions[0]);
                payload.correction = alert.suggestions[0].suggestion;
                payload.text = alert.text;
                payload.sentence = sentence.sentence;
                payload.error_type = alert.suggestions[0].type;
                payload.language = ((_s = (_r = this.grammarModuleConfig) === null || _r === void 0 ? void 0 : _r.trinkaEditorConfig) === null || _s === void 0 ? void 0 : _s.language) || 'US';
                payload.langCode = ((_u = (_t = this.grammarModuleConfig) === null || _t === void 0 ? void 0 : _t.trinkaEditorConfig) === null || _u === void 0 ? void 0 : _u.langCode) || 'en';
                if (alert.suggestions[0]["spell_status"] == 'conf') {
                    payload.type = 2;
                }
                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                    editorInstance.socketClient.reject(payload, ((_v = this.trinkaConfig) === null || _v === void 0 ? void 0 : _v.connectionType) === 'api');
                }
                else {
                    const searchKey = `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                    editorInstance.socketClient.reject(payload, ((_w = this.trinkaConfig) === null || _w === void 0 ? void 0 : _w.connectionType) === 'api');
                    this.cachingService.deleteCheckResponse(searchKey);
                }
                if (editorInstance.underlines.has(alertId)) {
                    editorInstance.underlines.get(alertId).rejectAlert();
                    this.removeAlertById(editorInstance, alertId);
                    this.grammarUI.applyFilter(editorInstance, true);
                    this.mappingCleanup(editorInstance);
                }
                this.sendAmplitudeEvent('grammar_reject', {
                    'error_text': alert.text,
                    'suggestion': alert.suggestions[0].suggestion,
                    'category': alert.suggestions[0].error_category,
                    'language': (_y = (_x = this.grammarModuleConfig) === null || _x === void 0 ? void 0 : _x.trinkaEditorConfig) === null || _y === void 0 ? void 0 : _y.language,
                    'lang_code': (_0 = (_z = this.grammarModuleConfig) === null || _z === void 0 ? void 0 : _z.trinkaEditorConfig) === null || _0 === void 0 ? void 0 : _0.langCode,
                    'strictness': alert.strictness,
                    "doc_type": this.documentTypeList[this.grammarModuleConfig.trinkaEditorConfig.document_type],
                    'model': this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module,
                    'isGroupedAlert': isGroupedAlert
                });
                if (!isGroupedAlert)
                    editorInstance.components.draggablePanel.closeAllCards();
                setTimeout(() => {
                    if (!isGroupedAlert)
                        editorInstance.components.draggablePanel.openNextAlert(currentAlertIndex);
                }, 0);
                break;
            }
            case 'ignore': {
                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                }
                else {
                    const searchKey = `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                    alert.suggestions.forEach((suggestion) => {
                        let payload = Object.assign({}, alert.suggestions[0]);
                        payload.correction = alert.suggestions[0].suggestion;
                        payload.text = alert.text;
                        payload.error_type = alert.suggestions[0].type;
                        payload.sentence = sentence.sentence;
                        payload.language = this.grammarModuleConfig.trinkaEditorConfig.language;
                        payload.langCode = this.grammarModuleConfig.trinkaEditorConfig.langCode;
                        if (suggestion.error_category === "Fragment") {
                            payload.type = 6;
                        }
                        if (suggestion.error_category === "Complexity") {
                            payload.type = 7;
                        }
                        if (suggestion.error_category === "RunOn") {
                            payload.type = 8;
                        }
                        editorInstance.socketClient.ignore(payload);
                    });
                    this.cachingService.deleteCheckResponse(searchKey);
                }
                if (editorInstance.underlines.has(alertId)) {
                    editorInstance.underlines.get(alertId).remove();
                    this.removeAlertById(editorInstance, alertId);
                    this.grammarUI.applyFilter(editorInstance, true);
                    this.mappingCleanup(editorInstance);
                }
                this.sendAmplitudeEvent('grammar_ignore', {
                    'error_text': alert.text,
                    'suggestion': alert.suggestions[0].suggestion,
                    'category': alert.suggestions[0].error_category,
                    'language': (_2 = (_1 = this.grammarModuleConfig) === null || _1 === void 0 ? void 0 : _1.trinkaEditorConfig) === null || _2 === void 0 ? void 0 : _2.language,
                    'lang_code': (_4 = (_3 = this.grammarModuleConfig) === null || _3 === void 0 ? void 0 : _3.trinkaEditorConfig) === null || _4 === void 0 ? void 0 : _4.langCode,
                    'strictness': alert.strictness,
                    "doc_type": this.documentTypeList[this.grammarModuleConfig.trinkaEditorConfig.document_type],
                    'model': this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module
                });
                break;
            }
            case 'ignoreAll': {
                const similarCards = this.hasSimilarCardsToIgnore(editorInstance, alert);
                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                }
                else {
                    for (let index = 0, length = similarCards.length; index < length; index++) {
                        this.handleUserActionsEvent({
                            detail: {
                                editorId: editorInstance.editorId,
                                alertId: similarCards[index].alertId,
                                action: 'ignore'
                            }
                        });
                    }
                }
                break;
            }
            case 'mute': {
                let payload = Object.assign({}, alert.suggestions[0]);
                payload.correction = alert.suggestions[0].suggestion;
                payload.text = alert.text;
                payload.error_type = alert.suggestions[0].type;
                payload.sentence = sentence.sentence;
                payload.language = ((_6 = (_5 = this.grammarModuleConfig) === null || _5 === void 0 ? void 0 : _5.trinkaEditorConfig) === null || _6 === void 0 ? void 0 : _6.language) || 'US';
                payload.langCode = ((_8 = (_7 = this.grammarModuleConfig) === null || _7 === void 0 ? void 0 : _7.trinkaEditorConfig) === null || _8 === void 0 ? void 0 : _8.langCode) || 'en';
                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                    editorInstance.socketClient.mute(payload, ((_9 = this.trinkaConfig) === null || _9 === void 0 ? void 0 : _9.connectionType) === 'api');
                }
                else {
                    const searchKey = `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                    editorInstance.socketClient.mute(payload, ((_10 = this.trinkaConfig) === null || _10 === void 0 ? void 0 : _10.connectionType) === 'api');
                    this.cachingService.deleteCheckResponse(searchKey);
                }
                if (editorInstance.underlines.has(alertId)) {
                    editorInstance.underlines.get(alertId).remove();
                    this.removeAlertById(editorInstance, alertId);
                    this.grammarUI.applyFilter(editorInstance, true);
                    this.mappingCleanup(editorInstance);
                }
                this.sendAmplitudeEvent('add_to_dictionary', {
                    'word': alert.text,
                    'lang_code': (_12 = (_11 = this.grammarModuleConfig) === null || _11 === void 0 ? void 0 : _11.trinkaEditorConfig) === null || _12 === void 0 ? void 0 : _12.langCode,
                    'model': this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module,
                    'isGroupedAlert': isGroupedAlert
                });
                break;
            }
            case 'accept_noncta': {
                let payload = Object.assign({}, alert.suggestions[0]);
                payload.correction = alert.suggestions[0].suggestion;
                payload.text = alert.text;
                payload.error_type = alert.suggestions[0].type;
                payload.sentence = sentence.sentence;
                payload.language = ((_14 = (_13 = this.grammarModuleConfig) === null || _13 === void 0 ? void 0 : _13.trinkaEditorConfig) === null || _14 === void 0 ? void 0 : _14.language) || 'US';
                payload.langCode = ((_16 = (_15 = this.grammarModuleConfig) === null || _15 === void 0 ? void 0 : _15.trinkaEditorConfig) === null || _16 === void 0 ? void 0 : _16.langCode) || 'en';
                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                    editorInstance.socketClient.acceptnoncta(payload, ((_17 = this.trinkaConfig) === null || _17 === void 0 ? void 0 : _17.connectionType) === 'api');
                }
                else {
                    editorInstance.socketClient.acceptnoncta(payload, ((_18 = this.trinkaConfig) === null || _18 === void 0 ? void 0 : _18.connectionType) === 'api');
                }
                if (editorInstance.underlines.has(alertId)) {
                    editorInstance.underlines.get(alertId).remove();
                    this.removeAlertById(editorInstance, alertId);
                    this.grammarUI.applyFilter(editorInstance, true);
                    this.mappingCleanup(editorInstance);
                }
                break;
            }
            default: {
                console.log(`${action} not supported`);
                break;
            }
        }
    }
    handleTrackChangesUserActionsEvent($event) {
        if (!$event.detail.editorId || !$event.detail.action)
            return;
        const editorInstance = this.grammarUI.listOfEditors[$event.detail.editorId];
        const action = $event.detail.action;
        const alertToProcess = new Map();
        let sequence = 0;
        const editorDOMTreeNodes = editorInstance.editorDOMTreeNodes;
        const segments = Object.keys(editorDOMTreeNodes).map(key => editorDOMTreeNodes[key]).sort((a, b) => b.position - a.position);
        segments.forEach(segment => {
            const sentences = Object.keys(editorInstance.segmentToSentencesMap.get(segment.segmentId) || {})
                .map(key => editorInstance.segmentToSentencesMap.get(segment.segmentId)[key])
                .sort((a, b) => b.begin - a.begin);
            sentences.forEach(sentence => {
                const alerts = Object.keys(editorInstance.sentenceToAlertsMap.get(sentence.sentenceId) || {})
                    .map(key => editorInstance.sentenceToAlertsMap.get(sentence.sentenceId)[key])
                    .sort((a, b) => b.begin - a.begin);
                for (let index = 0; index < alerts.length; index++) {
                    const alert = alerts[index];
                    alertToProcess.set(sequence, alert);
                    sequence++;
                }
            });
        });
        switch (action) {
            case 'accept-all': {
                const processMapInBatches = async (map, batchSize, delay) => {
                    const entries = Array.from(map.entries());
                    for (let i = 0; i < entries.length; i += batchSize) {
                        const batch = entries.slice(i, i + batchSize);
                        batch.forEach((mapRecord, sequence) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                            try {
                                const suggestionIndex = 0;
                                const alert = mapRecord[1];
                                const alertType = alert.suggestions[suggestionIndex].type;
                                let payload = Object.assign({}, alert.suggestions[suggestionIndex]);
                                payload.correction = alert.suggestions[suggestionIndex].suggestion;
                                payload.text = alert.text;
                                payload.sentence = alert.sentenceText;
                                payload.language = ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language) || 'US';
                                payload.langCode = ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode) || 'en';
                                if (alert.suggestions[suggestionIndex]["spell_status"] == 'conf') {
                                    payload.type = 2;
                                }
                                if (alertType === 3) {
                                    editorInstance.socketClient.acceptnoncta(payload, ((_e = this.trinkaConfig) === null || _e === void 0 ? void 0 : _e.connectionType) === 'api');
                                }
                                else {
                                    editorInstance.socketClient.accept(payload, ((_f = this.trinkaConfig) === null || _f === void 0 ? void 0 : _f.connectionType) === 'api');
                                }
                                if (editorInstance.underlines.has(alert.alertId)) {
                                    if (alertType === 3) {
                                        editorInstance.underlines.get(alert.alertId).remove();
                                    }
                                    else {
                                        editorInstance.underlines.get(alert.alertId).acceptAlert(suggestionIndex);
                                    }
                                }
                                (0, utils_1.addEventToTracingLog)('accept_grammar');
                                if (this.hostPageInfo.isCloudTrinka) {
                                    this.sendAmplitudeEvent('grammar_accept', {
                                        'category': alert.suggestions[suggestionIndex].error_category,
                                        'language': (_h = (_g = this.grammarModuleConfig) === null || _g === void 0 ? void 0 : _g.trinkaEditorConfig) === null || _h === void 0 ? void 0 : _h.language,
                                        'lang_code': (_k = (_j = this.grammarModuleConfig) === null || _j === void 0 ? void 0 : _j.trinkaEditorConfig) === null || _k === void 0 ? void 0 : _k.langCode,
                                        'strictness': alert.strictness,
                                        "doc_type": this.documentTypeList[(_m = (_l = this.grammarModuleConfig) === null || _l === void 0 ? void 0 : _l.trinkaEditorConfig) === null || _m === void 0 ? void 0 : _m.document_type],
                                        'model': (_p = (_o = this.grammarModuleConfig.trinkaEditorConfig) === null || _o === void 0 ? void 0 : _o.instructionModule) === null || _p === void 0 ? void 0 : _p.module
                                    });
                                }
                            }
                            catch (error) {
                                console.log('[DEBUG] error ===> ', error);
                            }
                        });
                        if (i + batchSize < entries.length) {
                            await new Promise(resolve => setTimeout(resolve, delay));
                        }
                    }
                    editorInstance.components.trackChangesEditorOverlayInstance.hideLoader();
                    editorInstance.pauseGEC = false;
                    editorInstance.components.trackChangesEditorOverlayInstance.editorOverlayElements.innerHTML = '';
                    const changeModeEvent = new CustomEvent("tsdk-view-mode-changed", {
                        bubbles: true,
                        composed: true,
                        detail: {
                            editorId: editorInstance.editorId,
                            mode: constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL
                        }
                    });
                    document.dispatchEvent(changeModeEvent);
                };
                editorInstance.components.trackChangesEditorOverlayInstance.showLoader();
                editorInstance.pauseGEC = true;
                processMapInBatches(alertToProcess, 20, 5000);
                break;
            }
            case 'reject-all': {
                const processMapInBatches = async (map, batchSize, delay) => {
                    const entries = Array.from(map.entries());
                    for (let i = 0; i < entries.length; i += batchSize) {
                        const batch = entries.slice(i, i + batchSize);
                        batch.forEach((mapRecord, sequence) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                            try {
                                const suggestionIndex = 0;
                                const alert = mapRecord[1];
                                let payload = Object.assign({}, alert.suggestions[suggestionIndex]);
                                payload.correction = alert.suggestions[suggestionIndex].suggestion;
                                payload.text = alert.text;
                                payload.sentence = alert.sentenceText;
                                payload.language = ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language) || 'US';
                                payload.langCode = ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode) || 'en';
                                if (alert.suggestions[suggestionIndex]["spell_status"] == 'conf') {
                                    payload.type = 2;
                                }
                                if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                                    editorInstance.socketClient.reject(payload, ((_e = this.trinkaConfig) === null || _e === void 0 ? void 0 : _e.connectionType) === 'api');
                                }
                                else {
                                    const searchKey = `${alert.sentenceText}#${this.generateSearchKeyForTrinkaConfig()}`;
                                    editorInstance.socketClient.reject(payload, ((_f = this.trinkaConfig) === null || _f === void 0 ? void 0 : _f.connectionType) === 'api');
                                    this.cachingService.deleteCheckResponse(searchKey);
                                }
                                if (editorInstance.underlines.has(alert.alertId)) {
                                    editorInstance.underlines.get(alert.alertId).rejectAlert();
                                }
                                this.sendAmplitudeEvent('grammar_reject', {
                                    'error_text': alert.text,
                                    'suggestion': alert.suggestions[suggestionIndex].suggestion,
                                    'category': alert.suggestions[suggestionIndex].error_category,
                                    'language': (_h = (_g = this.grammarModuleConfig) === null || _g === void 0 ? void 0 : _g.trinkaEditorConfig) === null || _h === void 0 ? void 0 : _h.language,
                                    'lang_code': (_k = (_j = this.grammarModuleConfig) === null || _j === void 0 ? void 0 : _j.trinkaEditorConfig) === null || _k === void 0 ? void 0 : _k.langCode,
                                    'strictness': alert.strictness,
                                    "doc_type": this.documentTypeList[this.grammarModuleConfig.trinkaEditorConfig.document_type],
                                    'model': this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module
                                });
                            }
                            catch (error) {
                                console.log('[DEBUG] error ===> ', error);
                            }
                        });
                        if (i + batchSize < entries.length) {
                            await new Promise(resolve => setTimeout(resolve, delay));
                        }
                    }
                    editorInstance.components.trackChangesEditorOverlayInstance.hideLoader();
                    editorInstance.pauseGEC = false;
                    editorInstance.components.trackChangesEditorOverlayInstance.editorOverlayElements.innerHTML = '';
                    const changeModeEvent = new CustomEvent("tsdk-view-mode-changed", {
                        bubbles: true,
                        composed: true,
                        detail: {
                            editorId: editorInstance.editorId,
                            mode: constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL
                        }
                    });
                    document.dispatchEvent(changeModeEvent);
                };
                editorInstance.components.trackChangesEditorOverlayInstance.showLoader();
                editorInstance.pauseGEC = true;
                processMapInBatches(alertToProcess, 20, 5000);
                break;
            }
            default: {
                console.log('[DEBUG] action is not supported');
                break;
            }
        }
    }
    handleSentenceWiseUserActionsEvent($event) {
        if (!$event.detail.editorId || !$event.detail.sentenceId || !$event.detail.action)
            return;
        const editorInstance = this.grammarUI.listOfEditors[$event.detail.editorId];
        const index = $event.detail.index;
        const sentenceId = $event.detail.sentenceId;
        const segmentId = $event.detail.segmentId;
        const action = $event.detail.action;
        const alerts = editorInstance.sentenceToAlertsMap.get(sentenceId) || {};
        const sentence = editorInstance.segmentToSentencesMap.get(segmentId)[sentenceId];
        const segment = Object.keys(editorInstance.editorDOMTreeNodes)
            .filter(segment => editorInstance.editorDOMTreeNodes[segment].segmentId === segmentId)
            .map(segment => editorInstance.editorDOMTreeNodes[segment])[0];
        const sortedAlerts = Object.keys(alerts).sort((a, b) => {
            return alerts[a].begin - alerts[b].begin;
        });
        if (!sortedAlerts.length)
            return;
        switch (action) {
            case 'accept-all': {
                const index = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.findIndex(alert => alert.sentenceId === sentenceId);
                if (index !== -1) {
                    editorInstance.components.draggablePanel.sentenceWiseCloseAllCards();
                    const sentenceHighlighter = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.splice(index, 1)[0];
                    sentenceHighlighter.sentenceHighlighter.destroy();
                    editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList];
                }
                sortedAlerts.reverse().forEach((alertId, currentIndex) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                    const suggestionIndex = 0;
                    const alert = alerts[alertId];
                    const alertType = alert.suggestions[suggestionIndex].type;
                    let payload = Object.assign({}, alert.suggestions[suggestionIndex]);
                    payload.correction = alert.suggestions[suggestionIndex].suggestion;
                    payload.text = alert.text;
                    payload.sentence = sentence.sentence;
                    payload.language = ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language) || 'US';
                    payload.langCode = ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode) || 'en';
                    if (alert.suggestions[suggestionIndex]["spell_status"] == 'conf') {
                        payload.type = 2;
                    }
                    if (alertType === 3) {
                        editorInstance.socketClient.acceptnoncta(payload, ((_e = this.trinkaConfig) === null || _e === void 0 ? void 0 : _e.connectionType) === 'api');
                    }
                    else {
                        editorInstance.socketClient.accept(payload, ((_f = this.trinkaConfig) === null || _f === void 0 ? void 0 : _f.connectionType) === 'api');
                    }
                    if (editorInstance.underlines.has(alertId)) {
                        if (alertType === 3) {
                            editorInstance.underlines.get(alertId).remove();
                        }
                        else {
                            if (currentIndex === sortedAlerts.length - 1) {
                                editorInstance.underlines.get(alertId).acceptAlert(suggestionIndex, true);
                            }
                            else {
                                editorInstance.underlines.get(alertId).acceptAlert(suggestionIndex, false);
                            }
                            if (!((_g = editorInstance === null || editorInstance === void 0 ? void 0 : editorInstance.editor) === null || _g === void 0 ? void 0 : _g.ckeditorInstance)) {
                                editorInstance.underlines.get(alertId).setCursorPosition({}, 'at-end-after-replace', suggestionIndex);
                                editorInstance.editor.focus();
                            }
                        }
                        (0, utils_1.addEventToTracingLog)('accept_grammar');
                        this.removeAlertById(editorInstance, alertId);
                        this.grammarUI.applyFilter(editorInstance, true);
                        this.mappingCleanup(editorInstance);
                    }
                    if (this.hostPageInfo.isCloudTrinka) {
                        this.sendAmplitudeEvent('grammar_accept', {
                            'category': alert.suggestions[suggestionIndex].error_category,
                            'language': (_j = (_h = this.grammarModuleConfig) === null || _h === void 0 ? void 0 : _h.trinkaEditorConfig) === null || _j === void 0 ? void 0 : _j.language,
                            'lang_code': (_l = (_k = this.grammarModuleConfig) === null || _k === void 0 ? void 0 : _k.trinkaEditorConfig) === null || _l === void 0 ? void 0 : _l.langCode,
                            'strictness': alert.strictness,
                            "doc_type": this.documentTypeList[(_o = (_m = this.grammarModuleConfig) === null || _m === void 0 ? void 0 : _m.trinkaEditorConfig) === null || _o === void 0 ? void 0 : _o.document_type],
                            'model': (_q = (_p = this.grammarModuleConfig.trinkaEditorConfig) === null || _p === void 0 ? void 0 : _p.instructionModule) === null || _q === void 0 ? void 0 : _q.module
                        });
                    }
                });
                editorInstance.components.draggablePanel.blockOrUnblockSentenceWiseAlertsPanel(true);
                setTimeout(() => {
                    editorInstance.components.draggablePanel.blockOrUnblockSentenceWiseAlertsPanel(false);
                    if (!editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[index])
                        return;
                    const nextCardId = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[index].sentenceId;
                    editorInstance.components.draggablePanel.sentenceWiseToggleCardState(nextCardId);
                }, 4000);
                break;
            }
            case 'reject-all': {
                const index = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.findIndex(alert => alert.sentenceId === sentenceId);
                if (index !== -1) {
                    editorInstance.components.draggablePanel.sentenceWiseCloseAllCards();
                    const sentenceHighlighter = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.splice(index, 1)[0];
                    sentenceHighlighter.sentenceHighlighter.destroy();
                    editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList];
                }
                sortedAlerts.reverse().forEach(alertId => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                    const suggestionIndex = 0;
                    const alert = alerts[alertId];
                    let payload = Object.assign({}, alert.suggestions[suggestionIndex]);
                    payload.correction = alert.suggestions[suggestionIndex].suggestion;
                    payload.text = alert.text;
                    payload.sentence = sentence.sentence;
                    payload.language = ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language) || 'US';
                    payload.langCode = ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode) || 'en';
                    if (alert.suggestions[suggestionIndex]["spell_status"] == 'conf') {
                        payload.type = 2;
                    }
                    if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                        editorInstance.socketClient.reject(payload, ((_e = this.trinkaConfig) === null || _e === void 0 ? void 0 : _e.connectionType) === 'api');
                    }
                    else {
                        const searchKey = `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                        editorInstance.socketClient.reject(payload, ((_f = this.trinkaConfig) === null || _f === void 0 ? void 0 : _f.connectionType) === 'api');
                        this.cachingService.deleteCheckResponse(searchKey);
                    }
                    if (editorInstance.underlines.has(alertId)) {
                        editorInstance.underlines.get(alertId).rejectAlert();
                        this.removeAlertById(editorInstance, alertId);
                        this.grammarUI.applyFilter(editorInstance, true);
                        this.mappingCleanup(editorInstance);
                    }
                    this.sendAmplitudeEvent('grammar_reject', {
                        'error_text': alert.text,
                        'suggestion': alert.suggestions[suggestionIndex].suggestion,
                        'category': alert.suggestions[suggestionIndex].error_category,
                        'language': (_h = (_g = this.grammarModuleConfig) === null || _g === void 0 ? void 0 : _g.trinkaEditorConfig) === null || _h === void 0 ? void 0 : _h.language,
                        'lang_code': (_k = (_j = this.grammarModuleConfig) === null || _j === void 0 ? void 0 : _j.trinkaEditorConfig) === null || _k === void 0 ? void 0 : _k.langCode,
                        'strictness': alert.strictness,
                        "doc_type": this.documentTypeList[this.grammarModuleConfig.trinkaEditorConfig.document_type],
                        'model': this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module
                    });
                });
                setTimeout(() => {
                    if (!editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[index])
                        return;
                    const nextCardId = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[index].sentenceId;
                    editorInstance.components.draggablePanel.sentenceWiseToggleCardState(nextCardId);
                }, 0);
                break;
            }
            default:
                break;
        }
    }
    registerEventListeners() {
        document.addEventListener('tsdk-editor-input-event', this.boundHandleInputEvent);
        document.addEventListener('tsdk-overlay-user-action', this.boundHandleUserActionsEvent);
        document.addEventListener('tsdk-sentence-wise-user-action', this.boundHandleSentenceWiseUserActionsEvent);
        document.addEventListener('tsdk-draggable-panel-track-changes-action', this.boundHandleTrackChangesUserActionsEvent);
        document.addEventListener('tsdk-update-writing-model-n-instruction', this.boundHandleWritingModeUpdateEvent);
        document.addEventListener('tsdk-draggable-panel-sentence-wise-card-single-reject', this.boundHandleDraggablePanelSentenceWiseCardSingleRejectEvent);
        document.addEventListener('tsdk-grouped-alert-event', this.boundGroupedAlertEvent);
    }
    removeEventListeners() {
        document.removeEventListener('tsdk-editor-input-event', this.boundHandleInputEvent);
        document.removeEventListener('tsdk-overlay-user-action', this.boundHandleUserActionsEvent);
        document.removeEventListener('tsdk-sentence-wise-user-action', this.boundHandleSentenceWiseUserActionsEvent);
        document.removeEventListener('tsdk-draggable-panel-track-changes-action', this.boundHandleTrackChangesUserActionsEvent);
        document.removeEventListener('tsdk-update-writing-model-n-instruction', this.boundHandleWritingModeUpdateEvent);
        document.removeEventListener('tsdk-draggable-panel-sentence-wise-card-single-reject', this.boundHandleDraggablePanelSentenceWiseCardSingleRejectEvent);
        document.removeEventListener('tsdk-grouped-alert-event', this.boundGroupedAlertEvent);
    }
    startAllEditorScanning() {
        Object.keys(this.grammarUI.listOfEditors).forEach(editorId => {
            const editorInstance = this.grammarUI.listOfEditors[editorId];
            this.startEditorScanning(editorInstance);
        });
    }
    async startEditorScanning(editorInstance) {
        editorInstance.components.draggablePanel.isEditorEmpty = (0, utils_1.checkIfEditorIsEmpty)(editorInstance.editor);
        switch (editorInstance.editorType) {
            case 'input':
            case 'textarea': {
                break;
            }
            case 'body':
            case 'div': {
                if (editorInstance.pauseGEC)
                    return;
                if (this.grammarUI.currentSelectedMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE) {
                    this.grammarUI.refreshSentenceWiseAlert(editorInstance);
                }
                this.generateEditorDOMStructure(editorInstance);
                if (!Object.keys(editorInstance.editorDOMTreeNodes).length || editorInstance.components.draggablePanel.isEditorEmpty) {
                    this.grammarUI.removeEditorUnderLines(editorInstance);
                    editorInstance.components.draggablePanel.alertList = [];
                    editorInstance.segmentToSentencesMap.clear();
                    editorInstance.sentenceToAlertsMap.clear();
                    this.grammarUI.applyFilter(editorInstance);
                    this.mappingCleanup(editorInstance);
                    this.grammarUI.hideLoader(editorInstance);
                    editorInstance.editorDOMTreeNodes = {};
                    this.mappingCleanup(editorInstance);
                    editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.forEach(alert => {
                        alert.sentenceHighlighter.destroy();
                    });
                    editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [];
                    editorInstance.components.sentenceWiseEditorOverlayInstance.changeOverlayVisibility(true);
                    editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements.innerHTML = '';
                    return;
                }
                const isScanRequired = !!Object.values(editorInstance.editorDOMTreeNodes).filter((node) => node.updateRequired).length;
                if (!isScanRequired) {
                    Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                        if (!editorInstance.latestContenteditableDOM[text]) {
                            this.removeExistingSegment(editorInstance, text);
                        }
                    });
                    return;
                }
                if (editorInstance.isScanningInProgress) {
                    editorInstance.changeDetectionQueue.push({ id: editorInstance.changeDetectionQueue.length + 1 });
                    return;
                }
                editorInstance.isScanningInProgress = true;
                editorInstance.socketClient.waitForSocketOpen()
                    .then(async () => {
                    if (!this.hostPageInfo.isCloudTrinka) {
                        await this.apiSplitAndCheckInBatchV2(editorInstance);
                        editorInstance.isScanningInProgress = false;
                    }
                    else {
                        await this.splitAndCheckInBatchV2(editorInstance);
                        editorInstance.isScanningInProgress = false;
                    }
                    if (editorInstance.changeDetectionQueue.length) {
                        editorInstance.changeDetectionQueue = [];
                        this.startEditorScanning(editorInstance);
                    }
                })
                    .catch((error) => {
                    this.grammarUI.hideLoader(editorInstance);
                    console.log('Error waiting for socket to open:', error);
                });
                break;
            }
        }
    }
    async splitAndCheck(editorInstance, node) {
        const alerts = [];
        return new Promise((resolve, reject) => {
            const payload = {
                text: node.text,
                language: this.grammarModuleConfig.languagepref ? this.grammarModuleConfig.languagepref.toUpperCase() : 'US',
                request_id: node.segmentId
            };
            this.cachingService.splitAndCheck(node.text)
                .then(async (data) => {
                if (!data) {
                    data = await editorInstance.socketClient.splitAndCheck(payload);
                    this.cachingService.setSplitAndCheckResponse(payload.text, data);
                }
                node.updateRequired = false;
                if (!editorInstance.segmentToSentencesMap.has(node.segmentId)) {
                    editorInstance.segmentToSentencesMap.set(node.segmentId, {});
                }
                data.response.forEach((sentence) => {
                    const sentenceId = (0, utils_1.generateRandomId)(10);
                    const newSentence = Object.assign(Object.assign({}, sentence), { sentenceId, alerts: {} });
                    editorInstance.segmentToSentencesMap.get(node.segmentId)[sentenceId] = newSentence;
                    if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                        editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                    }
                    sentence.result.forEach((alert) => {
                        const alertId = (0, utils_1.generateRandomId)(10);
                        const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: node.segmentId, sentencePosition: sentence.begin, segmentPosition: node.position, sentenceText: sentence.sentence });
                        editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                        alerts.push(newAlert);
                    });
                });
                resolve(alerts);
            })
                .catch((error) => {
                resolve(alerts);
                console.log('Error processing node:', node, error);
            });
        });
    }
    refreshUnderlines(editorId) {
        if (!editorId)
            return;
        const editorInstance = this.grammarUI.listOfEditors[editorId];
        if (!editorInstance)
            return;
        const alerts = Array.from(editorInstance.sentenceToAlertsMap.values());
        this.grammarUI.removeEditorUnderLines(editorInstance);
        editorInstance.components.draggablePanel.alertList = Array.from(editorInstance.sentenceToAlertsMap.values())
            .flatMap(obj => Object.values(obj));
        this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
        this.grammarUI.applyFilter(editorInstance);
    }
    async apiSplitAndCheckInBatch(editorInstance) {
        const treeNodes = Object.keys(editorInstance.editorDOMTreeNodes)
            .filter(segment => editorInstance.editorDOMTreeNodes[segment].updateRequired && !editorInstance.socketClient.isRequestIsInProgress(editorInstance.editorDOMTreeNodes[segment].segmentId));
        for (let i = 0; i < treeNodes.length; i += constants_1.CONCURRENCY) {
            const batch = treeNodes.slice(i, i + constants_1.CONCURRENCY);
            const promises = batch.map(segment => this.splitAndCheck(editorInstance, editorInstance.editorDOMTreeNodes[segment]));
            const batchResults = await Promise.all(promises);
            const finalAlerts = batchResults.reduce((acc, current) => acc.concat(current), []);
            editorInstance.components.draggablePanel.alertList = [
                ...editorInstance.components.draggablePanel.alertList,
                ...finalAlerts
            ];
            editorInstance.components.draggablePanel.requestUpdate();
            await this.grammarUI.renderEditorUnderLines(editorInstance, finalAlerts);
            this.grammarUI.applyFilter(editorInstance);
        }
    }
    async scanCache(listOfSearchKey) {
        const cache = {
            cached: [],
            notCached: [],
        };
        for (const text of Object.keys(listOfSearchKey)) {
            const existingEntry = await this.cachingService.getSplitAndCheckResponse(text);
            if (!existingEntry) {
                cache.notCached.push(text);
            }
            else {
                cache.cached.push(text);
            }
        }
        return cache;
    }
    async apiSplitAndCheckInBatchV2(editorInstance) {
        editorInstance.sendJsonRpcRequest('waitForDBOpen')
            .then((waitForDBOpenResult) => {
            editorInstance.sendJsonRpcRequest('scanCache', {
                storeName: 'sac',
                indexKey: 'text',
                listOfSearchKey: Object.keys(editorInstance.editorDOMTreeNodes)
            }).then((scanCacheResult) => {
                editorInstance.sendJsonRpcRequest('waitForSocketOpen')
                    .then((waitForSocketOpenResult) => {
                    editorInstance.sendJsonRpcRequest('getCallbackMapper')
                        .then(async (getCallbackMapperResult) => {
                        const cachedTreeNodes = Object.keys(editorInstance.editorDOMTreeNodes)
                            .filter(segment => editorInstance.editorDOMTreeNodes[segment].updateRequired &&
                            scanCacheResult.cached.some(item => item.sentence === segment));
                        if (cachedTreeNodes.length) {
                            this.grammarUI.showLoader(editorInstance);
                        }
                        for (let i = 0; i < cachedTreeNodes.length; i++) {
                            const segment = editorInstance.editorDOMTreeNodes[cachedTreeNodes[i]];
                            const cachedSegment = scanCacheResult.cached.find(item => item.sentence === cachedTreeNodes[i]);
                            segment.updateRequired = false;
                            if (!editorInstance.segmentToSentencesMap.has(segment.segmentId)) {
                                editorInstance.segmentToSentencesMap.set(segment.segmentId, {});
                            }
                            cachedSegment.response.forEach((sentence) => {
                                const sentenceId = (0, utils_1.generateRandomId)(10);
                                const newSentence = Object.assign(Object.assign({}, sentence), { sentenceId, alerts: {} });
                                editorInstance.segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                                    editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                                }
                                sentence.result.forEach((alert) => {
                                    const alertId = (0, utils_1.generateRandomId)(10);
                                    const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                    editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                    editorInstance.components.draggablePanel.alertList.push(newAlert);
                                });
                            });
                        }
                        this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
                        this.grammarUI.applyFilter(editorInstance);
                        if (cachedTreeNodes.length) {
                            this.grammarUI.hideLoader(editorInstance);
                        }
                        const treeNodes = Object.keys(editorInstance.editorDOMTreeNodes)
                            .filter(segment => editorInstance.editorDOMTreeNodes[segment].updateRequired &&
                            !getCallbackMapperResult.includes(`split_check_reqest-${editorInstance.editorDOMTreeNodes[segment].segmentId}`) &&
                            scanCacheResult.notCached.some(item => item.searchKey === segment));
                        if (treeNodes.length) {
                            this.grammarUI.showLoader(editorInstance);
                        }
                        for (let i = 0; i < treeNodes.length; i++) {
                            const segment = editorInstance.editorDOMTreeNodes[treeNodes[i]];
                            const payload = {
                                text: segment.text,
                                language: this.grammarModuleConfig.languagepref ? this.grammarModuleConfig.languagepref.toUpperCase() : 'US',
                                request_id: segment.segmentId
                            };
                            editorInstance.sendJsonRpcRequest('splitAndCheck', payload)
                                .then((splitAndCheckResult) => {
                                this.cachingService.setSplitAndCheckResponse(payload.text, splitAndCheckResult);
                                segment.updateRequired = false;
                                if (!editorInstance.segmentToSentencesMap.has(segment.segmentId)) {
                                    editorInstance.segmentToSentencesMap.set(segment.segmentId, {});
                                }
                                splitAndCheckResult.response.forEach(async (sentence) => {
                                    const sentenceId = (0, utils_1.generateRandomId)(10);
                                    const newSentence = Object.assign(Object.assign({}, sentence), { sentenceId, alerts: {} });
                                    editorInstance.segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                    if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                                        editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                                    }
                                    sentence.result.forEach((alert) => {
                                        const alertId = (0, utils_1.generateRandomId)(10);
                                        const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                        editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                        editorInstance.components.draggablePanel.alertList.push(newAlert);
                                    });
                                    Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                                        if (!editorInstance.latestContenteditableDOM[text]) {
                                            this.removeExistingSegment(editorInstance, text);
                                        }
                                    });
                                    this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
                                    this.grammarUI.applyFilter(editorInstance);
                                });
                            })
                                .catch(error => console.log('Error:', error));
                        }
                    })
                        .catch(error => {
                        console.log('[DEBUG] getCallbackMapper error ===> ', error);
                    });
                })
                    .catch(error => console.log('Error:', error));
            }).catch(error => {
                console.log('[DEBUG] scanCache error ===> ', error);
            });
        });
    }
    async splitAndCheckSegment(editorInstance, segmentId) {
        const segment = Object.keys(editorInstance.editorDOMTreeNodes)
            .filter((key) => editorInstance.editorDOMTreeNodes[key].segmentId === segmentId)
            .map((key) => editorInstance.editorDOMTreeNodes[key])[0];
        let alerts = [];
        const sentences = await this.splitSegment(editorInstance, segment);
        let checkPromises = sentences.map(sentence => this.checkSentence(editorInstance, sentence));
        let checkResults = await Promise.allSettled(checkPromises);
        alerts = checkResults.reduce((acc, result) => {
            if (result.status === 'fulfilled') {
                acc.push(...result.value);
            }
            return acc;
        }, []);
        const oldSegmentAlerts = editorInstance.components.draggablePanel.alertList.filter(alert => alert.segmentId === segmentId);
        oldSegmentAlerts.forEach(alert => {
            this.grammarUI.removeUnderLineById(editorInstance, alert.alertId);
            this.removeAlertById(editorInstance, alert.alertId);
        });
        setTimeout(async () => {
            editorInstance.components.draggablePanel.alertList = [
                ...editorInstance.components.draggablePanel.alertList,
                ...alerts
            ];
            editorInstance.components.draggablePanel.requestUpdate();
            await this.grammarUI.renderEditorUnderLines(editorInstance, alerts);
            this.grammarUI.applyFilter(editorInstance);
        }, 1000);
    }
    async splitAndCheckInBatch(editorInstance) {
        const processNode = async (node) => {
            let alerts = [];
            try {
                const sentences = await this.splitSegment(editorInstance, node);
                let checkPromises = sentences.map(sentence => this.checkSentence(editorInstance, sentence));
                let checkResults = await Promise.allSettled(checkPromises);
                alerts = checkResults.reduce((acc, result) => {
                    if (result.status === 'fulfilled') {
                        acc.push(...result.value);
                    }
                    return acc;
                }, []);
                return alerts;
            }
            catch (error) {
                console.log('Error processing node:', node, error);
                return alerts;
            }
        };
        const treeNodes = Object.keys(editorInstance.editorDOMTreeNodes)
            .filter(segment => editorInstance.editorDOMTreeNodes[segment].updateRequired && !editorInstance.socketClient.isRequestIsInProgress(editorInstance.editorDOMTreeNodes[segment].segmentId));
        for (let i = 0; i < treeNodes.length; i += constants_1.CONCURRENCY) {
            const batch = treeNodes.slice(i, i + constants_1.CONCURRENCY);
            const promises = batch.map(segment => processNode(editorInstance.editorDOMTreeNodes[segment]));
            const batchResults = await Promise.all(promises);
            const finalAlerts = batchResults.reduce((acc, current) => acc.concat(current), []);
            editorInstance.components.draggablePanel.alertList = [
                ...editorInstance.components.draggablePanel.alertList,
                ...finalAlerts
            ];
            editorInstance.components.draggablePanel.requestUpdate();
            await this.grammarUI.renderEditorUnderLines(editorInstance, finalAlerts);
            this.grammarUI.applyFilter(editorInstance);
        }
    }
    incrementCounterByType(mappings, type) {
        let mapping = mappings.find(item => item.type === type);
        if (mapping) {
            mapping.counter += 1;
        }
    }
    updateSentenceWiseList(editorInstance, sentence, segment) {
        const sentenceId = sentence.sentenceId;
        sentence.alerts = editorInstance.sentenceToAlertsMap.get(sentenceId) || {};
        if (!Object.keys(sentence.alerts).length)
            return;
        const sentenceHighlighter = new sentence_highlighter_1.SentenceHighlighter(editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements, editorInstance.components.draggablePanel, sentence, segment);
        let sentenceText = sentence.sentence;
        let newSentence = `<sentence id="${sentenceId}" data-begin="${sentence.begin}" data-end="${sentence.end}" selected="false">[[SENTENCE-PLACEHOLDER]]</sentence>`;
        let alerts = Object.keys(sentence.alerts);
        const mapping = [
            { type: 1, category: 'Grammar', counter: 0 },
            { type: 2, category: 'Spelling', counter: 0 },
            { type: 3, category: 'Writing Advisor', counter: 0 },
            { type: 4, category: 'Enhancement', counter: 0 },
            { type: 5, category: 'Style Guide', counter: 0 },
            { type: 10, category: 'Inclusive Language', counter: 0 },
            { type: 11, category: 'Legal Writing', counter: 0 }
        ];
        const sortedAlerts = alerts.sort((a, b) => {
            return sentence.alerts[a].begin - sentence.alerts[b].begin;
        });
        sortedAlerts.slice().reverse().forEach(alert_uuid => {
            let alert = sentence.alerts[alert_uuid];
            let isReplacementAvailable = alert.suggestions[0].cta_present && alert.suggestions[0].suggestion;
            let isDelete = alert.suggestions[0].cta_present && !alert.suggestions[0].suggestion;
            sentenceText = [
                sentenceText.slice(0, alert.begin),
                `<alert alert-id="${alert_uuid}">`,
                (isReplacementAvailable || isDelete ? `<strikethrough-text>${sentenceText.slice(alert.begin, alert.end + 1)}</strikethrough-text> ${isDelete ? '' : `<replacement-text>${alert.suggestions[0].suggestion}</replacement-text>`}` : sentenceText.slice(alert.begin, alert.end + 1)),
                '</alert>',
                sentenceText.slice(alert.end + 1, sentenceText.length)
            ].join('');
            this.incrementCounterByType(mapping, alert.suggestions[0].type);
        });
        const sentenceWithAlerts = newSentence.replace('[[SENTENCE-PLACEHOLDER]]', sentenceText);
        editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.push(Object.assign(Object.assign({}, sentence), { segmentId: segment.segmentId, segmentPosition: segment.position, sentenceWithAlerts, alertsCount: mapping.filter(category => category.counter), sentenceHighlighter }));
        editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList]
            .sort((a, b) => {
            if (a.segmentPosition !== b.segmentPosition) {
                return a.segmentPosition - b.segmentPosition;
            }
            else {
                return a.begin - b.begin;
            }
        });
        const fragment = document.createDocumentFragment();
        sentenceHighlighter.highlighters.forEach(highlighter => {
            fragment.appendChild(highlighter.highlightContainer);
        });
        setTimeout(() => {
            editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements.appendChild(fragment);
        }, 0);
    }
    mappingCleanup(editorInstance) {
        function filterMappingInPlace(map, validIds) {
            let deletedSentences = [];
            map.forEach((_, key) => {
                if (!validIds.includes(key)) {
                    const segmentValue = map.get(key);
                    Object.keys(segmentValue).forEach(sentenceId => {
                        deletedSentences.push(sentenceId);
                    });
                    map.delete(key);
                }
                else {
                    const segmentWiseSentenceAlerts = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.filter(sentenceAlert => sentenceAlert.segmentId === key);
                    segmentWiseSentenceAlerts.forEach(sentence => {
                        if (!editorInstance.segmentToSentencesMap.get(key)[sentence.sentenceId]) {
                            deletedSentences.push(sentence.sentenceId);
                        }
                    });
                }
            });
            deletedSentences.forEach(sentenceId => {
                editorInstance.sentenceToAlertsMap.delete(sentenceId);
                const index = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.findIndex(alert => alert.sentenceId === sentenceId);
                if (index !== -1) {
                    const sentenceHighlighter = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.splice(index, 1)[0];
                    sentenceHighlighter.sentenceHighlighter.destroy();
                    editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList]
                        .sort((a, b) => {
                        if (a.segmentPosition !== b.segmentPosition) {
                            return a.segmentPosition - b.segmentPosition;
                        }
                        else {
                            return a.begin - b.begin;
                        }
                    });
                }
            });
        }
        const validIds = Object.keys(editorInstance.editorDOMTreeNodes).map(segment => editorInstance.editorDOMTreeNodes[segment].segmentId);
        filterMappingInPlace(editorInstance.segmentToSentencesMap, validIds);
        this.grammarUI.refreshSentenceWiseAlert(editorInstance);
    }
    async splitAndCheckInBatchV2(editorInstance) {
        editorInstance.sendJsonRpcRequest('waitForDBOpen')
            .then((waitForDBOpenResult) => {
            editorInstance.sendJsonRpcRequest('scanCache', {
                storeName: 'spt',
                indexKey: 'searchKey',
                listOfSearchKey: Object.keys(editorInstance.editorDOMTreeNodes).map(segment => `${segment}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`)
            }).then((scanCacheResult) => {
                editorInstance.sendJsonRpcRequest('waitForSocketOpen')
                    .then((waitForSocketOpenResult) => {
                    editorInstance.sendJsonRpcRequest('getCallbackMapper')
                        .then(async (getCallbackMapperResult) => {
                        const cachedTreeNodes = Object.keys(editorInstance.editorDOMTreeNodes)
                            .filter(segment => editorInstance.editorDOMTreeNodes[segment].updateRequired &&
                            scanCacheResult.cached.some(item => item.text === segment));
                        if (cachedTreeNodes.length) {
                            this.grammarUI.showLoader(editorInstance);
                        }
                        this.setSegmentDeletedFlag(editorInstance);
                        for (let i = 0; i < cachedTreeNodes.length; i++) {
                            const segment = editorInstance.editorDOMTreeNodes[cachedTreeNodes[i]];
                            const cachedSegment = scanCacheResult.cached.find(item => item.text === cachedTreeNodes[i]);
                            const searchKey = `${segment.text}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`;
                            segment.updateRequired = false;
                            editorInstance.segmentToSentencesMap.set(segment.segmentId, {});
                            editorInstance.sendJsonRpcRequest('scanCache', {
                                storeName: 'ck',
                                indexKey: 'searchKey',
                                listOfSearchKey: cachedSegment.response.map(sentence => `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`)
                            }).then((scanCacheCheckResult) => {
                                const cachedSentences = scanCacheCheckResult.cached
                                    .filter(sentence => cachedSegment.response.some(item => sentence.sentence === item.sentence));
                                cachedSentences.forEach((cachedSentence) => {
                                    var _a;
                                    const sentence = cachedSegment.response.find(item => item.sentence === cachedSentence.sentence);
                                    const sentenceId = (0, utils_1.generateRandomId)(10);
                                    const newSentence = Object.assign(Object.assign({ alerts: {} }, sentence), { sentenceId, segmentId: segment.segmentId });
                                    editorInstance.segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                    if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                                        editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                                    }
                                    (_a = cachedSentence === null || cachedSentence === void 0 ? void 0 : cachedSentence.response) === null || _a === void 0 ? void 0 : _a.forEach((alert) => {
                                        const alertId = (0, utils_1.generateRandomId)(10);
                                        const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                        editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                        editorInstance.components.draggablePanel.alertList.push(newAlert);
                                    });
                                    this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
                                    this.grammarUI.applyFilter(editorInstance);
                                    if (this.grammarUI.currentSelectedMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE) {
                                        this.updateSentenceWiseList(editorInstance, newSentence, segment);
                                        this.mappingCleanup(editorInstance);
                                    }
                                });
                                const notCachedSentences = cachedSegment.response
                                    .filter(sentence => scanCacheCheckResult.notCached.some(item => `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}` === item.searchKey));
                                const promises = [];
                                notCachedSentences.forEach((sentence) => {
                                    const sentenceId = (0, utils_1.generateRandomId)(10);
                                    const newSentence = Object.assign(Object.assign({ alerts: {} }, sentence), { sentenceId, segmentId: segment.segmentId });
                                    editorInstance.segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                    const checkPayload = {
                                        type: 'check',
                                        text: newSentence.sentence,
                                        language: this.grammarModuleConfig.trinkaEditorConfig.language,
                                        style_guide: this.grammarModuleConfig.trinkaEditorConfig.style_guide.value,
                                        request_id: sentenceId,
                                        source: this.grammarModuleConfig.trinkaEditorConfig.source,
                                        inclusive_lang: this.grammarModuleConfig.trinkaEditorConfig.inclusive_lang,
                                        langCode: this.grammarModuleConfig.trinkaEditorConfig.langCode,
                                        doc_type: this.grammarModuleConfig.trinkaEditorConfig.document_type,
                                        model: this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module,
                                        instruction: this.grammarModuleConfig.trinkaEditorConfig.instructionModule.instruction
                                    };
                                    const searchKey = `${newSentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                                    const promise = editorInstance.sendJsonRpcRequest('check', checkPayload)
                                        .then(async (checkResult) => {
                                        var _a, _b;
                                        if (((_a = checkResult === null || checkResult === void 0 ? void 0 : checkResult.response) === null || _a === void 0 ? void 0 : _a.length) == 0)
                                            this.handleControlMessages(editorInstance, checkResult);
                                        else
                                            this.cachingService.setCheckResponse(searchKey, checkResult);
                                        if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                                            editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                                        }
                                        (_b = checkResult === null || checkResult === void 0 ? void 0 : checkResult.response) === null || _b === void 0 ? void 0 : _b.forEach((alert) => {
                                            const alertId = (0, utils_1.generateRandomId)(10);
                                            const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                            editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                            editorInstance.components.draggablePanel.alertList.push(newAlert);
                                        });
                                        if (this.grammarUI.currentSelectedMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE) {
                                            this.updateSentenceWiseList(editorInstance, newSentence, segment);
                                            this.mappingCleanup(editorInstance);
                                        }
                                    })
                                        .catch(error => console.log('Error:', error));
                                    promises.push(promise);
                                });
                                Promise.allSettled(promises).then(() => {
                                    Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                                        if (!editorInstance.latestContenteditableDOM[text]) {
                                            this.removeExistingSegment(editorInstance, text);
                                        }
                                    });
                                    this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
                                    this.grammarUI.applyFilter(editorInstance);
                                    this.mappingCleanup(editorInstance);
                                });
                                if (cachedTreeNodes.length) {
                                    this.grammarUI.hideLoader(editorInstance);
                                }
                            }).catch(error => {
                                console.log('[DEBUG] scanCache error ===> ', error);
                            });
                        }
                        const treeNodes = Object.keys(editorInstance.editorDOMTreeNodes)
                            .filter(segment => editorInstance.editorDOMTreeNodes[segment].updateRequired &&
                            !getCallbackMapperResult.includes(`split-${editorInstance.editorDOMTreeNodes[segment].segmentId}`) &&
                            scanCacheResult.notCached.some(item => item.searchKey === `${segment}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`));
                        if (treeNodes.length) {
                            this.grammarUI.showLoader(editorInstance);
                        }
                        this.setSegmentDeletedFlag(editorInstance);
                        for (let i = 0; i < treeNodes.length; i++) {
                            const segment = editorInstance.editorDOMTreeNodes[treeNodes[i]];
                            const splitPayload = {
                                type: 'split',
                                text: segment.text,
                                request_id: segment.segmentId,
                                source: this.grammarModuleConfig.trinkaEditorConfig.source,
                                langCode: this.grammarModuleConfig.trinkaEditorConfig.langCode,
                                doc_type: this.grammarModuleConfig.trinkaEditorConfig.document_type,
                                style_guide: this.grammarModuleConfig.trinkaEditorConfig.style_guide.value
                            };
                            const searchKey = `${segment.text}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`;
                            editorInstance.sendJsonRpcRequest('split', splitPayload)
                                .then((splitResult) => {
                                this.cachingService.setSplitResponse(searchKey, splitResult);
                                segment.updateRequired = false;
                                editorInstance.segmentToSentencesMap.set(segment.segmentId, {});
                                editorInstance.sendJsonRpcRequest('scanCache', {
                                    storeName: 'ck',
                                    indexKey: 'searchKey',
                                    listOfSearchKey: splitResult.response.map(sentence => `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`)
                                }).then((scanCacheCheckResult) => {
                                    const cachedSentences = scanCacheCheckResult.cached;
                                    cachedSentences.forEach((cachedSentence) => {
                                        var _a;
                                        const sentence = splitResult.response.find(item => item.sentence === cachedSentence.sentence);
                                        const sentenceId = (0, utils_1.generateRandomId)(10);
                                        const newSentence = Object.assign(Object.assign({ alerts: {} }, sentence), { sentenceId, segmentId: segment.segmentId });
                                        editorInstance.segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                        if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                                            editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                                        }
                                        (_a = cachedSentence === null || cachedSentence === void 0 ? void 0 : cachedSentence.response) === null || _a === void 0 ? void 0 : _a.forEach((alert) => {
                                            const alertId = (0, utils_1.generateRandomId)(10);
                                            const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                            editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                            editorInstance.components.draggablePanel.alertList.push(newAlert);
                                        });
                                        this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
                                        this.grammarUI.applyFilter(editorInstance);
                                        if (this.grammarUI.currentSelectedMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE) {
                                            this.updateSentenceWiseList(editorInstance, newSentence, segment);
                                            this.mappingCleanup(editorInstance);
                                        }
                                    });
                                    const notCachedSentences = splitResult.response
                                        .filter(sentence => scanCacheCheckResult.notCached.some(item => `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}` === item.searchKey));
                                    const promises = [];
                                    notCachedSentences.forEach((sentence) => {
                                        const sentenceId = (0, utils_1.generateRandomId)(10);
                                        const newSentence = Object.assign(Object.assign({ alerts: {} }, sentence), { sentenceId, segmentId: segment.segmentId });
                                        editorInstance.segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                        const checkPayload = {
                                            type: 'check',
                                            text: newSentence.sentence,
                                            language: this.grammarModuleConfig.trinkaEditorConfig.language,
                                            style_guide: this.grammarModuleConfig.trinkaEditorConfig.style_guide.value,
                                            request_id: sentenceId,
                                            source: this.grammarModuleConfig.trinkaEditorConfig.source,
                                            inclusive_lang: this.grammarModuleConfig.trinkaEditorConfig.inclusive_lang,
                                            langCode: this.grammarModuleConfig.trinkaEditorConfig.langCode,
                                            doc_type: this.grammarModuleConfig.trinkaEditorConfig.document_type,
                                            model: this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module,
                                            instruction: this.grammarModuleConfig.trinkaEditorConfig.instructionModule.instruction
                                        };
                                        const searchKey = `${newSentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                                        const promise = editorInstance.sendJsonRpcRequest('check', checkPayload)
                                            .then((checkResult) => {
                                            var _a, _b;
                                            if (((_a = checkResult === null || checkResult === void 0 ? void 0 : checkResult.response) === null || _a === void 0 ? void 0 : _a.length) == 0)
                                                this.handleControlMessages(editorInstance, checkResult);
                                            else
                                                this.cachingService.setCheckResponse(searchKey, checkResult);
                                            if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                                                editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                                            }
                                            (_b = checkResult === null || checkResult === void 0 ? void 0 : checkResult.response) === null || _b === void 0 ? void 0 : _b.forEach((alert) => {
                                                const alertId = (0, utils_1.generateRandomId)(10);
                                                const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                                editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                                editorInstance.components.draggablePanel.alertList.push(newAlert);
                                            });
                                            if (this.grammarUI.currentSelectedMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE) {
                                                this.updateSentenceWiseList(editorInstance, newSentence, segment);
                                                this.mappingCleanup(editorInstance);
                                            }
                                        })
                                            .catch(error => console.log('Error:', error));
                                        promises.push(promise);
                                    });
                                    Promise.allSettled(promises).then(() => {
                                        Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                                            if (!editorInstance.latestContenteditableDOM[text]) {
                                                this.removeExistingSegment(editorInstance, text);
                                            }
                                        });
                                        this.grammarUI.renderEditorUnderLines(editorInstance, editorInstance.components.draggablePanel.alertList);
                                        this.grammarUI.applyFilter(editorInstance);
                                        this.mappingCleanup(editorInstance);
                                    });
                                }).catch(error => {
                                    console.log('[DEBUG] scanCache error ===> ', error);
                                });
                            })
                                .catch(error => console.log('Error:', error));
                        }
                    })
                        .catch(error => console.log('Error:', error));
                })
                    .catch(error => console.log('Error:', error));
            }).catch(error => {
                console.log('[DEBUG] scanCache error ===> ', error);
            });
        });
    }
    async splitSegment(editorInstance, node) {
        let sentences = [];
        return new Promise((resolve, reject) => {
            const payload = {
                type: 'split',
                text: node.text,
                request_id: node.segmentId,
                source: this.grammarModuleConfig.trinkaEditorConfig.source,
                langCode: this.grammarModuleConfig.trinkaEditorConfig.langCode,
                doc_type: this.grammarModuleConfig.trinkaEditorConfig.document_type,
                style_guide: this.grammarModuleConfig.trinkaEditorConfig.style_guide.value
            };
            const searchKey = `${node.text}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`;
            this.cachingService.split(searchKey)
                .then(async (data) => {
                if (!data) {
                    data = await editorInstance.socketClient.split(payload);
                    this.cachingService.setSplitResponse(searchKey, data);
                }
                node.updateRequired = false;
                if (!editorInstance.segmentToSentencesMap.has(node.segmentId)) {
                    editorInstance.segmentToSentencesMap.set(node.segmentId, {});
                }
                data.response.forEach((sentence) => {
                    const sentenceId = (0, utils_1.generateRandomId)(10);
                    const newSentence = Object.assign(Object.assign({ alerts: {} }, sentence), { sentenceId, segmentId: node.segmentId });
                    editorInstance.segmentToSentencesMap.get(node.segmentId)[sentenceId] = newSentence;
                    sentences.push(newSentence);
                });
                resolve(sentences);
            })
                .catch((error) => {
                console.log('this.cachingService.split ===> ', error);
                resolve(sentences);
            });
        });
    }
    async checkSentence(editorInstance, sentence) {
        let alerts = [];
        return new Promise((resolve, reject) => {
            const segmentId = sentence.segmentId;
            const sentenceId = sentence.sentenceId;
            const text = sentence.sentence;
            const segment = (0, utils_1.findObjectById)(editorInstance.editorDOMTreeNodes, segmentId);
            if (editorInstance.socketClient.isRequestIsInProgress(sentenceId)) {
                resolve(alerts);
                return;
            }
            const payload = {
                type: 'check',
                text: text,
                language: this.grammarModuleConfig.trinkaEditorConfig.language,
                style_guide: this.grammarModuleConfig.trinkaEditorConfig.style_guide.value,
                request_id: sentenceId,
                source: this.grammarModuleConfig.trinkaEditorConfig.source,
                inclusive_lang: this.grammarModuleConfig.trinkaEditorConfig.inclusive_lang,
                langCode: this.grammarModuleConfig.trinkaEditorConfig.langCode,
                doc_type: this.grammarModuleConfig.trinkaEditorConfig.document_type,
                model: this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module.length > 0 ? this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module : "advanced",
                instruction: this.grammarModuleConfig.trinkaEditorConfig.instructionModule.instruction
            };
            const searchKey = `${text}#${this.generateSearchKeyForTrinkaConfig()}`;
            this.cachingService.check(searchKey)
                .then(async (data) => {
                var _a;
                if (!data) {
                    if (!this.stopRemainingRequests) {
                        data = await editorInstance.socketClient.check(payload);
                        if (data.response.length == 0)
                            this.handleControlMessages(editorInstance, data);
                        else
                            this.cachingService.setCheckResponse(searchKey, data);
                    }
                    else
                        resolve(alerts);
                }
                if (!editorInstance.sentenceToAlertsMap.has(sentenceId)) {
                    editorInstance.sentenceToAlertsMap.set(sentenceId, {});
                }
                (_a = data === null || data === void 0 ? void 0 : data.response) === null || _a === void 0 ? void 0 : _a.forEach((alert) => {
                    const alertId = (0, utils_1.generateRandomId)(10);
                    const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                    editorInstance.sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                    alerts.push(newAlert);
                });
                resolve(alerts);
            })
                .catch((error) => {
                console.log('this.cachingService.check ===> ', error);
                resolve(alerts);
            });
        });
    }
    handleControlMessages(editorInstance, data) {
        switch (data.message) {
            case constants_1.LIMIT_EXHAUSTED_MESSAGE:
                editorInstance.components.draggablePanel.limitExhausted = true;
                if (this.grammarModuleConfig.isBrowserExtension) {
                    editorInstance.components.limitExhaustBadge.showLimitExhaustBadge = true;
                    editorInstance.components.limitExhaustBadge.userType = this.grammarModuleConfig.trinkaEditorConfig.userType;
                    editorInstance.components.alertCounter.hideAlertCounter = true;
                }
                const limitExhaustEvent = new CustomEvent("limitExhausted", {
                    detail: {
                        isLimitExhausted: true,
                        fromClick: false
                    },
                });
                document.dispatchEvent(limitExhaustEvent);
                this.stopRemainingRequests = true;
                setTimeout(() => {
                    this.grammarUI.hideLoader(editorInstance);
                }, 2500);
                break;
            default:
                break;
        }
    }
    addNewSegment(editorInstance, childNode, position = 0, fileId) {
        const text = childNode.textContent.trimEnd() || '';
        editorInstance.editorDOMTreeNodeCounter++;
        const existingSement = this.cachingService.getSegment(fileId, text);
        const newSegment = {
            position,
            segmentId: (0, utils_1.generateRandomId)(10),
            nodeId: editorInstance.editorDOMTreeNodeCounter,
            nodeName: childNode.nodeName,
            node: childNode,
            displayType: (0, utils_1.getElementDefaultDisplay)(childNode),
            textNodes: this.extractTextNodes(childNode, []),
            updateRequired: true,
            text,
            duplicateSegments: []
        };
        editorInstance.editorDOMTreeNodes[text] = newSegment;
        return newSegment;
    }
    removeExistingSegment(editorInstance, text) {
        let removedAlerts = [];
        let segment = editorInstance.editorDOMTreeNodes[text];
        if (!editorInstance.segmentToSentencesMap.has(segment.segmentId))
            return;
        let lineTimeout;
        Object.keys(editorInstance.segmentToSentencesMap.get(segment.segmentId)).forEach(sentenceId => {
            if (!editorInstance.sentenceToAlertsMap.has(sentenceId))
                return;
            Object.keys(editorInstance.sentenceToAlertsMap.get(sentenceId)).forEach(alertId => {
                removedAlerts.push(alertId);
                this.grammarUI.removeUnderLineById(editorInstance, alertId);
            });
        });
        editorInstance.components.draggablePanel.alertList = editorInstance.components.draggablePanel.alertList
            .filter(alert => !removedAlerts.includes(alert.alertId));
        delete editorInstance.editorDOMTreeNodes[text];
        this.grammarUI.applyFilter(editorInstance);
    }
    generateEditorDOMStructure(editorInstance) {
        var _a, _b, _c, _d, _e;
        const pathname = window.location.pathname;
        const fileId = pathname.split('/').pop();
        let position = 0;
        editorInstance.editor.normalize();
        editorInstance.latestContenteditableDOM = {};
        let childNodesArr = [];
        let newSegment = null;
        if (((_a = editorInstance.editor) === null || _a === void 0 ? void 0 : _a.ckeditorInstance) && ((_b = editorInstance.editor.childNodes[0]) === null || _b === void 0 ? void 0 : _b.nodeName) === 'DIV' && editorInstance.editor.childNodes[0].getAttribute('data-placeholder')) {
            for (let i = 0; i < editorInstance.editor.childNodes.length; i++) {
                if (!((_d = (_c = editorInstance.editor.childNodes[i]) === null || _c === void 0 ? void 0 : _c.classList) === null || _d === void 0 ? void 0 : _d.contains('page-break')))
                    childNodesArr === null || childNodesArr === void 0 ? void 0 : childNodesArr.push(...(_e = editorInstance.editor.childNodes[i]) === null || _e === void 0 ? void 0 : _e.childNodes);
            }
        }
        else
            childNodesArr = [...editorInstance.editor.childNodes];
        childNodesArr.forEach((childNode) => {
            let nodes = this.extractParentTextNodes(childNode, []);
            nodes.forEach((node) => {
                const text = node.textContent.trimEnd() || '';
                if (text) {
                    editorInstance.latestContenteditableDOM[text] = true;
                    if (!editorInstance.editorDOMTreeNodes[text]) {
                        newSegment = this.addNewSegment(editorInstance, node, position, fileId);
                    }
                    else {
                        if (editorInstance.editorDOMTreeNodes[text].position !== position) {
                            editorInstance.components.draggablePanel.alertList = editorInstance.components.draggablePanel.alertList.map(record => {
                                if (record.segmentPosition === editorInstance.editorDOMTreeNodes[text].position) {
                                    return Object.assign(Object.assign({}, record), { segmentPosition: position });
                                }
                                return record;
                            });
                            editorInstance.editorDOMTreeNodes[text].position = position;
                        }
                        const latestTextNodes = this.extractTextNodes(node, []);
                        editorInstance.editorDOMTreeNodes[text].textNodes = latestTextNodes;
                    }
                }
                position++;
            });
        });
        this.grammarUI.updateEditorUnderlinesPositionInBatch(editorInstance);
    }
    extractChildNodes(node, childNodes = []) {
        node.childNodes.forEach((childNode) => {
            if (childNode.nodeName !== 'BR') {
                childNodes.push(childNode);
                this.extractChildNodes(childNode, childNodes);
            }
        });
        return childNodes;
    }
    extractParentTextNodes(node, textNodes = []) {
        var _a;
        const nodeNames = ['#text', 'LI', 'BLOCKQUOTE', 'PRE', 'HR', 'SPAN', 'P', 'TD', 'DIV'];
        if (nodeNames.includes(node.nodeName)) {
            if (node.nodeName === 'LI' && ((_a = node === null || node === void 0 ? void 0 : node.childNodes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                node.childNodes.forEach((childNode) => {
                    if (childNode.nodeName === 'OL' || childNode.nodeName === 'UL') {
                        this.extractParentTextNodes(childNode, textNodes);
                        textNodes.splice(textNodes.indexOf(node), 1);
                    }
                    else
                        textNodes.push(node);
                });
            }
            else
                textNodes.push(node);
        }
        else {
            node.childNodes.forEach((childNode) => {
                this.extractParentTextNodes(childNode, textNodes);
            });
        }
        return textNodes;
    }
    extractTextNodes(node, textNodes = []) {
        const nodeNames = [
            'B', 'STRONG', 'I', 'EM', 'U', 'S', 'STRIKE', 'SUP', 'SUB', 'CODE', 'MARK',
            'UL', 'OL', 'LI', 'BLOCKQUOTE', 'PRE', 'HR', 'SPAN'
        ];
        if (node.nodeName == '#text') {
            textNodes.push(node);
        }
        else {
            node.childNodes.forEach((childNode) => {
                if (childNode.nodeName == '#text') {
                    textNodes.push(childNode);
                }
                else {
                    this.extractTextNodes(childNode, textNodes);
                }
            });
        }
        return textNodes;
    }
    sendAmplitudeEvent(eventName, eventObj) {
        (0, utils_1.triggerAmplitudeEvent)(eventName, eventObj);
    }
    handleDraggablePanelSentenceWiseCardSingleRejectEvent($event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.grammarUI.listOfEditors[$event.detail.editorId];
        const detail = $event.detail;
        const alertId = detail.alertId;
        const sentenceId = detail.sentenceId;
        const alertPostion = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.findIndex(alert => alert.sentenceId === sentenceId);
        let parser = new DOMParser();
        let sentenceHTML = parser.parseFromString(editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[alertPostion].sentenceWithAlerts, 'text/html');
        let alertElement = sentenceHTML.querySelector(`alert[alert-id="${alertId}"]`);
        if (alertElement) {
            let strikethroughText = alertElement.querySelector(`strikethrough-text`);
            let textNode = document.createTextNode(strikethroughText.innerText);
            alertElement.parentNode.replaceChild(textNode, alertElement);
            const alert = editorInstance.components.draggablePanel.alertList.find(alert => alert.alertId === alertId);
            const sentence = editorInstance.segmentToSentencesMap.get(alert.segmentId)[alert.sentenceId];
            const segment = Object.keys(editorInstance.editorDOMTreeNodes)
                .filter((key) => editorInstance.editorDOMTreeNodes[key].segmentId === alert.segmentId)
                .map((key) => editorInstance.editorDOMTreeNodes[key])[0];
            if (!segment)
                return;
            let payload = Object.assign({}, alert.suggestions[0]);
            payload.correction = alert.suggestions[0].suggestion;
            payload.text = alert.text;
            payload.sentence = sentence.sentence;
            payload.error_type = alert.suggestions[0].type;
            payload.language = ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language) || 'US';
            payload.langCode = ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode) || 'en';
            if (alert.suggestions[0]["spell_status"] == 'conf') {
                payload.type = 2;
            }
            if (this.trinkaConfig.connectionType && this.trinkaConfig.connectionType === 'api') {
                editorInstance.socketClient.reject(payload, ((_e = this.trinkaConfig) === null || _e === void 0 ? void 0 : _e.connectionType) === 'api');
            }
            else {
                const searchKey = `${sentence.sentence}#${this.generateSearchKeyForTrinkaConfig()}`;
                editorInstance.socketClient.reject(payload, ((_f = this.trinkaConfig) === null || _f === void 0 ? void 0 : _f.connectionType) === 'api');
                this.cachingService.deleteCheckResponse(searchKey);
            }
            if (editorInstance.underlines.has(alertId)) {
                editorInstance.underlines.get(alertId).rejectAlert();
                this.removeAlertById(editorInstance, alertId);
                this.grammarUI.applyFilter(editorInstance);
                this.mappingCleanup(editorInstance);
            }
            this.sendAmplitudeEvent('grammar_reject', {
                'error_text': alert.text,
                'suggestion': alert.suggestions[0].suggestion,
                'category': alert.suggestions[0].error_category,
                'language': (_h = (_g = this.grammarModuleConfig) === null || _g === void 0 ? void 0 : _g.trinkaEditorConfig) === null || _h === void 0 ? void 0 : _h.language,
                'lang_code': (_k = (_j = this.grammarModuleConfig) === null || _j === void 0 ? void 0 : _j.trinkaEditorConfig) === null || _k === void 0 ? void 0 : _k.langCode,
                'strictness': alert.strictness,
                "doc_type": this.documentTypeList[this.grammarModuleConfig.trinkaEditorConfig.document_type],
                'model': this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module
            });
            let serializer = new XMLSerializer();
            let updatedHtmlString = serializer.serializeToString(sentenceHTML);
            let alerts = editorInstance.sentenceToAlertsMap.get(sentenceId) || {};
            delete alerts[alertId];
            editorInstance.sentenceToAlertsMap.set(sentenceId, alerts);
            if (Object.keys(alerts).length === 0) {
                editorInstance.components.draggablePanel.sentenceWiseCloseAllCards();
                const sentenceHighlighter = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.splice(alertPostion, 1)[0];
                sentenceHighlighter.sentenceHighlighter.destroy();
                editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList];
                setTimeout(() => {
                    if (!editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[alertPostion])
                        return;
                    const nextCardId = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[alertPostion].sentenceId;
                    editorInstance.components.draggablePanel.sentenceWiseToggleCardState(nextCardId);
                }, 0);
            }
            else {
                editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[alertPostion].sentenceWithAlerts = updatedHtmlString;
                editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList];
            }
        }
        else {
            console.log('Alert not found');
        }
    }
    handleWritingModeUpdateEvent($event) {
        if (!$event.detail.editorId)
            return;
        this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module = $event.detail.model;
        this.grammarModuleConfig.trinkaEditorConfig.instructionModule.instruction = $event.detail.instruction;
        this.rescanAndDockRightPanelForCloudEditor();
    }
    rescanAndDockRightPanelForCloudEditor() {
        this.rescan();
        setTimeout(() => {
            if (this.hostPageInfo.isCloudTrinka) {
                const listOfEditorIDs = Object.keys(this.grammarUI.listOfEditors);
                const editorId = listOfEditorIDs[0];
                this.dockRightPanelForTrinkaEditor(editorId);
            }
        }, 3000);
    }
    showInclusiveLanguageFilter(editorInstance) {
        var _a, _b, _c, _d, _e;
        if (((_c = (_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.inclusiveErrorCategories) === null || _c === void 0 ? void 0 : _c.length) > 0 && ((_e = (_d = this.grammarModuleConfig) === null || _d === void 0 ? void 0 : _d.trinkaEditorConfig) === null || _e === void 0 ? void 0 : _e.langCode) !== 'es') {
            editorInstance.components.draggablePanel.filters.inclusiveLanguage.display = true;
            editorInstance.components.draggablePanel.filters.inclusiveLanguage.isSelected = true;
        }
        else {
            editorInstance.components.draggablePanel.filters.inclusiveLanguage.display = false;
            editorInstance.components.draggablePanel.filters.inclusiveLanguage.isSelected = false;
        }
        editorInstance.components.draggablePanel.manualRequestUpdate();
        this.grammarUI.applyFilter(editorInstance);
    }
    updateCloudConfig(config) {
        this.cloudConfig = config;
        this.grammarUI.cloudConfig = config;
    }
    setSegmentDeletedFlag(editorInstance) {
        for (const key in editorInstance.editorDOMTreeNodes) {
            if (!editorInstance.latestContenteditableDOM.hasOwnProperty(key)) {
                editorInstance.editorDOMTreeNodes[key] = Object.assign(Object.assign({}, editorInstance.editorDOMTreeNodes[key]), { isSegmentDeleted: true });
            }
        }
    }
    handleGroupedAlertEvent($event) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.grammarUI.listOfEditors[$event.detail.editorId];
        const detail = $event.detail;
        const firstAlert = detail.firstAlert;
        const noOfGroupedAlerts = detail.noOfGroupedAlerts;
        this.sendAmplitudeEvent(`group_${detail.eventType}`, {
            'category': firstAlert.suggestions[0].error_category,
            'language': (_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.language,
            'lang_code': (_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.langCode,
            'strictness': firstAlert.strictness,
            "doc_type": this.documentTypeList[(_f = (_e = this.grammarModuleConfig) === null || _e === void 0 ? void 0 : _e.trinkaEditorConfig) === null || _f === void 0 ? void 0 : _f.document_type],
            'model': (_h = (_g = this.grammarModuleConfig.trinkaEditorConfig) === null || _g === void 0 ? void 0 : _g.instructionModule) === null || _h === void 0 ? void 0 : _h.module,
            'noOfGroupedAlerts': noOfGroupedAlerts
        });
    }
}
exports.Grammar = Grammar;
