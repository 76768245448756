"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertsPanelLoader = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
let AlertsPanelLoader = class AlertsPanelLoader extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.isCloudTrinka = false;
    }
    render() {
        return (0, lit_1.html) `
      <div>
        <div class="editorCardLoaderSection  ${this.isCloudTrinka ? 'trinkaCloudEditor' : 'trinkaB2BEditor'}">
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
          <div class="loaderLine"></div>
        </div>
      </div>
    `;
    }
};
exports.AlertsPanelLoader = AlertsPanelLoader;
AlertsPanelLoader.styles = (0, lit_1.css) `
  .editorCardLoaderSection {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    z-index: 0;
    padding-top: 65px;
    margin: 0 14px;
 }
  @media (max-width: 1350px) {
    .editorCardLoaderSection {
      width: 90%;
   }
 }
  .editorCardLoaderSection.trinkaB2BEditor{
  padding-top: 95px;
  }
  .editorCardLoaderSection .loaderLine {
    width: 93%;
    height: 16px;
    margin-bottom: 15px;
 }
.editorCardLoaderSection.trinkaCloudEditor{
  top: 20px;
}

 .loaderLine{
  animation-duration: 3.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmerLoader;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #FDFDFD 8%, #eee 18%, #FDFDFD 33%);
  background-size: 1200px 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}
@-webkit-keyframes shimmerLoader {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmerLoader {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AlertsPanelLoader.prototype, "isCloudTrinka", void 0);
exports.AlertsPanelLoader = AlertsPanelLoader = __decorate([
    (0, decorators_js_1.customElement)('alerts-panel-loader')
], AlertsPanelLoader);
