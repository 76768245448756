"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskSelection = void 0;
class AskSelection {
    constructor(editorOverlay) {
        this.editorOverlay = editorOverlay;
    }
    clearHighlights() {
        this.editorOverlay.innerHTML = '';
        this.highlighter = [];
    }
    isHighLighted() {
        return !!this.editorOverlay.querySelectorAll(`.highlight`).length;
    }
    createHighlight(rects) {
        if (!rects || this.isHighLighted())
            return;
        const containerBounds = this.editorOverlay.getBoundingClientRect();
        for (let rect of rects) {
            const highlightContainer = document.createElement('div');
            highlightContainer.classList.add('highlight');
            Object.assign(highlightContainer.style, {
                'top': `${rect.top - containerBounds.top + this.editorOverlay.scrollTop}px`,
                'left': `${rect.left - containerBounds.left + this.editorOverlay.scrollLeft}px`,
                'width': `${rect.width}px`,
                'height': `${rect.height}px`,
                'position': `absolute`,
                'backgroundColor': `rgba(0, 56, 255, 0.15)`,
                'pointerEvents': `none`
            });
            this.editorOverlay.appendChild(highlightContainer);
            this.highlighter.push(highlightContainer);
        }
    }
    remove() {
        this.clearHighlights();
    }
}
exports.AskSelection = AskSelection;
