"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CachingService = void 0;
const dexie_1 = require("dexie");
class CachingService {
    constructor() {
        this.dbName = 'trinkaBg';
        this.columnNameMapping = {
            split: 'spt',
            check: 'ck',
            splitAndCheck: 'sac',
            segmentIds: 'si'
        };
        this.db = new dexie_1.default(this.dbName);
        this.db.version(2).stores({
            [this.columnNameMapping.split]: '++id, searchKey',
            [this.columnNameMapping.check]: '++id, searchKey',
            [this.columnNameMapping.splitAndCheck]: '++id, text',
            [this.columnNameMapping.segmentIds]: 'fileId, segment, segmentId'
        });
        this.db.open().catch((error) => {
            console.error("db does not exist ===> ", error);
        });
    }
    split(searchKey) {
        return new Promise((resolve, reject) => {
            this.getSplitResponse(searchKey)
                .then((data) => {
                if (data) {
                    resolve(data.response);
                }
                else {
                    resolve(null);
                }
            })
                .catch((error) => { });
        });
    }
    check(searchKey) {
        return new Promise((resolve, reject) => {
            this.getCheckResponse(searchKey).then((data) => {
                if (data) {
                    resolve(data.response);
                }
                else {
                    resolve(null);
                }
            })
                .catch((error) => { });
        });
    }
    splitAndCheck(text) {
        return new Promise((resolve, reject) => {
            this.getSplitAndCheckResponse(text)
                .then((data) => {
                if (data) {
                    resolve(data.response);
                }
                else {
                    resolve(null);
                }
            })
                .catch((error) => { });
        });
    }
    deleteSplitResponse(searchKey) {
        return this.db[this.columnNameMapping.split].where('searchKey').equals(searchKey).delete();
    }
    deleteCheckResponse(searchKey) {
        return this.db[this.columnNameMapping.check].where('searchKey').equals(searchKey).delete();
    }
    deleteSplitAndCheckResponse(text) {
        return this.db[this.columnNameMapping.splitAndCheck].where('text').equals(text).delete();
    }
    deleteSegment(fileId, segment) {
        return this.db[this.columnNameMapping.segmentIds]
            .where('[fileId+segment]')
            .equals([fileId, segment])
            .delete();
    }
    getSplitResponse(searchKey) {
        return this.db[this.columnNameMapping.split].where('searchKey').equals(searchKey).first();
    }
    getCheckResponse(searchKey) {
        return this.db[this.columnNameMapping.check].where('searchKey').equals(searchKey).first();
    }
    getSplitAndCheckResponse(text) {
        return this.db[this.columnNameMapping.splitAndCheck].where('text').equals(text).first();
    }
    getSegment(fileId, segment) {
        return this.db[this.columnNameMapping.segmentIds]
            .where('[fileId+segment]')
            .equals([fileId, segment])
            .first();
    }
    setSplitResponse(searchKey, response) {
        this.db[this.columnNameMapping.split].add({ searchKey, response })
            .catch((error) => {
        });
    }
    setCheckResponse(searchKey, response) {
        this.db[this.columnNameMapping.check].add({ searchKey, response })
            .catch((error) => {
        });
    }
    setSplitAndCheckResponse(text, response) {
        this.db[this.columnNameMapping.splitAndCheck].add({ text, response })
            .catch((error) => {
        });
    }
    setSegment(fileId, segment, segmentId) {
        this.db[this.columnNameMapping.segmentIds].add({ fileId, segment, segmentId })
            .catch((error) => {
        });
    }
}
exports.CachingService = CachingService;
