"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.injectScript = exports.getHighestTopRect = exports.filterDuplicateRects = exports.addEventToTracingLog = exports.getEditorDataWithNewlines = exports.getNodesWithinRange = exports.checkIfEditorIsEmpty = exports.triggerAmplitudeEvent = exports.isUnderlineInViewport = exports.findObjectById = exports.decodeString = exports.encodeString = exports.getElementDefaultDisplay = exports.getCcSelectedFilters = exports.getSelectedFilters = exports.throttle = exports.debounce = exports.delay = exports.generateRandomId = void 0;
const amplitude_service_1 = require("../utils/amplitude.service");
const generateRandomId = (length) => {
    let id = '';
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const numbersAndLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const lettersLength = letters.length;
    const numbersAndLettersLength = numbersAndLetters.length;
    id += letters.charAt(Math.floor(Math.random() * lettersLength));
    for (let i = 1; i < length; i++) {
        id += numbersAndLetters.charAt(Math.floor(Math.random() * numbersAndLettersLength));
    }
    return id;
};
exports.generateRandomId = generateRandomId;
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
exports.delay = delay;
const debounce = (callback, interval) => {
    let debounceTimeoutId;
    return (...args) => {
        clearTimeout(debounceTimeoutId);
        debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
    };
};
exports.debounce = debounce;
const throttle = (callback, interval) => {
    let enableCall = true;
    return (...args) => {
        if (!enableCall)
            return;
        enableCall = false;
        callback.apply(this, args);
        setTimeout(() => enableCall = true, interval);
    };
};
exports.throttle = throttle;
const getSelectedFilters = (filters) => {
    return Object.keys(filters)
        .filter(key => filters[key].isSelected)
        .map(key => filters[key].value.toString());
};
exports.getSelectedFilters = getSelectedFilters;
const getCcSelectedFilters = (filters) => {
    return filters
        .filter(item => item.isSelected)
        .map(item => item.name);
};
exports.getCcSelectedFilters = getCcSelectedFilters;
const getElementDefaultDisplay = (htmltag) => {
    if (htmltag && htmltag.style && htmltag.style.display === 'inline') {
        return 'inline';
    }
    let tag = htmltag.tagName;
    let tempElementStyle, temporaryElement = document.createElement(tag), gcs = "getComputedStyle" in window;
    document.body.appendChild(temporaryElement);
    tempElementStyle = (gcs ? window.getComputedStyle(temporaryElement, "") : temporaryElement.currentStyle).display;
    document.body.removeChild(temporaryElement);
    return tempElementStyle;
};
exports.getElementDefaultDisplay = getElementDefaultDisplay;
const encodeString = (text) => {
    return btoa(encodeURIComponent(text));
};
exports.encodeString = encodeString;
const decodeString = (text) => {
    return decodeURIComponent(atob(text));
};
exports.decodeString = decodeString;
const findObjectById = (objectOfObjects, id) => {
    for (const obj of Object.values(objectOfObjects)) {
        const anyObj = obj;
        if (anyObj.segmentId === id) {
            return anyObj;
        }
    }
    return null;
};
exports.findObjectById = findObjectById;
const isUnderlineInViewport = (editor, underline) => {
    const editorRect = editor.getBoundingClientRect();
    const underlineRect = underline.getBoundingClientRect();
    return (underlineRect.top >= editorRect.top &&
        underlineRect.left >= editorRect.left &&
        underlineRect.bottom <= editorRect.bottom &&
        underlineRect.right <= editorRect.right);
};
exports.isUnderlineInViewport = isUnderlineInViewport;
const triggerAmplitudeEvent = (eventName, amplitudeEventObj) => {
    (0, amplitude_service_1.logAmplitudeEvent)(eventName, amplitudeEventObj);
};
exports.triggerAmplitudeEvent = triggerAmplitudeEvent;
const checkIfEditorIsEmpty = (editor) => {
    if (editor instanceof HTMLInputElement || editor instanceof HTMLTextAreaElement) {
        return editor.value.trim() === '';
    }
    else if (editor.isContentEditable) {
        return editor.innerText.trim() === '' || editor.innerText.trim().length <= 7;
    }
    return false;
};
exports.checkIfEditorIsEmpty = checkIfEditorIsEmpty;
const getNodesWithinRange = (range) => {
    const selectedNodes = [];
    const treeWalker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_ALL, {
        acceptNode(node) {
            const nodeRange = document.createRange();
            nodeRange.selectNodeContents(node);
            return (range.intersectsNode(node)) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
        }
    });
    let currentNode = treeWalker.currentNode;
    while (currentNode) {
        selectedNodes.push(currentNode);
        currentNode = treeWalker.nextNode();
    }
    return selectedNodes;
};
exports.getNodesWithinRange = getNodesWithinRange;
function getEditorDataWithNewlines(editor) {
    const htmlData = editor.getData();
    const plainTextWithNewlines = htmlData
        .replace(/&nbsp;/g, '')
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/p>/gi, '\n')
        .replace(/<\/li>/gi, '\n')
        .replace(/<[^>]*>/g, '')
        .trim();
    return plainTextWithNewlines;
}
exports.getEditorDataWithNewlines = getEditorDataWithNewlines;
function addEventToTracingLog(eventName) {
    const event = new CustomEvent('tsdk-add-event-to-log', {
        bubbles: true,
        composed: true,
        detail: { eventName: eventName }
    });
    document.dispatchEvent(event);
}
exports.addEventToTracingLog = addEventToTracingLog;
const filterDuplicateRects = (rects) => {
    const uniqueRects = [];
    if (!rects)
        return;
    const rectArray = Array.from(rects);
    rectArray.forEach(rect => {
        const isDuplicate = uniqueRects.some(uniqueRect => uniqueRect.top === rect.top &&
            uniqueRect.left === rect.left &&
            uniqueRect.width === rect.width &&
            uniqueRect.height === rect.height);
        if (!isDuplicate) {
            uniqueRects.push(rect);
        }
    });
    return uniqueRects;
};
exports.filterDuplicateRects = filterDuplicateRects;
const getHighestTopRect = (rects) => {
    const rectArray = Array.from(rects);
    if (rectArray.length === 0)
        return null;
    return rectArray.reduce((highestRect, currentRect) => currentRect.top > highestRect.top ? currentRect : highestRect);
};
exports.getHighestTopRect = getHighestTopRect;
const injectScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
        setTimeout(() => {
            callback();
        }, 0);
    };
    return script;
};
exports.injectScript = injectScript;
