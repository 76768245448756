"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggablePanelHeader = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const utils_1 = require("../../helpers/utils");
const environment_1 = require("src/env/environment");
let DraggablePanelHeader = class DraggablePanelHeader extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.alertCounter = 0;
        this.filters = [];
        this.showDraggablePanelHeader = true;
        this.currentLang = 'en';
        this.boundHandleClickEvent = ($event) => this.handleClickEvent($event);
    }
    render() {
        return (0, lit_1.html) `
      <div id="header" class="dgl_h" style="cursor: ${this.showDraggablePanelHeader ? 'grab' : 'default'}">
        <div class="dgl_h_lic" style="display: ${this.showDraggablePanelHeader ? 'flex' : 'none'}">
          <span class="dgl_h_logo">
            <svg width="80" height="36" viewBox="0 0 132 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_242_12)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 21.1966H5.47604V36.0007H8.39659V21.1966H13.897V18.5549H0V21.1966ZM37.7878 24.511C37.7878 20.7231 35.2358 18.5549 30.8007 18.5549H23.7636V36.0007H26.6841V30.7419H30.7979C31.0705 30.7419 31.3431 30.7419 31.5907 30.7167L34.7622 36.0007H38.0826L34.1955 30.1698C36.4993 29.2711 37.7878 27.3023 37.7878 24.511ZM30.8007 28.1003H26.6869V21.1966H30.8007C33.4765 21.1966 35.0126 22.2932 35.0126 24.5858C35.0126 26.9526 33.4765 28.1003 30.8007 28.1003ZM49.0382 36.0035H51.9587V18.5549H49.0382V36.0035ZM76.0477 31.0937L66.7555 18.5549H63.8809V36.0007H66.8049V23.4892L76.0477 36.0007H78.9217V18.5549H76.0477V31.0937ZM105.511 18.5577H102.066L93.7915 27.5799V18.5577H90.8661V36.0007H93.7901V31.5644L96.7385 28.2996L102.441 36.0007H105.959L98.7968 26.0063L105.511 18.5577ZM124.417 18.5577H121.421L113.763 36.0007H116.81L118.47 32.0624H127.217L128.877 36.0007H131.999L124.417 18.5577ZM119.561 29.4487L122.829 21.698L126.097 29.4487H119.561Z"
                fill="black"></path>
                <path
                d="M7.07331 13.5697C10.7989 13.5697 13.8191 10.532 13.8191 6.78484C13.8191 3.03768 10.7989 0 7.07331 0C3.34771 0 0.327522 3.03768 0.327522 6.78484C0.327522 10.532 3.34771 13.5697 7.07331 13.5697Z"
                fill="#763393"></path>
                <path
                d="M28.8337 13.5741C32.5567 13.5741 35.5706 10.5344 35.5654 6.78463C35.5603 3.03489 32.538 -0.00488281 28.8149 -0.00488281C25.0919 -0.00488281 22.078 3.03489 22.0832 6.78463C22.0883 10.5344 25.1106 13.5741 28.8337 13.5741Z"
                fill="#FF5C5C"></path>
                <path
                d="M50.4466 13.5739C54.1697 13.5739 57.1836 10.5341 57.1784 6.78439C57.1733 3.03464 54.151 -0.00512695 50.4279 -0.00512695C46.7049 -0.00512695 43.691 3.03464 43.6961 6.78439C43.7013 10.5341 46.7236 13.5739 50.4466 13.5739Z"
                fill="#FDB933"></path>
              </g>
              <defs>
                <clipPath id="clip0_242_12">
                  <rect width="132" height="36" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          <span class="dgl_h_m">
            <svg width="18" height="16" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M2.20078 1.7C2.20078 1.88565 2.12703 2.0637 1.99575 2.19497C1.86448 2.32625 1.68643 2.4 1.50078 2.4C1.31513 2.4 1.13708 2.32625 1.00581 2.19497C0.874531 2.0637 0.800781 1.88565 0.800781 1.7C0.800781 1.51435 0.874531 1.3363 1.00581 1.20503C1.13708 1.07375 1.31513 1 1.50078 1C1.68643 1 1.86448 1.07375 1.99575 1.20503C2.12703 1.3363 2.20078 1.51435 2.20078 1.7V1.7Z"
              stroke="#4D4D4D"></path>
              <path
              d="M9.20078 1.7C9.20078 1.88565 9.12703 2.0637 8.99576 2.19497C8.86448 2.32625 8.68643 2.4 8.50078 2.4C8.31513 2.4 8.13708 2.32625 8.00581 2.19497C7.87453 2.0637 7.80078 1.88565 7.80078 1.7C7.80078 1.51435 7.87453 1.3363 8.00581 1.20503C8.13708 1.07375 8.31513 1 8.50078 1C8.68643 1 8.86448 1.07375 8.99576 1.20503C9.12703 1.3363 9.20078 1.51435 9.20078 1.7V1.7Z"
              stroke="#4D4D4D"></path>
              <path
              d="M16.2008 1.7C16.2008 1.88565 16.127 2.0637 15.9958 2.19497C15.8645 2.32625 15.6864 2.4 15.5008 2.4C15.3151 2.4 15.1371 2.32625 15.0058 2.19497C14.8745 2.0637 14.8008 1.88565 14.8008 1.7C14.8008 1.51435 14.8745 1.3363 15.0058 1.20503C15.1371 1.07375 15.3151 1 15.5008 1C15.6864 1 15.8645 1.07375 15.9958 1.20503C16.127 1.3363 16.2008 1.51435 16.2008 1.7V1.7Z"
              stroke="#4D4D4D"></path>
              <path
              d="M2.20078 7.3001C2.20078 7.48575 2.12703 7.6638 1.99575 7.79507C1.86448 7.92635 1.68643 8.0001 1.50078 8.0001C1.31513 8.0001 1.13708 7.92635 1.00581 7.79507C0.874531 7.6638 0.800781 7.48575 0.800781 7.3001C0.800781 7.11445 0.874531 6.9364 1.00581 6.80512C1.13708 6.67385 1.31513 6.6001 1.50078 6.6001C1.68643 6.6001 1.86448 6.67385 1.99575 6.80512C2.12703 6.9364 2.20078 7.11445 2.20078 7.3001V7.3001Z"
              stroke="#4D4D4D"></path>
              <path
              d="M9.20078 7.3001C9.20078 7.48575 9.12703 7.6638 8.99576 7.79507C8.86448 7.92635 8.68643 8.0001 8.50078 8.0001C8.31513 8.0001 8.13708 7.92635 8.00581 7.79507C7.87453 7.6638 7.80078 7.48575 7.80078 7.3001C7.80078 7.11445 7.87453 6.9364 8.00581 6.80512C8.13708 6.67385 8.31513 6.6001 8.50078 6.6001C8.68643 6.6001 8.86448 6.67385 8.99576 6.80512C9.12703 6.9364 9.20078 7.11445 9.20078 7.3001Z"
              stroke="#4D4D4D"></path>
              <path
              d="M16.2008 7.3001C16.2008 7.48575 16.127 7.6638 15.9958 7.79507C15.8645 7.92635 15.6864 8.0001 15.5008 8.0001C15.3151 8.0001 15.1371 7.92635 15.0058 7.79507C14.8745 7.6638 14.8008 7.48575 14.8008 7.3001C14.8008 7.11445 14.8745 6.9364 15.0058 6.80512C15.1371 6.67385 15.3151 6.6001 15.5008 6.6001C15.6864 6.6001 15.8645 6.67385 15.9958 6.80512C16.127 6.9364 16.2008 7.11445 16.2008 7.3001Z"
              stroke="#4D4D4D"></path>
            </svg>
            <div class="hoverEffectSection">
              <p>Grab and drag to move panel</p>
            </div>
          </span>
          <span class="dgl_h_cb" @click=${this.close}>
            <svg width="13" height="13" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M9.13169 7.48946L14.1435 2.4893C14.363 2.26982 14.4863 1.97215 14.4863 1.66176C14.4863 1.35138 14.363 1.05371 14.1435 0.834231C13.924 0.614755 13.6264 0.491455 13.316 0.491455C13.0056 0.491455 12.7079 0.614755 12.4885 0.834231L7.48828 5.84605L2.48811 0.834231C2.26864 0.614755 1.97097 0.491455 1.66058 0.491455C1.35019 0.491455 1.05252 0.614755 0.833046 0.834231C0.613571 1.05371 0.49027 1.35138 0.49027 1.66176C0.49027 1.97215 0.613571 2.26982 0.833046 2.4893L5.84487 7.48946L0.833046 12.4896C0.723802 12.598 0.637093 12.7269 0.57792 12.8689C0.518747 13.011 0.488281 13.1633 0.488281 13.3172C0.488281 13.471 0.518747 13.6234 0.57792 13.7654C0.637093 13.9074 0.723802 14.0363 0.833046 14.1447C0.941398 14.2539 1.07031 14.3406 1.21234 14.3998C1.35437 14.459 1.50671 14.4895 1.66058 14.4895C1.81444 14.4895 1.96679 14.459 2.10882 14.3998C2.25085 14.3406 2.37976 14.2539 2.48811 14.1447L7.48828 9.13287L12.4885 14.1447C12.5968 14.2539 12.7257 14.3406 12.8677 14.3998C13.0098 14.459 13.1621 14.4895 13.316 14.4895C13.4698 14.4895 13.6222 14.459 13.7642 14.3998C13.9063 14.3406 14.0352 14.2539 14.1435 14.1447C14.2528 14.0363 14.3395 13.9074 14.3986 13.7654C14.4578 13.6234 14.4883 13.471 14.4883 13.3172C14.4883 13.1633 14.4578 13.011 14.3986 12.8689C14.3395 12.7269 14.2528 12.598 14.1435 12.4896L9.13169 7.48946Z"
              fill="#4D4D4D" fill-opacity="0.5"></path>
            </svg>
          </span>
        </div>

        <div class="dgl_h_ah">
          <span class="acceptAllAlertText" style="visibility: hidden;">Accept all alerts</span>
          <span class="dgl_h_ahf">
            <span>Alerts <b>${this.alertCounter}</b></span>
            <div @click=${this.toggleFilter}>
              <svg class="funnel-svg" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.762 2.1709H3.238C2.928 2.1709 2.645 2.3439 2.504 2.6189C2.361 2.8969 2.386 3.2329 2.569 3.4869L8.989 12.5309C8.991 12.5339 8.993 12.5369 8.996 12.5399C9.229 12.8549 9.356 13.2369 9.356 13.6279V21.0019C9.355 21.2199 9.44 21.4299 9.594 21.5849C9.748 21.7399 9.957 21.8269 10.176 21.8269C10.287 21.8269 10.397 21.8049 10.499 21.7619L14.107 19.7469C14.43 19.6479 14.645 19.3429 14.645 18.9759V13.6279C14.646 13.2359 14.772 12.8539 15.005 12.5399C15.007 12.5369 15.009 12.5339 15.012 12.5309L21.433 3.4859C21.616 3.2319 21.641 2.8959 21.498 2.6179C21.355 2.3439 21.072 2.1709 20.762 2.1709Z" stroke="#4E4E4E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M7.566 6.27881H16.434" stroke="#7B3393" stroke-width="1.5" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>

            <div class="filter">
              <ul>
                <li class="customCheckBox">
                  <input type="checkbox" class="trinka-alert-filter" value="all" id="select-all" .checked="${this.filters.every(filter => filter.isSelected)}" @change="${this.handleFilterChangeSelectAll}">
                  <label class="checkbox-filter" for="select-all">
                  Select all categories
                  </label>
                </li>
                ${this.filters.map((value, index) => (0, lit_1.html) `

                    <li class="customCheckBox" style="display: ${!value.display ? 'none' : ''}; width:100%">
                      <input type="checkbox" class="trinka-alert-filter trinka-filter" value="${value.name}" id="${value.name}" .checked="${value.isSelected}" @change="${this.handleFilterChange}">
                      <label class="checkbox-filter" for="${value.name}">
                        ${value.title}
                        <span class="error-coount">${value.errorCount}</span>
                      </label>
                      <hr>
                    </li>
                  `)}
              </ul>
            </div>
          </span>
        </div>
      </div>
    `;
    }
    toggleFilter() {
        const filter = this.shadowRoot.querySelector('.filter');
        if (!filter.classList.contains('active')) {
            filter.classList.add('active');
        }
        else {
            filter.classList.remove('active');
        }
        this.sendAmplitudeEvent("ED_RightPanel_Consistency_Alerts", { value: 'Open Consistency Alert Box' });
    }
    closeFilter() {
        const filter = this.shadowRoot.querySelector('.filter');
        filter.classList.remove('active');
    }
    close() {
        const eventData = { data: '' };
        this.dispatchEvent(new CustomEvent('draggable-panel-close', {
            bubbles: true,
            composed: true,
            detail: eventData
        }));
    }
    closeAllCards() {
        this.dispatchEvent(new CustomEvent('close-all-cards', {
            bubbles: true,
            composed: true,
            detail: {}
        }));
    }
    triggerFilterChangedEvent() {
        const eventData = {
            editorId: this.editorId
        };
        this.dispatchEvent(new CustomEvent('draggable-panel-filter-update-cc', {
            bubbles: true,
            composed: true,
            detail: eventData
        }));
    }
    handleFilterChange($event) {
        this.closeAllCards();
        const checkbox = $event.target;
        const name = checkbox.id;
        const filter = this.filters.find(f => f.name === name);
        if (filter) {
            filter.isSelected = checkbox.checked;
            this.requestUpdate();
            this.sendAmplitudeEvent("ED_Consistency_Alerts_toggle_category", {
                filterName: name,
                action: checkbox.checked ? "enabled" : "disabled",
            });
        }
        this.triggerFilterChangedEvent();
    }
    handleFilterChangeSelectAll($event) {
        this.closeAllCards();
        const target = $event.target;
        const checked = target.checked;
        this.filters.forEach(filter => {
            filter.isSelected = checked;
        });
        this.requestUpdate();
        this.triggerFilterChangedEvent();
        const action = checked ? 'enable' : 'disable';
        this.sendAmplitudeEvent("ED_Consistency_Alerts_selectAll_categories", {
            action,
        });
    }
    firstUpdated() {
        this.draggablePanelHeaderCC = this.shadowRoot.querySelector('#header');
        this.draggablePanelHeaderCC.addEventListener('click', this.boundHandleClickEvent);
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    handleClickEvent(event) {
        if (event.target.closest(".filter.active") || event.target.closest(".funnel-svg")) {
            return;
        }
        else {
            const filter = this.shadowRoot.querySelector('.filter');
            filter.classList.remove('active');
        }
    }
    sendAmplitudeEvent(eventName, eventObj) {
        (0, utils_1.triggerAmplitudeEvent)(eventName, eventObj);
    }
};
exports.DraggablePanelHeader = DraggablePanelHeader;
DraggablePanelHeader.styles = (0, lit_1.css) `
  #header {
    position: relative;
    box-shadow: 0 3px 5px -3px #ccc;
    transition: all 0.5s ease;
    cursor: grab;
    background-color: #FFFFFF;
    padding: 0px;
    z-index: 1;
    height: 35px;
  }
    /*
  #header::before {
    content: '';
    position: absolute;
    left: 0;
    right: auto;
    background-color: #bcbcbc;
    height: 23px;
    width: 1px;
    top: 6px;
  }*/

  .dgl_h_lic {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px 0px 0px;
    margin-bottom: 0px;
  }

  .dgl_h_logo {}

  .dgl_h_m {
    cursor: move;
    position: relative;
    top: -7px;
    left: -30px;
    background: #F6F7FD;
    border-radius: 8px;
    width: 40px;
    height: 23px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dgl_h .dgl_h_lic .dgl_h_m .hoverEffectSection{
    display: none;
    transition: all 0.5s ease;
  }
  .dgl_h .dgl_h_lic .dgl_h_m .hoverEffectSection:before,
  .dgl_h .dgl_h_lic .dgl_h_m .hoverEffectSection:after{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .dgl_h .dgl_h_lic .dgl_h_m .hoverEffectSection:before{
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #f3595b;
    border-width: 6px;
    margin-left: -6px;
  }
  .dgl_h .dgl_h_lic .dgl_h_m .hoverEffectSection p{
    font-size: 10px;
    margin: 0;
    color: #FFF;
  }
  .dgl_h .dgl_h_lic .dgl_h_m:hover .hoverEffectSection{
    display: block;
    transition: all 0.5s ease;
    position: absolute;
    width: 135px;
    background: #f3595b;
    padding: 5px 10px;
    margin: 0;
    left: -68px;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    z-index: 99;
    top: 20px;
    bottom: auto;
    text-align: center;
  }

  .dgl_h_cb {
    cursor: pointer;
    position: relative;
    top: -6px;
    right: 2px;
  }

  .dgl_h_ah {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }

  .dgl_h_ahf {
    color: #4d4d4d;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 5px 15px 0 0;
  }

  .dgl_h_ahf div svg {
    margin-left: 5px;
    cursor: pointer;
    width: 16px;
    position: relative;
    top: 3px;
  }

  .filter {
    display: none;
    position: absolute;
    bottom: auto;
    top: 33px;
    left: auto;
    right: 10px;
    z-index: 9999;
    background: #FFF;
    min-width: 346px !important;
    height: auto;
    margin: 2px 0 0;
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 4px 4px 2px #cccc;
    padding: 10px 10px 0 10px;
  }

  .filter ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .filter.active {
    display: block;
  }
  @media(max-width: 1300px){
    .filter.active{
      max-height: 340px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .filter.active::-webkit-scrollbar-track{
      background-color: #f5f5f5;
    }
    .filter.active::-webkit-scrollbar{
      width: 6px;
	    background-color: #f5f5f5;
    }
    .filter.active::-webkit-scrollbar-thumb{
      background-color: #9ca3af;
	    border-radius: 10px;
    }
  }
  .filter ul li{
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px rgba(0, 0, 0, .12);
  }
  .filter ul li:first-child{
    border-bottom: none;
  }
  .filter ul li:first-child label.checkbox-filter {
    font-weight: 700;
  }
  .filter ul li:last-child{
    border-bottom: none;
  }

  .filter-category-badge {
    color: #fff;
    font-family: "Open Sans",sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    background-color: #f1305a;
    padding: 3px 4px;
    border-radius: 2px;
    line-height: 10px;
    display: inline-block;
    margin-left: 5px;
  }

  .checkbox-filter{
    height: 16px;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: 16px;
    flex-shrink: 0;
  }
  .error-coount{
    float: right;
    right: 0;
    position: absolute;
    border-radius: 4px;
    border: 1px solid transparent;
    color: #000;
    font-weight: 500;
  }
  .filter-category-badge {
    color: #fff;
    font-family: "Open Sans",sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    background-color: #f1305a;
    padding: 3px 4px;
    border-radius: 2px;
    line-height: 10px;
    display: inline-block;
    margin-left: 5px;
  }

  .customCheckBox {
    position: relative;
  }
  .customCheckBox input{
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  .customCheckBox label {
    position: relative;
    cursor: pointer;
    width: 100%;
    color: #4d4d4d;
    font-size: 16px;
    display: flex;
    align-items: center;

  }
  .customCheckBox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #4d4d4d;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 3px;
  }
  .customCheckBox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 6.5px;
    width: 3px;
    height: 8px;
    border: solid #4d4d4d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], DraggablePanelHeader.prototype, "alertCounter", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanelHeader.prototype, "filters", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], DraggablePanelHeader.prototype, "editorId", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanelHeader.prototype, "showDraggablePanelHeader", void 0);
exports.DraggablePanelHeader = DraggablePanelHeader = __decorate([
    (0, decorators_js_1.customElement)('draggable-panel-header-cc')
], DraggablePanelHeader);
