"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParaphraserUI = void 0;
const constants_1 = require("../helpers/constants");
require("../assets/styles.css");
const ask_1 = require("./ask");
const utils_1 = require("../helpers/utils");
class ParaphraserUI {
    constructor(paraphraserModuleConfig, uiConfig) {
        this.listOfEditors = [];
        this.listOfEditorsV2 = {};
        this.hostPageInfo = {
            supportedEditorsLibraries: constants_1.EDITORS_LIBRARIES_TO_SUPPORT,
            isCloudTrinka: true,
            isEditorLibraryPresent: false,
            isMultipleLibrariesPresent: false,
            detectedLibraries: []
        };
        this.isDestroyInProgress = false;
        this.validateEditor = (element) => {
            if (element.getAttribute(constants_1.EDITOR_ATTRIBUTES.DATA_TRINKA) === 'false') {
                return false;
            }
            return true;
        };
        this.paraphraserModuleConfig = paraphraserModuleConfig;
        this.uiConfig = uiConfig;
        this.scanHostPage();
    }
    async scanHostPage() {
        this.hostPageInfo.isCloudTrinka = true;
        Object.keys(this.hostPageInfo.supportedEditorsLibraries).forEach(editor => {
            this.hostPageInfo.supportedEditorsLibraries[editor].detect();
        });
        if (!Object.values(this.hostPageInfo.supportedEditorsLibraries).some(library => library.detected)) {
            this.hostPageInfo.isEditorLibraryPresent = false;
        }
        else {
            this.hostPageInfo.isEditorLibraryPresent = true;
            this.hostPageInfo.detectedLibraries = Object.values(this.hostPageInfo.supportedEditorsLibraries)
                .filter(library => library.detected)
                .map(library => library.editorLibraryName);
            this.hostPageInfo.isMultipleLibrariesPresent = this.hostPageInfo.detectedLibraries.length > 1;
        }
    }
    initialize(editableElements) {
        editableElements.forEach(editor => {
            if (this.validateEditor(editor)) {
                this.listOfEditors.push(editor);
                const editorId = (0, utils_1.generateRandomId)(16);
                editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorId);
                editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
                const additionalEditorInfo = this.getAdditionalEditorInfo(editor);
                let askPlugin = null;
                if (this.paraphraserModuleConfig.askEnabled && this.paraphraserModuleConfig.token) {
                    askPlugin = new ask_1.Ask(editor, editorId, { token: this.paraphraserModuleConfig.token, userPlan: this.paraphraserModuleConfig.userPlan }, this.hostPageInfo, constants_1.SDK_MODULES.PARAPHRASER);
                }
                const editorInstance = {
                    editor,
                    editorId,
                    additionalEditorInfo,
                    askPlugin,
                    listOfEvents: []
                };
                this.listOfEditorsV2[editorId] = editorInstance;
                this.listOfEditorsV2[editorId].listOfEvents = this.generateListOfEvents(editorId);
                this.listOfEditorsV2[editorId].listOfEvents.forEach(({ element, event, listener }) => {
                    element.addEventListener(event, listener, { passive: true });
                });
            }
        });
    }
    generateListOfEvents(editorId) {
        const events = {
            'focus': {
                name: 'focus',
                boundHandler: ($event) => this._handleFocusEvent($event, this.listOfEditorsV2[editorId])
            },
            'blur': {
                name: 'blur',
                boundHandler: ($event) => this._handleBlurEvent($event, this.listOfEditorsV2[editorId])
            }
        };
        let listOfEvents = [];
        Object.values(events).forEach(event => {
            listOfEvents.push({
                editorId,
                element: this.listOfEditorsV2[editorId].editor,
                event: event.name,
                listener: event.boundHandler
            });
        });
        return listOfEvents;
    }
    getAdditionalEditorInfo(editor) {
        let additionalEditorInfo = null;
        if (!this.hostPageInfo.isEditorLibraryPresent) {
        }
        else {
            const supportedEditorsLibraries = Object.keys(this.hostPageInfo.supportedEditorsLibraries);
            for (let index = 0; index < supportedEditorsLibraries.length; index++) {
                additionalEditorInfo = this.hostPageInfo.supportedEditorsLibraries[supportedEditorsLibraries[index]].validateEditor(editor);
                if (additionalEditorInfo && additionalEditorInfo.isValid) {
                    break;
                }
            }
        }
        if (additionalEditorInfo && !additionalEditorInfo.isValid) {
            additionalEditorInfo = null;
        }
        return additionalEditorInfo;
    }
    _updateCKEditor5Attributes(editorInstance) {
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorInstance.editorId);
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
    }
    _handleFocusEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        if (this.isDestroyInProgress)
            return;
        if (editorInstance.additionalEditorInfo && editorInstance.additionalEditorInfo.editorLibraryName === 'ckeditor5') {
            const spellCheckInterval = setInterval(() => {
                if (this.isDestroyInProgress) {
                    clearInterval(spellCheckInterval);
                    return;
                }
                this._updateCKEditor5Attributes(editorInstance);
            }, 100);
            setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
        }
    }
    _handleBlurEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        if (this.isDestroyInProgress)
            return;
        if (editorInstance.additionalEditorInfo && editorInstance.additionalEditorInfo.editorLibraryName === 'ckeditor5') {
            const spellCheckInterval = setInterval(() => {
                if (this.isDestroyInProgress) {
                    clearInterval(spellCheckInterval);
                    return;
                }
                this._updateCKEditor5Attributes(editorInstance);
            }, 100);
            setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
        }
    }
    hideParaPhraserTooltip() {
        if (this.paraphraseToolTipDiv && this.paraphraseToolTipDiv.style)
            this.paraphraseToolTipDiv.style.display = 'none';
        const event = new CustomEvent('hide-paraphrase-tooltip', {
            bubbles: true,
            composed: true,
            detail: {}
        });
        document.dispatchEvent(event);
    }
    showParaPhraserTooltip(currentFocusedEditable) {
        let positionToBeUpdated = this.getTooltipPosition(currentFocusedEditable);
        if (!this.paraphraseToolTipDiv) {
            this.paraphraseToolTipDiv = document.createElement('div');
            this.paraphraseToolTipDiv.classList.add('paraphrase-tooltip');
            this.paraphraseToolTipDiv.classList.add('tooltip');
            let paraphraseBtnHTML = `<button>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9997 3.5H9.33301C9.05967 3.5 8.83301 3.27333 8.83301 3C8.83301 2.72667 9.05967 2.5 9.33301 2.5H13.9997C14.273 2.5 14.4997 2.72667 14.4997 3C14.4997 3.27333 14.273 3.5 13.9997 3.5Z" fill="#9531C4"/>
          <path d="M13.9997 6.83594H9.33301C9.05967 6.83594 8.83301 6.60927 8.83301 6.33594C8.83301 6.0626 9.05967 5.83594 9.33301 5.83594H13.9997C14.273 5.83594 14.4997 6.0626 14.4997 6.33594C14.4997 6.60927 14.273 6.83594 13.9997 6.83594Z" fill="#9531C4"/>
          <path d="M14 10.1641H2C1.72667 10.1641 1.5 9.9374 1.5 9.66406C1.5 9.39073 1.72667 9.16406 2 9.16406H14C14.2733 9.16406 14.5 9.39073 14.5 9.66406C14.5 9.9374 14.2733 10.1641 14 10.1641Z" fill="#9531C4"/>
          <path d="M14 13.5H2C1.72667 13.5 1.5 13.2733 1.5 13C1.5 12.7267 1.72667 12.5 2 12.5H14C14.2733 12.5 14.5 12.7267 14.5 13C14.5 13.2733 14.2733 13.5 14 13.5Z" fill="#9531C4"/>
          <path d="M5.27967 7.16406H3.38634C2.35967 7.16406 1.83301 6.64406 1.83301 5.61073V3.7174C1.83301 2.69073 2.35301 2.16406 3.38634 2.16406H5.28634C6.31301 2.16406 6.83967 2.68406 6.83967 3.7174V5.6174C6.83301 6.64406 6.31301 7.16406 5.27967 7.16406ZM3.38634 3.16406C2.91301 3.16406 2.83301 3.24406 2.83301 3.7174V5.6174C2.83301 6.09073 2.91301 6.17073 3.38634 6.17073H5.28634C5.75967 6.17073 5.83967 6.09073 5.83967 5.6174V3.7174C5.83967 3.24406 5.75967 3.16406 5.28634 3.16406H3.38634Z" fill="#9531C4"/>
        </svg>
        Paraphrase
      </button>`;
            if (this.paraphraserModuleConfig.askEnabled && this.paraphraserModuleConfig.token) {
                paraphraseBtnHTML += `<span id="askJSMagicWantCTADockingPoint" style="position: relative; display: inline-block;"></span>`;
            }
            this.paraphraseToolTipDiv.innerHTML = paraphraseBtnHTML;
            this.paraphraseToolTipDiv.style.position = 'absolute';
            this.paraphraseToolTipDiv.style.top = `${(positionToBeUpdated === null || positionToBeUpdated === void 0 ? void 0 : positionToBeUpdated.top) - 5}px`;
            this.paraphraseToolTipDiv.style.left = `${positionToBeUpdated === null || positionToBeUpdated === void 0 ? void 0 : positionToBeUpdated.left}px`;
            this.paraphraseToolTipDiv.style.boxShadow = '0px 4px 20px 0px rgba(31, 41, 55, 0.2)';
            this.paraphraseToolTipDiv.style.padding = '6px 6px 6px 8px';
            this.paraphraseToolTipDiv.style.background = '#FFFFFF';
            this.paraphraseToolTipDiv.style.opacity = '1';
            this.paraphraseToolTipDiv.style.borderRadius = '6px';
            document.body.appendChild(this.paraphraseToolTipDiv);
            document
                .querySelector('.paraphrase-tooltip button')
                .setAttribute('style', 'padding: 8px;background: #F3E8FF;border-radius: 8px;justify-content: flex-start;align-items: flex-start;gap: 5px;display: inline-flex;text-align: center;color: #9531C4;font-size: 16px;font-weight: 500;line-height: 16px;word-wrap: break-word;border: none;cursor:pointer;');
            const style = document.createElement('style');
            style.textContent = `
      #magic-wand span{
        display: none;
      }
      #magic-wand:hover span{
        display: flex;
        justify-content: center;
      }
      #magic-wand span::before{
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        border-bottom-color: #414E62;
        border-width: 4px;
        margin-left: -4px;
      }
      `;
            document.head.appendChild(style);
            this.paraphraseToolTipDiv.addEventListener('click', () => {
                this.paraphraseToolTipDiv.style.display = 'none';
                const event = new CustomEvent('paraphrase-tooltip-clicked', {
                    bubbles: true,
                    composed: true,
                    detail: {}
                });
                document.dispatchEvent(event);
            });
        }
        else {
            this.paraphraseToolTipDiv.style.top = `${(positionToBeUpdated === null || positionToBeUpdated === void 0 ? void 0 : positionToBeUpdated.top) - 5}px`;
            this.paraphraseToolTipDiv.style.left = `${positionToBeUpdated === null || positionToBeUpdated === void 0 ? void 0 : positionToBeUpdated.left}px`;
            this.paraphraseToolTipDiv.style.display = positionToBeUpdated.display ? 'block' : 'none';
            if (this.paraphraserModuleConfig.askEnabled && this.paraphraserModuleConfig.token) {
                this.paraphraseToolTipDiv.style.height = `48px`;
                this.paraphraseToolTipDiv.style.borderRadius = `4px`;
                this.paraphraseToolTipDiv.style.background = `#FFFFFF`;
                this.paraphraseToolTipDiv.style.padding = `6px`;
                this.paraphraseToolTipDiv.style.boxShadow = `0px 4px 20px 0px rgba(31, 41, 55, 0.1)`;
            }
        }
    }
    destroy() {
        var _a;
        this.isDestroyInProgress = true;
        (_a = this.paraphraseToolTipDiv) === null || _a === void 0 ? void 0 : _a.remove();
        Object.keys(this.listOfEditorsV2).forEach((editorId) => {
            var _a;
            const editorInstance = this.listOfEditorsV2[editorId];
            editorInstance.listOfEvents.forEach(({ element, event, listener }) => {
                element.removeEventListener(event, listener, { passive: true });
            });
            (_a = editorInstance === null || editorInstance === void 0 ? void 0 : editorInstance.askPlugin) === null || _a === void 0 ? void 0 : _a.destroy();
            let timeInterval = setInterval(() => {
                if (editorInstance.editor.hasAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID)) {
                    editorInstance.editor.removeAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID);
                    clearInterval(timeInterval);
                }
            }, 600);
        });
    }
    getTooltipPosition(currentFocusedEditable) {
        var _a, _b, _c, _d, _e, _f;
        if (!currentFocusedEditable)
            return;
        let selection = window.getSelection();
        let editorRects = currentFocusedEditable.getBoundingClientRect();
        let position = {
            display: false,
            isBackward: false,
            top: 0,
            left: 0
        };
        position.isBackward = this.checkIfUserSelectionIsBackward(selection);
        let rects = (selection === null || selection === void 0 ? void 0 : selection.rangeCount) > 0 ? (_a = selection === null || selection === void 0 ? void 0 : selection.getRangeAt(0)) === null || _a === void 0 ? void 0 : _a.getClientRects() : null;
        if (!rects)
            return Object.assign({ 'display': false }, position);
        let selectionNodesLength = (rects === null || rects === void 0 ? void 0 : rects.length) - 1;
        let editorWrapper = currentFocusedEditable === null || currentFocusedEditable === void 0 ? void 0 : currentFocusedEditable.parentElement;
        if (position.isBackward) {
            position.display = true;
            position.top = ((_b = rects[0]) === null || _b === void 0 ? void 0 : _b.top) - 2;
            position.left = ((_c = rects[0]) === null || _c === void 0 ? void 0 : _c.left) - 95;
        }
        else {
            position.display = true;
            position.top = (((_d = rects[selectionNodesLength]) === null || _d === void 0 ? void 0 : _d.top) + 18);
            position.left = ((_e = rects[selectionNodesLength]) === null || _e === void 0 ? void 0 : _e.left) + ((_f = rects[selectionNodesLength]) === null || _f === void 0 ? void 0 : _f.width) + 40;
        }
        if (position.left < 0) {
            position.left = 40;
            position.top = position.top - 15;
        }
        else if (editorRects && (position.left + 125) >= editorRects.width) {
            position.left = editorRects.width - 125;
            position.top = position.top + 18;
        }
        return position;
    }
    checkIfUserSelectionIsBackward(sel) {
        var _a;
        let position = (_a = sel === null || sel === void 0 ? void 0 : sel.anchorNode) === null || _a === void 0 ? void 0 : _a.compareDocumentPosition(sel === null || sel === void 0 ? void 0 : sel.focusNode);
        let isBackward = false;
        if (!position && (sel === null || sel === void 0 ? void 0 : sel.anchorOffset) > (sel === null || sel === void 0 ? void 0 : sel.focusOffset) || position === (Node === null || Node === void 0 ? void 0 : Node.DOCUMENT_POSITION_PRECEDING)) {
            isBackward = true;
        }
        return isBackward;
    }
}
exports.ParaphraserUI = ParaphraserUI;
