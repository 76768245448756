"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASK_INLINE_POPOVER_OPTIONS = exports.ASK_POPOVER_OPTIONS = exports.EDITORS_LIBRARIES_TO_SUPPORT = exports.CCFilterCheckList = exports.FILTERS = exports.SDK_MODULES = exports.GRAMMAR_VIEW_MODES = exports.TRINKA_PLANS = exports.PARAPHRASER_SENTENCE_LIMIT = exports.EDITOR_KEYDOWN_EVENT_DEBOUNCE_TIME = exports.CONCURRENCY = exports.LIMIT_EXHAUSTED_MESSAGE = exports.EDITOR_RESIZE_DEBOUNCE_TIME = exports.EDITOR_CHANGE_DEBOUNCE_TIME = exports.EDITOR_ATTRIBUTES = void 0;
exports.EDITOR_ATTRIBUTES = {
    DATA_TRINKA: 'data-trinka',
    TRINKA_EDITOR_ID: 'trinka-editor-Id',
    SPELLCHECK: 'spellcheck'
};
exports.EDITOR_CHANGE_DEBOUNCE_TIME = 1000;
exports.EDITOR_RESIZE_DEBOUNCE_TIME = 400;
exports.LIMIT_EXHAUSTED_MESSAGE = 'LIMIT_EXHAUSTED';
exports.CONCURRENCY = 4;
exports.EDITOR_KEYDOWN_EVENT_DEBOUNCE_TIME = 400;
exports.PARAPHRASER_SENTENCE_LIMIT = 10;
exports.TRINKA_PLANS = {
    BASIC: 'BASIC',
    PREMIUM_TRIAL: 'PREMIUM_TRIAL',
    PREMIUM: 'PREMIUM',
    PREMIUM_PLUS: 'PREMIUM_PLUS',
    INSTITUTIONAL_ACCESS: 'INSTITUTIONAL_ACCESS'
};
exports.GRAMMAR_VIEW_MODES = {
    INDIVIDUAL: 'INDIVIDUAL',
    SENTENCE_WISE: 'SENTENCE-WISE',
    TRACK_CHANGES: 'TRACK-CHANGES'
};
exports.SDK_MODULES = {
    GRAMMAR: 'GRAMMAR',
    PARAPHRASER: 'PARAPHRASER',
    CONSISTENCY: 'CONSISTENCY'
};
exports.FILTERS = {
    'grammar': {
        id: 'grammar',
        title: 'Grammar',
        value: 1,
        isSelected: true,
        isNew: false,
        counter: 0,
        display: true,
        disabled: false,
        order: { 'regular': 0, 'legal': 5 },
        class: 'ilf-grammmar',
        browserExtensionTitle: 'grammar'
    },
    'spelling': {
        id: 'spelling',
        title: 'Spelling',
        value: 2,
        isSelected: true,
        isNew: false,
        counter: 0,
        display: true,
        disabled: false,
        order: { 'regular': 1, 'legal': 2 },
        class: '',
        browserExtensionTitle: 'spelling'
    },
    'writingAdvisor': {
        id: 'writingAdvisor',
        title: 'Writing Advisor',
        value: 3,
        isSelected: true,
        isNew: false,
        counter: 0,
        display: true,
        disabled: false,
        order: { 'regular': 2, 'legal': 6 },
        class: 'ilf-writing-advisor',
        browserExtensionTitle: 'advisor'
    },
    'enhancements': {
        id: 'enhancements',
        title: 'Enhancements',
        value: 4,
        isSelected: true,
        isNew: false,
        counter: 0,
        display: true,
        disabled: false,
        order: { 'regular': 3, 'legal': 3 },
        class: '',
        browserExtensionTitle: 'enhancement'
    },
    'styleGuide': {
        id: 'styleGuide',
        title: 'Style Guide',
        value: 5,
        isSelected: true,
        isNew: false,
        counter: 0,
        display: false,
        disabled: false,
        order: { 'regular': 4, 'legal': 1 },
        class: ''
    },
    'inclusiveLanguage': {
        id: 'inclusiveLanguage',
        title: 'Inclusive Language',
        value: 10,
        isSelected: false,
        isNew: true,
        counter: 0,
        display: false,
        disabled: false,
        order: { 'regular': 5, 'legal': 4 },
        class: '',
        browserExtensionTitle: 'inclusive_language'
    },
    'legalWritingStyle': {
        id: 'legalWritingStyle',
        title: 'Legal Writing Style',
        value: 11,
        isSelected: false,
        isNew: true,
        counter: 0,
        display: false,
        disabled: false,
        order: { 'regular': 6, 'legal': 0 },
        class: ''
    }
};
exports.CCFilterCheckList = [
    {
        name: "dashes",
        title: "Dashes",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    }, {
        name: "spelling",
        title: "Spelling",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "numbers",
        title: "Numbers",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "symbol",
        title: "Style",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    }, {
        name: "punctuations",
        title: "Punctuation",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    }, {
        name: "spacing",
        title: "Spacing",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "table_title",
        title: "Table title",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "figure_title",
        title: "Figure title",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "box_title",
        title: "Box title",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "boxes_title",
        title: "Boxes title",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "p_value",
        title: "P Value",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "accent_chars",
        title: "Accent characters",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "tables_title",
        title: "Tables title",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "figures_title",
        title: "Figures title",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    },
    {
        name: "symbol_spacing",
        title: "Symbol before spacing",
        disabled: false,
        isSelected: true,
        labelPosition: "after",
        flag: 1,
        errorCount: 0,
        display: true
    }
];
exports.EDITORS_LIBRARIES_TO_SUPPORT = {
    'ckeditor4': {
        detected: false,
        editorLibraryName: 'ckeditor4',
        detect: function () {
            this.detected = (typeof window.CKEDITOR !== 'undefined') && !!(typeof window.CKEDITOR.revision);
        },
        validateEditor: function (element) {
            var _a;
            const isValid = element.classList.contains('cke_editable');
            const version = isValid ? (_a = window.CKEDITOR) === null || _a === void 0 ? void 0 : _a.version : undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'ckeditor5': {
        detected: false,
        editorLibraryName: 'ckeditor5',
        detect: function () {
            this.detected = (typeof window.ClassicEditor !== 'undefined' ||
                typeof window.InlineEditor !== 'undefined' ||
                typeof window.BalloonEditor !== 'undefined' ||
                typeof window.DecoupledEditor !== 'undefined') ||
                (typeof window.CKEDITOR !== 'undefined' &&
                    (typeof window.CKEDITOR.ClassicEditor !== 'undefined' ||
                        typeof window.CKEDITOR.InlineEditor !== 'undefined' ||
                        typeof window.CKEDITOR.BalloonEditor !== 'undefined' ||
                        typeof window.CKEDITOR.DecoupledEditor !== 'undefined') ||
                    document.querySelectorAll('[data-cke]').length > 0);
        },
        validateEditor: function (element) {
            const isValid = element.classList.contains('ck-editor__editable');
            const version = undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'tinymce': {
        detected: false,
        editorLibraryName: 'tinymce',
        detect: function () {
            this.detected = (typeof window.tinymce !== 'undefined');
        },
        validateEditor: function (element) {
            var _a, _b, _c;
            const tinymceInstance = (_a = window.tinymce) === null || _a === void 0 ? void 0 : _a.get(element.getAttribute('data-id'));
            const isValid = !!tinymceInstance;
            const version = isValid ? `${(_b = window.tinymce) === null || _b === void 0 ? void 0 : _b.majorVersion}.${(_c = window.tinymce) === null || _c === void 0 ? void 0 : _c.minorVersion}` : undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'quill': {
        detected: false,
        editorLibraryName: 'quill',
        detect: function () {
            this.detected = (document.querySelector('.ql-editor') !== null);
        },
        validateEditor: function (element) {
            const isValid = element.classList.contains('ql-editor');
            const version = undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'summernote': {
        detected: false,
        editorLibraryName: 'summernote',
        detect: function () {
            this.detected = (typeof window.$ !== 'undefined' && typeof window.$.fn.summernote !== 'undefined');
        },
        validateEditor: function (element) {
            const isValid = element.classList.contains('note-editable');
            const version = undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'froala': {
        detected: false,
        editorLibraryName: 'froala',
        detect: function () {
            this.detected = (typeof window.$ !== 'undefined' && (typeof window.$.FE !== 'undefined' || typeof window.FroalaEditor !== 'undefined'));
        },
        validateEditor: function (element) {
            const isValid = element.classList.contains('fr-element');
            const version = undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'trumbowyg': {
        detected: false,
        editorLibraryName: 'trumbowyg',
        detect: function () {
            this.detected = (typeof window.$ !== 'undefined' && typeof window.$.trumbowyg !== 'undefined');
        },
        validateEditor: function (element) {
            const isValid = element.classList.contains('trumbowyg-editor');
            const version = undefined;
            return {
                isValid,
                editorLibraryName: this.editorLibraryName,
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    },
    'jodit': {
        detected: false,
        editorLibraryName: 'jodit',
        detect: function () {
            this.detected = (typeof window.Jodit !== 'undefined');
        },
        validateEditor: function (element) {
            const isValid = element.classList.contains('jodit_wysiwyg') || element.classList.contains('jodit-wysiwyg');
            const version = undefined;
            return {
                isValid,
                editorLibraryName: 'jodit',
                version,
                element,
                document: element.ownerDocument,
                id: element.id,
                classList: element.classList
            };
        }
    }
};
exports.ASK_POPOVER_OPTIONS = {
    write: {
        write_explanation: {
            title: 'Write Explanation',
            appId: 261,
            description: 'Provide a detailed explanation of the provided text, ensuring clarity and accuracy in the explanation. Maintain a neutral tone and ensure the explanation is grammatically correct.',
            nestedOptions: false
        },
        give_relevant_examples: {
            title: 'Give Relevant Examples',
            appId: 263,
            description: 'Generate relevant examples that illustrate and support the main points made in the provided text. Ensure these examples are directly connected to the content and enhance understanding. Maintain a neutral tone and check for grammatical correctness.',
            nestedOptions: false
        },
        give_counter_examples: {
            title: 'Give Counter Examples',
            appId: 264,
            description: 'Provide counter examples that challenge or offer alternative perspectives to the assertions made in the provided text. Ensure these examples are realistic and directly relevant. Maintain a neutral tone and check for grammatical correctness.',
            nestedOptions: false
        },
        keep_writing: {
            title: 'Keep Writing',
            appId: 265,
            description: 'Continue the provided text, maintaining the existing style, tone, and content direction. Ensure a seamless extension that feels like a natural continuation of the original text.',
            nestedOptions: false
        }
    },
    rewrite: {
        improve_fluency: {
            title: 'Improve Fluency',
            appId: 408,
            description: 'NA',
            nestedOptions: false
        },
        improve_clarity: {
            title: 'Improve Clarity',
            appId: 409,
            description: 'NA',
            nestedOptions: false
        },
        make_it_for_readable: {
            title: 'Improve Readability',
            appId: 274,
            description: 'Improve the readability of the provided text by simplifying complex sentences, reducing jargon, and ensuring a clear and coherent flow of ideas.',
            nestedOptions: false
        },
        make_it_longer: {
            title: 'Make it Longer',
            appId: 410,
            description: 'NA',
            nestedOptions: false
        },
        make_it_shorter: {
            title: 'Make it Shorter',
            appId: 411,
            description: 'NA',
            nestedOptions: false
        },
        tone: {
            title: 'Adjust Tone',
            appId: null,
            description: '',
            nestedOptions: true,
            options: {
                persuasive: {
                    title: 'Persuasive',
                    appId: 269,
                    description: 'Rewrite the provided text to have a persuasive tone. Use compelling language, emphasize key points, and structure arguments to convincingly persuade the reader.',
                    nestedOptions: false
                },
                authoritative: {
                    title: 'Authoritative',
                    appId: 412,
                    description: 'NA',
                    nestedOptions: false
                },
                neutral: {
                    title: 'Neutral',
                    appId: 413,
                    description: 'NA',
                    nestedOptions: false
                },
                critical: {
                    title: 'Critical',
                    appId: 414,
                    description: 'NA',
                    nestedOptions: false
                },
            }
        },
        voice: {
            title: 'Change Voice',
            appId: null,
            description: '',
            nestedOptions: true,
            options: {
                make_it_academic: {
                    title: 'Active Voice',
                    appId: 272,
                    description: 'Transform the provided text from passive to active voice. Focus on making sentences direct and dynamic, with subjects performing clear actions. Do not change its meaning and do not add new content.',
                    nestedOptions: false
                },
                persuasive: {
                    title: 'Passive Voice',
                    appId: 273,
                    description: 'Convert the provided text to passive voice. Emphasize the actions being performed on the subjects rather than by the subjects, adjusting sentence structure accordingly. Do not change its meaning.',
                    nestedOptions: false
                }
            }
        },
        paraphrase: {
            title: 'Paraphrase',
            appId: null,
            description: '',
            nestedOptions: true,
            options: {
                standard: {
                    title: 'Standard',
                    appId: 276,
                    description: 'Paraphrase the sentence given below. Do not change the meaning or add new content.',
                    nestedOptions: false
                },
                make_it_academic: {
                    title: 'Academic',
                    appId: 415,
                    description: 'Adjust the tone of the provided text to be more academic by incorporating formal language, precise terminology, and a structured format. Ensure the content remains factual and objective.',
                    nestedOptions: false
                },
                formal: {
                    title: 'Formal',
                    appId: 277,
                    description: 'Rewrite this sentence using a professional tone. Do not change its meaning and do not add new content.',
                    nestedOptions: false
                },
                concise: {
                    title: 'Concise',
                    appId: 278,
                    description: 'Make this sentence concise, but do not change the meaning.',
                    nestedOptions: false
                },
                simple: {
                    title: 'Simple',
                    appId: 279,
                    description: 'Rewrite this sentence using simple words. Do not change the meaning and do not add new content.',
                    nestedOptions: false
                }
            }
        }
    },
    analyse_argument: {
        strengthen_an_argument: {
            title: 'Strengthen the Argument',
            appId: 283,
            description: 'Enhance the arguments in the provided text by deepening the evidence, refining the reasoning, and improving the persuasive elements.',
            nestedOptions: false
        },
        identify_gaps: {
            title: 'Identify Assumptions',
            appId: 402,
            description: 'Analyze the provided text to identify any gaps in information, logic, or research. Highlight areas that lack supporting details, are not clearly explained, or require further evidence to strengthen the arguments.',
            nestedOptions: false
        },
        write_a_critique: {
            title: 'Write a Critique',
            appId: 403,
            description: 'Identify and present strong counterarguments to the positions held in the provided text, ensuring they are well-reasoned and supported by evidence.',
            nestedOptions: false
        },
    }
};
exports.ASK_INLINE_POPOVER_OPTIONS = {
    write: {
        keep_writing: {
            title: 'Keep Writing',
            appId: 285,
            description: 'Continue writing from where the provided text ends, maintaining the same style, tone, and thematic direction. Ensure a seamless flow and coherence with the original content, while developing the ideas further and keeping the reader engaged.',
            userPrompt: '',
            nestedOptions: false
        },
        write_a_paragraph: {
            title: 'Write a Paragraph',
            appId: 286,
            description: 'Compose a well-structured paragraph based on the key ideas from the provided text, ensuring it has a clear topic sentence, supporting sentences, and a concluding sentence.',
            userPrompt: 'Write a paragraph on',
            nestedOptions: false
        },
        create_an_outline: {
            title: 'Create an Outline',
            appId: 287,
            description: 'Develop a detailed outline for expanding the provided text into a longer document. Include main sections, subpoints, and a logical progression of ideas.',
            userPrompt: 'Create a outline for',
            nestedOptions: false
        },
        create_a_blog_post: {
            title: 'Create a Blog Post',
            appId: 288,
            description: 'Convert the provided text into a blog post format. Include an engaging introduction, informative body sections, and a compelling conclusion, adapting the content to suit a blog audience.',
            userPrompt: 'Create a blog post for',
            nestedOptions: false
        },
        write_a_critique: {
            title: 'Write a Critique',
            appId: 289,
            description: 'Write a critique of the provided text, analyzing its content, style, and structure. Highlight strengths, note weaknesses, and suggest improvements.',
            userPrompt: 'Write a critique on',
            nestedOptions: false
        },
        write_a_research_plan: {
            title: 'Write a Research Plan',
            appId: 290,
            description: 'Formulate a comprehensive research plan based on the provided text. Outline the research questions, methods, expected outcomes, and timeline.',
            userPrompt: 'Write a research plan for',
            nestedOptions: false
        },
        write_a_case_study: {
            title: 'Write a Case Study',
            appId: 291,
            description: 'Develop a case study from the provided text. Detail the background, the problem addressed, the approach taken, and the outcomes, including insights and lessons learned.',
            userPrompt: 'Write a case study for',
            nestedOptions: false
        }
    }
};
