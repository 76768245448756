"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrammarUI = void 0;
const WebSocketClient_1 = require("../connection/WebSocketClient");
const draggable_panel_1 = require("../components/draggable-panel/draggable-panel");
const utils_1 = require("../helpers/utils");
const constants_1 = require("../helpers/constants");
const editor_overlay_1 = require("../components/editor-overlay");
const alert_counter_1 = require("../components/alert-counter");
const limit_exhaust_badge_1 = require("../components/limit-exhaust-badge");
const underline_1 = require("../components/underline");
const overlay_card_1 = require("../components/overlay-card");
const grammar_worker_1 = require("../workers/grammar.worker");
require("../assets/styles.css");
const ask_1 = require("./ask");
const sentence_wise_editor_overlay_1 = require("../components/sentence-wise-editor-overlay");
const sentence_highlighter_1 = require("../components/sentence-highlighter");
const track_changes_editor_overlay_1 = require("../components/track-changes-editor-overlay");
class GrammarUI {
    constructor(trinkaConfig, grammarModuleConfig, uiConfig, hostPageInfo, cloudConfig) {
        this.listOfEditors = {};
        this.underlineBatchSize = 50;
        this.boundHandleFilterChange = ($event) => this.handleFilterChange($event);
        this.boundHandleUnderlineMouseOverEvent = ($event) => this.handleUnderlineMouseOverEvent($event);
        this.boundHandleUnderlineMouseOutEvent = ($event) => this.handleUnderlineMouseOutEvent($event);
        this.boundHandleUnderlineClickedEvent = ($event) => this.handleUnderlineClickedEvent($event);
        this.boundHandleDraggablePanelCardToggleEvent = ($event) => this.handleDraggablePanelCardToggleEvent($event);
        this.boundHandleDraggablePanelToggleEvent = ($event) => this.handleDraggablePanelToggleEvent($event);
        this.boundHandleViewModeChangedToggleEvent = ($event) => this.handleViewModeChangedEvent($event);
        this.boundHandleClickEventOnDocument = ($event) => this.handleClickEventOnDocument($event);
        this.boundHandleDraggablePanelSentenceWiseCardToggleEvent = ($event) => this.handleDraggablePanelSentenceWiseCardToggleEvent($event);
        this.hostPageInfo = {};
        this.cloudConfig = undefined;
        this.currentSelectedMode = constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL;
        this.langCategoryArr = ['Brevity', 'Capitalization & Spacing', 'Contractions', 'Punctuation', 'Regional Style', 'Spellings & Typos'];
        this.globalGroupedObj = {};
        this.isPasteEventStarted = false;
        this.resizeObserverCallback = (editorId) => {
            const editorInstance = this.listOfEditors[editorId];
            if (!editorInstance)
                return;
            editorInstance.components.editorOverlayInstance.handleResizeEvent();
            if (editorInstance.askPlugin) {
                editorInstance.askPlugin.handleResizeEvent();
            }
            if (editorInstance.isFirstResizeObserver) {
                editorInstance.isFirstResizeObserver = false;
            }
            else {
                this.updateEditorUnderlinesPositionInBatch(editorInstance);
            }
        };
        this.grammarModuleConfig = grammarModuleConfig;
        this.uiConfig = uiConfig;
        this.hostPageInfo = hostPageInfo;
        this.cloudConfig = cloudConfig;
        document.addEventListener('tsdk-draggable-panel-filter-update', this.boundHandleFilterChange);
        document.addEventListener('tsdk-editor-underline-mouse-over', this.boundHandleUnderlineMouseOverEvent);
        document.addEventListener('tsdk-editor-underline-mouse-out', this.boundHandleUnderlineMouseOutEvent);
        document.addEventListener('tsdk-editor-underline-clicked', this.boundHandleUnderlineClickedEvent);
        document.addEventListener('tsdk-draggable-panel-card-toggle', this.boundHandleDraggablePanelCardToggleEvent);
        document.addEventListener('tsdk-draggable-panel-toggle', this.boundHandleDraggablePanelToggleEvent);
        document.addEventListener('tsdk-view-mode-changed', this.boundHandleViewModeChangedToggleEvent);
        document.addEventListener('click', this.boundHandleClickEventOnDocument);
        document.addEventListener('tsdk-draggable-panel-sentence-wise-card-toggle', this.boundHandleDraggablePanelSentenceWiseCardToggleEvent);
    }
    generateListOfEvents(editorId) {
        const events = {
            'input': {
                name: 'input',
                boundHandler: (0, utils_1.debounce)(($event) => this._handleInputEvent($event, this.listOfEditors[editorId]), constants_1.EDITOR_CHANGE_DEBOUNCE_TIME)
            },
            'focusWithDebounce': {
                name: 'focus',
                boundHandler: (0, utils_1.debounce)(($event) => this._handleFocusWithDebounceEvent($event, this.listOfEditors[editorId]), constants_1.EDITOR_CHANGE_DEBOUNCE_TIME)
            },
            'focus': {
                name: 'focus',
                boundHandler: ($event) => this._handleFocusEvent($event, this.listOfEditors[editorId])
            },
            'blur': {
                name: 'blur',
                boundHandler: ($event) => this._handleBlurEvent($event, this.listOfEditors[editorId])
            },
            'keydown': {
                name: 'keydown',
                boundHandler: ($event) => this._handleKeydownEvent($event, this.listOfEditors[editorId])
            },
            'scroll': {
                name: 'scroll',
                boundHandler: ($event) => this._handleScrollEvent($event, this.listOfEditors[editorId])
            },
            'paste': {
                name: 'paste',
                boundHandler: ($event) => this._handlePasteEvent($event, this.listOfEditors[editorId])
            },
        };
        let listOfEvents = [];
        if (this.listOfEditors[editorId].editor.ckeditorInstance) {
            delete events.input;
            delete events.keydown;
        }
        Object.values(events).forEach(event => {
            listOfEvents.push({
                editorId,
                element: this.listOfEditors[editorId].editor,
                event: event.name,
                listener: event.boundHandler
            });
        });
        return listOfEvents;
    }
    initialize(editableElements) {
        this.overlayCardComponent = new overlay_card_1.OverlayCardComponent();
        document.body.appendChild(this.overlayCardComponent);
        this.overlayCardComponent.trinkaEditorConfig = this.grammarModuleConfig.trinkaEditorConfig;
        editableElements.forEach(editor => {
            if (this.validateEditor(editor)) {
                if (['TEXTAREA', 'INPUT'].includes(editor.nodeName)) {
                    editor = this.createDivMirror(editor);
                }
                const additionalEditorInfo = this.getAdditionalEditorInfo(editor);
                const editorId = (0, utils_1.generateRandomId)(16);
                this.listOfEditors[editorId] = this.onBoardEditor(editorId, editor, additionalEditorInfo);
                this.listOfEditors[editorId].listOfEvents = this.generateListOfEvents(editorId);
                const debounceHandleCKEditorInput = (0, utils_1.debounce)(() => this.handleCKEditorInput(this.listOfEditors[editorId]), constants_1.EDITOR_CHANGE_DEBOUNCE_TIME);
                if (this.listOfEditors[editorId].editor.ckeditorInstance) {
                    this.listOfEditors[editorId].editor.ckeditorInstance.model.document.on('change:data', (evt, batch) => {
                        debounceHandleCKEditorInput();
                        if (this.isPasteEventStarted)
                            this.isPasteEventStarted = false;
                        else
                            this.handleCKEditorInputWithoutDebounce(this.listOfEditors[editorId], batch.isTyping || batch.isUndo);
                    });
                    this.listOfEditors[editorId].editor.ckeditorInstance.editing.view.document.on('clipboardInput', (evt, data) => {
                        this.isPasteEventStarted = true;
                        setTimeout(() => {
                            (0, utils_1.addEventToTracingLog)('paste');
                        }, 200);
                    });
                }
                this.registerEditorEventListeners(this.listOfEditors[editorId]);
            }
        });
    }
    destroy() {
        this.removeAllEditorUnderLines();
        this.removeAllEditorEventListeners();
        this.removeAllEditorComponents();
        this.cleanUpForModule();
    }
    destroyAllEditors() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            const editorInstance = this.listOfEditors[editorId];
            this.destroyEditor(editorInstance);
        });
    }
    updateSocketEndpoint() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            const editorInstance = this.listOfEditors[editorId];
            editorInstance.socketClient.restartSocket(this.grammarModuleConfig.url);
        });
    }
    destroyEditor(editorInstance) {
        this.removeEditorUnderLines(editorInstance);
        this.removeEditorEventListeners(editorInstance);
        this.removeEditorComponents(editorInstance);
        this.cleanUpForEditor(editorInstance);
    }
    showLoader(editorInstance) {
        if (this.uiConfig.counter) {
            editorInstance.components.alertCounter.loader = true;
        }
        editorInstance.components.draggablePanel.loader = true;
    }
    hideLoader(editorInstance) {
        if (this.uiConfig.counter) {
            editorInstance.components.alertCounter.loader = false;
        }
        editorInstance.components.draggablePanel.loader = false;
    }
    getEditorAlertCount(editorInstance) {
        const filterList = JSON.parse(JSON.stringify(editorInstance.components.draggablePanel.filters));
        let alertCount = {};
        Object.keys(filterList).forEach((filter) => {
            alertCount[filter] = editorInstance.components.draggablePanel.alertList
                .filter(alert => {
                if (!alert.suggestions || !alert.suggestions[0])
                    return false;
                return filterList[filter].value === alert.suggestions[0].type;
            }).length;
        });
        alertCount['total'] = Object.values(alertCount)
            .reduce((partialSum = 0, currentValue = 0) => partialSum + currentValue, 0);
        return alertCount;
    }
    async handleUnderlineMouseOverEvent($event) {
        if (!$event.detail.editorId || !$event.detail.alertId || !$event.detail.target)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        const alertId = $event.detail.alertId;
        const target = $event.detail.target;
        if (editorInstance.components.draggablePanel.isOpen)
            return;
        let underline = editorInstance.underlines.get(alertId);
        if (!underline)
            return;
        underline.selectUnderline();
        let eventBound = target.getBoundingClientRect();
        this.overlayCardComponent.isOpen = true;
        this.overlayCardComponent.visibility = 'hidden';
        let position = await underline.getPopupPosition(eventBound);
        this.overlayCardComponent.editorId = underline.editorId;
        this.overlayCardComponent.alert = underline.alert;
        this.overlayCardComponent.currentIndexOfSuggestion = 0;
        this.overlayCardComponent.top = position === null || position === void 0 ? void 0 : position.top;
        this.overlayCardComponent.left = position === null || position === void 0 ? void 0 : position.left;
        setTimeout(() => {
            this.overlayCardComponent.visibility = 'visible';
        }, 20);
    }
    handleUnderlineMouseOutEvent($event) {
        if (!$event.detail.editorId || !$event.detail.alertId)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        const alertId = $event.detail.alertId;
        const originalEvent = $event.detail.$event;
        if (editorInstance.components.draggablePanel.isOpen)
            return;
        let underline = editorInstance.underlines.get(alertId);
        if (!underline)
            return;
        if (editorInstance.components.draggablePanel.isOpen)
            return;
        underline.unselectUnderline();
        if ((originalEvent.relatedTarget != null) && originalEvent.relatedTarget.nodeName.toLowerCase() !== 'trinka-card') {
            this.overlayCardComponent.isOpen = false;
            this.overlayCardComponent.currentIndexOfSuggestion = 0;
        }
    }
    handleUnderlineClickedEvent($event) {
        if (!$event.detail.editorId || !$event.detail.alertId)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        const alertId = $event.detail.alertId;
        this.unselectEditorAllUnderlines(editorInstance);
        setTimeout(() => {
            if (editorInstance.underlines.has(alertId)) {
                editorInstance.underlines.get(alertId).selectUnderline();
            }
            editorInstance.components.draggablePanel.scrollToElementById(alertId);
        }, 50);
    }
    unselectEditorAllUnderlines(editorInstance) {
        const listOfSelectedUnderlines = editorInstance.components.editorOverlayInstance.editorOverlayElements.querySelectorAll(`.selected`);
        listOfSelectedUnderlines.forEach(underline => {
            if (editorInstance.underlines.has(underline.id)) {
                editorInstance.underlines.get(underline.id).unselectUnderline();
            }
        });
    }
    unselectEditorAllSentences(editorInstance) {
        editorInstance.components.draggablePanel.sentenceWiseCloseAllCards();
        editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements
            .querySelectorAll(`.highlight.selected`)
            .forEach(el => { el.classList.remove('selected'); });
    }
    handleDraggablePanelCardToggleEvent($event) {
        if (!$event.detail.editorId || !$event.detail.alertId)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        const alertId = $event.detail.alertId;
        const isOpen = $event.detail.isOpen;
        let scrollableElement = editorInstance.editor;
        if (editorInstance.underlines.has(alertId)) {
            if (isOpen) {
                this.unselectEditorAllUnderlines(editorInstance);
                editorInstance.underlines.get(alertId).selectUnderline();
                editorInstance.underlines.get(alertId).setCursorPosition({}, 'at-start');
                const underline = editorInstance.underlines.get(alertId).underlines[0];
                if (!underline)
                    return;
                if ((0, utils_1.isUnderlineInViewport)(scrollableElement, underline)) {
                    return;
                }
                const underlineRect = underline.getBoundingClientRect();
                const editorRect = scrollableElement.getBoundingClientRect();
                const top = underlineRect.top - editorRect.top + scrollableElement.scrollTop;
                scrollableElement.scrollTop = Math.abs(top);
            }
            else {
                editorInstance.underlines.get(alertId).unselectUnderline();
            }
        }
    }
    generateSentenceBounds(selectedNodes) {
        const range = document.createRange();
        try {
            range.setStart(selectedNodes.startNode, selectedNodes.startOffset);
            range.setEnd(selectedNodes.endNode, selectedNodes.endOffset);
        }
        catch (error) {
            console.error('[DEBUG] generateSentenceBounds error ===> ', error);
        }
        let newRange = range.getBoundingClientRect();
        return newRange;
    }
    incrementCounterByType(mappings, type) {
        let mapping = mappings.find(item => item.type === type);
        if (mapping) {
            mapping.counter += 1;
        }
    }
    updateSentenceWiseList(editorInstance, sentence, segment) {
        const currentSelection = (0, utils_1.getSelectedFilters)(editorInstance.components.draggablePanel.filters);
        const sentenceId = sentence.sentenceId;
        sentence.alerts = editorInstance.sentenceToAlertsMap.get(sentenceId) || {};
        if (!Object.keys(sentence.alerts).length)
            return;
        const sentenceHighlighter = new sentence_highlighter_1.SentenceHighlighter(editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements, editorInstance.components.draggablePanel, sentence, segment);
        let sentenceText = sentence.sentence;
        let newSentence = `<sentence id="${sentenceId}" data-begin="${sentence.begin}" data-end="${sentence.end}" selected="false">[[SENTENCE-PLACEHOLDER]]</sentence>`;
        let alerts = Object.keys(sentence.alerts);
        const mapping = [
            { type: 1, category: 'Grammar', counter: 0 },
            { type: 2, category: 'Spelling', counter: 0 },
            { type: 3, category: 'Writing Advisor', counter: 0 },
            { type: 4, category: 'Enhancement', counter: 0 },
            { type: 5, category: 'Style Guide', counter: 0 },
            { type: 10, category: 'Inclusive Language', counter: 0 },
            { type: 11, category: 'Legal Writing', counter: 0 }
        ];
        const sortedAlerts = alerts
            .filter(alert_uuid => {
            var _a, _b, _c, _d, _e, _f;
            let alert = sentence.alerts[alert_uuid];
            if (!alert.suggestions || !alert.suggestions[0])
                return false;
            return currentSelection.includes(alert.suggestions[0].type.toString()) &&
                alert.suggestions[0].type !== 3 &&
                (alert.suggestions[0].type === 10 &&
                    ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.inclusiveErrorCategories) &&
                    ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.inclusiveErrorCategories.length) > 0 ?
                    ((_f = (_e = this.grammarModuleConfig) === null || _e === void 0 ? void 0 : _e.trinkaEditorConfig) === null || _f === void 0 ? void 0 : _f.inclusiveErrorCategories.indexOf(alert.suggestions[0].error_category)) !== -1 :
                    true);
        }).sort((a, b) => {
            return sentence.alerts[a].begin - sentence.alerts[b].begin;
        });
        if (!sortedAlerts.length)
            return;
        sortedAlerts.slice().reverse().forEach(alert_uuid => {
            let alert = sentence.alerts[alert_uuid];
            let isReplacementAvailable = alert.suggestions[0].cta_present && alert.suggestions[0].suggestion;
            let isDelete = alert.suggestions[0].cta_present && !alert.suggestions[0].suggestion;
            sentenceText = [
                sentenceText.slice(0, alert.begin),
                `<alert alert-id="${alert_uuid}">`,
                (isReplacementAvailable || isDelete ? `<strikethrough-text>${sentenceText.slice(alert.begin, alert.end + 1)}</strikethrough-text> ${isDelete ? '' : `<replacement-text>${alert.suggestions[0].suggestion}</replacement-text>`}` : sentenceText.slice(alert.begin, alert.end + 1)),
                '</alert>',
                sentenceText.slice(alert.end + 1, sentenceText.length)
            ].join('');
            this.incrementCounterByType(mapping, alert.suggestions[0].type);
        });
        const sentenceWithAlerts = newSentence.replace('[[SENTENCE-PLACEHOLDER]]', sentenceText);
        return Object.assign(Object.assign({}, sentence), { segmentId: segment.segmentId, segmentPosition: segment.position, sentenceWithAlerts, alertsCount: mapping.filter(category => category.counter), sentenceHighlighter });
    }
    mappingCleanup(editorInstance) {
        function filterMappingInPlace(map, validIds) {
            let deletedSentences = [];
            map.forEach((_, key) => {
                if (!validIds.includes(key)) {
                    const segmentValue = map.get(key);
                    Object.keys(segmentValue).forEach(sentenceId => {
                        deletedSentences.push(sentenceId);
                    });
                    map.delete(key);
                    deletedSentences.forEach(sentenceId => {
                        editorInstance.sentenceToAlertsMap.delete(sentenceId);
                        const index = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.findIndex(alert => alert.sentenceId === sentenceId);
                        if (index !== -1) {
                            const sentenceHighlighter = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.splice(index, 1)[0];
                            sentenceHighlighter.sentenceHighlighter.destroy();
                            editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList]
                                .sort((a, b) => {
                                if (a.segmentPosition !== b.segmentPosition) {
                                    return a.segmentPosition - b.segmentPosition;
                                }
                                else {
                                    return a.begin - b.begin;
                                }
                            });
                        }
                    });
                }
            });
        }
        const validIds = Object.keys(editorInstance.editorDOMTreeNodes).map(segment => editorInstance.editorDOMTreeNodes[segment].segmentId);
        filterMappingInPlace(editorInstance.segmentToSentencesMap, validIds);
    }
    handleViewModeChangedEvent($event) {
        const detail = $event.detail;
        if (!detail.editorId || !detail.mode)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        editorInstance.components.draggablePanel.viewMode = detail.mode;
        this.currentSelectedMode = detail.mode;
        switch (detail.mode) {
            case constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL: {
                this.unselectEditorAllSentences(editorInstance);
                setTimeout(() => {
                    this.openFirstAlert(editorInstance);
                }, 1000);
                editorInstance.components.sentenceWiseEditorOverlayInstance.changeOverlayVisibility(false);
                editorInstance.components.trackChangesEditorOverlayInstance.editorOverlayElements.innerHTML = '';
                editorInstance.components.trackChangesEditorOverlayInstance.changeOverlayVisibility(false);
                break;
            }
            case constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE: {
                this.unselectEditorAllUnderlines(editorInstance);
                editorInstance.components.trackChangesEditorOverlayInstance.editorOverlayElements.innerHTML = '';
                editorInstance.components.trackChangesEditorOverlayInstance.changeOverlayVisibility(false);
                this.mappingCleanup(editorInstance);
                editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.forEach(alert => {
                    alert.sentenceHighlighter.destroy();
                });
                editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [];
                editorInstance.components.sentenceWiseEditorOverlayInstance.changeOverlayVisibility(true);
                editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements.innerHTML = '';
                const sentenceWiseAlertList = [];
                Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                    const segment = editorInstance.editorDOMTreeNodes[text];
                    const sentences = editorInstance.segmentToSentencesMap.get(segment.segmentId);
                    if (sentences) {
                        Object.keys(sentences).forEach((sentenceKey) => {
                            const sentence = sentences[sentenceKey];
                            const sentenceHighlighter = this.updateSentenceWiseList(editorInstance, sentence, segment);
                            if (sentenceHighlighter) {
                                sentenceWiseAlertList.push(sentenceHighlighter);
                            }
                        });
                    }
                });
                editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList = [...sentenceWiseAlertList]
                    .sort((a, b) => {
                    if (a.segmentPosition !== b.segmentPosition) {
                        return a.segmentPosition - b.segmentPosition;
                    }
                    else {
                        return a.begin - b.begin;
                    }
                });
                const fragment = document.createDocumentFragment();
                for (let i = 0; i < editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.length; i++) {
                    const sentence = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[i];
                    sentence.sentenceHighlighter.highlighters.forEach(highlighter => {
                        fragment.appendChild(highlighter.highlightContainer);
                    });
                }
                setTimeout(() => {
                    editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements.appendChild(fragment);
                }, 0);
                setTimeout(() => {
                    this.openSentenceWiseFirstAlert(editorInstance);
                    this.refreshSentenceWiseAlert(editorInstance);
                }, 1000);
                break;
            }
            case constants_1.GRAMMAR_VIEW_MODES.TRACK_CHANGES: {
                const editor = editorInstance.editor;
                if (!editor.ckeditorInstance)
                    return;
                editorInstance.components.sentenceWiseEditorOverlayInstance.changeOverlayVisibility(false);
                editorInstance.components.trackChangesEditorOverlayInstance.changeOverlayVisibility(true);
                const generateSearchKeyForTrinkaConfig = () => {
                    return `${this.grammarModuleConfig.trinkaEditorConfig.language}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}#${this.grammarModuleConfig.trinkaEditorConfig.style_guide.value}#${this.grammarModuleConfig.trinkaEditorConfig.document_type}#${this.grammarModuleConfig.trinkaEditorConfig.power_mode}#${this.grammarModuleConfig.trinkaEditorConfig.instructionModule.module}#${this.grammarModuleConfig.trinkaEditorConfig.instructionModule.instruction}`;
                };
                const editorDOMTreeNodes = editorInstance.editorDOMTreeNodes;
                const parser = new DOMParser();
                const doc = parser.parseFromString(editor.ckeditorInstance.getData(), 'text/html');
                let segmentToSentencesMap = new Map();
                let sentenceToAlertsMap = new Map();
                let promises = [];
                const findNodesByText = (doc, searchText) => {
                    const iterator = document.createNodeIterator(doc, NodeFilter.SHOW_TEXT, {
                        acceptNode: (node) => {
                            return node.nodeValue.includes(searchText)
                                ? NodeFilter.FILTER_ACCEPT
                                : NodeFilter.FILTER_REJECT;
                        }
                    });
                    const matchingNodes = [];
                    let currentNode;
                    while ((currentNode = iterator.nextNode())) {
                        matchingNodes.push(currentNode.parentNode);
                    }
                    return matchingNodes;
                };
                editorInstance.sendJsonRpcRequest('waitForDBOpen')
                    .then((waitForDBOpenResult) => {
                    editorInstance.sendJsonRpcRequest('scanCache', {
                        storeName: 'spt',
                        indexKey: 'searchKey',
                        listOfSearchKey: Object.keys(editorDOMTreeNodes).map(segment => `${segment}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`)
                    }).then((scanCacheResult) => {
                        const cachedTreeNodes = Object.keys(editorDOMTreeNodes)
                            .filter(segment => scanCacheResult.cached.some(item => item.text === segment));
                        for (let i = 0; i < cachedTreeNodes.length; i++) {
                            const segment = editorDOMTreeNodes[cachedTreeNodes[i]];
                            const cachedSegment = scanCacheResult.cached.find(item => item.text === cachedTreeNodes[i]);
                            const searchKey = `${segment.text}#${this.grammarModuleConfig.trinkaEditorConfig.langCode}`;
                            segment.updateRequired = false;
                            if (!segmentToSentencesMap.has(segment.segmentId)) {
                                segmentToSentencesMap.set(segment.segmentId, {});
                            }
                            const promise = editorInstance.sendJsonRpcRequest('scanCache', {
                                storeName: 'ck',
                                indexKey: 'searchKey',
                                listOfSearchKey: cachedSegment.response.map(sentence => `${sentence.sentence}#${generateSearchKeyForTrinkaConfig()}`)
                            }).then((scanCacheCheckResult) => {
                                const cachedSentences = scanCacheCheckResult.cached
                                    .filter(sentence => cachedSegment.response.some(item => sentence.sentence === item.sentence));
                                cachedSentences.forEach((cachedSentence) => {
                                    var _a;
                                    const sentence = cachedSegment.response.find(item => item.sentence === cachedSentence.sentence);
                                    const sentenceId = (0, utils_1.generateRandomId)(10);
                                    const newSentence = Object.assign(Object.assign({ alerts: {} }, sentence), { sentenceId, segmentId: segment.segmentId });
                                    segmentToSentencesMap.get(segment.segmentId)[sentenceId] = newSentence;
                                    if (!sentenceToAlertsMap.has(sentenceId)) {
                                        sentenceToAlertsMap.set(sentenceId, {});
                                    }
                                    (_a = cachedSentence === null || cachedSentence === void 0 ? void 0 : cachedSentence.response) === null || _a === void 0 ? void 0 : _a.forEach((alert) => {
                                        const alertId = (0, utils_1.generateRandomId)(10);
                                        const newAlert = Object.assign(Object.assign({}, alert), { alertId, sentenceId, segmentId: segment.segmentId, sentencePosition: sentence.begin, segmentPosition: segment.position, sentenceText: sentence.sentence });
                                        sentenceToAlertsMap.get(sentenceId)[alertId] = newAlert;
                                    });
                                });
                            });
                            promises.push(promise);
                        }
                        Promise.allSettled(promises).then(() => {
                            Object.keys(editorDOMTreeNodes).forEach((segmentKey) => {
                                const segment = editorDOMTreeNodes[segmentKey];
                                const sentences = segmentToSentencesMap.get(segment.segmentId) || {};
                                let segmentWithAlerts = ``;
                                if (!Object.keys(sentences).length)
                                    return;
                                Object.keys(sentences).forEach((sentenceId) => {
                                    const sentence = sentences[sentenceId];
                                    sentence.alerts = sentenceToAlertsMap.get(sentenceId);
                                    let alerts = Object.keys(sentence.alerts);
                                    let sentenceText = sentence.sentence;
                                    let newSentence = `<sentence id="${sentenceId}" data-begin="${sentence.begin}" data-end="${sentence.end}" selected="false">[[SENTENCE-PLACEHOLDER]]</sentence>`;
                                    if (!Object.keys(sentence.alerts).length) {
                                        sentenceText = sentence.sentence;
                                    }
                                    else {
                                        let alerts = Object.keys(sentence.alerts);
                                        const sortedAlerts = alerts.sort((a, b) => {
                                            return sentence.alerts[a].begin - sentence.alerts[b].begin;
                                        });
                                        sortedAlerts.slice().reverse().forEach(alert_uuid => {
                                            let alert = sentence.alerts[alert_uuid];
                                            let isReplacementAvailable = alert.suggestions[0].cta_present && alert.suggestions[0].suggestion;
                                            let isDelete = alert.suggestions[0].cta_present && !alert.suggestions[0].suggestion;
                                            sentenceText = [
                                                sentenceText.slice(0, alert.begin),
                                                `<alert alert-id="${alert_uuid}">`,
                                                (isReplacementAvailable || isDelete ? `<strikethrough-text>${sentenceText.slice(alert.begin, alert.end + 1)}</strikethrough-text> ${isDelete ? '' : `<replacement-text>${alert.suggestions[0].suggestion}</replacement-text>`}` : sentenceText.slice(alert.begin, alert.end + 1)),
                                                '</alert>',
                                                sentenceText.slice(alert.end + 1, sentenceText.length)
                                            ].join('');
                                        });
                                    }
                                    const sentenceWithAlerts = newSentence.replace('[[SENTENCE-PLACEHOLDER]]', sentenceText);
                                    segmentWithAlerts += sentenceWithAlerts;
                                });
                                const newSegment = document.createElement('p');
                                newSegment.innerHTML = segmentWithAlerts;
                                editorInstance.components.trackChangesEditorOverlayInstance.editorOverlayElements.appendChild(newSegment);
                            });
                        });
                    });
                });
                break;
            }
            default:
                break;
        }
    }
    openFirstAlert(editorInstance) {
        editorInstance.components.draggablePanel.openFirstAlert();
    }
    openSentenceWiseFirstAlert(editorInstance) {
        editorInstance.components.draggablePanel.openSentenceWiseFirstAlert();
    }
    refreshSentenceWiseAlert(editorInstance) {
        this.updateEditorSentencewiseHighlighterPositionInBatch(editorInstance);
    }
    handleDraggablePanelToggleEvent($event) {
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        const isOpen = $event.detail.isOpen;
        if (isOpen) {
            this.unselectEditorAllSentences(editorInstance);
            this.openFirstAlert(editorInstance);
            setTimeout(() => {
                this.openSentenceWiseFirstAlert(editorInstance);
                this.refreshSentenceWiseAlert(editorInstance);
            }, 1000);
            editorInstance.components.draggablePanel.open();
            editorInstance.components.sentenceWiseEditorOverlayInstance.changeOverlayVisibility(true);
        }
        else {
            this.unselectEditorAllUnderlines(editorInstance);
            editorInstance.components.draggablePanel.close();
            editorInstance.components.sentenceWiseEditorOverlayInstance.changeOverlayVisibility(false);
        }
    }
    handleDraggablePanelSentenceWiseCardToggleEvent($event) {
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        const alertId = $event.detail.alertId;
        const isOpen = $event.detail.isOpen;
        let scrollableElement = editorInstance.editor;
        this.unselectEditorAllSentences(editorInstance);
        if (isOpen) {
            const listOfHighlight = editorInstance.components.sentenceWiseEditorOverlayInstance.editorOverlayElements
                .querySelectorAll(`[data-group="sentence-${alertId}"]`);
            listOfHighlight.forEach(el => {
                el.classList.add('selected');
            });
            if (listOfHighlight.length) {
                const firstHighlight = listOfHighlight[0];
                if (!firstHighlight)
                    return;
                if ((0, utils_1.isUnderlineInViewport)(scrollableElement, firstHighlight)) {
                    return;
                }
                const firstHighlightRect = firstHighlight.getBoundingClientRect();
                const editorRect = scrollableElement.getBoundingClientRect();
                const top = firstHighlightRect.top - editorRect.top + scrollableElement.scrollTop;
                scrollableElement.scrollTop = Math.abs(top);
            }
        }
    }
    validateEditor(element) {
        var _a, _b, _c;
        let isWhitelistEditorOptionEnabled = false;
        let foundWhitelistEditor = false;
        if (((_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.whitelistEditors) && ((_b = this.grammarModuleConfig) === null || _b === void 0 ? void 0 : _b.whitelistEditors.length)) {
            isWhitelistEditorOptionEnabled = true;
            for (let i = 0; i < this.grammarModuleConfig.whitelistEditors.length; i++) {
                let allElementsWithSelector = (_c = element === null || element === void 0 ? void 0 : element.ownerDocument) === null || _c === void 0 ? void 0 : _c.querySelectorAll(this.grammarModuleConfig.whitelistEditors[i]);
                for (let j = 0; j < allElementsWithSelector.length; j++) {
                    foundWhitelistEditor = allElementsWithSelector[j] === element;
                }
            }
        }
        if (element.getAttribute(constants_1.EDITOR_ATTRIBUTES.DATA_TRINKA) === 'false') {
            return false;
        }
        else if (element.hasAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID)) {
            return false;
        }
        else if (isWhitelistEditorOptionEnabled && !foundWhitelistEditor) {
            return false;
        }
        return true;
    }
    getAdditionalEditorInfo(editor) {
        let additionalEditorInfo = null;
        if (!this.hostPageInfo.isEditorLibraryPresent) {
        }
        else {
            const supportedEditorsLibraries = Object.keys(this.hostPageInfo.supportedEditorsLibraries);
            for (let index = 0; index < supportedEditorsLibraries.length; index++) {
                additionalEditorInfo = this.hostPageInfo.supportedEditorsLibraries[supportedEditorsLibraries[index]].validateEditor(editor);
                if (additionalEditorInfo && additionalEditorInfo.isValid) {
                    break;
                }
            }
        }
        if (additionalEditorInfo && !additionalEditorInfo.isValid) {
            additionalEditorInfo = null;
        }
        return additionalEditorInfo;
    }
    onBoardEditor(editorId, editor, additionalEditorInfo) {
        editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorId);
        editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
        const flagForCloudTrinka = this.grammarModuleConfig.isBrowserExtension ? false : this.hostPageInfo.isCloudTrinka;
        const draggablePanel = new draggable_panel_1.DraggablePanel(editorId, { userPlan: this.grammarModuleConfig.userPlan, isCloudTrinka: flagForCloudTrinka });
        const editorOverlayInstance = new editor_overlay_1.EditorOverlay(editorId, editor, this.hostPageInfo.isCloudTrinka, this.grammarModuleConfig.isBrowserExtension);
        const sentenceWiseEditorOverlayInstance = new sentence_wise_editor_overlay_1.SentenceWiseEditorOverlay(editorId, editor, this.hostPageInfo.isCloudTrinka);
        const trackChangesEditorOverlayInstance = new track_changes_editor_overlay_1.TrackChangesEditorOverlay(editorId, editor, this.hostPageInfo.isCloudTrinka);
        const alertCounter = new alert_counter_1.AlertCounter(this.uiConfig, editorId);
        const limitExhaustBadge = new limit_exhaust_badge_1.LimitExhaustBadge();
        document.body.appendChild(draggablePanel);
        editorOverlayInstance.editorOverlay.appendChild(alertCounter);
        if (this.grammarModuleConfig.isBrowserExtension)
            editorOverlayInstance.editorOverlay.appendChild(limitExhaustBadge);
        draggablePanel.filters = JSON.parse(JSON.stringify(constants_1.FILTERS));
        draggablePanel.trinkaEditorConfig = this.grammarModuleConfig.trinkaEditorConfig;
        draggablePanel.cloudConfig = this.cloudConfig;
        draggablePanel.showOfflineModel = this.grammarModuleConfig.showOfflineModel;
        let url = '';
        if (!this.hostPageInfo.isCloudTrinka) {
            url = `${this.grammarModuleConfig.url}?token=${this.grammarModuleConfig.socketToken}`;
            draggablePanel.filters.inclusiveLanguage.display = true;
            draggablePanel.filters.inclusiveLanguage.isSelected = true;
            if (this.grammarModuleConfig.filtering) {
                Object.keys(this.grammarModuleConfig.filtering).forEach(filter => {
                    if (draggablePanel.filters[filter]) {
                        const state = !!this.grammarModuleConfig.filtering[filter];
                        draggablePanel.filters[filter].display = state;
                        draggablePanel.filters[filter].isSelected = state;
                    }
                });
            }
        }
        else {
            url = this.grammarModuleConfig.url;
            if (this.grammarModuleConfig.trinkaEditorConfig && this.grammarModuleConfig.trinkaEditorConfig.style_guide.value !== `NONE`) {
                draggablePanel.filters.styleGuide.title = `${this.grammarModuleConfig.trinkaEditorConfig.style_guide.title} Style`;
                draggablePanel.filters.styleGuide.display = true;
            }
            else {
                draggablePanel.filters.styleGuide.display = false;
            }
            if (this.grammarModuleConfig.trinkaEditorConfig && this.grammarModuleConfig.trinkaEditorConfig.document_type == 4) {
                draggablePanel.isLegal = true;
                draggablePanel.filters.legalWritingStyle.isSelected = true;
                draggablePanel.filters.legalWritingStyle.display = true;
                draggablePanel.filters.grammar.isSelected = false;
                draggablePanel.filters.grammar.disabled = true;
                draggablePanel.filters.writingAdvisor.isSelected = false;
                draggablePanel.filters.writingAdvisor.disabled = true;
            }
            else {
                draggablePanel.isLegal = false;
                draggablePanel.filters.legalWritingStyle.isSelected = false;
                draggablePanel.filters.legalWritingStyle.display = false;
                draggablePanel.filters.grammar.isSelected = true;
                draggablePanel.filters.grammar.disabled = false;
                draggablePanel.filters.writingAdvisor.isSelected = true;
                draggablePanel.filters.writingAdvisor.disabled = false;
            }
            if (this.grammarModuleConfig.isBrowserExtension) {
                draggablePanel.filters.inclusiveLanguage.display = true;
                draggablePanel.filters.inclusiveLanguage.isSelected = true;
            }
        }
        function showLoader(editorId) {
            that.showLoader(that.listOfEditors[editorId]);
        }
        function hideLoader(editorId) {
            that.hideLoader(that.listOfEditors[editorId]);
        }
        const grammarApis = {
            showLoader: (editorId) => showLoader(editorId),
            hideLoader: (editorId) => hideLoader(editorId),
        };
        const that = this;
        const grammmarWorkerPendingRequests = {};
        const grammarWorker = new Worker(grammar_worker_1.default);
        function sendJsonRpcRequest(method, params = {}) {
            const requestId = Date.now() + Math.random();
            const request = {
                jsonrpc: "2.0",
                method: method,
                params: params,
                id: requestId,
                type: 'request'
            };
            return new Promise((resolve, reject) => {
                grammmarWorkerPendingRequests[requestId] = { resolve, reject };
                grammarWorker.postMessage(request);
            });
        }
        function sendResponse(id, result, error = null) {
            grammarWorker.postMessage({
                jsonrpc: "2.0",
                result: result,
                error: error,
                id: id,
                type: 'response'
            });
        }
        grammarWorker.onmessage = async function (event) {
            const { id, method, params, type, result, error } = event.data;
            if (type === 'response' && grammmarWorkerPendingRequests[id]) {
                const { resolve, reject } = grammmarWorkerPendingRequests[id];
                if (error) {
                    reject(new Error(error.message));
                }
                else {
                    resolve(result);
                }
                delete grammmarWorkerPendingRequests[id];
            }
            else if (type === 'request' && grammarApis[method]) {
                const api = grammarApis[method];
                if (api) {
                    try {
                        const result = await api(params);
                        sendResponse(id, result);
                    }
                    catch (error) {
                        sendResponse(id, null, { code: -32603, message: error.message });
                    }
                }
                else {
                    sendResponse(id, null, { code: -32601, message: "Method not found" });
                }
            }
        };
        sendJsonRpcRequest('intiateWorker', { editorId, socketUrl: url, dbName: 'trinkaBg' })
            .then(result => { })
            .catch(error => console.error('Error:', error));
        let askPlugin = null;
        if (this.grammarModuleConfig.askEnabled && this.grammarModuleConfig.token) {
            askPlugin = new ask_1.Ask(editor, editorId, { token: this.grammarModuleConfig.token, userPlan: this.grammarModuleConfig.userPlan }, this.hostPageInfo, constants_1.SDK_MODULES.GRAMMAR);
        }
        const editorInstance = {
            editorId,
            editor,
            editorType: editor.nodeName.toLowerCase(),
            editorDOMTreeNodes: {},
            editorDOMTreeNodeCounter: Object.keys(this.listOfEditors).length * 10000,
            listOfEvents: [],
            segmentToSentencesMap: new Map(),
            sentenceToAlertsMap: new Map(),
            underlines: new Map(),
            components: {
                draggablePanel,
                editorOverlayInstance,
                alertCounter,
                limitExhaustBadge,
                sentenceWiseEditorOverlayInstance,
                trackChangesEditorOverlayInstance
            },
            askPlugin,
            socketClient: new WebSocketClient_1.WebSocketClient(url),
            resizeObserver: new ResizeObserver((0, utils_1.debounce)(() => this.resizeObserverCallback(editorId), constants_1.EDITOR_RESIZE_DEBOUNCE_TIME)),
            isFirstResizeObserver: true,
            latestContenteditableDOM: {},
            isScanningInProgress: false,
            isUnderlineRepositioningInProgress: false,
            changeDetectionQueue: [],
            additionalEditorInfo,
            grammarWorker,
            sendJsonRpcRequest,
            ticking: false,
            isEditorScrollInProgress: false,
            editorScrollTimeout: null,
            underlineRepositioningProcessId: null,
            isUnderlineRepositioningCancelRequested: false,
            pauseGEC: false,
            editorText: ''
        };
        editorInstance.components['overlayCardComponent'] = this.overlayCardComponent;
        editorInstance.resizeObserver.observe(editor);
        return editorInstance;
    }
    triggerEditorInputEventManually(editorInstance, updateTextFlag = false) {
        var _a;
        const event = new CustomEvent('tsdk-editor-input-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: editorInstance.editorId
            }
        });
        if (updateTextFlag || editorInstance.editorText.length === 0)
            editorInstance.editorText = (_a = editorInstance === null || editorInstance === void 0 ? void 0 : editorInstance.editor) === null || _a === void 0 ? void 0 : _a.textContent;
        document.dispatchEvent(event);
    }
    _handleInputEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        if (this.isPasteEventStarted)
            this.isPasteEventStarted = false;
        else
            (0, utils_1.addEventToTracingLog)('input');
        this.triggerEditorInputEventManually(editorInstance, true);
    }
    _handleFocusWithDebounceEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        this.triggerEditorInputEventManually(editorInstance);
    }
    _updateCKEditor5Attributes(editorInstance) {
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorInstance.editorId);
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
    }
    _handleFocusEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        if (editorInstance.additionalEditorInfo && editorInstance.additionalEditorInfo.editorLibraryName === 'ckeditor5') {
            const spellCheckInterval = setInterval(() => {
                this._updateCKEditor5Attributes(editorInstance);
            }, 100);
            setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
        }
    }
    _handleBlurEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        if (editorInstance.additionalEditorInfo && editorInstance.additionalEditorInfo.editorLibraryName === 'ckeditor5') {
            const spellCheckInterval = setInterval(() => {
                this._updateCKEditor5Attributes(editorInstance);
            }, 100);
            setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
        }
    }
    _handleKeydownEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        const keyboardEvent = event;
        const key = keyboardEvent.key.toLowerCase();
        if (keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
            switch (key) {
                case 'b':
                case 'i':
                case 'u':
                case 'z': {
                    this.triggerEditorInputEventManually(editorInstance);
                    break;
                }
            }
        }
        switch (key) {
            case 'enter':
            case 'backspace':
            case 'delete': {
                this.triggerEditorInputEventManually(editorInstance, true);
                break;
            }
        }
    }
    _handleScrollEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        editorInstance.components.sentenceWiseEditorOverlayInstance.handleScrollEvent();
        if (!editorInstance.isEditorScrollInProgress) {
        }
        editorInstance.isEditorScrollInProgress = true;
        clearTimeout(editorInstance.editorScrollTimeout);
        editorInstance.editorScrollTimeout = setTimeout(() => {
            editorInstance.isEditorScrollInProgress = false;
            if (!editorInstance.isUnderlineRepositioningInProgress) {
                this.updateEditorUnderlinesPositionInBatch(editorInstance);
            }
        }, 180);
        if (!editorInstance.ticking) {
            window.requestAnimationFrame(() => {
                editorInstance.components.editorOverlayInstance.handleScrollEvent();
                const alertCounterElement = editorInstance.components.alertCounter.shadowRoot.querySelector('#alert-counter');
                const newBottom = Math.ceil(editorInstance.editor.scrollTop - 2);
                const newRight = Math.ceil(editorInstance.editor.scrollLeft - 2);
                alertCounterElement.style.bottom = `-${newBottom}px`;
                alertCounterElement.style.right = `-${newRight}px`;
                editorInstance.ticking = false;
            });
            editorInstance.ticking = true;
        }
    }
    _handlePasteEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        this.triggerEditorInputEventManually(editorInstance, true);
        editorInstance.components.editorOverlayInstance.handleResizeEvent();
    }
    cleanUpForEditor(editorInstance) {
        var _a;
        const editorId = editorInstance.editorId;
        if (!this.hostPageInfo.isCloudTrinka)
            editorInstance.editor.removeAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID);
        else {
            let timeInterval = setInterval(() => {
                if (editorInstance.editor.hasAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID)) {
                    editorInstance.editor.removeAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID);
                    clearInterval(timeInterval);
                }
            }, 2000);
        }
        (_a = editorInstance === null || editorInstance === void 0 ? void 0 : editorInstance.askPlugin) === null || _a === void 0 ? void 0 : _a.destroy();
        editorInstance.socketClient.close();
        editorInstance.resizeObserver.disconnect();
        editorInstance.grammarWorker.terminate();
        delete this.listOfEditors[editorId];
    }
    cleanUpForModule() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            const editorInstance = this.listOfEditors[editorId];
            this.cleanUpForEditor(editorInstance);
        });
        document.removeEventListener('tsdk-draggable-panel-filter-update', this.boundHandleFilterChange);
        document.removeEventListener('tsdk-editor-underline-mouse-over', this.boundHandleUnderlineMouseOverEvent);
        document.removeEventListener('tsdk-editor-underline-mouse-out', this.boundHandleUnderlineMouseOutEvent);
        document.removeEventListener('tsdk-editor-underline-clicked', this.boundHandleUnderlineClickedEvent);
        document.removeEventListener('tsdk-draggable-panel-card-toggle', this.boundHandleDraggablePanelCardToggleEvent);
        document.removeEventListener('tsdk-draggable-panel-toggle', this.boundHandleDraggablePanelToggleEvent);
        document.removeEventListener('tsdk-view-mode-changed', this.boundHandleViewModeChangedToggleEvent);
        document.removeEventListener('click', this.boundHandleClickEventOnDocument);
        document.removeEventListener('tsdk-draggable-panel-sentence-wise-card-toggle', this.boundHandleDraggablePanelSentenceWiseCardToggleEvent);
    }
    createDivMirror(editor) {
        const div = document.createElement('div');
        div.id = (0, utils_1.generateRandomId)(5);
        div.contentEditable = 'true';
        div.style.position = 'absolute';
        const propertiesToCopy = ['color', 'font-size', 'background-color', 'border', 'margin', 'padding', 'width', 'height', 'box-sizing'];
        this.copyCssProperties(editor, div, propertiesToCopy);
        div.style.top = '0';
        div.style.left = '0';
        div.style.maxHeight = editor.style.height;
        div.style.overflow = 'auto';
        div.innerText = editor.value;
        editor.style.opacity = '0';
        editor.parentElement.style.position = 'relative';
        editor.insertAdjacentElement('afterend', div);
        div.addEventListener('input', () => {
            if (editor && div) {
                editor.value = div.innerText;
            }
        });
        div.setAttribute('is-mirror-div', 'true');
        const ele = document.querySelector(`#${div.id}`);
        return ele;
    }
    removeAllEditorComponents() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.removeEditorComponents(this.listOfEditors[editorId]);
        });
    }
    removeAllEditorEventListeners() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.removeEditorEventListeners(this.listOfEditors[editorId]);
        });
    }
    registerAllEditorEventListeners() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.registerEditorEventListeners(this.listOfEditors[editorId]);
        });
    }
    removeEditorComponents(editorInstance) {
        Object.keys(editorInstance.components).forEach((componentName) => {
            editorInstance.components[componentName].remove();
        });
    }
    removeEditorEventListeners(editorInstance) {
        editorInstance.listOfEvents.forEach(({ element, event, listener }) => {
            element.removeEventListener(event, listener, { passive: true });
        });
    }
    registerEditorEventListeners(editorInstance) {
        editorInstance.listOfEvents.forEach(({ element, event, listener }) => {
            element.addEventListener(event, listener, { passive: true });
        });
    }
    applyFilter(editorInstance, regroupAlert = false) {
        if (!editorInstance)
            return;
        Object.keys(editorInstance.components.draggablePanel.filters).forEach((filter) => {
            editorInstance.components.draggablePanel.filters[filter].counter = 0;
        });
        Object.keys(editorInstance.components.draggablePanel.filters).forEach((filter) => {
            editorInstance.components.draggablePanel.filters[filter].counter = editorInstance.components.draggablePanel.alertList
                .filter(alert => {
                var _a, _b, _c, _d;
                if (!alert.suggestions || !alert.suggestions[0])
                    return false;
                return editorInstance.components.draggablePanel.filters[filter].value === alert.suggestions[0].type && (alert.suggestions[0].type === 10 && ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.inclusiveErrorCategories) ? ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.inclusiveErrorCategories.indexOf(alert.suggestions[0].error_category)) !== -1 : true);
            }).length;
        });
        const currentSelection = (0, utils_1.getSelectedFilters)(editorInstance.components.draggablePanel.filters);
        if (regroupAlert)
            this.globalGroupedObj = {};
        editorInstance.components.draggablePanel.filteredAlertList = this.groupAlerts(editorInstance.components.draggablePanel.alertList
            .filter(alert => {
            var _a, _b, _c, _d, _e, _f;
            if (!alert.suggestions || !alert.suggestions[0])
                return false;
            return currentSelection.includes(alert.suggestions[0].type.toString()) && (alert.suggestions[0].type === 10 && ((_b = (_a = this.grammarModuleConfig) === null || _a === void 0 ? void 0 : _a.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.inclusiveErrorCategories) && ((_d = (_c = this.grammarModuleConfig) === null || _c === void 0 ? void 0 : _c.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.inclusiveErrorCategories.length) > 0 ? ((_f = (_e = this.grammarModuleConfig) === null || _e === void 0 ? void 0 : _e.trinkaEditorConfig) === null || _f === void 0 ? void 0 : _f.inclusiveErrorCategories.indexOf(alert.suggestions[0].error_category)) !== -1 : true);
        }).sort((a, b) => {
            if (a.segmentPosition !== b.segmentPosition) {
                return a.segmentPosition - b.segmentPosition;
            }
            else if (a.sentencePosition !== b.sentencePosition) {
                return a.sentencePosition - b.sentencePosition;
            }
            else {
                return a.begin - b.begin;
            }
        }), editorInstance);
        editorInstance.components.draggablePanel.globalGroupedObj = this.globalGroupedObj;
        editorInstance.components.overlayCardComponent.filteredAlertList = editorInstance.components.draggablePanel.filteredAlertList;
        editorInstance.components.alertCounter.updateCounter(editorInstance.components.draggablePanel.filteredAlertList.length);
        if (this.grammarModuleConfig
            && this.grammarModuleConfig.alertCountChangeEvent
            && typeof this.grammarModuleConfig.alertCountChangeEvent === 'function') {
            this.grammarModuleConfig.alertCountChangeEvent(editorInstance.editorId, this.getEditorAlertCount(editorInstance));
        }
        if (this.uiConfig.inlineAlerts) {
            this.addInlineAlerts(editorInstance);
        }
        this.updateEditorUnderlinesVisibility(editorInstance);
    }
    removeAllEditorUnderLines() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.removeEditorUnderLines(this.listOfEditors[editorId]);
        });
    }
    removeEditorUnderLines(editorInstance) {
        for (let underlineId of editorInstance.underlines.keys()) {
            this.removeUnderLineById(editorInstance, underlineId);
        }
    }
    removeUnderLineById(editorInstance, underlineId) {
        if (!editorInstance.underlines.has(underlineId))
            return;
        const underlineInstance = editorInstance.underlines.get(underlineId);
        underlineInstance.remove();
        editorInstance.underlines.delete(underlineId);
    }
    updateAllEditorUnderlinesPosition() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.updateEditorUnderlinesPositionInBatch(this.listOfEditors[editorId]);
        });
    }
    updateEditorUnderlinesPositionBySegment(editorInstance, segmentId) {
        const segmentUnderlines = Array.from(editorInstance.underlines).filter(([key, value]) => value.segment.segmentId === segmentId).map(underline => underline[1]);
        segmentUnderlines.forEach((underline) => {
            underline.updateUnderlinePosition();
        });
    }
    async updateEditorUnderlinesPositionInBatch(editorInstance) {
        if (editorInstance.isEditorScrollInProgress)
            return;
        const process = async () => {
            let i = 0;
            if (editorInstance.isUnderlineRepositioningInProgress) {
                editorInstance.isUnderlineRepositioningCancelRequested = true;
                cancelAnimationFrame(editorInstance.underlineRepositioningProcessId);
            }
            editorInstance.isUnderlineRepositioningInProgress = true;
            editorInstance.isUnderlineRepositioningCancelRequested = false;
            const repositionUnderlines = () => {
                if (editorInstance.isUnderlineRepositioningCancelRequested) {
                    editorInstance.isUnderlineRepositioningInProgress = false;
                    return;
                }
                let count = 0;
                while (i < editorInstance.underlines.size && count < 20) {
                    const underlineId = Array.from(editorInstance.underlines.keys())[i];
                    const underline = editorInstance.underlines.get(underlineId);
                    underline.updateUnderlinePosition();
                    i++;
                    count++;
                }
                if (i < editorInstance.underlines.size) {
                    editorInstance.underlineRepositioningProcessId = requestAnimationFrame(repositionUnderlines);
                }
                else {
                    editorInstance.isUnderlineRepositioningInProgress = false;
                }
            };
            editorInstance.underlineRepositioningProcessId = requestAnimationFrame(repositionUnderlines);
        };
        process();
    }
    async updateEditorSentencewiseHighlighterPositionInBatch(editorInstance) {
        const process = async () => {
            let i = 0;
            const repositionSentencewiseHighlighter = () => {
                let count = 0;
                while (i < editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.length && count < 20) {
                    const alert = editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList[i];
                    alert.sentenceHighlighter.refreshHighlight();
                    i++;
                    count++;
                }
                if (i < editorInstance.components.draggablePanel.sentenceWiseFilteredAlertList.length) {
                    requestAnimationFrame(repositionSentencewiseHighlighter);
                }
            };
            requestAnimationFrame(repositionSentencewiseHighlighter);
        };
        process();
    }
    updateAllEditorUnderlinesVisibility() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.updateEditorUnderlinesVisibility(this.listOfEditors[editorId]);
        });
    }
    updateEditorUnderlinesVisibility(editorInstance) {
        let showAlertsList = Array.from(editorInstance.underlines.keys()).filter(key => editorInstance.components.draggablePanel.filteredAlertList.some(item => item.alertId === key));
        let hideAlertsList = Array.from(editorInstance.underlines.keys()).filter(key => !editorInstance.components.draggablePanel.filteredAlertList.some(item => item.alertId === key));
        showAlertsList.forEach((underlineId) => {
            editorInstance.underlines.get(underlineId).setVisibility(true);
        });
        hideAlertsList.forEach((underlineId) => {
            editorInstance.underlines.get(underlineId).setVisibility(false);
        });
    }
    async renderEditorUnderLines(editorInstance, alerts) {
        const container = editorInstance.components.editorOverlayInstance.editorOverlayElements;
        const fragment = document.createDocumentFragment();
        alerts.forEach((alert) => {
            if (!editorInstance.underlines.has(alert.alertId)) {
                const segment = Object.keys(editorInstance.editorDOMTreeNodes)
                    .filter((key) => editorInstance.editorDOMTreeNodes[key].segmentId === alert.segmentId)
                    .map((key) => editorInstance.editorDOMTreeNodes[key])[0];
                if (!editorInstance.segmentToSentencesMap.get(alert.segmentId))
                    return;
                const sentence = editorInstance.segmentToSentencesMap.get(alert.segmentId)[alert.sentenceId];
                const underlineInstance = new underline_1.Underline(this.uiConfig, this.hostPageInfo.isCloudTrinka, editorInstance.editor, editorInstance.editorId, editorInstance.components.editorOverlayInstance.editorOverlay, editorInstance.components.overlayCardComponent, editorInstance.components.editorOverlayInstance.editorOverlayElements, segment, sentence, alert);
                editorInstance.underlines.set(alert.alertId, underlineInstance);
                underlineInstance.underlines.forEach((underline) => {
                    fragment.appendChild(underline);
                });
            }
        });
        await (0, utils_1.delay)(400);
        container.appendChild(fragment);
    }
    handleFilterChange($event) {
        if (!$event.detail.editorId)
            return;
        this.applyFilter(this.listOfEditors[$event.detail.editorId], true);
    }
    addInlineAlerts(editorInstance) {
        let outerDiv = editorInstance.editor;
        if (outerDiv.textContent.length > 0 && outerDiv.contentEditable == "true") {
            let originalText = outerDiv.textContent;
            let offset = 0;
            editorInstance.components.draggablePanel.filteredAlertList.forEach(item => {
                if (!item)
                    return;
                const begin = item.begin + offset;
                const end = item.end + 1 + offset;
                const beforeText = originalText.substring(0, begin);
                const targetText = originalText.substring(begin, end);
                const afterText = originalText.substring(end);
                const replacementHTML = '<s style="background-color: #ff000052;">' + targetText + '</s> ' +
                    '<span style="background-color: #00800042;">' + item.suggestions[0].suggestion + '</span>';
                originalText = beforeText + replacementHTML + afterText;
                offset += replacementHTML.length - targetText.length;
            });
            outerDiv.innerHTML = originalText;
        }
    }
    handleClickEventOnDocument($event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if ($event.target.closest("trinka-popup")) {
            return;
        }
        else {
            const filter = (_d = (_c = (_b = (_a = document.querySelector('trinka-popup')) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('draggable-panel-header')) === null || _c === void 0 ? void 0 : _c.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('.filter');
            (_e = filter === null || filter === void 0 ? void 0 : filter.classList) === null || _e === void 0 ? void 0 : _e.remove('active');
            const powerModeDropDown = (_j = (_h = (_g = (_f = document.querySelector('trinka-popup')) === null || _f === void 0 ? void 0 : _f.shadowRoot) === null || _g === void 0 ? void 0 : _g.querySelector('draggable-panel-header')) === null || _h === void 0 ? void 0 : _h.shadowRoot) === null || _j === void 0 ? void 0 : _j.querySelector('.powerModeDropDown');
            (_k = powerModeDropDown === null || powerModeDropDown === void 0 ? void 0 : powerModeDropDown.classList) === null || _k === void 0 ? void 0 : _k.add('hide');
            const trackChangesDropDown = (_p = (_o = (_m = (_l = document.querySelector('trinka-popup')) === null || _l === void 0 ? void 0 : _l.shadowRoot) === null || _m === void 0 ? void 0 : _m.querySelector('draggable-panel-header')) === null || _o === void 0 ? void 0 : _o.shadowRoot) === null || _p === void 0 ? void 0 : _p.querySelector('.viewSuggestionDorpDown');
            (_q = trackChangesDropDown === null || trackChangesDropDown === void 0 ? void 0 : trackChangesDropDown.classList) === null || _q === void 0 ? void 0 : _q.add('hide');
        }
    }
    handleCKEditorInputWithoutDebounce(editorInstance, isTypingFlag = false) {
        if (!editorInstance)
            return;
        if (isTypingFlag)
            (0, utils_1.addEventToTracingLog)('ckEditorInput');
    }
    handleCKEditorInput(editorInstance) {
        if (!editorInstance)
            return;
        this.triggerEditorInputEventManually(editorInstance, true);
    }
    copyCssProperties(sourceElement, targetElement, properties) {
        const computedStyles = window.getComputedStyle(sourceElement);
        properties.forEach(property => {
            targetElement.style[property] = computedStyles.getPropertyValue(property);
        });
    }
    groupAlerts(filteredAlertList, editorInstance) {
        var _a;
        const invalidIds = [];
        for (let i = 0; i < Object.keys(editorInstance.editorDOMTreeNodes).length; i++) {
            const segment = Object.keys(editorInstance.editorDOMTreeNodes)[i];
            const segmentObj = editorInstance.editorDOMTreeNodes[segment];
            if (segmentObj.isSegmentDeleted)
                invalidIds.push(segmentObj.segmentId);
        }
        filteredAlertList = filteredAlertList.filter((x) => invalidIds.indexOf(x.segmentId) === -1);
        for (let i = 0; i < filteredAlertList.length; i++) {
            const alert = filteredAlertList[i];
            let lang_category = alert.suggestions[0].lang_category;
            let similarAlertCount = filteredAlertList.filter((x) => x.suggestions[0].cta_present && x.suggestions[0].lang_category === lang_category && x.suggestions.length === 1).length;
            if (this.langCategoryArr.indexOf(lang_category) > -1 && alert.suggestions[0].cta_present && alert.suggestions.length === 1 && (similarAlertCount > 1 || filteredAlertList[i].isGroupedFromStart)) {
                if (!this.globalGroupedObj[lang_category]) {
                    this.globalGroupedObj[lang_category] = new Map();
                }
                if (!this.globalGroupedObj[lang_category].has(alert.alertId)) {
                    this.globalGroupedObj[lang_category].set(alert.alertId, alert);
                    filteredAlertList[i].groupPosition = Array.from(this.globalGroupedObj[lang_category].keys()).indexOf(alert.alertId);
                }
                filteredAlertList[i].isGrouped = true;
                filteredAlertList[i].isGroupedFromStart = true;
                filteredAlertList[i].groupNumber = this.langCategoryArr.indexOf((_a = alert === null || alert === void 0 ? void 0 : alert.suggestions[0]) === null || _a === void 0 ? void 0 : _a.lang_category);
            }
            else {
                filteredAlertList[i].isGrouped = false;
            }
        }
        for (const lang_category in this.globalGroupedObj) {
            const groupedAlerts = Array.from(this.globalGroupedObj[lang_category].values());
            const groupedAlertKeys = Array.from(this.globalGroupedObj[lang_category].keys());
            for (let i = 0; i < groupedAlertKeys.length; i++) {
                if (filteredAlertList.findIndex((x) => x.alertId === groupedAlertKeys[i]) === -1) {
                    this.globalGroupedObj[lang_category].delete(groupedAlertKeys[i]);
                }
            }
            const hasZeroPosition = groupedAlerts.some(alert => alert.groupPosition === 0);
            if (!hasZeroPosition) {
                groupedAlerts.forEach(alert => {
                    alert.groupPosition -= 1;
                    const index = filteredAlertList.findIndex(a => a.alertId === alert.alertId);
                    if (index !== -1) {
                        filteredAlertList[index].groupPosition = alert.groupPosition;
                    }
                });
            }
        }
        return filteredAlertList;
    }
}
exports.GrammarUI = GrammarUI;
