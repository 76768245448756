"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ask = void 0;
const constants_1 = require("../helpers/constants");
const ask_ui_1 = require("./ask-ui");
const utils_1 = require("../helpers/utils");
const ask_snackbar_1 = require("../components/ask-plugin/ask-snackbar");
class Ask {
    constructor(editor, editorId, config, hostPageInfo, module = constants_1.SDK_MODULES.GRAMMAR) {
        this.hostPageInfo = {
            supportedEditorsLibraries: constants_1.EDITORS_LIBRARIES_TO_SUPPORT,
            isCloudTrinka: false,
            isEditorLibraryPresent: false,
            isMultipleLibrariesPresent: false,
            detectedLibraries: []
        };
        this.editorSelectionDetails = null;
        this.boundHandleSelectionChangeEvent = (0, utils_1.debounce)(($event) => this.handleSelectionChangeEvent($event), 400);
        this.boundHandleClickEvent = (0, utils_1.debounce)(($event) => this.handleClickEvent($event), 400);
        this.boundAskReplaceTextEvent = ($event) => this.askReplaceTextEvent($event);
        this.boundAskEditorScrollEvent = ($event) => this.askEditorScrollEvent($event);
        this.boundAskInlineOnSubmitEvent = ($event) => this.askInlineOnSubmitEvent($event);
        this.boundonAskInlineResponseEvent = ($event) => this.onAskInlineResponseEvent($event);
        this.boundAskPopoverOnErrorEvent = ($event) => this.askPopoverOnErrorEvent($event);
        this.boundHandleViewModeChangedToggleEvent = ($event) => this.handleViewModeChangedEvent($event);
        this.isClick = false;
        this.editor = editor;
        this.editorId = editorId;
        this.hostPageInfo = hostPageInfo;
        this.askUI = new ask_ui_1.AskUI(editor, editorId, config, hostPageInfo, module);
        this.initialize();
    }
    destroy() {
        this.askUI.destroy();
        this.removeEventListeners();
    }
    handleResizeEvent() {
        this.askUI.askInstance.components.editorOverlayInstance.handleResizeEvent();
        this.askUI.clearEditorSelection(this.askUI.askInstance);
    }
    handleViewModeChangedEvent($event) {
        const detail = $event.detail;
        if (!detail.editorId || !detail.mode)
            return;
        this.askUI.currentSelectedMode = detail.mode;
    }
    registerEventListeners() {
        document.addEventListener('selectionchange', this.boundHandleSelectionChangeEvent);
        document.addEventListener('click', this.boundHandleClickEvent);
        document.addEventListener('tsdk-ask-replace-text', this.boundAskReplaceTextEvent);
        document.addEventListener('tsdk-ask-editor-scroll-event', this.boundAskEditorScrollEvent);
        document.addEventListener('tsdk-ask-inline-on-submit-event', this.boundAskInlineOnSubmitEvent);
        document.addEventListener('tsdk-on-ask-inline-response-event', this.boundonAskInlineResponseEvent);
        document.addEventListener('tsdk-ask-popover-on-error-event', this.boundAskPopoverOnErrorEvent);
        document.addEventListener('tsdk-view-mode-changed', this.boundHandleViewModeChangedToggleEvent);
    }
    removeEventListeners() {
        document.removeEventListener('selectionchange', this.boundHandleSelectionChangeEvent);
        document.removeEventListener('click', this.boundHandleClickEvent);
        document.removeEventListener('tsdk-ask-replace-text', this.boundAskReplaceTextEvent);
        document.removeEventListener('tsdk-ask-editor-scroll-event', this.boundAskEditorScrollEvent);
        document.removeEventListener('tsdk-ask-inline-on-submit-event', this.boundAskInlineOnSubmitEvent);
        document.removeEventListener('tsdk-on-ask-inline-response-event', this.boundonAskInlineResponseEvent);
        document.removeEventListener('tsdk-ask-popover-on-error-event', this.boundAskPopoverOnErrorEvent);
        document.removeEventListener('tsdk-view-mode-changed', this.boundHandleViewModeChangedToggleEvent);
    }
    async scanHostPage() {
        Object.keys(this.hostPageInfo.supportedEditorsLibraries).forEach(editor => {
            this.hostPageInfo.supportedEditorsLibraries[editor].detect();
        });
        if (!Object.values(this.hostPageInfo.supportedEditorsLibraries).some(library => library.detected)) {
            this.hostPageInfo.isEditorLibraryPresent = false;
        }
        else {
            this.hostPageInfo.isEditorLibraryPresent = true;
            this.hostPageInfo.detectedLibraries = Object.values(this.hostPageInfo.supportedEditorsLibraries)
                .filter(library => library.detected)
                .map(library => library.editorLibraryName);
            this.hostPageInfo.isMultipleLibrariesPresent = this.hostPageInfo.detectedLibraries.length > 1;
        }
    }
    initialize() {
        this.askUI.initialize(this.editor, this.editorId);
        this.registerEventListeners();
    }
    isSelectionInsideTrinkaEditor(selection, range) {
        if (!selection.rangeCount) {
            return null;
        }
        const selectedText = selection.toString();
        const contentEditables = document.querySelectorAll('[contenteditable="true"]');
        for (let i = 0; i < contentEditables.length; i++) {
            const contentEditable = contentEditables[i];
            const anchorNode = selection.anchorNode;
            const focusNode = selection.focusNode;
            if (contentEditable.contains(anchorNode) && contentEditable.contains(focusNode) && contentEditable.hasAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID)) {
                const editorId = contentEditable.getAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID);
                if (editorId === this.editorId) {
                    const details = {
                        editorInstance: this.askUI.askInstance,
                        selectedText,
                        selectedNodes: (0, utils_1.getNodesWithinRange)(range),
                        range
                    };
                    if (this.hostPageInfo.supportedEditorsLibraries.ckeditor5.detected) {
                        const selection = this.askUI.askInstance.editor.ckeditorInstance.model.document.selection;
                        if (selection && !selection.isCollapsed) {
                            const range = selection.getFirstRange();
                            details['startModelPosition'] = range.start;
                            details['endModelPosition'] = range.end;
                        }
                        else {
                            console.warn('Selection is collapsed or not available.');
                        }
                    }
                    return details;
                }
            }
        }
        return null;
    }
    getEditorSelectionDetails() {
        const selection = window.getSelection();
        if (!selection || selection.isCollapsed || !selection.rangeCount) {
            return null;
        }
        const range = selection.getRangeAt(0);
        const isSelectionInsideTrinkaEditorResponse = this.isSelectionInsideTrinkaEditor(selection, range);
        return isSelectionInsideTrinkaEditorResponse;
    }
    clearEditorSelection() {
        if (this.editorSelectionDetails) {
            this.askUI.clearEditorSelection(this.editorSelectionDetails.editorInstance);
        }
        this.editorSelectionDetails = null;
    }
    handleSelectionChangeEvent($event) {
        if (this.askUI.currentSelectedMode !== constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL)
            return;
        const selection = window.getSelection();
        if (selection.isCollapsed)
            return;
        if (this.isClick)
            return;
        this.askUI.askInstance.components.askPopover.selectedText = '';
        this.askUI.askInstance.components.askSelection.clearHighlights();
        this.editorSelectionDetails = this.getEditorSelectionDetails();
        if (!this.editorSelectionDetails) {
            return;
        }
        this.showMagicWand();
    }
    showMagicWand() {
        const containerBounds = this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.editorOverlayElements.getBoundingClientRect();
        const rects = this.editorSelectionDetails.range.getClientRects();
        const lastNode = rects[rects.length - 1];
        const magicWand = document.createElement('div');
        magicWand.id = 'magic-wand';
        magicWand.innerHTML = `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.75 3.66666L14.8333 4.83333L13.6666 2.75L14.8333 0.666664L12.75 1.83333L10.6666 0.666664L11.8333 2.75L10.6666 4.83333L12.75 3.66666ZM2.74995 11.8333L0.666618 10.6667L1.83328 12.75L0.666618 14.8333L2.74995 13.6667L4.83328 14.8333L3.66662 12.75L4.83328 10.6667L2.74995 11.8333ZM0.666618 0.666664L1.83328 2.75L0.666618 4.83333L2.74995 3.66666L4.83328 4.83333L3.66662 2.75L4.83328 0.666664L2.74995 1.83333L0.666618 0.666664ZM7.88328 9.65L5.84995 7.61666L7.61662 5.85L9.64995 7.88333L7.88328 9.65ZM7.02495 5.075L5.07495 7.025C4.74995 7.33333 4.74995 7.875 5.07495 8.2L14.8 17.925C15.125 18.25 15.6666 18.25 15.975 17.925L17.925 15.975C18.25 15.6667 18.25 15.125 17.925 14.8L8.19995 5.075C7.87495 4.75 7.33329 4.75 7.02495 5.075Z" fill="white"/></svg><span style="position: absolute;
      width: 100px;
      background: #414E62;
      border-radius: 5px;
      text-align: center;font-size: 12px;
      color: #FFF;
      bottom: -28px;">Rewrite with AI</span>`;
        Object.assign(magicWand.style, {
            'top': `${lastNode.top - containerBounds.top + this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.editorOverlayElements.scrollTop}px`,
            'left': `${lastNode.left - containerBounds.left + this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.editorOverlayElements.scrollLeft + lastNode.width}px`,
            'width': `34px`,
            'height': `34px`,
            'pointer-events': 'auto',
            'position': `absolute`,
            'background': `#7A28A0`,
            'cursor': `pointer`,
            'z-index': `999`,
            'border-radius': '5px',
            'display': 'flex',
            'align-items': 'center',
            'justify-content': 'center'
        });
        const magicWandPointerdownEvent = ($event) => {
            $event.stopImmediatePropagation();
            $event.stopPropagation();
            const selection = window.getSelection();
            const selectionText = selection.toString().trim();
            let errorMessage = '';
            if (selectionText && selectionText.split(' ').length <= 3) {
                errorMessage = 'Please select more than 3 words.';
            }
            if (selectionText && selectionText.split(' ').length >= 400) {
                errorMessage = 'Please select less than 400 words.';
            }
            if (errorMessage) {
                const event = new CustomEvent('tsdk-ask-popover-on-error-event', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        editorId: this.editorId,
                        message: errorMessage
                    }
                });
                document.dispatchEvent(event);
                return;
            }
            this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(this.editorSelectionDetails.editorInstance.components.askPopover);
            const rects = (0, utils_1.filterDuplicateRects)(this.editorSelectionDetails.range.getClientRects());
            const lastNode = (0, utils_1.getHighestTopRect)(this.editorSelectionDetails.range.getClientRects());
            const position = {
                'top': `${lastNode.top - containerBounds.top + this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.editorOverlayElements.scrollTop + lastNode.height}px`,
                'left': `${lastNode.left - containerBounds.left}px`,
            };
            this.editorSelectionDetails.editorInstance.components.askPopover.open(this.askUI.activeModule, position);
            this.editorSelectionDetails.editorInstance.components.askPopover.selectedText = this.editorSelectionDetails.selectedText;
            this.editorSelectionDetails.editorInstance.components.askSelection.createHighlight(rects);
            magicWand.removeEventListener('pointerdown', boundMagicWandPointerdownEvent);
            magicWand.remove();
            if (this.askUI.activeModule === constants_1.SDK_MODULES.PARAPHRASER) {
                const askJSMagicWantCTADockingPoint = document.querySelector(`#askJSMagicWantCTADockingPoint`);
                askJSMagicWantCTADockingPoint.innerHTML = '';
            }
            (0, utils_1.triggerAmplitudeEvent)('rewrite_with_ai', {
                right_panel: this.askUI.activeModule.toLowerCase()
            });
        };
        const boundMagicWandPointerdownEvent = ($event) => magicWandPointerdownEvent($event);
        magicWand.addEventListener('pointerdown', boundMagicWandPointerdownEvent);
        if (this.askUI.activeModule === constants_1.SDK_MODULES.PARAPHRASER) {
            setTimeout(() => {
                const askJSMagicWantCTADockingPoint = document.querySelector(`#askJSMagicWantCTADockingPoint`);
                if (askJSMagicWantCTADockingPoint.childNodes.length) {
                    askJSMagicWantCTADockingPoint.innerHTML = '';
                }
                Object.assign(magicWand.style, {
                    'top': `2px`,
                    'left': `0px`,
                    'margin-left': `10px`,
                    'position': `relative`
                });
                askJSMagicWantCTADockingPoint.appendChild(magicWand);
            }, 0);
        }
        else {
            this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(magicWand);
        }
    }
    handleClickEvent($event) {
        this.isClick = true;
        const resetClickState = () => {
            setTimeout(() => {
                this.isClick = false;
            }, 0);
        };
        const selection = window.getSelection();
        if (!selection.isCollapsed) {
            resetClickState();
            return;
        }
        const eventOriginatedFromEditor = !!(this.askUI.askInstance.editor === $event.target);
        if (eventOriginatedFromEditor) {
            this.clearEditorSelection();
            resetClickState();
            return;
        }
        ;
        if (this.editorSelectionDetails) {
            if (!this.editorSelectionDetails.editorInstance.components.askPopover.contains($event.target) && $event.target !== this.editorSelectionDetails.editorInstance.components.editorOverlayInstance.trinkaExtension) {
                this.clearEditorSelection();
            }
        }
        else {
        }
        resetClickState();
    }
    askReplaceTextCKE5EventV4($event) {
        const detail = $event.detail;
        const editor = this.editorSelectionDetails.editorInstance.editor;
        const newText = ` ${detail.text}`;
        const model = editor.model;
        switch (detail.action) {
            case 'replace': {
                const startModelPosition = this.editorSelectionDetails.startModelPosition;
                const endModelPosition = this.editorSelectionDetails.endModelPosition;
                if (!startModelPosition || !endModelPosition) {
                    console.warn('No valid selection positions found. Please ensure start and end model positions are available.');
                    return;
                }
                editor.ckeditorInstance.model.change(writer => {
                    const range = writer.createRange(startModelPosition, endModelPosition);
                    writer.remove(range);
                    writer.insertText(newText, startModelPosition);
                    this.clearEditorSelection();
                });
                break;
            }
            case 'insert': {
                const endModelPosition = this.editorSelectionDetails.endModelPosition;
                if (!endModelPosition) {
                    console.warn('No valid insert position found. Please ensure the end model position is available.');
                    return;
                }
                editor.ckeditorInstance.model.change(writer => {
                    writer.insertText(newText, endModelPosition);
                    this.clearEditorSelection();
                });
                break;
            }
        }
    }
    askReplaceTextCKE5EventV3($event) {
        const detail = $event.detail;
        const editor = this.editorSelectionDetails.editorInstance.editor;
        const highlights = this.editorSelectionDetails.editorInstance.components.askSelection.highlighter;
        const newText = ` ${detail.text}`;
        switch (detail.action) {
            case 'replace': {
                if (highlights.length === 0) {
                    console.warn('No highlights to replace. Inserting text at cursor position.');
                    return;
                }
                editor.ckeditorInstance.editing.view.focus();
                const firstHighlight = highlights[0].getBoundingClientRect();
                const lastHighlight = highlights[highlights.length - 1].getBoundingClientRect();
                const startDomRange = document.caretRangeFromPoint(firstHighlight.left, firstHighlight.top);
                const endDomRange = document.caretRangeFromPoint(lastHighlight.right, lastHighlight.bottom);
                if (!startDomRange || !endDomRange) {
                    console.warn('Unable to calculate DOM range from highlights.');
                    return;
                }
                try {
                    const startViewPosition = editor.ckeditorInstance.editing.view.domConverter.domPositionToView(startDomRange.startContainer, startDomRange.startOffset);
                    const endViewPosition = editor.ckeditorInstance.editing.view.domConverter.domPositionToView(endDomRange.endContainer, endDomRange.endOffset);
                    const startModelPosition = editor.ckeditorInstance.editing.mapper.toModelPosition(startViewPosition);
                    const endModelPosition = editor.ckeditorInstance.editing.mapper.toModelPosition(endViewPosition);
                    editor.ckeditorInstance.model.change(writer => {
                        const modelRange = writer.createRange(startModelPosition, endModelPosition);
                        writer.remove(modelRange);
                        writer.insertText(newText, startModelPosition);
                        this.clearEditorSelection();
                    });
                }
                catch (error) {
                    console.error('Error mapping DOM range to CKEditor view:', error);
                }
                break;
            }
            case 'insert': {
                const lastHighlight = highlights[highlights.length - 1].getBoundingClientRect();
                const endDomRange = document.caretRangeFromPoint(lastHighlight.right, lastHighlight.bottom);
                if (!endDomRange) {
                    console.warn('Unable to calculate DOM range from the end of highlights.');
                    return;
                }
                try {
                    const endViewPosition = editor.ckeditorInstance.editing.view.domConverter.domPositionToView(endDomRange.endContainer, endDomRange.endOffset);
                    const endModelPosition = editor.ckeditorInstance.editing.mapper.toModelPosition(endViewPosition);
                    editor.ckeditorInstance.model.change(writer => {
                        writer.insertText(newText, endModelPosition);
                        this.clearEditorSelection();
                    });
                }
                catch (error) {
                    console.error('Error mapping DOM range to CKEditor view:', error);
                }
                break;
            }
        }
    }
    askReplaceTextCKE5EventV2($event) {
        const detail = $event.detail;
        const editor = this.editorSelectionDetails.editorInstance.editor;
        const highlights = this.editorSelectionDetails.editorInstance.components.askSelection.highlighter;
        const newText = ` ${detail.text}`;
        switch (detail.action) {
            case 'replace': {
                if (highlights.length === 0) {
                    console.warn('No highlights to replace. Inserting text at cursor position.');
                    return;
                }
                editor.ckeditorInstance.editing.view.focus();
                const firstHighlight = highlights[0].getBoundingClientRect();
                const lastHighlight = highlights[highlights.length - 1].getBoundingClientRect();
                const startDomRange = document.caretRangeFromPoint(firstHighlight.left, firstHighlight.top);
                const endDomRange = document.caretRangeFromPoint(lastHighlight.right, lastHighlight.bottom);
                if (!startDomRange || !endDomRange) {
                    console.warn('Unable to calculate DOM range from highlights.');
                    return;
                }
                const startViewPosition = editor.ckeditorInstance.editing.view.domConverter.domPositionToView(startDomRange.startContainer, startDomRange.startOffset);
                const endViewPosition = editor.ckeditorInstance.editing.view.domConverter.domPositionToView(endDomRange.endContainer, endDomRange.endOffset);
                if (!startViewPosition || !endViewPosition) {
                    console.warn('Unable to map DOM range to CKEditor view.');
                    return;
                }
                const startModelPosition = editor.ckeditorInstance.editing.mapper.toModelPosition(startViewPosition);
                const endModelPosition = editor.ckeditorInstance.editing.mapper.toModelPosition(endViewPosition);
                if (!startModelPosition || !endModelPosition) {
                    console.warn('Invalid model positions derived from the highlights.');
                    return;
                }
                editor.ckeditorInstance.model.change(writer => {
                    const modelRange = writer.createRange(startModelPosition, endModelPosition);
                    writer.remove(modelRange);
                    writer.insertText(newText, startModelPosition);
                    this.clearEditorSelection();
                });
                break;
            }
            case 'insert': {
                const lastHighlight = highlights[highlights.length - 1].getBoundingClientRect();
                const endDomRange = document.caretRangeFromPoint(lastHighlight.right, lastHighlight.bottom);
                if (!endDomRange) {
                    console.warn('Unable to calculate DOM range from the end of highlights.');
                    return;
                }
                const endViewPosition = editor.ckeditorInstance.editing.view.domConverter.domPositionToView(endDomRange.endContainer, endDomRange.endOffset);
                if (!endViewPosition) {
                    console.warn('Unable to map DOM range to CKEditor view.');
                    return;
                }
                const endModelPosition = editor.ckeditorInstance.editing.mapper.toModelPosition(endViewPosition);
                if (!endModelPosition) {
                    console.warn('Invalid model position derived from the end of the highlights.');
                    return;
                }
                editor.ckeditorInstance.model.change(writer => {
                    writer.insertText(newText, endModelPosition);
                    this.clearEditorSelection();
                });
                break;
            }
        }
    }
    askReplaceTextCKE5EventV1($event) {
        const detail = $event.detail;
        const editor = this.editorSelectionDetails.editorInstance.editor;
        editor.ckeditorInstance.model.change(writer => {
            const selection = editor.ckeditorInstance.model.document.selection;
            const selectedRange = selection.getFirstRange();
            switch (detail.action) {
                case 'replace': {
                    if (!selection.isCollapsed) {
                        writer.remove(selectedRange);
                    }
                    break;
                }
                case 'insert': {
                    writer.setSelection(selectedRange.end);
                    break;
                }
            }
            writer.insertText(detail.text, editor.ckeditorInstance.model.document.selection.getFirstPosition());
            const positionAfterInsertedText = writer.createPositionAfter(selection.getFirstPosition().nodeAfter);
            writer.setSelection(positionAfterInsertedText);
        });
    }
    askEditorScrollEvent($event) {
    }
    askInlineOnSubmitEvent($event) {
        const detail = $event.detail;
        if (this.askUI.askInstance.editorId !== detail.editorId)
            return;
        this.askUI.askInstance.components.editorOverlayInstance.editorOverlayElements.innerHTML = '';
        this.askUI.askInstance.components.askInlinePopover.close();
    }
    askPopoverOnErrorEvent($event) {
        const detail = $event.detail;
        if (this.askUI.askInstance.editorId !== detail.editorId)
            return;
        const askSnackbar = new ask_snackbar_1.AskSnackbar();
        askSnackbar.message = detail.message;
        this.askUI.askInstance.components.editorOverlayInstance.editorOverlayViewport.appendChild(askSnackbar);
    }
    onAskInlineResponseEvent($event) {
        const detail = $event.detail;
        if (this.askUI.askInstance.editorId !== detail.editorId)
            return;
        this._writeWordByWord(this.askUI.askInstance, detail.text);
    }
    _writeWordByWordWithBlurV2(askInstance, text) {
        const editor = askInstance.editor;
        const words = text.split(" ");
        let index = 0;
        editor.ckeditorInstance.model.change(writer => {
            const insertPosition = editor.ckeditorInstance.model.document.selection.getFirstPosition();
            const blurredSpan = '<span class="ask-blurred-span"></span>';
            const viewFragment = editor.ckeditorInstance.data.processor.toView(blurredSpan);
            const modelFragment = editor.ckeditorInstance.data.toModel(viewFragment);
            writer.insert(modelFragment, insertPosition);
        });
        const intervalId = setInterval(() => {
            if (index < words.length) {
                editor.ckeditorInstance.model.change(writer => {
                    const root = editor.ckeditorInstance.model.document.getRoot();
                    const lastPosition = editor.ckeditorInstance.model.createPositionAt(root, 'end');
                    writer.insertText(words[index] + ' ', lastPosition);
                    index++;
                });
            }
            else {
                clearInterval(intervalId);
                this._unblurContentV2(askInstance);
            }
        }, 50);
    }
    _unblurContentV2(askInstance) {
        const editor = askInstance.editor;
        const currentData = editor.ckeditorInstance.getData();
        const unblurredData = currentData.replace(/<span class="ask-blurred-span">/g, '<span>').replace(/<\/span>/g, '');
        editor.ckeditorInstance.setData(unblurredData);
    }
    _writeCompleteResponse(askInstance, text) {
        const editor = askInstance.editor;
        const writer = editor.ckeditorInstance.model.change(writer => {
            const insertPosition = editor.ckeditorInstance.model.document.selection.getFirstPosition();
            writer.insertText(text, insertPosition);
        });
    }
    _writeWordByWord(askInstance, text) {
        const editor = askInstance.editor;
        const words = text.split(" ");
        let index = 0;
        askInstance.components.editorOverlayInstance.editorOverlay.style.pointerEvents = 'all';
        const intervalId = setInterval(() => {
            console.log('_writeWordByWord in progress !!!');
            if (this.hostPageInfo.supportedEditorsLibraries.ckeditor5.detected) {
                if (index < words.length) {
                    const writer = editor.ckeditorInstance.model.change(writer => {
                        const insertPosition = editor.ckeditorInstance.model.document.selection.getFirstPosition();
                        writer.insertText((index === 0 ? "" : " ") + words[index], insertPosition);
                        (0, utils_1.addEventToTracingLog)('input_ask');
                    });
                    index++;
                    askInstance.editor.scrollTop += 2;
                }
                else {
                    clearInterval(intervalId);
                    askInstance.components.editorOverlayInstance.editorOverlay.style.pointerEvents = 'none';
                }
            }
            else {
                if (index < words.length) {
                    editor.textContent += (index === 0 ? "" : " ") + words[index];
                    index++;
                    askInstance.editor.scrollTop += 2;
                }
                else {
                    clearInterval(intervalId);
                    askInstance.components.editorOverlayInstance.editorOverlay.style.pointerEvents = 'none';
                }
            }
        }, 50);
    }
    _writeWordByWordWithBlur(askInstance, text) {
        const editor = askInstance.editor;
        const words = text.split(" ");
        let index = 0;
        const intervalId = setInterval(() => {
            if (index < words.length) {
                editor.ckeditorInstance.model.change(writer => {
                    const selection = editor.ckeditorInstance.model.document.selection;
                    const insertPosition = selection.getFirstPosition();
                    writer.insertText(words[index] + ' ', { 'span': 'ask-blurred-span' }, insertPosition);
                    index++;
                });
            }
            else {
                clearInterval(intervalId);
                this._unblurAllWords(askInstance);
            }
        }, 50);
    }
    _unblurAllWords(askInstance) {
        const editor = askInstance.editor;
        const currentData = editor.ckeditorInstance.getData();
        const unblurredData = currentData.replace(/<span class="ask-blurred-span">/g, '<span>').replace(/<\/span>/g, '</span>');
        editor.ckeditorInstance.setData(unblurredData);
    }
    askReplaceTextEvent($event) {
        if (this.hostPageInfo.supportedEditorsLibraries.ckeditor5.detected) {
            this.askReplaceTextCKE5EventV4($event);
            (0, utils_1.addEventToTracingLog)('accept_ask');
        }
        else {
            const detail = $event.detail;
            if (this.editorSelectionDetails) {
                switch (detail.action) {
                    case 'replace': {
                        this.editorSelectionDetails.range.deleteContents();
                        break;
                    }
                    case 'insert': {
                        this.editorSelectionDetails.range.collapse(false);
                        break;
                    }
                }
                const textNode = document.createTextNode($event.detail.text);
                this.editorSelectionDetails.range.insertNode(textNode);
                const newRange = document.createRange();
                newRange.setStartAfter(textNode);
                newRange.setEndAfter(textNode);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(newRange);
                selection.collapseToEnd();
                this.clearEditorSelection();
            }
        }
    }
}
exports.Ask = Ask;
