"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertsPanel = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const utils_1 = require("../../helpers/utils");
const environment_1 = require("src/env/environment");
let AlertsPanel = class AlertsPanel extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.filteredAlertList = [];
        this.editorId = '';
        this.dataGroupedByText = [];
        this.maxItemsToShow = 4;
        this.langCode = '';
        this.currentLang = 'en';
        this.previourCard = {};
        this.groupByKey = (data, key) => {
            return data.reduce((result, currentValue) => {
                currentValue['checked'] = false;
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
                return result;
            }, {});
        };
    }
    render() {
        var _a;
        return (0, lit_1.html) `
      <div id="body" class="dgl_b">
        ${(_a = this.filteredAlertList) === null || _a === void 0 ? void 0 : _a.map((item, index) => (0, lit_1.html) `
            <div class="card_wrapper card_wrapper-item-${index} ${item.cardState ? 'active' : ''}">
              ${this.CollapseCardHeader(item, index)}

              <div class="card_b">
                ${item.type === 1 ? (0, lit_1.html) ` ${this.Type1(item, index)}` : (0, lit_1.html) ` ${this.Type2(item, index)}`}
              </div>
            </div>
          `)}
      </div>
    `;
    }
    CollapseCardHeader(item, index) {
        return (0, lit_1.html) `
      <div class="card_h" @click=${() => this.toggleCardState(`card_wrapper-item-${index}`, item)}>
          <button class="card_O_C">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd"
              d="M10.3162 0.375L6.12075 4.68698L1.92531 0.375L0.648438 1.68734L6.12075 7.31166L11.5931 1.68734L10.3162 0.375Z"
              fill="#4D4D4D"></path>
            </svg>
          </button>
          <div class="card_h_l">
            <p class="card-heading-cc">
            <span class="cc_title_section" data-no-translate>${item.title}</span>
            ${this.ModuleNameTitle(item, index)}
            </p>
          </div>
        </div>
    `;
    }
    Type1(item, index) {
        var _a, _b;
        return (0, lit_1.html) `
        <div class="consistencyCardBodySection" data-no-translate>
          <div style="width: 100%;margin-left:-10px;">
            ${(_a = Object.keys(item.listItem)) === null || _a === void 0 ? void 0 : _a.map((item_child, index_child) => (0, lit_1.html) `
              <p>
                  <input type="radio" id="radio-option-${item_child + index_child}" name="name-${item.title}" value="${item_child}" ?checked="${item.listItem[item_child].checked}" @change=${() => this.radioButtonClick(item, item_child)}>
                  <label for="radio-option-${item_child + index_child}"> ${item_child} (${item.listItem[item_child].alerts.length + ' occurrences'})</label>
              </p>
            `)}
          </div>
          <div class="card_option_b">
          </div>
        </div>
        <div class="card_bottom_nav">
            <button class="apply-variant-cta cursorHand" @click=${() => this.applySelectedVarient(item.listItem)}>Apply to all occurrences</button>
            ${(_b = Object.keys(item.listItem)) === null || _b === void 0 ? void 0 : _b.map((item_child, index_child) => (0, lit_1.html) `
                ${item.listItem[item_child].checked ? (0, lit_1.html) `
                    <div>
                      <span @click=${() => this.previousItem(index, item_child)} class="cursorHand"><</span> ${this.filteredAlertList[index].listItem[item_child].currentCounter} - ${item.listItem[item_child].pagination.length} <span @click=${() => this.nextItem(index, item_child)} class="cursorHand">></span>
                    </div>
                    ` : (0, lit_1.html) ``}
            `)}
        </div>
    `;
    }
    Type2(item, index) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        switch (item.module) {
            case "spacing":
                return (0, lit_1.html) `
          <div class="card_des_sec">
            <div style="width: 100%;">
              <p data-no-translate>
                <input type="radio" id="radio-option-add-space-${item.module}" name="name-add-remove-space-${item.module}" value="add-space" ?checked="${item.listItem['add-space'].checked}" @change=${() => this.radioButtonClick(item, 'add-space')}>
                <label for="radio-option-add-space-${item.module}" class="add_remove_space_title">Add space</label>
              </p>
              <ul class="add-remove-space-ul" data-no-translate>
                  ${(_a = item.listItem['add-space']) === null || _a === void 0 ? void 0 : _a.alerts.slice(0, item.listItem['add-space'].showAll ? (_b = item.listItem['add-space']) === null || _b === void 0 ? void 0 : _b.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['add-space'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['add-space'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['add-space'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['add-space'])}>View less<span>`}
              </div>` : ``}
              <p data-no-translate>
                <input type="radio" id="radio-option-remove-space-${item.module}" name="name-add-remove-space-${item.module}" value="remove-space" ?checked="${item.listItem['remove-space'].checked}" @change=${() => this.radioButtonClick(item, 'remove-space')}>
                <label for="radio-option-remove-space-${item.module}" class="add_remove_space_title">Remove space</label>
              </p>
              <ul class="add-remove-space-ul" data-no-translate>
                  ${(_c = item.listItem['remove-space']) === null || _c === void 0 ? void 0 : _c.alerts.slice(0, item.listItem['remove-space'].showAll ? (_d = item.listItem['remove-space']) === null || _d === void 0 ? void 0 : _d.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['remove-space'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['remove-space'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['remove-space'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['remove-space'])}>View less<span>`}
              </div>` : ``}
              <div class="card_option_b">
              </div>
              <div class="card_bottom_nav">
                <button class="apply-variant-cta cursorHand" @click=${() => this.applySelectedVarient(item.listItem)}>Apply to all occurrences</button>
                ${(_e = Object.keys(item.listItem)) === null || _e === void 0 ? void 0 : _e.map((item_child, index_child) => (0, lit_1.html) `
                  ${item.listItem[item_child].checked ? (0, lit_1.html) `
                      <div>
                      <span @click=${() => this.previousItem(index, item_child)} class="cursorHand"><</span> ${this.filteredAlertList[index].listItem[item_child].currentCounter} - ${item.listItem[item_child].pagination.length} <span @click=${() => this.nextItem(index, item_child)} class="cursorHand">></span>
                      </div>
                  ` : (0, lit_1.html) ``}
                `)}
              </div>
          `;
                break;
            case "punctuations":
                return (0, lit_1.html) `
          <div class="card_des_sec">
            <div style="width: 100%;">
              <p data-no-translate>
                <input type="radio" id="radio-option-out-side-${item.module}" name="name-add-remove-space-${item.module}" value="out-side" ?checked="${item.listItem['out-side'].checked}" @change=${() => this.radioButtonClick(item, 'out-side')}>
                <label for="radio-option-out-side-${item.module}" class="add_remove_space_title">Add space</label>
              </p>
              <ul data-no-translate class="add-remove-space-ul">
                  ${(_f = item.listItem['out-side']) === null || _f === void 0 ? void 0 : _f.alerts.slice(0, item.listItem['out-side'].showAll ? (_g = item.listItem['out-side']) === null || _g === void 0 ? void 0 : _g.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['out-side'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['out-side'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['out-side'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['out-side'])}>View less<span>`}
              </div>` : ``}
              <p data-no-translate>
                <input type="radio" id="radio-option-in-side-${item.module}" name="name-add-in-side-${item.module}" value="in-side" ?checked="${item.listItem['in-side'].checked}" @change=${() => this.radioButtonClick(item, 'in-side')}>
                <label for="radio-option-in-side-${item.module}" class="add_remove_space_title">Remove space</label>
              </p>
              <ul data-no-translate class="add-in-side-ul">
                  ${(_h = item.listItem['in-side']) === null || _h === void 0 ? void 0 : _h.alerts.slice(0, item.listItem['in-side'].showAll ? (_j = item.listItem['in-side']) === null || _j === void 0 ? void 0 : _j.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-in-side-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['in-side'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['in-side'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['in-side'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['in-side'])}>View less<span>`}
              </div>` : ``}
              <div class="card_option_b">
              </div>
              <div class="card_bottom_nav">
                <button class="apply-variant-cta cursorHand" @click=${() => this.applySelectedVarient(item.listItem)}>Apply to all occurrences</button>
                ${(_k = Object.keys(item.listItem)) === null || _k === void 0 ? void 0 : _k.map((item_child, index_child) => (0, lit_1.html) `
                  ${item.listItem[item_child].checked ? (0, lit_1.html) `
                      <div>
                      <span @click=${() => this.previousItem(index, item_child)} class="cursorHand"><</span> ${this.filteredAlertList[index].listItem[item_child].currentCounter} - ${item.listItem[item_child].pagination.length} <span @click=${() => this.nextItem(index, item_child)} class="cursorHand">></span>
                      </div>
                  ` : (0, lit_1.html) ``}
                `)}
              </div>
          `;
                break;
            case 'symbol':
                return (0, lit_1.html) `
        <div class="card_des_sec">
            <div style="width: 100%;">
              <p data-no-translate>
                <input type="radio" id="radio-option-symbol-to-string-${item.module}" name="name-symbol-string-${item.module}" value="symbol" ?checked="${item.listItem['symbol'].checked}" @change=${() => this.radioButtonClick(item, 'symbol')}>
                <label for="radio-option-symbol-to-string-${item.module}" class="add_remove_space_title">Symbol <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> Spelled-out</label>
              </p>
              <ul data-no-translate class="add-remove-space-ul">
                  ${(_l = item.listItem['symbol']) === null || _l === void 0 ? void 0 : _l.alerts.slice(0, item.listItem['symbol'].showAll ? (_m = item.listItem['symbol']) === null || _m === void 0 ? void 0 : _m.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['symbol'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['symbol'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['symbol'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['symbol'])}>View less<span>`}
              </div>` : ``}
              <p data-no-translate>
                <input type="radio" id="radio-option-string-to-symbol-${item.module}" name="name-symbol-string-${item.module}" value="string" ?checked="${item.listItem['string'].checked}" @change=${() => this.radioButtonClick(item, 'string')}>
                <label for="radio-option-string-to-symbol-${item.module}" class="add_remove_space_title">Spelled-out <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> Symbol</label>
              </p>
              <ul data-no-translate class="add-remove-space-ul">
                  ${(_o = item.listItem['string']) === null || _o === void 0 ? void 0 : _o.alerts.slice(0, item.listItem['string'].showAll ? (_p = item.listItem['string']) === null || _p === void 0 ? void 0 : _p.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['string'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['string'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['string'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['string'])}>View less<span>`}
              </div>` : ``}
              <div class="card_option_b">
              </div>
              <div class="card_bottom_nav">
                <button class="apply-variant-cta cursorHand" @click=${() => this.applySelectedVarient(item.listItem)}>Apply to all occurrences</button>
                ${(_q = Object.keys(item.listItem)) === null || _q === void 0 ? void 0 : _q.map((item_child, index_child) => (0, lit_1.html) `
                  ${item.listItem[item_child].checked ? (0, lit_1.html) `
                      <div>
                      <span @click=${() => this.previousItem(index, item_child)} class="cursorHand"><</span> ${this.filteredAlertList[index].listItem[item_child].currentCounter} - ${item.listItem[item_child].pagination.length} <span @click=${() => this.nextItem(index, item_child)} class="cursorHand">></span>
                      </div>
                  ` : (0, lit_1.html) ``}
                `)}
              </div>
        `;
                break;
            case 'numbers':
                return (0, lit_1.html) `
        <div class="card_des_sec">
            <div style="width: 100%;">
              <p data-no-translate>
                <input type="radio" id="radio-option-digit-to-string-${item.module}" name="name-digit-string-${item.module}" value="digit" ?checked="${item.listItem['digit'].checked}" @change=${() => this.radioButtonClick(item, 'digit')}>
                <label for="radio-option-digit-to-string-${item.module}" class="add_remove_space_title numberCategory">Numeral <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> Spelled-out</label>
              </p>
              <ul data-no-translate class="add-remove-space-ul">
                  ${(_r = item.listItem['digit']) === null || _r === void 0 ? void 0 : _r.alerts.slice(0, item.listItem['digit'].showAll ? (_s = item.listItem['digit']) === null || _s === void 0 ? void 0 : _s.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['digit'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['digit'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['digit'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['digit'])}>View less<span>`}
              </div>` : ``}
              <p data-no-translate>
                <input type="radio" id="radio-option-string-to-digit-${item.module}" name="name-digit-string-${item.module}" value="string" ?checked="${item.listItem['string'].checked}" @change=${() => this.radioButtonClick(item, 'string')}>
                <label for="radio-option-string-to-digit-${item.module}" class="add_remove_space_title numberCategory">Spelled-out <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> Numeral</label>
              </p>
              <ul data-no-translate class="add-remove-space-ul">
                  ${(_t = item.listItem['string']) === null || _t === void 0 ? void 0 : _t.alerts.slice(0, item.listItem['string'].showAll ? (_u = item.listItem['string']) === null || _u === void 0 ? void 0 : _u.alerts.length : this.maxItemsToShow).map((ele, index) => (0, lit_1.html) `
                      <li class="add-remove-space-li">
                        ${ele.text} <img alt="arrow" src="https://cloud.trinka.ai/assets/images/arrow.png"> ${ele.correction}
                      </li>
                  `)}
              </ul>
              ${item.listItem['string'].alerts.length > 3 ? (0, lit_1.html) `<div class="viewAllOption">
                ${!item.listItem['string'].showAll ?
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['string'])}>View All<span>` :
                    (0, lit_1.html) `<span @click=${() => this.toggleViewAllOption(item.listItem['string'])}>View less<span>`}
              </div>` : ``}
              <div class="card_option_b">
              </div>
              <div class="card_bottom_nav">
                <button class="apply-variant-cta cursorHand" @click=${() => this.applySelectedVarient(item.listItem)}>Apply to all occurrences</button>
                ${(_v = Object.keys(item.listItem)) === null || _v === void 0 ? void 0 : _v.map((item_child, index_child) => (0, lit_1.html) `
                  ${item.listItem[item_child].checked ? (0, lit_1.html) `
                      <div>
                      <span @click=${() => this.previousItem(index, item_child)} class="cursorHand"><</span> ${this.filteredAlertList[index].listItem[item_child].currentCounter} - ${item.listItem[item_child].pagination.length} <span @click=${() => this.nextItem(index, item_child)} class="cursorHand">></span>
                      </div>
                  ` : (0, lit_1.html) ``}
                `)}
              </div>
        `;
                break;
            default:
                (0, lit_1.html) `<p>nothing</p>`;
                break;
        }
    }
    toggleViewAllOption(category) {
        category.showAll = !category.showAll;
        this.requestUpdate();
    }
    ModuleNameTitle(item, index) {
        return (0, lit_1.html) `
        ${item.type === 1 && item.module === 'dashes' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Dashes<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'spelling' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Spelling<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'accent_chars' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Accent characters<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 2 && item.module === 'numbers' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title" style="margin-left:0">Spelled-out Numbers</span>&nbsp;<strong class="card-cat-title"><span>(</span>Numbers<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'table_title' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Table<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'tables_title' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Tables<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'figure_title' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Figure<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'figures_title' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Figures<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'box_title' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Box<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'boxes_title' ? (0, lit_1.html) `<strong class="card-cat-title"><span>(</span>Boxes<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'p_value' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title">P Value</span> <strong class="card-cat-title"><span>(</span>Values<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 2 && item.module === 'symbol' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title">Greek letters</span> <strong class="card-cat-title"><span>(</span>Style<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 2 && item.module === 'punctuations' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title">Quotation marks</span> <strong class="card-cat-title"><span>(</span>Punctuation<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 2 && item.module === 'spacing' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title">Spaces around symbol</span> &nbsp;<strong class="card-cat-title"><span>(</span>Spacing<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 2 && item.module === 'symbol_spacing' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title">Spaces before symbols</span> &nbsp;<strong class="card-cat-title"><span>(</span>Spacing<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 2 && item.module === 'p_value' ? (0, lit_1.html) `<span data-no-translate class="card-heading-cc card-cat-title">P value</span>&nbsp;<strong class="card-cat-title"> <span>(</span>${item.casing ? 'Casing' : 'Decimal style'}<span>)</span></strong>` : (0, lit_1.html) ``}
        ${item.type === 1 && item.module === 'unit' ? (0, lit_1.html) `<b>${item.title} </b> <strong class="card-cat-title"><span>(</span>Unit<span>)</span></strong>` : (0, lit_1.html) ``}
    `;
    }
    returnCurrentCounter(index, key) {
        return this.filteredAlertList[index].listItem[key].currentCounter;
    }
    previousItem(index, key) {
        if (this.filteredAlertList[index].listItem[key].currentCounter == 1)
            return;
        this.filteredAlertList[index].listItem[key].currentCounter = this.filteredAlertList[index].listItem[key].currentCounter - 1;
        this.filteredAlertList[index].listItem[key].pagination.forEach(element => {
            if (element.title == this.filteredAlertList[index].listItem[key].currentCounter) {
                this.navigationEvent(element);
            }
        });
        this.requestUpdate();
    }
    nextItem(index, key) {
        if (this.filteredAlertList[index].listItem[key].currentCounter == this.filteredAlertList[index].listItem[key].pagination.length)
            return;
        this.filteredAlertList[index].listItem[key].currentCounter = this.filteredAlertList[index].listItem[key].currentCounter + 1;
        this.filteredAlertList[index].listItem[key].pagination.forEach(element => {
            if (element.title == this.filteredAlertList[index].listItem[key].currentCounter) {
                this.navigationEvent(element);
            }
        });
        this.requestUpdate();
    }
    groupArrayByFilterText(item, filter_text) {
        var mapList = item.filter(obj => {
            return obj.type == filter_text;
        });
        return mapList;
    }
    radioButtonClick(item, item_child) {
        Object.keys(item.listItem).forEach((e) => {
            item.listItem[e].checked = false;
            item.listItem[e].currentCounter = 1;
        });
        item.listItem[item_child].checked = true;
        this.despatchRadioButtonEvent(item.listItem[item_child], true);
        this.requestUpdate();
    }
    despatchRadioButtonEvent(item, isOpen) {
        const eventData = { data: item, editorId: this.editorId, isOpen };
        this.dispatchEvent(new CustomEvent('cc-alert-radio-selection', {
            bubbles: true,
            composed: true,
            detail: eventData
        }));
    }
    toggleCardState(identifier, item) {
        const clickedCard = this.shadowRoot.querySelector(`.${identifier}`);
        let isOpen = false;
        if (!item.cardState) {
            isOpen = true;
            this.closeAllCards();
            item.cardState = true;
            clickedCard.classList.add('active');
        }
        else {
            item.cardState = false;
            clickedCard.classList.remove('active');
        }
        this.despatchRadioButtonEvent(item.listItem[Object.keys(item.listItem)[0]], isOpen);
        Object.keys(item.listItem).forEach(key => {
            item.listItem[key].currentCounter = 1;
        });
        this.requestUpdate();
    }
    closeAllCards() {
        this.filteredAlertList.forEach((ele) => {
            ele.cardState = false;
        });
        const allCards = this.shadowRoot.querySelectorAll(`.card_wrapper.active`);
        allCards.forEach(card => {
            card.classList.remove('active');
        });
    }
    groupArray(data, flag = false) {
        this.dataGroupedByText = [];
        this.dataGroupedByText = this.groupByKey(data.alerts, 'text');
        if (flag) {
            return this.convertJSON(this.dataGroupedByText);
        }
        else {
            return Object.keys(this.dataGroupedByText);
        }
    }
    convertJSON(json2) {
        const json1 = {};
        for (const key in json2) {
            const alerts = json2[key].map((obj) => ({
                text: obj.text,
                begin: obj.begin,
                end: obj.end,
                paragraph: obj.paragraph,
                overlap: obj.overlap,
                checked: obj.checked
            }));
            json1[key] = { alerts, checked: false };
        }
        return json1;
    }
    applySelectedVarient(itemList) {
        var checkedElement = {};
        var uncheckedElement = [];
        Object.values(itemList).forEach((it) => {
            if (it.checked) {
                checkedElement = it;
            }
            else {
                uncheckedElement = uncheckedElement.concat(it.alerts);
            }
        });
        (0, utils_1.triggerAmplitudeEvent)('ED_Consistency_Applytoalloccurrences_CTA', {
            'category': checkedElement.module,
            'language': this.langCode
        });
        switch (checkedElement.alertType) {
            case 1:
                this.applyVarient(uncheckedElement, checkedElement.alerts[0].text, checkedElement.alertType);
                break;
            case 2:
                this.applyVarient(checkedElement.alerts, checkedElement.alerts[0].text, checkedElement.alertType);
                break;
            default:
                break;
        }
    }
    applyVarient(list, correction, alertType) {
        this.despatchApplyVarientEvent(list, correction, alertType);
    }
    despatchApplyVarientEvent(listToCorrect, correction, alertType) {
        const eventData = { data: { listToCorrect, correction, alertType }, editorId: this.editorId };
        this.dispatchEvent(new CustomEvent('cc-alert-apply-varient-selection', {
            bubbles: true,
            composed: true,
            detail: eventData
        }));
    }
    navigationEvent(obj) {
        const eventData = { alertId: obj.alertId, editorId: this.editorId };
        this.dispatchEvent(new CustomEvent('cc-alert-card-navigation', {
            bubbles: true,
            composed: true,
            detail: eventData
        }));
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
};
exports.AlertsPanel = AlertsPanel;
AlertsPanel.styles = (0, lit_1.css) `
  #body {
    position: relative;
    padding: 10px 10px 100px 15px;
    margin-top: 0px;
    margin-bottom: 20px;
    max-height: calc(100% - 100px);
    min-height: calc(100% - 100px);
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    transition: all 0.5s ease;
  }
  #body:hover{
    scrollbar-color: rgb(157, 157, 157) #EEEEEE;
    transition: all 0.5s ease;
  }
  .dgl_b .card_wrapper {
    position: relative;
    background: #FFFFFF;
    box-shadow: 1px 1px 2px 0 #4d4d4d21!important;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: all 0.5s ease;
  }
  .dgl_b .card_wrapper .card_h {
    position: relative;
    display: flex;
    padding: 13px 20px 13px 10px;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
  }
  .dgl_b .card_wrapper .card_h button {
    background-color: transparent;
    border: none;
    position: absolute;
    left: auto;
    right: 4px;
    top: 15px;
    display: none;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .dgl_b .card_wrapper.active .card_h button{
    display: block;
    transform: rotate(180deg);
    transition: all 0.5s ease 0s;
  }
  .dgl_b .card_wrapper.active .card_h .card_h_l .card-heading-cc .cc_title_section,
  .dgl_b .card_wrapper.active .card_h .card_h_l .card-heading-cc .card-cat-title span,
  .dgl_b .card_wrapper.active .card_h .card_h_l .card-heading-cc .card-heading-cc.card-cat-title{
    display: none !important;
  }
  .dgl_b .card_wrapper.active .card_h .card_h_l .card-heading-cc .card-cat-title{
    margin-left: 0
  }
  .dgl_b .card_wrapper .card_h .card_h_l {
    position: relative;
    display: flex;
    align-items: center;
  }
  .dgl_b .card_wrapper .card_h .card_h_l .identify_dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(225,0,0,.5);
    margin-top: 1px;
    margin-right: 6px;
    position: absolute;
    top: 8px;
  }
  .dgl_b .card_wrapper .card_h .card_h_l p {
    color: #4d4d4d;
    // text-decoration: line-through;
    text-decoration-color: #FF5C5C;
    padding: 0 0px 0 10px;
    margin: 0;
    font-size: 16px;
  }
  .dgl_b .card_wrapper .card_h .card_h_l p::before {
    content: "";
    position: absolute;
    position: absolute;
    left: 0px;
    top: 10px;
    background: rgba(225, 0, 0, .5);
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
  .dgl_b .card_wrapper .card_h .card_h_r, .dgl_b .card_wrapper.multiple_card_header .card_h_multiple .card_h_r {
    display: flex;
    align-items: unset;
    padding-left: 10px;
  }
  .dgl_b .card_wrapper .card_h .card_h_r svg, .dgl_b .card_wrapper.multiple_card_header .card_h_multiple .card_h_r svg {
    margin: 0 9px 0 0px;
    position: relative;
    top: 9px;
  }
  .dgl_b .card_wrapper .card_h .card_h_r p {
    color: #763493;
    cursor: pointer;
    padding: 3px 5px;
    margin-right: 10px;
    max-width: 170px;
    margin: 0;
    font-size: 16px;
  }
  .dgl_b .card_wrapper .card_b {
    position: relative;
    margin-top: 0px;
    padding: 0 10px 0 25px;
    display: none;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b {
    position: relative;
  }
  .card-heading-cc{
    position: relative;
    font-weight: 500;
    color: #ff5c5c !important;
    font-size: 16px;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec p {
    margin: 0;
    display: block;
    height: auto;
    color: rgba(77, 77, 77, 0.8);
    font-size: 12px;
    word-break: break-word;
    line-height: 17px;
    margin-bottom: 10px;
    width: calc(100% - 35px);
    margin-left: -5px;
  }
  .card-cat-title{
    font-weight: 500;
    color: #4d4d44;
    margin-left: 5px;
    font-size: 16px;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b {
    position: relative;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b ul li {
    margin-bottom: 10px;
  }
  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b ul li:last-child {
    margin-bottom: 0px;
  }
  .cc_type_2_list{
    margin: 0 0 10px;
    position: relative;
    padding-left: 21px;
  }
  .dgl_b .card_wrapper .card_b .card_cat {
    position: relative;
    margin-top: 5px;
    border-top: 1px solid #eeeeee;
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: -5px;
  }
  .add_remove_space_title{
    font-weight: 600;
    color: #4d4d4d;
    cursor: pointer;
  }
  .add_remove_space_title.numberCategory{
    position: relative;
    top: -2px;
  }
  .dgl_b .card_wrapper .card_b .card_cat p {
    font-size: 10px;
    font-style: italic;
    color: rgba(77, 77, 77, 0.8);
    width: 100%;
    line-height: inherit;
    min-height: auto;
    margin: 0;
    text-transform: uppercase;
  }
  .dgl_b .card_wrapper.active {
    box-shadow: 0 0 4px .5px rgba(77,77,77,.8)!important;
  }
  .dgl_b .card_wrapper.active .card_b {
    display: block;
  }
  .dgl_b .card_wrapper:hover .card_h button {
    display: block;
    transition: all 0.5s ease;
    z-index: 0; //Changing from 99
  }
  .apply-variant-cta{
    border: 1px solid #7a28a0;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    padding: 4px 0;
    width: 160px;
    background: #7a28a0;
    font-weight: 600;
    transition: all .5s ease;
    margin: 20px 0 19px -4px;
    font-family: "Open Sans", sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .apply-variant-cta:hover{
    border: 1px solid #7a28a0;
    background: transparent;
    color:  #7a28a0;
    transition: all .5s ease;
  }
  .card_bottom_nav{
    display: flex;
    align-items: center;
    right: auto;
    left: 165px;
    bottom: 24px;
  }
  .card_bottom_nav div {
    margin-left: 28px;
  }
  .cursorHand{
    cursor: pointer;
  }
  .add-remove-space-li{
    margin: 0 0 10px;
    position: relative;
    padding-left: 28px;
    font-size: 14px;
    color: rgba(0, 0, 0, .87);
  }
  .add-remove-space-ul{
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 30px 15px 0;
  }
  li::before {
    content: "";
    position: absolute;
    left: 15px;
    top: 9px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(77, 77, 77, .75);
  }
  input[type="radio" i] {
    background-color: initial;
    cursor: pointer;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 0px 5px;
    padding: initial;
    border: initial;
    accent-color: #4d4d4d;
  }
  .viewAllOption {
    color: #00e;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 0;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .consistencyCardBodySection p{
    margin: 5px 0;
  }
  .consistencyCardBodySection p label{
    font-size: 14px;
    color: rgba(0, 0, 0, .87);
    position: relative;
    top: -2px;
  }
  .paraphraser-outer-panel[trinka-language^="es"] .apply-variant-cta{
    width: 190px !important;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "filteredAlertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "editorId", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "langCode", void 0);
exports.AlertsPanel = AlertsPanel = __decorate([
    (0, decorators_js_1.customElement)('alerts-panel-cc')
], AlertsPanel);
