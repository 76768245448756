"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorOverlay = void 0;
class EditorOverlay {
    constructor(editorId, editor, isCloudTrinka, isBrowserExtension = false) {
        this.editorId = editorId;
        this.isCloudTrinka = isCloudTrinka;
        this.isBrowserExtension = isBrowserExtension;
        this.trinkaExtension = document.createElement('trinka-extension');
        const trinkaExtensionShadowRoot = this.trinkaExtension.attachShadow({ mode: 'open' });
        editor.parentElement.appendChild(this.trinkaExtension);
        this.editor = editor;
        this.editorOverlayWrapper = this.createOverlay();
        trinkaExtensionShadowRoot.appendChild(this.editorOverlayWrapper);
    }
    handleScrollEvent() {
        var _a;
        if (!this.editorOverlay || !this.editorOverlayElements)
            return;
        this.editorOverlay.scrollTop = this.editor.scrollTop;
        this.editorOverlay.scrollLeft = this.editor.scrollLeft;
        Object.assign(this.editorOverlayElements.style, {
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
    }
    handleResizeEvent() {
        var _a;
        if (!this.editorOverlay || !this.editorOverlayElements)
            return;
        const computedstyle = window.getComputedStyle(this.editor);
        Object.assign(this.editorOverlay.style, {
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay.scrollTop = this.editor.scrollTop;
        this.editorOverlay.scrollLeft = this.editor.scrollLeft;
        Object.assign(this.editorOverlayElements.style, {
            'width': `${(parseFloat(computedstyle.width) + 2000)}px`,
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
    }
    createOverlay() {
        var _a;
        const computedstyle = window.getComputedStyle(this.editor);
        const editorOverlayWrapper = document.createElement('div');
        editorOverlayWrapper.id = 'editorOverlayWrapper';
        Object.assign(editorOverlayWrapper.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'top': '0px',
            'left': '0px'
        });
        this.editorOverlayElements = document.createElement('div');
        this.editorOverlayElements.id = 'editorOverlayElements';
        Object.assign(this.editorOverlayElements.style, {
            'position': 'relative',
            'top': '0px',
            'left': '0px',
            'overflow': 'hidden',
            'width': `${(parseFloat(computedstyle.width) + 2000)}px`,
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
        this.boundHandleMouseOverEvent = ($event) => this.handleMouseOverEvent($event);
        this.editorOverlayElements.addEventListener('mouseover', this.boundHandleMouseOverEvent);
        this.boundHandleMouseOutEvent = ($event) => this.handleMouseOutEvent($event);
        this.editorOverlayElements.addEventListener('mouseout', this.boundHandleMouseOutEvent);
        this.boundHandleClickEvent = ($event) => this.handleClickEvent($event);
        this.editorOverlayElements.addEventListener('click', this.boundHandleClickEvent);
        this.editorOverlay = document.createElement('div');
        this.editorOverlay.id = 'editorOverlay';
        this.editorOverlay.classList.add('editorOverlay');
        Object.assign(this.editorOverlay.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'overflow': 'hidden',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay.appendChild(this.editorOverlayElements);
        editorOverlayWrapper.appendChild(this.editorOverlay);
        this.sentenceWiseEditorOverlay = document.createElement('div');
        this.sentenceWiseEditorOverlay.id = 'sentenceWiseEditorOverlay';
        this.sentenceWiseEditorOverlay.classList.add('sentenceWiseEditorOverlay');
        Object.assign(this.sentenceWiseEditorOverlay.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'overflow': 'hidden',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        editorOverlayWrapper.appendChild(this.sentenceWiseEditorOverlay);
        let that = this;
        this.editorOverlay.addEventListener('click', function (event) {
            const x = event.clientX;
            const y = event.clientY;
            const scrollTop = that.editor.scrollTop;
            that.editor.scrollTop = scrollTop;
            const range = document.createRange();
            const selection = window.getSelection();
            function getNodeAtCoordinates(root, x, y) {
                const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT, null);
                let node;
                while ((node = walker.nextNode())) {
                    const range = document.createRange();
                    range.selectNodeContents(node);
                    const rects = range.getClientRects();
                    for (let rect of rects) {
                        if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
                            return node;
                        }
                    }
                }
                return null;
            }
            const textNode = getNodeAtCoordinates(that.editor, x, y);
            if (textNode) {
                range.selectNodeContents(textNode);
                let charIndex = 0;
                let found = false;
                const length = textNode.textContent.length;
                for (charIndex = 0; charIndex < length; charIndex++) {
                    range.setStart(textNode, charIndex);
                    range.setEnd(textNode, charIndex + 1);
                    const rect = range.getBoundingClientRect();
                    if (rect.left <= x && rect.right >= x && rect.top <= y && rect.bottom >= y) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    charIndex = length;
                }
                range.setStart(textNode, charIndex);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        });
        return editorOverlayWrapper;
    }
    handleMouseOverEvent($event) {
        const target = $event.target;
        if (target.classList.contains('alert')) {
            const event = new CustomEvent('tsdk-editor-underline-mouse-over', {
                bubbles: true,
                composed: true,
                detail: {
                    editorId: this.editorId,
                    alertId: target.id,
                    target
                }
            });
            document.dispatchEvent(event);
        }
    }
    handleMouseOutEvent($event) {
        const target = $event.target;
        if (target.classList.contains('alert')) {
            const event = new CustomEvent('tsdk-editor-underline-mouse-out', {
                bubbles: true,
                composed: true,
                detail: {
                    editorId: this.editorId,
                    alertId: target.id,
                    $event
                }
            });
            document.dispatchEvent(event);
        }
    }
    handleClickEvent($event) {
        const target = $event.target;
        if (target.classList.contains('alert')) {
            const event = new CustomEvent('tsdk-editor-underline-clicked', {
                bubbles: true,
                composed: true,
                detail: {
                    editorId: this.editorId,
                    alertId: target.id,
                    $event
                }
            });
            document.dispatchEvent(event);
        }
    }
    remove() {
        var _a;
        if (this.editor.parentElement && this.editor.parentElement.contains(this.trinkaExtension)) {
            this.editor.parentElement.removeChild(this.trinkaExtension);
        }
        else
            (_a = this.trinkaExtension) === null || _a === void 0 ? void 0 : _a.remove();
        this.editorOverlayElements.removeEventListener('mouseover', this.boundHandleMouseOverEvent);
        this.editorOverlayElements.removeEventListener('mouseout', this.boundHandleMouseOutEvent);
        this.editorOverlayElements.removeEventListener('click', this.boundHandleClickEvent);
    }
}
exports.EditorOverlay = EditorOverlay;
