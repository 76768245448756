"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertsPanel = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const unsafe_html_js_1 = require("lit/directives/unsafe-html.js");
require("./fup-card");
const utils_1 = require("../../helpers/utils");
const environment_1 = require("src/env/environment");
let AlertsPanel = class AlertsPanel extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.isCloudTrinka = false;
        this.filteredAlertList = [];
        this.trinkaEditorConfig = {};
        this.currentIndexOfSuggestion = 0;
        this.limitExhausted = false;
        this.userUpgradeLink = '';
        this.globalGroupedObj = {};
        this.currentLang = 'en';
        this.newArr = [];
        this.langCategoryArr = ['Brevity', 'Capitalization & Spacing', 'Contractions', 'Punctuation', 'Regional Style', 'Spellings & Typos'];
        this.highlightedGroupedAlertId = '';
    }
    render() {
        var _a, _b, _c;
        return (0, lit_1.html) `
    <div id="body" class="dgl_b ${this.isCloudTrinka ? 'trinkaCloudEditor' : 'trinkaB2BEditor'}">
    ${this.limitExhausted ? (0, lit_1.html) `<fup-card .userType="${(_a = this.trinkaEditorConfig) === null || _a === void 0 ? void 0 : _a.userType}" .userUpgradeLink="${this.userUpgradeLink}"></fup-card>` : ``}
    ${(_b = this.filteredAlertList) === null || _b === void 0 ? void 0 : _b.filter(x => x.isGrouped && x.groupPosition === 0).sort((a, b) => a.groupNumber - b.groupNumber).map((alert, index) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return (0, lit_1.html) `
      <div id="${alert.alertId}" class="grouped-alert-card alert-card card_wrapper group_card_wrapper-item-${index} ${alert.disabled ? 'alert-disabled' : ''}">
        ${alert.suggestions && alert.suggestions.length && alert.suggestions.length > 1 ? (0, lit_1.html) `
          <div class="card_h" @click=${() => this.toggleCardState(alert.alertId, `group_card_wrapper-item-${index}`)}>
            <button class="card_O_C">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3162 0.375L6.12075 4.68698L1.92531 0.375L0.648438 1.68734L6.12075 7.31166L11.5931 1.68734L10.3162 0.375Z"
                  fill="#4D4D4D"></path>
                </svg>
             </button>
             <div class="card_cat">
                ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
                    <span class="masterCardIcons">
                      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="1.94595" cy="2.94727" rx="1.94595" ry="2" fill="#DC2626"/>
                        <ellipse cx="3.94595" cy="2.94727" rx="1.94595" ry="2" fill="#F87171"/>
                        <ellipse cx="5.94595" cy="2.94727" rx="1.94595" ry="2" fill="#FEE2E2"/>
                      </svg>
                    </span>
                    <span class="alertTotalNumberCount">
                      ${(_b = (_a = this.similarAlertsMap) === null || _a === void 0 ? void 0 : _a.get(alert.suggestions[0].lang_category)) !== null && _b !== void 0 ? _b : 0}
                    </span>
                    <p>${this.getGroupedCardHeading(alert.suggestions[0].lang_category)}</p>
                    `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
             </div>
            <div class="card_h_l">
              <span class="identify_dot"></span>
              <p style="text-decoration: ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present ? 'line-through; text-decoration-color: #FF5C5C;' : 'none'};">${alert.text}</p>
            </div>
          </div>
          ${(_c = alert.suggestions) === null || _c === void 0 ? void 0 : _c.map((suggestion, i) => (0, lit_1.html) `
            <div class="card_h" @click=${() => this.toggleCardState(alert.alertId, `group_card_wrapper-item-${index}`)} style="${i === 0 ? 'padding-bottom: 0px;' : 'padding-top: 0px; padding-bottom: 0px;'}">
              <!--<button class="card_O_C" style="visibility: ${i === 0 ? 'visible' : 'hidden'}">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3162 0.375L6.12075 4.68698L1.92531 0.375L0.648438 1.68734L6.12075 7.31166L11.5931 1.68734L10.3162 0.375Z"
                  fill="#4D4D4D"></path>
                </svg>
              </button>-->
              <!--<div class="card_cat">
                ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
                    <p>${this.getAlertCategory(alert.suggestions[0])}</p>
                    `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
              </div>-->
              <!-- <div class="card_h_l" style="visibility: ${i === 0 ? 'visible' : 'hidden'}">
                <span class="identify_dot"></span>
                <p style="text-decoration: ${suggestion.cta_present ? 'line-through; text-decoration-color: #FF5C5C;' : 'none'};">${alert.text}</p>
              </div> -->
              <div class="card_h_r">
                ${suggestion.cta_present
                ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                  fill="#4D4D4D" fill-opacity="0.38"></path>
                </svg>` : (0, lit_1.html) ``}
                <p @click=${(event) => this.toggleCardState(alert.alertId, `group_card_wrapper-item-${index}`)}>
                  ${suggestion.cta_present
                ? (0, lit_1.html) `
                    <div>${suggestion.suggestion === '' ? alert.text : suggestion.suggestion}</div>
                    `
                : (0, lit_1.html) ``}
                </p>
              </div>
            </div>
          `)}
        ` : (0, lit_1.html) `
          <div class="card_h" @click=${() => this.toggleCardState(alert.alertId, `group_card_wrapper-item-${index}`)}>
            <button class="card_O_C">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd"
                d="M10.3162 0.375L6.12075 4.68698L1.92531 0.375L0.648438 1.68734L6.12075 7.31166L11.5931 1.68734L10.3162 0.375Z"
                fill="#4D4D4D"></path>
              </svg>
            </button>
            <div class="card_cat">
                 ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
                    <span class="masterCardIcons">
                      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="1.94595" cy="2.94727" rx="1.94595" ry="2" fill="#DC2626"/>
                        <ellipse cx="3.94595" cy="2.94727" rx="1.94595" ry="2" fill="#F87171"/>
                        <ellipse cx="5.94595" cy="2.94727" rx="1.94595" ry="2" fill="#FEE2E2"/>
                      </svg>
                    </span>
                    <span class="alertTotalNumberCount">
                      ${(_e = (_d = this.similarAlertsMap) === null || _d === void 0 ? void 0 : _d.get(alert.suggestions[0].lang_category)) !== null && _e !== void 0 ? _e : 0}
                    </span>
                    <p>${this.getGroupedCardHeading(alert.suggestions[0].lang_category)}</p>
                    `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
              </div>
            <div class="card_h_l">
              <span class="identify_dot"></span>
              <p style="text-decoration: ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present ? 'line-through; text-decoration-color: #FF5C5C;' : 'none'};">${alert.text}</p>
            </div>
            <div class="card_h_r">
              ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present
                ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                fill="#4D4D4D" fill-opacity="0.38"></path>
              </svg>` : (0, lit_1.html) ``}
              <p @click=${(event) => {
                event.stopPropagation();
                this.toggleCardState(alert.alertId, `group_card_wrapper-item-${index}`);
            }}>
                ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present
                ? (0, lit_1.html) `
                  <div>${alert.suggestions[0].suggestion === '' ? alert.text : alert.suggestions[0].suggestion}</div>
                  `
                : (0, lit_1.html) ``}
              </p>
            </div>
          </div>
          ${alert.isGrouped ? (0, lit_1.html) `<div class="alert-grouped-span-outer" @click=${() => this.toggleCardState(alert.alertId, `group_card_wrapper-item-${index}`)}><span class="alert-grouped-span">${(_g = (_f = this.similarAlertsMap) === null || _f === void 0 ? void 0 : _f.get(alert.suggestions[0].lang_category)) !== null && _g !== void 0 ? _g : 0} 
            similar alerts
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.892345 0.153898L4.36944 3.63099C4.41806 3.67945 4.45664 3.73703 4.48297 3.80042C4.50929 3.86382 4.52284 3.93179 4.52284 4.00044C4.52284 4.06908 4.50929 4.13705 4.48297 4.20045C4.45664 4.26384 4.41806 4.32142 4.36944 4.36988L0.892345 7.84697C0.794363 7.94495 0.661471 8 0.522904 8C0.384336 8 0.251444 7.94495 0.153462 7.84697C0.0554803 7.74899 0.000434731 7.6161 0.00043473 7.47753C0.000434728 7.33896 0.0554803 7.20607 0.153462 7.10809L3.26155 4L0.153028 0.891912C0.0550459 0.79393 0 0.661038 0 0.52247C0 0.383903 0.0550459 0.251011 0.153028 0.153029C0.25101 0.055047 0.383902 0 0.522469 0C0.661037 0 0.793929 0.055047 0.891911 0.153029L0.892345 0.153898Z" fill="#F87171"/>
              <path d="M5.41517 0.153898L8.89227 3.63099C8.94089 3.67945 8.97947 3.73703 9.0058 3.80042C9.03212 3.86382 9.04567 3.93179 9.04567 4.00044C9.04567 4.06908 9.03212 4.13705 9.0058 4.20045C8.97947 4.26384 8.94089 4.32142 8.89227 4.36988L5.41517 7.84697C5.31719 7.94495 5.1843 8 5.04573 8C4.90716 8 4.77427 7.94495 4.67629 7.84697C4.57831 7.74899 4.52326 7.6161 4.52326 7.47753C4.52326 7.33896 4.57831 7.20607 4.67629 7.10809L7.78438 4L4.67586 0.891912C4.57787 0.79393 4.52283 0.661038 4.52283 0.52247C4.52283 0.383903 4.57787 0.251011 4.67586 0.153029C4.77384 0.055047 4.90673 0 5.0453 0C5.18386 0 5.31676 0.055047 5.41474 0.153029L5.41517 0.153898Z" fill="#B91C1C"/>
            </svg>
            </span></div>` : ``}
        `}
        <div class="card_b">
          <div class="multiAlertGroupingBody" id="alertGroupingBody">
          ${this.getGroupedAlertsList(alert.suggestions[0].lang_category).map((singleAlert, index) => {
                var _a, _b;
                return (0, lit_1.html) `
              <div class="cardBodyAlerts" data-no-translate>
                <div class="multiAlertBodyHover ${singleAlert.suggestions[0].type === 2 ? 'withAddToDictionaryBtn' : ''}" @click="${() => { this.viewGroupAlertInEditor(singleAlert.alertId); }}">
                  <span>${(0, unsafe_html_js_1.unsafeHTML)(this.getAlertHTML(singleAlert))}</span>
                  ${singleAlert.suggestions && singleAlert.suggestions.length && singleAlert.suggestions[0].cta_present
                    ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                      fill="#4D4D4D" fill-opacity="0.38"></path>
                    </svg>` : (0, lit_1.html) ``}
                    <p class="rightTextSection ${singleAlert.suggestions[0].suggestion.length > 0 ? '' : 'hideRightTextSection'}">
                      ${singleAlert.suggestions && singleAlert.suggestions.length && ((_a = singleAlert.suggestions[this.currentIndexOfSuggestion]) === null || _a === void 0 ? void 0 : _a.cta_present) && singleAlert.suggestions.length === 1
                    ? (0, lit_1.html) `
                        ${singleAlert.suggestions[this.currentIndexOfSuggestion].suggestion === '' ? singleAlert.text : singleAlert.suggestions[this.currentIndexOfSuggestion].suggestion}
                        `
                    : (0, lit_1.html) ``}
                    </p>
                    <span class="view-text-tooltip">View in text</span>
                  ${singleAlert.suggestions && singleAlert.suggestions.length && singleAlert.suggestions.length <= 1 ? (0, lit_1.html) `<span class="">${this.extractSurroundingWord(singleAlert.sentenceText, singleAlert.begin, singleAlert.end, false)}...</span>` : ``}
                    
                  ${singleAlert.suggestions && singleAlert.suggestions.length && singleAlert.suggestions.length > 1 ? (0, lit_1.html) `
                  ${(_b = singleAlert.suggestions) === null || _b === void 0 ? void 0 : _b.map((suggestion, i) => (0, lit_1.html) `
                  <p class="rightTextSection multiSuggestionsListOption">
                    <span>
                    ${suggestion.cta_present
                    ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                      fill="#4D4D4D" fill-opacity="0.38"></path>
                    </svg>` : (0, lit_1.html) ``}
                    </span>
                    ${suggestion.cta_present
                    ? (0, lit_1.html) `
                        <div style="width: 210px">${suggestion.suggestion === '' ? singleAlert.text : suggestion.suggestion}</div>
                        `
                    : (0, lit_1.html) ``}
                      <button class="multiAlertAcceptButton">Accept</button>
                    </p>
                  `)}
                  ` : ``}
                </div>

                <div class="onHoverContents">
                      ${singleAlert.suggestions && singleAlert.suggestions.length && singleAlert.suggestions[0].type == 2
                    ? (0, lit_1.html) `<a @click=${() => this.muteAlert(singleAlert.alertId, true)} class="hoverAddToDictionary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 10" fill="none">
                          <g clip-path="url(#clip0_350_2174)">
                          <path d="M1.25 9.375H5.95215V10H1.25C1.07747 10 0.916341 9.96745 0.766602 9.90234C0.616862 9.83724 0.483398 9.74772 0.366211 9.63379C0.249023 9.51986 0.159505 9.38802 0.0976562 9.23828C0.0358073 9.08854 0.00325521 8.92578 0 8.75V1.25C0 1.09049 0.0341797 0.935872 0.102539 0.786133C0.170898 0.636393 0.265299 0.50293 0.385742 0.385742C0.506185 0.268555 0.639648 0.175781 0.786133 0.107422C0.932617 0.0390625 1.08724 0.00325521 1.25 0H8.125V5.95215H7.8125H7.5V0.625H1.25C1.17513 0.625 1.10026 0.642904 1.02539 0.678711C0.950521 0.714518 0.883789 0.763346 0.825195 0.825195C0.766602 0.887044 0.719401 0.953776 0.683594 1.02539C0.647786 1.09701 0.628255 1.17188 0.625 1.25V7.67578C0.719401 7.62044 0.818685 7.57812 0.922852 7.54883C1.02702 7.51953 1.13607 7.50326 1.25 7.5H5.95215V7.8125V8.125H1.25C1.16211 8.125 1.08073 8.14128 1.00586 8.17383C0.93099 8.20638 0.865885 8.25033 0.810547 8.30566C0.755208 8.361 0.709635 8.42773 0.673828 8.50586C0.638021 8.58398 0.621745 8.66536 0.625 8.75C0.625 8.83789 0.641276 8.91927 0.673828 8.99414C0.70638 9.06901 0.750326 9.13411 0.805664 9.18945C0.861003 9.24479 0.927734 9.29036 1.00586 9.32617C1.08398 9.36198 1.16536 9.37826 1.25 9.375ZM6.25 3.75H1.875V1.875H6.25V3.75ZM2.5 2.5V3.125H5.625V2.5H2.5Z" fill="#414E62"/>
                          <path d="M8.32975 6.66699V8.28809M8.32975 10.0003V8.28809M8.32975 8.28809H9.99642H6.66309" stroke="#414E62" stroke-width="0.666667"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_350_2174">
                          <rect width="10" height="10" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                        <span>Add to dictionary</span>
                      </a>` : ``}
                      <a class="hoverDismiss" @click=${() => this.rejectAlert(singleAlert.alertId, true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14" fill="none">
                          <path d="M11.8211 3.77494C10.0372 3.59815 8.24251 3.50708 6.45322 3.50708C5.39251 3.50708 4.33179 3.56065 3.27108 3.66779L2.17822 3.77494" stroke="#414E62" stroke-width="0.771429" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M5.125 3.23389L5.24286 2.53211C5.32857 2.02318 5.39286 1.64282 6.29821 1.64282H7.70179C8.60714 1.64282 8.67679 2.04461 8.75714 2.53747L8.875 3.23389" stroke="#414E62" stroke-width="0.771429" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.6694 5.46777L10.3212 10.8624C10.2622 11.7035 10.214 12.3571 8.71936 12.3571H5.28008C3.78544 12.3571 3.73722 11.7035 3.67829 10.8624L3.33008 5.46777" stroke="#414E62" stroke-width="0.771429" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M6.10547 9.41064H7.8894" stroke="#414E62" stroke-width="0.771429" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M5.66064 7.26782H8.33922" stroke="#414E62" stroke-width="0.771429" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Dismiss</span>
                      </a>
                    </div>
              </div>
            `;
            })}
          <!-- <div class="card_des_sec" data-no-translate>
            <div style="width: 100%;" data-no-translate>
              ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].comment
                ? (0, lit_1.html) `
                <p>${alert.suggestions[0].comment}</p>
                `
                : (0, lit_1.html) ``}
            </div>
            <div class="card_option_b">
              <ul>
                <li style="cursor: pointer" @click=${() => this.rejectAlert(alert.alertId)}>
                  <svg fill="none" height="16" viewBox="0 0 20 20" width="16" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M1.3 8.26669C1.32248 8.73481 1.48877 9.18461 1.77619 9.55479C1.60192 9.93961 1.55414 10.3698 1.6397 10.7835C1.72526 11.1971 1.93976 11.5731 2.25238 11.8572C2.84286 12.4 3.85714 12.6429 5.25714 12.5715C5.87696 12.5432 6.49336 12.4636 7.1 12.3334V12.3334C7.29048 12.3 7.49048 12.2596 7.69286 12.2143C7.70714 12.4524 7.66667 13.05 7.21667 14.3905C6.69286 15.9834 6.72619 17.2048 7.31428 18.0215C7.53903 18.3119 7.82791 18.5464 8.15835 18.7067C8.48878 18.867 8.85181 18.9486 9.21905 18.9453C9.35503 18.9455 9.48941 18.916 9.61279 18.8588C9.73617 18.8016 9.84557 18.7182 9.93333 18.6143C10.3452 18.1381 10.2952 17.2405 10.2476 16.831C10.7667 15.5096 12.1429 12.2596 13.3333 11.3572C13.3548 11.3417 13.3748 11.3241 13.3929 11.3048C13.6971 10.9817 13.945 10.6099 14.1262 10.2048C14.3538 10.3295 14.6095 10.3943 14.869 10.3929H17.1429C17.5556 10.3923 17.9513 10.228 18.2432 9.93611C18.5351 9.64423 18.6994 9.24853 18.7 8.83574V2.7524C18.7003 2.5476 18.6603 2.34475 18.5822 2.15542C18.5041 1.96609 18.3895 1.79399 18.2449 1.64896C18.1003 1.50392 17.9286 1.38878 17.7395 1.31012C17.5504 1.23146 17.3477 1.19081 17.1429 1.1905H14.8619C14.5395 1.18981 14.2249 1.28969 13.9619 1.47621L13.0952 1.37621C11.4366 1.17319 9.76615 1.08171 8.09524 1.1024C7.09681 0.99801 6.0887 1.03327 5.1 1.20717C3.95476 1.44526 3.17381 1.93574 2.77381 2.65478C2.53481 3.09025 2.44531 3.59212 2.51905 4.08336C2.23578 4.31976 2.01133 4.61875 1.86341 4.95675C1.71548 5.29474 1.64813 5.66249 1.66667 6.03098C1.68237 6.28728 1.7353 6.53993 1.82381 6.78098C1.477 7.19755 1.29114 7.72472 1.3 8.26669V8.26669Z"
                    stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"></path>
                    <path d="M14 1.5V10.2405" stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.3"></path>
                  </svg>
                </li>
                ${this.hasSimilarCardsToIgnore(alert)
                ? (0, lit_1.html) `
                    <li style="cursor: pointer" @click=${() => this.ignoreAlertAll(alert.alertId)}>
                      <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.37834 9.52529V20.3867C7.37834 21.0285 7.60548 21.6312 8.00148 22.0641C8.39577 22.4979 8.94519 22.7441 9.52034 22.745H17.562C18.1372 22.7441 18.6866 22.4979 19.0809 22.0641C19.4778 21.6312 19.704 21.0285 19.704 20.3867V9.52529C20.4918 9.3084 21.0026 8.51817 20.8972 7.67994C20.7918 6.84082 20.1026 6.21415 19.2875 6.21415H17.1112V5.66303C17.1138 5.19992 16.9372 4.75458 16.6209 4.42746C16.3046 4.10035 15.8752 3.91813 15.4286 3.92168H11.6538C11.2072 3.91813 10.7778 4.10035 10.4615 4.42746C10.1452 4.75458 9.96862 5.19992 9.97119 5.66303V6.21415H7.79491C6.97977 6.21415 6.29063 6.84171 6.1852 7.67994C6.07977 8.51906 6.58977 9.3084 7.37834 9.52529ZM17.2526 6.21415H9.82976H17.2526Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4.12285 6.85879V17.7202C4.12285 18.362 4.34999 18.9647 4.74599 19.3976C5.14027 19.8314 5.6897 20.0776 6.26484 20.0785H14.3065C14.8817 20.0776 15.4311 19.8314 15.8254 19.3976C16.2223 18.9647 16.4485 18.362 16.4485 17.7202V6.85879C17.2363 6.6419 17.7471 5.85167 17.6417 5.01344C17.5363 4.17432 16.8471 3.54765 16.032 3.54765H13.8557V2.99653C13.8583 2.53341 13.6817 2.08808 13.3654 1.76096C13.0491 1.43385 12.6197 1.25162 12.1731 1.25518H8.39827C7.9517 1.25162 7.52227 1.43385 7.20598 1.76096C6.8897 2.08808 6.71313 2.53341 6.7157 2.99653V3.54765H4.53942C3.72427 3.54765 3.03513 4.17521 2.9297 5.01344C2.82428 5.85256 3.33428 6.6419 4.12285 6.85879ZM13.9971 3.54765H6.57427H13.9971Z" fill="white" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.01855 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.5528 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </li>
                  ` : (0, lit_1.html) ``}
              </ul>
            </div>
          </div>  -->
        </div>
          ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].type == 2
                ? (0, lit_1.html) `
            <div @click=${() => this.muteAlert(alert.alertId)}>
              <button class="addToDictionaryBtn" style="display: none;">Add to My Dictionary</button>
            </div>
            ` : (0, lit_1.html) ``}
          ${alert.suggestions && alert.suggestions.length && !alert.suggestions[0].cta_present
                ? (0, lit_1.html) `
            <div class="wasHelpfulBtnWrapper">
              <button class="addToDictionaryBtn wasHelpFulBtn" @click=${() => this.acceptNonCTAAlert(alert.alertId)}>This was helpful</button>
              <button class="btnDismissed" @click=${(event) => this.rejectAlert(alert.alertId)}>Dismiss</button>
            </div>
            ` : (0, lit_1.html) ``}
          <div class="card_cat">
            ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
                <span class="masterCardIcons">
                  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="1.94595" cy="2.94727" rx="1.94595" ry="2" fill="#DC2626"/>
                    <ellipse cx="3.94595" cy="2.94727" rx="1.94595" ry="2" fill="#F87171"/>
                    <ellipse cx="5.94595" cy="2.94727" rx="1.94595" ry="2" fill="#FEE2E2"/>
                  </svg>
                </span>
                <span class="alertTotalNumberCount">
                  ${(_j = (_h = this.similarAlertsMap) === null || _h === void 0 ? void 0 : _h.get(alert.suggestions[0].lang_category)) !== null && _j !== void 0 ? _j : 0}
                </span>
              <p>${this.getGroupedCardHeading(alert.suggestions[0].lang_category)}</p>
              `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
          </div>
          <div class="optionButtons" style="${!alert.suggestions[0].cta_present ? 'display: none' : ''}">
            <div class="optionButtonsLeftSide">
              ${alert.suggestions.length === 1 ? (0, lit_1.html) `<button class="btnAccepted" @click=${(event) => this.acceptAllAlert(event, alert.suggestions[0].lang_category)}>Accept all</button>` : ``}
                <button class="btnDismissed" @click=${(event) => this.rejectAllAlert(alert.suggestions[0].lang_category)}>Dismiss all</button>
                    <!--${alert.suggestions && alert.suggestions.length && this.hasSimilarCardsToIgnore(alert)
                ? (0, lit_1.html) `<div class="dictionaryCTA similarCardsCTA" @click=${() => this.ignoreAlertAll(alert.alertId)}>
                        <button>
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.37834 9.52529V20.3867C7.37834 21.0285 7.60548 21.6312 8.00148 22.0641C8.39577 22.4979 8.94519 22.7441 9.52034 22.745H17.562C18.1372 22.7441 18.6866 22.4979 19.0809 22.0641C19.4778 21.6312 19.704 21.0285 19.704 20.3867V9.52529C20.4918 9.3084 21.0026 8.51817 20.8972 7.67994C20.7918 6.84082 20.1026 6.21415 19.2875 6.21415H17.1112V5.66303C17.1138 5.19992 16.9372 4.75458 16.6209 4.42746C16.3046 4.10035 15.8752 3.91813 15.4286 3.92168H11.6538C11.2072 3.91813 10.7778 4.10035 10.4615 4.42746C10.1452 4.75458 9.96862 5.19992 9.97119 5.66303V6.21415H7.79491C6.97977 6.21415 6.29063 6.84171 6.1852 7.67994C6.07977 8.51906 6.58977 9.3084 7.37834 9.52529ZM17.2526 6.21415H9.82976H17.2526Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M4.12285 6.85879V17.7202C4.12285 18.362 4.34999 18.9647 4.74599 19.3976C5.14027 19.8314 5.6897 20.0776 6.26484 20.0785H14.3065C14.8817 20.0776 15.4311 19.8314 15.8254 19.3976C16.2223 18.9647 16.4485 18.362 16.4485 17.7202V6.85879C17.2363 6.6419 17.7471 5.85167 17.6417 5.01344C17.5363 4.17432 16.8471 3.54765 16.032 3.54765H13.8557V2.99653C13.8583 2.53341 13.6817 2.08808 13.3654 1.76096C13.0491 1.43385 12.6197 1.25162 12.1731 1.25518H8.39827C7.9517 1.25162 7.52227 1.43385 7.20598 1.76096C6.8897 2.08808 6.71313 2.53341 6.7157 2.99653V3.54765H4.53942C3.72427 3.54765 3.03513 4.17521 2.9297 5.01344C2.82428 5.85256 3.33428 6.6419 4.12285 6.85879ZM13.9971 3.54765H6.57427H13.9971Z" fill="white" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.01855 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.5528 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                        <span>Dismiss all the similar spelling alerts</span>
                      </div>` : ``} -->
                  ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].type == 2
                ? (0, lit_1.html) `
                    <div @click=${() => this.muteAllAlert(alert.suggestions[0].lang_category)} class="dictionaryCTA">
                      <button>
                        <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2959_25877)">
                          <path class="fillColored" d="M1.75 13.125H8.33301V14H1.75C1.50846 14 1.28288 13.9544 1.07324 13.8633C0.863607 13.7721 0.676758 13.6468 0.512695 13.4873C0.348633 13.3278 0.223307 13.1432 0.136719 12.9336C0.0501302 12.724 0.00455729 12.4961 0 12.25V1.75C0 1.52669 0.0478516 1.31022 0.143555 1.10059C0.239258 0.890951 0.371419 0.704102 0.540039 0.540039C0.708659 0.375977 0.895508 0.246094 1.10059 0.150391C1.30566 0.0546875 1.52214 0.00455729 1.75 0H11.375V8.33301H10.9375H10.5V0.875H1.75C1.64518 0.875 1.54036 0.900065 1.43555 0.950195C1.33073 1.00033 1.2373 1.06868 1.15527 1.15527C1.07324 1.24186 1.00716 1.33529 0.957031 1.43555C0.906901 1.53581 0.879557 1.64062 0.875 1.75V10.7461C1.00716 10.6686 1.14616 10.6094 1.29199 10.5684C1.43783 10.5273 1.59049 10.5046 1.75 10.5H8.33301V10.9375V11.375H1.75C1.62695 11.375 1.51302 11.3978 1.4082 11.4434C1.30339 11.4889 1.21224 11.5505 1.13477 11.6279C1.05729 11.7054 0.99349 11.7988 0.943359 11.9082C0.893229 12.0176 0.870443 12.1315 0.875 12.25C0.875 12.373 0.897786 12.487 0.943359 12.5918C0.988932 12.6966 1.05046 12.7878 1.12793 12.8652C1.2054 12.9427 1.29883 13.0065 1.4082 13.0566C1.51758 13.1068 1.63151 13.1296 1.75 13.125ZM8.75 5.25H2.625V2.625H8.75V5.25ZM3.5 3.5V4.375H7.875V3.5H3.5Z" fill="#647087"/>
                          <path class="strokeColred" d="M11.6615 9.33398V11.6035M11.6615 14.0007V11.6035M11.6615 11.6035H13.9948H9.32812" stroke="#647087" stroke-width="0.933333"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_2959_25877">
                          <rect width="14" height="14" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <span>Add all to dictionary</span>
                    </div>
                    ` : (0, lit_1.html) ``}
                  ${alert.suggestions && alert.suggestions.length && !alert.suggestions[0].cta_present
                ? (0, lit_1.html) `
                    <div style="display: none" @click=${() => this.acceptNonCTAAlert(alert.alertId)}>
                      <button>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2173_15330)">
                          <path class="fillColored" d="M1.75 13.125H8.33301V14H1.75C1.50846 14 1.28288 13.9544 1.07324 13.8633C0.863607 13.7721 0.676758 13.6468 0.512695 13.4873C0.348633 13.3278 0.223307 13.1432 0.136719 12.9336C0.0501302 12.724 0.00455729 12.4961 0 12.25V1.75C0 1.52669 0.0478516 1.31022 0.143555 1.10059C0.239258 0.890951 0.371419 0.704102 0.540039 0.540039C0.708659 0.375977 0.895508 0.246094 1.10059 0.150391C1.30566 0.0546875 1.52214 0.00455729 1.75 0H11.375V8.33301H10.9375H10.5V0.875H1.75C1.64518 0.875 1.54036 0.900065 1.43555 0.950195C1.33073 1.00033 1.2373 1.06868 1.15527 1.15527C1.07324 1.24186 1.00716 1.33529 0.957031 1.43555C0.906901 1.53581 0.879557 1.64062 0.875 1.75V10.7461C1.00716 10.6686 1.14616 10.6094 1.29199 10.5684C1.43783 10.5273 1.59049 10.5046 1.75 10.5H8.33301V10.9375V11.375H1.75C1.62695 11.375 1.51302 11.3978 1.4082 11.4434C1.30339 11.4889 1.21224 11.5505 1.13477 11.6279C1.05729 11.7054 0.99349 11.7988 0.943359 11.9082C0.893229 12.0176 0.870443 12.1315 0.875 12.25C0.875 12.373 0.897786 12.487 0.943359 12.5918C0.988932 12.6966 1.05046 12.7878 1.12793 12.8652C1.2054 12.9427 1.29883 13.0065 1.4082 13.0566C1.51758 13.1068 1.63151 13.1296 1.75 13.125ZM8.75 5.25H2.625V2.625H8.75V5.25ZM3.5 3.5V4.375H7.875V3.5H3.5Z" fill="rgba(77, 77, 77, 0.8)"/>
                          <path class="strokeColred" d="M11.6654 9.33398V11.6035M11.6654 14.0007V11.6035M11.6654 11.6035H13.9987H9.33203" stroke="rgba(77, 77, 77, 0.8)" stroke-width="0.933333"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_2173_15330">
                          <rect width="14" height="14" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <span>This was helpful</span>
                    </div>
                    ` : (0, lit_1.html) ``}
              <!--  ${alert.suggestions.length > 1 ? (0, lit_1.html) `<div class="multipleCardArrow">
                  <a class="${this.currentIndexOfSuggestion === 0 ? 'disablePrev' : ''}" @click=${() => this.changeCurrentSuggestion(-1, index)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.3541 12.6465C10.4006 12.693 10.4374 12.7481 10.4626 12.8088C10.4877 12.8695 10.5007 12.9346 10.5007 13.0003C10.5007 13.066 10.4877 13.131 10.4626 13.1917C10.4374 13.2524 10.4006 13.3076 10.3541 13.354C10.3077 13.4005 10.2525 13.4373 10.1918 13.4625C10.1311 13.4876 10.0661 13.5006 10.0004 13.5006C9.9347 13.5006 9.86964 13.4876 9.80895 13.4625C9.74825 13.4373 9.6931 13.4005 9.64664 13.354L4.64664 8.35403C4.60015 8.30759 4.56328 8.25245 4.53811 8.19175C4.51295 8.13105 4.5 8.06599 4.5 8.00028C4.5 7.93457 4.51295 7.86951 4.53811 7.80881C4.56328 7.74811 4.60015 7.69296 4.64664 7.64653L9.64664 2.64653C9.74046 2.55271 9.86771 2.5 10.0004 2.5C10.1331 2.5 10.2603 2.55271 10.3541 2.64653C10.448 2.74035 10.5007 2.8676 10.5007 3.00028C10.5007 3.13296 10.448 3.26021 10.3541 3.35403L5.70727 8.00028L10.3541 12.6465Z" fill="rgba(77, 77, 77, 0.8)"/>
                    </svg>
                  </a>
                  <span>${this.currentIndexOfSuggestion + 1} of ${alert.suggestions.length}</span>
                  <a class="${this.currentIndexOfSuggestion >= alert.suggestions.length - 1 ? 'disableNext' : ''}" @click=${() => this.changeCurrentSuggestion(1, index)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.64586 3.35347C5.5994 3.30702 5.56255 3.25187 5.53741 3.19117C5.51227 3.13047 5.49933 3.06542 5.49933 2.99972C5.49933 2.93402 5.51227 2.86897 5.53741 2.80827C5.56255 2.74758 5.5994 2.69243 5.64586 2.64597C5.69231 2.59952 5.74746 2.56267 5.80816 2.53753C5.86885 2.51238 5.93391 2.49944 5.99961 2.49944C6.0653 2.49944 6.13036 2.51238 6.19105 2.53753C6.25175 2.56267 6.3069 2.59952 6.35336 2.64597L11.3534 7.64597C11.3998 7.69241 11.4367 7.74755 11.4619 7.80825C11.487 7.86895 11.5 7.93401 11.5 7.99972C11.5 8.06543 11.487 8.13049 11.4619 8.19119C11.4367 8.25189 11.3998 8.30704 11.3534 8.35347L6.35336 13.3535C6.25954 13.4473 6.13229 13.5 5.99961 13.5C5.86692 13.5 5.73968 13.4473 5.64586 13.3535C5.55204 13.2597 5.49933 13.1324 5.49933 12.9997C5.49933 12.867 5.55204 12.7398 5.64586 12.646L10.2927 7.99972L5.64586 3.35347Z" fill="rgba(77, 77, 77, 0.8)"/>
                    </svg>
                  </a>
                </div>` : ``}-->
            </div>
            <div class="optionButtonsRightSide">
              <a>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3279 10.8115C17.309 10.3888 17.1696 9.98258 16.9286 9.64829C17.0747 9.30079 17.1148 8.91234 17.043 8.53877C16.9713 8.1652 16.7914 7.82573 16.5293 7.56917C16.0341 7.07895 15.1836 6.85964 14.0097 6.92414C13.4899 6.94962 12.9731 7.02154 12.4644 7.13915V7.13915C12.3047 7.16925 12.137 7.2058 11.9673 7.24665C11.9553 7.03164 11.9892 6.49197 12.3666 5.28148C12.8058 3.84307 12.7778 2.74008 12.2847 2.0026C12.0963 1.74031 11.854 1.52853 11.5769 1.3838C11.2998 1.23908 10.9954 1.16535 10.6875 1.16837C10.5735 1.16819 10.4608 1.19485 10.3573 1.24647C10.2539 1.29809 10.1621 1.37344 10.0885 1.46723C9.74315 1.89725 9.78508 2.70783 9.82501 3.07764C9.38977 4.27094 8.23579 7.2058 7.23754 8.02068C7.21952 8.03466 7.2028 8.05051 7.18763 8.06798C6.9325 8.35972 6.72464 8.6955 6.57271 9.06132C6.38183 8.94866 6.16746 8.89021 5.9498 8.89147H4.04314C3.697 8.89203 3.3652 9.04036 3.12044 9.30395C2.87569 9.56753 2.73795 9.92486 2.73742 10.2976V15.7911C2.73716 15.976 2.77073 16.1592 2.8362 16.3302C2.90168 16.5011 2.99778 16.6566 3.11903 16.7875C3.24028 16.9185 3.38429 17.0225 3.54285 17.0935C3.70141 17.1645 3.87141 17.2012 4.04314 17.2015H5.95579C6.22614 17.2021 6.48995 17.112 6.71047 16.9435L7.43719 17.0338C8.82804 17.2172 10.2287 17.2998 11.6299 17.2811C12.4671 17.3754 13.3124 17.3435 14.1415 17.1865C15.1018 16.9715 15.7566 16.5286 16.092 15.8792C16.2924 15.486 16.3675 15.0328 16.3057 14.5892C16.5432 14.3757 16.7314 14.1057 16.8554 13.8005C16.9795 13.4953 17.036 13.1632 17.0204 12.8304C17.0072 12.599 16.9629 12.3708 16.8886 12.1531C17.1794 11.777 17.3353 11.3009 17.3279 10.8115V10.8115Z" stroke="rgba(77, 77, 77, 0.8)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.625 16.8848V8.99182" stroke="#647087" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Like</span>
              </a>
              <a>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.67213 9.18851C2.69098 9.61124 2.83042 10.0174 3.07143 10.3517C2.9253 10.6992 2.88523 11.0877 2.95697 11.4612C3.02872 11.8348 3.20859 12.1743 3.47073 12.4308C3.96586 12.9211 4.81637 13.1404 5.99032 13.0759C6.51005 13.0504 7.02693 12.9785 7.53561 12.8609V12.8609C7.69533 12.8308 7.86304 12.7942 8.03274 12.7533C8.04472 12.9684 8.01078 13.508 7.63344 14.7185C7.19421 16.1569 7.22216 17.2599 7.7153 17.9974C7.90375 18.2597 8.14599 18.4715 8.42307 18.6162C8.70015 18.7609 9.00456 18.8346 9.3125 18.8316C9.42652 18.8318 9.53921 18.8052 9.64267 18.7535C9.74612 18.7019 9.83786 18.6266 9.91145 18.5328C10.2568 18.1028 10.2149 17.2922 10.175 16.9224C10.6102 15.7291 11.7642 12.7942 12.7625 11.9793C12.7805 11.9653 12.7972 11.9495 12.8124 11.932C13.0675 11.6403 13.2754 11.3045 13.4273 10.9387C13.6182 11.0513 13.8325 11.1098 14.0502 11.1085H15.9569C16.303 11.108 16.6348 10.9596 16.8796 10.6961C17.1243 10.4325 17.262 10.0751 17.2626 9.70238V4.20892C17.2628 4.02398 17.2293 3.8408 17.1638 3.66982C17.0983 3.49885 17.0022 3.34344 16.881 3.21247C16.7597 3.0815 16.6157 2.97753 16.4571 2.90649C16.2986 2.83546 16.1286 2.79875 15.9569 2.79847H14.0442C13.7739 2.79785 13.5101 2.88804 13.2895 3.05648L12.5628 2.96617C11.172 2.78284 9.77126 2.70023 8.37015 2.71892C7.53293 2.62464 6.6876 2.65649 5.85855 2.81352C4.89823 3.02853 4.24338 3.47144 3.90796 4.12077C3.70755 4.51401 3.6325 4.96721 3.69434 5.41082C3.45681 5.62429 3.2686 5.8943 3.14456 6.19952C3.02052 6.50474 2.96405 6.83683 2.97959 7.16959C2.99276 7.40103 3.03714 7.62919 3.11136 7.84686C2.82055 8.22304 2.6647 8.69909 2.67213 9.18851V9.18851Z" stroke="rgba(77, 77, 77, 0.8)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.375 3.11523V11.0082" stroke="#647087" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span style="left: 22px;">Dislike</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      `;
        })}
    
    ${(_c = this.filteredAlertList) === null || _c === void 0 ? void 0 : _c.filter(x => !x.isGrouped).map((alert, index) => {
            var _a, _b, _c;
            return (0, lit_1.html) `<div id="${alert.alertId}" class="alert-card card_wrapper card_wrapper-item-${index} ${alert.disabled ? 'alert-disabled' : ''}">
        ${alert.suggestions && alert.suggestions.length && alert.suggestions.length > 1 ? (0, lit_1.html) `
          <div class="card_h" @click=${() => this.toggleCardState(alert.alertId, `card_wrapper-item-${index}`)}>
            <button class="card_O_C">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd"
                d="M10.3162 0.375L6.12075 4.68698L1.92531 0.375L0.648438 1.68734L6.12075 7.31166L11.5931 1.68734L10.3162 0.375Z"
                fill="#4D4D4D"></path>
              </svg>
            </button>
            <div class="card_cat">
              ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
                <p>${this.getAlertCategory(alert.suggestions[0])}</p>
                `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
            </div>
            <div class="card_h_l" data-no-translate>
              <span class="identify_dot"></span>
              <p style="text-decoration: ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present ? 'line-through; text-decoration-color: #FF5C5C;' : 'none'};">${alert.text}</p>
            </div>
          </div>
          ${(_a = alert.suggestions) === null || _a === void 0 ? void 0 : _a.map((suggestion, i) => (0, lit_1.html) `
            <div data-no-translate class="card_h" @click=${() => this.toggleCardState(alert.alertId, `card_wrapper-item-${index}`)} style="${i === 0 ? 'padding-bottom: 0px;' : 'padding-top: 0px; padding-bottom: 0px;'}">
              <div class="card_h_r">
                ${suggestion.cta_present
                ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                  fill="#4D4D4D" fill-opacity="0.38"></path>
                </svg>` : (0, lit_1.html) ``}
                <p @click=${(event) => this.acceptAlert(alert, event, i)}>
                  ${suggestion.cta_present
                ? (0, lit_1.html) `
                    <div>${suggestion.suggestion === '' ? alert.text : suggestion.suggestion}</div>
                    `
                : (0, lit_1.html) ``}
                </p>
              </div>
            </div>
          `)}
        ` : (0, lit_1.html) `
          <div class="card_h" @click=${() => this.toggleCardState(alert.alertId, `card_wrapper-item-${index}`)}>
            <button class="card_O_C">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd"
                d="M10.3162 0.375L6.12075 4.68698L1.92531 0.375L0.648438 1.68734L6.12075 7.31166L11.5931 1.68734L10.3162 0.375Z"
                fill="#4D4D4D"></path>
              </svg>
            </button>
            <div class="card_cat">
              ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
                <p>${this.getAlertCategory(alert.suggestions[0])}</p>
                `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
            </div>
            <div class="card_h_l" data-no-translate>
              <span class="identify_dot"></span>
              <p style="text-decoration: ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present ? 'line-through; text-decoration-color: #FF5C5C;' : 'none'};">${alert.text}</p>
            </div>
            <div class="card_h_r" data-no-translate>
              ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present
                ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                fill="#4D4D4D" fill-opacity="0.38"></path>
              </svg>` : (0, lit_1.html) ``}
              <p @click=${(event) => this.acceptAlert(alert, event, 0)}>
                ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present
                ? (0, lit_1.html) `
                  <div>${alert.suggestions[0].suggestion === '' ? alert.text : alert.suggestions[0].suggestion}</div>
                  `
                : (0, lit_1.html) ``}
              </p>
            </div>
          </div>
        `}
        <div class="card_b">
          <div class="cardBodyAlerts" data-no-translate>
              <span>${(0, unsafe_html_js_1.unsafeHTML)(this.getAlertHTML(alert))}</span>
              ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].cta_present
                ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                  fill="#4D4D4D" fill-opacity="0.38"></path>
                </svg>` : (0, lit_1.html) ``}
                <p @click=${(event) => this.acceptAlert(alert, event, this.currentIndexOfSuggestion)} class="rightTextSection ${alert.suggestions[0].suggestion.length > 0 ? '' : 'hideRightTextSection'}">
                  ${alert.suggestions && alert.suggestions.length && ((_b = alert.suggestions[this.currentIndexOfSuggestion]) === null || _b === void 0 ? void 0 : _b.cta_present) && alert.suggestions.length === 1
                ? (0, lit_1.html) `
                    ${alert.suggestions[this.currentIndexOfSuggestion].suggestion === '' ? alert.text : alert.suggestions[this.currentIndexOfSuggestion].suggestion}
                    `
                : (0, lit_1.html) ``}
                </p>
              ${alert.suggestions && alert.suggestions.length && alert.suggestions.length <= 1 ? (0, lit_1.html) `<span>${this.extractSurroundingWord(alert.sentenceText, alert.begin, alert.end, false)}...</span>` : ``}
              ${alert.suggestions && alert.suggestions.length && alert.suggestions.length > 1 ? (0, lit_1.html) `
              ${(_c = alert.suggestions) === null || _c === void 0 ? void 0 : _c.map((suggestion, i) => (0, lit_1.html) `

              <p @click=${(event) => this.acceptAlert(alert, event, i)} class="rightTextSection multiSuggestionsListOption">
                <span>
                ${suggestion.cta_present
                ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                  fill="#4D4D4D" fill-opacity="0.38"></path>
                </svg>` : (0, lit_1.html) ``}
                </span>
                ${suggestion.cta_present
                ? (0, lit_1.html) `
                    <div style="width: 210px">${suggestion.suggestion === '' ? alert.text : suggestion.suggestion}</div>
                    `
                : (0, lit_1.html) ``}
                  <button class="multiAlertAcceptButton">Accept</button>
                </p>
              `)}
              ` : ``}
          </div>
          <div class="card_des_sec" data-no-translate>
            <div style="width: 100%;">
              ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].comment
                ? (0, lit_1.html) `
                <p>${alert.suggestions[0].comment}</p>
                `
                : (0, lit_1.html) ``}
            </div>
            <div class="card_option_b">
              <ul>
                <li style="cursor: pointer" @click=${() => this.rejectAlert(alert.alertId)}>
                  <svg fill="none" height="16" viewBox="0 0 20 20" width="16" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M1.3 8.26669C1.32248 8.73481 1.48877 9.18461 1.77619 9.55479C1.60192 9.93961 1.55414 10.3698 1.6397 10.7835C1.72526 11.1971 1.93976 11.5731 2.25238 11.8572C2.84286 12.4 3.85714 12.6429 5.25714 12.5715C5.87696 12.5432 6.49336 12.4636 7.1 12.3334V12.3334C7.29048 12.3 7.49048 12.2596 7.69286 12.2143C7.70714 12.4524 7.66667 13.05 7.21667 14.3905C6.69286 15.9834 6.72619 17.2048 7.31428 18.0215C7.53903 18.3119 7.82791 18.5464 8.15835 18.7067C8.48878 18.867 8.85181 18.9486 9.21905 18.9453C9.35503 18.9455 9.48941 18.916 9.61279 18.8588C9.73617 18.8016 9.84557 18.7182 9.93333 18.6143C10.3452 18.1381 10.2952 17.2405 10.2476 16.831C10.7667 15.5096 12.1429 12.2596 13.3333 11.3572C13.3548 11.3417 13.3748 11.3241 13.3929 11.3048C13.6971 10.9817 13.945 10.6099 14.1262 10.2048C14.3538 10.3295 14.6095 10.3943 14.869 10.3929H17.1429C17.5556 10.3923 17.9513 10.228 18.2432 9.93611C18.5351 9.64423 18.6994 9.24853 18.7 8.83574V2.7524C18.7003 2.5476 18.6603 2.34475 18.5822 2.15542C18.5041 1.96609 18.3895 1.79399 18.2449 1.64896C18.1003 1.50392 17.9286 1.38878 17.7395 1.31012C17.5504 1.23146 17.3477 1.19081 17.1429 1.1905H14.8619C14.5395 1.18981 14.2249 1.28969 13.9619 1.47621L13.0952 1.37621C11.4366 1.17319 9.76615 1.08171 8.09524 1.1024C7.09681 0.99801 6.0887 1.03327 5.1 1.20717C3.95476 1.44526 3.17381 1.93574 2.77381 2.65478C2.53481 3.09025 2.44531 3.59212 2.51905 4.08336C2.23578 4.31976 2.01133 4.61875 1.86341 4.95675C1.71548 5.29474 1.64813 5.66249 1.66667 6.03098C1.68237 6.28728 1.7353 6.53993 1.82381 6.78098C1.477 7.19755 1.29114 7.72472 1.3 8.26669V8.26669Z"
                    stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"></path>
                    <path d="M14 1.5V10.2405" stroke="#7A7A7A" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.3"></path>
                  </svg>
                </li>
                ${this.hasSimilarCardsToIgnore(alert)
                ? (0, lit_1.html) `
                    <li style="cursor: pointer" @click=${() => this.ignoreAlertAll(alert.alertId)}>
                      <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.37834 9.52529V20.3867C7.37834 21.0285 7.60548 21.6312 8.00148 22.0641C8.39577 22.4979 8.94519 22.7441 9.52034 22.745H17.562C18.1372 22.7441 18.6866 22.4979 19.0809 22.0641C19.4778 21.6312 19.704 21.0285 19.704 20.3867V9.52529C20.4918 9.3084 21.0026 8.51817 20.8972 7.67994C20.7918 6.84082 20.1026 6.21415 19.2875 6.21415H17.1112V5.66303C17.1138 5.19992 16.9372 4.75458 16.6209 4.42746C16.3046 4.10035 15.8752 3.91813 15.4286 3.92168H11.6538C11.2072 3.91813 10.7778 4.10035 10.4615 4.42746C10.1452 4.75458 9.96862 5.19992 9.97119 5.66303V6.21415H7.79491C6.97977 6.21415 6.29063 6.84171 6.1852 7.67994C6.07977 8.51906 6.58977 9.3084 7.37834 9.52529ZM17.2526 6.21415H9.82976H17.2526Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4.12285 6.85879V17.7202C4.12285 18.362 4.34999 18.9647 4.74599 19.3976C5.14027 19.8314 5.6897 20.0776 6.26484 20.0785H14.3065C14.8817 20.0776 15.4311 19.8314 15.8254 19.3976C16.2223 18.9647 16.4485 18.362 16.4485 17.7202V6.85879C17.2363 6.6419 17.7471 5.85167 17.6417 5.01344C17.5363 4.17432 16.8471 3.54765 16.032 3.54765H13.8557V2.99653C13.8583 2.53341 13.6817 2.08808 13.3654 1.76096C13.0491 1.43385 12.6197 1.25162 12.1731 1.25518H8.39827C7.9517 1.25162 7.52227 1.43385 7.20598 1.76096C6.8897 2.08808 6.71313 2.53341 6.7157 2.99653V3.54765H4.53942C3.72427 3.54765 3.03513 4.17521 2.9297 5.01344C2.82428 5.85256 3.33428 6.6419 4.12285 6.85879ZM13.9971 3.54765H6.57427H13.9971Z" fill="white" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.01855 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.5528 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </li>
                  ` : (0, lit_1.html) ``}
              </ul>
            </div>
          </div>
          ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].type == 2
                ? (0, lit_1.html) `
            <div @click=${() => this.muteAlert(alert.alertId)}>
              <button class="addToDictionaryBtn" style="display: none;">Add to My Dictionary</button>
            </div>
            ` : (0, lit_1.html) ``}
          ${alert.suggestions && alert.suggestions.length && !alert.suggestions[0].cta_present
                ? (0, lit_1.html) `
            <div class="wasHelpfulBtnWrapper">
              <button class="addToDictionaryBtn wasHelpFulBtn" @click=${() => this.acceptNonCTAAlert(alert.alertId)}>This was helpful</button>
              <button class="btnDismissed" @click=${(event) => this.rejectAlert(alert.alertId)}>Dismiss</button>
            </div>
            ` : (0, lit_1.html) ``}
          <div class="card_cat">
            ${alert.suggestions && alert.suggestions.length
                ? (0, lit_1.html) `
              <p>${this.getAlertCategory(alert.suggestions[0])}</p>
              `
                : (0, lit_1.html) `<p>UNKNOWN</p>`}
          </div>
          <div class="optionButtons" style="${!alert.suggestions[0].cta_present ? 'display: none' : ''}">
            <div class="optionButtonsLeftSide">
              ${alert.suggestions.length === 1 ? (0, lit_1.html) `<button class="btnAccepted" @click=${(event) => this.acceptAlert(alert, event, this.currentIndexOfSuggestion)}>Accept</button>` : ``}
                <button class="btnDismissed" @click=${(event) => this.rejectAlert(alert.alertId)}>${alert.suggestions.length === 1 ? 'Dismiss' : 'Dismiss All'}</button>
                    ${alert.suggestions && alert.suggestions.length && this.hasSimilarCardsToIgnore(alert)
                ? (0, lit_1.html) `<div class="dictionaryCTA similarCardsCTA" @click=${() => this.ignoreAlertAll(alert.alertId)}>
                        <button>
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.37834 9.52529V20.3867C7.37834 21.0285 7.60548 21.6312 8.00148 22.0641C8.39577 22.4979 8.94519 22.7441 9.52034 22.745H17.562C18.1372 22.7441 18.6866 22.4979 19.0809 22.0641C19.4778 21.6312 19.704 21.0285 19.704 20.3867V9.52529C20.4918 9.3084 21.0026 8.51817 20.8972 7.67994C20.7918 6.84082 20.1026 6.21415 19.2875 6.21415H17.1112V5.66303C17.1138 5.19992 16.9372 4.75458 16.6209 4.42746C16.3046 4.10035 15.8752 3.91813 15.4286 3.92168H11.6538C11.2072 3.91813 10.7778 4.10035 10.4615 4.42746C10.1452 4.75458 9.96862 5.19992 9.97119 5.66303V6.21415H7.79491C6.97977 6.21415 6.29063 6.84171 6.1852 7.67994C6.07977 8.51906 6.58977 9.3084 7.37834 9.52529ZM17.2526 6.21415H9.82976H17.2526Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M4.12285 6.85879V17.7202C4.12285 18.362 4.34999 18.9647 4.74599 19.3976C5.14027 19.8314 5.6897 20.0776 6.26484 20.0785H14.3065C14.8817 20.0776 15.4311 19.8314 15.8254 19.3976C16.2223 18.9647 16.4485 18.362 16.4485 17.7202V6.85879C17.2363 6.6419 17.7471 5.85167 17.6417 5.01344C17.5363 4.17432 16.8471 3.54765 16.032 3.54765H13.8557V2.99653C13.8583 2.53341 13.6817 2.08808 13.3654 1.76096C13.0491 1.43385 12.6197 1.25162 12.1731 1.25518H8.39827C7.9517 1.25162 7.52227 1.43385 7.20598 1.76096C6.8897 2.08808 6.71313 2.53341 6.7157 2.99653V3.54765H4.53942C3.72427 3.54765 3.03513 4.17521 2.9297 5.01344C2.82428 5.85256 3.33428 6.6419 4.12285 6.85879ZM13.9971 3.54765H6.57427H13.9971Z" fill="white" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M8.01855 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.5528 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        </button>
                        <span>Dismiss all the similar spelling alerts</span>
                      </div>` : ``}
                  ${alert.suggestions && alert.suggestions.length && alert.suggestions[0].type == 2
                ? (0, lit_1.html) `
                    <div @click=${() => this.muteAlert(alert.alertId)} class="dictionaryCTA">
                      <button>
                        <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2959_25877)">
                          <path class="fillColored" d="M1.75 13.125H8.33301V14H1.75C1.50846 14 1.28288 13.9544 1.07324 13.8633C0.863607 13.7721 0.676758 13.6468 0.512695 13.4873C0.348633 13.3278 0.223307 13.1432 0.136719 12.9336C0.0501302 12.724 0.00455729 12.4961 0 12.25V1.75C0 1.52669 0.0478516 1.31022 0.143555 1.10059C0.239258 0.890951 0.371419 0.704102 0.540039 0.540039C0.708659 0.375977 0.895508 0.246094 1.10059 0.150391C1.30566 0.0546875 1.52214 0.00455729 1.75 0H11.375V8.33301H10.9375H10.5V0.875H1.75C1.64518 0.875 1.54036 0.900065 1.43555 0.950195C1.33073 1.00033 1.2373 1.06868 1.15527 1.15527C1.07324 1.24186 1.00716 1.33529 0.957031 1.43555C0.906901 1.53581 0.879557 1.64062 0.875 1.75V10.7461C1.00716 10.6686 1.14616 10.6094 1.29199 10.5684C1.43783 10.5273 1.59049 10.5046 1.75 10.5H8.33301V10.9375V11.375H1.75C1.62695 11.375 1.51302 11.3978 1.4082 11.4434C1.30339 11.4889 1.21224 11.5505 1.13477 11.6279C1.05729 11.7054 0.99349 11.7988 0.943359 11.9082C0.893229 12.0176 0.870443 12.1315 0.875 12.25C0.875 12.373 0.897786 12.487 0.943359 12.5918C0.988932 12.6966 1.05046 12.7878 1.12793 12.8652C1.2054 12.9427 1.29883 13.0065 1.4082 13.0566C1.51758 13.1068 1.63151 13.1296 1.75 13.125ZM8.75 5.25H2.625V2.625H8.75V5.25ZM3.5 3.5V4.375H7.875V3.5H3.5Z" fill="#647087"/>
                          <path class="strokeColred" d="M11.6615 9.33398V11.6035M11.6615 14.0007V11.6035M11.6615 11.6035H13.9948H9.32812" stroke="#647087" stroke-width="0.933333"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_2959_25877">
                          <rect width="14" height="14" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <span>Add to my dictionary</span>
                    </div>
                    ` : (0, lit_1.html) ``}
                  ${alert.suggestions && alert.suggestions.length && !alert.suggestions[0].cta_present
                ? (0, lit_1.html) `
                    <div style="display: none" @click=${() => this.acceptNonCTAAlert(alert.alertId)}>
                      <button>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_2173_15330)">
                          <path class="fillColored" d="M1.75 13.125H8.33301V14H1.75C1.50846 14 1.28288 13.9544 1.07324 13.8633C0.863607 13.7721 0.676758 13.6468 0.512695 13.4873C0.348633 13.3278 0.223307 13.1432 0.136719 12.9336C0.0501302 12.724 0.00455729 12.4961 0 12.25V1.75C0 1.52669 0.0478516 1.31022 0.143555 1.10059C0.239258 0.890951 0.371419 0.704102 0.540039 0.540039C0.708659 0.375977 0.895508 0.246094 1.10059 0.150391C1.30566 0.0546875 1.52214 0.00455729 1.75 0H11.375V8.33301H10.9375H10.5V0.875H1.75C1.64518 0.875 1.54036 0.900065 1.43555 0.950195C1.33073 1.00033 1.2373 1.06868 1.15527 1.15527C1.07324 1.24186 1.00716 1.33529 0.957031 1.43555C0.906901 1.53581 0.879557 1.64062 0.875 1.75V10.7461C1.00716 10.6686 1.14616 10.6094 1.29199 10.5684C1.43783 10.5273 1.59049 10.5046 1.75 10.5H8.33301V10.9375V11.375H1.75C1.62695 11.375 1.51302 11.3978 1.4082 11.4434C1.30339 11.4889 1.21224 11.5505 1.13477 11.6279C1.05729 11.7054 0.99349 11.7988 0.943359 11.9082C0.893229 12.0176 0.870443 12.1315 0.875 12.25C0.875 12.373 0.897786 12.487 0.943359 12.5918C0.988932 12.6966 1.05046 12.7878 1.12793 12.8652C1.2054 12.9427 1.29883 13.0065 1.4082 13.0566C1.51758 13.1068 1.63151 13.1296 1.75 13.125ZM8.75 5.25H2.625V2.625H8.75V5.25ZM3.5 3.5V4.375H7.875V3.5H3.5Z" fill="rgba(77, 77, 77, 0.8)"/>
                          <path class="strokeColred" d="M11.6654 9.33398V11.6035M11.6654 14.0007V11.6035M11.6654 11.6035H13.9987H9.33203" stroke="rgba(77, 77, 77, 0.8)" stroke-width="0.933333"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_2173_15330">
                          <rect width="14" height="14" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </button>
                      <span>This was helpful</span>
                    </div>
                    ` : (0, lit_1.html) ``}
              <!--  ${alert.suggestions.length > 1 ? (0, lit_1.html) `<div class="multipleCardArrow">
                  <a class="${this.currentIndexOfSuggestion === 0 ? 'disablePrev' : ''}" @click=${() => this.changeCurrentSuggestion(-1, index)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.3541 12.6465C10.4006 12.693 10.4374 12.7481 10.4626 12.8088C10.4877 12.8695 10.5007 12.9346 10.5007 13.0003C10.5007 13.066 10.4877 13.131 10.4626 13.1917C10.4374 13.2524 10.4006 13.3076 10.3541 13.354C10.3077 13.4005 10.2525 13.4373 10.1918 13.4625C10.1311 13.4876 10.0661 13.5006 10.0004 13.5006C9.9347 13.5006 9.86964 13.4876 9.80895 13.4625C9.74825 13.4373 9.6931 13.4005 9.64664 13.354L4.64664 8.35403C4.60015 8.30759 4.56328 8.25245 4.53811 8.19175C4.51295 8.13105 4.5 8.06599 4.5 8.00028C4.5 7.93457 4.51295 7.86951 4.53811 7.80881C4.56328 7.74811 4.60015 7.69296 4.64664 7.64653L9.64664 2.64653C9.74046 2.55271 9.86771 2.5 10.0004 2.5C10.1331 2.5 10.2603 2.55271 10.3541 2.64653C10.448 2.74035 10.5007 2.8676 10.5007 3.00028C10.5007 3.13296 10.448 3.26021 10.3541 3.35403L5.70727 8.00028L10.3541 12.6465Z" fill="rgba(77, 77, 77, 0.8)"/>
                    </svg>
                  </a>
                  <span>${this.currentIndexOfSuggestion + 1} of ${alert.suggestions.length}</span>
                  <a class="${this.currentIndexOfSuggestion >= alert.suggestions.length - 1 ? 'disableNext' : ''}" @click=${() => this.changeCurrentSuggestion(1, index)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.64586 3.35347C5.5994 3.30702 5.56255 3.25187 5.53741 3.19117C5.51227 3.13047 5.49933 3.06542 5.49933 2.99972C5.49933 2.93402 5.51227 2.86897 5.53741 2.80827C5.56255 2.74758 5.5994 2.69243 5.64586 2.64597C5.69231 2.59952 5.74746 2.56267 5.80816 2.53753C5.86885 2.51238 5.93391 2.49944 5.99961 2.49944C6.0653 2.49944 6.13036 2.51238 6.19105 2.53753C6.25175 2.56267 6.3069 2.59952 6.35336 2.64597L11.3534 7.64597C11.3998 7.69241 11.4367 7.74755 11.4619 7.80825C11.487 7.86895 11.5 7.93401 11.5 7.99972C11.5 8.06543 11.487 8.13049 11.4619 8.19119C11.4367 8.25189 11.3998 8.30704 11.3534 8.35347L6.35336 13.3535C6.25954 13.4473 6.13229 13.5 5.99961 13.5C5.86692 13.5 5.73968 13.4473 5.64586 13.3535C5.55204 13.2597 5.49933 13.1324 5.49933 12.9997C5.49933 12.867 5.55204 12.7398 5.64586 12.646L10.2927 7.99972L5.64586 3.35347Z" fill="rgba(77, 77, 77, 0.8)"/>
                    </svg>
                  </a>
                </div>` : ``}-->
            </div>
            <div class="optionButtonsRightSide">
              <a>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3279 10.8115C17.309 10.3888 17.1696 9.98258 16.9286 9.64829C17.0747 9.30079 17.1148 8.91234 17.043 8.53877C16.9713 8.1652 16.7914 7.82573 16.5293 7.56917C16.0341 7.07895 15.1836 6.85964 14.0097 6.92414C13.4899 6.94962 12.9731 7.02154 12.4644 7.13915V7.13915C12.3047 7.16925 12.137 7.2058 11.9673 7.24665C11.9553 7.03164 11.9892 6.49197 12.3666 5.28148C12.8058 3.84307 12.7778 2.74008 12.2847 2.0026C12.0963 1.74031 11.854 1.52853 11.5769 1.3838C11.2998 1.23908 10.9954 1.16535 10.6875 1.16837C10.5735 1.16819 10.4608 1.19485 10.3573 1.24647C10.2539 1.29809 10.1621 1.37344 10.0885 1.46723C9.74315 1.89725 9.78508 2.70783 9.82501 3.07764C9.38977 4.27094 8.23579 7.2058 7.23754 8.02068C7.21952 8.03466 7.2028 8.05051 7.18763 8.06798C6.9325 8.35972 6.72464 8.6955 6.57271 9.06132C6.38183 8.94866 6.16746 8.89021 5.9498 8.89147H4.04314C3.697 8.89203 3.3652 9.04036 3.12044 9.30395C2.87569 9.56753 2.73795 9.92486 2.73742 10.2976V15.7911C2.73716 15.976 2.77073 16.1592 2.8362 16.3302C2.90168 16.5011 2.99778 16.6566 3.11903 16.7875C3.24028 16.9185 3.38429 17.0225 3.54285 17.0935C3.70141 17.1645 3.87141 17.2012 4.04314 17.2015H5.95579C6.22614 17.2021 6.48995 17.112 6.71047 16.9435L7.43719 17.0338C8.82804 17.2172 10.2287 17.2998 11.6299 17.2811C12.4671 17.3754 13.3124 17.3435 14.1415 17.1865C15.1018 16.9715 15.7566 16.5286 16.092 15.8792C16.2924 15.486 16.3675 15.0328 16.3057 14.5892C16.5432 14.3757 16.7314 14.1057 16.8554 13.8005C16.9795 13.4953 17.036 13.1632 17.0204 12.8304C17.0072 12.599 16.9629 12.3708 16.8886 12.1531C17.1794 11.777 17.3353 11.3009 17.3279 10.8115V10.8115Z" stroke="rgba(77, 77, 77, 0.8)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.625 16.8848V8.99182" stroke="#647087" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Like</span>
              </a>
              <a>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.67213 9.18851C2.69098 9.61124 2.83042 10.0174 3.07143 10.3517C2.9253 10.6992 2.88523 11.0877 2.95697 11.4612C3.02872 11.8348 3.20859 12.1743 3.47073 12.4308C3.96586 12.9211 4.81637 13.1404 5.99032 13.0759C6.51005 13.0504 7.02693 12.9785 7.53561 12.8609V12.8609C7.69533 12.8308 7.86304 12.7942 8.03274 12.7533C8.04472 12.9684 8.01078 13.508 7.63344 14.7185C7.19421 16.1569 7.22216 17.2599 7.7153 17.9974C7.90375 18.2597 8.14599 18.4715 8.42307 18.6162C8.70015 18.7609 9.00456 18.8346 9.3125 18.8316C9.42652 18.8318 9.53921 18.8052 9.64267 18.7535C9.74612 18.7019 9.83786 18.6266 9.91145 18.5328C10.2568 18.1028 10.2149 17.2922 10.175 16.9224C10.6102 15.7291 11.7642 12.7942 12.7625 11.9793C12.7805 11.9653 12.7972 11.9495 12.8124 11.932C13.0675 11.6403 13.2754 11.3045 13.4273 10.9387C13.6182 11.0513 13.8325 11.1098 14.0502 11.1085H15.9569C16.303 11.108 16.6348 10.9596 16.8796 10.6961C17.1243 10.4325 17.262 10.0751 17.2626 9.70238V4.20892C17.2628 4.02398 17.2293 3.8408 17.1638 3.66982C17.0983 3.49885 17.0022 3.34344 16.881 3.21247C16.7597 3.0815 16.6157 2.97753 16.4571 2.90649C16.2986 2.83546 16.1286 2.79875 15.9569 2.79847H14.0442C13.7739 2.79785 13.5101 2.88804 13.2895 3.05648L12.5628 2.96617C11.172 2.78284 9.77126 2.70023 8.37015 2.71892C7.53293 2.62464 6.6876 2.65649 5.85855 2.81352C4.89823 3.02853 4.24338 3.47144 3.90796 4.12077C3.70755 4.51401 3.6325 4.96721 3.69434 5.41082C3.45681 5.62429 3.2686 5.8943 3.14456 6.19952C3.02052 6.50474 2.96405 6.83683 2.97959 7.16959C2.99276 7.40103 3.03714 7.62919 3.11136 7.84686C2.82055 8.22304 2.6647 8.69909 2.67213 9.18851V9.18851Z" stroke="rgba(77, 77, 77, 0.8)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.375 3.11523V11.0082" stroke="#647087" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span style="left: 22px;">Dislike</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      `;
        })}
    </div>
    `;
    }
    firstUpdated() {
        this.boundHandlClickEvent = ($event) => this.handleClick($event);
        this.trinkaAlertCard = this.shadowRoot.querySelector('#body');
        this.trinkaAlertCard.addEventListener('click', this.boundHandlClickEvent);
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    updated(changedProperties) {
        if (changedProperties.has('filteredAlertList')) {
            this.updateSimilarAlertsCount();
        }
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    scrollToElementById(alertId) {
        let card = this.shadowRoot.querySelector(`#${alertId}`);
        if (card) {
            this.closeAllCards();
            this.openCard(card);
            card.scrollIntoView({ behavior: 'smooth' });
            return;
        }
        else {
            for (const groupMap of Object.values(this.globalGroupedObj)) {
                if (groupMap.has(alertId)) {
                    const firstAlertId = groupMap.keys().next().value;
                    card = this.shadowRoot.querySelector(`#${firstAlertId}`);
                    if (card) {
                        this.closeAllCards();
                        this.openCard(card);
                        card.scrollIntoView({ behavior: 'smooth' });
                    }
                    return;
                }
            }
        }
    }
    hasSimilarCardsToIgnore(alertToCheck) {
        let type = alertToCheck['suggestions'][0]['type'];
        if (type === 2) {
            const findSimilarCards = this.filteredAlertList
                .filter(alert => alert['text'] === alertToCheck['text'])
                .filter(alert => alert['suggestions'][0]['type'] === type);
            return findSimilarCards.length > 1;
        }
        return false;
    }
    toggleCardState(alertId, identifier) {
        var _a, _b, _c;
        const card = this.shadowRoot.querySelector(`.${identifier}`);
        let isOpen = false;
        if (!card)
            return;
        this.currentIndexOfSuggestion = 0;
        const alert = this.filteredAlertList.filter((x) => x.alertId === alertId)[0];
        (0, utils_1.triggerAmplitudeEvent)('ED_RightPanel_Open_grammar_card_CTA', {
            'buttonClicked': card.classList.contains('active') ? 'close' : 'open',
            'category': this.getAlertCategory(alert === null || alert === void 0 ? void 0 : alert.suggestions[0]),
            'suggestion': (_a = alert === null || alert === void 0 ? void 0 : alert.suggestions[0]) === null || _a === void 0 ? void 0 : _a.suggestion,
            'Editing Mode': ((_c = (_b = this.trinkaEditorConfig) === null || _b === void 0 ? void 0 : _b.instructionModule) === null || _c === void 0 ? void 0 : _c.module) === 'advanced' ? 'Power Mode' : 'Lite Mode'
        });
        if (!card.classList.contains('active')) {
            isOpen = true;
            this.closeAllCards();
            this.openCard(card);
        }
        else {
            this.closeCard(card);
            if (this.highlightedGroupedAlertId) {
                this.viewGroupAlertInEditor(this.highlightedGroupedAlertId, false);
                this.highlightedGroupedAlertId = '';
            }
        }
        const event = new CustomEvent('tsdk-draggable-panel-card-toggle', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId,
                isOpen
            }
        });
        document.dispatchEvent(event);
    }
    openCard(card) {
        card.classList.add('active');
    }
    closeCard(card) {
        card.classList.remove('active');
    }
    closeAllCards() {
        const allCards = this.shadowRoot.querySelectorAll(`.alert-card.active`);
        allCards.forEach(card => {
            this.closeCard(card);
        });
    }
    getAlertCategory(suggestion) {
        if (suggestion.type == 1)
            return 'Grammar';
        else if (suggestion.type == 2)
            return 'Spelling';
        else if (suggestion.type == 3)
            return 'Writing Advisor';
        else if (suggestion.type == 4)
            return 'Enhancement';
        else if (suggestion.type == 5)
            return `Style Guide: ${this.trinkaEditorConfig.style_guide.title}`;
        else if (suggestion.type == 10)
            return `Inclusive Language: ${suggestion.error_category}`;
        else if (suggestion.type == 11)
            return `Legal Writing`;
        else
            return 'Unknown';
    }
    rejectAlert(alertId, isGroupAlert = false) {
        var _a, _b, _c, _d, _e;
        const indexOfFilterList = this.filteredAlertList.findIndex((x) => x.alertId === alertId);
        const alert = this.filteredAlertList[indexOfFilterList];
        const alertCardState = ((_b = (_a = this.shadowRoot.querySelector(`.card_wrapper-item-${indexOfFilterList}`)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.contains('active')) ? 'open' : 'closed';
        (0, utils_1.triggerAmplitudeEvent)(`ED_RightPanel_${alertCardState}_dismiss_correction`, {
            'category': this.getAlertCategory(alert === null || alert === void 0 ? void 0 : alert.suggestions[0]),
            'suggestion': (_c = alert === null || alert === void 0 ? void 0 : alert.suggestions[0]) === null || _c === void 0 ? void 0 : _c.suggestion,
            'Editing Mode': ((_e = (_d = this.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.instructionModule) === null || _e === void 0 ? void 0 : _e.module) === 'advanced' ? 'Power Mode' : 'Lite Mode',
            'isMultiCard': (alert === null || alert === void 0 ? void 0 : alert.suggestions.length) > 1
        });
        if (!isGroupAlert)
            this.closeAllCards();
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: alertId,
                action: 'reject',
                isGroupAlert
            }
        });
        document.dispatchEvent(event);
    }
    acceptAlert(alert, $event, suggestionIndex = 0, isGroupAlert = false, groupPosition = 0) {
        var _a, _b, _c, _d, _e;
        $event.stopPropagation();
        alert.disabled = true;
        const alertId = alert.alertId;
        const indexOfFilterList = this.filteredAlertList.findIndex((x) => x.alertId === alertId);
        const alertCardState = ((_b = (_a = this.shadowRoot.querySelector(`.card_wrapper-item-${indexOfFilterList}`)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.contains('active')) ? 'open' : 'closed';
        (0, utils_1.triggerAmplitudeEvent)(`ED_RightPanel_${alertCardState}_accept_correction`, {
            'category': this.getAlertCategory(alert === null || alert === void 0 ? void 0 : alert.suggestions[0]),
            'suggestion': (_c = alert === null || alert === void 0 ? void 0 : alert.suggestions[0]) === null || _c === void 0 ? void 0 : _c.suggestion,
            'Editing Mode': ((_e = (_d = this.trinkaEditorConfig) === null || _d === void 0 ? void 0 : _d.instructionModule) === null || _e === void 0 ? void 0 : _e.module) === 'advanced' ? 'Power Mode' : 'Lite Mode',
            'isMultiCard': (alert === null || alert === void 0 ? void 0 : alert.suggestions.length) > 1
        });
        this.closeAllCards();
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: alertId,
                suggestionIndex,
                action: 'accept',
                isGroupAlert,
                groupPosition
            }
        });
        document.dispatchEvent(event);
    }
    muteAlert(alertId, isGroupAlert = false) {
        const indexOfFilterList = this.filteredAlertList.findIndex((x) => x.alertId === alertId);
        const alert = this.filteredAlertList[indexOfFilterList];
        if (!isGroupAlert)
            this.closeAllCards();
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: alertId,
                action: 'mute',
                isGroupAlert
            }
        });
        document.dispatchEvent(event);
    }
    acceptNonCTAAlert(alertId) {
        this.closeAllCards();
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: alertId,
                action: 'accept_noncta'
            }
        });
        document.dispatchEvent(event);
    }
    ignoreAlertAll(alertId) {
        this.closeAllCards();
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: alertId,
                action: 'ignoreAll'
            }
        });
        document.dispatchEvent(event);
    }
    extractSurroundingWord(text, startIndex, endIndex, returnPreWord = true) {
        let preWord = '';
        let postWord = '';
        if (startIndex < 10)
            preWord = text.substring(0, startIndex);
        else
            preWord = text.substring(startIndex - 10, startIndex);
        if (text.length - endIndex < 10)
            postWord = text.substring(endIndex + 1, text.length - 1);
        else
            postWord = text.substring(endIndex + 1, endIndex + 10);
        return returnPreWord ? preWord : postWord;
    }
    changeCurrentSuggestion(value = 1, alertIndex = 0) {
        var _a;
        const newIndex = this.currentIndexOfSuggestion + value;
        const currentAlert = this.filteredAlertList[alertIndex];
        if (newIndex >= 0 && newIndex < ((_a = currentAlert === null || currentAlert === void 0 ? void 0 : currentAlert.suggestions) === null || _a === void 0 ? void 0 : _a.length)) {
            this.currentIndexOfSuggestion = newIndex;
        }
    }
    getAlertHTML(alert) {
        let isReplacementAvailable = alert.suggestions[0].cta_present && alert.suggestions[0].suggestion;
        let isDelete = alert.suggestions[0].cta_present && !alert.suggestions[0].suggestion;
        let sentenceText = alert.sentenceText;
        let htmlText = [
            `...`,
            sentenceText.slice(Math.max(0, alert.begin - 10), alert.begin),
            `<span>`,
            (isReplacementAvailable || isDelete ? `<p alert-id="${alert.alertId}" is-delete-text="${isDelete}" class="wrongTextSection">${sentenceText.slice(alert.begin, alert.end + 1)}</p>` : `<p alert-id="${alert.alertId}" class="wrongTextSection wrongTextNotClickable">${sentenceText.slice(alert.begin, alert.end + 1)}</p>`),
            '</span>'
        ].join('');
        return htmlText;
    }
    handleClick(event) {
        if (event.target.getAttribute('alert-id') && event.target.getAttribute('is-delete-text') === 'true') {
            let alertId = event.target.getAttribute('alert-id');
            const alert = this.filteredAlertList.filter((x) => x.alertId === alertId)[0];
            this.acceptAlert(alert, event, 0);
        }
    }
    openFirstAlert() {
        const alert = this.filteredAlertList[0];
        this.toggleCardState(alert.alertId, `card_wrapper-item-${0}`);
    }
    openNextAlert(index = 0) {
        const alert = this.filteredAlertList[index];
        this.toggleCardState(alert.alertId, `card_wrapper-item-${index}`);
    }
    groupAlerts() {
        var _a, _b;
        for (let i = 0; i < this.filteredAlertList.length; i++) {
            const alert = this.filteredAlertList[i];
            let lang_category = alert.suggestions[0].lang_category;
            if (this.langCategoryArr.indexOf((_a = alert === null || alert === void 0 ? void 0 : alert.suggestions[0]) === null || _a === void 0 ? void 0 : _a.lang_category) > -1) {
                if (!this.globalGroupedObj[lang_category]) {
                    this.globalGroupedObj[lang_category] = new Map();
                }
                if (!this.globalGroupedObj[lang_category].has(alert.alertId)) {
                    this.globalGroupedObj[lang_category].set(alert.alertId, alert);
                }
                this.filteredAlertList[i].groupPosition = Array.from(this.globalGroupedObj[lang_category].keys()).indexOf(alert.alertId);
                this.filteredAlertList[i].isGrouped = Array.from(this.globalGroupedObj[lang_category].values()).length > 1;
                this.filteredAlertList[i].groupNumber = this.langCategoryArr.indexOf((_b = alert === null || alert === void 0 ? void 0 : alert.suggestions[0]) === null || _b === void 0 ? void 0 : _b.lang_category);
            }
            else {
                this.filteredAlertList[i].isGrouped = false;
            }
        }
        this.requestUpdate('globalGroupedObj');
        this.updateSimilarAlertsCount();
    }
    updateSimilarAlertsCount() {
        const map = new Map();
        this.filteredAlertList.forEach((alert) => {
            if (alert.suggestions.length === 1 && alert.suggestions[0].cta_present) {
                const key = alert.suggestions[0].lang_category;
                map.set(key, (map.get(key) || 0) + 1);
            }
        });
        this.similarAlertsMap = map;
        this.requestUpdate('similarAlertsMap');
    }
    getGroupedAlertsList(langCategory) {
        var _a, _b;
        if (this.globalGroupedObj && this.globalGroupedObj[langCategory]) {
            const arrayOfSimilarAlerts = (_b = Array.from((_a = this.globalGroupedObj[langCategory]) === null || _a === void 0 ? void 0 : _a.values())) === null || _b === void 0 ? void 0 : _b.sort((a, b) => {
                if (a.segmentPosition !== b.segmentPosition) {
                    return a.segmentPosition - b.segmentPosition;
                }
                else if (a.sentencePosition !== b.sentencePosition) {
                    return a.sentencePosition - b.sentencePosition;
                }
                else {
                    return a.begin - b.begin;
                }
            });
            return arrayOfSimilarAlerts.length >= 1 ? arrayOfSimilarAlerts : [];
        }
        else
            return [];
    }
    acceptAllAlert(event, langCategory) {
        var _a;
        const groupedAlertsArr = Array.from((_a = this.globalGroupedObj[langCategory]) === null || _a === void 0 ? void 0 : _a.values());
        const customEvent = new CustomEvent('tsdk-grouped-alert-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                firstAlert: groupedAlertsArr[0],
                eventType: 'accept',
                noOfGroupedAlerts: groupedAlertsArr === null || groupedAlertsArr === void 0 ? void 0 : groupedAlertsArr.length
            }
        });
        document.dispatchEvent(customEvent);
        for (let i = groupedAlertsArr.length - 1; i >= 0; i--) {
            this.acceptAlert(groupedAlertsArr[i], event, 0, true, i);
        }
    }
    rejectAllAlert(langCategory) {
        var _a;
        const groupedAlertsArr = Array.from((_a = this.globalGroupedObj[langCategory]) === null || _a === void 0 ? void 0 : _a.values());
        const customEvent = new CustomEvent('tsdk-grouped-alert-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                firstAlert: groupedAlertsArr[0],
                eventType: 'reject',
                noOfGroupedAlerts: groupedAlertsArr === null || groupedAlertsArr === void 0 ? void 0 : groupedAlertsArr.length
            }
        });
        document.dispatchEvent(customEvent);
        for (let i = 0; i < groupedAlertsArr.length; i++) {
            this.rejectAlert(groupedAlertsArr[i].alertId);
        }
    }
    muteAllAlert(langCategory) {
        var _a;
        const groupedAlertsArr = Array.from((_a = this.globalGroupedObj[langCategory]) === null || _a === void 0 ? void 0 : _a.values());
        const customEvent = new CustomEvent('tsdk-grouped-alert-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                firstAlert: groupedAlertsArr[0],
                eventType: 'add_to_dictionary',
                noOfGroupedAlerts: groupedAlertsArr === null || groupedAlertsArr === void 0 ? void 0 : groupedAlertsArr.length
            }
        });
        document.dispatchEvent(customEvent);
        for (let i = 0; i < groupedAlertsArr.length; i++) {
            this.muteAlert(groupedAlertsArr[i].alertId, true);
        }
    }
    reGroupAlerts() {
        setTimeout(() => {
            this.globalGroupedObj = {};
            this.groupAlerts();
            this.requestUpdate('filteredAlertList');
        }, 1500);
    }
    viewGroupAlertInEditor(alertId, isOpen = true) {
        const event = new CustomEvent('tsdk-draggable-panel-card-toggle', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId,
                isOpen
            }
        });
        this.highlightedGroupedAlertId = alertId;
        document.dispatchEvent(event);
    }
    getGroupedCardHeading(alertType) {
        let cardHeading = '';
        switch (alertType) {
            case 'Articles':
                cardHeading = 'Fix all article & determiner errors';
                break;
            case 'Brevity':
                cardHeading = 'Make the phrasing concise and impactful';
                break;
            case 'Capitalization & Spacing':
                cardHeading = 'Fix all capitalization & spacing issues';
                break;
            case 'Contractions':
                cardHeading = 'Avoid contractions in formal text';
                break;
            case 'Punctuation':
                cardHeading = 'Fix all punctuation errors';
                break;
            case 'Regional Style':
                cardHeading = 'Use a uniform English spelling style';
                break;
            case 'Spellings & Typos':
                cardHeading = 'Fix all typos';
                break;
        }
        return cardHeading;
    }
};
exports.AlertsPanel = AlertsPanel;
AlertsPanel.styles = (0, lit_1.css) `
  #body {
    position: relative;
    padding: 10px 10px 100px 15px;
    margin-top: 0px;
    margin-bottom: 20px;
    max-height: calc(100% - 100px);
    min-height: calc(100% - 100px);
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    transition: all 0.5s ease;
  }
  #body:hover{
    scrollbar-color: rgb(157, 157, 157) #EEEEEE;
    transition: all 0.5s ease;
  }
  .dgl_b .card_wrapper {
    scroll-margin-top: 20px;
  }

  .dgl_b .card_wrapper {
    position: relative;
    background: #FFFFFF;
    box-shadow: 1px 1px 2px 0 #4d4d4d21!important;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: all 0.5s ease;
  }

  .dgl_b .card_wrapper .card_h {
    position: static;
    display: flex;
    padding: 8px 20px 8px 10px;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
  }

  .dgl_b .card_wrapper .card_h button {
    background-color: transparent;
    border: none;
    position: absolute;
    left: auto;
    right: 4px;
    top: 15px;
    display: none;
    cursor: pointer;
    transition: all 0.5s ease;
  }


  .dgl_b .card_wrapper .card_h .card_h_l {
    position: relative;
    display: flex;
    align-items: center;
  }

  .dgl_b .card_wrapper .card_h .card_h_l .identify_dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #DC2626;
    margin-top: 1px;
    margin-right: 6px;
    position: absolute;
    top: 10px;
  }

  .dgl_b .card_wrapper .card_h .card_h_l p {
    color: #4d4d4d;
    text-decoration: line-through;
    text-decoration-color: #FF5C5C;
    padding: 0 0px 0 10px;
    margin: 0;
    font-size: 16px;
    text-decoration-thickness: 1.3px !important;
  }

  .dgl_b .card_wrapper .card_h .card_h_r, .dgl_b .card_wrapper.multiple_card_header .card_h_multiple .card_h_r {
    display: flex;
    align-items: unset;
    padding-left: 10px;
  }

  .dgl_b .card_wrapper .card_h .card_h_r svg, .dgl_b .card_wrapper.multiple_card_header .card_h_multiple .card_h_r svg {
    margin: 0 9px 0 0px;
    position: relative;
    top: 9px;
  }

  .dgl_b .card_wrapper .card_h .card_h_r p {
    color: #763493;
    cursor: pointer;
    padding: 3px 5px;
    margin-right: 10px;
    max-width: 170px;
    margin: 0;
    font-size: 16px;
    transition: all 0.5s;
    line-height: 20px;
  }
  .dgl_b .card_wrapper .card_h .card_h_r p:hover{
    background: rgba(226, 226, 226, 0.71);
    --webkit-text-stroke: .5px rgba(122, 40, 160, .5);
  }

  .dgl_b .card_wrapper .card_b {
    position: relative;
    margin-top: 5px;
    padding: 0 10px 0 25px;
    display: none;
  }

  .dgl_b .card_wrapper .card_b .card_des_sec {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
  }


  .dgl_b .card_wrapper .card_b .card_des_sec p {
    margin: 0;
    display: block;
    height: auto;
    min-height: auto;
    color: rgba(77, 77, 77, 0.8);
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 17px;
    margin-bottom: 0px;
    width: calc(100% - 35px);
    margin-left: -5px;
  }

  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b {
    position: absolute;
    left: auto;
    right: 10px;
    top: 0px;
  }

  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b ul li {
    margin-bottom: 10px;
  }

  .dgl_b .card_wrapper .card_b .card_des_sec .card_option_b ul li:last-child {
    margin-bottom: 0px;
  }

  .dgl_b .card_wrapper .card_b .card_cat {
    position: relative;
    margin-top: 5px;
    border-top: 1px solid #eeeeee;
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: -5px;
  }

  .dgl_b .card_wrapper .card_b .card_cat p {
    font-size: 10px;
    font-style: italic;
    color: rgba(77, 77, 77, 0.8);
    width: 100%;
    line-height: inherit;
    min-height: auto;
    margin: 0;
    text-transform: uppercase;
  }

  .dgl_b .card_wrapper.active {
    box-shadow: 0 0 4px .5px rgba(77,77,77,.8)!important;
  }

  .dgl_b .card_wrapper.active .card_b {
    display: block;
  }
  .dgl_b .card_wrapper.active .card_h button{
    display: block;
    transform: rotate(180deg);
    transition: all 0.5s ease 0s;
  }

  .dgl_b .card_wrapper:hover .card_h button {
    display: block;
    transition: all 0.5s ease;
    z-index: 0; //Changing from 99
  }

  .addToDictionaryBtn.wasHelpFulBtn {
    background: #7A28A0;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #FFFFFF;
    margin-left: -3px;
    height: 36px;
    padding: 0 8px;
    border-radius: 4px;
    margin-right: 2px;
    position: relative;
    top: -1px;
  }
  .addToDictionaryBtn.wasHelpFulBtn:hover{
    cursor: pointer;
  }

  .alert-disabled {
    background: #80808073 !important;
    cursor: not-allowed;
  }
  .trinkaB2BEditor#body{
    max-height: calc(100% - 260px);
    min-height: calc(100% - 220px);
  }
  .optionButtons{
    display: none;
  }
  #body.trinkaCloudEditor{
    max-height: calc(100% - 200px);
    min-height: calc(100% - 200px);
    padding-top: 16px !important;
  }
  .alert-card .card_h .card_cat,
  #body.trinkaCloudEditor .alert-card.active .card_h .card_h_l,
  #body.trinkaCloudEditor .alert-card.active .card_h,
  #body.trinkaCloudEditor .alert-card.active .card_h .card_h_r,
  #body.trinkaCloudEditor .alert-card .card_b .card_cat,
  #body.trinkaCloudEditor .card_option_b{
    display: none;
  }
  #body.trinkaCloudEditor .alert-card .card_h .card_cat,
  #body.trinkaCloudEditor .alert-card.active .card_h .card_cat,
  #body.trinkaCloudEditor .alert-card.active .card_h:first-child{
    display: block;
  }
  #body.trinkaCloudEditor .alert-card.active .card_h .card_cat{
      position: relative;
      padding-left: 10px;
      margin-bottom: 5px;
      top:0;
  }
  #body.trinkaCloudEditor .alert-card .card_h .card_O_C{
    top: 13px;
    right: 10px;
  }
  #body.trinkaCloudEditor .alert-card.active .card_h .card_cat p,
  #body.trinkaCloudEditor .alert-card .card_h .card_cat p{
    margin: 0 0 0 0px;
    color: #4D4D4D;
    font-size: 10px;
    font-weight: 400;
    line-height: 13.62px;
    text-align: left;
    padding-top: 4px;
    position: relative;
  }
  #body.trinkaCloudEditor .alert-card.active .card_h .card_cat p{
    margin: 0 0 0 4px;
  }
  #body.trinkaCloudEditor .alert-card .card_h .card_cat p{
    text-transform: capitalize;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_h .card_cat p{
    text-transform: none;
  }
  #body.trinkaCloudEditor .alert-card.active .card_h .card_cat::before,
  #body.trinkaCloudEditor .alert-card .card_h .card_cat::before{
    content: '';
    position: absolute;
    left: 0px;
    right: auto;
    width: 4px;
    height: 4px;
    background: #DC2626;
    border-radius: 50%;
    top: 9px;
  }
  #body.trinkaCloudEditor .alert-card .card_h .card_cat{
    position: absolute;
    left: 0;
    right: auto;
    top: -20px;
    padding-left: 10px;
    opacity: 0;
  }
  #body.trinkaCloudEditor .alert-card.active .card_h .card_cat{
    opacity: 1;
  }
  #body.trinkaCloudEditor .card_wrapper{
      box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.1) !important;
      margin-bottom: 16px;
      padding: 11px 14px;
  }
  #body.trinkaCloudEditor .card_wrapper .card_h{
      padding: 0px 10px 0px 0px;
  }
  #body.trinkaCloudEditor .card_wrapper .card_h .card_h_l .identify_dot{

  }
  #body.trinkaCloudEditor .optionButtons{
    position: relative;
    left: -5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide{
    display: flex;
    align-items: center;
    position: relative;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button{
    font-family: "Open Sans", sans-serif;
    padding: 4px 6px;
    min-width: 80px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    margin-right: 10px;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button:hover{
    transition: all 0.5s ease;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button:focuse{
    box-shadow: none;
    outline: none;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button.btnAccepted{
    background: #7A28A0;
    border: none;
    color: #FFFFFF;
    font-weight: 500;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button.btnAccepted:hover{
    background: #6B238D;
    transition: all 0.5s ease;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15);
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button.btnDismissed{
    background: transparent;
    border: none;
    color: rgba(77, 77, 77, 0.8);
    margin-right: 0;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide button.btnDismissed:hover{
    background: #EFF1F5;
    transition: all 0.5s ease;
    box-shadow: none;
  }
  #body.trinkaCloudEditor .wasHelpfulBtnWrapper button.btnDismissed {
    font-family: "Open Sans", sans-serif;
    padding: 4px 6px;
    min-width: 80px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    margin-right: 0px;
    transition: 0.5s;
    cursor: pointer;
    background: transparent;
    border: none;
    color: rgba(77, 77, 77, 0.8);
  }
  #body.trinkaCloudEditor .wasHelpfulBtnWrapper{
    margin-top: 14px;
  }
  #body.trinkaCloudEditor .wasHelpfulBtnWrapper button.btnDismissed:hover {
    background: #EFF1F5;
    transition: all 0.5s ease;
    box-shadow: none;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsRightSide{
    display: flex;
    align-items: center;
    position: relative;
    top: 4px;
    display: none;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsRightSide a{
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsRightSide a:hover svg path{
    stroke: #7A28A0;
    transition: all 0.5s ease;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsRightSide a span{
    position: absolute;
    background: #414E62;
    color: #FFF;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    top: 25px;
    z-index: 2;
    left: -3px;
    display: none;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsRightSide a span::before{
    bottom: 100%;
    left: 30%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #414E62;
    border-width: 5px;
    margin-left: 5px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsRightSide a:hover span{
    display: block;
  }
  #body.trinkaCloudEditor .card_wrapper .card_b{
    padding: 0px 6px 6px 15px;
    margin-top: 0px;
  }
  #body.trinkaCloudEditor .card_wrapper .card_b .card_des_sec p{
    width: 100%;
  }
  .card_b .cardBodyAlerts{
    display: none;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts{
    display: block;
    // align-items: center;
    // flex-wrap: wrap;
    position: relative;
    left: -4px;
    margin-bottom: 10px;
    line-height: 18px;
  }
  #body.trinkaCloudEditor .card_b .multiAlertGroupingBody .cardBodyAlerts{
    display: flex;
    padding-right: 6px;
  }
  #body.trinkaCloudEditor .card_b .multiAlertGroupingBody .cardBodyAlerts:last-child{
    margin-bottom: 27px;
  }  
  #body.trinkaCloudEditor .card_b .cardBodyAlerts span {
    padding: 0;
    margin: 0;
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.wrongTextSection {
    display: inline;
    margin: 0;
    padding: 0;
    color: #EA1537;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    position: relative;
    cursor: default;
    text-decoration: line-through;
    text-decoration-thickness: 1.3px !important;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.wrongTextSection[is-delete-text="true"] {
    cursor: pointer;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection {
    display: inline-block;
    margin: 0px;
    padding: 0;
    color: #16A34A;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection.hideRightTextSection {
    display: none;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection.multiSuggestionsListOption {
    width: 100%;
    display: flex;
    margin: 2px 0;
    padding: 4px 6px;
    border-radius: 4px;
    position: relative;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection.multiSuggestionsListOption .multiAlertAcceptButton{
    background-color: transparent;
    border: none;
    color: #7A28A0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    left: auto;
    right: 10px;
    top: 2px;
    display: none;
    font-family: "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection.multiSuggestionsListOption:hover .multiAlertAcceptButton{
    display: block;
    cursor: pointer;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection.multiSuggestionsListOption:hover{
    background-color: rgba(234, 190, 255, 0.2);
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.rightTextSection.multiSuggestionsListOption span svg {
    display: inline-block;
    margin-right: 15px;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts p.wrongTextSection.wrongTextNotClickable {
    cursor: text;
    text-decoration: none;
  }
  #body.trinkaCloudEditor .card_b .cardBodyAlerts svg{
    display: none;
  }
  #body.trinkaCloudEditor .card_h .card_h_r p{
    font-size: 14px;
    max-width: initial !important;
  }
  #body.trinkaCloudEditor .card_h .card_h_l p{
    font-size: 14px;
    padding-left: 10;
  }
  // .trinkaCloudEditor .addToDictionaryBtn{
  //   display: none;
  // }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA{
    position: relative;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA button{
    background-color: transparent;
    padding: 0;
    border: none;
    color: #7A28A0;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-right: 0;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA button:hover{
    box-shadow: none;
    background-color: #F3E8FF;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA button:hover svg .fillColored{
    fill: #7A28A0;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA button:hover svg .strokeColred{
    stroke: #7A28A0;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA span{
    position: absolute;
    background: rgb(65, 78, 98);
    color: rgb(255, 255, 255);
    padding: 6px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    top: 40px;
    z-index: 2;
    left: -48px;
    width: 130px;
    display: none;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA span::before{
    bottom: 100%;
    left: 40%;
    border-style: solid;
    border-color: transparent transparent rgb(65, 78, 98);
    border-image: initial;
    content: "";
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
    border-width: 5px;
    margin-left: 5px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .dictionaryCTA:hover span{
    display: block;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .similarCardsCTA.dictionaryCTA {
    margin-right: 0px;
    cursor: pointer;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .similarCardsCTA.dictionaryCTA span{
    width: 210px;
    left: -80px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .similarCardsCTA.dictionaryCTA:hover svg path{
    stroke: rgb(122, 40, 160);
  }
  .multipleCardArrow{
    display: none;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow{
    position: relative;
    display: flex;
    align-items: center;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow a{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow a:hover{
    background: rgb(239, 241, 245);
    border-radius: 2px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow a svg path{
    fill: rgba(77, 77, 77, 0.8);
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow span{
    font-size: 12px;
    font-weight: 400;
    line-height: 26.44px;
    color: rgba(77, 77, 77, 0.8);
    padding: 0 2px;
  }
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow .disableNext,
  #body.trinkaCloudEditor .optionButtons .optionButtonsLeftSide .multipleCardArrow .disablePrev {
    cursor: not-allowed !important;
    opacity: 0.4;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_h{
    padding-top: 16px;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_h .card_cat{
    opacity: 1;
    top: 6px;
    left: 14px;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_h .card_h_l .identify_dot{
    display: none;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card.active .card_h{
    padding-top: 0px;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card.active .alert-grouped-span, #body.trinkaCloudEditor .grouped-alert-card.alert-card.active .alert-grouped-span-outer{
    display:none;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .alert-grouped-span-outer {
    width: 100%;
    cursor: pointer;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card.active .card_h .card_cat{
    opacity: 1;
    top: -5px;
    left: 0px;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .optionButtons{
    margin: 16px 0px -16px -23px;
    background: #FFFFFF;
    width: 326px;
    padding: 10px 16px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 0px 3px 0px rgba(31, 41, 55, 0.09);
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .optionButtons .optionButtonsLeftSide button{
    height: 34px;
    padding: 4px 10px;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .cardBodyAlerts .multiAlertBodyHover{
    margin: 0;  
    padding: 4px 6px;
    border-radius: 4px;
    transition: 0.2s all ease;
    max-width: calc(100% - 38px);
    cursor: pointer;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .cardBodyAlerts .multiAlertBodyHover  .wrongTextSection{
   cursor: pointer;
  }
  // #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .multiAlertGroupingBody .cardBodyAlerts:last-child .multiAlertBodyHover {
  //   margin-bottom: 11px;
  // }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .cardBodyAlerts .multiAlertBodyHover.withAddToDictionaryBtn {
      max-width: calc(100% - 70px);
  }  
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .cardBodyAlerts .multiAlertBodyHover:hover{
    background: rgba(230, 190, 255, 0.2);
    transition: 0.5s all ease;
  }
  .grouped-alert-card .alert-grouped-span{
    background: #FEF2F2;
    padding: 0px 6px;
    border-radius: 4px;
    color: #DC2626;
    font-size: 12px;
    line-height: 100%;
    font-family: "Open Sans";
    height: 22px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 8px;
  }
  .grouped-alert-card .alert-grouped-span svg{
    margin-left: 4px;
  }
  .grouped-alert-card .multiAlertGroupingBody{
    margin: 0 -20px -26px -6px;
    position: relative;
    max-height: 260px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color:#F3E8FF #FFFFFF;
    scrollbar-width: thin;
  }
  .grouped-alert-card .multiAlertGroupingBody:hover{
    scrollbar-color:#F3E8FF #FFFFFF; !important;
    scrollbar-width: thin;
  }
  .grouped-alert-card .cardBodyAlerts .multiAlertBodyHover{
    width: 100%;
    position: relative;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents{
    align-items: center;
    gap: 2px;
    display: none;
  }
  .grouped-alert-card .cardBodyAlerts:hover .onHoverContents{
    display: flex !important;
    min-width: 25px;
    text-align: right;
    float: right;
    justify-content: end;
    height: 26px;
  }
  .grouped-alert-card .cardBodyAlerts .view-text-tooltip {
    position: absolute;
    left: 50%;
    right: auto;
    bottom: auto;
    top: 27px;
    z-index: 9;
    background: #414E62 !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12.82px !important;
    letter-spacing: 0% !important;
    text-align: center !important;
    padding: 4px 6px !important;
    broder-radius: 4px !important;
    display: none;
    color: #FFFFFF !important;
    border-radius: 4px !important;
  }
  
  .grouped-alert-card .cardBodyAlerts .onHoverContents a{
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents a svg{
    display: block !important;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents a:hover{
    transition: all 0.5s ease;
    background: #EFF1F5 !important;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverAddToDictionary span,
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverDismiss span{
    position: absolute;
    left: auto;
    right: -10px;
    bottom: -26px;
    background: #414E62 !important;
    font-weight: 500 !important;
    font-size: 10px !important;
    line-height: 12.82px !important;
    letter-spacing: 0% !important;
    text-align: center !important;
    padding: 4px 2px !important;
    broder-radius: 4px !important;
    display: none;
    color: #FFFFFF !important;
    border-radius: 4px !important;
    width: 40px;
    z-index: 1;
  }
  .grouped-alert-card .cardBodyAlerts .view-text-tooltip:before, 
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverAddToDictionary span:before,   
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverDismiss span:before{
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #414E62;
    border-width: 6px;
    margin-left: -6px;
  }
  
  .grouped-alert-card .cardBodyAlerts .onHoverContents a:hover span{
    display: block;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverAddToDictionary:hover{
    background: #F3E8FF !important;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverAddToDictionary:hover svg path{
    fill: #7A28A0;
  }
  .grouped-alert-card .cardBodyAlerts .onHoverContents a.hoverAddToDictionary span{
    width: 90px;
    margin: auto;
    left: -36px !important;
    right: auto !important;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_b .cardBodyAlerts .multiAlertBodyHover:hover .view-text-tooltip{
    display: block !important;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .card_h .card_cat::before{
      display: none;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card.active .masterCardIcons,
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .alertTotalNumberCount{
    display: none;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card .masterCardIcons{
    position: absolute;
    left: -2px;
    top: -5px;
  }
  #body.trinkaCloudEditor .grouped-alert-card.alert-card.active .alertTotalNumberCount{
    position: absolute;
    left: -6px;
    top: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FEE2E2;
    color: #DC2626;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "isCloudTrinka", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "editorId", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "filteredAlertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "trinkaEditorConfig", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "currentIndexOfSuggestion", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "limitExhausted", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "userUpgradeLink", void 0);
__decorate([
    (0, decorators_js_1.property)(),
    __metadata("design:type", Object)
], AlertsPanel.prototype, "globalGroupedObj", void 0);
exports.AlertsPanel = AlertsPanel = __decorate([
    (0, decorators_js_1.customElement)('alerts-panel')
], AlertsPanel);
