"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrinkaSDK = void 0;
const trinka_enums_1 = require("./enums/trinka.enums");
const grammar_1 = require("./modules/grammar");
const paraphraser_1 = require("./modules/paraphraser");
const consistency_1 = require("./modules/consistency");
const amplitude_service_1 = require("./utils/amplitude.service");
class TrinkaSDK {
    constructor() {
        this.initializedModule = null;
    }
    initializeModule(module, trinkaConfig, moduleConfig, uiConfig, cloudConfig = undefined) {
        this.trinkaConfig = trinkaConfig;
        this.moduleConfig = moduleConfig;
        this.uiConfig = uiConfig;
        this.cloudConfig = cloudConfig;
        if (cloudConfig) {
            (0, amplitude_service_1.initAmplitude)(cloudConfig === null || cloudConfig === void 0 ? void 0 : cloudConfig.amplitudeKey, cloudConfig === null || cloudConfig === void 0 ? void 0 : cloudConfig.userID);
        }
        else
            (0, amplitude_service_1.initAmplitude)();
        if (this.initializedModule) {
            console.warn(`${this.initializedModule.name} module is already initialized, please destroy the existing module and then intialize the new one`);
            return;
        }
        switch (module) {
            case 'grammar':
                this.initializedModule = new grammar_1.Grammar(this.trinkaConfig, this.moduleConfig, this.uiConfig, `input[type="text"], textarea, [contenteditable="true"]`, cloudConfig);
                break;
            case 'paraphraser':
                this.initializedModule = new paraphraser_1.Paraphraser(this.moduleConfig, this.uiConfig, `[contenteditable="true"]`, cloudConfig);
                break;
            case 'consistencyChecker':
                this.initializedModule = new consistency_1.Consistency(this.moduleConfig, this.uiConfig, `[contenteditable="true"]`, cloudConfig);
                break;
            default:
                console.warn(`${module} module is not supported`);
        }
    }
    destroy() {
        if (this.initializedModule) {
            this.initializedModule.destroy();
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.initializedModule = null;
                    resolve(true);
                    console.log('===> resolved !!!');
                }, 2000);
            });
        }
        else {
            return Promise.resolve(true);
        }
    }
    updateCloudConfig(config) {
        this.cloudConfig = Object.assign(Object.assign({}, this.cloudConfig), config);
        this.initializedModule.cloudConfig = this.cloudConfig;
        this.initializedModule.updateCloudConfig(this.cloudConfig);
    }
}
exports.TrinkaSDK = TrinkaSDK;
window.TrinkaSDK = TrinkaSDK;
window.Trinka_ENUMS = trinka_enums_1.Trinka_ENUMS;
