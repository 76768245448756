"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const grammarWorker = () => {
    let url;
    let socket;
    let isSocketOpen = false;
    let callbackMapper = new Map();
    let openSocketPromise;
    let openDBPromise;
    let dbConnectionRequest;
    let db;
    let currentEditorId;
    let grammmarPendingRequests = {};
    const encodeString = (text) => {
        return btoa(encodeURIComponent(text));
    };
    const decodeString = (text) => {
        return decodeURIComponent(atob(text));
    };
    function intiateWorker(editorId, socketUrl, dbName) {
        currentEditorId = editorId;
        intiateSocket(socketUrl);
        intiateDB(dbName);
    }
    function intiateSocket(url) {
        try {
            url = url;
            socket = new WebSocket(url);
            isSocketOpen = false;
            initSocketOpenPromise();
            addEventListeners();
            return true;
        }
        catch (error) {
            return false;
        }
    }
    function intiateDB(dbName, version = 2) {
        try {
            if (openDBPromise) { }
            else {
                openDBPromise = new Promise((resolve, reject) => {
                    dbConnectionRequest = indexedDB.open(dbName);
                    dbConnectionRequest.onsuccess = function (event) {
                        db = event.target.result;
                        resolve(true);
                    };
                    dbConnectionRequest.onerror = function (event) {
                        reject(false);
                    };
                    dbConnectionRequest.onupgradeneeded = function (event) {
                        db = event.target.result;
                    };
                });
            }
            return true;
        }
        catch (error) {
            return false;
        }
    }
    function restartSocket(newUrl) {
        removeEventListeners();
        socket.close();
        url = newUrl;
        socket = new WebSocket(newUrl);
        isSocketOpen = false;
        initSocketOpenPromise();
        addEventListeners();
    }
    function initSocketOpenPromise() {
        openSocketPromise = new Promise((resolve) => {
            if (isSocketOpen) {
                resolve({ data: socket.readyState, alreadyResolved: true });
            }
            else {
                const openHandler = () => {
                    isSocketOpen = true;
                    socket.removeEventListener('open', openHandler);
                    resolve({ data: socket.readyState, alreadyResolved: false });
                };
                socket.addEventListener('open', openHandler);
            }
        });
    }
    async function waitForSocketOpen() {
        return openSocketPromise;
    }
    async function waitForDBOpen() {
        return openDBPromise;
    }
    function addEventListeners() {
        socket.onmessage = handleMessage;
        socket.addEventListener('open', handleOpen);
        socket.addEventListener('close', handleClose);
        socket.addEventListener('error', handleError);
    }
    function removeEventListeners() {
        socket.onmessage = () => { };
        socket.removeEventListener('open', handleOpen);
        socket.removeEventListener('close', handleClose);
        socket.removeEventListener('error', handleError);
    }
    let handleOpen = () => {
        console.log('WebSocket connection established');
    };
    let handleClose = (event) => {
        console.log('WebSocket connection closed:', event.code, event.reason);
    };
    let handleError = (error) => {
        console.error('WebSocket error:', error);
    };
    let handleMessage = (event) => {
        let data;
        try {
            data = JSON.parse(decodeURIComponent(atob(event.data)));
            const request_id = `${data.type}-${data.request_id}`;
            if (!callbackMapper.has(request_id))
                return;
            callbackMapper.get(request_id).resolve(data);
            callbackMapper.delete(request_id);
        }
        catch (error) {
            try {
                data = JSON.parse(event.data);
                const request_id = `split_check_reqest-${data.request_id}`;
                if (!callbackMapper.has(request_id))
                    return;
                callbackMapper.get(request_id).resolve(data);
                callbackMapper.delete(request_id);
            }
            catch (error) {
                console.log('ignore for now ===> ', error);
            }
        }
        updateLoaderState();
    };
    function updateLoaderState() {
        if (callbackMapper.size) {
            sendJsonRpcRequest('showLoader', currentEditorId)
                .then(result => { })
                .catch(error => console.error('Error:', error));
        }
        else {
            sendJsonRpcRequest('hideLoader', currentEditorId)
                .then(result => { })
                .catch(error => console.error('Error:', error));
        }
    }
    function sendJsonRpcRequest(method, params = {}) {
        const requestId = Date.now() + Math.random();
        const request = {
            jsonrpc: "2.0",
            method: method,
            params: params,
            id: requestId,
            type: 'request'
        };
        return new Promise((resolve, reject) => {
            grammmarPendingRequests[requestId] = { resolve, reject };
            postMessage(request);
        });
    }
    onmessage = async function (event) {
        const { jsonrpc, method, params, id, type, result, error } = event.data;
        if (jsonrpc !== "2.0") {
            sendResponse(id, null, { code: -32600, message: "Invalid JSON-RPC version" });
            return;
        }
        if (type === 'response' && grammmarPendingRequests[id]) {
            const { resolve, reject } = grammmarPendingRequests[id];
            if (error) {
                reject(new Error(error.message));
            }
            else {
                resolve(result);
            }
            delete grammmarPendingRequests[id];
        }
        else if (type === 'request' && workerApis[method]) {
            const api = workerApis[method];
            if (api) {
                try {
                    const result = await api(params);
                    sendResponse(id, result);
                }
                catch (error) {
                    sendResponse(id, null, { code: -32603, message: error.message });
                }
            }
            else {
                sendResponse(id, null, { code: -32601, message: "Method not found" });
            }
        }
    };
    function sendResponse(id, result, error = null) {
        postMessage({
            jsonrpc: "2.0",
            result: result,
            error: error,
            id: id,
            type: 'response'
        });
    }
    function sendPayload(data) {
        socket.send(data);
    }
    function isRequestIsInProgress(request_id) {
        return Promise.resolve(!!callbackMapper.has(request_id));
    }
    function getCallbackMapper() {
        const keysIterator = callbackMapper.keys();
        const listOfRequestIds = [...keysIterator];
        return listOfRequestIds;
    }
    function splitAndCheck(payload) {
        const requestId = `split_check_reqest-${payload.request_id}`;
        return new Promise((resolve, reject) => {
            callbackMapper.set(requestId, { requestId, text: payload.text, resolve, reject });
            sendPayload(JSON.stringify(payload));
            setTimeout(() => {
                if (callbackMapper.has(requestId)) {
                    callbackMapper.delete(requestId);
                    reject(payload);
                }
                updateLoaderState();
            }, 50 * 1000);
        });
    }
    function split(payload) {
        const requestId = `split-${payload.request_id}`;
        return new Promise((resolve, reject) => {
            callbackMapper.set(requestId, { requestId, text: payload.text, resolve, reject });
            sendPayload(encodeString(JSON.stringify(payload)));
            setTimeout(() => {
                if (callbackMapper.has(requestId)) {
                    callbackMapper.delete(requestId);
                    reject(payload);
                }
                updateLoaderState();
            }, 50 * 1000);
        });
    }
    function check(payload) {
        const requestId = `check-${payload.request_id}`;
        return new Promise((resolve, reject) => {
            callbackMapper.set(requestId, { requestId, text: payload.text, resolve, reject });
            sendPayload(encodeString(JSON.stringify(payload)));
            setTimeout(() => {
                if (callbackMapper.has(requestId)) {
                    callbackMapper.delete(requestId);
                    reject(payload);
                }
                updateLoaderState();
            }, 50 * 1000);
        });
    }
    function scanCache(storeName, indexKey, listOfSearchKey) {
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);
        const cache = {
            cached: [],
            notCached: [],
        };
        let remainingChecks = listOfSearchKey.length;
        return new Promise((resolve, reject) => {
            listOfSearchKey.forEach(searchKey => {
                const index = store.index(indexKey);
                const query = index.get(searchKey);
                query.onsuccess = function () {
                    if (!query.result) {
                        cache.notCached.push({ searchKey });
                    }
                    else {
                        cache.cached.push(query.result.response);
                    }
                    remainingChecks--;
                    if (remainingChecks === 0) {
                        resolve(cache);
                    }
                };
                query.onerror = function () {
                    remainingChecks--;
                    if (remainingChecks === 0) {
                        resolve(cache);
                    }
                };
            });
        });
    }
    const workerApis = {
        intiateWorker: ({ editorId, socketUrl, dbName }) => intiateWorker(editorId, socketUrl, dbName),
        intiateSocket: (url) => intiateSocket(url),
        intiateDB: (name) => intiateDB(name),
        restartSocket: (url) => restartSocket(url),
        waitForSocketOpen: () => waitForSocketOpen(),
        waitForDBOpen: () => waitForDBOpen(),
        splitAndCheck: (payload) => splitAndCheck(payload),
        split: (payload) => split(payload),
        check: (payload) => check(payload),
        isRequestIsInProgress: (request_id) => isRequestIsInProgress(request_id),
        getCallbackMapper: () => getCallbackMapper(),
        scanCache: ({ storeName, indexKey, listOfSearchKey }) => scanCache(storeName, indexKey, listOfSearchKey)
    };
};
const workerBlob = new Blob([`(${grammarWorker.toString()})()`], { type: 'application/javascript' });
const grammarWorkerURL = URL.createObjectURL(workerBlob);
exports.default = grammarWorkerURL;
