"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggablePanelFooter = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let DraggablePanelFooter = class DraggablePanelFooter extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.limitExhausted = false;
        this.loginRequired = false;
        this.loader = false;
        this.userType = 'BASIC';
        this.classesForLimitExhaustSection = { 'limitExhaustedSection': true, 'exhaustedLimistNew': true, 'viewMoreLimitExhausted': false };
        this.currentLang = 'en';
    }
    showViewMore() {
        this.classesForLimitExhaustSection = Object.assign(Object.assign({}, this.classesForLimitExhaustSection), { viewMoreLimitExhausted: true });
    }
    closeViewMore() {
        this.classesForLimitExhaustSection = Object.assign(Object.assign({}, this.classesForLimitExhaustSection), { viewMoreLimitExhausted: false });
    }
    computeClasses(classes) {
        return Object.keys(classes)
            .filter((key) => classes[key])
            .join(' ');
    }
    render() {
        return (0, lit_1.html) `
    <div class="${this.computeClasses(this.classesForLimitExhaustSection)}" style="display: ${this.limitExhausted ? 'none' : 'none'}">
      <svg width="18" height="16" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
          d="M15.1684 1.63019C15.4518 1.13538 15.863 0.723743 16.3602 0.43733C16.8574 0.150917 17.4226 0 17.9982 0C18.5738 0 19.139 0.150917 19.6362 0.43733C20.1334 0.723743 20.5446 1.13538 20.828 1.63019L35.5602 27.1215C36.8131 29.2905 35.2398 32 32.7268 32H3.27319C0.76022 32 -0.813087 29.2905 0.439799 27.1215L15.172 1.62663L15.1684 1.63019ZM16.1981 12.4433V15.999C16.1981 16.4706 16.3877 16.9228 16.7253 17.2562C17.0629 17.5896 17.5208 17.7769 17.9982 17.7769C18.4756 17.7769 18.9335 17.5896 19.2711 17.2562C19.6087 16.9228 19.7983 16.4706 19.7983 15.999V12.4433C19.7983 11.9718 19.6087 11.5195 19.2711 11.1861C18.9335 10.8527 18.4756 10.6654 17.9982 10.6654C17.5208 10.6654 17.0629 10.8527 16.7253 11.1861C16.3877 11.5195 16.1981 11.9718 16.1981 12.4433ZM17.9982 20.4438C17.2821 20.4438 16.5953 20.7247 16.0889 21.2249C15.5825 21.725 15.298 22.4033 15.298 23.1106C15.298 23.8179 15.5825 24.4962 16.0889 24.9963C16.5953 25.4964 17.2821 25.7774 17.9982 25.7774C18.7143 25.7774 19.4011 25.4964 19.9075 24.9963C20.4139 24.4962 20.6984 23.8179 20.6984 23.1106C20.6984 22.4033 20.4139 21.725 19.9075 21.2249C19.4011 20.7247 18.7143 20.4438 17.9982 20.4438Z"
          fill="#ed2327"></path>
        </svg>
      ${this.userType === 'BASIC' ? (0, lit_1.html) `<p class="userLimitExhaust">
        <span>Your 5,000-word limit is exhausted. You can still review and apply suggestions for processed sentences. Upgrade to enjoy uninterrupted writing.</span>
        <button @click=${() => this.showViewMore()} class="view-more-btn">view more</button>
        <button @click=${() => this.closeViewMore()} class="view-less-btn">view less</button>
      </p>` : (0, lit_1.html) `<p class="userLimitExhaust">
        <span>Your 150,000-word limit is reached. You can still review and apply suggestions for processed sentences. For further assistance, please contact us at <a href="mailto:support@trinka.ai">support@trinka.ai</a>.</span>
        <button @click=${() => this.showViewMore()} class="view-more-btn">view more</button>
        <button @click=${() => this.closeViewMore()} class="view-less-btn">view less</button>
      </p>`}

    </div>

    <div class="loginSection" style="display: ${this.loginRequired ? 'block' : 'none'}">
      <p>
        <svg width="18" height="16" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
          d="M15.1684 1.63019C15.4518 1.13538 15.863 0.723743 16.3602 0.43733C16.8574 0.150917 17.4226 0 17.9982 0C18.5738 0 19.139 0.150917 19.6362 0.43733C20.1334 0.723743 20.5446 1.13538 20.828 1.63019L35.5602 27.1215C36.8131 29.2905 35.2398 32 32.7268 32H3.27319C0.76022 32 -0.813087 29.2905 0.439799 27.1215L15.172 1.62663L15.1684 1.63019ZM16.1981 12.4433V15.999C16.1981 16.4706 16.3877 16.9228 16.7253 17.2562C17.0629 17.5896 17.5208 17.7769 17.9982 17.7769C18.4756 17.7769 18.9335 17.5896 19.2711 17.2562C19.6087 16.9228 19.7983 16.4706 19.7983 15.999V12.4433C19.7983 11.9718 19.6087 11.5195 19.2711 11.1861C18.9335 10.8527 18.4756 10.6654 17.9982 10.6654C17.5208 10.6654 17.0629 10.8527 16.7253 11.1861C16.3877 11.5195 16.1981 11.9718 16.1981 12.4433ZM17.9982 20.4438C17.2821 20.4438 16.5953 20.7247 16.0889 21.2249C15.5825 21.725 15.298 22.4033 15.298 23.1106C15.298 23.8179 15.5825 24.4962 16.0889 24.9963C16.5953 25.4964 17.2821 25.7774 17.9982 25.7774C18.7143 25.7774 19.4011 25.4964 19.9075 24.9963C20.4139 24.4962 20.6984 23.8179 20.6984 23.1106C20.6984 22.4033 20.4139 21.725 19.9075 21.2249C19.4011 20.7247 18.7143 20.4438 17.9982 20.4438Z"
          fill="#FDB933"></path>
        </svg>
        <a href="https://cloud.trinka.ai/signin" target="_blank">Please login</a>
      </p>
    </div>

    <div class="pluginFooterLoader" style="display: ${this.loader ? 'block' : 'none'}">
      <div class="checkingloader ${this.isCloudTrinka ? 'trinkaCloudEditor' : 'trinkaB2BEditor'}">
        <span>Scanning</span>
        <div class="dot-flashing"></div>
      </div>
    </div>
    `;
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
};
exports.DraggablePanelFooter = DraggablePanelFooter;
DraggablePanelFooter.styles = (0, lit_1.css) `
  .limitExhaustedSection, .loginSection {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    background: #FFF;
    bottom: -1px;
    box-shadow: 0 0px 9px 1px #ccc;
    border-radius: 0px;
  }
  .limitExhaustedSection p.userLimitExhaust{
    text-align: left;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 4px 0 10px 0;
    font-size: 12px;
    color: #ed2327;
    position: relative;
    top: 4px;
  }
  .limitExhaustedSection p.userLimitExhaust svg{
    margin-right: 10px;
  }
  .limitExhaustedSection p.userLimitExhaust span{
    margin: 0;
    padding: 0;
    color: #000000;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 260px;
    display: inline-block;
  }
  .limitExhaustedSection p.userLimitExhaust span a{
    font-size: 12px;
    font-weight: 600;
    color: #000;
  }
  .limitExhaustedSection.exhaustedLimistNew{
    display: none; //Hidden for now
    align-items: inherit;
    padding: 0 10px;
    box-shadow: 6px .5rem 1rem rgba(0,0,0,.15);
    bottom: 25px;
    border-radius: 4px;
    border: solid 1px #d2d2d2;
  }
  .limitExhaustedSection.exhaustedLimistNew svg{
    min-width: 18px;
    height: 16px;
    margin-right: 10px;
    position: relative;
    top: 12px;
  }
  .limitExhaustedSection p.userLimitExhaust button{
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: -0px;
    color: #007bff;
    cursor: pointer;
    margin-left: 5px;
    font-size: 12px;
  }
  .limitExhaustedSection p.userLimitExhaust button:hover{
    text-decoration: underline;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted p.userLimitExhaust{
    display: inline-block;
    line-height: 18px;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted p.userLimitExhaust svg{
    top: 10px;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted p.userLimitExhaust span{
    white-space: normal;
    display: inline;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted p.userLimitExhaust span a:hover{
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted p.userLimitExhaust button{
    top: 0;
  }
  .limitExhaustedSection.exhaustedLimistNew p.userLimitExhaust button.view-more-btn {
    display: block;
  }
  .limitExhaustedSection.exhaustedLimistNew button.view-less-btn {
    display: none;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted p.userLimitExhaust button.view-more-btn {
    display: none;
  }
  .limitExhaustedSection.exhaustedLimistNew.viewMoreLimitExhausted button.view-less-btn {
    display: block;
    margin-left: 0;
  }
  .loginSection p {
    margin: 0;
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .limitExhaustedSection p a, .loginSection p a {
    font-size: 16px;
    text-decoration: none;
    margin-left: 4px;
    color: #551a8b;
    cursor: pointer;
  }

  .pluginFooterLoader{
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    height: 30px;
    bottom: 21px;
    border-radius: 0px 0px 6px 6px;
    bottom: 0px;
    box-shadow: none;
  }

.checkingloader {
    position: absolute;
    background: #fff;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: inherit;
    padding: 0 15px;
    height: 32px;
    border-top: 1px solid #dde2e9;
    border-left: 2px solid #dde2e9;
 }
  .checkingloader span {
    color: #99a2b2;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
 }
.pluginFooterLoader .checkingloader.trinkaB2BEditor{
    min-width: 361px !important;
    width: auto;
    bottom: 0px;
    left: 0px;
    border: none;
}

  .dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #99a2b2;
    color: #99a2b2;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin-left: 15px;
    top: 0px;
 }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
 }
  .dot-flashing::before {
    left: -7px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #99a2b2;
    color: #99a2b2;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
 }
  .dot-flashing::after {
    left: 7px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #99a2b2;
    color: #99a2b2;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
 }
  @keyframes dot-flashing {
    0% {
      background-color: #99a2b2;
   }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
   }
 }

  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanelFooter.prototype, "limitExhausted", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanelFooter.prototype, "loginRequired", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanelFooter.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], DraggablePanelFooter.prototype, "userType", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], DraggablePanelFooter.prototype, "classesForLimitExhaustSection", void 0);
exports.DraggablePanelFooter = DraggablePanelFooter = __decorate([
    (0, decorators_js_1.customElement)('draggable-panel-footer')
], DraggablePanelFooter);
