"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Consistency = void 0;
const consistency_ui_1 = require("./consistency-ui");
const utils_1 = require("../helpers/utils");
const constants_1 = require("../helpers/constants");
const cc_underline_1 = require("../components/cc-underline");
class Consistency {
    constructor(consistencyModuleConfig, uiConfig, selector, cloudConfig = undefined) {
        this.name = `consistency`;
        this.cloudConfig = undefined;
        this.boundHandleInputEvent = ($event) => this.handleInputEvent($event);
        this.alertCardRadioButtonSelectionEvent = ($event) => this.alertCardRadioButtonSelection($event);
        this.applySelectedVarientEvent = ($event) => this.applySelectedVarient($event);
        this.alertCardNavigationEvent = ($event) => this.alertCardNavigation($event);
        this.dataGroupedByText = [];
        this.groupByKey = (data, key) => {
            return data.reduce((result, currentValue) => {
                currentValue['checked'] = false;
                currentValue['alertId'] = (0, utils_1.generateRandomId)(10);
                (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
                return result;
            }, {});
        };
        this.consistencyModuleConfig = consistencyModuleConfig;
        this.cloudConfig = cloudConfig;
        this.uiConfig = uiConfig;
        this.selector = selector;
        this.consistencyUI = new consistency_ui_1.ConsistencyUI(this.consistencyModuleConfig, this.uiConfig);
        this.initialize();
    }
    destroy() {
        this.consistencyUI.destroy();
        this.removeEventListeners();
    }
    removeEventListeners() {
        document.removeEventListener('tsdk-cc-editor-input-event', this.boundHandleInputEvent);
        document.removeEventListener('cc-alert-radio-selection', this.alertCardRadioButtonSelectionEvent);
        document.removeEventListener('cc-alert-apply-varient-selection', this.applySelectedVarientEvent);
        document.removeEventListener('cc-alert-card-navigation', this.alertCardNavigationEvent);
    }
    updateConsistencyConfig(config, rescanRequired = true) {
        if (!config)
            return;
        this.consistencyModuleConfig = Object.assign(Object.assign({}, this.consistencyModuleConfig), config);
        if (rescanRequired) {
            Object.keys(this.consistencyUI.listOfEditors).forEach((editorId) => {
                const editorInstance = this.consistencyUI.listOfEditors[editorId];
                Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
                    this.removeExistingSegment(editorInstance, text);
                });
                editorInstance.editorDOMTreeNodes = {};
                this.startEditorScanning(editorInstance);
            });
        }
        else {
            Object.keys(this.consistencyUI.listOfEditors).forEach((editorId) => {
                const editorInstance = this.consistencyUI.listOfEditors[editorId];
                this.consistencyUI.applyFilter(editorInstance);
            });
        }
    }
    initialize() {
        const editableElements = document.getElementById(this.consistencyModuleConfig.editorId);
        if (editableElements) {
            this.consistencyUI.initialize([editableElements], this.consistencyModuleConfig.editorId);
            this.registerEventListeners();
            this.startAllEditorScanning();
        }
    }
    registerEventListeners() {
        document.addEventListener('tsdk-cc-editor-input-event', this.boundHandleInputEvent);
        document.addEventListener('cc-alert-radio-selection', this.alertCardRadioButtonSelectionEvent);
        document.addEventListener('cc-alert-apply-varient-selection', this.applySelectedVarientEvent);
        document.addEventListener('cc-alert-card-navigation', this.alertCardNavigationEvent);
    }
    handleInputEvent($event) {
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.consistencyUI.listOfEditors[$event.detail.editorId];
        if (!editorInstance)
            return;
        this.startEditorScanning(editorInstance);
    }
    startAllEditorScanning() {
        Object.keys(this.consistencyUI.listOfEditors).forEach(editorId => {
            const editorInstance = this.consistencyUI.listOfEditors[editorId];
            this.startEditorScanning(editorInstance);
        });
    }
    alertCardRadioButtonSelection($event) {
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.consistencyUI.listOfEditors[$event.detail.editorId];
        if (!editorInstance)
            return;
        this.removeEditorUnderLines(editorInstance);
        if (!$event.detail.isOpen)
            return;
        $event.detail.data.alerts.forEach(element => {
            var segment = editorInstance.editorDOMTreeNodes[Object.keys(editorInstance.editorDOMTreeNodes)[element.paragraph - 1]];
            var undrLine = editorInstance.underlines.get(element.alertId);
            if (!undrLine) {
                const underlineInstance = new cc_underline_1.CcUnderline(editorInstance.editor, editorInstance.editorId, editorInstance.components.editorOverlayInstance.editorOverlay, editorInstance.components.draggablePanel, segment, element, this.uiConfig);
                editorInstance.underlines.set(element.alertId, underlineInstance);
                editorInstance.underlines.forEach(underline => {
                    underline.underlines.forEach(selUnd => {
                        editorInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(selUnd);
                    });
                });
            }
        });
        const alertsList = $event.detail.data.alerts.sort((a, b) => {
            if (a.paragraph !== b.paragraph) {
                return a.paragraph - b.paragraph;
            }
            else {
                return a.begin - b.begin;
            }
        });
        if (alertsList.length && editorInstance.underlines.has(alertsList[0].alertId)) {
            const alertId = alertsList[0].alertId;
            const underlines = editorInstance.underlines.get(alertId);
            underlines.selectUnderline();
            const underline = editorInstance.underlines.get(alertId).underlines[0];
            if (!underline)
                return;
            if ((0, utils_1.isUnderlineInViewport)(editorInstance.editor, underline)) {
                return;
            }
            const underlineRect = underline.getBoundingClientRect();
            const editorRect = editorInstance.editor.getBoundingClientRect();
            const top = underlineRect.top - editorRect.top + editorInstance.editor.scrollTop;
            editorInstance.editor.scrollTop = Math.abs(top);
        }
    }
    ;
    applySelectedVarient($event) {
        const editorInstance = this.consistencyUI.listOfEditors[$event.detail.editorId];
        switch ($event.detail.data.alertType) {
            case 1:
                this.removeEditorUnderLines(editorInstance);
                var paragraphSortedList = $event.detail.data.listToCorrect.sort((a, b) => b.begin - a.begin);
                paragraphSortedList.forEach(element => {
                    var segment = editorInstance.editorDOMTreeNodes[Object.keys(editorInstance.editorDOMTreeNodes)[element.paragraph - 1]];
                    const underlineInstance = new cc_underline_1.CcUnderline(editorInstance.editor, editorInstance.editorId, editorInstance.components.editorOverlayInstance.editorOverlay, editorInstance.components.draggablePanel, segment, element, this.uiConfig);
                    editorInstance.underlines.set(element.alertId, underlineInstance);
                    editorInstance.underlines.forEach(underline => {
                        underline.underlines.forEach(selUnd => {
                            editorInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(selUnd);
                        });
                    });
                    try {
                        editorInstance.underlines.get(element.alertId).acceptAlert($event.detail.data.correction);
                        (0, utils_1.addEventToTracingLog)('accept_consistency');
                    }
                    catch (error) {
                        console.log(error);
                    }
                });
                break;
            case 2:
                var tempList = $event.detail.data.listToCorrect.sort((a, b) => b.begin - a.begin);
                tempList.forEach(element => {
                    if (editorInstance.underlines.has(element.alertId)) {
                        editorInstance.underlines.get(element.alertId).acceptAlert(element.correction);
                        (0, utils_1.addEventToTracingLog)('accept_consistency');
                    }
                });
                break;
            default:
                break;
        }
        this.removeEditorUnderLines(editorInstance);
        Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
            delete editorInstance.editorDOMTreeNodes[text];
        });
        editorInstance.components.draggablePanel.alertList = [];
        editorInstance.components.draggablePanel.filteredAlertList = [];
        this.consistencyUI.applyFilter(editorInstance);
        this.startEditorScanning(editorInstance);
    }
    alertCardNavigation($event) {
        const editorId = $event.detail.editorId;
        const alertId = $event.detail.alertId;
        const editorInstance = this.consistencyUI.listOfEditors[editorId];
        if (!editorInstance)
            return;
        for (let underlineId of editorInstance.underlines.keys()) {
            editorInstance.underlines.get(underlineId).unselectUnderline();
        }
        editorInstance.underlines.get(alertId).selectUnderline();
        const underline = editorInstance.underlines.get(alertId).underlines[0];
        if (!underline)
            return;
        if ((0, utils_1.isUnderlineInViewport)(editorInstance.editor, underline)) {
            return;
        }
        const underlineRect = underline.getBoundingClientRect();
        const editorRect = editorInstance.editor.getBoundingClientRect();
        const top = underlineRect.top - editorRect.top + editorInstance.editor.scrollTop;
        editorInstance.editor.scrollTop = Math.abs(top);
    }
    startEditorScanning(editorInstance) {
        editorInstance.components.draggablePanel.isEditorEmpty = (0, utils_1.checkIfEditorIsEmpty)(editorInstance.editor);
        switch (editorInstance.editorType) {
            case 'input':
            case 'textarea': {
                break;
            }
            case 'div': {
                editorInstance.components.draggablePanel.alertList = [];
                editorInstance.components.draggablePanel.filteredAlertList = [];
                this.consistencyUI.applyFilter(editorInstance);
                this.generateEditorDOMStructure(editorInstance);
                if (!Object.keys(editorInstance.editorDOMTreeNodes).length) {
                    this.consistencyUI.hideLoader(editorInstance);
                    return;
                }
                const isScanRequired = !!Object.values(editorInstance.editorDOMTreeNodes).filter((node) => node.updateRequired).length;
                if (!isScanRequired)
                    return;
                this.consistencyUI.showLoader(editorInstance);
                setTimeout(() => {
                    editorInstance.components.draggablePanel.open();
                    this.callConsistencyCheckerAPI(Object.keys(editorInstance.editorDOMTreeNodes || []), editorInstance);
                }, 300);
                break;
            }
        }
    }
    generateEditorDOMStructure(editorInstance) {
        var _a, _b, _c, _d, _e;
        let latestContenteditableDOM = {};
        let childNodesArr = [];
        if (((_a = editorInstance.editor) === null || _a === void 0 ? void 0 : _a.ckeditorInstance) && ((_b = editorInstance.editor.childNodes[0]) === null || _b === void 0 ? void 0 : _b.nodeName) === 'DIV' && editorInstance.editor.childNodes[0].getAttribute('data-placeholder')) {
            for (let i = 0; i < editorInstance.editor.childNodes.length; i++) {
                if (!((_d = (_c = editorInstance.editor.childNodes[i]) === null || _c === void 0 ? void 0 : _c.classList) === null || _d === void 0 ? void 0 : _d.contains('page-break')))
                    childNodesArr === null || childNodesArr === void 0 ? void 0 : childNodesArr.push(...(_e = editorInstance.editor.childNodes[i]) === null || _e === void 0 ? void 0 : _e.childNodes);
            }
        }
        else
            childNodesArr = [...editorInstance.editor.childNodes];
        childNodesArr === null || childNodesArr === void 0 ? void 0 : childNodesArr.forEach((childNode) => {
            const text = childNode.textContent.trimEnd() || '';
            if (text) {
                latestContenteditableDOM[text] = true;
                if (!editorInstance.editorDOMTreeNodes[text]) {
                    this.addNewSegment(editorInstance, childNode);
                }
                else {
                    if (childNode.nodeName !== '#text') {
                        editorInstance.editorDOMTreeNodes[text].duplicateSegments.push(childNode);
                    }
                    const latestTextNodes = this.extractTextNodes(childNode, []);
                    editorInstance.editorDOMTreeNodes[text].textNodes = latestTextNodes;
                }
            }
        });
        Object.keys(editorInstance.editorDOMTreeNodes).forEach((text) => {
            if (!latestContenteditableDOM[text]) {
                this.removeExistingSegment(editorInstance, text);
            }
        });
    }
    addNewSegment(editorInstance, childNode) {
        const text = childNode.textContent.trimEnd() || '';
        editorInstance.editorDOMTreeNodeCounter++;
        editorInstance.editorDOMTreeNodes[text] = {
            segmentId: (0, utils_1.generateRandomId)(10),
            nodeId: editorInstance.editorDOMTreeNodeCounter,
            nodeName: childNode.nodeName,
            node: childNode,
            displayType: (0, utils_1.getElementDefaultDisplay)(childNode),
            childNodes: this.extractChildNodes(childNode, []),
            textNodes: this.extractTextNodes(childNode, []),
            updateRequired: true,
            text,
            duplicateSegments: []
        };
    }
    extractChildNodes(node, childNodes = []) {
        node.childNodes.forEach((childNode) => {
            if (childNode.nodeName !== 'BR') {
                childNodes.push(childNode);
                this.extractChildNodes(childNode, childNodes);
            }
        });
        return childNodes;
    }
    extractTextNodes(node, textNodes = []) {
        if (node.nodeName == '#text') {
            textNodes.push(node);
        }
        else {
            node.childNodes.forEach((childNode) => {
                if (childNode.nodeName == '#text') {
                    textNodes.push(childNode);
                }
                else {
                    this.extractTextNodes(childNode, textNodes);
                }
            });
        }
        return textNodes;
    }
    removeExistingSegment(editorInstance, text) {
        delete editorInstance.editorDOMTreeNodes[text];
        this.consistencyUI.applyFilter(editorInstance);
    }
    callConsistencyCheckerAPI(listOfParagraph, editorInstance) {
        var _a;
        var listGroupByMapper = [];
        const API = 'https://uat.channels.trinka.ai/trinka/api/v1/consistency';
        const input = { "text": listOfParagraph, "language": this.consistencyModuleConfig.language, "file_id": this.consistencyModuleConfig.file_id, "lang_code": (_a = this.consistencyModuleConfig) === null || _a === void 0 ? void 0 : _a.langCode };
        fetch(this.consistencyModuleConfig.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.consistencyModuleConfig.token,
                'Cookie': this.consistencyModuleConfig.wips
            },
            credentials: 'include',
            body: JSON.stringify(input)
        })
            .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
            var temp = [];
            temp = data.data.filter((ccitem) => {
                return !['date_format', 'p_value', 'symbol_spacing'].includes(ccitem.module);
            });
            editorInstance.components.draggablePanel.alertList = this.jsonSerialiser(temp);
            editorInstance.components.draggablePanel.filters = this.filterCountAnsDisplay(editorInstance, temp);
            ;
            this.consistencyUI.hideLoader(editorInstance);
            this.consistencyUI.applyFilter(editorInstance);
        })
            .catch(error => {
            this.consistencyUI.hideLoader(editorInstance);
            console.error('There was a problem with the fetch operation:', error);
        });
    }
    filterCountAnsDisplay(editorInstance, list) {
        constants_1.CCFilterCheckList.forEach((element) => {
            if (this.groupByKey(editorInstance.components.draggablePanel.alertList, 'module')[element.name] != undefined) {
                element.errorCount = this.groupByKey(editorInstance.components.draggablePanel.alertList, 'module')[element.name].length;
                element.display = true;
            }
            else {
                element.display = false;
                element.errorCount = 0;
            }
        });
        return constants_1.CCFilterCheckList;
    }
    groupArray(data, flag = false) {
        this.dataGroupedByText = [];
        if (data.type == 1) {
            this.dataGroupedByText = this.groupByKey(data.alerts, 'text');
        }
        else {
            this.dataGroupedByText = this.groupByKey(data.alerts, 'type');
        }
        if (flag) {
            return this.convertJSON(this.dataGroupedByText, data);
        }
        else {
            return Object.keys(this.dataGroupedByText);
        }
    }
    convertJSON(json2, originalObj) {
        const json1 = {};
        var counter = 0;
        for (const key in json2) {
            const alerts = json2[key].map((obj) => {
                if (originalObj.type == 1) {
                    return {
                        text: obj.text,
                        begin: obj.begin,
                        end: obj.end,
                        paragraph: obj.paragraph,
                        overlap: obj.overlap,
                        checked: obj.checked,
                        alertId: obj.alertId
                    };
                }
                else {
                    return {
                        text: obj.text,
                        begin: obj.begin,
                        end: obj.end,
                        paragraph: obj.paragraph,
                        overlap: obj.overlap,
                        checked: obj.checked,
                        alertId: obj.alertId,
                        correction: obj.correction
                    };
                }
            });
            const pagination = json2[key].map((obj) => ({
                alertId: obj.alertId,
                begin: obj.begin,
                paragraph: obj.paragraph
            })).sort((a, b) => {
                if (a.paragraph !== b.paragraph) {
                    return a.paragraph - b.paragraph;
                }
                else {
                    return a.begin - b.begin;
                }
            });
            pagination.forEach((page, index) => {
                page.title = index + 1;
            });
            json1[key] = { alerts, checked: counter == 0 ? true : false, module: originalObj.module, pagination: pagination, currentCounter: 1, alertType: originalObj.type, showAll: false };
            counter++;
        }
        return json1;
    }
    jsonSerialiser(originalJSON) {
        var serialised = originalJSON.map((item) => {
            return { title: item.source, module: item.module, cardState: false, listItem: this.groupArray(item, true), type: item.type };
        });
        return serialised;
    }
    removeEditorUnderLines(editorInstance) {
        for (let underlineId of editorInstance.underlines.keys()) {
            this.removeUnderLineById(editorInstance, underlineId);
        }
    }
    removeUnderLineById(editorInstance, underlineId) {
        if (!editorInstance.underlines.has(underlineId))
            return;
        const underlineInstance = editorInstance.underlines.get(underlineId);
        underlineInstance.remove();
        editorInstance.underlines.delete(underlineId);
    }
    dockRightPanelForCCTrinkaEditor(editorId) {
        if (!editorId)
            return {};
        const editorInstance = this.consistencyUI.listOfEditors[editorId];
        if (!editorInstance)
            return {};
        editorInstance.components.draggablePanel.showDraggablePanelHeader = false;
        editorInstance.components.draggablePanel.setRightPanelForCloud();
        setTimeout(() => {
            editorInstance.components.draggablePanel.open();
        }, 200);
    }
}
exports.Consistency = Consistency;
