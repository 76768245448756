"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentenceWiseAlertsPanel = void 0;
const lit_1 = require("lit");
const unsafe_html_js_1 = require("lit/directives/unsafe-html.js");
const decorators_js_1 = require("lit/decorators.js");
const utils_1 = require("../../helpers/utils");
const environment_1 = require("src/env/environment");
let SentenceWiseAlertsPanel = class SentenceWiseAlertsPanel extends lit_1.LitElement {
    render() {
        var _a;
        return (0, lit_1.html) `
      <div id="cards-container">
        <div id="cards-wrapper">
          ${(_a = this.sentenceWiseFilteredAlertList) === null || _a === void 0 ? void 0 : _a.map((alert, index) => (0, lit_1.html) `
              <div id="sentence-${alert.sentenceId}" class="card card-${index}">
                <div class="card-header" @click=${() => this.toggleCardState(alert.sentenceId)}>
                  <div class="cardHeaderCatName">
                    <div class="alert-info">
                      <span></span>
                      <ul>
                        ${alert.alertsCount.map((alertCount, index) => (0, lit_1.html) `
                        <li>
                          <!-- <span>${alertCount.counter}</span>-->
                          <p>${alertCount.category}</p>
                        </li>
                        `)}
                      </ul>
                      <!-- <li><p>${alert.sentenceId}</p></li> -->
                    </div>
                    <button @click=${(e) => {
            e.stopPropagation();
            this.toggleCardState(alert.sentenceId);
        }}>
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 4.99923L9.22916 5.76172L5.70711 2.23966C5.31658 1.84914 4.68342 1.84914 4.29289 2.23966L0.770825 5.76172L0 4.99923L4.29289 0.706336C4.68342 0.315812 5.31658 0.315813 5.70711 0.706337L10 4.99923Z" fill="#647087"/>
                      </svg>
                    </button>
                  </div>
                  <div class="alert-sentence" data-no-translate>
                    <p>${alert.sentence}</p>
                  </div>
                </div>
                <div class="card-body">
                  <span data-no-translate>
                    ${(0, unsafe_html_js_1.unsafeHTML)(alert.sentenceWithAlerts)}
                  </span>
                  <div id="card-footer">
                    <button class="accept-all" @click=${() => this.alertActions(index, alert.sentenceId, alert.segmentId, 'accept-all')}>Accept All</button>
                    <button class="reject-all" @click=${() => this.alertActions(index, alert.sentenceId, alert.segmentId, 'reject-all')}>Dismiss All</button>
                  </div>
                </div>
              </div>
            `)}
        </div>
      </div>
    `;
    }
    constructor() {
        super();
        this.isCloudTrinka = false;
        this.sentenceWiseFilteredAlertList = [];
        this.trinkaEditorConfig = {};
        this.currentIndexOfSuggestion = 0;
        this.limitExhausted = false;
        this.userUpgradeLink = '';
        this.currentLang = 'en';
        this.scrollIntoViewPromise = async (node, options) => {
            node.scrollIntoView(options);
            return new Promise((resolve) => {
                const intersectionObserver = new IntersectionObserver((entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            resolve(true);
                            intersectionObserver.unobserve(node);
                        }, 0);
                    }
                });
                intersectionObserver.observe(node);
            });
        };
    }
    firstUpdated() {
        const cardsContainer = this.shadowRoot.querySelector(`#cards-container`);
        const cardsWrapper = this.shadowRoot.querySelector(`#cards-wrapper`);
        cardsWrapper === null || cardsWrapper === void 0 ? void 0 : cardsWrapper.addEventListener('mouseover', (event) => {
            const target = event.target;
            const existingPopover = cardsContainer.querySelectorAll(`.sentencewise-custom-popover`);
            existingPopover.forEach((popover) => {
                popover.remove();
            });
            if (target.tagName === 'STRIKETHROUGH-TEXT' || target.tagName === 'REPLACEMENT-TEXT') {
                let popover = document.createElement('div');
                popover.classList.add('sentencewise-custom-popover');
                let hoveredElement = target;
                popover.innerHTML = `
          <div class="popoverContents">
            <svg width="13" height="13" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.25 2.99166C7.8625 2.85416 6.46667 2.78333 5.075 2.78333C4.25 2.78333 3.425 2.82499 2.6 2.90833L1.75 2.99166" stroke="#414E62" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.0415 2.57087L4.13317 2.02504C4.19984 1.62921 4.24984 1.33337 4.954 1.33337H6.04567C6.74984 1.33337 6.804 1.64587 6.8665 2.02921L6.95817 2.57087" stroke="#414E62" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.35433 4.30835L8.0835 8.50418C8.03766 9.15835 8.00016 9.66668 6.83766 9.66668H4.16266C3.00016 9.66668 2.96266 9.15835 2.91683 8.50418L2.646 4.30835" stroke="#414E62" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.8042 7.375H6.1917" stroke="#414E62" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.4585 5.70837H6.54183" stroke="#414E62" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Dismiss
          </div>
        `;
                cardsContainer.appendChild(popover);
                const alert = target.parentElement;
                const sentence = alert.parentElement;
                Object.assign(popover.style, {
                    display: 'none',
                    position: 'absolute',
                    backgroundColor: '#EFF1F5',
                    color: 'white',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    transform: 'translateX(-50%)',
                    border: '0.5px solid #CED5DE',
                    boxShadow: '0px 0px 4px 0px rgba(139, 139, 139, 0.2)',
                    zIndex: '99',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                });
                const arrowSize = '5px';
                const arrow = document.createElement('div');
                Object.assign(arrow.style, {
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    display: 'none',
                    marginLeft: `-${arrowSize}`,
                    borderLeft: `${arrowSize} solid transparent`,
                    borderRight: `${arrowSize} solid transparent`,
                    borderTop: `${arrowSize} solid #FFFFFF`
                });
                popover.appendChild(arrow);
                setTimeout(() => {
                    popover.style.display = 'flex';
                    const isMultiLineAlert = alert.getBoundingClientRect().height > 20;
                    if (!isMultiLineAlert) {
                        Object.assign(popover.style, {
                            top: `${hoveredElement.getBoundingClientRect().top - cardsContainer.getBoundingClientRect().top + cardsContainer.scrollTop - (popover.offsetHeight)}px`,
                            left: `${alert.getBoundingClientRect().left - cardsContainer.getBoundingClientRect().left + (alert.getBoundingClientRect().width / 2)}px`,
                        });
                    }
                    else {
                        const multiLineRange = alert.getClientRects();
                        const multiLineRangeLastElement = multiLineRange[multiLineRange.length - 1];
                        Object.assign(popover.style, {
                            top: `${multiLineRangeLastElement.top - cardsContainer.getBoundingClientRect().top + cardsContainer.scrollTop}px`,
                            left: `${multiLineRangeLastElement.left - cardsContainer.getBoundingClientRect().left + (multiLineRangeLastElement.width + 36)}px`,
                        });
                    }
                }, 0);
                popover.addEventListener('click', ($event) => {
                    const event = new CustomEvent('tsdk-draggable-panel-sentence-wise-card-single-reject', {
                        bubbles: true,
                        composed: true,
                        detail: {
                            editorId: this.editorId,
                            alertId: alert.getAttribute('alert-id'),
                            sentenceId: sentence.id
                        }
                    });
                    document.dispatchEvent(event);
                    if (popover) {
                        popover.remove();
                    }
                });
                target._popover = popover;
            }
        });
        cardsWrapper === null || cardsWrapper === void 0 ? void 0 : cardsWrapper.addEventListener('mouseout', (event) => {
            const target = event.target;
            const relatedTarget = event.relatedTarget;
            if ((relatedTarget != null) && (relatedTarget.nodeName.toLowerCase() !== 'div' && relatedTarget.classList.contains('sentencewise-custom-popover')) && target._popover) {
                target._popover.remove();
                delete target._popover;
            }
        });
        cardsWrapper === null || cardsWrapper === void 0 ? void 0 : cardsWrapper.addEventListener('scroll', () => {
            const popovers = document.querySelectorAll('.sentencewise-custom-popover');
            popovers.forEach(popover => popover.remove());
        });
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    blockOrUnblockSentenceWiseAlertsPanel(isBlock) {
        const cardsContainer = this.shadowRoot.querySelector(`#cards-container`);
        const cardsWrapper = this.shadowRoot.querySelector(`#cards-wrapper`);
        if (isBlock) {
            cardsWrapper.style.pointerEvents = 'none';
        }
        else {
            cardsWrapper.style.pointerEvents = 'auto';
        }
    }
    openCard(card) {
        card.classList.add('active');
    }
    closeCard(card) {
        card.classList.remove('active');
    }
    closeAllCards() {
        const allCards = this.shadowRoot.querySelectorAll(`.card.active`);
        allCards.forEach(card => {
            this.closeCard(card);
        });
    }
    toggleCardState(alertId) {
        const card = this.shadowRoot.querySelector(`#sentence-${alertId}`);
        let isOpen = false;
        if (!card)
            return;
        this.currentIndexOfSuggestion = 0;
        if (!card.classList.contains('active')) {
            isOpen = true;
            this.closeAllCards();
            setTimeout(() => {
                this.openCard(card);
            }, 0);
        }
        else {
            this.closeCard(card);
        }
        const event = new CustomEvent('tsdk-draggable-panel-sentence-wise-card-toggle', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId,
                isOpen
            }
        });
        document.dispatchEvent(event);
    }
    alertActions(index, sentenceId, segmentId, action) {
        const event = new CustomEvent('tsdk-sentence-wise-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                index,
                sentenceId,
                segmentId,
                action
            }
        });
        document.dispatchEvent(event);
        this.sendAmplitudeEvent('Sentence_View_Card_Open', { value: action === 'accept-all' ? 'Accept All' : 'Reject All' });
    }
    scrollToElementById(alertId) {
        const card = this.shadowRoot.querySelector(`#${alertId}`);
        if (card) {
            this.closeAllCards();
            this.openCard(card);
            card.scrollIntoView({ behavior: 'smooth' });
        }
    }
    openSentenceWiseFirstAlert() {
        const alert = this.sentenceWiseFilteredAlertList[0];
        this.toggleCardState(alert.sentenceId);
    }
    sentenceWiseSelectNextAlert(index) {
        const alert = this.sentenceWiseFilteredAlertList[index];
        this.toggleCardState(alert.sentenceId);
    }
    sendAmplitudeEvent(eventName, eventObj) {
        (0, utils_1.triggerAmplitudeEvent)(eventName, eventObj);
    }
};
exports.SentenceWiseAlertsPanel = SentenceWiseAlertsPanel;
SentenceWiseAlertsPanel.styles = (0, lit_1.css) `
    #cards-wrapper {
      position: relative;
    }

    #cards-wrapper-overlay {}

    .card {
      margin-bottom: 12px;
      padding: 12px 12px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.2) !important;
      border-radius: 5px;
      scroll-margin-top: 20px;
    }

    .card.active .card-body {
      display: block;
      transition: all 0.5s ease;
    }

    .card.active .alert-sentence {
      display: none;
    }

    .card-header {
        position: relative;
        cursor: pointer;
    }
    .card-header .cardHeaderCatName{
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
    }
    .card-header .cardHeaderCatName .alert-info{
      position: relative;
      display: flex;
      align-items: center;
    }
    .card-header .cardHeaderCatName .alert-info span{
        position: absolute;
        width: 4px;
        height: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 6px;
        background: rgb(220, 38, 38);
    }
    .card-header .cardHeaderCatName .alert-info ul{
        position: relative;
        list-style: none;
        padding: 0 0 0 10px;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .card-header .cardHeaderCatName .alert-info ul li{
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 6px;
        padding-right: 6px;
        border-right: solid 1px #647087;
    }
    // .card-header .cardHeaderCatName .alert-info ul li::before{
    //   content:'';
    //   position: absolute;
    //   left: auto;
    //   right: -1px;
    //   width: 1px;
    //   height: 9px;
    //   background: #647087;
    // }
    .card-header .cardHeaderCatName .alert-info ul li:last-child{
      margin-right: 0px;
      border-right: none;
    }
    .card-header .cardHeaderCatName .alert-info ul li:last-child::before{
      display: none;
    }
    .card-header .cardHeaderCatName .alert-info ul li span{
        position: relative;
        width: 6px;
        height: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: #FEE2E2;
        border-radius: 50%;
        font-family: "Open Sans", sans-serif;
        // font-size: 9px;
        // font-weight: 600;
        // line-height: 14px;
        // text-align: center;
        // color: #DC2626;
        margin-right: 6px;
    }
    .card-header .cardHeaderCatName .alert-info ul li p{
      color: #647087;
      font-family: "Open Sans", sans-serif;
      font-size: 10px;
      font-weight: 400;
      line-height: 11px;
      text-align: left;
      margin: 0;
    }
    .card-header button{
        position: absolute;
        left: auto;
        right: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        transition: all 0.5s ease;
        display: none;
        transform: rotate(180deg);
    }
    .card:hover .card-header button{
      display: block;
      transition: all 0.5s ease;
    }
    .card.active .card-header button{
        transform: rotate(0deg);
        transition: all 0.5s ease;
        display: block;
    }
    .card-header .alert-sentence{
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 1px;
      width: 90%;
    }
    .card-header .alert-sentence p{
        margin: 0;
        color: #111A27;
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        text-align: left;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .card-body {
      /* overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal; */
      display: none;
      padding: 0px 10px 0 10px;
      line-height: 22px;
      transition: all 0.5s ease;
      width: 88%;
      position: relative;
    }
    .card-body sentence{
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #414E62;
    }
    .card-body #card-footer{
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 24px;
    }

    .accept-all {
      background: #7A28A0;
      border: none;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      padding: 4px 8px;
      color: #FFF;
      margin-right: 8px;
      transition: all 0.5s ease;
      cursor: pointer;
      border-radius: 4px;
    }
    .accept-all:hover{
      background: #6B238D;
      transition: all 0.5s ease;
    }

    .reject-all {
      background: transparent;
      border: none;
      color: #414E62;
      transition: all 0.5s ease;
      cursor: pointer;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      padding: 4px 8px;
      border-radius: 4px;
    }

    .reject-all:hover {
      background: #EFF1F5;
      box-shadow: none;
      transition: all 0.5s ease;
    }

    strikethrough-text {
      display: inline;
      margin: 0px;
      padding: 0px;
      color: rgb(234, 21, 55);
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      text-align: left;
      position: relative;
      cursor: default;
      text-decoration: line-through;
    }

    replacement-text {
      display: inline-block;
      margin: 0px;
      padding: 0px;
      color: rgb(22, 163, 74);
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      text-align: left;
      cursor: default;
      text-decoration: underline;
    }

    #cards-container {
      position: relative;
      max-height: calc(100% - 140px);
      min-height: calc(100% - 140px);
      padding-top: 16px !important;
      padding: 10px 10px 100px 15px;
      margin-top: 0px;
      margin-bottom: 20px;
      overflow-y: auto;
      scrollbar-color: transparent transparent;
      scrollbar-width: thin;
      transition: 0.5s;
    }
    .popoverContents{
      position: relative;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #414E62;
      font-weight: 400;
      font-size: 12px;
      line-height: 16.34px;
      letter-spacing: 0%;
    }
    .popoverContents svg{
      margin-right: 4px;
    }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "isCloudTrinka", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "editorId", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "sentenceWiseFilteredAlertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "trinkaEditorConfig", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "currentIndexOfSuggestion", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "limitExhausted", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], SentenceWiseAlertsPanel.prototype, "userUpgradeLink", void 0);
exports.SentenceWiseAlertsPanel = SentenceWiseAlertsPanel = __decorate([
    (0, decorators_js_1.customElement)('sentence-wise-alerts-panel'),
    __metadata("design:paramtypes", [])
], SentenceWiseAlertsPanel);
