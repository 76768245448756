"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskUI = void 0;
const ask_editor_overlay_1 = require("../components/ask-plugin/ask-editor-overlay");
const constants_1 = require("../helpers/constants");
const utils_1 = require("../helpers/utils");
const ask_selection_1 = require("../components/ask-plugin/ask-selection");
const ask_popover_1 = require("../components/ask-plugin/ask-popover");
const ask_inline_popover_1 = require("../components/ask-plugin/ask-inline-popover");
class AskUI {
    constructor(editor, editorId, config, hostPageInfo, module = constants_1.SDK_MODULES.GRAMMAR) {
        this.currentSelectedMode = constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL;
        this.activeModule = constants_1.SDK_MODULES.GRAMMAR;
        this.editor = editor;
        this.editorId = editorId;
        this.config = config;
        this.hostPageInfo = hostPageInfo;
        this.activeModule = module;
    }
    destroy() {
        this.removeEditorEventListeners(this.askInstance);
        this.removeEditorComponents(this.askInstance);
        this.cleanUpForEditor(this.askInstance);
    }
    cleanUpForEditor(askInstance) {
        delete this.askInstance;
    }
    generateListOfEvents(askInstance, editorId) {
        const events = {
            'input': {
                name: 'input',
                boundHandler: (0, utils_1.debounce)(($event) => this._handleInputEvent($event, askInstance), constants_1.EDITOR_CHANGE_DEBOUNCE_TIME)
            },
            'focus': {
                name: 'focus',
                boundHandler: ($event) => this._handleFocusEvent($event, askInstance)
            },
            'scroll': {
                name: 'scroll',
                boundHandler: ($event) => this._handleScrollEvent($event, askInstance)
            },
            'keydown': {
                name: 'keydown',
                boundHandler: ($event) => this._handleKeydownEvent($event, askInstance)
            },
            'click': {
                name: 'click',
                boundHandler: ($event) => this._handleClickEvent($event, askInstance)
            }
        };
        let listOfEvents = [];
        Object.values(events).forEach(event => {
            listOfEvents.push({
                editorId,
                element: askInstance.editor,
                event: event.name,
                listener: event.boundHandler
            });
        });
        return listOfEvents;
    }
    initialize(editor, editorId) {
        this.askInstance = this.onBoardEditor(editorId, editor);
        this.askInstance.listOfEvents = this.generateListOfEvents(this.askInstance, editorId);
        const editorElement = this.askInstance.editor;
        const debounceHandleCKEditorInput = (0, utils_1.debounce)(($event) => {
            if (!this.askInstance)
                return;
            const editor = this.askInstance.editor;
            const selection = editor.ckeditorInstance.model.document.selection;
            const range = selection.getFirstRange();
            if (!range)
                return;
            let text = '';
            const walker = range.getWalker();
            for (const value of walker) {
                if (value.item.is('text')) {
                    text += value.item.data;
                }
            }
            const commandPattern = /(?<!\S)\/ask(?!\S)/;
            const match = text.match(commandPattern);
            if (match) {
                editor.ckeditorInstance.model.change(writer => {
                    const position = selection.getFirstPosition();
                    const start = position.getShiftedBy(-5);
                    const rangeToDelete = writer.createRange(start, position);
                    writer.remove(rangeToDelete);
                });
            }
            return this._handleInputEvent($event, this.askInstance);
        }, 0);
        if (editorElement.ckeditorInstance) {
            editorElement.ckeditorInstance.model.document.on('change:data', ($event) => {
                debounceHandleCKEditorInput();
            });
        }
        this.registerEditorEventListeners(this.askInstance);
    }
    onBoardEditor(editorId, editor) {
        editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorId);
        editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
        const askPopover = new ask_popover_1.AskPopover(editorId, this.config, this.hostPageInfo.isCloudTrinka);
        const askInlinePopover = new ask_inline_popover_1.AskInlinePopover(editorId, this.config, this.hostPageInfo.isCloudTrinka);
        const askEditorOverlayInstance = new ask_editor_overlay_1.AskEditorOverlay(editorId, editor, this.hostPageInfo.isCloudTrinka);
        const askSelection = new ask_selection_1.AskSelection(askEditorOverlayInstance.editorOverlayElements);
        const askInstance = {
            editorId,
            editor,
            editorType: editor.nodeName.toLowerCase(),
            listOfEvents: [],
            components: {
                askPopover,
                askInlinePopover,
                askSelection,
                editorOverlayInstance: askEditorOverlayInstance
            }
        };
        return askInstance;
    }
    scanForCommand(editableElement, commandPatterns) {
        const selection = window.getSelection();
        if (!selection.rangeCount)
            return null;
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        let matchedCommand = null;
        for (const commandPattern of commandPatterns) {
            const match = commandPattern.exec(selectedText) || commandPattern.exec(selection.anchorNode.textContent);
            if (match) {
                matchedCommand = match[0];
                const anchorNode = selection.anchorNode;
                if (commandPattern.test(anchorNode.nodeValue)) {
                    anchorNode.nodeValue = anchorNode.nodeValue.replace(commandPattern, '');
                }
                else {
                    const walker = document.createTreeWalker(editableElement, NodeFilter.SHOW_TEXT, null);
                    while (walker.nextNode()) {
                        const textNode = walker.currentNode;
                        if (commandPattern.test(textNode.nodeValue)) {
                            textNode.nodeValue = textNode.nodeValue.replace(commandPattern, '');
                            break;
                        }
                    }
                }
                const newRange = document.createRange();
                if (anchorNode.nodeType === Node.TEXT_NODE) {
                    const textNodeLength = anchorNode.nodeValue.length;
                    newRange.setStart(anchorNode, textNodeLength);
                    newRange.setEnd(anchorNode, textNodeLength);
                }
                else {
                    newRange.selectNodeContents(anchorNode);
                    newRange.collapse(false);
                }
                selection.removeAllRanges();
                selection.addRange(newRange);
                return matchedCommand;
            }
        }
        return null;
    }
    clearEditorSelection(askInstance) {
        console.log('clearEditorSelection ===> ', askInstance);
        if (!askInstance)
            return;
        askInstance.components.askSelection.clearHighlights();
        askInstance.components.askPopover.selectedText = '';
        askInstance.components.askPopover.close();
        askInstance.components.askInlinePopover.close();
    }
    _handleScrollEvent($event, askInstance) {
        if (!askInstance)
            return;
        askInstance.components.editorOverlayInstance.handleScrollEvent();
        const event = new CustomEvent('tsdk-ask-editor-scroll-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: askInstance.editorId
            }
        });
        document.dispatchEvent(event);
    }
    _handleKeydownEvent($event, askInstance) {
        if (!askInstance)
            return;
        const keyboardEvent = event;
        const key = keyboardEvent.key.toLowerCase();
        switch (key) {
            case 'enter':
            case 'backspace':
            case 'delete': {
                this.clearEditorSelection(askInstance);
                break;
            }
        }
    }
    _handleFocusEvent($event, askInstance) {
        if (!askInstance)
            return;
        askInstance.components.editorOverlayInstance.editorOverlayElements.innerHTML = '';
        askInstance.components.askInlinePopover.close();
    }
    async _handleInputEvent($event, askInstance) {
        if (!askInstance)
            return;
        if (this.currentSelectedMode !== constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL)
            return;
        const commandPatterns = [/(?<!\S)\/ask(?!\S)/, /(?<!\S)\/test(?!\S)/];
        const command = this.scanForCommand(askInstance.editor, commandPatterns);
        if (command) {
            switch (command) {
                case '/ask': {
                    const containerBounds = askInstance.components.editorOverlayInstance.editorOverlayElements.getBoundingClientRect();
                    const editor = askInstance.editor;
                    if (this.hostPageInfo.supportedEditorsLibraries.ckeditor5.detected) {
                        const paragraphsBeforeCursor = this.getTwoParagraphsBeforeCursorV4(askInstance);
                        const view = editor.ckeditorInstance.editing.view;
                        const domConverter = editor.ckeditorInstance.editing.view.domConverter;
                        const viewDocument = view.document;
                        const model = editor.ckeditorInstance.model;
                        const selection = model.document.selection;
                        if (selection.isCollapsed) {
                            const position = selection.getFirstPosition();
                            model.change(writer => {
                                const start = position.getShiftedBy(-command.length);
                                const range = writer.createRange(start, position);
                                writer.remove(range);
                            });
                            let popoverPosition = this._getPopoverPosition(askInstance, containerBounds);
                            await askInstance.components.askInlinePopover.open(this.activeModule, popoverPosition, paragraphsBeforeCursor);
                            (0, utils_1.triggerAmplitudeEvent)('ask_command', {});
                        }
                        else {
                            console.warn('Selection is not collapsed; cannot identify command position precisely.');
                        }
                    }
                    else {
                        const selection = window.getSelection();
                        if (!selection.rangeCount) {
                            console.error("No selection or caret is available.");
                            return;
                        }
                        const range = selection.getRangeAt(0);
                        if (!selection.isCollapsed) {
                            console.error("The selection is not collapsed (i.e., there is a text selection).");
                            return;
                        }
                        if (range && range.getClientRects().length > 0) {
                            const boundingRect = JSON.parse(JSON.stringify(range.getBoundingClientRect()));
                            askInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(askInstance.components.askInlinePopover);
                            let position = boundingRect;
                            position['top'] = boundingRect.top - containerBounds.top + askInstance.components.editorOverlayInstance.editorOverlayElements.scrollTop + boundingRect.height;
                            await askInstance.components.askInlinePopover.open(this.activeModule, position, []);
                        }
                        else if (range.startOffset === 0 && range.endOffset === 0 && range.startContainer) {
                            const boundingRect = JSON.parse(JSON.stringify(range.startContainer.parentElement.getBoundingClientRect()));
                            askInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(askInstance.components.askInlinePopover);
                            let position = boundingRect;
                            position['top'] = boundingRect.top - containerBounds.top + askInstance.components.editorOverlayInstance.editorOverlayElements.scrollTop;
                            await askInstance.components.askInlinePopover.open(this.activeModule, position, []);
                        }
                    }
                    break;
                }
                default: {
                    console.warn(`[DEBUG] command is not supported ===> ${command}`);
                    break;
                }
            }
        }
    }
    _handleClickEvent($event, askInstance) {
        if (!askInstance)
            return;
        if (this.hostPageInfo.supportedEditorsLibraries.ckeditor5.detected) {
            const spellCheckInterval = setInterval(() => {
                this._updateCKEditor5Attributes(askInstance);
            }, 100);
            setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
        }
    }
    _updateCKEditor5Attributes(editorInstance) {
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorInstance.editorId);
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
    }
    getTwoParagraphsBeforeCursorv1(askInstance) {
        const editor = askInstance.editor;
        const model = editor.ckeditorInstance.model;
        const selection = model.document.selection;
        if (!selection.isCollapsed) {
            return [];
        }
        const position = selection.getFirstPosition();
        if (!position || position.isAtStart) {
            return [];
        }
        const paragraphs = [];
        let currentPosition = position;
        while (paragraphs.length < 2 && currentPosition) {
            const previousParagraph = currentPosition.parent.previousSibling;
            if (previousParagraph && previousParagraph.is('element', 'paragraph') && this.hasTextContent(previousParagraph)) {
                paragraphs.push(previousParagraph);
            }
            currentPosition = previousParagraph ? model.createPositionBefore(previousParagraph) : null;
        }
        return paragraphs;
    }
    getTwoParagraphsBeforeCursor(askInstance) {
        const editor = askInstance.editor;
        const model = editor.ckeditorInstance.model;
        const selection = model.document.selection;
        if (!selection.isCollapsed) {
            return [];
        }
        const position = selection.getFirstPosition();
        if (!position || position.isAtStart) {
            return [];
        }
        const elements = [];
        let currentPosition = position;
        while (currentPosition) {
            const parent = currentPosition.parent;
            const previousElement = parent.previousSibling;
            if (!previousElement) {
                break;
            }
            elements.unshift(previousElement);
            currentPosition = model.createPositionBefore(previousElement);
        }
        return elements;
    }
    getTwoParagraphsBeforeCursorV3(askInstance) {
        var _a;
        const editor = askInstance.editor;
        const model = editor.ckeditorInstance.model;
        const document = model.document;
        const selection = document.selection;
        const previousElementsText = [];
        if (selection.isCollapsed) {
            const position = selection.getFirstPosition();
            let currentElement = position.parent;
            while (currentElement && previousElementsText.length < 2) {
                const prevSibling = currentElement.previousSibling;
                if (prevSibling) {
                    if (this.hasTextContent(prevSibling)) {
                        const paragraph = ((_a = prevSibling.getChild(0)) === null || _a === void 0 ? void 0 : _a.data) || '';
                        previousElementsText.push(paragraph.trim());
                    }
                    currentElement = prevSibling;
                }
                else {
                    currentElement = currentElement.parent;
                }
            }
        }
        return previousElementsText;
    }
    getTwoParagraphsBeforeCursorV4(askInstance) {
        var _a, _b;
        const editor = askInstance.editor;
        const model = editor.ckeditorInstance.model;
        const document = model.document;
        const selection = document.selection;
        const paragraphsText = [];
        if (selection.isCollapsed) {
            const position = selection.getFirstPosition();
            let currentElement = position.parent;
            let totalWordCount = 0;
            const countWords = (text) => {
                return text.trim().split(/\s+/).length;
            };
            while (currentElement && totalWordCount < 400 && paragraphsText.length < 2) {
                if (this.hasTextContent(currentElement)) {
                    const paragraphText = ((_a = currentElement.getChild(0)) === null || _a === void 0 ? void 0 : _a.data) || '';
                    const paragraphWordCount = countWords(paragraphText);
                    if (totalWordCount + paragraphWordCount > 400) {
                        break;
                    }
                    paragraphsText.push(paragraphText.trim());
                    totalWordCount += paragraphWordCount;
                }
                currentElement = currentElement.previousSibling;
            }
            if (!paragraphsText.length) {
                currentElement = position.parent;
                const paragraphText = ((_b = currentElement.getChild(0)) === null || _b === void 0 ? void 0 : _b.data) || '';
                paragraphsText.push(paragraphText.trim());
            }
        }
        return paragraphsText;
    }
    hasTextContent(element) {
        var _a;
        const text = ((_a = element.getChild(0)) === null || _a === void 0 ? void 0 : _a.data) || '';
        return text.trim().length > 0;
    }
    _getPopoverPosition(askInstance, containerBounds) {
        const editor = askInstance.editor;
        const view = editor.ckeditorInstance.editing.view;
        const domConverter = editor.ckeditorInstance.editing.view.domConverter;
        const viewDocument = view.document;
        const position = viewDocument.selection.getFirstPosition();
        const range = view.createRange(position);
        const domRange = domConverter.viewRangeToDom(range);
        if (domRange && domRange.getClientRects().length > 0) {
            const rect = domRange.getClientRects()[0];
            askInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(askInstance.components.askInlinePopover);
            let position = JSON.parse(JSON.stringify(rect));
            position['top'] = rect.top - containerBounds.top + askInstance.components.editorOverlayInstance.editorOverlayElements.scrollTop + rect.height;
            return position;
        }
        else if (domRange.startOffset === 0 && domRange.endOffset === 0 && domRange.startContainer) {
            const rect = domRange.startContainer.getBoundingClientRect();
            askInstance.components.editorOverlayInstance.editorOverlayElements.appendChild(askInstance.components.askInlinePopover);
            let position = JSON.parse(JSON.stringify(rect));
            position['top'] = rect.top - containerBounds.top + askInstance.components.editorOverlayInstance.editorOverlayElements.scrollTop + rect.height;
            return position;
        }
    }
    removeEditorComponents(askInstance) {
        Object.keys(askInstance.components).forEach((componentName) => {
            askInstance.components[componentName].remove();
        });
    }
    removeEditorEventListeners(askInstance) {
        askInstance.listOfEvents.forEach(({ element, event, listener }) => {
            element.removeEventListener(event, listener, { passive: true });
        });
    }
    registerEditorEventListeners(askInstance) {
        askInstance.listOfEvents.forEach(({ element, event, listener }) => {
            element.addEventListener(event, listener, { passive: true });
        });
    }
}
exports.AskUI = AskUI;
