"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParaphraserModal = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let ParaphraserModal = class ParaphraserModal extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.cardArr = [{ isChecked: true }, { isChecked: true }, { isChecked: true }, { isChecked: true }, { isChecked: true }];
        this.showLanguageDropDown = false;
        this.currentLanguage = 'EN';
        this.currentTabNumber = 0;
        this.currentLang = 'en';
    }
    render() {
        return (0, lit_1.html) `
      <div class="modal" @click=${this._closeModal}>
        <div class="modal-content" @click=${this._stopPropagation}>

          <div class="modal-header">
            <h2>Know more about Paraphrasing Modes</h2>
            <button @click=${this._closeModal} class="close-button">
              <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="#414E62"/>
              </svg>
            </button>
          </div>

          <div class="modal-body">
            <p>We have introduced four modes in paraphraser to  help you meet  your writing goals. Explore the examples below to find the mode that matches your desired tone and style.</p>
            <div class="headingWithLanguageDropDown">
              <h3>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.1693 8.68417H5.9326M5.9326 8.68417C5.9326 9.06962 5.77882 9.43928 5.50627 9.71183C5.23371 9.98439 4.86405 10.1375 4.4786 10.1375C4.09316 10.1375 3.7235 9.98439 3.45094 9.71183C3.17839 9.43928 3.02527 9.06962 3.02527 8.68417M5.9326 8.68417C5.9326 8.29872 5.77882 7.92906 5.50627 7.65651C5.23371 7.38396 4.86405 7.23084 4.4786 7.23084C4.09316 7.23084 3.7235 7.38396 3.45094 7.65651C3.17839 7.92906 3.02527 8.29872 3.02527 8.68417M3.02527 8.68417H1.83594M14.1693 13.0888H10.3373M10.3373 13.0888C10.3373 13.4744 10.1838 13.8445 9.91117 14.1171C9.63855 14.3897 9.26881 14.5428 8.88327 14.5428C8.49782 14.5428 8.12816 14.3891 7.85561 14.1165C7.58306 13.8439 7.42994 13.4743 7.42994 13.0888M10.3373 13.0888C10.3373 12.7033 10.1838 12.3339 9.91117 12.0613C9.63855 11.7887 9.26881 11.6355 8.88327 11.6355C8.49782 11.6355 8.12816 11.7886 7.85561 12.0612C7.58306 12.3337 7.42994 12.7034 7.42994 13.0888M7.42994 13.0888H1.83594M14.1693 4.27951H12.0993M12.0993 4.27951C12.0993 4.47036 12.061 4.65935 11.988 4.83567C11.9149 5.012 11.8079 5.17221 11.6729 5.30717C11.538 5.44212 11.3778 5.54917 11.2014 5.62221C11.0251 5.69525 10.8361 5.73284 10.6453 5.73284C10.2598 5.73284 9.89016 5.57972 9.61761 5.30717C9.34506 5.03461 9.19194 4.66495 9.19194 4.27951M12.0993 4.27951C12.0993 4.08865 12.061 3.89967 11.988 3.72334C11.9149 3.54701 11.8079 3.3868 11.6729 3.25184C11.538 3.11689 11.3778 3.00984 11.2014 2.9368C11.0251 2.86376 10.8361 2.82617 10.6453 2.82617C10.2598 2.82617 9.89016 2.97929 9.61761 3.25184C9.34506 3.5244 9.19194 3.89406 9.19194 4.27951M9.19194 4.27951H1.83594" stroke="#7A28A0" stroke-miterlimit="10" stroke-linecap="round"/>
                </svg>
                Paraphrasing Modes:
              </h3>
              <div class="selectLanguageDropdown" @click="${() => this.showLanguageDropDown = !this.showLanguageDropDown}">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.0025997 4.80025 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C11.9982 4.40926 11.3654 2.8842 10.2406 1.75938C9.1158 0.634561 7.59074 0.0018323 6 0ZM11.055 5.53846H8.75712C8.65385 3.43211 7.84269 1.9425 7.1775 1.06154C8.21035 1.30957 9.13968 1.87464 9.83515 2.67751C10.5306 3.48038 10.9568 4.48079 11.055 5.53846ZM4.16712 6.46154H7.83289C7.69962 8.86211 6.55039 10.3333 6 10.9038C5.44904 10.3327 4.30039 8.86154 4.16712 6.46154ZM4.16712 5.53846C4.30039 3.13788 5.44962 1.66673 6 1.09615C6.55096 1.66904 7.69962 3.14019 7.83289 5.53846H4.16712ZM4.8225 1.06154C4.15731 1.9425 3.34616 3.43211 3.24289 5.53846H0.944425C1.04261 4.48079 1.46937 3.48038 2.16485 2.67751C2.86033 1.87464 3.78965 1.30957 4.8225 1.06154ZM0.944425 6.46154H3.24289C3.34789 8.56788 4.15731 10.0575 4.8225 10.9385C3.78965 10.6904 2.86033 10.1254 2.16485 9.32249C1.46937 8.51961 1.04261 7.5192 0.944425 6.46154ZM7.17519 10.9385C7.84039 10.0575 8.64981 8.56788 8.75481 6.46154H11.0533C10.9552 7.5189 10.5288 8.51908 9.83377 9.32192C9.13874 10.1248 8.20992 10.69 7.1775 10.9385H7.17519Z" fill="#414E62"/>
                </svg>
                <span>
                  ${this.currentLanguage}
                  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.8461 1.63063L4.36901 5.10772C4.32055 5.15635 4.26297 5.19493 4.19958 5.22125C4.13618 5.24757 4.06821 5.26113 3.99956 5.26113C3.93092 5.26113 3.86295 5.24757 3.79955 5.22125C3.73616 5.19493 3.67858 5.15635 3.63012 5.10772L0.153028 1.63063C0.0550458 1.53264 -1.46004e-09 1.39975 0 1.26118C1.46005e-09 1.12262 0.0550458 0.989725 0.153028 0.891744C0.25101 0.793762 0.383902 0.738716 0.522469 0.738716C0.661037 0.738716 0.793929 0.793762 0.891911 0.891744L4 3.99983L7.10809 0.891309C7.20607 0.793327 7.33896 0.738281 7.47753 0.738281C7.6161 0.738281 7.74899 0.793327 7.84697 0.891309C7.94495 0.989291 8 1.12218 8 1.26075C8 1.39932 7.94495 1.53221 7.84697 1.63019L7.8461 1.63063Z" fill="black"/>
                  </svg>
                </span>
                <div class="dropDownLanguagecontents ${this.showLanguageDropDown ? 'showLangDropDown' : ''}">
                  <h4>Select language</h4>
                  <ul>
                    <li @click=${(event) => {
            event.stopPropagation();
            this.changeSampleLanguage('EN');
        }}>
                      <input type="radio" name="languageType" value="en" id="englishLanguage" .checked=${this.currentLanguage === 'EN'} />
                      <label for="englishLanguage">English (EN)</label>
                    </li>
                    <li @click=${(event) => {
            event.stopPropagation();
            this.changeSampleLanguage('ES');
        }}>
                      <input type="radio" name="languageType" value="es" id="spanishLanguage" .checked=${this.currentLanguage === 'ES'} />
                      <label for="spanishLanguage">Spanish (ES)</label>
                    </li>
                  </ul>
                </div>
                <!-- <select>
                    <option value="en">EN</option>
                    <option value="es">ES</option>
                  </select> -->
              </div>
            </div>
            <div class="Tabs ${this.currentLanguage === 'EN' ? 'isEnglishTabs' : ''}">
              <div class="Tab">
                  <input checked value="A" name="Tabs" type="radio" id="TabA"/>
                  <label class="Header" for="TabA" @click=${() => { this.currentTabNumber = 0; }}> Standard </label>
                  <div class="Content">
                      <h2><span>Defination:</span>Rewrites your text by revising the vocabulary or sentence structure</h2>
                      <h2 class="exampleTitle">
                        <span>Example:</span>
                        <div class="languageAndRevision">
                          <div></div>
                          <div class="resvisionToggle">
                            <label>Show revisions</label>
                            <div class="toggleDiffOption">
                              <label class="switch">
                                <input type="checkbox" id="toggleDiffOptionCheckbox1" name="showRevisions" ?checked=${this.cardArr[0].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(0);
        }}>
                                <span class="slider round">
                                  <i class="sliderTextOn">ON</i>
                                  <i class="sliderTextOff">OFF</i>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </h2>
                      <div class="ExampleContents">
                        <div class="exampleWithText ExampleLeftContents">
                          <h4>Original Text</h4>
                          <p data-no-translate>Infectious disease epidemics cause a decline in screening uptake among asymptomatic individuals, leading to concerns regarding advanced cancer cases and mortality.</p>
                        </div>
                        <div class="exampleWithText ExampleRightContents">
                          <h4>Paraphrased Text</h4>
                          <p style="${this.cardArr[0].isChecked ? 'display: block' : 'display: none'}" data-no-translate>
                          <i class="wrongTextParaphrased">Infectious disease epidemics cause a decline</i>
                          <i class="RightTextParaphrased">Epidemics of infectious diseases result</i>
                          in
                          <i class="wrongTextParaphrased">screening uptake among</i>
                          <i class="RightTextParaphrased">a decrease in the number of</i>
                          asymptomatic individuals,
                          <i class="wrongTextParaphrased">leading</i> who
                          <i class="RightTextParaphrased">undergo screening, giving rise</i>
                          to concerns
                          <i class="wrongTextParaphrased">regarding</i>
                          <i class="RightTextParaphrased">about</i>
                          a rise in advanced cancer
                          <i class="RightTextParaphrased">cases</i>
                          and
                          <i class="wrongTextParaphrased">deaths increased</i>
                          <i class="RightTextParaphrased">mortality.</i></p>
                          <p class="standardExampleOut" style="${this.cardArr[0].isChecked ? 'display: none' : 'display: block'}" data-no-translate>Epidemics of infectious diseases result in a decrease in the number of asymptomatic individuals who undergo screening, giving rise to concerns about a rise in advanced cancer cases and mortality.</p>
                        </div>
                        <div class="exampleArrow">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                            <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                            <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                          </svg>
                        </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=E name=Tabs type=radio id=TabE />
                  <label class=Header for=TabE @click=${() => { this.currentTabNumber = 1; }}> Academic </label>
                    <div class=Content>
                        <h2><span>Defination:</span>Makes your writing appropriate for academic readers and publishers</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                          <div class="languageAndRevision">
                          <div></div>
                          <div class="resvisionToggle">
                            <label>Show revisions</label>
                          <div class="toggleDiffOption">
                            <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox4" name="showRevisions" ?checked=${this.cardArr[4].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(4);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                            </label>
                          </div>
                          </div>
                          </div>
                        </h2>
                        <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents" style="height: 120px">
                            <h4>Original Text</h4>
                            <p data-no-translate>This work could help us figure out how other bugs that live in colonies, like ants and bees, deal with different bio situations in nature</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents" style="height: 120px">
                            <h4>Paraphrased Text</h4>
                            <p style="${this.cardArr[4].isChecked ? 'display: block' : 'display: none'}" data-no-translate>
                            This
                            <i class="wrongTextParaphrased">work</i>
                            <i class="RightTextParaphrased">research</i>
                            <i class="wrongTextParaphrased">could help us figure out</i>
                            <i class="RightTextParaphrased">may contribute to a deeper understanding of</i> how other
                            <i class="wrongTextParaphrased">bugs that live in colonies</i>
                            <i class="RightTextParaphrased">social insects,</i>
                            <i class="wrongTextParaphrased">likes</i>
                            <i class="RightTextParaphrased">such as</i> ants and bees,
                            <i class="wrongTextParaphrased">deal with different</i>
                            <i class="RightTextParaphrased">adapt to diverse</i>
                            <i class="wrongTextParaphrased">bio situation in nature</i>
                            <i class="RightTextParaphrased">environmental conditions in their natural habitats.</i>
                            </p>
                            <p class="formalExampleOut" style="${this.cardArr[4].isChecked ? 'display: none' : 'display: block'}" data-no-translate> This research may contribute to a deeper understanding of how other social insects, such as ants and bees, adapt to diverse environmental conditions in their natural habitats.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=B name=Tabs type=radio id=TabB />
                  <label class=Header for=TabB @click=${() => { this.currentTabNumber = 2; }}> Formal </label>
                    <div class=Content>
                        <h2><span>Defination:</span>Makes your writing tone professional and businesslike</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                          <div class="languageAndRevision">
                          <div></div>
                          <div class="resvisionToggle">
                            <label>Show revisions</label>
                          <div class="toggleDiffOption">
                            <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox2" name="showRevisions" ?checked=${this.cardArr[1].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(1);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                            </label>
                          </div>
                          </div>
                          </div>
                        </h2>
                        <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents" style="height: 120px">
                            <h4>Original Text</h4>
                            <p data-no-translate>Can we move that meeting up with the HR folks to 9:30 am?</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents" style="height: 120px">
                            <h4>Paraphrased Text</h4>
                            <p data-no-translate style="${this.cardArr[1].isChecked ? 'display: block' : 'display: none'}">Can we
                            <i class="wrongTextParaphrased">move that</i>
                            <i class="RightTextParaphrased">reschedule our</i>
                            meeting
                            <i class="wrongTextParaphrased">up</i> with the HR
                            <i class="wrongTextParaphrased">folks</i>
                            <i class="RightTextParaphrased">department</i> to 9:30 am?</p>
                            <p data-no-translate class="formalExampleOut" style="${this.cardArr[1].isChecked ? 'display: none' : 'display: block'}">Can we reschedule our meeting with the HR department to 9:30 am?</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=D name=Tabs type=radio id=TabD />
                  <label class=Header for=TabD @click=${() => { this.currentTabNumber = 3; }}> Concise </label>
                  <div class=Content>
                      <h2><span>Defination:</span>Makes your writing to the point and direct by using less words</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                            <div class="languageAndRevision">
                            <div></div>
                            <div class="resvisionToggle">
                              <label>Show revisions</label>
                            <div class="toggleDiffOption">
                              <label class="switch">
                                <input type="checkbox" id="toggleDiffOptionCheckbox" name="showRevisions" ?checked=${this.cardArr[3].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(3);
        }}>
                                <span class="slider round">
                                  <i class="sliderTextOn">ON</i>
                                  <i class="sliderTextOff">OFF</i>
                                </span>
                              </label>
                            </div>
                            </div>
                          </div>
                        </h2>
                          <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents">
                            <h4>Original Text</h4>
                            <p data-no-translate>In this study, recent works on the thermal conductivity of nanofluids are examined to gain the best insights into and understanding of the influential parameters that affect thermal conductivity.</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents">
                            <h4>Paraphrased Text</h4>
                            <p style="${this.cardArr[3].isChecked ? 'display: block' : 'display: none'}" data-no-translate>
                            <i class="wrongTextParaphrased">In this study, recent works</i>
                            <i class="RightTextParaphrased">Recent research</i>
                            on the thermal conductivity of nanofluids
                            <i class="wrongTextParaphrased">are examined</i>
                            <i class="RightTextParaphrased">is reviewed</i> to
                            <i class="wrongTextParaphrased">gain the best insights into and</i>
                            <i class="RightTextParaphrased">provide a comprehensive</i> understanding of the
                            <i class="wrongTextParaphrased">influential parameters that affect</i>
                            <i class="RightTextParaphrased">factors impacting</i> thermal conductivity.</p>
                            <p data-no-translate class="ConciseExampleOut" style="${this.cardArr[3].isChecked ? 'display: none' : 'display: block'}">Recent research on the thermal conductivity of nanofluids is reviewed to provide a comprehensive understanding of the factors impacting thermal conductivity.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=C name=Tabs type=radio id=TabC />
                  <label class=Header for=TabC @click=${() => { this.currentTabNumber = 4; }}> Simple </label>
                  <div class=Content>
                      <h2><span>Defination:</span>Conveys your message in an easy-to-understand way</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                          <div class="languageAndRevision">
                            <div></div>
                            <div class="resvisionToggle">
                              <label>Show revisions</label>
                            <div class="toggleDiffOption">
                              <label class="switch">
                                <input type="checkbox" id="toggleDiffOptionCheckbox3" name="showRevisions" ?checked=${this.cardArr[3].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(2);
        }}>
                                <span class="slider round">
                                  <i class="sliderTextOn">ON</i>
                                  <i class="sliderTextOff">OFF</i>
                                </span>
                              </label>
                            </div>
                            </div>
                          </div>
                        </h2>
                        <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents" style="height: 130px">
                            <h4>Original Text</h4>
                            <p data-no-translate>Idarucizumab binds to dabigatran and quickly reverses its anticoagulant effects.</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents" style="height: 130px">
                            <h4>Paraphrased Text</h4>
                            <p data-no-translate style="${this.cardArr[2].isChecked ? 'display: block' : 'display: none'}">Idarucizumab
                            <i class="wrongTextParaphrased">binds</i>
                            <i class="RightTextParaphrased">attaches</i>
                            to dabigatran and
                            <i class="wrongTextParaphrased">quickly</i>
                            <i class="RightTextParaphrased">rapidly</i>
                            reverses its
                            <i class="wrongTextParaphrased">anticoagulant</i>
                            <i class="RightTextParaphrased">blood-thinning</i>
                            effects.</p>
                            <p data-no-translate class="sampleExampleOut" style="${this.cardArr[2].isChecked ? 'display: none' : 'display: block'}">Idarucizumab attaches to dabigatran and rapidly reverses its blood-thinning effects.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

            </div>

            <div class="Tabs ${this.currentLanguage === 'ES' ? 'isSpanishTabs' : ''}">
              <div class="Tab">
                  <input checked value=A name=SpanishTabs type=radio id=SpanishTabA />
                  <label class="Header" for=SpanishTabA @click=${() => { this.currentTabNumber = 0; }}> Standard </label>
                  <div class="Content">
                      <h2><span>Defination:</span>Rewrites your text by revising the vocabulary or sentence structure</h2>
                      <h2 class="exampleTitle">
                        <span>Example:</span>
                        <div class="languageAndRevision">
                          <div></div>
                          <div class="resvisionToggle">
                            <label>Show revisions</label>
                            <div class="toggleDiffOption">
                              <label class="switch">
                                <input type="checkbox" id="toggleDiffOptionCheckbox1" name="showRevisions" ?checked=${this.cardArr[0].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(0);
        }}>
                                <span class="slider round">
                                  <i class="sliderTextOn">ON</i>
                                  <i class="sliderTextOff">OFF</i>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </h2>
                      <div class="ExampleContents">
                        <div class="exampleWithText ExampleLeftContents">
                          <h4>Original Text</h4>
                          <p data-no-translate>De esta manera, la importancia de la ciberseguridad en la investigación de mercados digitales radica en la  necesidad apremiante de proteger la integridad, confidencialidad y disponibilidad de la información reunida durante este proceso (Miranda, 2023).</p>
                        </div>
                        <div class="exampleWithText ExampleRightContents">
                          <h4>Paraphrased Text</h4>
                          <p data-no-translate style="${this.cardArr[0].isChecked ? 'display: block' : 'display: none'}">
                          <i class="wrongTextParaphrased">De esta manera</i>
                          <i class="RightTextParaphrased">Así,</i>
                          la
                          <i class="wrongTextParaphrased">importancia</i>
                          <i class="RightTextParaphrased">relevancia</i>
                          de la ciberseguridad en la investigación de mercados digitales
                          <i class="wrongTextParaphrased">radica</i>
                          <i class="RightTextParaphrased">se fundamenta</i>
                          en la
                          <i class="RightTextParaphrased">urgente</i> necesidad
                          <i class="wrongTextParaphrased">apremiante</i> de
                          <i class="wrongTextParaphrased">proteger</i>
                          <i class="RightTextParaphrased">salvaguardar</i>
                          la integridad, confidencialidad y disponibilidad de la información
                          <i class="wrongTextParaphrased">recopilada</i>
                          <i class="RightTextParaphrased">reunida</i>
                          durante este proceso (Miranda, 2023).</p>
                          <p data-no-translate class="standardExampleOut" style="${this.cardArr[0].isChecked ? 'display: none' : 'display: block'}">Así, la relevancia de la ciberseguridad en la investigación de mercados digitales se fundamenta en la urgente necesidad de salvaguardar la integridad, confidencialidad y disponibilidad de la información reunida durante este proceso (Miranda, 2023).</p>
                        </div>
                        <div class="exampleArrow">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                            <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                            <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                          </svg>
                        </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=E name=SpanishTabs type=radio id=SpanishTabE />
                  <label class=Header for=SpanishTabE @click=${() => { this.currentTabNumber = 1; }}> Academic </label>
                    <div class=Content>
                        <h2><span>Defination:</span>Makes your writing appropriate for academic readers and publishers</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                          <div class="languageAndRevision">
                          <div></div>
                          <div class="resvisionToggle">
                            <label>Show revisions</label>
                          <div class="toggleDiffOption">
                            <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox4" name="showRevisions" ?checked=${this.cardArr[4].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(4);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                            </label>
                          </div>
                          </div>
                          </div>
                        </h2>
                        <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents" style="height: 120px">
                            <h4>Original Text</h4>
                            <p data-no-translate>La noche en Málaga ha sido tensa, donde ha continuado lloviendo aunque con menos intensidad que a lo largo del miércoles, donde en la capital se registraron hasta 150 litros por metro cuadrado.</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents" style="height: 120px">
                            <h4>Paraphrased Text</h4>
                            <p data-no-translate style="${this.cardArr[4].isChecked ? 'display: block' : 'display: none'}">
                            La noche en Málaga ha
                            <i class="wrongTextParaphrased">sido tensa, donde ha continuado lloviendo</i>
                            <i class="RightTextParaphrased">estado marcada por la tensión, con precipitaciones que persistieron</i>
                            aunque con
                            <i class="wrongTextParaphrased">menos</i>
                            <i class="RightTextParaphrased">menor</i> intensidad que
                            <i class="wrongTextParaphrased">a lo largo del</i>
                            <i class="RightTextParaphrased">el</i> miércoles
                            <i class="wrongTextParaphrased">donde en la capital</i>
                            <i class="RightTextParaphrased">cuando</i> se registraron hasta 150 litros por metro cuadrado
                            <i class="RightTextParaphrased">en la capital.</i>
                            </p>
                            <p data-no-translate class="formalExampleOut" style="${this.cardArr[4].isChecked ? 'display: none' : 'display: block'}">La noche en Málaga ha estado marcada por la tensión, con precipitaciones que persistieron, aunque con menor intensidad que el miércoles, cuando se registraron hasta 150 litros por metro cuadrado en la capital.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=B name=SpanishTabs type=radio id=SpanishTabB />
                  <label class=Header for=SpanishTabB @click=${() => { this.currentTabNumber = 2; }}> Formal </label>
                    <div class=Content>
                        <h2><span>Defination:</span>Makes your writing tone professional and businesslike</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                          <div class="languageAndRevision">
                          <div></div>
                          <div class="resvisionToggle">
                            <label>Show revisions</label>
                          <div class="toggleDiffOption">
                            <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox2" name="showRevisions" ?checked=${this.cardArr[1].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(1);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                            </label>
                          </div>
                          </div>
                          </div>
                        </h2>
                        <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents" style="height: 120px">
                            <h4>Original Text</h4>
                            <p data-no-translate>No es el tipo de vehículo que yo me compraría, pero cada loco con su tema.</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents" style="height: 120px">
                            <h4>Paraphrased Text</h4>
                            <p data-no-translate style="${this.cardArr[1].isChecked ? 'display: block' : 'display: none'}">No es el tipo de
                            <i class="wrongTextParaphrased">carro</i>
                            <i class="RightTextParaphrased">vehículo</i>
                            que yo
                            <i class="wrongTextParaphrased">me compraría</i>
                            <i class="RightTextParaphrased">adquiriría,</i>
                            pero cada
                            <i class="wrongTextParaphrased">loco con su tema</i>
                            <i class="RightTextParaphrased">persona tiene sus propias preferencias.</i></p>
                            <p data-no-translate class="formalExampleOut" style="${this.cardArr[1].isChecked ? 'display: none' : 'display: block'}">No es el tipo de vehículo que yo adquiriría, pero cada persona tiene sus propias preferencias.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=D name=SpanishTabs type=radio id=SpanishTabD />
                  <label class=Header for=SpanishTabD @click=${() => { this.currentTabNumber = 3; }}> Concise </label>
                  <div class=Content>
                      <h2><span>Defination:</span>Makes your writing to the point and direct by using less words</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                            <div class="languageAndRevision">
                            <div></div>
                            <div class="resvisionToggle">
                              <label>Show revisions</label>
                            <div class="toggleDiffOption">
                              <label class="switch">
                                <input type="checkbox" id="toggleDiffOptionCheckbox" name="showRevisions" ?checked=${this.cardArr[3].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(3);
        }}>
                                <span class="slider round">
                                  <i class="sliderTextOn">ON</i>
                                  <i class="sliderTextOff">OFF</i>
                                </span>
                              </label>
                            </div>
                            </div>
                          </div>
                        </h2>
                          <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents">
                            <h4>Original Text</h4>
                            <p data-no-translate>El 9.8 % (21) de los pacientes diagnosticados con enfermedad renal crónica no presentaron ninguna comorbilidad y en la mayor parte de este porcentaje de ellos era conformado por pacientes jóvenes y de sexo masculino. </p>
                          </div>
                          <div class="exampleWithText ExampleRightContents">
                            <h4>Paraphrased Text</h4>
                            <p data-no-translate style="${this.cardArr[3].isChecked ? 'display: block' : 'display: none'}">
                            El 9.8 % (21) de los pacientes diagnosticados con enfermedad renal crónica no
                            <i class="wrongTextParaphrased">presentaron ninguna comorbilidad y en la mayor parte de este porcentaje de ellos era conformado por pacientes jóvenes y de sexo masculino</i>
                            <i class="RightTextParaphrased">presentó comorbilidades, siendo en su mayoría jóvenes y hombres.</i></p>
                            <p class="ConciseExampleOut" style="${this.cardArr[3].isChecked ? 'display: none' : 'display: block'}">El 9.8 % (21) de los pacientes diagnosticados con enfermedad renal crónica no presentaron ninguna comorbilidad y en la mayor parte de este porcentaje de ellos era conformado por pacientes jóvenes y de sexo masculino presentó comorbilidades, siendo en su mayoría jóvenes y hombres.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

              <div class=Tab>
                  <input value=C name=SpanishTabs type=radio id=SpanishTabC />
                  <label class=Header for=SpanishTabC @click=${() => { this.currentTabNumber = 4; }}> Simple </label>
                  <div class=Content>
                      <h2><span>Defination:</span>Conveys your message in an easy-to-understand way</h2>
                        <h2 class="exampleTitle">
                          <span>Example:</span>
                          <div class="languageAndRevision">
                            <div></div>
                            <div class="resvisionToggle">
                              <label>Show revisions</label>
                            <div class="toggleDiffOption">
                              <label class="switch">
                                <input type="checkbox" id="toggleDiffOptionCheckbox3" name="showRevisions" ?checked=${this.cardArr[3].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(2);
        }}>
                                <span class="slider round">
                                  <i class="sliderTextOn">ON</i>
                                  <i class="sliderTextOff">OFF</i>
                                </span>
                              </label>
                            </div>
                            </div>
                          </div>
                        </h2>
                        <div class="ExampleContents">
                          <div class="exampleWithText ExampleLeftContents" style="height: 130px">
                            <h4>Original Text</h4>
                            <p data-no-translate>La anestesia regional (AR) ha sido un pilar fundamental en la práctica médica moderna, permitiendo la realización de procedimientos quirúrgicos (PQ) de manera segura y eficaz al bloquear selectivamente la sensación de dolor en regiones específicas del cuerpo.</p>
                          </div>
                          <div class="exampleWithText ExampleRightContents" style="height: 130px">
                            <h4>Paraphrased Text</h4>
                            <p data-no-translate style="${this.cardArr[2].isChecked ? 'display: block' : 'display: none'}">La anestesia regional (AR)
                            <i class="wrongTextParaphrased">ha sido un pilar fundamental</i>
                            <i class="RightTextParaphrased">es esencial</i>
                            en la
                            <i class="wrongTextParaphrased">práctica médica moderna, permitiendo la realización de</i>
                            <i class="RightTextParaphrased">medicina actual, ya que permite realizar</i>
                            procedimientos quirúrgicos (PQ) de
                            <i class="wrongTextParaphrased">manera</i>
                            <i class="RightTextParaphrased">forma</i> segura y
                            <i class="wrongTextParaphrased">eficaz</i>
                            <i class="RightTextParaphrased">efectiva</i> al bloquear
                            <i class="wrongTextParaphrased">selectivamente la sensación de</i>
                            <i class="RightTextParaphrased">el</i> dolor en
                            <i class="wrongTextParaphrased">regiones</i>
                            <i class="RightTextParaphrased">áreas</i>
                              específicas del cuerpo.
                            </p>
                            <p class="sampleExampleOut" style="${this.cardArr[2].isChecked ? 'display: none' : 'display: block'}">La anestesia regional (AR) es esencial en la medicina actual, ya que permite realizar procedimientos quirúrgicos (PQ) de forma segura y efectiva al bloquear el dolor en áreas específicas del cuerpo.</p>
                          </div>
                          <div class="exampleArrow">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="12" fill="#F3E8FF"/>
                              <path d="M16.5877 11.9999L13.2942 15.2921C13.2013 15.385 13.1277 15.4953 13.0774 15.6167C13.0271 15.7381 13.0012 15.8682 13.0012 15.9995C13.0012 16.1309 13.0271 16.261 13.0774 16.3824C13.1277 16.5038 13.2013 16.6141 13.2942 16.707C13.3871 16.7999 13.4974 16.8736 13.6188 16.9238C13.7402 16.9741 13.8703 17 14.0017 17C14.1331 17 14.2631 16.9741 14.3845 16.9238C14.5059 16.8736 14.6162 16.7999 14.7091 16.707L18.7087 12.7074C18.8017 12.6145 18.8754 12.5042 18.9257 12.3829C18.9761 12.2615 19.002 12.1314 19.002 11.9999C19.002 11.8685 18.9761 11.7384 18.9257 11.617C18.8754 11.4957 18.8017 11.3854 18.7087 11.2925L14.7091 7.29293C14.6162 7.20003 14.5059 7.12633 14.3845 7.07606C14.2631 7.02578 14.1331 6.9999 14.0017 6.9999C13.8703 6.9999 13.7402 7.02578 13.6188 7.07606C13.4974 7.12633 13.3871 7.20003 13.2942 7.29293C13.2013 7.38583 13.1277 7.49612 13.0774 7.6175C13.0271 7.73888 13.0012 7.86897 13.0012 8.00036C13.0012 8.13174 13.0271 8.26183 13.0774 8.38321C13.1277 8.50459 13.2013 8.61488 13.2942 8.70778L16.5877 11.9999Z" fill="#7A28A0"/>
                              <path d="M9.58571 11.9999L6.29229 15.2921C6.10467 15.4797 5.99926 15.7342 5.99926 15.9995C5.99926 16.2649 6.10467 16.5194 6.29229 16.707C6.47991 16.8946 6.73438 17 6.99972 17C7.26506 17 7.51953 16.8946 7.70715 16.707L11.7067 12.7074C11.7997 12.6145 11.8735 12.5042 11.9238 12.3829C11.9741 12.2615 12 12.1314 12 11.9999C12 11.8685 11.9741 11.7384 11.9238 11.617C11.8735 11.4957 11.7997 11.3854 11.7067 11.2925L7.70715 7.29293C7.61425 7.20003 7.50396 7.12633 7.38258 7.07606C7.2612 7.02578 7.1311 6.9999 6.99972 6.9999C6.86834 6.9999 6.73824 7.02578 6.61686 7.07606C6.49548 7.12633 6.38519 7.20003 6.29229 7.29293C6.19939 7.38583 6.1257 7.49612 6.07542 7.6175C6.02514 7.73888 5.99926 7.86897 5.99926 8.00036C5.99926 8.13174 6.02514 8.26183 6.07542 8.38321C6.1257 8.50459 6.19939 8.61488 6.29229 8.70778L9.58571 11.9999Z" fill="#7A28A0" fill-opacity="0.7"/>
                            </svg>
                          </div>
                      </div>
                  </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    `;
    }
    _closeModal() {
        this.dispatchEvent(new CustomEvent('close-modal', { bubbles: true, composed: true }));
    }
    _stopPropagation(event) {
        event.stopPropagation();
    }
    showOrHideRevisions(cardNumber = 0) {
        this.cardArr[cardNumber].isChecked = !this.cardArr[cardNumber].isChecked;
        this.requestUpdate();
    }
    changeSampleLanguage(language = 'EN') {
        var _a;
        let tabNumberMappingWithInputValue = {
            0: 'A',
            1: 'E',
            2: 'B',
            3: 'D',
            4: 'C',
        };
        console.log('value to be picked: ', tabNumberMappingWithInputValue[this.currentTabNumber]);
        this.showLanguageDropDown = false;
        this.currentLanguage = language;
        (_a = this === null || this === void 0 ? void 0 : this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll(`[value="${tabNumberMappingWithInputValue[this.currentTabNumber]}"]`).forEach((x) => {
            x.checked = true;
        });
        this.requestUpdate();
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
};
exports.ParaphraserModal = ParaphraserModal;
ParaphraserModal.styles = (0, lit_1.css) `
    .modal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }
    .modal-content {
      background: #FFFFFF;
      border-radius: 6px;
      max-width: 702px;
      width: 100%;
      overflow: hidden;
    }
    .modal-header{
      position: relative;
      background-color: #F4E9FF;
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .modal-header h2{
      padding: 0;
      font-size: 16.54px;
      font-weight: 600;
      line-height: 16.31px;
      text-align: left;
      margin: 0;
      color: #7A28A0;
    }
    .modal-header button{
      background-color: transparent;
      border: none;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-header button:hover{
      background-color: #EEEEEE;
    }
    .modal-header button:focus{
      outline: none;
      box-shadow: none;
    }
    .modal-body{
      position: relative;
      padding: 20px 32px;
      background: #F7F7FA;
      height: 530px;
      overflow: auto;
      margin-right: 2px;
    }
    .modal-body::-webkit-scrollbar,
    .modal-body::-webkit-scrollbar {
      width: 4px;
    }
    .modal-body::-webkit-scrollbar-track-piece,
    .modal-body::-webkit-scrollbar-track-piece {
      background-color: #e7e7e7;
    }
    .modal-body::-webkit-scrollbar-thumb,
    .modal-body::-webkit-scrollbar-thumb{
      background-color: #9c9c9c;
      border-radius: 10px;
    }
    @media(max-width: 1480px){
      .modal-body{
        height: 450px;
      }
    }
    @media(max-width: 1366px){
      .modal-body{
        height: 400px;
      }
    }

    .modal-body p{
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #000000;
      margin: 0 0 24px 0;
    }
    .modal-body h3{
      position: relative;
      display: flex;
      align-item: center;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 16.31px;
      text-align: left;
      color: #111A27;
    }
    .modal-body h3 svg{
      margin-right: 6px;
    }
    .headingWithLanguageDropDown{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown{
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown svg{
      margin-right: 4px;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown span{
      position: relative;
      display: flex;
      align-items: center;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown span svg{
      margin: 0 0 0 4px;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents{
      display: none;
      position: absolute;
      left: auto;
      right: 0;
      background: #FFFFFF;
      top: 20px;
      width: 170px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.30);
      z-index: 9;
      border-radius: 4px;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents.showLangDropDown{
      display: block;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents h4{
      position: relative;
      margin: 10px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents ul li{
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 10px;
      border-bottom: solid 1px #EEE;
      transition: 0.5s;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents ul li:hover{
      background-color: rgb(250, 238, 255);
      transition: 0.5s;
      cursor: pointer;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents ul li:last-child{
      border-bottom: none;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents ul li input{
      accent-color: #7A28A0;
      padding: 0;
      margin: 0 6px 0 0;
      cursor: pointer;
    }
    .headingWithLanguageDropDown .selectLanguageDropdown .dropDownLanguagecontents ul li label{
      position: relative;
      font-size: 14px;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
    .Tabs {
      flex-wrap: wrap;
      display: none;
      column-gap: 0.125rem;
      width: 100%;
      margin-inline: auto;
    }
    .Tabs.isEnglishTabs, .Tabs.isSpanishTabs {
      display: flex;
    }
    .Tab {
      display: contents;
    }
    .Tab input {
      display: none;
    }
    .Header {
      order: -1;
      position: relative;
      top: 3px;
      padding: 0.5rem 1.5rem;
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      user-select: none;
      transition: background 80ms ease-in-out;
    }
    .Header:hover {
        background: #FFFFFF;
    }
    input:checked ~ .Header {
        background: #FFFFFF;
    }
    .Content {
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.1);
        border-radius: 8px;
        padding: 24px;
    }
    input:not(:checked) ~ .Content {
        display: none;
    }
    .Content h2{
      color: #111A27;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.31px;
      text-align: left;
      margin: 0 0 18px 0;
      position: relative;
    }
    .Content h2::before {
      content: '';
      position: absolute;
      left: -10px;
      right: auto;
      top: 5px;
      background: #414E62;
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
    .Content h2 span{
      color: #000000;
      font-weight: 600;
      margin-right: 3px;
    }
    .Content h2.exampleTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
    }
    .Content h2.exampleTitle::before {
      top: 7px;
    }
    .ExampleContents{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .ExampleContents .exampleWithText{
      position: relative;
      padding-left: 10px;
      padding-right: 20px;
      margin-top: 16px;
      width: 50%;
      height: 221px !important;
    }
    .ExampleContents .exampleWithText.ExampleLeftContents::before{
      content: '';
      position: absolute;
      left: auto;
      right: 5px;
      background-color:rgba(122, 40, 160, 0.2);
      width: 1px;
      height: 100%;
      top: -10px;
    }
    .ExampleContents .exampleWithText h4{
      color: #414E62;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.31px;
      text-align: left;
      margin: 0 0 8px 0;
    }
    .ExampleContents .exampleWithText p{
      color: #111A27;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.85px;
      text-align: left;
      margin: 0;
      padding-left: 10px;
    }
    .ExampleContents .exampleWithText p i{
      font-style: normal;
    }
    .ExampleContents .exampleWithText p i.wrongTextParaphrased{
      color: #DC2626;
      text-decoration: line-through;
    }
    .ExampleContents .exampleWithText p i.RightTextParaphrased{
      background-color: rgba(21, 128, 61, 0.2);
      padding: 0 2px;
    }
    .exampleArrow{
      position: absolute;
      left: 46.7%;
      top: 48%;
    }
    .languageAndRevision{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;

    }
    .resvisionToggle{
      position: relative;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .resvisionToggle label{
      color: #414E62;
      font-size: 12px;
      font-weight: 600;
      line-height: 11.59px;
      margin: 1px 5px 0 0;
    }
    .resvisionToggle .toggleDiffOption{
      position: relative;
    }
    .resvisionToggle .toggleDiffOption label{
      margin: 0;
      color: #647087;
      font-size: 12px;
      font-weight: 500;
      position: relative;
      top: 4px;
      display: inline-block;
      width: 32px;
      height: 16px;
    }
    .resvisionToggle .toggleDiffOption label input{
      opacity: 0;
      width: 0;
      height: 0;
      box-sizing: border-box;
      padding: 0;
    }
    .resvisionToggle .toggleDiffOption label span{
      border-radius: 50px;
      width: 28px;
      height: 14px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider{
      background-color: #7a28a0;
    }
    .resvisionToggle .toggleDiffOption label span .sliderTextOn{
      position: absolute;
      color: #FFF;
      font-style: normal;
      font-size: 6.51px;
      font-weight: 600;
      line-height: 10.42px;
      text-align: left;
      top: 2px;
      left: 3px;
      display: none;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider .sliderTextOn{
      display: inline-block;
    }
    .resvisionToggle .toggleDiffOption label span .sliderTextOff{
      position: absolute;
      color: #4d4d4d;
      font-style: normal;
      font-size: 6.51px;
      font-weight: 600;
      line-height: 10.42px;
      text-align: left;
      top: 2px;
      left: auto;
      right: 3px;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider .sliderTextOff{
      display: none;
    }
    .resvisionToggle .toggleDiffOption label span:before{
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      left: 1.5px;
      top: 2px;
      background-color: #fff;
      border: 1px solid #bac3cd;
      transition: .4s;
      border-radius: 50%;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider:before{
      transform: translateX(12px);
      left: 5px;
      border: 1px solid #7a28a0;
      border-radius: 50%;
    }
    .modal[trinka-language^="ja"] .Header {
      padding: 0.5rem 1rem !important;
    }
  `;
__decorate([
    (0, decorators_js_1.property)(),
    __metadata("design:type", Object)
], ParaphraserModal.prototype, "cardArr", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], ParaphraserModal.prototype, "showLanguageDropDown", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], ParaphraserModal.prototype, "currentLanguage", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], ParaphraserModal.prototype, "currentTabNumber", void 0);
exports.ParaphraserModal = ParaphraserModal = __decorate([
    (0, decorators_js_1.customElement)('tsdk-paraphraser-modal')
], ParaphraserModal);
