"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitExhaustBadge = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let LimitExhaustBadge = class LimitExhaustBadge extends lit_1.LitElement {
    render() {
        return this.showLimitExhaustBadge ?
            (0, lit_1.html) `
          <style>${this.css}</style>
          <div class="limitExhaustContainers">
            <span class="limit_exhaust_error">
              <svg style="width: 25px;height: 20px;right: 10px;position: relative;" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1684 1.63019C15.4518 1.13538 15.863 0.723743 16.3602 0.43733C16.8574 0.150917 17.4226 0 17.9982 0C18.5738 0 19.139 0.150917 19.6362 0.43733C20.1334 0.723743 20.5446 1.13538 20.828 1.63019L35.5602 27.1215C36.8131 29.2905 35.2398 32 32.7268 32H3.27319C0.76022 32 -0.813087 29.2905 0.439799 27.1215L15.172 1.62663L15.1684 1.63019ZM16.1981 12.4433V15.999C16.1981 16.4706 16.3877 16.9228 16.7253 17.2562C17.0629 17.5896 17.5208 17.7769 17.9982 17.7769C18.4756 17.7769 18.9335 17.5896 19.2711 17.2562C19.6087 16.9228 19.7983 16.4706 19.7983 15.999V12.4433C19.7983 11.9718 19.6087 11.5195 19.2711 11.1861C18.9335 10.8527 18.4756 10.6654 17.9982 10.6654C17.5208 10.6654 17.0629 10.8527 16.7253 11.1861C16.3877 11.5195 16.1981 11.9718 16.1981 12.4433ZM17.9982 20.4438C17.2821 20.4438 16.5953 20.7247 16.0889 21.2249C15.5825 21.725 15.298 22.4033 15.298 23.1106C15.298 23.8179 15.5825 24.4962 16.0889 24.9963C16.5953 25.4964 17.2821 25.7774 17.9982 25.7774C18.7143 25.7774 19.4011 25.4964 19.9075 24.9963C20.4139 24.4962 20.6984 23.8179 20.6984 23.1106C20.6984 22.4033 20.4139 21.725 19.9075 21.2249C19.4011 20.7247 18.7143 20.4438 17.9982 20.4438Z" fill="#FDB933"></path>
              </svg>
            </span>
            <div class="limitExhaustErrorDisplayContainer ${this.userType !== 'BASIC' ? 'premium-fup-container' : ''}">
              ${this.userType !== 'BASIC' ? (0, lit_1.html) `<h4><p style="margin: 0;">Your fair usage limit is exhausted for the month!</p></h4>` : ``}
              <span>
                ${this.userType === 'BASIC' ? (0, lit_1.html) `<p>Your free usage limit is exhausted! Upgrade now to enjoy full access.</p>` : (0, lit_1.html) `<p>However, we understand that you may have additional writing needs. For the same, please write to us at <a style='font-weight: 600;'>support@trinka.ai</a>, and our team will get in touch with you to address your needs.</p>`}
              </span>
              <span class="actionButtonSpan">
                <button class="actionButton" @click=${() => this.emitActionBtnEvent('redirectToPlansPage')} style="display: inline-block; border: none; cursor: pointer;">See all benefits</button>
                <button class="buyPremiumButton" @click=${() => this.emitActionBtnEvent('redirectToPremiumPage')} style="display: inline-block; border: none; cursor: pointer;">Upgrade</button>
              </span>
            </div>
          </div>
      ` : ``;
    }
    constructor() {
        super();
        this.css = `
  .limitExhaustContainers{
    width: 30px;
    height: 33px;
    position: absolute;
    float: right;
    margin: -40px 35px 0 0;
    border: solid 1px transparent;
    border-radius: 30px;
    left: auto;
    right: 0;
    z-index:110;
    font-family: Oxygen,Geneva,sans-serif;
    bottom: 0;
    right: 0;
    pointer-events: visible;
  }
  .limit_exhaust_error:hover .limitExhaustErrorDisplayContainer{
    width: 275px;
    border-radius: 30px;
    border: solid 1px #ccc;
    background: #FFF;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    position: absolute;
    z-index: 999;
    margin: -40px 35px 0 -246px;
  }

  .limitExhaustErrorDisplayContainer{
    width:240px;
    padding: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #4d4d4d;
    font-size: 14px;
    position: absolute;
    right: 10px;
    bottom: 100%;
    margin: 2px 2px 0 0;
    z-index: 99999999;
    background: #FFFFFF;
    display: none;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
    }

    .premium-fup-container.limitExhaustErrorDisplayContainer {
      width: 380px;
      justify-content: left;
      color: #111A27;
    }
    .premium-fup-container.limitExhaustErrorDisplayContainer h4 {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;

    }
    .premium-fup-container.limitExhaustErrorDisplayContainer span p {
      color: #111A27;
      font-size: 12px;
      font-weight: 400;
      margin: 0;
      margin-top: 9px;
    }
    .premium-fup-container.limitExhaustErrorDisplayContainer span p a {
      font-weight: 600;
    }
    .limitExhaustErrorDisplayContainer span p{
      margin:0;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: bold;
      color: #d4d4d;
    }
.limitExhaustErrorDisplayContainer .actionButtonSpan{
  width: 100%;
  display:flex;
  justify-content: space-between;
}
.limitExhaustErrorDisplayContainer .actionButtonSpan .buyPremiumButton{
  background: #FDB933;
    width: 115px;
    height: 27px;
    border-radius: 5px;
    color: #000;
    border: solid 1px #FDB933;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600px;
    cursor: pointer;
}
.limitExhaustErrorDisplayContainer .actionButtonSpan .actionButton{
  cursor: pointer;
  color: #4a3176;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  padding: 0;
  text-decoration: underline;
  border: none !important;
  cursor: pointer;
}
.limitExhaustContainers:hover .limitExhaustErrorDisplayContainer{
  display: flex;
  }
  `;
        this.currentLang = 'en';
        this.showLimitExhaustBadge = false;
        this.userType = 'BASIC';
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    emitActionBtnEvent(redirectionType) {
        const event = new CustomEvent('tsdk-redirect-to-trinka-page', {
            bubbles: true,
            composed: true,
            detail: {
                redirectionType: redirectionType
            }
        });
        document.dispatchEvent(event);
    }
};
exports.LimitExhaustBadge = LimitExhaustBadge;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Boolean)
], LimitExhaustBadge.prototype, "showLimitExhaustBadge", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", String)
], LimitExhaustBadge.prototype, "userType", void 0);
exports.LimitExhaustBadge = LimitExhaustBadge = __decorate([
    (0, decorators_js_1.customElement)('tsdk-limit-exhaust-badge'),
    __metadata("design:paramtypes", [])
], LimitExhaustBadge);
