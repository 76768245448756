"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditingModesModal = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let EditingModesModal = class EditingModesModal extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.cardArr = [{ isChecked: true }, { isChecked: true }, { isChecked: true }];
        this.currentLang = 'en';
    }
    render() {
        return (0, lit_1.html) `
      <div class="modal" @click=${this._closeModal}>
        <div class="modal-content" @click=${this._stopPropagation}>
          <div class="modal-header">
            <h2>Know more about Editing Modes</h2>
            <button @click=${this._closeModal}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0325 11.969C13.1734 12.1099 13.2526 12.301 13.2526 12.5002C13.2526 12.6995 13.1734 12.8906 13.0325 13.0315C12.8917 13.1724 12.7006 13.2515 12.5013 13.2515C12.302 13.2515 12.1109 13.1724 11.97 13.0315L8.00193 9.06212L4.03255 13.0302C3.89165 13.1711 3.70056 13.2503 3.5013 13.2503C3.30204 13.2503 3.11095 13.1711 2.97005 13.0302C2.82915 12.8893 2.75 12.6983 2.75 12.499C2.75 12.2997 2.82915 12.1086 2.97005 11.9677L6.93943 7.99962L2.9713 4.03025C2.8304 3.88935 2.75125 3.69825 2.75125 3.499C2.75125 3.29974 2.8304 3.10864 2.9713 2.96775C3.1122 2.82685 3.30329 2.7477 3.50255 2.7477C3.70181 2.7477 3.8929 2.82685 4.0338 2.96775L8.00193 6.93712L11.9713 2.96712C12.1122 2.82622 12.3033 2.74707 12.5025 2.74707C12.7018 2.74707 12.8929 2.82622 13.0338 2.96712C13.1747 3.10802 13.2538 3.29911 13.2538 3.49837C13.2538 3.69763 13.1747 3.88872 13.0338 4.02962L9.06442 7.99962L13.0325 11.969Z" fill="#414E62"/>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="knowMoreAboutPowerMode">
              <div class="powerModeHoverModeBody">
                <h3>
                  <svg width="10" height="16" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                  </svg>
                  Power Mode
                </h3>
                <p><strong>About:</strong> Power Mode corrects your language as well as enhances it with advanced AI suggestions. It also paraphrases the text lightly in order to bring clarity and fluency to your writing.</p>
                <p><strong>When to use:</strong> Use Power Mode when you need both corrections and enhancements for your writing, especially if you aim to make your writing clearer, more fluent, and impactful.</p>
                <h3 style="margin-top: 24px;">
                  <svg width="14" height="14.4" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Lite Mode
                </h3>
                <p><strong>About:</strong> Lite Mode focuses on correcting basic grammar, spelling, and punctuation mistakes. It helps keep your writing accurate.</p>
                <p><strong>When to use:</strong> Use Lite Mode when basic grammar corrections are sufficient for your needs or you just want to proofread the content.</p>
                <h3  style="margin-top: 24px;margin-bottom: 24px;">
                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.550781" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect y="4.21777" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect y="7.88379" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect y="11.5508" width="5.5" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect x="7.33594" y="11.5508" width="1.375" height="1.375" rx="0.6875" fill="#444444"/>
                  </svg>
                  View Examples
                </h3>
                <div class=Tabs>
                  <div class=Tab>
                  <input checked value=A name=Tabs type=radio id=TabA />
                  <label class=Header for=TabA> Example 1 </label>
                  <div class=Content>
                      <div class="languageAndRevision">
                        <div class="resvisionToggle">
                          <label>Revision</label>
                          <div class="toggleDiffOption">
                            <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox1" name="showRevisions" ?checked=${this.cardArr[0].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(0);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="ExampleContents">
                        <div class="exampleWithText ExampleLeftContents">
                          <h4>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.01611 1.19792C2.04048 1.11369 2.09107 1.03975 2.16029 0.98717C2.22951 0.934589 2.31364 0.906199 2.40008 0.90625H5.59988C5.66322 0.906299 5.72565 0.921626 5.78201 0.950971C5.83838 0.980315 5.88708 1.02284 5.92411 1.07504C5.96113 1.12724 5.98542 1.18762 5.99497 1.25122C6.00453 1.31481 5.99907 1.37981 5.97905 1.44084L4.55514 5.78096H7.59975C7.67465 5.78091 7.74806 5.80222 7.81162 5.84246C7.87519 5.88271 7.92635 5.94027 7.95927 6.0086C7.9922 6.07692 8.00558 6.15327 7.99788 6.22894C7.99018 6.30461 7.96172 6.37656 7.91573 6.43661L2.31609 13.7487C2.25872 13.8239 2.17716 13.8764 2.08562 13.8968C1.99409 13.9172 1.8984 13.9043 1.81525 13.8604C1.73211 13.8165 1.66678 13.7443 1.63068 13.6565C1.59458 13.5687 1.59 13.4707 1.61773 13.3798L3.05844 8.62454H0.400209C0.338024 8.62458 0.276688 8.60989 0.221071 8.58164C0.165454 8.55339 0.117088 8.51236 0.0798152 8.46181C0.0425419 8.41125 0.0173879 8.35257 0.0063507 8.29042C-0.00468647 8.22826 -0.00130278 8.16435 0.0162332 8.10376L2.01611 1.19792Z" fill="#FDB933"/>
                            </svg>
                            Power Mode
                          </h4>
                          <p  data-no-translate style="${this.cardArr[0].isChecked ? 'display: block' : 'display: none'}">Since 2006, corporate profits
                          <i class="wrongTextParaphrased">broke</i>
                          <i class="RightTextParaphrased">have broken</i> records,
                          <i class="wrongTextParaphrased">while</i>
                          <i class="RightTextParaphrased">and</i> unemployment
                          <i class="wrongTextParaphrased">rate</i> in
                          <i class="wrongTextParaphrased">America</i>
                          <i class="RightTextParaphrased">the US</i> has
                          <i class="wrongTextParaphrased">gone up</i>
                          <i class="RightTextParaphrased">increased</i> significantly.</p>
                          <p data-no-translate class="powerModeExampleOne" style="${this.cardArr[0].isChecked ? 'display: none' : 'display: block'}">Since 2006, corporate profits have broken records, and unemployment in the US has increased significantly.</p>
                          <h5><span></span> Corrections and enhancements</h5>
                        </div>
                        <div class="exampleWithText ExampleRightContents">
                        <h4>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 13.1803L2.36198 11.8184L3.72395 13.1803L5.08593 11.8184L6.4479 13.1803L7.80988 11.8184L9.17186 13.1803L10.8743 11.8184L12.2363 13.1803" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12.2357 3.72714L11.5547 3.04615L10.1927 1.69136M10.1927 1.69136L4.0638 7.85121L3.38281 10.537L6.10676 9.85604L12.5762 3.38665C12.7103 3.25252 12.8168 3.09327 12.8894 2.91801C12.962 2.74274 12.9994 2.55488 12.9994 2.36517C12.9994 2.17545 12.962 1.98759 12.8894 1.81233C12.8168 1.63706 12.7103 1.47781 12.5762 1.34368L12.4863 1.26128C12.2098 1.02814 11.8558 0.907478 11.4945 0.923247C11.1332 0.939016 10.791 1.09006 10.5359 1.34641L10.1927 1.69136Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Lite Mode
                          </h4>
                          <p  data-no-translate style="padding-left: 6px; ${this.cardArr[0].isChecked ? 'display: block' : 'display: none'}">Since 2006, corporate profits
                          <i class="wrongTextParaphrased">broke</i>
                          <i class="RightTextParaphrased">have broken</i> records, while
                          <i class="RightTextParaphrased">the</i> unemployment rate in America has
                          <i class="wrongTextParaphrased">gone up</i>
                          <i class="RightTextParaphrased">increased</i> significantly.</p>
                          <p data-no-translate class="LiteModeExampleOne" style="padding-left: 6px; ${this.cardArr[0].isChecked ? 'display: none' : 'display: block'}">Since 2006, corporate profits have broken records, while the unemployment rate in America has increased significantly.</p>
                          <h5><span></span> Only corrections</h5>
                        </div>
                      </div>
                      </div>
                  </div>

                  <div class=Tab>
                  <input value=B name=Tabs type=radio id=TabB />
                  <label class=Header for=TabB> Example 2 </label>
                      <div class=Content>
                        <div class="languageAndRevision">
                        <div class="resvisionToggle">
                          <label>Revision</label>
                          <div class="toggleDiffOption">
                              <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox2" name="showRevisions" ?checked=${this.cardArr[1].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(1);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="ExampleContents">
                        <div class="exampleWithText ExampleLeftContents">
                          <h4>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.01611 1.19792C2.04048 1.11369 2.09107 1.03975 2.16029 0.98717C2.22951 0.934589 2.31364 0.906199 2.40008 0.90625H5.59988C5.66322 0.906299 5.72565 0.921626 5.78201 0.950971C5.83838 0.980315 5.88708 1.02284 5.92411 1.07504C5.96113 1.12724 5.98542 1.18762 5.99497 1.25122C6.00453 1.31481 5.99907 1.37981 5.97905 1.44084L4.55514 5.78096H7.59975C7.67465 5.78091 7.74806 5.80222 7.81162 5.84246C7.87519 5.88271 7.92635 5.94027 7.95927 6.0086C7.9922 6.07692 8.00558 6.15327 7.99788 6.22894C7.99018 6.30461 7.96172 6.37656 7.91573 6.43661L2.31609 13.7487C2.25872 13.8239 2.17716 13.8764 2.08562 13.8968C1.99409 13.9172 1.8984 13.9043 1.81525 13.8604C1.73211 13.8165 1.66678 13.7443 1.63068 13.6565C1.59458 13.5687 1.59 13.4707 1.61773 13.3798L3.05844 8.62454H0.400209C0.338024 8.62458 0.276688 8.60989 0.221071 8.58164C0.165454 8.55339 0.117088 8.51236 0.0798152 8.46181C0.0425419 8.41125 0.0173879 8.35257 0.0063507 8.29042C-0.00468647 8.22826 -0.00130278 8.16435 0.0162332 8.10376L2.01611 1.19792Z" fill="#FDB933"/>
                            </svg>
                            Power Mode
                          </h4>
                          <p data-no-translate style="${this.cardArr[1].isChecked ? 'display: block' : 'display: none'}">In recent years, air
                          <i class="wrongTextParaphrased">pollutions</i>
                          <i class="RightTextParaphrased">pollution</i>
                          <i class="wrongTextParaphrased">are</i>
                          <i class="RightTextParaphrased">has become</i>
                          <i class="wrongTextParaphrased">more and more</i>
                          <i class="RightTextParaphrased">increasingly</i>
                          <i class="RightTextParaphrased">serious</i>
                          <i class="wrongTextParaphrased">with</i>
                          <i class="RightTextParaphrased">because of</i>
                          <i class="wrongTextParaphrased">the development of</i>
                          <i class="RightTextParaphrased">advances in</i>
                          technology and industry.</p>
                          <p data-no-translate class="powerModeExampleTwo" style="${this.cardArr[1].isChecked ? 'display: none' : 'display: block'}">In recent years, air pollution has become increasingly serious because of advances in technology and industry.</p>
                          <h5><span></span> Corrections and enhancements</h5>
                        </div>
                        <div class="exampleWithText ExampleRightContents">
                        <h4>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 13.1803L2.36198 11.8184L3.72395 13.1803L5.08593 11.8184L6.4479 13.1803L7.80988 11.8184L9.17186 13.1803L10.8743 11.8184L12.2363 13.1803" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12.2357 3.72714L11.5547 3.04615L10.1927 1.69136M10.1927 1.69136L4.0638 7.85121L3.38281 10.537L6.10676 9.85604L12.5762 3.38665C12.7103 3.25252 12.8168 3.09327 12.8894 2.91801C12.962 2.74274 12.9994 2.55488 12.9994 2.36517C12.9994 2.17545 12.962 1.98759 12.8894 1.81233C12.8168 1.63706 12.7103 1.47781 12.5762 1.34368L12.4863 1.26128C12.2098 1.02814 11.8558 0.907478 11.4945 0.923247C11.1332 0.939016 10.791 1.09006 10.5359 1.34641L10.1927 1.69136Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Lite Mode
                          </h4>
                          <p data-no-translate style="padding-left: 6px; ${this.cardArr[1].isChecked ? 'display: block' : 'display: none'}">In recent years, air
                          <i class="wrongTextParaphrased">pollutions</i>
                          <i class="RightTextParaphrased">pollution</i>
                          <i class="wrongTextParaphrased">are</i>
                          <i class="RightTextParaphrased">has become</i> more and more serious with the development of technology and industry.</p>
                          <p data-no-translate class="LiteModeExampleTwo" style="padding-left: 6px; ${this.cardArr[1].isChecked ? 'display: none' : 'display: block'}">In recent years, air pollution has become more and more serious with the development of technology and industry.</p>
                          <h5><span></span> Only corrections</h5>
                        </div>
                      </div>
                      </div>
                    </div>

                  <div class=Tab>
                  <input value=C name=Tabs type=radio id=TabC />
                  <label class=Header for=TabC> Example 3 </label>
                  <div class=Content>
                        <div class="languageAndRevision">
                        <div class="resvisionToggle">
                          <label>Revision</label>
                          <div class="toggleDiffOption">
                              <label class="switch">
                              <input type="checkbox" id="toggleDiffOptionCheckbox3" name="showRevisions" ?checked=${this.cardArr[2].isChecked} @change=${(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.showOrHideRevisions(2);
        }}>
                              <span class="slider round">
                                <i class="sliderTextOn">ON</i>
                                <i class="sliderTextOff">OFF</i>
                              </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="ExampleContents">
                        <div class="exampleWithText ExampleLeftContents">
                          <h4>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.01611 1.19792C2.04048 1.11369 2.09107 1.03975 2.16029 0.98717C2.22951 0.934589 2.31364 0.906199 2.40008 0.90625H5.59988C5.66322 0.906299 5.72565 0.921626 5.78201 0.950971C5.83838 0.980315 5.88708 1.02284 5.92411 1.07504C5.96113 1.12724 5.98542 1.18762 5.99497 1.25122C6.00453 1.31481 5.99907 1.37981 5.97905 1.44084L4.55514 5.78096H7.59975C7.67465 5.78091 7.74806 5.80222 7.81162 5.84246C7.87519 5.88271 7.92635 5.94027 7.95927 6.0086C7.9922 6.07692 8.00558 6.15327 7.99788 6.22894C7.99018 6.30461 7.96172 6.37656 7.91573 6.43661L2.31609 13.7487C2.25872 13.8239 2.17716 13.8764 2.08562 13.8968C1.99409 13.9172 1.8984 13.9043 1.81525 13.8604C1.73211 13.8165 1.66678 13.7443 1.63068 13.6565C1.59458 13.5687 1.59 13.4707 1.61773 13.3798L3.05844 8.62454H0.400209C0.338024 8.62458 0.276688 8.60989 0.221071 8.58164C0.165454 8.55339 0.117088 8.51236 0.0798152 8.46181C0.0425419 8.41125 0.0173879 8.35257 0.0063507 8.29042C-0.00468647 8.22826 -0.00130278 8.16435 0.0162332 8.10376L2.01611 1.19792Z" fill="#FDB933"/>
                            </svg>
                            Power Mode
                          </h4>
                          <p data-no-translate style="${this.cardArr[2].isChecked ? 'display: block' : 'display: none'}">Subadult and juvenile
                          <i class="RightTextParaphrased">elephants</i>
                          <i class="wrongTextParaphrased">were found to consume</i>
                          <i class="RightTextParaphrased">consumed</i> more plant species <i class="wrongTextParaphrased">then</i> <i class="RightTextParaphrased">than</i> adult elephants.</p>
                          <p data-no-translate class="powerModeExampleThree" style="${this.cardArr[2].isChecked ? 'display: none' : 'display: block'}">Subadult and juvenile elephants consumed more plant species than adult elephants.</p>
                          <h5><span></span> Corrections and enhancements</h5>
                        </div>
                        <div class="exampleWithText ExampleRightContents">
                        <h4>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 13.1803L2.36198 11.8184L3.72395 13.1803L5.08593 11.8184L6.4479 13.1803L7.80988 11.8184L9.17186 13.1803L10.8743 11.8184L12.2363 13.1803" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12.2357 3.72714L11.5547 3.04615L10.1927 1.69136M10.1927 1.69136L4.0638 7.85121L3.38281 10.537L6.10676 9.85604L12.5762 3.38665C12.7103 3.25252 12.8168 3.09327 12.8894 2.91801C12.962 2.74274 12.9994 2.55488 12.9994 2.36517C12.9994 2.17545 12.962 1.98759 12.8894 1.81233C12.8168 1.63706 12.7103 1.47781 12.5762 1.34368L12.4863 1.26128C12.2098 1.02814 11.8558 0.907478 11.4945 0.923247C11.1332 0.939016 10.791 1.09006 10.5359 1.34641L10.1927 1.69136Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Lite Mode
                          </h4>
                          <p data-no-translate style="padding-left: 6px; ${this.cardArr[2].isChecked ? 'display: block' : 'display: none'}">Subadult and juvenile
                          <i class="RightTextParaphrased">elephants</i> were found to consume more plant species <i class="wrongTextParaphrased">then</i> <i class="RightTextParaphrased">than</i> adult elephants.</p>
                          <p data-no-translate class="LiteModeExampleThree" style="padding-left: 6px; ${this.cardArr[2].isChecked ? 'display: none' : 'display: block'}">Subadult and juvenile elephants were found to consume more plant species than adult elephants.</p>
                          <h5><span></span> Only corrections</h5>
                        </div>
                      </div>
                </div>
                <p class="modeDisclaimerText"> * Editing Modes are available only for English language.</p>
              </div>
              </div>
              <!-- <div class="powerModeHoverSliderSectoin">
                <h4>
                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="11" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect y="3.66675" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect y="7.33325" width="11" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect y="11" width="5.5" height="1.375" rx="0.6875" fill="#444444"/>
                    <rect x="7.3335" y="11" width="1.375" height="1.375" rx="0.6875" fill="#444444"/>
                  </svg>
                  View Examples
                </h4>
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleControls" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleControls" data-slide-to="1"></li>
                    <li data-target="#carouselExampleControls" data-slide-to="2"></li>
                  </ol>
                  <div class="carousel-inner">
                    <div class="carousel-item active">

                      <div class="powerModeShowReivision">
                        <label for="">Show Revisions</label>
                        <input type="checkbox" checked (click)="showRevivationActive_Slider_two()">
                      </div>
                      <div class="powerModeSlides">
                        <div class="powerModeCard" [ngClass]="{'showRevivisionTwoPowerMode': showRevivsionTwo}">
                          <h2>
                            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                            </svg>
                            Power Mode
                          </h2>
                          <p style="margin-bottom: 15px;">
                            In recent years, air
                            <span class="powerModeNormalWord">pollutions</span>
                            <span class="powerModeEditedWord" style="margin-right: 3px;">pollution</span>
                            <span class="powerModeNormalWord">are</span>
                            <span class="powerModeEditedWord" style="margin-right: 3px;">has become</span>
                            <span class="powerModeNormalWord">more and more</span>
                            <span class="powerModeEditedWord">increasingly</span>
                              serious
                              <span class="powerModeNormalWord">with</span>
                            <span class="powerModeEditedWord" style="margin-right: 3px;">because of</span>
                            <span class="powerModeNormalWord">the development</span>
                            <span class="powerModeEditedWord">advances in</span>
                              technology and industry.
                          </p>
                          <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                        </div>
                        <div class="liteModeCard" [ngClass]="{'showRevivisionTwoLiteMode': showRevivsionTwo}">
                          <h2>
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Lite Mode
                          </h2>
                          <p style="margin-bottom: 53px;">
                            In recent years, air
                            <span class="powerModeNormalWord">pollutions</span>
                            <span class="powerModeEditedWord">pollution</span>
                            <span class="powerModeNormalWord">are</span>
                            <span class="powerModeEditedWord" style="margin-right: 0px;">has become</span> more and more serious with the development of technology and industry.
                          </p>
                          <p class="powerModeNameOfSentence">Only corrections</p>
                        </div>
                      </div>
                      <div class="powerModeEditingMode">
                        <p> * Editing Modes are available only for English language.</p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="powerModeShowReivision">
                        <label for="">Show Revisions</label>
                        <input type="checkbox" checked (click)="showRevivationActive_Slider_one()">
                      </div>
                      <div class="powerModeSlides">
                        <div class="powerModeCard" [ngClass]="{'showRevivisionOnePowerMode': showRevivsionOne}">
                          <h2>
                            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                            </svg>
                            Power Mode
                          </h2>
                          <p style="margin-bottom: 50px;">Since 2006, corporate profits
                            <span class="powerModeNormalWord">broke</span>
                            <span class="powerModeEditedWord">have broken</span> records,
                            <span class="powerModeNormalWord">while</span>
                            <span class="powerModeEditedWord">and</span>  unemployment
                            <span class="powerModeNormalWord" style="margin-right: 0;">rate</span> in
                            <span class="powerModeNormalWord">America</span>
                            <span class="powerModeEditedWord">the US</span> has
                            <span class="powerModeNormalWord">gone up</span>
                            <span class="powerModeEditedWord">increased</span> significantly.
                          </p>
                          <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                        </div>
                        <div class="liteModeCard" [ngClass]="{'showRevivisionOneLiteMode': showRevivsionOne}">
                          <h2>
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Lite Mode
                          </h2>
                          <p style="margin-bottom: 49px;">Since 2006, corporate profits
                            <span class="powerModeNormalWord">broke</span>
                            <span class="powerModeEditedWord" style="margin-right: 0;">have broken</span> records, while
                            <span class="powerModeEditedWord" style="margin-right: 0;">the</span> unemployment rate in America has
                            <span class="powerModeNormalWord">gone up</span>
                            <span class="powerModeEditedWord" style="margin-right: 0;">increased</span> significantly.
                          </p>
                          <p class="powerModeNameOfSentence">Only corrections</p>
                        </div>
                      </div>
                      <div class="powerModeEditingMode">
                        <p> * Editing Modes are available only for English language.</p>
                      </div>
                    </div>
                    <div class="carousel-item">
                        <div class="powerModeShowReivision">
                          <label for="">Show Revisions</label>
                          <input type="checkbox" checked (click)="showRevivationActive_Slider_three()">
                        </div>
                        <div class="powerModeSlides">
                          <div class="powerModeCard" [ngClass]="{'showRevivisionThreePowerMode': showRevivsionThree}">
                            <h2>
                              <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.51611 0.29167C2.54048 0.207442 2.59107 0.133501 2.66029 0.0809202C2.72951 0.0283393 2.81364 -5.13359e-05 2.90008 6.96853e-08H6.09988C6.16322 4.89044e-05 6.22565 0.0153765 6.28201 0.0447209C6.33838 0.0740654 6.38708 0.116588 6.42411 0.168787C6.46113 0.220986 6.48542 0.28137 6.49497 0.344967C6.50453 0.408565 6.49907 0.473557 6.47905 0.534593L5.05514 4.87471H8.09975C8.17465 4.87466 8.24806 4.89597 8.31162 4.93621C8.37519 4.97646 8.42635 5.03402 8.45927 5.10235C8.4922 5.17067 8.50558 5.24702 8.49788 5.32269C8.49018 5.39836 8.46172 5.47031 8.41573 5.53036L2.81609 12.8424C2.75872 12.9177 2.67716 12.9701 2.58562 12.9905C2.49409 13.011 2.3984 12.9981 2.31525 12.9542C2.23211 12.9103 2.16678 12.8381 2.13068 12.7502C2.09458 12.6624 2.09 12.5645 2.11773 12.4736L3.55844 7.71829H0.900209C0.838024 7.71833 0.776688 7.70364 0.721071 7.67539C0.665454 7.64714 0.617088 7.60611 0.579815 7.55556C0.542542 7.505 0.517388 7.44632 0.506351 7.38417C0.495314 7.32201 0.498697 7.2581 0.516233 7.19751L2.51611 0.29167Z" fill="#FDB933"/>
                              </svg>
                              Power Mode
                            </h2>
                            <p style="margin-bottom: 52px;">
                              Subadult and juvenile <span class="powerModeEditedWord" style="margin-right: 3px;">elephants</span> <span class="powerModeNormalWord">were found to consume</span> <span class="powerModeEditedWord">consumed</span> more plant species <span class="powerModeNormalWord">then</span> <span class="powerModeEditedWord">than</span> adult elephants.
                            </p>
                            <p class="powerModeNameOfSentence">Corrections and enhancements</p>
                          </div>
                          <div class="liteModeCard" [ngClass]="{'showRevivisionThreeLiteMode': showRevivsionThree}">
                            <h2>
                              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 13.2578L1.86198 11.8958L3.22395 13.2578L4.58593 11.8958L5.9479 13.2578L7.30988 11.8958L8.67186 13.2578L10.3743 11.8958L11.7363 13.2578" stroke="#647087" stroke-width="0.680988" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.7364 3.80527L11.0554 3.12428L9.69343 1.76948M9.69343 1.76948L3.56453 7.92933L2.88354 10.6151L5.6075 9.93416L12.0769 3.46477C12.2111 3.33064 12.3175 3.1714 12.3901 2.99613C12.4627 2.82086 12.5001 2.63301 12.5001 2.44329C12.5001 2.25358 12.4627 2.06572 12.3901 1.89045C12.3175 1.71518 12.2111 1.55594 12.0769 1.42181L11.987 1.33941C11.7105 1.10627 11.3565 0.985603 10.9952 1.00137C10.6339 1.01714 10.2917 1.16819 10.0366 1.42453L9.69343 1.76948Z" stroke="#647087" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              Lite Mode
                            </h2>
                            <p style="margin-bottom: 70px;">
                              Subadult and juvenile <span class="powerModeEditedWord" style="margin-right: 0;">elephants</span> were found to consume more plant species  <span class="powerModeNormalWord">then</span> <span style="margin-right: 0;" class="powerModeEditedWord">than</span> adult elephants.
                            </p>
                            <p class="powerModeNameOfSentence">Only corrections</p>
                          </div>
                        </div>
                        <div class="powerModeEditingMode">
                          <p> * Editing Modes are available only for English language.</p>
                        </div>
                      </div>
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>

              </div> -->
            </div>
          </div>
        </div>
      </div>
    `;
    }
    _closeModal() {
        this.dispatchEvent(new CustomEvent('close-modal', { bubbles: true, composed: true }));
    }
    _stopPropagation(event) {
        event.stopPropagation();
    }
    showOrHideRevisions(cardNumber = 0) {
        this.cardArr[cardNumber].isChecked = !this.cardArr[cardNumber].isChecked;
        this.requestUpdate();
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
};
exports.EditingModesModal = EditingModesModal;
EditingModesModal.styles = (0, lit_1.css) `
    .modal {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }
    .modal-content {
      background: #FFFFFF;
      border-radius: 7px;
      max-width: 702px;
      width: 100%;
      overflow: hidden;
    }
    .modal-header {
      position: relative;
      background-color: #F4E9FF;
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .modal-header h2{
      padding: 0;
      margin: 0;
      color: #7A28A0;
      font-size: 20px;
      font-weight: 600;
      line-height: 19.72px;
      text-align: left;
    }
    .modal-header button{
      background-color: transparent;
      border: none;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-header button:hover{
      background-color: #EEEEEE;
    }
    .modal-header button:focus{
      outline: none;
      box-shadow: none;
    }
    .modal-body {
      position: relative;
      margin-right: 1px;
    }


  @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
  .powerModeHoverMode {
    position: relative;
    width: 588px;
    background: #fff;
    box-shadow: 0px 2.98px 14.92px 0px rgba(31, 41, 55, 0.2);
    border-radius: 6px;
    white-space: initial;
    z-index: 9;
  }
  .powerModeHoverMode .knowMoreAboutPowerMode,
  .modal-body .knowMoreAboutPowerMode {
    position: relative;
    height: 585px;
    background-color: #F7F7FA;
    overflow: hidden;
    overflow-y: auto;
  }
  @media (max-width: 1366px) {
    .powerModeHoverMode .knowMoreAboutPowerMode,
    .modal-body .knowMoreAboutPowerMode {
      height: calc(100vh - 140px);
    }
  }
  .powerModeHoverMode .knowMoreAboutPowerMode::-webkit-scrollbar,
  .modal-body .knowMoreAboutPowerMode::-webkit-scrollbar {
    width: 4px;
  }
  .powerModeHoverMode .knowMoreAboutPowerMode::-webkit-scrollbar-track-piece,
  .modal-body .knowMoreAboutPowerMode::-webkit-scrollbar-track-piece {
    background-color: #e7e7e7;
  }
  .powerModeHoverMode .knowMoreAboutPowerMode::-webkit-scrollbar-thumb,
  .modal-body .knowMoreAboutPowerMode::-webkit-scrollbar-thumb{
    background-color: #9c9c9c;
    border-radius: 10px;
  }
  .powerModeHoverMode .powerModeHoverModeHeader {
    position: relative;
    padding: 18px 18px 16px 18px;
    background-color: rgba(122, 40, 160, 0.1);
    border-radius: 6px 6px 0 0;
  }
  .powerModeHoverMode .powerModeHoverModeHeader h2 {
    color: #7a28a0;
    font-size: 20px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    line-height: 19.72px;
    margin: 0;
  }
  .powerModeHoverMode .powerModeHoverModeHeader button {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    position: absolute;
    left: auto;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.5;
  }
  .powerModeHoverMode .powerModeHoverModeHeader button img {
    width: 20px;
    height: 20px;
  }
  .powerModeHoverMode .powerModeHoverModeHeader button:hover {
    background-color: #ccc;
  }
  .powerModeHoverMode .powerModeHoverModeBody {
    position: relative;
    margin: 16px 26px 10px 26px;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverModeBody h3 {
    font-size: 18px;
    color: #000;
    margin: 0 0 10px 0;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .powerModeHoverMode .powerModeHoverModeBody h3 svg {
    margin-right: 8px;
  }
  .powerModeHoverMode .powerModeHoverModeBody p {
    font-size: 14px;
    font-weight: 400;
    color: #414e62;
    line-height: 20px;
    margin-bottom: 9px;
    padding: 0 16px 0 30px;
  }
  .powerModeHoverMode .powerModeHoverModeBody p:hover {
    text-decoration: none !important;
  }
  .powerModeHoverMode .powerModeHoverModeBody p strong {
    font-weight: 600;
    color: #111a27;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin {
    position: relative;
    padding: 14px 26px 40px 28px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin h4 {
    color: #000;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 14px;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin h4 svg {
    margin-right: 8px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin h5 {
    color: #414e62;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    line-height: 16px;
    margin: 0 auto 13px;
    max-width: 510px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeShowReivision {
    position: relative;
    text-align: right;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeShowReivision label {
    position: relative;
    margin: 0;
    color: #414e62;
    font-size: 12px;
    font-weight: 600;
    line-height: 15.66px;
    padding-right: 6px;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeShowReivision input[type="checkbox"] {
    position: relative;
    width: 25px;
    height: 13px;
    -webkit-appearance: none;
    background: #9ca3af;
    outline: none;
    border-radius: 20px;
    transition: 0.7s;
    cursor: pointer;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeShowReivision input:checked[type="checkbox"] {
    background: #7a28a0;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeShowReivision input[type="checkbox"]:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    top: 1.5px;
    left: 2px;
    background: #fff;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeShowReivision input:checked[type="checkbox"]:before {
    left: 12.5px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin #carouselExampleControls .carousel-indicators {
    bottom: -30px;
    padding: 0;
    margin: 0;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin #carouselExampleControls .carousel-indicators li {
    background-color: #b5b5b5;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin #carouselExampleControls .carousel-indicators li.active {
    background-color: #7a28a0;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionOnePowerMode span.powerModeNormalWord {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionOnePowerMode span.powerModeEditedWord {
    color: #414e62 !important;
    display: inline-block;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionOnePowerMode p {
    margin-bottom: 68px !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionOnePowerMode p.powerModeNameOfSentence {
    margin: 0 !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionTwoPowerMode span.powerModeNormalWord {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionTwoPowerMode span.powerModeEditedWord {
    color: #414e62 !important;
    display: inline-block;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionTwoPowerMode p {
    margin-bottom: 52px !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionTwoPowerMode p.powerModeNameOfSentence {
    margin: 0 !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionThreePowerMode span.powerModeNormalWord {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionThreePowerMode span.powerModeEditedWord {
    color: #414e62 !important;
    display: inline-block;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionThreePowerMode p {
    margin-bottom: 71px !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard.showRevivisionThreePowerMode p.powerModeNameOfSentence {
    margin: 0 !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard {
    position: relative;
    background-color: rgba(122, 40, 160, 0.08);
    border-radius: 6px;
    padding: 12px 22px 12px 22px;
    width: 49%;
    height: 217px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard h2 {
    color: #7a28a0;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    line-height: 19.36px;
    margin-bottom: 12.16px;
    margin-left: -12px;
    display: flex;
    align-items: center;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard h2 svg {
    margin-right: 5px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard p {
    color: #414e62;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 18.83px;
    font-family: "Open Sans", sans-serif;
    padding-left: 7px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard p .powerModeNormalWord {
    color: #dc2626;
    margin-right: 4px;
    text-decoration: line-through;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard p .powerModeEditedWord {
    color: #16a34a;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard .powerModeNameOfSentence {
    font-size: 12px;
    color: #414e62;
    margin: 0;
    font-weight: 400;
    line-height: 15px;
    font-family: "Open Sans", sans-serif;
    position: relative;
    padding-left: 5px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .powerModeCard .powerModeNameOfSentence::before {
    content: '';
    position: absolute;
    left: -2.5px;
    right: auto;
    top: 6px;
    width: 4px;
    height: 4px;
    background-color: #dc2626;
    border-radius: 50%;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionOneLiteMode span.powerModeNormalWord {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionOneLiteMode span.powerModeEditedWord {
    color: #414e62 !important;
    display: inline-block;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionTwoLiteMode span.powerModeNormalWord {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionTwoLiteMode span.powerModeEditedWord {
    color: #414e62 !important;
    display: inline-block;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionTwoLiteMode p {
    margin-bottom: 72px !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionTwoLiteMode p.powerModeNameOfSentence {
    margin: 0 !important;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionThreeLiteMode span.powerModeNormalWord {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard.showRevivisionThreeLiteMode span.powerModeEditedWord {
    color: #414e62 !important;
    display: inline-block;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard {
    position: relative;
    background-color: #eee;
    border-radius: 6px;
    padding: 12px 22px 12px 22px;
    width: 49%;
    height: 217px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard h2 {
    color: #647087;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    line-height: 19.36px;
    margin-bottom: 12.16px;
    margin-left: -12px;
    display: flex;
    align-items: center;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard h2 svg {
    margin-right: 5px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard p {
    color: #414e62;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 18.83px;
    font-family: "Open Sans", sans-serif;
    padding-left: 7px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard p .powerModeNormalWord {
    color: #dc2626;
    margin-right: 4px;
    text-decoration: line-through;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard p .powerModeEditedWord {
    color: #16a34a;
    margin-right: 4px;
    font-family: "Open Sans", sans-serif;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard .powerModeNameOfSentence {
    font-size: 12px;
    color: #414e62;
    margin: 0;
    font-weight: 400;
    line-height: 15px;
    font-family: "Open Sans", sans-serif;
    position: relative;
    padding-left: 5px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeSlides .liteModeCard .powerModeNameOfSentence::before {
    content: '';
    position: absolute;
    left: -2.5px;
    right: auto;
    top: 6px;
    width: 4px;
    height: 4px;
    background-color: #dc2626;
    border-radius: 50%;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeEditingMode {
    position: relative;
    margin-top: 14px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .powerModeEditingMode p {
    margin: 0;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    line-height: 17px;
    font-weight: 400;
    color: #111a27;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-prev {
    left: -56px !important;
    right: auto;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-next {
    right: -56px !important;
    left: auto;
    transform: rotate(180deg);
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-prev, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-next {
    top: 45%;
    height: 20px;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-prev .carousel-control-prev-icon::after, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-next .carousel-control-prev-icon::after, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-prev .carousel-control-next-icon::after, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-next .carousel-control-next-icon::after {
    display: none;
  }
  .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-prev .carousel-control-prev-icon::before, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-next .carousel-control-prev-icon::before, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-prev .carousel-control-next-icon::before, .powerModeHoverMode .powerModeHoverSliderSectoin .carousel-control-next .carousel-control-next-icon::before {
    content: '';
    position: absolute;
    background: url('../../../../../assets/images/powerModeSliderArrow.svg');
    width: 10px;
    height: 20px;
    background-size: cover;
  }
  .knowMoreAboutPowerMode .powerModeHoverModeBody{
    position: relative;
    padding: 24px 30px;
  }
  .knowMoreAboutPowerMode .powerModeHoverModeBody h3{
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #111A27;
  }
  .knowMoreAboutPowerMode .powerModeHoverModeBody h3 svg{
    margin-right: 7px;
  }
  .knowMoreAboutPowerMode .powerModeHoverModeBody p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #414E62;
    margin:0 0 10px 0;
    padding-left: 30px;
  }
  .knowMoreAboutPowerMode .powerModeHoverModeBody p strong{
    color: #111A27;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
    .Tabs {
      flex-wrap: wrap;
      display: flex;
      column-gap: 0.125rem;
      width: 100%;
      margin-inline: auto;
    }
    .Tab {
      display: contents;
    }
    .Tab input {
      display: none;
    }
    .Header {
      order: -1;
      position: relative;
      top: 3px;
      padding: 0.5rem 1.5rem;
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      user-select: none;
      transition: background 80ms ease-in-out;
    }
    .Header:hover {
        background: #FFFFFF;
    }
    input:checked ~ .Header {
        background: #FFFFFF;
    }
    .Content {
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.1);
        border-radius: 8px;
    }
    input:not(:checked) ~ .Content {
        display: none;
    }
    .ExampleContents{
      position: relative;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px;
    }
    .ExampleContents .exampleWithText{
      position: relative;
      border-radius: 8px;
      padding: 20px 30px;
      height: 171px;
      width: 50%;
    }
    .exampleWithText.ExampleLeftContents{
      background: rgba(122, 40, 160, 0.07);
    }
    .exampleWithText.ExampleRightContents{
      background: rgba(0, 0, 0, 0.07);
    }
    .ExampleContents .exampleWithText h4{
      color: #414E62;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      letter-spacing: -0.02em;
      text-align: left;
      margin: 0 0 8px -15px;
      color: #7A28A0;
      display: flex;
      align-items: center;
    }
    .ExampleContents .exampleWithText h4 svg{
      margin-right: 7px;
    }
    .ExampleContents .exampleWithText.ExampleRightContents h4{
      color: #647087;
    }
    .ExampleContents .exampleWithText p{
      font-size: 12px;
      font-weight: 600;
      line-height: 15.66px;
      text-align: left;
      color: #414E62;
      padding-left: 0;
    }
    .ExampleContents .exampleWithText p i{
      font-style: normal;
    }
    .ExampleContents .exampleWithText p i.wrongTextParaphrased{
      color: #DC2626;
      text-decoration: line-through;
    }
    .ExampleContents .exampleWithText p i.RightTextParaphrased{
      color: #16A34A;
    }
    .ExampleContents .exampleWithText h5{
      color: #414E62;
      margin: 0;
      font-size: 10px;
      font-weight: 400;
      line-height: 15.9px;
      text-align: left;
      position: absolute;
      left: 15px;
      right: auto;
      bottom: 15px;
      display: flex;
      align-items: center;
    }
    .ExampleContents .exampleWithText h5 span{
      width: 3px;
      height: 3px;
      background: #DC2626;
      margin-right: 3px;
      border-radius: 50%;
    }
    .exampleArrow{
      position: absolute;
      left: 46.7%;
      top: 48%;
    }
    .languageAndRevision{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 7px 10px 0px 10px;

    }
    .resvisionToggle{
      position: relative;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .resvisionToggle label{
      color: #414E62;
      font-size: 12px;
      font-weight: 600;
      line-height: 11.59px;
      margin: 1px 5px 0 0;
    }
    .resvisionToggle .toggleDiffOption{
      position: relative;
    }
    .resvisionToggle .toggleDiffOption label{
      margin: 0;
      color: #647087;
      font-size: 12px;
      font-weight: 500;
      position: relative;
      top: 4px;
      display: inline-block;
      width: 32px;
      height: 16px;
    }
    .resvisionToggle .toggleDiffOption label input{
      opacity: 0;
      width: 0;
      height: 0;
      box-sizing: border-box;
      padding: 0;
    }
    .resvisionToggle .toggleDiffOption label span{
      border-radius: 50px;
      width: 28px;
      height: 14px;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider{
      background-color: #7a28a0;
    }
    .resvisionToggle .toggleDiffOption label span .sliderTextOn{
      position: absolute;
      color: #FFF;
      font-style: normal;
      font-size: 6.51px;
      font-weight: 600;
      line-height: 10.42px;
      text-align: left;
      top: 2px;
      left: 4px;
      display: none;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider .sliderTextOn{
      display: inline-block;
    }
    .resvisionToggle .toggleDiffOption label span .sliderTextOff{
      position: absolute;
      color: #4d4d4d;
      font-style: normal;
      font-size: 6.51px;
      font-weight: 600;
      line-height: 10.42px;
      text-align: left;
      top: 2px;
      left: auto;
      right: 3px;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider .sliderTextOff{
      display: none;
    }
    .resvisionToggle .toggleDiffOption label span:before{
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      left: 1.5px;
      top: 2px;
      background-color: #fff;
      border: 1px solid #bac3cd;
      transition: .4s;
      border-radius: 50%;
    }
    .resvisionToggle .toggleDiffOption label input:checked + span.slider:before{
      transform: translateX(12px);
      left: 5px;
      border: 1px solid #7a28a0;
      border-radius: 50%;
    }
    .modeDisclaimerText{
      position: relative;
      font-size: 12px !important;
      font-style: italic !important;
      font-weight: 400 !important;
      line-height: 17px !important;
      text-align: left !important;
      color: #111A27 !important;
      padding: 16px 0 0 0 !important;
    }
  `;
__decorate([
    (0, decorators_js_1.property)(),
    __metadata("design:type", Object)
], EditingModesModal.prototype, "cardArr", void 0);
exports.EditingModesModal = EditingModesModal = __decorate([
    (0, decorators_js_1.customElement)('tsdk-editing-modes-modal')
], EditingModesModal);
