"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackChangesEditorOverlay = void 0;
class TrackChangesEditorOverlay {
    constructor(editorId, editor, isCloudTrinka) {
        this.editorId = editorId;
        this.isCloudTrinka = isCloudTrinka;
        this.trinkaExtension = document.createElement('track-changes-trinka-extension');
        const trinkaExtensionShadowRoot = this.trinkaExtension.attachShadow({ mode: 'open' });
        editor.parentElement.appendChild(this.trinkaExtension);
        const style = document.createElement('style');
        style.textContent = `
      strikethrough-text {
        display: inline;
        margin: 0px;
        padding: 0px;
        color: #DC2626;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.07px;
        text-align: left;
        position: relative;
        cursor: default;
        text-decoration: line-through;
      }

      replacement-text {
        display: inline-block;
        margin: 0px;
        padding: 0px;
        color: rgb(17, 26, 39);
        font-size: 14px;
        font-weight: 500;
        line-height: 19.07px;
        text-align: left;
        cursor: pointer;
        background-color: rgba(21, 128, 61, 0.2);
        padding: 0px 2px;
      }
      .spinnerLoader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        display: inline-block;
        border-radius: 50%;
        background: conic-gradient(#0000 10%, #2b14e9);
        -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
        animation: loaderAnimations 1s infinite linear;
      }

      @keyframes loaderAnimations {
        to {
          transform: rotate(1turn);
        }
      }
      #editorOverlayWrapper #editorOverlay{
        min-width: 670px;
        margin: 0 auto !important;
        background: #FFFFFF !important;
      }
      #editorOverlayWrapper #editorOverlay #editorOverlayElements{
        width: auto !important;
        padding: 2% 18% !important;
      }
      #editorOverlayWrapper #editorOverlay #editorOverlayElements:focus-visible,
      #editorOverlayWrapper #editorOverlay #editorOverlayElements p:focus-visible,
      #editorOverlayWrapper #editorOverlay #editorOverlayElements p:focus{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
      #editorOverlayWrapper #editorOverlay #editorOverlayElements p{
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #1E2938;
      }
    `;
        trinkaExtensionShadowRoot.appendChild(style);
        this.editor = editor;
        this.editorOverlayWrapper = this.createOverlay();
        trinkaExtensionShadowRoot.appendChild(this.editorOverlayWrapper);
    }
    changeOverlayVisibility(state) {
        Object.assign(this.editorOverlayWrapper.style, {
            'visibility': state ? 'visible' : 'hidden'
        });
    }
    showLoader() {
        this.editorOverlayLoader.style.display = 'block';
        this.editorOverlay.style.display = 'none';
    }
    hideLoader() {
        this.editorOverlayLoader.style.display = 'none';
        this.editorOverlay.style.display = 'block';
    }
    handleScrollEvent() {
        var _a;
        if (!this.editorOverlay || !this.editorOverlayElements)
            return;
        this.editorOverlay.scrollTop = this.editor.scrollTop;
        this.editorOverlay.scrollLeft = this.editor.scrollLeft;
        Object.assign(this.editorOverlayElements.style, {
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
    }
    handleResizeEvent() {
        var _a;
        if (!this.editorOverlay || !this.editorOverlayElements)
            return;
        const computedstyle = window.getComputedStyle(this.editor);
        Object.assign(this.editorOverlay.style, {
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay.scrollTop = this.editor.scrollTop;
        this.editorOverlay.scrollLeft = this.editor.scrollLeft;
        Object.assign(this.editorOverlayElements.style, {
            'width': `${(parseFloat(computedstyle.width))}px`,
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
    }
    createOverlay() {
        var _a;
        const computedstyle = window.getComputedStyle(this.editor);
        const editorOverlayWrapper = document.createElement('div');
        editorOverlayWrapper.id = 'editorOverlayWrapper';
        Object.assign(editorOverlayWrapper.style, {
            'visibility': 'hidden',
            'position': 'absolute',
            'top': '0px',
            'left': '0px'
        });
        this.editorOverlayElements = document.createElement('div');
        this.editorOverlayElements.id = 'editorOverlayElements';
        this.editorOverlayElements.contentEditable = 'true';
        this.editorOverlayElements.setAttribute('spellcheck', 'false');
        Object.assign(this.editorOverlayElements.style, {
            'position': 'relative',
            'top': '0px',
            'left': '0px',
            'width': `${(parseFloat(computedstyle.width))}px`,
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
        this.editorOverlayViewport = document.createElement('div');
        this.editorOverlayViewport.id = 'editorOverlayViewport';
        this.editorOverlayViewport.classList.add('editorOverlayViewport');
        Object.assign(this.editorOverlayViewport.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'overflow': 'auto',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlayLoader = document.createElement('div');
        this.editorOverlayLoader.id = 'editorOverlayLoader';
        this.editorOverlayLoader.classList.add('editorOverlayLoader');
        Object.assign(this.editorOverlayLoader.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'overflow': 'auto',
            'scrollbar-width': 'none',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`,
            'background': `rgb(128, 128, 128, 38%)`,
            'display': `none`
        });
        const spinner = document.createElement('div');
        spinner.id = 'spinnerLoader';
        spinner.classList.add('spinnerLoader');
        this.editorOverlayLoader.appendChild(spinner);
        this.editorOverlay = document.createElement('div');
        this.editorOverlay.id = 'editorOverlay';
        this.editorOverlay.classList.add('editorOverlay');
        Object.assign(this.editorOverlay.style, {
            'position': 'absolute',
            'overflow': 'auto',
            'scrollbar-width': 'none',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            'background': '#bdc2c5',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay.addEventListener('scroll', ($event) => {
            this.editor.scrollTop = this.editorOverlay.scrollTop;
            this.editor.scrollLeft = this.editorOverlay.scrollLeft;
        });
        this.editorOverlay.appendChild(this.editorOverlayElements);
        editorOverlayWrapper.appendChild(this.editorOverlay);
        editorOverlayWrapper.appendChild(this.editorOverlayViewport);
        editorOverlayWrapper.appendChild(this.editorOverlayLoader);
        return editorOverlayWrapper;
    }
    remove() {
        var _a;
        if (this.editor.parentElement && this.editor.parentElement.contains(this.trinkaExtension)) {
            this.editor.parentElement.removeChild(this.trinkaExtension);
        }
        else
            (_a = this.trinkaExtension) === null || _a === void 0 ? void 0 : _a.remove();
    }
}
exports.TrackChangesEditorOverlay = TrackChangesEditorOverlay;
