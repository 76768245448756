"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketClient = void 0;
const utils_1 = require("../helpers/utils");
class WebSocketClient {
    constructor(url) {
        this.callbackMapper = new Map();
        this.handleOpen = () => {
            console.log('WebSocket connection established');
        };
        this.handleClose = (event) => {
            console.log('WebSocket connection closed:', event.code, event.reason);
        };
        this.handleError = (error) => {
            console.error('WebSocket error:', error);
        };
        this.handleMessage = (event) => {
            let data;
            try {
                data = JSON.parse(decodeURIComponent(atob(event.data)));
                const request_id = `${data.type}-${data.request_id}`;
                if (!this.callbackMapper.has(request_id))
                    return;
                this.callbackMapper.get(request_id).resolve(data);
                this.callbackMapper.delete(request_id);
            }
            catch (error) {
                try {
                    data = JSON.parse(event.data);
                    const request_id = `split_check_reqest-${data.request_id}`;
                    if (!this.callbackMapper.has(request_id))
                        return;
                    this.callbackMapper.get(request_id).resolve(data);
                    this.callbackMapper.delete(request_id);
                }
                catch (error) {
                    console.log('ignore for now ===> ', error);
                }
            }
        };
        this.url = url;
        this.socket = new WebSocket(url);
        this.isOpen = false;
        this.initOpenPromise();
        this.addEventListeners();
    }
    restartSocket(newUrl) {
        this.removeEventListeners();
        this.socket.close();
        this.url = newUrl;
        this.socket = new WebSocket(newUrl);
        this.isOpen = false;
        this.initOpenPromise();
        this.addEventListeners();
    }
    waitForSocketOpen() {
        return this.openPromise;
    }
    initOpenPromise() {
        this.openPromise = new Promise((resolve) => {
            if (this.isOpen) {
                resolve(true);
            }
            else {
                const openHandler = () => {
                    this.isOpen = true;
                    this.socket.removeEventListener('open', openHandler);
                    resolve(true);
                };
                this.socket.addEventListener('open', openHandler);
            }
        });
    }
    addEventListeners() {
        this.socket.onmessage = this.handleMessage;
        this.socket.addEventListener('open', this.handleOpen);
        this.socket.addEventListener('close', this.handleClose);
        this.socket.addEventListener('error', this.handleError);
    }
    removeEventListeners() {
        this.socket.onmessage = () => { };
        this.socket.removeEventListener('open', this.handleOpen);
        this.socket.removeEventListener('close', this.handleClose);
        this.socket.removeEventListener('error', this.handleError);
    }
    isRequestIsInProgress(request_id) {
        return !!this.callbackMapper.has(request_id);
    }
    get getCallbackMapperLength() {
        return this.callbackMapper.size;
    }
    split(payload) {
        const requestId = `split-${payload.request_id}`;
        return new Promise((resolve, reject) => {
            this.callbackMapper.set(requestId, { requestId, text: payload.text, resolve, reject });
            this.send((0, utils_1.encodeString)(JSON.stringify(payload)));
        });
    }
    check(payload) {
        const requestId = `check-${payload.request_id}`;
        return new Promise((resolve, reject) => {
            this.callbackMapper.set(requestId, { requestId, text: payload.text, resolve, reject });
            this.send((0, utils_1.encodeString)(JSON.stringify(payload)));
        });
    }
    splitAndCheck(payload) {
        const requestId = `split_check_reqest-${payload.request_id}`;
        return new Promise((resolve, reject) => {
            this.callbackMapper.set(requestId, { requestId, text: payload.text, resolve, reject });
            this.send(JSON.stringify(payload));
            setTimeout(() => {
                if (this.callbackMapper.has(requestId)) {
                    this.callbackMapper.delete(requestId);
                    reject(false);
                }
            }, 20 * 1000);
        });
    }
    mute(payload, sendPayloadToAPI = false) {
        payload.type = "mute";
        let payloadToBeSent = sendPayloadToAPI ? JSON.stringify(payload) : (0, utils_1.encodeString)(JSON.stringify(payload));
        this.send(payloadToBeSent);
    }
    acceptnoncta(payload, sendPayloadToAPI = false) {
        payload.type = "accept_noncta";
        let payloadToBeSent = sendPayloadToAPI ? JSON.stringify(payload) : (0, utils_1.encodeString)(JSON.stringify(payload));
        this.send(payloadToBeSent);
    }
    accept(payload, sendPayloadToAPI = false) {
        payload.type = "accept";
        let payloadToBeSent = sendPayloadToAPI ? JSON.stringify(payload) : (0, utils_1.encodeString)(JSON.stringify(payload));
        this.send(payloadToBeSent);
    }
    ignore(payload, sendPayloadToAPI = false) {
        payload.type = "ignore";
        let payloadToBeSent = sendPayloadToAPI ? JSON.stringify(payload) : (0, utils_1.encodeString)(JSON.stringify(payload));
        this.send(payloadToBeSent);
    }
    reject(payload, sendPayloadToAPI = false) {
        payload.type = "reject";
        let payloadToBeSent = sendPayloadToAPI ? JSON.stringify(payload) : (0, utils_1.encodeString)(JSON.stringify(payload));
        this.send(payloadToBeSent);
    }
    send(data) {
        this.socket.send(data);
    }
    close() {
        this.removeEventListeners();
        this.socket.close();
    }
}
exports.WebSocketClient = WebSocketClient;
