"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackChangesPanel = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
let TrackChangesPanel = class TrackChangesPanel extends lit_1.LitElement {
    render() {
        return (0, lit_1.html) `
      <div class="trackChangesOuterWrapper">
        <!-- shimmer loader container -->
        <div class="trackChangesShimmerLoader" style="display: none">
          <p class="loaderLine"></p>
          <ul>
            <li class="loaderLine"></li>
            <li class="loaderLine"></li>
            <li class="loaderLine"></li>
            <li class="loaderLine"></li>
          </ul>
        </div>

        <div class="trackChangesInnerContainer">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
          <ul>
            <li>
              <button class="acceptAllButton" @click=${() => this.userAction('accept-all')}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 10.9453V15.9453C22.5 20.9453 20.5 22.9453 15.5 22.9453H9.5C4.5 22.9453 2.5 20.9453 2.5 15.9453V9.94531C2.5 4.94531 4.5 2.94531 9.5 2.94531H14.5" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22.5 10.9453H18.5C15.5 10.9453 14.5 9.94531 14.5 6.94531V2.94531L22.5 10.9453Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.5 15.4385L10.1398 17.0783L14.3508 12.9453" stroke="#9CA3AF" stroke-width="1.54985" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Accept All
              </button>
            </li>
            <li>
              <button class="rejectAllButton" @click=${() => this.userAction('reject-all')}>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 10.9453V15.9453C22 20.9453 20 22.9453 15 22.9453H9C4 22.9453 2 20.9453 2 15.9453V9.94531C2 4.94531 4 2.94531 9 2.94531H14" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 10.9453H18C15 10.9453 14 9.94531 14 6.94531V2.94531L22 10.9453Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 16.9453L9 12.9453" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 12.9453L9 16.9453" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                Reject All
              </button>
            </li>
            <li>
              <button class="acceptButton" @click=${() => this.userAction('accept')}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 10.9453V15.9453C22.5 20.9453 20.5 22.9453 15.5 22.9453H9.5C4.5 22.9453 2.5 20.9453 2.5 15.9453V9.94531C2.5 4.94531 4.5 2.94531 9.5 2.94531H14.5" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22.5 10.9453H18.5C15.5 10.9453 14.5 9.94531 14.5 6.94531V2.94531L22.5 10.9453Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.5 15.4385L10.1398 17.0783L14.3508 12.9453" stroke="#9CA3AF" stroke-width="1.54985" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Accept
              </button>
            </li>
            <li>
              <button class="rejectButton" @click=${() => this.userAction('reject')}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 10.9453V15.9453C22 20.9453 20 22.9453 15 22.9453H9C4 22.9453 2 20.9453 2 15.9453V9.94531C2 4.94531 4 2.94531 9 2.94531H14" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 10.9453H18C15 10.9453 14 9.94531 14 6.94531V2.94531L22 10.9453Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 16.9453L9 12.9453" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 12.9453L9 16.9453" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                Reject
              </button>
            </li>
            <li>
              <button class="undoButton" @click=${() => this.userAction('undo')}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2071 6.24211C10.5976 6.63264 10.5976 7.2658 10.2071 7.65633L7.91421 9.94922H16.5C17.8261 9.94922 19.0979 10.476 20.0355 11.4137C20.9732 12.3514 21.5 13.6231 21.5 14.9492C21.5 16.2753 20.9732 17.5471 20.0355 18.4848C19.0979 19.4224 17.8261 19.9492 16.5 19.9492H15.5C14.9477 19.9492 14.5 19.5015 14.5 18.9492C14.5 18.3969 14.9477 17.9492 15.5 17.9492H16.5C17.2956 17.9492 18.0587 17.6331 18.6213 17.0705C19.1839 16.5079 19.5 15.7449 19.5 14.9492C19.5 14.1536 19.1839 13.3905 18.6213 12.8279C18.0587 12.2653 17.2956 11.9492 16.5 11.9492H7.91421L10.2071 14.2421C10.5976 14.6326 10.5976 15.2658 10.2071 15.6563C9.81658 16.0469 9.18342 16.0469 8.79289 15.6563L4.79289 11.6563C4.40237 11.2658 4.40237 10.6326 4.79289 10.2421L8.79289 6.24211C9.18342 5.85159 9.81658 5.85159 10.2071 6.24211Z" fill="#9CA3AF"/>
                </svg>
                Undo
              </button>
            </li>
            <li>
              <button class="redoButton" @click=${() => this.userAction('redo')}>
                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 1.23821C10.9024 1.62873 10.9024 2.2619 11.2929 2.65242L13.5858 4.94531H5C3.67392 4.94531 2.40215 5.4721 1.46447 6.40978C0.526785 7.34746 0 8.61923 0 9.94531C0 11.2714 0.526785 12.5432 1.46447 13.4808C2.40215 14.4185 3.67392 14.9453 5 14.9453H6C6.55229 14.9453 7 14.4976 7 13.9453C7 13.393 6.55229 12.9453 6 12.9453H5C4.20435 12.9453 3.44129 12.6292 2.87868 12.0666C2.31607 11.504 2 10.741 2 9.94531C2 9.14966 2.31607 8.3866 2.87868 7.82399C3.44129 7.26138 4.20435 6.94531 5 6.94531H13.5858L11.2929 9.23821C10.9024 9.62873 10.9024 10.2619 11.2929 10.6524C11.6834 11.0429 12.3166 11.0429 12.7071 10.6524L16.7071 6.65242C17.0976 6.2619 17.0976 5.62873 16.7071 5.23821L12.7071 1.23821C12.3166 0.847681 11.6834 0.847681 11.2929 1.23821Z" fill="#9CA3AF"/>
                </svg>
                Redo
              </button>
            </li>
          </ul>
        </div>

      </div>
      `;
    }
    constructor() {
        super();
        this.isCloudTrinka = false;
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    userAction(action) {
        const event = new CustomEvent('tsdk-draggable-panel-track-changes-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                action
            }
        });
        document.dispatchEvent(event);
    }
};
exports.TrackChangesPanel = TrackChangesPanel;
TrackChangesPanel.styles = (0, lit_1.css) `
.trackChangesOuterWrapper {
  position: relative;
  padding: 24px 16px 50px 16px;
  margin-top: 0px;
  background: #FFFFFF;
  height: calc(100% - 154px);
  overflow-y: auto;
}
.loaderLine{
  animation-duration: 3.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmerLoader;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}
@-webkit-keyframes shimmerLoader {
  0% {
      background-position: -100% 0;
  }
  100% {
      background-position: 100% 0;
  }
}

@keyframes shimmerLoader {
  0% {
      background-position: -1200px 0;
  }
  100% {
      background-position: 1200px 0;
  }
}
.trackChangesShimmerLoader{
  position: relative;
}
.trackChangesShimmerLoader p{
  height: 30px;
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 14px;
}
.trackChangesShimmerLoader ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.trackChangesShimmerLoader ul li{
    width: 42%;
    margin-bottom: 16px;
    height: 30px;
    border-radius: 6px;
}
.trackChangesInnerContainer{
  position: relative;
}
.trackChangesInnerContainer p{
  color: #111A27;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 14px;
}
.trackChangesInnerContainer ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.trackChangesInnerContainer ul li{
  width: 48%;
  margin-bottom: 16px;
}
.trackChangesInnerContainer ul li button{
  background: #EEEEEE;
  border: none;
  height: 40px;
  border-radius: 4px;
  color: #9CA3AF;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.5s ease;
}
.trackChangesInnerContainer ul li button:focus{
  box-shadow: none;
  outline: none;
}
.trackChangesInnerContainer ul li button svg{
  margin-right: 6px;
  transition: all 0.5s ease;
}
.trackChangesInnerContainer ul li button.acceptAllButton:hover,
.trackChangesInnerContainer ul li button.acceptButton:hover,
.trackChangesInnerContainer ul li button.undoButton:hover{
  cursor: pointer;
  background: #15803D;
  color: #FFFFFF;
  transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.trackChangesInnerContainer ul li button.acceptAllButton:hover svg path,
.trackChangesInnerContainer ul li button.acceptButton:hover svg path{
  stroke: #FFFFFF;
  transition: all 0.5s ease;
}

.trackChangesInnerContainer ul li button.rejectAllButton:hover,
.trackChangesInnerContainer ul li button.rejectButton:hover,
.trackChangesInnerContainer ul li button.redoButton:hover{
  cursor: pointer;
  background: #DC2626;
  color: #FFFFFF;
  transition: all 0.5s ease;
}
.trackChangesInnerContainer ul li button.rejectAllButton:hover svg path,
.trackChangesInnerContainer ul li button.rejectButton:hover svg path{
  stroke: #FFFFFF;
  transition: all 0.5s ease;
}
.trackChangesInnerContainer ul li button.redoButton:hover svg path,
.trackChangesInnerContainer ul li button.undoButton:hover svg path{
  fill: #FFFFFF;
  transition: all 0.5s ease;
}
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], TrackChangesPanel.prototype, "isCloudTrinka", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], TrackChangesPanel.prototype, "editorId", void 0);
exports.TrackChangesPanel = TrackChangesPanel = __decorate([
    (0, decorators_js_1.customElement)('track-changes-panel'),
    __metadata("design:paramtypes", [])
], TrackChangesPanel);
