"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertCounter = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let AlertCounter = class AlertCounter extends lit_1.LitElement {
    render() {
        return (0, lit_1.html) `
    <style>${this.css}</style>
    <div id="alert-counter" style=${this.getAlertCounterStyle()} @click=${this.alertCounterClicked}>
      <div id="loader" style=${this.getLoaderStyle()}></div>
      <div id="counter" style=${this.getCounterStyle()}>${this.counter}</div>
      <span class="hoverIconErrorSection">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
          d="M14.6 10.0124L12.8796 11.7327L10.3068 9.15988L9.15988 10.3068L11.7327 12.8796L10.0124 14.6H14.6V10.0124ZM0 4.58764L1.72037 2.86728L4.29321 5.44012L5.44012 4.29321L2.86728 1.72037L4.58764 0H0V4.58764ZM14.6 0H10.0124L11.7327 1.72037L9.15988 4.29321L10.3068 5.44012L12.8796 2.86728L14.6 4.58764V0ZM0 14.6H4.58764L2.86728 12.8796L5.44012 10.3068L4.29321 9.15988L1.72037 11.7327L0 10.0124V14.6Z"
          fill="white"></path>
        </svg>
      </span>
      <span class="tooltipHoverEffect">Click to expand alerts</span>
    </div>
    `;
    }
    constructor(uiConfig, editorId) {
        super();
        this.css = `
  #alert-counter {
    position: absolute;
    bottom: 2px;
    right: 2px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    margin: 2px 2px 0 0;
    font-family: Oxygen,Geneva,sans-serif;
    pointer-events: visible;
    z-index: 4;
  }

  #loader {
    display: none;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  .hoverIconErrorSection{
    display: none;
  }

  .tooltipHoverEffect{
    line-height : 1.2em !important;
    position: absolute;
    z-index: 9999;
    left: -144px;
    background: #b4b4b4;
    width: 130px;
    right: auto;
    text-align: center;
    border-radius: 3px;
    padding: 4px 4px;
    top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1d1d1d;
    display: none;
  }

  .tooltipHoverEffect:after{
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #b4b4b4;
    border-width: 5px;
    margin-top: -5px;
  }

  #alert-counter:hover span.hoverIconErrorSection {
    display: flex !important;
  }

  #alert-counter:hover span.tooltipHoverEffect {
    display: flex !important;
  }

  #alert-counter:hover div#loader {
    display: none !important;
  }

  #alert-counter:hover div#counter {
    display: none !important;
  }
  `;
        this.loader = false;
        this.counter = 0;
        this.hideAlertCounter = false;
        this.currentLang = 'en';
        this.uiConfig = uiConfig;
        this.editorId = editorId;
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    getAlertCounterStyle() {
        return `display: ${this.uiConfig.counter && !this.hideAlertCounter ? 'flex' : 'none'}; background: ${this.counter > 0 ? 'linear-gradient(0deg, rgba(194, 38, 37, 1) 0%, rgba(247, 83, 84, 1) 100%);' : 'linear-gradient(0deg, rgba(69,48,127,1) 0%, rgba(124,75,154,1) 100%) !important'}`;
    }
    getLoaderStyle() {
        return `display: ${this.uiConfig.counter && this.loader ? 'block' : 'none'};`;
    }
    getCounterStyle() {
        return `display: ${this.uiConfig.counter && this.loader ? 'none' : 'block'};`;
    }
    alertCounterClicked() {
        document.dispatchEvent(new CustomEvent('tsdk-draggable-panel-toggle', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                isOpen: true
            }
        }));
    }
    updateCounter(count) {
        this.counter = count;
    }
};
exports.AlertCounter = AlertCounter;
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Boolean)
], AlertCounter.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Number)
], AlertCounter.prototype, "counter", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AlertCounter.prototype, "hideAlertCounter", void 0);
exports.AlertCounter = AlertCounter = __decorate([
    (0, decorators_js_1.customElement)('tsdk-alert-counter'),
    __metadata("design:paramtypes", [Object, Object])
], AlertCounter);
