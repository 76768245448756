"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoAlertsPanel = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let NoAlertsPanel = class NoAlertsPanel extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.loader = false;
        this.isEditorEmpty = false;
        this.currentLang = 'en';
    }
    render() {
        return (0, lit_1.html) `
      <div class="noTextDetected" style="display: ${this.loader ? 'none' : 'flex'}">
        ${this.isEditorEmpty ? (0, lit_1.html) `
          <div class="messageBlock emptyEditor">
          <svg xmlns="http://www.w3.org/2000/svg" width="164" height="136" viewBox="0 0 164 136" fill="none">
            <path opacity="0.27" fill-rule="evenodd" clip-rule="evenodd" d="M29.4475 106.155H14.9418H14.5653C6.55404 106.155 0 112.709 0 120.72C0 128.732 6.55404 135.286 14.5653 135.286H57.0846H64.4087H140.417C153.389 135.286 164 124.673 164 111.703C164 98.7311 153.387 88.1204 140.417 88.1182C134.933 88.1182 130.445 83.6319 130.445 78.1478C130.445 72.6638 134.933 68.1753 140.417 68.1753H152.419C158.12 68.1753 162.785 63.5103 162.785 57.8071C162.785 52.1061 158.12 47.4389 152.419 47.4389H137.244C135.023 47.4389 133.208 45.6222 133.208 43.4035C133.208 41.1827 135.025 39.3681 137.244 39.3681H139.92C141.451 39.3681 142.704 38.1152 142.704 36.5814C142.704 35.0498 141.451 33.7969 139.92 33.7969H129.894C129.894 33.7969 126.063 33.7969 122.366 33.7969C118.668 33.7969 118.258 38.1641 108.919 38.1641C94.4838 38.1641 99.6551 38.1641 85.2196 38.1641C83.22 38.1641 81.5841 39.8 81.5841 41.7996C81.5841 43.7992 83.22 45.435 85.2196 45.435H87.4107C90.308 45.435 92.6777 47.8048 92.6777 50.7021C92.6777 53.5994 90.308 55.9692 87.4107 55.9692H80.2504C66.0638 55.9692 64.0599 59.677 61.5753 67.05C59.0906 74.423 46.1868 75.0187 46.1868 75.0187H28.5434C24.1783 75.0187 20.6066 78.5903 20.6066 82.9554C20.6066 87.3205 24.1783 90.8922 28.5434 90.8922H29.4517C33.6509 90.8922 37.0864 94.3277 37.0864 98.5268C37.0821 102.722 33.6466 106.155 29.4475 106.155ZM147.733 39.3681C146.195 39.3681 144.948 38.1216 144.948 36.5814C144.948 35.0434 146.195 33.7969 147.733 33.7969C149.271 33.7969 150.52 35.0434 150.52 36.5814C150.518 38.1194 149.271 39.3681 147.733 39.3681Z" fill="url(#paint0_linear)"/>
            <path opacity="0.22" d="M81.9987 126.197C112.358 126.197 136.969 124.926 136.969 123.357C136.969 121.789 112.358 120.518 81.9987 120.518C51.6395 120.518 27.0286 121.789 27.0286 123.357C27.0286 124.926 51.6395 126.197 81.9987 126.197Z" fill="url(#paint1_linear)"/>
            <path d="M44.5851 122.571H119.415C124.701 122.571 128.988 118.284 128.988 112.998V40.6333L89.6337 1.2793H44.5851C39.2989 1.2793 35.0125 5.5657 35.0125 10.8519V112.998C35.0125 118.284 39.2967 122.571 44.5851 122.571Z" fill="white" stroke="url(#paint2_linear)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M89.4573 1.2793V31.2352C89.4573 36.5214 93.7437 40.8078 99.0299 40.8078H128.986" stroke="url(#paint3_linear)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear" x1="81.9988" y1="33.7961" x2="81.9988" y2="135.285" gradientUnits="userSpaceOnUse">
            <stop stop-color="#C7B4D8" stop-opacity="0.3"/>
            <stop offset="1" stop-color="#937DB9"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="283.183" y1="122.434" x2="79.326" y2="123.369" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.6778" stop-color="#BBA6D0"/>
            <stop offset="1" stop-color="#816BAF"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="129.305" y1="61.9247" x2="34.6925" y2="61.9247" gradientUnits="userSpaceOnUse">
            <stop stop-color="#6367AF"/>
            <stop offset="0.999" stop-color="#9882BC"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="129.306" y1="21.0441" x2="89.1381" y2="21.0441" gradientUnits="userSpaceOnUse">
            <stop stop-color="#6367AF"/>
            <stop offset="0.999" stop-color="#9882BC"/>
            </linearGradient>
            </defs>
          </svg>
          <p>Start writing or upload any document to get corrections for your text.</p>
          </div>` :
            (0, lit_1.html) `
        <svg viewBox="0 0 164 136" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.27" fill-rule="evenodd" clip-rule="evenodd"
          d="M29.4475 105.875H14.9418H14.5653C6.55404 105.875 0 112.429 0 120.44C0 128.451 6.55404 135.005 14.5653 135.005H57.0846H64.4087H140.417C153.389 135.005 164 124.393 164 111.423C164 98.4508 153.387 87.8401 140.417 87.8379C134.933 87.8379 130.445 83.3516 130.445 77.8675C130.445 72.3835 134.933 67.895 140.417 67.895H152.419C158.12 67.895 162.785 63.23 162.785 57.5268C162.785 51.8258 158.12 47.1586 152.419 47.1586H137.244C135.023 47.1586 133.208 45.342 133.208 43.1232C133.208 40.9024 135.025 39.0879 137.244 39.0879H139.92C141.451 39.0879 142.704 37.8349 142.704 36.3012C142.704 34.7696 141.451 33.5166 139.92 33.5166H129.894C129.894 33.5166 126.063 33.5166 122.366 33.5166C118.668 33.5166 118.258 37.8838 108.919 37.8838C94.4837 37.8838 99.6551 37.8838 85.2196 37.8838C83.22 37.8838 81.5841 39.5197 81.5841 41.5193C81.5841 43.5189 83.22 45.1548 85.2196 45.1548H87.4107C90.308 45.1548 92.6777 47.5245 92.6777 50.4218C92.6777 53.3191 90.308 55.6889 87.4107 55.6889H80.2504C66.0638 55.6889 64.0599 59.3967 61.5753 66.7697C59.0906 74.1427 46.1868 74.7384 46.1868 74.7384H28.5434C24.1783 74.7384 20.6066 78.31 20.6066 82.6751C20.6066 87.0402 24.1783 90.6119 28.5434 90.6119H29.4517C33.6509 90.6119 37.0864 94.0474 37.0864 98.2466C37.0821 102.441 33.6466 105.875 29.4475 105.875ZM147.733 39.0879C146.195 39.0879 144.948 37.8413 144.948 36.3012C144.948 34.7632 146.195 33.5166 147.733 33.5166C149.271 33.5166 150.52 34.7632 150.52 36.3012C150.518 37.8392 149.271 39.0879 147.733 39.0879Z"
          fill="url(#paint0_linear_1_3683)"></path>
          <path opacity="0.22"
          d="M81.9985 125.918C112.358 125.918 136.969 124.647 136.969 123.078C136.969 121.51 112.358 120.238 81.9985 120.238C51.6393 120.238 27.0283 121.51 27.0283 123.078C27.0283 124.647 51.6393 125.918 81.9985 125.918Z"
          fill="url(#paint1_linear_1_3683)"></path>
          <path
          d="M119.413 122.291H44.5853C39.2991 122.291 35.0127 118.005 35.0127 112.719V10.5726C35.0127 5.2864 39.2991 1 44.5853 1H119.415C124.702 1 128.988 5.2864 128.988 10.5726V112.719C128.986 118.005 124.702 122.291 119.413 122.291Z"
          fill="white" stroke="url(#paint2_linear_1_3683)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round"></path>
          <path
          d="M59.5572 60.2201C60.7527 60.2478 62.4502 60.9115 63.7542 62.4006C66.2814 65.2851 68.7809 68.1952 71.2911 71.0925C71.6846 71.5477 72.1037 71.9859 72.4738 72.4603C73.2651 73.4729 73.8565 73.3176 74.6521 72.3518C84.7629 60.0925 94.9077 47.8651 105.042 35.6271C105.821 34.6868 106.695 33.8593 107.79 33.3126C109.907 32.2554 111.579 33.3296 111.487 35.6845C111.428 37.2161 110.751 38.5456 109.949 39.7986C102.934 50.7326 95.9033 61.6582 88.877 72.5858C85.8223 77.336 82.7995 82.1053 79.7043 86.8256C76.673 91.4502 71.808 91.5375 68.2746 87.2574C64.0882 82.1903 59.738 77.2573 55.4665 72.2582C54.8262 71.5094 54.1646 70.7649 53.6328 69.9395C52.3097 67.8889 52.0969 65.7212 53.3392 63.5621C54.5326 61.4837 56.2727 60.1904 59.5572 60.2201Z"
          fill="white" stroke="url(#paint3_linear_1_3683)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round"></path>
          <defs>
            <linearGradient id="paint0_linear_1_3683" x1="81.9989" y1="33.5158" x2="81.9989" y2="135.004" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C7B4D8" stop-opacity="0.3"></stop>
              <stop offset="1" stop-color="#937DB9"></stop>
            </linearGradient>
            <linearGradient id="paint1_linear_1_3683" x1="283.183" y1="122.155" x2="79.3259" y2="123.09" gradientUnits="userSpaceOnUse">
              <stop stop-color="white"></stop>
              <stop offset="0.6778" stop-color="#BBA6D0"></stop>
              <stop offset="1" stop-color="#816BAF"></stop>
            </linearGradient>
            <linearGradient id="paint2_linear_1_3683" x1="34.6928" y1="61.6454" x2="129.306" y2="61.6454" gradientUnits="userSpaceOnUse">
              <stop stop-color="#6367AF"></stop>
              <stop offset="0.999" stop-color="#9882BC"></stop>
            </linearGradient>
            <linearGradient id="paint3_linear_1_3683" x1="52.1916" y1="61.6452" x2="111.808" y2="61.6452" gradientUnits="userSpaceOnUse">
              <stop stop-color="#6367AF"></stop>
              <stop offset="0.999" stop-color="#9882BC"></stop>
            </linearGradient>
          </defs>
        </svg>
        <p>No consistency issues found</p>
        <span>Trinka has checked your text thoroughly and did not find any consistency issues.</span>
      </div>`}
    `;
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
};
exports.NoAlertsPanel = NoAlertsPanel;
NoAlertsPanel.styles = (0, lit_1.css) `
  .noTextDetected {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 40px;
    bottom: 0px;
    margin: auto;
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .noTextDetected svg {
    width: 120px;
    margin-bottom: 20px;
  }

  .noTextDetected p {
    color: #4d4d4d;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  .noTextDetected span {
    text-align: center;
    display: block;
    font-size: 14px;
    padding: 0 23px;
    margin: 20px 0 0 0;
    color: rgba(77,77,77,0.8);
    line-height: 20px;
  }

  .messageBlock.emptyEditor {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    margin: 0 15px;
    text-align: center;
  }
  .messageBlock.emptyEditor p{
    font-size: 18px;
    max-width: 100%;
    text-align: center;
    color: #4d4d4d;
    padding: 0 15px;
    font-weight: 400;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], NoAlertsPanel.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], NoAlertsPanel.prototype, "isEditorEmpty", void 0);
exports.NoAlertsPanel = NoAlertsPanel = __decorate([
    (0, decorators_js_1.customElement)('no-alerts-panel-cc')
], NoAlertsPanel);
