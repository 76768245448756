"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CcUnderline = void 0;
class CcUnderline {
    constructor(editor, editorId, editorOverlay, draggablePanel, segment, alert, uiConfig) {
        this.underlines = [];
        this.color = {
            borderColor: 'rgba(255, 92, 92, 0.7)',
            bgColor: 'rgba(255, 92, 92, 0.5)',
            type: 'Default'
        };
        this.isVisible = true;
        this.editor = editor;
        this.editorId = editorId;
        this.editorOverlay = editorOverlay;
        this.draggablePanel = draggablePanel;
        this.segment = segment;
        this.alert = alert;
        this.uiConfig = uiConfig;
        if (!segment)
            return;
        let startOfTextNode = 0;
        let listOfBounds = this.prepUnderlineBounds(segment, alert);
        listOfBounds.forEach((bounds) => {
            const newUnderline = this.createNewUnderline(alert, bounds);
            if (newUnderline instanceof Node) {
                this.underlines.push(newUnderline);
            }
        });
    }
    updateUnderlinePosition() {
        this.remove();
        let listOfBounds = this.prepUnderlineBounds(this.segment, this.alert);
        listOfBounds.forEach((bounds) => {
            const newUnderline = this.createNewUnderline(this.alert, bounds);
            if (newUnderline instanceof Node) {
                this.underlines.push(newUnderline);
            }
        });
    }
    setVisibility(state) {
        this.isVisible = state;
        this.underlines.forEach((underline) => {
            underline.style.display = `${this.isVisible ? 'block' : 'none'}`;
        });
    }
    selectUnderline() {
        this.underlines.forEach((underline) => {
            underline.style.backgroundColor = this.getAlertTypeColor(10, 'bgColor');
            underline.classList.add('selected');
        });
    }
    unselectUnderline() {
        this.underlines.forEach((underline) => {
            underline.style.backgroundColor = '';
            underline.classList.remove('selected');
        });
    }
    remove() {
        while (this.underlines.length > 0) {
            let underline = this.underlines.shift();
            if (!underline)
                continue;
            underline.removeEventListener('click', this.boundHandleClickEvent);
            underline.remove();
        }
    }
    prepUnderlineBounds(segment, alert) {
        let listOfBounds = [];
        const rangeOffset = 0;
        const selectedNodes = this.selectStartEndnodes(segment.textNodes, rangeOffset, alert.begin, alert.end - 1);
        const selectNodesBounds = this.generateUnderlineBounds(selectedNodes);
        if (selectNodesBounds.hasWordBreak) {
            listOfBounds = this.generateUnderlineBoundsForMultilineSpan(segment.textNodes, rangeOffset, alert);
        }
        else {
            listOfBounds.push({ bound: selectNodesBounds, selectedNodes: selectedNodes });
        }
        return listOfBounds;
    }
    createNewUnderline(alert, bounds) {
        const containerBounds = this.editorOverlay.getBoundingClientRect();
        let underline = document.createElement('div');
        underline.id = alert.alertId;
        const newBounds = {
            width: bounds.bound.width,
            height: bounds.bound.height,
            top: bounds.bound.top - containerBounds.top + this.editorOverlay.scrollTop,
            left: bounds.bound.left - containerBounds.left + this.editorOverlay.scrollLeft
        };
        if (newBounds.top < 0 || newBounds.left < 0) {
            return;
        }
        Object.assign(underline.style, {
            'border-bottom': '3px solid',
            'border-bottom-color': `${this.getAlertTypeColor(10, 'borderColor')}`,
            'position': 'absolute',
            'pointer-events': 'all',
            'transition': 'height 0.3s ease, width 0.3s ease, top 0.3s ease, left 0.3s ease, border-bottom-color 0.3s ease',
            'cursor': 'pointer',
            'z-index': '99 !important',
            'height': `${newBounds.height}px`,
            'width': `${newBounds.width}px`,
            'top': `${newBounds.top - 1}px`,
            'left': `${newBounds.left}px`,
            'display': `${this.isVisible ? 'block' : 'none'}`,
        });
        this.boundHandleClickEvent = ($event) => this.handleClickEvent($event);
        underline.addEventListener('click', this.boundHandleClickEvent);
        return underline;
    }
    generateUnderlineBoundsForMultilineSpan(textBlocks, rangeOffset, alert) {
        let arrayOfword = Array.from(alert.text);
        let prevHeight = 0;
        let prevBounds;
        let prevNodes;
        let beginCount = 0;
        let arrayOfRange = [];
        let start = alert.begin;
        let updatedSelectedNodes;
        let arraylength = arrayOfword.length;
        for (let index = 0; index < arraylength; index++) {
            updatedSelectedNodes = this.selectStartEndnodes(textBlocks, rangeOffset, start, start + beginCount);
            let wb = this.generateUnderlineBounds(updatedSelectedNodes);
            if (prevHeight != 0 && wb.height > prevHeight) {
                arrayOfRange.push({ bound: prevBounds, selectedNodes: prevNodes });
                prevHeight = 0;
                start = start + beginCount;
                beginCount = 1;
                continue;
            }
            prevHeight = wb.height;
            prevBounds = wb;
            prevNodes = updatedSelectedNodes;
            beginCount++;
        }
        arrayOfRange.push({ bound: prevBounds, selectedNodes: prevNodes });
        return arrayOfRange;
    }
    acceptAlert(correctionText) {
        const selectedNodes = this.selectStartEndnodes(this.segment.textNodes, 0, this.alert.begin, this.alert.end - 1);
        this.replaceTextInEditor(selectedNodes, correctionText);
    }
    rejectAlert() {
        this.remove();
    }
    replaceTextInEditor(selectedNodes, textToReplace) {
        let ckeditorInstance = this.editor.ckeditorInstance;
        if (selectedNodes.startNode === selectedNodes.endNode) {
            var offset = selectedNodes.startNode.textContent.indexOf(this.alert.text);
            if (!ckeditorInstance)
                selectedNodes.startNode.replaceData(offset, this.alert.text.length, textToReplace);
            else
                this.replaceTextInCKEditor(ckeditorInstance, textToReplace, selectedNodes.startIndexInSegment, selectedNodes.endIndexInSegment, selectedNodes.startNode, selectedNodes.endNode);
        }
        else {
            let filteredList = [];
            let filter = false;
            for (let i = 0; i < selectedNodes.textBlocks.length; i++) {
                let startN = selectedNodes.startNode;
                let endN = selectedNodes.endNode;
                if (startN === selectedNodes.textBlocks[i]) {
                    filter = true;
                }
                if (endN === selectedNodes.textBlocks[i]) {
                    filter = false;
                }
                if (filter && startN !== selectedNodes.textBlocks[i] && endN !== selectedNodes.textBlocks[i]) {
                    filteredList.push(selectedNodes.textBlocks[i]);
                }
            }
            if (!ckeditorInstance) {
                const startNode = selectedNodes.startNode;
                startNode.replaceData(selectedNodes.startOffset, startNode.length - selectedNodes.startOffset, textToReplace);
                const endNode = selectedNodes.endNode;
                endNode.replaceData(0, selectedNodes.endNode.length, '');
                for (let i = 0; i < filteredList.length; i++) {
                    filteredList[i].replaceData(0, filteredList[i].length, '');
                }
            }
            else
                this.replaceTextInCKEditor(ckeditorInstance, textToReplace, selectedNodes.startIndexInSegment, selectedNodes.endIndexInSegment, selectedNodes.startNode, selectedNodes.endNode);
        }
    }
    handleClickEvent($event) {
        const event = new CustomEvent('tsdk-editor-underline-clicked', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: this.alert.alertId
            }
        });
        document.dispatchEvent(event);
    }
    selectStartEndnodes(textBlocks, rangeOffset, beginIndex, endIndex) {
        var _a, _b, _c;
        const start = rangeOffset + beginIndex;
        const end = rangeOffset + endIndex;
        let pointer = 0;
        let startNode;
        let endNode;
        let startOffset = 0;
        let endOffset = 0;
        let response;
        textBlocks = textBlocks.filter((x) => x);
        startNode = textBlocks[0];
        endNode = textBlocks[textBlocks.length - 1];
        for (let i = 0; i < textBlocks.length; i++) {
            if (pointer <= start && start <= ((_a = textBlocks[i]) === null || _a === void 0 ? void 0 : _a.length) + pointer - 1) {
                startNode = textBlocks[i];
                startOffset = start - pointer;
            }
            if (pointer <= end && end <= ((_b = textBlocks[i]) === null || _b === void 0 ? void 0 : _b.length) + pointer - 1) {
                endNode = textBlocks[i];
                endOffset = end - pointer + 1;
            }
            pointer = pointer + ((_c = textBlocks[i]) === null || _c === void 0 ? void 0 : _c.length);
        }
        let startIndexInSegment = beginIndex;
        let endIndexInSegment = endIndex + 1;
        response = { startNode, endNode, startOffset, endOffset, textBlocks, startIndexInSegment, endIndexInSegment };
        return response;
    }
    generateUnderlineBounds(selectedNodes) {
        const range = document.createRange();
        try {
            range.setStart(selectedNodes.startNode, selectedNodes.startOffset);
            range.setEnd(selectedNodes.endNode, selectedNodes.endOffset);
        }
        catch (error) { }
        let newRange = range.getBoundingClientRect();
        const startRect = range.getBoundingClientRect();
        range.collapse(false);
        const endRect = range.getBoundingClientRect();
        newRange['hasWordBreak'] = !(startRect.height === endRect.height);
        return newRange;
    }
    getAlertTypeColor(alertType, colorType) {
        let currentAlertColorConfig = {};
        switch (alertType) {
            default:
                currentAlertColorConfig = this.color;
                break;
        }
        if (!currentAlertColorConfig)
            currentAlertColorConfig = this.color;
        return currentAlertColorConfig[colorType];
    }
    replaceTextInCKEditor(editor, newText, startOffset, endOffset, startNode, endNode) {
        var _a, _b;
        const view = editor.editing.view;
        const model = editor.model;
        let that = this;
        const domRoot = view.getDomRoot();
        const scrollPosition = getScrollPosition(editor);
        const startViewNode = checkNodeInViewHierarchy(startNode);
        let startTextNode = (_a = startViewNode === null || startViewNode === void 0 ? void 0 : startViewNode._children) === null || _a === void 0 ? void 0 : _a.filter((x) => { var _a, _b; return ((_a = x === null || x === void 0 ? void 0 : x.data) === null || _a === void 0 ? void 0 : _a.replace(/\u00A0/g, ' ')) === ((_b = startNode === null || startNode === void 0 ? void 0 : startNode.textContent) === null || _b === void 0 ? void 0 : _b.replace(/\u00A0/g, ' ')); })[0];
        const endViewNode = checkNodeInViewHierarchy(endNode);
        let endTextNode = (_b = endViewNode === null || endViewNode === void 0 ? void 0 : endViewNode._children) === null || _b === void 0 ? void 0 : _b.filter((x) => { var _a, _b; return ((_a = x === null || x === void 0 ? void 0 : x.data) === null || _a === void 0 ? void 0 : _a.replace(/\u00A0/g, ' ')) === ((_b = endNode === null || endNode === void 0 ? void 0 : endNode.textContent) === null || _b === void 0 ? void 0 : _b.replace(/\u00A0/g, ' ')); })[0];
        if (!startViewNode || !endViewNode) {
            console.log('DOM node is not part of the CKEditor view.');
            return;
        }
        const startViewPos = view.createPositionAt(startTextNode, startOffset);
        const endViewPos = view.createPositionAt(endTextNode, endOffset);
        const viewRange = view.createRange(startViewPos, endViewPos);
        const modelRange = editor.editing.mapper.toModelRange(viewRange);
        let newPosition;
        model.change(writer => {
            let indexInPara = this.segment.text.indexOf(getNodeText(modelRange.start.parent)) > -1 ? this.segment.text.indexOf(getNodeText(modelRange.start.parent)) : getStartIndexWithSpaces(this.segment.text, getNodeText(modelRange.start.parent));
            if (indexInPara === -1)
                return;
            replaceTextInNode(writer, modelRange.start.parent, startOffset - indexInPara, endOffset - indexInPara, newText);
            newPosition = writer.createPositionAt(modelRange.start.parent, startOffset + newText.length);
        });
        setTimeout(() => {
        }, 20);
        function getScrollPosition(editor) {
            const editableElement = editor.editing.view.getDomRoot();
            return {
                top: editableElement.scrollTop,
                left: editableElement.scrollLeft
            };
        }
        function setScrollPosition(editor, position) {
            const editableElement = editor.editing.view.getDomRoot();
            editableElement.scrollTop = position.top;
            editableElement.scrollLeft = position.left;
        }
        function checkNodeInViewHierarchy(node) {
            const view = editor.editing.view;
            let viewNode = view.domConverter.mapDomToView(node);
            if (viewNode) {
                return viewNode;
            }
            let ancestor = node.parentNode;
            while (ancestor) {
                viewNode = view.domConverter.mapDomToView(ancestor);
                if (viewNode) {
                    return viewNode;
                }
                ancestor = ancestor.parentNode;
            }
            return null;
        }
        function getNodeText(node) {
            let textContent = '';
            for (const child of node.getChildren()) {
                if (child.is('text')) {
                    textContent += child.data;
                }
                else if (child.is('element')) {
                    textContent += getNodeText(child);
                }
            }
            return textContent;
        }
        function replaceTextInNode(writer, node, startOffset, endOffset, newText) {
            let offsetCounter = 0;
            let remainingText = newText;
            const toInsert = [];
            for (const child of Array.from(node.getChildren())) {
                if (child.is('text')) {
                    const textLength = child.data.length;
                    if (child.data.trim().length > 0) {
                        if (offsetCounter + textLength > startOffset && offsetCounter < endOffset) {
                            const textStart = Math.max(startOffset - offsetCounter, 0);
                            const textEnd = Math.min(endOffset - offsetCounter, textLength);
                            const beforeText = child.data.slice(0, textStart);
                            const afterText = child.data.slice(textEnd);
                            const replaceLength = Math.max(textEnd - textStart, remainingText.length);
                            const replaceText = remainingText.slice(0, replaceLength);
                            remainingText = remainingText.slice(replaceLength);
                            if (beforeText) {
                                toInsert.push({ text: beforeText, attributes: child.getAttributes() });
                            }
                            toInsert.push({ text: replaceText, attributes: child.getAttributes() });
                            if (afterText) {
                                toInsert.push({ text: afterText, attributes: child.getAttributes() });
                            }
                            writer.remove(child);
                        }
                        else {
                            toInsert.push({ text: child.data, attributes: child.getAttributes() });
                            writer.remove(child);
                        }
                    }
                    offsetCounter += textLength;
                }
                else if (child.is('element') && child.name !== "htmlCustomElement") {
                    if (child.name !== "softBreak")
                        replaceTextInNode(writer, child, startOffset - offsetCounter, endOffset - offsetCounter, remainingText);
                    else
                        writer.remove(child);
                    offsetCounter += child.name !== "softBreak" && child.name !== "imageInline" && child.name !== "htmlInput" ? child === null || child === void 0 ? void 0 : child.getChildCount() : 0;
                }
            }
            for (const { text, attributes } of toInsert) {
                writer.insertText(text, attributes, node, 'end');
            }
            that.segment.text = replaceTextInRange(that.segment.text, newText, startOffset, endOffset);
        }
        function getStartIndexWithSpaces(longStr, shortStr) {
            var _a, _b;
            const normalizedLongStr = (_a = longStr === null || longStr === void 0 ? void 0 : longStr.replace(/\s+/g, ' ')) === null || _a === void 0 ? void 0 : _a.trim();
            const normalizedShortStr = (_b = shortStr === null || shortStr === void 0 ? void 0 : shortStr.replace(/\s+/g, ' ')) === null || _b === void 0 ? void 0 : _b.trim();
            const startIndex = normalizedLongStr === null || normalizedLongStr === void 0 ? void 0 : normalizedLongStr.indexOf(normalizedShortStr);
            if (startIndex === -1) {
                return -1;
            }
            let currentIndex = 0;
            let spacesSeen = 0;
            for (let i = 0; i < (longStr === null || longStr === void 0 ? void 0 : longStr.length); i++) {
                if (longStr[i] !== ' ' || (longStr[i] === ' ' && longStr[i - 1] !== ' ')) {
                    if (spacesSeen === startIndex) {
                        return i;
                    }
                    spacesSeen++;
                }
            }
            return -1;
        }
        function replaceTextInRange(text, replacement, startOffset, endOffset) {
            if (startOffset >= 0 && endOffset >= 0 && startOffset < endOffset && endOffset <= text.length) {
                const before = text.slice(0, startOffset);
                const after = text.slice(endOffset);
                return before + replacement + after;
            }
            else {
                throw new Error("Invalid start or end offset");
            }
        }
    }
    findMatchingTextNode(rootNode, startOffset, endOffset) {
        let matchingNode = null;
        let accumulatedLength = 0;
        function traverse(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                const nodeValueLength = node.nodeValue.length;
                let textNodeStart = accumulatedLength;
                let textNodeEnd = textNodeStart + nodeValueLength;
                if (textNodeStart >= startOffset && textNodeEnd <= endOffset) {
                    matchingNode = node;
                    return true;
                }
                accumulatedLength += nodeValueLength;
            }
            for (let child of node.childNodes) {
                if (traverse(child)) {
                    return true;
                }
            }
            return false;
        }
        traverse(rootNode);
        return matchingNode;
    }
}
exports.CcUnderline = CcUnderline;
