"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentenceHighlighter = void 0;
class SentenceHighlighter {
    constructor(editorOverlay, draggablePanel, sentence, segment) {
        this.highlighters = [];
        this.editorOverlay = editorOverlay;
        this.draggablePanel = draggablePanel;
        this.sentence = sentence;
        this.segment = segment;
        const sentenceRects = this.createSentenceRange(segment.textNodes, sentence.begin, sentence.end);
        if (!sentenceRects)
            return;
        this.createHighlight(sentenceRects);
    }
    refreshHighlight() {
        const containerBounds = this.editorOverlay.getBoundingClientRect();
        const sentenceRects = this.createSentenceRange(this.segment.textNodes, this.sentence.begin, this.sentence.end);
        if (!sentenceRects)
            return;
        this.highlighters.forEach((highlighter, index) => {
            const rect = sentenceRects[index];
            if (!rect)
                return;
            Object.assign(highlighter.highlightContainer.style, {
                'top': `${rect.top - containerBounds.top + this.editorOverlay.scrollTop}px`,
                'left': `${rect.left - containerBounds.left + this.editorOverlay.scrollLeft}px`,
                'width': `${rect.width}px`,
                'height': `${rect.height}px`,
                'position': `absolute`,
                'pointer-events': 'all',
                'cursor': 'pointer'
            });
        });
    }
    createHighlight(rects) {
        const containerBounds = this.editorOverlay.getBoundingClientRect();
        for (let rect of rects) {
            const highlightContainer = document.createElement('div');
            highlightContainer.classList.add('highlight');
            highlightContainer.dataset.group = `sentence-${this.sentence.sentenceId}`;
            Object.assign(highlightContainer.style, {
                'top': `${rect.top - containerBounds.top + this.editorOverlay.scrollTop}px`,
                'left': `${rect.left - containerBounds.left + this.editorOverlay.scrollLeft}px`,
                'width': `${rect.width}px`,
                'height': `${rect.height}px`,
                'position': `absolute`,
                'pointer-events': 'all'
            });
            const listOfEvents = this.prepEventlisteners(highlightContainer);
            this.registerHighlighterEventListeners(listOfEvents);
            this.highlighters.push({ highlightContainer, listOfEvents });
        }
    }
    createSentenceRange(textNodes, startOffset, endOffset) {
        try {
            let range = document.createRange();
            let currentOffset = 0;
            let startNode, startNodeOffset, endNode, endNodeOffset;
            for (let i = 0; i < textNodes.length; i++) {
                const nodeLength = textNodes[i].textContent.length;
                if (startNode === undefined && currentOffset + nodeLength > startOffset) {
                    startNode = textNodes[i];
                    startNodeOffset = startOffset - currentOffset;
                }
                if (endNode === undefined && currentOffset + nodeLength >= endOffset) {
                    endNode = textNodes[i];
                    endNodeOffset = endOffset - currentOffset;
                    break;
                }
                currentOffset += nodeLength;
            }
            if (!startNode || !endNode) {
                throw new Error("Offsets are out of range for the provided textNodes.");
            }
            range.setStart(startNode, startNodeOffset);
            range.setEnd(endNode, endNodeOffset);
            return range.getClientRects();
        }
        catch (error) {
            console.log("[DEBUG] createSentenceRange error ===>", error);
        }
        return null;
    }
    _mouseoverEventHandler($event, container) {
        const group = container.dataset.group;
        this.editorOverlay
            .querySelectorAll(`[data-group="${group}"]`)
            .forEach(el => (el.style.backgroundColor = 'rgba(0, 56, 255, 0.15)'));
    }
    _mouseoutEventHandler($event, container) {
        const group = container.dataset.group;
        this.editorOverlay
            .querySelectorAll(`[data-group="${group}"]`)
            .forEach(el => (el.style.backgroundColor = ''));
    }
    _clickEventHandler($event, container) {
        const group = container.dataset.group;
        this.editorOverlay
            .querySelectorAll(`.highlight.selected`)
            .forEach(el => { el.classList.remove('selected'); });
        this.editorOverlay
            .querySelectorAll(`[data-group="${group}"]`)
            .forEach(el => (el.classList.add('selected')));
        this.draggablePanel.sentenceWiseScrollToElementById(group);
    }
    prepEventlisteners(container) {
        let listOfEvents = [];
        const events = {
            'mouseover': {
                name: 'mouseover',
                boundHandler: ($event) => this._mouseoverEventHandler($event, container)
            },
            'mouseout': {
                name: 'mouseout',
                boundHandler: ($event) => this._mouseoutEventHandler($event, container)
            },
            'click': {
                name: 'click',
                boundHandler: ($event) => this._clickEventHandler($event, container)
            }
        };
        Object.values(events).forEach(event => {
            listOfEvents.push({
                sentenceId: `sentence-${this.sentence.sentenceId}`,
                element: container,
                event: event.name,
                listener: event.boundHandler
            });
        });
        return listOfEvents;
    }
    removeHighlighterEventListeners(listOfEvents) {
        listOfEvents.forEach(({ element, event, listener }) => {
            element.removeEventListener(event, listener, { passive: true });
        });
    }
    registerHighlighterEventListeners(listOfEvents) {
        listOfEvents.forEach(({ element, event, listener }) => {
            element.addEventListener(event, listener, { passive: true });
        });
    }
    destroy() {
        this.highlighters.forEach((highlighter) => {
            this.removeHighlighterEventListeners(highlighter.listOfEvents);
            highlighter.highlightContainer.remove();
        });
    }
}
exports.SentenceHighlighter = SentenceHighlighter;
