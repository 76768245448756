"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverlayCardComponent = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let OverlayCardComponent = class OverlayCardComponent extends lit_1.LitElement {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
        return (0, lit_1.html) `
      <div id="tsdkCardContainer" class="trinkaCloudEditor"
       style="display: ${this.isOpen ? 'block' : 'none'}; position: fixed; top: ${this.top}px; left: ${this.left}px;">
        ${this.alert && JSON.stringify(this.alert) !== '{}' ? (0, lit_1.html) `<div class="card">
          <div id="cardWrapper">
            <div class="cardTitleSection">
            ${this.cardTitle()}
            </div>
            <div class="cardNewBody" @click="${() => this.acceptAlert(this.currentIndexOfSuggestion)}">
              <div class="cardNewDescriptions ${(((_b = (_a = this.alert) === null || _a === void 0 ? void 0 : _a.suggestions) === null || _b === void 0 ? void 0 : _b.length) > 1) ? 'multiAlertCardHeader' : ''}">
                <div class="categoryName">
                  <p>${this.getAlertCategory((_c = this.alert) === null || _c === void 0 ? void 0 : _c.suggestions)}</p>
                </div>
                <div class="errorWordCorrection" data-no-translate>
                  ${this.alert && this.alert.suggestions && this.alert.suggestions.length && this.alert.suggestions[0].cta_present ?
            (0, lit_1.html) `<span class="right_text deleteText" style="cursor: pointer;" @click=${this.acceptAlert}>${this.alert.text}</span>` :
            (0, lit_1.html) ``}
                  ${this.alert && this.alert.suggestions && this.alert.suggestions.length && this.alert.suggestions[0].suggestion !== '' && this.alert.suggestions[0].cta_present && ((_e = (_d = this.alert) === null || _d === void 0 ? void 0 : _d.suggestions) === null || _e === void 0 ? void 0 : _e.length) <= 1 ?
            (0, lit_1.html) `${(_f = this.alert.suggestions) === null || _f === void 0 ? void 0 : _f.map((suggestion, index) => {
                return index === this.currentIndexOfSuggestion ? (0, lit_1.html) `<span class="right_text" style="cursor: pointer;" @click=${() => this.acceptAlert(index)}>${suggestion.suggestion}</span>` : ``;
            })}` :
            (0, lit_1.html) ``}
                  ${this.alert && this.alert.suggestions && this.alert.suggestions.length && !this.alert.suggestions[0].cta_present ?
            (0, lit_1.html) `<div><span class="legalWritingText">${this.alert.text}</span></div>` :
            (0, lit_1.html) ``}
                </div>
              </div>
              ${((_h = (_g = this.alert) === null || _g === void 0 ? void 0 : _g.suggestions) === null || _h === void 0 ? void 0 : _h.length) > 1 ? (0, lit_1.html) `<div class="multiCardWrapper">
              ${(_k = (_j = this.alert) === null || _j === void 0 ? void 0 : _j.suggestions) === null || _k === void 0 ? void 0 : _k.map((suggestion, i) => (0, lit_1.html) `
                <div class="singleSuggestion" @click=${() => this.acceptAlert(i)}>
                  ${suggestion.cta_present
            ? (0, lit_1.html) `<svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 5.00063C0 4.86802 0.0526784 4.74085 0.146447 4.64708C0.240215 4.55331 0.367392 4.50063 0.5 4.50063H12.293L9.146 1.35463C9.05211 1.26075 8.99937 1.13341 8.99937 1.00063C8.99937 0.867856 9.05211 0.740518 9.146 0.646631C9.23989 0.552745 9.36722 0.5 9.5 0.5C9.63278 0.5 9.76011 0.552745 9.854 0.646631L13.854 4.64663C13.9006 4.69308 13.9375 4.74825 13.9627 4.809C13.9879 4.86974 14.0009 4.93486 14.0009 5.00063C14.0009 5.0664 13.9879 5.13152 13.9627 5.19226C13.9375 5.25301 13.9006 5.30819 13.854 5.35463L9.854 9.35463C9.76011 9.44852 9.63278 9.50126 9.5 9.50126C9.36722 9.50126 9.23989 9.44852 9.146 9.35463C9.05211 9.26075 8.99937 9.13341 8.99937 9.00063C8.99937 8.86786 9.05211 8.74052 9.146 8.64663L12.293 5.50063H0.5C0.367392 5.50063 0.240215 5.44795 0.146447 5.35419C0.0526784 5.26042 0 5.13324 0 5.00063Z"
                  fill="#4D4D4D" fill-opacity="0.38"></path>
                  </svg>` : (0, lit_1.html) ``}
                  <span class="right_text">
                  ${suggestion.cta_present
            ? (0, lit_1.html) `
                    <span>${suggestion.suggestion === '' ? this.alert.text : suggestion.suggestion}</span>
                    <button @click=${() => this.acceptAlert(i)} class="multiAlertAcceptButton">Accept</button>
                    `
            : (0, lit_1.html) ``}
                  </span>
                </div>
              `)}
              </div>` : ``}
              ${this.alert && this.alert.suggestions && this.alert.suggestions.length ?
            (0, lit_1.html) `<p data-no-translate class="description_text ${(((_m = (_l = this.alert) === null || _l === void 0 ? void 0 : _l.suggestions) === null || _m === void 0 ? void 0 : _m.length) > 1) ? 'multiAlertCardHeader' : ''}"">${this.alert.suggestions[0].comment}</p>` :
            (0, lit_1.html) ``}
              <div class="cardNewFooter ${!((_p = (_o = this.alert) === null || _o === void 0 ? void 0 : _o.suggestions[0]) === null || _p === void 0 ? void 0 : _p.cta_present) ? 'cardFooterRowType' : ''}">
                 ${((_q = this.alert) === null || _q === void 0 ? void 0 : _q.suggestions) && ((_s = (_r = this.alert) === null || _r === void 0 ? void 0 : _r.suggestions) === null || _s === void 0 ? void 0 : _s.length) && ((_u = (_t = this.alert) === null || _t === void 0 ? void 0 : _t.suggestions[0]) === null || _u === void 0 ? void 0 : _u.type) == 2
            ? (0, lit_1.html) `<button type="button" class="rejectButtons b_addToMyDicitonary" @click=${() => this.muteAlert()}>
                    <svg width="11" height="11" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3069_27145)">
                      <path d="M1.375 10.8125H6.54736V11.5H1.375C1.18522 11.5 1.00798 11.4642 0.843262 11.3926C0.678548 11.321 0.531738 11.2225 0.402832 11.0972C0.273926 10.9718 0.175456 10.8268 0.107422 10.6621C0.039388 10.4974 0.00358073 10.3184 0 10.125V1.875C0 1.69954 0.0375977 1.52946 0.112793 1.36475C0.187988 1.20003 0.291829 1.05322 0.424316 0.924316C0.556803 0.79541 0.703613 0.693359 0.864746 0.618164C1.02588 0.542969 1.19596 0.503581 1.375 0.5H8.9375V7.04736H8.59375H8.25V1.1875H1.375C1.29264 1.1875 1.21029 1.20719 1.12793 1.24658C1.04557 1.28597 0.972168 1.33968 0.907715 1.40771C0.843262 1.47575 0.791341 1.54915 0.751953 1.62793C0.712565 1.70671 0.691081 1.78906 0.6875 1.875V8.94336C0.791341 8.88249 0.900553 8.83594 1.01514 8.80371C1.12972 8.77148 1.24967 8.75358 1.375 8.75H6.54736V9.09375V9.4375H1.375C1.27832 9.4375 1.1888 9.4554 1.10645 9.49121C1.02409 9.52702 0.952474 9.57536 0.891602 9.63623C0.830729 9.6971 0.780599 9.77051 0.741211 9.85645C0.701823 9.94238 0.683919 10.0319 0.6875 10.125C0.6875 10.2217 0.705404 10.3112 0.741211 10.3936C0.777018 10.4759 0.825358 10.5475 0.88623 10.6084C0.947103 10.6693 1.02051 10.7194 1.10645 10.7588C1.19238 10.7982 1.2819 10.8161 1.375 10.8125ZM6.875 4.625H2.0625V2.5625H6.875V4.625ZM2.75 3.25V3.9375H6.1875V3.25H2.75Z" fill="#647087"/>
                      <path d="M9.15365 7.83594V9.61914M9.15365 11.5026V9.61914M9.15365 9.61914H10.987H7.32031" stroke="#647087" stroke-width="0.733333"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_3069_27145">
                      <rect width="11" height="11" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <span>Add to my dictionary</span>
                </button>` : ``}

                ${((_v = this.alert) === null || _v === void 0 ? void 0 : _v.suggestions) && ((_w = this.alert) === null || _w === void 0 ? void 0 : _w.suggestions.length) && !((_y = (_x = this.alert) === null || _x === void 0 ? void 0 : _x.suggestions[0]) === null || _y === void 0 ? void 0 : _y.cta_present)
            ? (0, lit_1.html) `<button type="button" class="rejectButtons wasHelpFulBtn" @click=${() => this.acceptNonCTAAlert()}>
                    <span style="display: flex">
                    <svg style="position: relative;top: 0;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.3905 6.48885C10.3792 6.23521 10.2955 5.9915 10.1509 5.79093C10.2386 5.58243 10.2626 5.34936 10.2196 5.12521C10.1765 4.90107 10.0686 4.69739 9.91131 4.54345C9.61423 4.24932 9.10393 4.11774 8.39956 4.15644C8.08772 4.17173 7.77759 4.21488 7.47238 4.28544V4.28544C7.37655 4.3035 7.27593 4.32543 7.17411 4.34995C7.16692 4.22094 7.18728 3.89714 7.41369 3.17084C7.67722 2.3078 7.66045 1.646 7.36457 1.20352C7.2515 1.04614 7.10616 0.91907 6.93991 0.832237C6.77366 0.745404 6.59101 0.701164 6.40625 0.702977C6.33784 0.702869 6.27023 0.718861 6.20815 0.749835C6.14608 0.780809 6.09103 0.826016 6.04688 0.882294C5.83964 1.1403 5.8648 1.62665 5.88876 1.84854C5.62761 2.56452 4.93523 4.32543 4.33628 4.81436C4.32546 4.82275 4.31543 4.83226 4.30633 4.84274C4.15325 5.01779 4.02853 5.21925 3.93737 5.43875C3.82285 5.37115 3.69422 5.33608 3.56363 5.33683H2.41963C2.21195 5.33717 2.01287 5.42617 1.86601 5.58432C1.71916 5.74247 1.63652 5.95687 1.6362 6.18052V9.4766C1.63605 9.58756 1.65619 9.69748 1.69547 9.80006C1.73476 9.90264 1.79242 9.99589 1.86517 10.0745C1.93792 10.1531 2.02433 10.2154 2.11946 10.2581C2.2146 10.3007 2.31659 10.3227 2.41963 10.3229H3.56722C3.72944 10.3232 3.88772 10.2691 4.02003 10.1681L4.45607 10.2222C5.29057 10.3323 6.13099 10.3818 6.97166 10.3706C7.47399 10.4272 7.98119 10.4081 8.47862 10.3138C9.05481 10.1848 9.44772 9.91909 9.64897 9.52949C9.76922 9.29355 9.81425 9.02163 9.77715 8.75546C9.91966 8.62738 10.0326 8.46538 10.107 8.28224C10.1814 8.09911 10.2153 7.89985 10.206 7.7002C10.1981 7.56133 10.1715 7.42444 10.1269 7.29384C10.3014 7.06813 10.3949 6.7825 10.3905 6.48885V6.48885Z" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M3.96875 10.1328V5.39704" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    This was helpful</span>
                  </button>` : ``}

                <button type="button" class="rejectButtons ${(((_0 = (_z = this.alert) === null || _z === void 0 ? void 0 : _z.suggestions) === null || _0 === void 0 ? void 0 : _0.length) > 1) ? 'multiAlertCardHeader' : ''}"" @click=${this.rejectAlert}>
                  <svg width="11" height="11" style="top: 0" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 2.99023C8.835 2.82523 7.16 2.74023 5.49 2.74023C4.5 2.74023 3.51 2.79023 2.52 2.89023L1.5 2.99023" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.25 2.485L4.36 1.83C4.44 1.355 4.5 1 5.345 1H6.655C7.5 1 7.565 1.375 7.64 1.835L7.75 2.485" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.42422 4.57031L9.09922 9.60531C9.04422 10.3903 8.99922 11.0003 7.60422 11.0003H4.39422C2.99922 11.0003 2.95422 10.3903 2.89922 9.60531L2.57422 4.57031" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.16406 8.25H6.82906" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.75 6.25H7.25" stroke="#647087" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  ${((_2 = (_1 = this.alert) === null || _1 === void 0 ? void 0 : _1.suggestions) === null || _2 === void 0 ? void 0 : _2.length) > 1 ? 'Dismiss All' : 'Dismiss'}
                </button>


                ${this.alert.suggestions && this.alert.suggestions.length && this.hasSimilarCardsToIgnore()
            ? (0, lit_1.html) `<button class="similarCardsCTA rejectButtons" @click=${() => this.ignoreAlertAll(this.alert.alertId)}>
                    <svg width="11" height="11" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.37834 9.52529V20.3867C7.37834 21.0285 7.60548 21.6312 8.00148 22.0641C8.39577 22.4979 8.94519 22.7441 9.52034 22.745H17.562C18.1372 22.7441 18.6866 22.4979 19.0809 22.0641C19.4778 21.6312 19.704 21.0285 19.704 20.3867V9.52529C20.4918 9.3084 21.0026 8.51817 20.8972 7.67994C20.7918 6.84082 20.1026 6.21415 19.2875 6.21415H17.1112V5.66303C17.1138 5.19992 16.9372 4.75458 16.6209 4.42746C16.3046 4.10035 15.8752 3.91813 15.4286 3.92168H11.6538C11.2072 3.91813 10.7778 4.10035 10.4615 4.42746C10.1452 4.75458 9.96862 5.19992 9.97119 5.66303V6.21415H7.79491C6.97977 6.21415 6.29063 6.84171 6.1852 7.67994C6.07977 8.51906 6.58977 9.3084 7.37834 9.52529ZM17.2526 6.21415H9.82976H17.2526Z" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4.12285 6.85879V17.7202C4.12285 18.362 4.34999 18.9647 4.74599 19.3976C5.14027 19.8314 5.6897 20.0776 6.26484 20.0785H14.3065C14.8817 20.0776 15.4311 19.8314 15.8254 19.3976C16.2223 18.9647 16.4485 18.362 16.4485 17.7202V6.85879C17.2363 6.6419 17.7471 5.85167 17.6417 5.01344C17.5363 4.17432 16.8471 3.54765 16.032 3.54765H13.8557V2.99653C13.8583 2.53341 13.6817 2.08808 13.3654 1.76096C13.0491 1.43385 12.6197 1.25162 12.1731 1.25518H8.39827C7.9517 1.25162 7.52227 1.43385 7.20598 1.76096C6.8897 2.08808 6.71313 2.53341 6.7157 2.99653V3.54765H4.53942C3.72427 3.54765 3.03513 4.17521 2.9297 5.01344C2.82428 5.85256 3.33428 6.6419 4.12285 6.85879ZM13.9971 3.54765H6.57427H13.9971Z" fill="white" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.01855 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.5528 8.62854V15.9013" stroke="#7A7A7A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    <span>Dismiss all the similar spelling alerts</span>
                  </button>` : ``}

                <!-- ${((_4 = (_3 = this.alert) === null || _3 === void 0 ? void 0 : _3.suggestions) === null || _4 === void 0 ? void 0 : _4.length) > 1 ? (0, lit_1.html) `<div class="CTAForMultipleError">
                  <a class="previousError ${this.currentIndexOfSuggestion === 0 ? 'disablePrev' : ''}" style="margin-right: 6px" @click=${() => this.changeCurrentAlert(-1)}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.11719 11L3.82429 6.70711C3.43377 6.31658 3.43377 5.68342 3.82429 5.29289L8.11719 1L8.87969 1.77083L5.35762 5.29289C4.9671 5.68342 4.96709 6.31658 5.35762 6.70711L8.87969 10.2292L8.11719 11Z" fill="#647087"/>
                    </svg>
                  </a>
                  <a class="nextError ${this.currentIndexOfSuggestion >= this.alert.suggestions.length - 1 ? 'disableNext' : ''}" style="margin-left: 6px" @click=${() => this.changeCurrentAlert(+1)}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.87967 11L3.11719 10.2292L6.63924 6.70711C7.02977 6.31658 7.02977 5.68342 6.63925 5.29289L3.11719 1.77083L3.87967 1L8.17257 5.29289C8.56309 5.68342 8.56309 6.31658 8.17257 6.70711L3.87967 11Z" fill="#647087"/>
                    </svg>
                  </a>
                </div>` : ``}  -->
              </div>
            </div>
            <div class="card-body grammar">
              <div class="textContainer">
                <div>
                  ${this.alert && this.alert.suggestions && this.alert.suggestions.length && this.alert.suggestions[0].suggestion === '' && this.alert.suggestions[0].cta_present ?
            (0, lit_1.html) `<span class="right_text deleteText" style="cursor: pointer;" @click=${this.acceptAlert}>${this.alert.text}</span>` :
            (0, lit_1.html) ``}
                  ${this.alert && this.alert.suggestions && this.alert.suggestions.length && this.alert.suggestions[0].suggestion !== '' && this.alert.suggestions[0].cta_present ?
            (0, lit_1.html) `${(_5 = this.alert.suggestions) === null || _5 === void 0 ? void 0 : _5.map((suggestion, index) => (0, lit_1.html) `<div><span class="right_text" style="cursor: pointer;" @click=${() => this.acceptAlert(index)}>${suggestion.suggestion}</span></div>`)}` :
            (0, lit_1.html) ``}
                  ${this.alert && this.alert.suggestions && this.alert.suggestions.length && !this.alert.suggestions[0].cta_present ?
            (0, lit_1.html) `<div><span>${this.alert.text}</span></div>` :
            (0, lit_1.html) ``}
                </div>
                ${this.alert && this.alert.suggestions && this.alert.suggestions.length ?
            (0, lit_1.html) `<p class="description_text">${this.alert.suggestions[0].comment}</p>` :
            (0, lit_1.html) ``}
              </div>
              <div class="footer_buttons">
                <button type="button" class="rejectButtons" title="Reject" @click=${this.rejectAlert}>
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 84 84" style="enable-background:new 0 0 84 84;" xml:space="preserve"> <style type="text/css">   .st0{fill:none;stroke:#7a7a7a;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;} </style> <g id="DislikeIcon_10">   <path class="st0" d="M5.5,34.7c0.1,2,0.8,3.9,2,5.4c-1.5,3.3-0.7,7.2,2,9.7c2.5,2.3,6.7,3.3,12.6,3c2.6-0.1,5.2-0.5,7.7-1l0,0     c0.8-0.1,1.6-0.3,2.5-0.5c0.1,1-0.1,3.5-2,9.1c-2.2,6.7-2.1,11.8,0.4,15.2c1.9,2.5,4.9,3.9,8,3.9c1.2,0,2.3-0.5,3-1.4     c1.7-2,1.5-5.8,1.3-7.5c2.2-5.6,8-19.2,13-23c0.1-0.1,0.2-0.1,0.2-0.2c1.3-1.4,2.3-2.9,3.1-4.6c1,0.5,2,0.8,3.1,0.8H72     c3.6,0,6.5-2.9,6.5-6.5V11.6C78.5,7.9,75.6,5,72,5c0,0,0,0,0,0h-9.6c-1.4,0-2.7,0.4-3.8,1.2L55,5.8c-7-0.9-14-1.2-21-1.2     c-4.2-0.4-8.4-0.3-12.6,0.4c-4.8,1-8.1,3.1-9.8,6.1c-1,1.8-1.4,3.9-1.1,6c-2.4,2-3.7,5-3.6,8.2c0.1,1.1,0.3,2.1,0.7,3.1     C6.2,30.2,5.4,32.4,5.5,34.7z">
                  </path><line class="st0" x1="59.1" y1="6.5" x2="59.1" y2="43.2"></line></g></svg>
                </button>
              </div>
            </div>
            <div class="footersignUp" style="display: ${this.loginRequired ? 'block' : 'none'}">
              <span>For real-time editing, <button class="actionButton" style="display: inline-block; border: none; cursor: pointer;">Login Now.</button>
              </span>
            </div>
            <div class="footerVerify" style="display: ${this.verifyEmail ? 'block' : 'none'}">
              <span>To make corrections, <button class="ve_actionButton" style="display: inline-block; border: none; cursor: pointer;">Verify Email.</button>
              </span>
            </div>
            <div class="footerlimitExhaust" style="display: ${this.limitExhausted ? 'block' : 'none'}">
              <span>Usage limit exhaust. <button class="actionButton" style="display: inline-block;">Buy Premium</button>
              </span>
            </div>
          </div>
        </div>` : ``}
      </div>
    `;
    }
    constructor() {
        super();
        this.editorId = '';
        this.alert = {};
        this.top = 0;
        this.left = 0;
        this.isOpen = false;
        this.limitExhausted = false;
        this.loginRequired = false;
        this.verifyEmail = false;
        this.currentIndexOfSuggestion = 0;
        this.trinkaEditorConfig = {};
        this.visibility = 'visible';
        this.filteredAlertList = [];
        this.currentLang = 'en';
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.trinkaCard.removeEventListener('mouseleave', this.boundHandleMouseLeaveEvent);
    }
    firstUpdated() {
        this.boundHandleMouseLeaveEvent = ($event) => this.handleMouseLeaveEvent($event);
        this.trinkaCard = this.shadowRoot.querySelector('#tsdkCardContainer');
        this.trinkaCard.addEventListener('mouseleave', this.boundHandleMouseLeaveEvent);
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    handleMouseLeaveEvent($event) {
        this.isOpen = false;
    }
    rejectAlert() {
        this.isOpen = false;
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: this.alert.alertId,
                action: 'reject'
            }
        });
        document.dispatchEvent(event);
    }
    muteAlert() {
        this.isOpen = false;
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: this.alert.alertId,
                action: 'mute'
            }
        });
        document.dispatchEvent(event);
    }
    acceptNonCTAAlert() {
        this.isOpen = false;
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: this.alert.alertId,
                action: 'accept_noncta'
            }
        });
        document.dispatchEvent(event);
    }
    acceptAlert(suggestionIndex = 0) {
        this.isOpen = false;
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: this.alert.alertId,
                suggestionIndex,
                action: 'accept'
            }
        });
        document.dispatchEvent(event);
    }
    cardTitle() {
        if (!this.alert || !this.alert.suggestions)
            return '';
        let cardTitle = 'CHANGE TO';
        if (!this.alert.suggestions[0].cta_present) {
            cardTitle = 'CONSIDER REVISING';
        }
        else if (this.alert.suggestions.length > 1) {
            cardTitle = 'PICK AN OPTION';
        }
        else if (this.alert.suggestions[0].suggestion == '' && this.alert.suggestions[0].cta_present) {
            cardTitle = 'DELETE';
        }
        return cardTitle;
    }
    changeCurrentAlert(value = 1) {
        const newIndex = this.currentIndexOfSuggestion + value;
        if (newIndex >= 0 && newIndex < this.alert.suggestions.length) {
            this.currentIndexOfSuggestion = newIndex;
        }
    }
    getAlertCategory(suggestionArr) {
        if (suggestionArr && suggestionArr.length > 0) {
            let suggestion = suggestionArr[0];
            let alertCategoryToReturn = ``;
            if (suggestion.type == 1)
                alertCategoryToReturn = 'Grammar';
            else if (suggestion.type == 2)
                alertCategoryToReturn = 'Spelling';
            else if (suggestion.type == 3)
                alertCategoryToReturn = 'Writing Advisor';
            else if (suggestion.type == 4)
                alertCategoryToReturn = 'Enhancement';
            else if (suggestion.type == 5)
                alertCategoryToReturn = `Style Guide: ${this.trinkaEditorConfig.style_guide.title}`;
            else if (suggestion.type == 10)
                alertCategoryToReturn = `Inclusive Language: ${suggestion.error_category}`;
            else if (suggestion.type == 11)
                alertCategoryToReturn = `Legal Writing`;
            else
                alertCategoryToReturn = 'Unknown';
            return alertCategoryToReturn;
        }
    }
    hasSimilarCardsToIgnore() {
        let type = this.alert['suggestions'][0]['type'];
        if (type === 2) {
            const findSimilarCards = this.filteredAlertList
                .filter(alert => alert['text'] === this.alert['text'])
                .filter(alert => alert['suggestions'][0]['type'] === type);
            return findSimilarCards.length > 1;
        }
        return false;
    }
    ignoreAlertAll(alertId) {
        this.isOpen = false;
        const event = new CustomEvent('tsdk-overlay-user-action', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                alertId: alertId,
                action: 'ignoreAll'
            }
        });
        document.dispatchEvent(event);
    }
};
exports.OverlayCardComponent = OverlayCardComponent;
OverlayCardComponent.styles = (0, lit_1.css) `
  #tsdkCardContainer {
    z-index: 5;
  }
  #tsdkCardContainer .card {
    padding: 8px 10px;
    z-index: 20000000;
    width: auto;
    background: transparent;
    display: inline-block;
    width: auto;
    max-width: 270px;
    min-width: auto;
    font-family: "Open Sans", sans-serif;
  }
  #tsdkCardContainer.trinkaCloudEditor .card{
  max-width: 400px;
  }
  #tsdkCardContainer #cardWrapper{
    border: 1px solid #f1f1f1;
    padding: 10px 10px 10px 15px;
    box-shadow: 0px 0px 9px rgba(0,0,0,.30);
    font-family: Oxygen,Geneva,sans-serif;
    font-size: 16px;
    border-radius: 5px;
    z-index: 20000000;
    outline: none;
    width: auto;
    display: inline-block;
    width: auto;
    max-width: 270px;
    min-width: auto;
    box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.15);
    font-family: "Open Sans", sans-serif;
    background: linear-gradient(180deg, rgba(228, 228, 228, 0.6) 20%, rgba(244, 244, 244, 0.1) 70%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  }
  #cardWrapper .cardTitleSection{
    font-size: 8px;
    color: #4D4D4D;
    opacity: 0.7;
    display: block;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    margin-bottom: 4px;
    line-height: unset;
  }
  #tsdkCardContainer .card button{
    display: none;
  }
  #tsdkCardContainer .card button.Add_to_dictionaryButton{
    display: inline-block;
  }
  #tsdkCardContainer .card .card-body {
    display: flex;
  }
  #tsdkCardContainer .card .card-body.multiple-suggestions-popup{
    height: 250px;
    overflow-y: auto;
  }
  #tsdkCardContainer .card .card-body div{
    width: 100%;
    padding-right: 5px;
  }
  #tsdkCardContainer .card .card-body div.textContainer{
    scrollbar-color: #e4e4e4 white;
    scrollbar-width: thin;
  }
  #tsdkCardContainer .card .card-body div.textContainer::-webkit-scrollbar {
    width: 4px;
  }

  #tsdkCardContainer .card .card-body div.textContainer::-webkit-scrollbar-track {
    background: white;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
  }

  #tsdkCardContainer .card .card-body div.textContainer::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }
  #tsdkCardContainer .card .card-body div.footer_buttons{
    width: auto;
    paddirg-right: 0;
  }
  #tsdkCardContainer .card .card-body div.footer_buttons button{
    display: inline-block;
  }
  #tsdkCardContainer .card .card-body::-webkit-scrollbar {
    width: 5px;
  }

  #tsdkCardContainer .card .card-body::-webkit-scrollbar-track {
    background: white;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
  }

  #tsdkCardContainer .card .card-body::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }

  #tsdkCardContainer .card .card-body .right_text {
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    position: relative;
    padding: 2px 0 0 0px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    letter-spacing: 1px;
  }
  #tsdkCardContainer .card .card-body .right_text.deleteText{
    text-decoration: line-through;
    cursor: pointer;
  }
  #tsdkCardContainer .card .card-body .wrong_text{
    display: none;
  }
  #tsdkCardContainer .card .card-body .description_text {
    margin: 15px 0 5px 0;
    position: relative;
    color: #000000;
    font-size: 12px;
    padding: 0;
    display: block;
    max-height: 190px;
    overflow-y: auto;
    scrollbar-color: #e4e4e4 transparent;
    scrollbar-width: thin;
    line-height: 16px !important;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
  }
  #tsdkCardContainer .card .card-body.inclusive_language.multiple-suggestions-popup .description_text{
    max-height: inherit;
  }

  #tsdkCardContainer .card .card-body .description_text::-webkit-scrollbar {
    width: 5px;
  }

  #tsdkCardContainer .card .card-body .description_text::-webkit-scrollbar-track {
    background: transparent;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
  }

  #tsdkCardContainer .card .card-body .description_text::-webkit-scrollbar-thumb {
    background: #e4e4e4;
  }

  #tsdkCardContainer .card .card-body .right_text span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 5px 0 0;
    position: absolute;
    top: 12px;
    left: 0;
  }
  #tsdkCardContainer .card .card-body .right_text span.spelling {
    background: #FD9A00;
    top: 11px;
  }
  #tsdkCardContainer .card .card-body .right_text span.grammar {
    background: #763393;
    top: 11px;
  }
  #tsdkCardContainer .card .card-body .right_text span.enhancement {
    background: #ff5c5d;
  }
  #tsdkCardContainer .card .card-body .right_text span.advisor {
    background: #ff5c5d;
  }

  #tsdkCardContainer .card .card-body.spelling .right_text{
    color: #763393;
  }
  #tsdkCardContainer .card .card-body.spelling .right_text span.dotspan{
    background: #FD9A00;
  }

  #tsdkCardContainer .card .card-body.grammar .right_text{
    color: #763393;
  }
  #tsdkCardContainer .card .card-body.grammar .right_text span.dotspan{
    background: #763393;
  }
  #tsdkCardContainer .card .card-body.enhancement .right_text{
    color: #763393;
    margin-bottom: 10px;
  }
  #tsdkCardContainer .card .card-body.inclusive_language .right_text{
    color: #763393;
  }
  #tsdkCardContainer .card .card-body.enhancement .right_text span.dotspan{
    background: #ff5c5d;
  }
  #tsdkCardContainer .card .card-body.advisor .right_text{
    color: #ff5c5d;
  }
  #tsdkCardContainer .card .card-body.advisor span,
  #tsdkCardContainer .card .card-body.enhancement span{
    /* background: #ff5c5d; */
    font-weight: 600;
    font-size: 18px;
    display: inline-block;
    position: relative;
    padding: 5px 0 0 0px;
    cursor: default;
    color: rgba(77,77,77,0.9);
  }
  #tsdkCardContainer .card .card-body.enhancement span.wrong_text{
    display: none;
  }

  #tsdkCardContainer .card .card-body.advisor span.dotspan{
    position: absolute;
    left: 10px;
    width: 6px;
    padding: 0;
    height: 6px;
    background: #ff5c5d;
    border-radius: 50%;
    top: 21px;
    margin: 0;
  }
  #tsdkCardContainer .card .card-body.grammar span{
    color: #4d4d4d;
    font-weight: 600;
    padding: 6px 0 0 0px;
    display: inline-block;
  }

  #tsdkCardContainer .card .footer_buttons {
    text-align: right;
    padding: 10px 1px 0 15px;
    display: flex;
    align-items: initial;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .Add_to_dictionaryButton {
    font-size: 12px;
    border: none;
    padding: 3px 12px;
    border-radius: 20px;
    cursor: pointer;
    background: rgba(122,40,160,.25);
    color: #4d4d4d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    letter-spacing: 1px;
    font-family: Oxygen,Geneva,sans-serif;
  }
  .Add_to_dictionaryButton span{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Add_to_dictionaryButton span i{
    display: inline-block;
    font-size: 16px;
    margin: 0 7px 0 0;
  }
  .Add_to_dictionaryButton:focus {
    box-shadow: none;
    outline: none;
  }
  .Add_to_dictionaryButton span img{
    width: 100%;
    max-width: 9px;
  }
  .Add_to_dictionaryButton:hover {
    background: #4a3176;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  #tsdkCardContainer .card .footer_buttons .rejectButtons,
  #tsdkCardContainer .card .footer_buttons .IngoreButtons {
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;
    outline: none;
    cursor: pointer;
  }
  #tsdkCardContainer .card .footer_buttons .rejectButtons:focus,
  #tsdkCardContainer .card .footer_buttons .IngoreButtons:focus {
    box-shadow: none;
    outline: none;
  }
  #tsdkCardContainer .card .footer_buttons .rejectButtons svg,
  #tsdkCardContainer .card .footer_buttons .IngoreButtons svg {
    width: 16px;
    cursor: pointer;
    fill: #4d4d4d !important;
  }
  #tsdkCardContainer .card .footer_buttons .IngoreButtons svg path {
    stroke: #7a7a7a !important;
    stroke-width: 3px !important;
  }
  #tsdkCardContainer .card .footer_buttons .rejectButtons svg:hover,
  #tsdkCardContainer .card .footer_buttons .IngoreButtons svg:hover {
    fill: #4a3176;
  }
  #tsdkCardContainer .card .footer_buttons .IngoreButtons{
    margin: 0 0 12px 0;
  }
  #tsdkCardContainer .card .footer_buttons .IngoreButtons img{
    width: 100%;
    max-width: 15px;
  }
  #tsdkCardContainer .card .footer_buttons .rejectButtons img{
    transform: rotate(180deg);
    width: 100%;
    max-width: 22px;
  }
  .footersignUp.active{
    display:block;
  }
  .footersignUp{
    display:none;
    border-top: solid 1px #ccc;
    margin-top: 8px;
    padding-top: 5px;
    text-align: left;
   font-family: "Open Sans", sans-serif;
  }
  .footersignUp span{
    margin: 0;
    font-weight: 500;
    color: #3c3c3c;
    font-size: 13px;
  }
  .footersignUp span a:hover{
    color: #4a3176;
  }
  .footersignUp button{
    color: #4a3176;
    font-weight: 600;
    background: none;
    border: none;
  }
  .footersignUp button:focus{
    outline: none;
    box-shadow: none;
  }
  .footerVerify.active{
    display:block;
  }
  .footerVerify{
    display:none;
    border-top: solid 1px #ccc;
    margin-top: 8px;
    padding-top: 5px;
    text-align: left;
  }
  .footerVerify span{
    margin: 0;
    font-weight: 500;
    color: #3c3c3c;
    font-size: 13px;
  }
  .footerVerify span a:hover{
    color: #4a3176;
  }
  .footerVerify button{
    color: #4a3176;
    font-weight: 600;
    background: none;
    border: none;
  }
  .footerVerify button:focus{
    outline: none;
    box-shadow: none;
  }
  .footerlimitExhaust.active{
    display:block;
  }
  .footerlimitExhaust{
    display:none;
    border-top: solid 1px #ccc;
    margin-top: 8px;
    padding-top: 5px;
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }
  .footerlimitExhaust span{
    margin: 0;
    font-weight: 500;
    color: #3c3c3c;
    font-size: 13px;
  }
  .footerlimitExhaust span a:hover{
    color: #4a3176;
  }
  .footerlimitExhaust button{
    color: #4a3176;
    font-weight: 600;
    background: none;
    border: none;
  }
  .footerlimitExhaust button:focus{
    outline: none;
    box-shadow: none;
  }
  .cardNewBody,
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .card-body{
    display: none;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper{
    background: #FFF;
    padding: 0px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.2);
    max-width: 400px;
    min-width: 150px;
    overflow: hidden;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardTitleSection{
    display: none;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .card-body .footer_buttons{
    display: none;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody{
    display: block;
    box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.2);
    background: linear-gradient(180deg, #FBF2FF 0%, #FFFFFF 100%);
    transition: all 0.5s ease;
    padding-top: 1px;
  }

  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewDescriptions:hover {
    background: rgba(234, 190, 255, 0.2);
    transition: all 0.5s ease;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewDescriptions.multiAlertCardHeader .errorWordCorrection .right_text.deleteText{
    cursor: default !important;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewDescriptions.multiAlertCardHeader:hover{
    background: transparent !important;
    cursor: default;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions{
    margin: 4px;
    border-radius: 8px;
    padding: 4px 10px 4px 4px;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions .categoryName{
    position: relative;
    margin-bottom: 2px;
    padding-left: 10px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions .categoryName::before{
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #DC2626;
    left: 0;
    top: 5px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions .categoryName p{
    color: #4D4D4D;
    font-size: 10px;
    font-weight: 400;
    line-height: 13.62px;
    text-align: left;
    margin: 0;
  }
  #tsdkCardContainer.trinkaCloudEditor .description_text{
    color: #3C3C3C;
    margin: 4px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    font-family: "Open Sans", sans-serif;
  }
  #tsdkCardContainer.trinkaCloudEditor .description_text.multiAlertCardHeader{
    padding-left: 20px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions .errorWordCorrection{
    position: relative;
    margin-bottom: 6px;
    display: inline-block;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions .errorWordCorrection .right_text.deleteText{
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    color: #DC2626;
    margin-right: 0px;
    padding: 0;
    position: relative;
    cursor: default;
    text-decoration: line-through;
    text-decoration-color: #DC2626;
    font-family: "Open Sans", sans-serif;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewDescriptions .errorWordCorrection .right_text{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #16A34A;
    text-decoration: underline;
    font-family: "Open Sans", sans-serif;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    flex-direction: column;  //TODO: @shabaz please check this adding it to go to next row
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter.cardFooterRowType {
    padding: 10px 8px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons.b_addToMyDicitonary{
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    color: #647087;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .b_addToMyDicitonary svg{
    margin-right: 4px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons{
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: #647087;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    padding: 4px 8px;
    font-family: "Open Sans", sans-serif;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons.multiAlertCardHeader{
    padding: 4px 14px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons svg{
    margin-right: 4px;
    position: relative;
    top: -1px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons:hover{
    color: #647087;
    background-color: #EFF1F5;
    cursor: pointer;
    border-radius: 4px;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .CTAForMultipleError{
    display: flex;
    align-items: center;
    position: relative;rejectButtons
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .CTAForMultipleError a{
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .CTAForMultipleError a:hover svg path{
    fill: #7A28A0;
  }
  .disablePrev, .disableNext {
      cursor: not-allowed !important;
  }
  .multiCardWrapper {
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
  }
  .multiCardWrapper .singleSuggestion {
    margin: 0;
    color: rgba(22, 163, 74, 1);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 8px;
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
  }
  .multiCardWrapper .singleSuggestion .right_text{
    text-decoration: underline;
  }
  .multiCardWrapper .singleSuggestion:hover{
    background-color: rgba(234, 190, 255, 0.2);
  }
  .multiCardWrapper .singleSuggestion svg{
    margin-right: 10px;
  }

  .multiCardWrapper .singleSuggestion .multiAlertAcceptButton {
    background-color: transparent;
    border: none;
    color: #7A28A0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    left: auto;
    right: 10px;
    top: 1px;
    display: none;
    font-family: "Open Sans", sans-serif;
    padding: 0;
    margin: 0;
  }
  .multiCardWrapper .singleSuggestion:hover .multiAlertAcceptButton {
    display: block !important;
    cursor: pointer;
  }
  .legalWritingText{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: rgb(220, 38, 38);
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons.wasHelpFulBtn {
    background: transparent;
    border: none;
    color: rgb(100, 112, 135);
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
    cursor: pointer;
    margin: 4px 0;
  }
  #tsdkCardContainer.trinkaCloudEditor #cardWrapper .cardNewBody .cardNewFooter .rejectButtons.wasHelpFulBtn:hover{
    background: rgb(239, 241, 245);
    transition: all 0.5s ease;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", String)
], OverlayCardComponent.prototype, "editorId", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "alert", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "top", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "left", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "isOpen", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "limitExhausted", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "loginRequired", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "verifyEmail", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "currentIndexOfSuggestion", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "trinkaEditorConfig", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", String)
], OverlayCardComponent.prototype, "visibility", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], OverlayCardComponent.prototype, "filteredAlertList", void 0);
exports.OverlayCardComponent = OverlayCardComponent = __decorate([
    (0, decorators_js_1.customElement)('trinka-card'),
    __metadata("design:paramtypes", [])
], OverlayCardComponent);
