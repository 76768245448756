"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskEditorOverlay = void 0;
class AskEditorOverlay {
    constructor(editorId, editor, isCloudTrinka) {
        this.editorId = editorId;
        this.isCloudTrinka = isCloudTrinka;
        this.trinkaExtension = document.createElement('ask-trinka-extension');
        const trinkaExtensionShadowRoot = this.trinkaExtension.attachShadow({ mode: 'open' });
        editor.parentElement.appendChild(this.trinkaExtension);
        const style = document.createElement('style');
        style.textContent = `
    #magic-wand span{
        display: none;
      }
      #magic-wand:hover span{
        display: flex;
        justify-content: center;
      }
      #magic-wand span::before{
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        border-bottom-color: #414E62;
        border-width: 4px;
        margin-left: -4px;
      }
    `;
        trinkaExtensionShadowRoot.appendChild(style);
        this.editor = editor;
        this.editorOverlayWrapper = this.createOverlay();
        trinkaExtensionShadowRoot.appendChild(this.editorOverlayWrapper);
    }
    handleScrollEvent() {
        var _a;
        if (!this.editorOverlay || !this.editorOverlayElements)
            return;
        this.editorOverlay.scrollTop = this.editor.scrollTop;
        this.editorOverlay.scrollLeft = this.editor.scrollLeft;
        Object.assign(this.editorOverlayElements.style, {
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
    }
    handleResizeEvent() {
        var _a;
        if (!this.editorOverlay || !this.editorOverlayElements)
            return;
        const computedstyle = window.getComputedStyle(this.editor);
        Object.assign(this.editorOverlay.style, {
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay.scrollTop = this.editor.scrollTop;
        this.editorOverlay.scrollLeft = this.editor.scrollLeft;
        Object.assign(this.editorOverlayElements.style, {
            'width': `${this.editor.offsetWidth}px`,
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
    }
    createOverlay() {
        var _a;
        const computedstyle = window.getComputedStyle(this.editor);
        const editorOverlayWrapper = document.createElement('div');
        editorOverlayWrapper.id = 'editorOverlayWrapper';
        Object.assign(editorOverlayWrapper.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'top': '0px',
            'left': '0px'
        });
        this.editorOverlayElements = document.createElement('div');
        this.editorOverlayElements.id = 'editorOverlayElements';
        Object.assign(this.editorOverlayElements.style, {
            'position': 'relative',
            'top': '0px',
            'left': '0px',
            'width': `${(parseFloat(computedstyle.width))}px`,
            'height': `${(parseFloat((_a = this.editor) === null || _a === void 0 ? void 0 : _a.scrollHeight))}px`,
        });
        this.editorOverlayViewport = document.createElement('div');
        this.editorOverlayViewport.id = 'editorOverlayViewport';
        this.editorOverlayViewport.classList.add('editorOverlayViewport');
        Object.assign(this.editorOverlayViewport.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'overflow': 'auto',
            'scrollbar-width': 'none',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay = document.createElement('div');
        this.editorOverlay.id = 'editorOverlay';
        this.editorOverlay.classList.add('editorOverlay');
        Object.assign(this.editorOverlay.style, {
            'position': 'absolute',
            'pointer-events': 'none',
            'overflow': 'auto',
            'scrollbar-width': 'none',
            'border': '0px',
            'border-radius': '0px',
            'margin': '0px',
            '-webkit-box-sizing': 'border-box',
            '-moz-box-sizing': 'border-box',
            '-box-sizing': 'border-box',
            'width': `${this.editor.offsetWidth}px`,
            'height': `${this.editor.offsetHeight}px`,
            'top': `${this.editor.offsetTop}px`,
            'left': `${this.editor.offsetLeft}px`
        });
        this.editorOverlay.addEventListener('scroll', ($event) => {
            this.editor.scrollTop = this.editorOverlay.scrollTop;
            this.editor.scrollLeft = this.editorOverlay.scrollLeft;
        });
        this.editorOverlay.appendChild(this.editorOverlayElements);
        editorOverlayWrapper.appendChild(this.editorOverlay);
        editorOverlayWrapper.appendChild(this.editorOverlayViewport);
        return editorOverlayWrapper;
    }
    remove() {
        var _a;
        if (this.editor.parentElement && this.editor.parentElement.contains(this.trinkaExtension)) {
            this.editor.parentElement.removeChild(this.trinkaExtension);
        }
        else
            (_a = this.trinkaExtension) === null || _a === void 0 ? void 0 : _a.remove();
    }
}
exports.AskEditorOverlay = AskEditorOverlay;
