"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FupCard = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let FupCard = class FupCard extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.userType = 'BASIC';
        this.userUpgradeLink = '';
        this.currentLang = 'en';
    }
    render() {
        return (0, lit_1.html) `<div class="fup-card">
    ${this.userType === 'BASIC' ? (0, lit_1.html) `
      <h4>Monthly Usage Limit Reached!</h4>
      <div class="userLimitExhaust">
        <p style="margin-bottom: 16px">You’ve reached your 5000-word limit. However, you can still review and apply suggestions for processed sentences. <a style="cursor: pointer" @click=${() => this.openLimitExhaustModal()}>Learn more</a> to understand this better and get additional assistance.</p>
        <button @click=${() => this.redirectToUpgradeLink()}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3739 6.03781L11.8739 2.03781C11.8271 1.98436 11.7694 1.9415 11.7048 1.91208C11.6401 1.88266 11.5699 1.86736 11.4989 1.86719H4.49887C4.42783 1.86736 4.35765 1.88266 4.29298 1.91208C4.22832 1.9415 4.17067 1.98436 4.12387 2.03781L0.623874 6.03781C0.542222 6.13104 0.4981 6.25126 0.500063 6.37517C0.502026 6.49908 0.549935 6.61785 0.634499 6.70844L7.6345 14.2084C7.68128 14.2586 7.73788 14.2986 7.80078 14.3259C7.86368 14.3533 7.93154 14.3674 8.00012 14.3674C8.06871 14.3674 8.13656 14.3533 8.19946 14.3259C8.26236 14.2986 8.31896 14.2586 8.36575 14.2084L15.3657 6.70844C15.45 6.61753 15.4974 6.4986 15.4989 6.37469C15.5004 6.25078 15.4559 6.13073 15.3739 6.03781ZM13.897 5.86719H11.2489L8.99887 2.86719H11.272L13.897 5.86719ZM4.66012 6.86719L6.54325 11.5753L2.1495 6.86719H4.66012ZM10.2601 6.86719L7.99887 12.5209L5.73762 6.86719H10.2601ZM5.99887 5.86719L7.99887 3.20031L9.99887 5.86719H5.99887ZM11.3376 6.86719H13.8482L9.4545 11.5753L11.3376 6.86719ZM4.72575 2.86719H6.99887L4.74887 5.86719H2.10075L4.72575 2.86719Z" fill="#92400E"/>
          </svg>
          Upgrade to get full access
        </button>
      </div>` : (0, lit_1.html) `<h4>Monthly Fair Usage Limit Reached!</h4>
        <div class="userLimitExhaust">
          <p>You’ve reached your 150,000-word limit. However, you can still review and apply suggestions for processed sentences. <a style="cursor: pointer" @click=${() => this.openLimitExhaustModal()}>Learn more</a> to understand this better and get additional assistance.</p>
        </div>`}
    </div>`;
    }
    firstUpdated() {
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    openLimitExhaustModal() {
        const limitExhaustEvent = new CustomEvent("limitExhausted", {
            detail: {
                isLimitExhausted: true,
                fromClick: true
            },
            bubbles: true,
            composed: true,
        });
        this.dispatchEvent(limitExhaustEvent);
    }
    redirectToUpgradeLink() {
        window.open(this.userUpgradeLink);
    }
};
exports.FupCard = FupCard;
FupCard.styles = (0, lit_1.css) `
  .fup-card {
    padding: 0px 0px 4px 0px;
    position: relative;
    background: #321F41;
    border-radius: 10px;
    margin-bottom: 12px;
    transition: 0.5s;
    overflow: hidden;
    z-index: 0;
  }
  .fup-card h4{
    position: relative;
    text-align: center;
    padding: 16px 0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    background-color: #FDE68A;
    color: #111A27;
  }
  .fup-card .userLimitExhaust{
      position: relative;
      padding: 20px;
  }
  .fup-card .userLimitExhaust p{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0;
    color: #FFFFFF;
    padding: 0 10px;
  }
  .fup-card .userLimitExhaust p a{
    color: #FFFFFF;
    text-decoration: underline;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;

  }
  .fup-card .userLimitExhaust p a:hover{
    text-decoration: underline;
  }
  .fup-card .userLimitExhaust button{
    padding: 10px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDE68A;
    border: none;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin: 0 auto;
    cursor: pointer;
    color: #92400E;
  }
  .fup-card .userLimitExhaust button:hover{
    background-color: #e2ce7f;
  }
  .fup-card .userLimitExhaust button:focus{
    box-shadow: none;
    outline: none;
  }
  .fup-card .userLimitExhaust button svg{
    margin-right: 6px;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], FupCard.prototype, "userType", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], FupCard.prototype, "userUpgradeLink", void 0);
exports.FupCard = FupCard = __decorate([
    (0, decorators_js_1.customElement)('fup-card')
], FupCard);
