"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggablePanel = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
require("./cc-alerts-panel");
require("./no-alerts-panel");
require("./draggable-panel-header");
require("./draggable-panel-footer");
let DraggablePanel = class DraggablePanel extends lit_1.LitElement {
    updated(changedProperties) {
    }
    render() {
        return (0, lit_1.html) `
    <div id="draggablePanel" class="consistencyCheckSection">
      <draggable-panel-header-cc
      editorId="${this.editorId}"
      .filters="${this.filters}"
      .alertCounter="${this.filteredAlertList.length}"
      .showDraggablePanelHeader="${this.showDraggablePanelHeader}"
      @draggable-panel-close="${this.close}"
      @close-all-cards=${this.closeAllCards}
      ></draggable-panel-header-cc>

      ${this.filteredAlertList && this.filteredAlertList.length
            ? (0, lit_1.html) `<alerts-panel-cc .langCode="${this.langCode}" .filteredAlertList="${this.filteredAlertList}" .editorId="${this.editorId}"></alerts-panel-cc>`
            : (0, lit_1.html) `<no-alerts-panel-cc .isEditorEmpty="${this.isEditorEmpty}" .loader="${this.loader}"></no-alerts-panel>`}

      <draggable-panel-footer-cc
      .limitExhausted="${this.limitExhausted}"
      .loginRequired="${this.loginRequired}"
      .loader="${this.loader}"></draggable-panel-footer>
    </div>
    `;
    }
    constructor(editorId) {
        super();
        this.alertList = [];
        this.filteredAlertList = [];
        this.limitExhausted = false;
        this.loginRequired = false;
        this.loader = false;
        this.filters = [];
        this.showDraggablePanelHeader = true;
        this.isEditorEmpty = false;
        this.langCode = '';
        this.boundHandleClickEvent = ($event) => this.handleClickEvent($event);
        this.boundHandleMouseDownEvent = ($event) => this.handleMousedownEvent($event, this.draggablePanel);
        this.editorId = editorId;
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.draggablePanel.removeEventListener('mousedown', this.boundHandleMouseDownEvent);
    }
    firstUpdated() {
        this.draggablePanel = this.shadowRoot.querySelector('#draggablePanel');
        this.draggablePanel.addEventListener('mousedown', this.boundHandleMouseDownEvent);
        this.draggablePanel.addEventListener('click', this.boundHandleClickEvent);
    }
    handleMousedownEvent($event, draggablePanel) {
        if (this.showDraggablePanelHeader) {
            let offsetX, offsetY;
            offsetX = $event.clientX - draggablePanel.getBoundingClientRect().left;
            offsetY = $event.clientY - draggablePanel.getBoundingClientRect().top;
            const dragMove = ($event) => {
                const x = $event.clientX - offsetX;
                const y = $event.clientY - offsetY;
                draggablePanel.style.left = `${x}px`;
                draggablePanel.style.top = `${y}px`;
            };
            const dragEnd = () => {
                document.removeEventListener('mousemove', dragMove);
                document.removeEventListener('mouseup', dragEnd);
            };
            document.addEventListener('mousemove', dragMove);
            document.addEventListener('mouseup', dragEnd);
        }
    }
    close($event) {
        if (this.draggablePanelHeaderComponent) {
            this.draggablePanelHeaderComponent.closeFilter();
        }
        this.draggablePanel.style.display = 'none';
    }
    open() {
        this.draggablePanel.style.display = 'block';
    }
    closeAllCards() {
        if (this.alertsPanelCc && this.alertsPanelCc.closeAllCards)
            this.alertsPanelCc.closeAllCards();
    }
    setRightPanelForCloud() {
        setTimeout(() => {
            this.draggablePanel.style.right = '0px';
            this.draggablePanel.style.top = '72px';
            this.draggablePanel.style.width = '395px';
        });
    }
    handleClickEvent(event) {
        var _a, _b, _c, _d;
        if (event.target.closest("draggable-panel-header-cc")) {
            return;
        }
        else {
            const filter = (_c = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('draggable-panel-header-cc')) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.filter');
            (_d = filter === null || filter === void 0 ? void 0 : filter.classList) === null || _d === void 0 ? void 0 : _d.remove('active');
        }
    }
};
exports.DraggablePanel = DraggablePanel;
DraggablePanel.styles = (0, lit_1.css) `
  #draggablePanel {
    position: fixed;
    top: 10px;
    right: 10px;
    background: #EEEEEE;
    box-shadow: rgba(0, 0, 0, 0.075) 8px -5px 4px -5px inset;
    border-radius: 3px;
    width: 390px;
    height: calc(100% - 45px);
    pointer-events: all;
    z-index: 5;
    display: none;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "alertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "filteredAlertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "limitExhausted", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "loginRequired", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "filters", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "showDraggablePanelHeader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "isEditorEmpty", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "langCode", void 0);
__decorate([
    (0, decorators_js_1.query)('alerts-panel-cc'),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "alertsPanelCc", void 0);
__decorate([
    (0, decorators_js_1.query)('draggable-panel-header-cc'),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "draggablePanelHeaderComponent", void 0);
exports.DraggablePanel = DraggablePanel = __decorate([
    (0, decorators_js_1.customElement)('trinka-popup-cc'),
    __metadata("design:paramtypes", [Object])
], DraggablePanel);
