"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskPopover = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const constants_1 = require("../../helpers/constants");
const utils_1 = require("src/helpers/utils");
const environment_1 = require("src/env/environment");
const utils_2 = require("src/helpers/utils");
let AskPopover = class AskPopover extends lit_1.LitElement {
    updated(changedProperties) { }
    get totalPages() {
        return this.processedResponses.length;
    }
    prevPage() {
        (0, utils_1.triggerAmplitudeEvent)('navigation_button', {
            action: 'previous',
            user_plan: this.config.userPlan,
            right_panel: this.activeModule
        });
        if (this.currentPage > 0) {
            this.currentPage -= 1;
        }
    }
    nextPage() {
        (0, utils_1.triggerAmplitudeEvent)('navigation_button', {
            action: 'next',
            user_plan: this.config.userPlan,
            right_panel: this.activeModule
        });
        if (this.currentPage < this.processedResponses.length - 1) {
            this.currentPage += 1;
        }
    }
    renderNestedPopoverOptions(title, options) {
        return (0, lit_1.html) `
      <div class="subMenu">
        <!-- <span class="writeText">${title}</span> -->
        <ul>
          ${Object.keys(options).map((key) => {
            const option = options[key];
            return (0, lit_1.html) `
              <li @click=${($event) => this.onOptionSelection($event, option)}>
                <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_809_1292" fill="white">
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                    </mask>
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                    <mask id="path-2-inside-2_809_1292" fill="white">
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                    </mask>
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                  </svg>
                ${option.title}
              </li>
            `;
        })}
        </ul>
      <div>
    `;
    }
    handleHover($event, option) {
        const container = this.shadowRoot.querySelector(`#stage-1-menu-wrapper`);
        const containerRect = container.getBoundingClientRect();
        const target = $event.target;
        const rect = target.getBoundingClientRect();
        this.externalMenusPopupPosition = {
            top: rect.top - containerRect.top,
            left: containerRect.width,
            display: option.nestedOptions,
            option
        };
    }
    renderPopoverOptions(options) {
        return (0, lit_1.html) `
      <ul>
        ${Object.keys(options).filter((key) => this.activeModule === constants_1.SDK_MODULES.PARAPHRASER ? !!(key !== 'paraphrase') : true).map((key) => {
            const option = options[key];
            return (0, lit_1.html) `
            <li @click=${($event) => this.onOptionSelection($event, option)} @mouseover=${($event) => this.handleHover($event, option)}>
              <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_809_1292" fill="white">
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                    </mask>
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                    <mask id="path-2-inside-2_809_1292" fill="white">
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                    </mask>
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                </svg>
              ${option.title}
              <!--
              ${option.nestedOptions && option.options
                ? this.renderNestedPopoverOptions(`${option.title}`, option.options)
                : ''}
              -->
              ${option.nestedOptions && option.options
                ? (0, lit_1.html) `
                  <svg class="rightArrow" style="position: absolute;margin: 0;left: auto;right: 10px;" width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.64795 4.70471L1.89795 8.45471C1.79228 8.56038 1.64895 8.61975 1.49951 8.61975C1.35007 8.61975 1.20675 8.56038 1.10107 8.45471C0.995401 8.34904 0.936035 8.20572 0.936035 8.05627C0.936035 7.90683 0.995401 7.76351 1.10107 7.65784L4.4531 4.30674L1.10201 0.954711C1.04969 0.902388 1.00818 0.84027 0.979865 0.771906C0.951547 0.703542 0.936972 0.63027 0.936972 0.556274C0.936972 0.482277 0.951547 0.409005 0.979865 0.340641C1.00818 0.272277 1.04969 0.21016 1.10201 0.157836C1.15433 0.105513 1.21645 0.0640073 1.28482 0.0356899C1.35318 0.00737265 1.42645 -0.00720215 1.50045 -0.00720215C1.57445 -0.00720215 1.64772 0.00737264 1.71608 0.0356899C1.78445 0.0640073 1.84656 0.105513 1.89889 0.157836L5.64889 3.90784C5.70126 3.96016 5.7428 4.0223 5.77111 4.09071C5.79942 4.15911 5.81395 4.23244 5.81386 4.30647C5.81377 4.3805 5.79907 4.45379 5.7706 4.52213C5.74213 4.59047 5.70045 4.65251 5.64795 4.70471Z" fill="#9CA3AF"/>
                  </svg>
                `
                : ''}
            </li>
          `;
        })}
      </ul>
    `;
    }
    render() {
        var _a, _b, _c, _d, _e;
        return (0, lit_1.html) `
    <div id="askPopoverWrapper">
      <div id="askPopover" class="${this.isCloudTrinka ? 'trinkaCloudEditor' : 'trinkaB2BEditor'}" style="display: ${this.isOpen ? 'block' : 'none'}">
        <!-- <div id="askPopoverHeader">
          <span class="dgl_h_cb" @click=${this.close}>
            <svg width="13" height="13" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
              d="M9.13169 7.48946L14.1435 2.4893C14.363 2.26982 14.4863 1.97215 14.4863 1.66176C14.4863 1.35138 14.363 1.05371 14.1435 0.834231C13.924 0.614755 13.6264 0.491455 13.316 0.491455C13.0056 0.491455 12.7079 0.614755 12.4885 0.834231L7.48828 5.84605L2.48811 0.834231C2.26864 0.614755 1.97097 0.491455 1.66058 0.491455C1.35019 0.491455 1.05252 0.614755 0.833046 0.834231C0.613571 1.05371 0.49027 1.35138 0.49027 1.66176C0.49027 1.97215 0.613571 2.26982 0.833046 2.4893L5.84487 7.48946L0.833046 12.4896C0.723802 12.598 0.637093 12.7269 0.57792 12.8689C0.518747 13.011 0.488281 13.1633 0.488281 13.3172C0.488281 13.471 0.518747 13.6234 0.57792 13.7654C0.637093 13.9074 0.723802 14.0363 0.833046 14.1447C0.941398 14.2539 1.07031 14.3406 1.21234 14.3998C1.35437 14.459 1.50671 14.4895 1.66058 14.4895C1.81444 14.4895 1.96679 14.459 2.10882 14.3998C2.25085 14.3406 2.37976 14.2539 2.48811 14.1447L7.48828 9.13287L12.4885 14.1447C12.5968 14.2539 12.7257 14.3406 12.8677 14.3998C13.0098 14.459 13.1621 14.4895 13.316 14.4895C13.4698 14.4895 13.6222 14.459 13.7642 14.3998C13.9063 14.3406 14.0352 14.2539 14.1435 14.1447C14.2528 14.0363 14.3395 13.9074 14.3986 13.7654C14.4578 13.6234 14.4883 13.471 14.4883 13.3172C14.4883 13.1633 14.4578 13.011 14.3986 12.8689C14.3395 12.7269 14.2528 12.598 14.1435 12.4896L9.13169 7.48946Z"
              fill="#4D4D4D" fill-opacity="0.5"></path>
            </svg>
          </span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.402 5.86956L18.8477 7.23913L17.4781 4.79348L18.8477 2.34782L16.402 3.71739L13.9564 2.34782L15.326 4.79348L13.9564 7.23913L16.402 5.86956ZM4.66291 15.4565L2.21726 14.087L3.58682 16.5326L2.21726 18.9783L4.66291 17.6087L7.10856 18.9783L5.739 16.5326L7.10856 14.087L4.66291 15.4565ZM2.21726 2.34782L3.58682 4.79348L2.21726 7.23913L4.66291 5.86956L7.10856 7.23913L5.739 4.79348L7.10856 2.34782L4.66291 3.71739L2.21726 2.34782ZM10.689 12.8935L8.30204 10.5065L10.376 8.43261L12.7629 10.8196L10.689 12.8935ZM9.68139 7.52282L7.39226 9.81195C7.01074 10.1739 7.01074 10.8098 7.39226 11.1913L18.8086 22.6076C19.1901 22.9891 19.826 22.9891 20.1879 22.6076L22.477 20.3185C22.8586 19.9565 22.8586 19.3207 22.477 18.9391L11.0607 7.52282C10.6792 7.1413 10.0433 7.1413 9.68139 7.52282Z" fill="#FBBF24"/>
          </svg>
          <h3>Trinka AI</h3>
        </div> -->

        <div id="askPopoverBody">
          <!-- ${this.selectedText ?
            (0, lit_1.html) `
              <div style="margin-top: 15px;">
                <h3>Selection</h3>
                <div>
                  ${this.selectedText}
                </div>
              </div>
            ` :
            (0, lit_1.html) `
              <h3>Try selecting something</h3>
            `} -->

          <div id="stage-1-menu-wrapper" style="position: relative; width: fit-content;">
            <div id="stage-1" class="stages">
              <!--
              <span class="writeText">Write</span>
              ${this.renderPopoverOptions(this.options.write)}
              -->
              <div class=labsTag>
                <h5>Rewrite</h5>
                <span data-no-translate>
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34114 8C7.31864 5.4465 6.12514 4.7735 6.12514 3.5V2H6.37514C6.44144 2 6.50503 1.97366 6.55191 1.92678C6.5988 1.87989 6.62514 1.8163 6.62514 1.75V1.25C6.62514 1.1837 6.5988 1.12011 6.55191 1.07322C6.50503 1.02634 6.44144 1 6.37514 1H3.87514C3.80883 1 3.74524 1.02634 3.69836 1.07322C3.65148 1.12011 3.62514 1.1837 3.62514 1.25V1.75C3.62514 1.8163 3.65148 1.87989 3.69836 1.92678C3.74524 1.97366 3.80883 2 3.87514 2H4.12514V3.5C4.12514 4.7735 2.93164 5.4465 1.90914 8C1.58364 8.8125 0.747638 10 5.12514 10C9.50264 10 8.66664 8.8125 8.34114 8ZM7.53264 8.8755C7.18164 8.9805 6.48314 9.1 5.12514 9.1C3.76714 9.1 3.06864 8.9805 2.71764 8.8755C2.59314 8.8385 2.54464 8.694 2.58864 8.5615C2.69864 8.2265 2.90614 7.6475 3.29414 7.001C4.24214 5.4215 5.22564 7.2495 6.04414 7.2495C6.86264 7.2495 6.63814 6.469 6.95614 7.001C7.2486 7.49332 7.48523 8.01672 7.66164 8.5615C7.70564 8.694 7.65714 8.838 7.53264 8.8755Z" fill="white"/>
                  </svg>
                  Labs
                </span>
              </div>
              ${this.renderPopoverOptions(this.options.rewrite)}

              <span class="writeText">Analyse Argument</span>
              ${this.renderPopoverOptions(this.options.analyse_argument)}
            </div>

            <div id="externalMenusPopup" class="externalMenus" style="display: ${this.externalMenusPopupPosition.display ? 'block' : 'none'}; left: ${this.externalMenusPopupPosition.left}px; top: ${this.externalMenusPopupPosition.top}px;">
              <span class="writeText">Make it</span>
              ${((_a = this.externalMenusPopupPosition.option) === null || _a === void 0 ? void 0 : _a.nestedOptions) && ((_b = this.externalMenusPopupPosition.option) === null || _b === void 0 ? void 0 : _b.options)
            ? this.renderNestedPopoverOptions(`${(_c = this.externalMenusPopupPosition.option) === null || _c === void 0 ? void 0 : _c.title}`, (_d = this.externalMenusPopupPosition.option) === null || _d === void 0 ? void 0 : _d.options)
            : ''}
            </div>
          </div>

          <div id="stage-2" class="stages">
            ${!this.loader ?
            (0, lit_1.html) `
                <div class="prcessedWtihText">
                  <!-- <h3>Processed Text (${this.currentSelectedPrompt})</h3> -->
                  <p>${(_e = this.processedResponses[this.currentPage]) === null || _e === void 0 ? void 0 : _e.response}</p>

                  <div class="searchMoreText">
                    <div class="searchMoreInputBox">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 3.73325L12.6666 4.66659L11.7333 2.99992L12.6666 1.33325L11 2.26659L9.33329 1.33325L10.2666 2.99992L9.33329 4.66659L11 3.73325ZM2.99996 10.2666L1.33329 9.33325L2.26663 10.9999L1.33329 12.6666L2.99996 11.7333L4.66663 12.6666L3.73329 10.9999L4.66663 9.33325L2.99996 10.2666ZM1.33329 1.33325L2.26663 2.99992L1.33329 4.66659L2.99996 3.73325L4.66663 4.66659L3.73329 2.99992L4.66663 1.33325L2.99996 2.26659L1.33329 1.33325ZM7.10663 8.51992L5.47996 6.89325L6.8933 5.47992L8.51996 7.10659L7.10663 8.51992ZM6.41996 4.85992L4.85996 6.41992C4.59996 6.66659 4.59996 7.09992 4.85996 7.35992L12.64 15.1399C12.9 15.3999 13.3333 15.3999 13.58 15.1399L15.14 13.5799C15.4 13.3333 15.4 12.8999 15.14 12.6399L7.35996 4.85992C7.09996 4.59992 6.66663 4.59992 6.41996 4.85992Z" fill="#7A28A0"/>
                      </svg>
                      <input
                        type="text"
                        placeholder="Ask Trinka what to do next (eg. Improve fluency)"
                        @input=${this.handleInputChange}
                        @keypress="${this.handleKeyPress}"
                        autocomplete="off"
                      />
                      <button class="searchMoreSubmitButton" @click=${this.handleSubmit} ?disabled="${!this.tellTrinkaInput.length}">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.74742 3.13775L17.5058 9.27275C18.6091 9.76442 18.6091 11.2353 17.5058 11.7278L3.74742 17.8628C2.50158 18.4186 1.20825 17.1844 1.82575 16.0278L4.45242 11.1078C4.65658 10.7253 4.65658 10.2744 4.45242 9.89275L1.82575 4.97275C1.20908 3.81608 2.50075 2.58108 3.74742 3.13775Z" stroke="#9CA3AF" stroke-width="1.25"/>
                          <path d="M4.5835 10.499H17.9168" stroke="#9CA3AF" stroke-width="1.25"/>
                        </svg>
                      </button>
                    </div>
                    ${this.totalPages > 1 ? (0, lit_1.html) `
                      <div class="paginationSection" style="width: 96px;">
                        <!-- <span>Pagination ${this.currentPage + 1} out of ${this.totalPages}</span> -->
                        <button class="previousCTA" @click="${this.prevPage}" ?disabled="${this.currentPage === 0}">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3532 12.6465C10.3996 12.693 10.4365 12.7481 10.4616 12.8088C10.4868 12.8695 10.4997 12.9346 10.4997 13.0003C10.4997 13.066 10.4868 13.131 10.4616 13.1917C10.4365 13.2524 10.3996 13.3076 10.3532 13.354C10.3067 13.4005 10.2516 13.4373 10.1909 13.4625C10.1302 13.4876 10.0651 13.5006 9.99942 13.5006C9.93372 13.5006 9.86867 13.4876 9.80797 13.4625C9.74727 13.4373 9.69212 13.4005 9.64567 13.354L4.64567 8.35403C4.59918 8.30759 4.5623 8.25245 4.53714 8.19175C4.51197 8.13105 4.49902 8.06599 4.49902 8.00028C4.49902 7.93457 4.51197 7.86951 4.53714 7.80881C4.5623 7.74811 4.59918 7.69296 4.64567 7.64653L9.64567 2.64653C9.73949 2.55271 9.86674 2.5 9.99942 2.5C10.1321 2.5 10.2593 2.55271 10.3532 2.64653C10.447 2.74035 10.4997 2.8676 10.4997 3.00028C10.4997 3.13296 10.447 3.26021 10.3532 3.35403L5.70629 8.00028L10.3532 12.6465Z" fill="black"/>
                          </svg>
                        </button>
                        <span>${this.currentPage + 1} of ${this.totalPages}</span>
                        <button class="nextCTA" @click="${this.nextPage}" ?disabled="${this.currentPage === this.totalPages - 1}">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.64586 3.35372C4.5994 3.30726 4.56255 3.25211 4.53741 3.19141C4.51227 3.13072 4.49933 3.06566 4.49933 2.99997C4.49933 2.93427 4.51227 2.86921 4.53741 2.80852C4.56255 2.74782 4.5994 2.69267 4.64586 2.64622C4.69231 2.59976 4.74746 2.56291 4.80816 2.53777C4.86885 2.51263 4.93391 2.49969 4.99961 2.49969C5.0653 2.49969 5.13036 2.51263 5.19105 2.53777C5.25175 2.56291 5.3069 2.59976 5.35336 2.64622L10.3534 7.64622C10.3998 7.69265 10.4367 7.7478 10.4619 7.8085C10.487 7.8692 10.5 7.93426 10.5 7.99997C10.5 8.06567 10.487 8.13074 10.4619 8.19144C10.4367 8.25214 10.3998 8.30728 10.3534 8.35372L5.35336 13.3537C5.25954 13.4475 5.13229 13.5002 4.99961 13.5002C4.86692 13.5002 4.73968 13.4475 4.64586 13.3537C4.55204 13.2599 4.49933 13.1326 4.49933 13C4.49933 12.8673 4.55204 12.74 4.64586 12.6462L9.29273 7.99997L4.64586 3.35372Z" fill="black"/>
                          </svg>
                        </button>
                      </div>
                    ` : (0, lit_1.html) ``}
                  </div>
                </div>
              ` :
            (0, lit_1.html) `
              <div class="selectionLoader">
                <div class="spinner textLoader" style="width: 500px; padding-right: 20px; maring-right: 20px"></div>
                <div class="spinner textLoader" style="width: 400px;"></div>
                <div class="spinner textLoader" style="width: 300px;"></div>
                <div class="spinner textLoader" style="width: 200px;"></div>
              </div>
                `}
          </div>
        </div>
      </div>

      <div id="stage-2-options">
        ${this.processedResponses.length && !this.loader ?
            (0, lit_1.html) `
            <div id="listOfActions">
              <ul>
                <!-- <li @click=${() => this.performAction('replace')}>Replace</li> -->
                <li @click=${() => this.performAction('replace')}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM8 13.5C6.91221 13.5 5.84884 13.1774 4.94437 12.5731C4.0399 11.9687 3.33495 11.1098 2.91867 10.1048C2.50238 9.09977 2.39347 7.9939 2.60568 6.927C2.8179 5.86011 3.34173 4.8801 4.11092 4.11091C4.8801 3.34172 5.86011 2.8179 6.92701 2.60568C7.9939 2.39346 9.09977 2.50238 10.1048 2.91866C11.1098 3.33494 11.9687 4.03989 12.5731 4.94436C13.1774 5.84883 13.5 6.9122 13.5 8C13.4983 9.45818 12.9184 10.8562 11.8873 11.8873C10.8562 12.9184 9.45819 13.4983 8 13.5Z" fill="#7A28A0"/>
                    <path d="M11.0707 6.55414L11.1414 6.48343L11.0707 6.41272L10.5873 5.92929L10.5166 5.85861L10.4459 5.92926L6.88566 9.48613L5.55405 8.15777L5.48334 8.08724L5.41272 8.15786L4.92929 8.64129L4.85858 8.712L4.92929 8.78271L6.815 10.6684L6.88571 10.7391L6.95642 10.6684L11.0707 6.55414Z" fill="#7A28A0" stroke="#7A28A0" stroke-width="0.2"/>
                  </svg>
                  Replace Selection
                </li>
                <li @click=${() => this.performAction('insert')}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM8 13.5C6.91221 13.5 5.84884 13.1774 4.94437 12.5731C4.0399 11.9687 3.33495 11.1098 2.91867 10.1048C2.50238 9.09977 2.39347 7.9939 2.60568 6.927C2.8179 5.86011 3.34173 4.8801 4.11092 4.11091C4.8801 3.34172 5.86011 2.8179 6.92701 2.60568C7.9939 2.39346 9.09977 2.50238 10.1048 2.91866C11.1098 3.33494 11.9687 4.03989 12.5731 4.94436C13.1774 5.84883 13.5 6.9122 13.5 8C13.4983 9.45818 12.9184 10.8562 11.8873 11.8873C10.8562 12.9184 9.45819 13.4983 8 13.5ZM11 8C11 8.13261 10.9473 8.25979 10.8536 8.35355C10.7598 8.44732 10.6326 8.5 10.5 8.5H8.5V10.5C8.5 10.6326 8.44732 10.7598 8.35356 10.8536C8.25979 10.9473 8.13261 11 8 11C7.86739 11 7.74022 10.9473 7.64645 10.8536C7.55268 10.7598 7.5 10.6326 7.5 10.5V8.5H5.5C5.36739 8.5 5.24022 8.44732 5.14645 8.35355C5.05268 8.25979 5 8.13261 5 8C5 7.86739 5.05268 7.74021 5.14645 7.64645C5.24022 7.55268 5.36739 7.5 5.5 7.5H7.5V5.5C7.5 5.36739 7.55268 5.24021 7.64645 5.14645C7.74022 5.05268 7.86739 5 8 5C8.13261 5 8.25979 5.05268 8.35356 5.14645C8.44732 5.24021 8.5 5.36739 8.5 5.5V7.5H10.5C10.6326 7.5 10.7598 7.55268 10.8536 7.64645C10.9473 7.74021 11 7.86739 11 8Z" fill="#7A28A0"/>
                  </svg>
                  Insert below
                </li>
                <li @click=${() => this.performAction('try_again')}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 6.49999H2.5C2.36739 6.49999 2.24021 6.44731 2.14645 6.35354C2.05268 6.25977 2 6.13259 2 5.99999V2.99999C2 2.86738 2.05268 2.7402 2.14645 2.64643C2.24021 2.55266 2.36739 2.49999 2.5 2.49999C2.63261 2.49999 2.75979 2.55266 2.85355 2.64643C2.94732 2.7402 3 2.86738 3 2.99999V4.79311L3.91438 3.87874C5.02951 2.75799 6.544 2.12585 8.125 2.12124H8.15812C9.72568 2.1172 11.2317 2.73088 12.35 3.82936C12.4413 3.92277 12.4924 4.04821 12.4924 4.17884C12.4925 4.30947 12.4413 4.43491 12.35 4.52833C12.2587 4.62175 12.1345 4.67574 12.0039 4.67873C11.8733 4.68173 11.7467 4.6335 11.6512 4.54436C10.7191 3.62941 9.46427 3.11817 8.15812 3.12124H8.13C6.81261 3.12529 5.55067 3.65196 4.62125 4.58561L3.70688 5.49999H5.5C5.63261 5.49999 5.75979 5.55266 5.85355 5.64643C5.94732 5.7402 6 5.86738 6 5.99999C6 6.13259 5.94732 6.25977 5.85355 6.35354C5.75979 6.44731 5.63261 6.49999 5.5 6.49999ZM13.5 9.49999H10.5C10.3674 9.49999 10.2402 9.55266 10.1464 9.64643C10.0527 9.7402 10 9.86738 10 9.99999C10 10.1326 10.0527 10.2598 10.1464 10.3535C10.2402 10.4473 10.3674 10.5 10.5 10.5H12.2931L11.3787 11.4144C10.4495 12.3479 9.1878 12.8745 7.87063 12.8787H7.8425C6.53636 12.8818 5.28151 12.3706 4.34937 11.4556C4.3028 11.408 4.24718 11.3701 4.18578 11.3443C4.12437 11.3184 4.05842 11.3051 3.99179 11.3051C3.92517 11.3051 3.85922 11.3184 3.79781 11.3443C3.73641 11.3701 3.68079 11.408 3.63423 11.4556C3.58766 11.5033 3.55109 11.5598 3.52665 11.6218C3.50222 11.6837 3.49042 11.75 3.49195 11.8166C3.49348 11.8832 3.5083 11.9488 3.53555 12.0096C3.5628 12.0704 3.60192 12.1251 3.65062 12.1706C4.76892 13.2691 6.27495 13.8828 7.8425 13.8787H7.875C9.45579 13.874 10.97 13.2418 12.085 12.1212L13 11.2069V13C13 13.1326 13.0527 13.2598 13.1464 13.3535C13.2402 13.4473 13.3674 13.5 13.5 13.5C13.6326 13.5 13.7598 13.4473 13.8536 13.3535C13.9473 13.2598 14 13.1326 14 13V9.99999C14 9.86738 13.9473 9.7402 13.8536 9.64643C13.7598 9.55266 13.6326 9.49999 13.5 9.49999Z" fill="#7A28A0"/>
                  </svg>
                  Try again
                </li>
                <li @click=${() => this.performAction('discard')}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 3H11V2.5C11 2.10218 10.842 1.72064 10.5607 1.43934C10.2794 1.15804 9.89782 1 9.5 1H6.5C6.10218 1 5.72064 1.15804 5.43934 1.43934C5.15804 1.72064 5 2.10218 5 2.5V3H2.5C2.36739 3 2.24021 3.05268 2.14645 3.14645C2.05268 3.24021 2 3.36739 2 3.5C2 3.63261 2.05268 3.75979 2.14645 3.85355C2.24021 3.94732 2.36739 4 2.5 4H3V13C3 13.2652 3.10536 13.5196 3.29289 13.7071C3.48043 13.8946 3.73478 14 4 14H12C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V4H13.5C13.6326 4 13.7598 3.94732 13.8536 3.85355C13.9473 3.75979 14 3.63261 14 3.5C14 3.36739 13.9473 3.24021 13.8536 3.14645C13.7598 3.05268 13.6326 3 13.5 3ZM6 2.5C6 2.36739 6.05268 2.24021 6.14645 2.14645C6.24021 2.05268 6.36739 2 6.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5V3H6V2.5ZM12 13H4V4H12V13ZM7 6.5V10.5C7 10.6326 6.94732 10.7598 6.85355 10.8536C6.75979 10.9473 6.63261 11 6.5 11C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5V6.5C6 6.36739 6.05268 6.24021 6.14645 6.14645C6.24021 6.05268 6.36739 6 6.5 6C6.63261 6 6.75979 6.05268 6.85355 6.14645C6.94732 6.24021 7 6.36739 7 6.5ZM10 6.5V10.5C10 10.6326 9.94732 10.7598 9.85355 10.8536C9.75979 10.9473 9.63261 11 9.5 11C9.36739 11 9.24021 10.9473 9.14645 10.8536C9.05268 10.7598 9 10.6326 9 10.5V6.5C9 6.36739 9.05268 6.24021 9.14645 6.14645C9.24021 6.05268 9.36739 6 9.5 6C9.63261 6 9.75979 6.05268 9.85355 6.14645C9.94732 6.24021 10 6.36739 10 6.5Z" fill="#7A28A0"/>
                  </svg>
                  Discard
                </li>
              </ul>
            </div>
          ` : (0, lit_1.html) ``}
      </div>
    </div>
    `;
    }
    constructor(editorId, config, isCloudTrinka) {
        super();
        this.selectedText = '';
        this.isOpen = false;
        this.loader = false;
        this.processedResponses = [];
        this.currentSelectedPrompt = '';
        this.currentSelectedOption = 0;
        this.tellTrinkaInput = '';
        this.currentPage = 0;
        this.activeModule = constants_1.SDK_MODULES.GRAMMAR;
        this.externalMenusPopupPosition = { top: 0, left: 0, display: false, option: {} };
        this.promptsConfig = {
            userId: 'abc',
            userInstructions: '-',
            model: 'LLAMA 3.1',
            authorizationHeader: '',
            prompts: {
                rephrase: {
                    adminPrompt: 'Rephrase the text',
                    description: 'This prompt will rephrase the text'
                },
                simplify: {
                    adminPrompt: 'Simplify the text',
                    description: 'This prompt will simplify the text'
                },
                polish: {
                    adminPrompt: 'Polish the text',
                    description: 'This prompt will polish the text'
                },
                shorten: {
                    adminPrompt: 'Shorten the text',
                    description: 'This prompt will shorten the text'
                },
                enrich: {
                    adminPrompt: 'Enrich the text',
                    description: 'This prompt will enrich the text'
                },
            }
        };
        this.options = constants_1.ASK_POPOVER_OPTIONS;
        this.config = {};
        this.currentLang = 'en';
        this.editorId = editorId;
        this.config = config;
        this.isCloudTrinka = isCloudTrinka;
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    preventScroll($event) {
        $event.preventDefault();
    }
    firstUpdated() {
        this.askPopoverWrapper = this.shadowRoot.querySelector('#askPopoverWrapper');
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_2.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    close($event) {
        this.isOpen = false;
    }
    activateStage(stage) {
        if (!this.shadowRoot)
            return;
        const stages = this.shadowRoot.querySelectorAll('.stages');
        stages.forEach((stage) => {
            stage.style.display = 'none';
        });
        const activateStage = this.shadowRoot.querySelector(stage);
        activateStage.style.display = 'block';
    }
    async handleInputChange(event) {
        this.tellTrinkaInput = event.target.value;
    }
    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleSubmit();
        }
    }
    async handleSubmit() {
        if (!this.tellTrinkaInput || !this.tellTrinkaInput.length)
            return;
        this.currentSelectedOption = 293;
        this.loader = true;
        this.activateStage(`#stage-2`);
        const body = {
            "inputs": [
                {
                    "name": "",
                    "value": [
                        {
                            "name": "LLM_output",
                            "value": this.processedResponses[this.currentPage].response
                        },
                        {
                            "name": "User Prompt",
                            "value": this.tellTrinkaInput
                        }
                    ]
                }
            ],
            "model": "LLAMA 3.1",
            "app": this.currentSelectedOption,
            "raw": true
        };
        (0, utils_1.triggerAmplitudeEvent)('fine_tune_output', {
            appId: this.currentSelectedOption,
            user_plan: this.config.userPlan,
            right_panel: this.activeModule
        });
        const response = await this.makeApiCall(body);
        if (response) {
            this.processedResponses.push({ body, response });
            this.currentPage = this.processedResponses.length - 1;
            this.tellTrinkaInput = '';
        }
        this.loader = false;
    }
    waitTillAskpopoverLoaded() {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.shadowRoot.querySelector('#askPopoverWrapper')) {
                    clearInterval(interval);
                    this.askPopoverWrapper = this.shadowRoot.querySelector('#askPopoverWrapper');
                    resolve(true);
                }
            }, 500);
        });
    }
    async open(activeModule = constants_1.SDK_MODULES.GRAMMAR, position = null) {
        this.activeModule = activeModule;
        this.externalMenusPopupPosition.display = false;
        await this.waitTillAskpopoverLoaded();
        if (this.askPopoverWrapper && position) {
            setTimeout(() => {
                this.discardAction();
                this.askPopoverWrapper.style.top = `${position.top}`;
                this.askPopoverWrapper.style.left = `${position.left}`;
            }, 0);
        }
        this.isOpen = true;
    }
    async performAction(action) {
        switch (action) {
            case 'replace':
            case 'insert': {
                const currentAction = action === 'replace' ? 'replace_selection' : 'insert_below';
                (0, utils_1.triggerAmplitudeEvent)(currentAction, {
                    user_plan: this.config.userPlan,
                    right_panel: this.activeModule
                });
                this.dispatchEvent(new CustomEvent('tsdk-ask-replace-text', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        editorId: this.editorId,
                        text: this.processedResponses[this.currentPage].response,
                        action
                    }
                }));
                this.processedResponses = [];
                break;
            }
            case 'try_again': {
                this.loader = true;
                const body = this.processedResponses[this.currentPage].body;
                (0, utils_1.triggerAmplitudeEvent)('try_again', {
                    appId: body.app,
                    user_plan: this.config.userPlan,
                    right_panel: this.activeModule
                });
                const response = await this.makeApiCall(body);
                if (response) {
                    this.processedResponses[this.currentPage].response = response;
                }
                this.loader = false;
                break;
            }
            case 'discard': {
                (0, utils_1.triggerAmplitudeEvent)('discard', {
                    user_plan: this.config.userPlan,
                    right_panel: this.activeModule
                });
                this.discardAction();
                break;
            }
        }
    }
    discardAction() {
        this.activateStage(`#stage-1`);
        this.processedResponses = [];
        this.currentSelectedPrompt = '';
        this.loader = false;
        this.currentPage = 0;
        this.processedResponses = [];
    }
    async onOptionSelection($event, option) {
        $event.stopPropagation();
        const title = option.title;
        const appId = option.appId;
        this.externalMenusPopupPosition.display = false;
        this.currentSelectedOption = appId;
        this.loader = true;
        this.currentPage = 0;
        this.processedResponses = [];
        if (!appId)
            return;
        this.activateStage(`#stage-2`);
        const body = {
            "inputs": [
                {
                    "name": "",
                    "type": "text",
                    "placeholder": "",
                    "hint": "",
                    "ai": {
                        "enabled": false
                    },
                    "value": this.selectedText
                }
            ],
            "model": "LLAMA 3.1",
            "app": appId,
            "raw": true
        };
        (0, utils_1.triggerAmplitudeEvent)('select_and_rewrite', {
            title: title,
            appId: appId,
            user_plan: this.config.userPlan,
            right_panel: this.activeModule
        });
        const response = await this.makeApiCall(body);
        if (response) {
            this.processedResponses.push({ body, response });
        }
        this.loader = false;
    }
    onError(message) {
        this.discardAction();
        this.dispatchEvent(new CustomEvent('tsdk-ask-popover-on-error-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                message
            }
        }));
    }
    async makeApiCall(body) {
        const url = environment_1.environment.ASK_URL;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${this.config.token}`,
                    'user-plan': `${this.config.userPlan}`,
                    'source': 'ASK-JS'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const result = await response.json();
                return result && result.content ? result.content : '';
            }
            else if (response.status === 403) {
                const responseBody = await response.json();
                if (responseBody.error.toLowerCase() === "usage limit exceeded") {
                    console.error("Usage limit exceeded. Please try again later.");
                    const limitExhaustEvent = new CustomEvent("limitExhausted", {
                        bubbles: true,
                        composed: true,
                        detail: {
                            isLimitExhausted: true,
                            fromClick: false,
                            ask: true
                        },
                    });
                    document.dispatchEvent(limitExhaustEvent);
                    return null;
                }
                else {
                    console.error("Forbidden: " + responseBody.error);
                    this.onError('Forbidden error');
                    return null;
                }
            }
            else if (!response.ok) {
                console.error(`Error: ${response.status} ${response.statusText}`);
                this.onError(`Error: ${response.status} ${response.statusText}`);
                return null;
            }
        }
        catch (error) {
            this.onError('Network error: Unable to reach the server.');
            return null;
        }
    }
};
exports.AskPopover = AskPopover;
AskPopover.styles = (0, lit_1.css) `
  #askPopoverWrapper {
    position: absolute;
    max-width: 800px;
    z-index: 5;
  }
  @media(max-width: 1366px){
    #askPopoverWrapper{
      max-width: 600px;
    }
  }
  #askPopover {
    border-radius: 10px;
    pointer-events: all;
  }

  #stage-2-options {
    margin-top: 7px;
    border-radius: 10px;
    pointer-events: all;
    // border: 1.17px solid #EEEEEE;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.2);
    width: 200px;
  }
  // #askPopover.trinkaB2BEditor{
  //   box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  //   // height: calc(100% - 100px);
  // }
  #askPopoverHeader {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: #321F41;
    position: relative;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
  }
  #askPopoverHeader svg{
    margin-right: 10px;
  }
  #askPopoverHeader h3 {
    margin: 0;
    color: #FFFFFF;
    font-size: 18.78px;
    font-weight: 600;
    line-height: 28.17px;
    text-align: left;

  }
  #askPopoverBody {
    position: relative;
    // padding: 16px;
    max-width: 800px;
  }
  @media(max-width: 1366px){
    #askPopoverBody{
      max-width: 600px;
    }
  }
  #askPopoverBody h3 {
    margin-top: 0px;
  }
  #listOfActions{
    position: relative;
    padding: 10px;
  }
  #listOfActions ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  #listOfActions ul li {
    cursor: pointer;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 4px 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
    color: #111A27;
  }
  #listOfActions ul li:last-child{
    margin-bottom: 0px;
  }
  #listOfActions ul li:hover{
    background: rgba(122, 40, 160, 0.1);
    transition: all 0.5s ease;
  }
  #listOfActions ul li svg{
    margin-right: 4px;
  }
  #stage-1{
    position: relative;
    width: 300px;
    height: auto;
    margin-right: 6px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px 0px rgba(31, 41, 55, 0.2);
    border: 1px solid rgb(206, 213, 222);
    border-radius: 10px;
    height: 422px;
    overflow-y: hidden;
    /* overflow-y: auto; */
    scrollbar-color: #ccc #eee;
    scrollbar-width: thin;
  }
  #stage-1::-webkit-scrollbar-track{
	  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	  background-color: #F5F5F5;
    display: none;
  }

  #stage-1::-webkit-scrollbar{
	  width: 6px;
	  background-color: #F5F5F5;
    display: none;
  }

  #stage-1::-webkit-scrollbar-thumb{
	  background-color: #000000;
    display: none;
  }

  #stage-1 span.writeText{
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #71717A;
    // text-transform: uppercase;
  }
  #stage-1 ul {
    list-style-type: none;
    padding: 4px 0 0 0;
    margin: 0;
    position: relative;
  }
  #stage-1 ul li {
    cursor: pointer;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 2px 4px;
    border-radius: 4px;
    transition: all 0.5s ease;
    color: #111A27;
  }
  #stage-1 ul li:last-child{
    margin-bottom: 10px;
  }
  #stage-1 ul li:hover{
    background: rgba(122, 40, 160, 0.1);
    transition: all 0.5s ease;
    color: #7A28A0;
    cursor: pointer;
  }
  #stage-1 ul li .rightArrow{
    margin: 0;
    position: absolute;
    left: auto;
    right: 20px;
  }
  #stage-1 ul li:hover .rightArrow path{
    fill: #7A28A0;
  }
  #stage-1 ul li svg{
    margin-right: 8px;
  }
  #stage-1 ul li .subMenu{
    position: absolute;
    background: #FFFFFF;
    padding: 0;
    border-radius: 6px;
    left: auto;
    right: -210px;
    bottom: 0;
    top: auto;
    width: 180px;
    transition: all 0.5s ease;
    border: 1px solid #CED5DE;
    padding: 16px;
    display: none;
    z-index: 9;
    cursor: default;

  }
  #stage-1 ul li .subMenu ul li{
    align-items: center;
  }
  #stage-1 ul li .subMenu ul li:last-child{
    margin-bottom: 0px;
  }
  #stage-1 ul li:hover .subMenu{
    display: block;
    transition: all 0.5s ease;
  }
  #stage-1 ul li .subMenu ul li:hover{
    color: #7A28A0 !important;
  }
  #listOfActions ul li ul.subMenu li span{
    width: calc(100% - 36px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #stage-2 {
    display: none;
  }
  .spinner {
    background-color: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }
  .textLoader{
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .whatToWriteSection{
    position: relative;
    padding: 0 12px 12px;
  }
  .whatToWriteSection p{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #414E62;
    margin-bottom: 6px;
  }
  .whatToWriteSection input{
    background: #FFFFFF;
    padding: 7px 10px 7px 32px;
    border: 1px solid #D6D6D6;
    border-radius: 7.83px;
    width: 186px;
    transition: all 0.5s ease;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #414E62;
  }
  .whatToWriteSection input:focus{
    transition: all 0.5s ease;
    border: solid 1px #7A28A0;
    box-shadow: none;
    outline: none;
  }
  .whatToWriteSection input:placeholder{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #BAC3CD;
  }
  #stage-2 input{
    background: #FFFFFF;
    padding: 7px 10px 7px 32px;
    border: 1px solid #D6D6D6;
    border-radius: 7.83px;
    width: 100%;
    transition: all 0.5s ease;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #414E62;
  }
  #stage-2 input:focus{
    transition: all 0.5s ease;
    border: solid 1px #7A28A0;
    box-shadow: none;
    outline: none;
  }
  #stage-2 input:placeholder{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #BAC3CD;
  }
  .whatToWriteSection svg{
    position: absolute;
    left: 26px;
    top: 40px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .prcessedWtihText{
    position: relative;
    padding: 0 12px;
  }
  .prcessedWtihText p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 16px;
    color: #414E62;
    max-height: 197px;
    overflow-y: auto;
    padding-right: 10px;
    margin: 0 0 16px 0;
  }
  .prcessedWtihText p::-webkit-scrollbar{
    width: 4px;
  }
  .prcessedWtihText p::-webkit-scrollbar-track{
    background: transparent !important;
    border-radius: 5px;
  }
  .prcessedWtihText p::-webkit-scrollbar-thumb{
    background: rgba(122, 40, 160, 0.3);
  }
  .searchMoreText{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .searchMoreText .searchMoreInputBox{
    position: relative;
    width: 100%;
  }
  #stage-2 .searchMoreText .searchMoreInputBox input{
    width: calc(100% - 60px);
  }
  .searchMoreText .searchMoreInputBox svg{
    position: absolute;
    left: 12px;
    top: 11px;
  }
  .searchMoreText .searchMoreSubmitButton{
    padding: 0;
    background: #FFFFFF;
    border: 0;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    cursor: pointer;
    position: absolute;
    left: auto;
    right: 20px;
    margin: 0;
    top: 1px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
  .searchMoreText .searchMoreSubmitButton svg{
    position: relative;
    left: 0;
    top: 0;
  }
  .searchMoreText .searchMoreSubmitButton svg path{
    stroke: rgba(122, 40, 160, 1) !important;
  }
  .searchMoreText .searchMoreSubmitButton:focuse{
    box-shadow: none;
    outline: none;
  }
  .searchMoreText .searchMoreSubmitButton:disabled svg path{
      stroke: #9CA3AF !important;
      cursor: default;
  }
  .searchMoreText .searchMoreSubmitButton:hover svg path{
    stroke: rgba(122, 40, 160, 1);
  }
  .searchMoreText .searchMoreSubmitButton:disabled{
    cursor: default;
  }
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  .searchMoreText .paginationSection{
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .searchMoreText .paginationSection span{
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 0 6px;
  }
  .searchMoreText .paginationSection button{
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: relative;
    top: 2px;
  }
  .searchMoreText .paginationSection button:disabled svg path{
    fill: #9CA3AF;
  }
  #stage-2{
    background: #FFF;
    padding: 16px;
    border-radius: 8px;
    box-shadow: rgba(31, 41, 55, 0.1) 0px 4px 20px 0px !important;
    width: 600px;
  }
  .externalMenus{
    position: absolute;
    background: #FFF;
    top: auto;
    bottom: 16px;
    left: auto;
    right: 80px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px rgba(31, 41, 55, 0.2);
    width: 200px;
    height: fit-content;
    white-space: nowrap;
    background: #FFFFFF;
    color: white !important;
    border: 1px solid #CED5DE;
    padding: 8px;
  }
  .externalMenus .subMenu ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .externalMenus .subMenu ul li{
    cursor: pointer;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 2px 4px;
    border-radius: 4px;
    transition: all 0.5s ease;
    color: #111A27;
  }
  .externalMenus .subMenu ul li svg{
    margin-right: 4px;
  }
  .externalMenus .subMenu ul li:last-child{
    margin-bottom: 0px;
  }
  .externalMenus .subMenu ul li:hover{
    background-color: rgba(122, 40, 160, 0.1);
    color: #7A28A0 !important;
  }
  #externalMenusPopup.externalMenus .writeText{
    color: #71717A;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    display: 4px;
    margin-bottom: 4px;
  }
  .labsTag{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }
  .labsTag h5{
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #71717A;
    margin: 0 !important;
  }
  .labsTag span{
    position: relative;
    background-color: #F1305A;
    color: #FFFFFF;
    /* width: 39px; */
    /* height: 13px; */
    border-radius: 2px;
    font-size: 8px;
    font-weight: 700;
    line-height: 10.89px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 1px 4px;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", String)
], AskPopover.prototype, "selectedText", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AskPopover.prototype, "isOpen", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AskPopover.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskPopover.prototype, "processedResponses", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskPopover.prototype, "currentSelectedPrompt", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskPopover.prototype, "currentSelectedOption", void 0);
__decorate([
    (0, decorators_js_1.state)(),
    __metadata("design:type", Object)
], AskPopover.prototype, "tellTrinkaInput", void 0);
__decorate([
    (0, decorators_js_1.state)(),
    __metadata("design:type", Object)
], AskPopover.prototype, "currentPage", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskPopover.prototype, "activeModule", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], AskPopover.prototype, "externalMenusPopupPosition", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskPopover.prototype, "promptsConfig", void 0);
exports.AskPopover = AskPopover = __decorate([
    (0, decorators_js_1.customElement)('ask-trinka-popup'),
    __metadata("design:paramtypes", [Object, Object, Object])
], AskPopover);
