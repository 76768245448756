"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonFeedbackSnackbar = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const environment_1 = require("src/env/environment");
const utils_1 = require("src/helpers/utils");
let CommonFeedbackSnackbar = class CommonFeedbackSnackbar extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.message = 'An error occurred';
        this.currentLang = 'en';
    }
    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => this.remove(), 5000);
    }
    firstUpdated() {
        console.log('Component has mounted on the page!');
        this.shadowRoot.querySelector('.tsdk-common-feedback-snackbar').classList.add('moving-transition');
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    render() {
        return (0, lit_1.html) `<div class="tsdk-common-feedback-snackbar">
      <div class="message">${this.message}</div>
    </div>`;
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_1.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
};
exports.CommonFeedbackSnackbar = CommonFeedbackSnackbar;
CommonFeedbackSnackbar.styles = (0, lit_1.css) `
    .tsdk-common-feedback-snackbar {
      position: fixed;
      bottom: 30px;
      left: 0;
      right: 0;
      z-index: 1000;
      text-align: center;
      transition: 0.5s all ease;
      opacity: 0;
    }
    .moving-transition {
      opacity: 1;
      bottom: 60px;
      transition: 0.5s all ease;
    }

    .tsdk-common-feedback-snackbar .message {
      display: inline-block;
      padding: 4px 10px;
      border-radius: 5px;
      color: white;
      background: #414E62;
      font-size: 14px;
    }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], CommonFeedbackSnackbar.prototype, "message", void 0);
exports.CommonFeedbackSnackbar = CommonFeedbackSnackbar = __decorate([
    (0, decorators_js_1.customElement)('common-feedback-snackbar')
], CommonFeedbackSnackbar);
