"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsistencyUI = void 0;
const draggable_panel_1 = require("../components/draggable-panel-cc/draggable-panel");
const utils_1 = require("../helpers/utils");
const constants_1 = require("../helpers/constants");
require("../assets/styles.css");
const editor_overlay_1 = require("../components/editor-overlay");
const ask_1 = require("./ask");
class ConsistencyUI {
    constructor(consistencyModuleConfig, uiConfig) {
        this.listOfEditors = {};
        this.hostPageInfo = {
            supportedEditorsLibraries: constants_1.EDITORS_LIBRARIES_TO_SUPPORT,
            isCloudTrinka: true,
            isEditorLibraryPresent: false,
            isMultipleLibrariesPresent: false,
            detectedLibraries: []
        };
        this.boundHandleClickEventOnDocument = ($event) => this.handleClickEventOnDocument($event);
        this.boundHandleFilterChange = ($event) => this.handleFilterChange($event);
        this.isPasteEventStarted = false;
        this.resizeObserverCallback = (editorId) => {
            const editorInstance = this.listOfEditors[editorId];
            if (!editorInstance)
                return;
            editorInstance.components.editorOverlayInstance.handleResizeEvent();
        };
        this.consistencyModuleConfig = consistencyModuleConfig;
        this.uiConfig = uiConfig;
        document.addEventListener('draggable-panel-filter-update-cc', this.boundHandleFilterChange);
        document.addEventListener('click', this.boundHandleClickEventOnDocument);
    }
    destroy() {
        this.removeAllEditorUnderLines();
        this.removeAllEditorEventListeners();
        this.removeAllEditorComponents();
        this.cleanUpForModule();
    }
    initialize(editableElements) {
        editableElements.forEach(editor => {
            if (this.validateEditor(editor)) {
                const editorId = (0, utils_1.generateRandomId)(16);
                this.listOfEditors[editorId] = this.onBoardEditor(editorId, editor);
                const debouncedHandleInputEvent = (0, utils_1.debounce)(($event) => this.handleInputEvent($event, this.listOfEditors[editorId]), constants_1.EDITOR_CHANGE_DEBOUNCE_TIME);
                const debouncedHandleScrollEvent = ($event) => this.handleScrollEvent($event, this.listOfEditors[editorId]);
                const debounceHandlePasteEvent = ($event) => this.handlePasteEvent($event, this.listOfEditors[editorId]);
                const debouncedHandleKeydownEvent = (0, utils_1.debounce)(($event) => this.handleKeydownEvent($event, this.listOfEditors[editorId]), constants_1.EDITOR_KEYDOWN_EVENT_DEBOUNCE_TIME);
                const debounceHandleFocusEvent = ($event) => this._handleFocusEvent($event, this.listOfEditors[editorId]);
                const debounceHandleBlurEvent = ($event) => this._handleBlurEvent($event, this.listOfEditors[editorId]);
                const debounceHandleCKEditorInput = (0, utils_1.debounce)(() => this.handleCKEditorInput(this.listOfEditors[editorId]), constants_1.EDITOR_CHANGE_DEBOUNCE_TIME);
                if (!this.listOfEditors[editorId].editor.ckeditorInstance) {
                    this.listOfEditors[editorId].listOfEvents.push({
                        editorId,
                        element: this.listOfEditors[editorId].editor,
                        event: 'input',
                        listener: debouncedHandleInputEvent
                    });
                    this.listOfEditors[editorId].listOfEvents.push({
                        editorId,
                        element: this.listOfEditors[editorId].editor,
                        event: 'keydown',
                        listener: debouncedHandleKeydownEvent
                    });
                }
                else {
                    this.listOfEditors[editorId].editor.ckeditorInstance.model.document.on('change:data', (e, batch) => {
                        debounceHandleCKEditorInput();
                        if (this.isPasteEventStarted)
                            this.isPasteEventStarted = false;
                        else
                            this.handleCKEditorInputWithoutDebounce(this.listOfEditors[editorId], batch.isTyping || batch.isUndo);
                    });
                    this.listOfEditors[editorId].editor.ckeditorInstance.editing.view.document.on('clipboardInput', (evt, data) => {
                        this.isPasteEventStarted = true;
                        setTimeout(() => {
                            (0, utils_1.addEventToTracingLog)('paste');
                        }, 200);
                    });
                }
                this.listOfEditors[editorId].listOfEvents.push({
                    editorId,
                    element: this.listOfEditors[editorId].editor,
                    event: 'scroll',
                    listener: debouncedHandleScrollEvent
                });
                this.listOfEditors[editorId].listOfEvents.push({
                    editorId,
                    element: this.listOfEditors[editorId].editor,
                    event: 'paste',
                    listener: debounceHandlePasteEvent
                });
                this.listOfEditors[editorId].listOfEvents.push({
                    editorId,
                    element: this.listOfEditors[editorId].editor,
                    event: 'blur',
                    listener: debounceHandleBlurEvent
                });
                this.listOfEditors[editorId].listOfEvents.push({
                    editorId,
                    element: this.listOfEditors[editorId].editor,
                    event: 'focus',
                    listener: debounceHandleFocusEvent
                });
                this.registerEditorEventListeners(this.listOfEditors[editorId]);
            }
        });
    }
    handleFilterChange($event) {
        if (!$event.detail.editorId)
            return;
        const editorInstance = this.listOfEditors[$event.detail.editorId];
        editorInstance.components.draggablePanel.hea;
        this.applyFilter(this.listOfEditors[$event.detail.editorId]);
    }
    applyFilter(editorInstance) {
        if (!editorInstance)
            return;
        const currentSelection = (0, utils_1.getCcSelectedFilters)(editorInstance.components.draggablePanel.filters);
        editorInstance.components.draggablePanel.filteredAlertList = editorInstance.components.draggablePanel.alertList
            .filter(alert => {
            return currentSelection.includes(alert.module);
        });
        if (this.consistencyModuleConfig
            && this.consistencyModuleConfig.alertCountChangeEvent
            && typeof this.consistencyModuleConfig.alertCountChangeEvent === 'function') {
            this.consistencyModuleConfig.alertCountChangeEvent(editorInstance.editorId, this.getEditorAlertCount(editorInstance));
        }
        this.updateEditorUnderlinesVisibility(editorInstance);
    }
    getEditorAlertCount(editorInstance) {
        const filterList = JSON.parse(JSON.stringify(constants_1.CCFilterCheckList));
        let alertCount = {};
        filterList.forEach((filter, index) => {
            alertCount[index] = editorInstance.components.draggablePanel.filteredAlertList
                .filter(alert => {
                return filterList[index].name === alert.module;
            }).length;
        });
        alertCount['total'] = Object.values(alertCount)
            .reduce((partialSum = 0, currentValue = 0) => partialSum + currentValue, 0);
        return alertCount;
    }
    updateEditorUnderlinesVisibility(editorInstance) {
        let showAlertsList = Array.from(editorInstance.underlines.keys()).filter(key => editorInstance.components.draggablePanel.filteredAlertList.some(item => item.alertId === key));
        let hideAlertsList = Array.from(editorInstance.underlines.keys()).filter(key => !editorInstance.components.draggablePanel.filteredAlertList.some(item => item.alertId === key));
        showAlertsList.forEach((underlineId) => {
            editorInstance.underlines.get(underlineId).setVisibility(true);
        });
        hideAlertsList.forEach((underlineId) => {
            editorInstance.underlines.get(underlineId).setVisibility(false);
        });
    }
    triggerEditorInputEventManually(editorInstance) {
        const event = new CustomEvent('tsdk-cc-editor-input-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: editorInstance.editorId
            }
        });
        document.dispatchEvent(event);
    }
    handleInputEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        this.triggerEditorInputEventManually(editorInstance);
    }
    handleScrollEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        editorInstance.components.editorOverlayInstance.handleResizeEvent();
    }
    handlePasteEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        this.triggerEditorInputEventManually(editorInstance);
        editorInstance.components.editorOverlayInstance.handleResizeEvent();
    }
    handleKeydownEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        const keyboardEvent = $event;
        const key = keyboardEvent.key.toLowerCase();
        if (keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
            switch (keyboardEvent.key.toLowerCase()) {
                case 'b':
                case 'i':
                case 'u':
                case 'z': {
                    this.triggerEditorInputEventManually(editorInstance);
                    break;
                }
            }
        }
        if (key === 'enter' || key === 'backspace' || key === 'delete') {
            this.triggerEditorInputEventManually(editorInstance);
        }
    }
    async scanHostPage() {
        this.hostPageInfo.isCloudTrinka = true;
        Object.keys(this.hostPageInfo.supportedEditorsLibraries).forEach(editor => {
            this.hostPageInfo.supportedEditorsLibraries[editor].detect();
        });
        if (!Object.values(this.hostPageInfo.supportedEditorsLibraries).some(library => library.detected)) {
            this.hostPageInfo.isEditorLibraryPresent = false;
        }
        else {
            this.hostPageInfo.isEditorLibraryPresent = true;
            this.hostPageInfo.detectedLibraries = Object.values(this.hostPageInfo.supportedEditorsLibraries)
                .filter(library => library.detected)
                .map(library => library.editorLibraryName);
            this.hostPageInfo.isMultipleLibrariesPresent = this.hostPageInfo.detectedLibraries.length > 1;
        }
    }
    onBoardEditor(editorId, editor) {
        const isCloudTrinka = true;
        editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorId);
        editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
        const draggablePanel = new draggable_panel_1.DraggablePanel(editorId);
        draggablePanel.langCode = this.consistencyModuleConfig.langCode;
        const editorOverlayInstance = new editor_overlay_1.EditorOverlay(editorId, editor, isCloudTrinka);
        document.body.appendChild(draggablePanel);
        let askPlugin = null;
        if (this.consistencyModuleConfig.askEnabled && this.consistencyModuleConfig.token) {
            askPlugin = new ask_1.Ask(editor, editorId, { token: this.consistencyModuleConfig.token, userPlan: this.consistencyModuleConfig.userPlan }, this.hostPageInfo, constants_1.SDK_MODULES.CONSISTENCY);
        }
        const editorInstance = {
            editorId,
            editor,
            editorType: editor.nodeName.toLowerCase(),
            editorDOMTreeNodes: {},
            editorDOMTreeNodeCounter: Object.keys(this.listOfEditors).length * 10000,
            listOfEvents: [],
            segmentToSentencesMap: new Map(),
            sentenceToAlertsMap: new Map(),
            underlines: new Map(),
            components: {
                draggablePanel,
                editorOverlayInstance,
            },
            askPlugin,
            resizeObserver: new ResizeObserver((0, utils_1.debounce)(() => this.resizeObserverCallback(editorId), constants_1.EDITOR_RESIZE_DEBOUNCE_TIME))
        };
        editorInstance.resizeObserver.observe(editor);
        return editorInstance;
    }
    validateEditor(element) {
        if (element.getAttribute(constants_1.EDITOR_ATTRIBUTES.DATA_TRINKA) === 'false') {
            return false;
        }
        else if (element.hasAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID)) {
            return false;
        }
        return true;
    }
    processEditorText(editorId, editor) {
    }
    registerEditorEventListeners(editorInstance) {
        editorInstance.listOfEvents.forEach(({ element, event, listener }) => {
            element.addEventListener(event, listener);
        });
    }
    showLoader(editorInstance) {
        editorInstance.components.draggablePanel.loader = true;
    }
    hideLoader(editorInstance) {
        editorInstance.components.draggablePanel.loader = false;
    }
    cleanUpForModule() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            const editorInstance = this.listOfEditors[editorId];
            this.cleanUpForEditor(editorInstance);
        });
        document.removeEventListener('draggable-panel-filter-update-cc', this.boundHandleFilterChange);
        document.addEventListener('click', this.boundHandleClickEventOnDocument);
    }
    cleanUpForEditor(editorInstance) {
        var _a;
        const editorId = editorInstance.editorId;
        editorInstance.editor.removeAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID);
        editorInstance.resizeObserver.disconnect();
        (_a = editorInstance === null || editorInstance === void 0 ? void 0 : editorInstance.askPlugin) === null || _a === void 0 ? void 0 : _a.destroy();
        delete this.listOfEditors[editorId];
    }
    removeAllEditorComponents() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.removeEditorComponents(this.listOfEditors[editorId]);
        });
    }
    removeEditorComponents(editorInstance) {
        Object.keys(editorInstance.components).forEach((componentName) => {
            editorInstance.components[componentName].remove();
        });
    }
    removeAllEditorUnderLines() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.removeEditorUnderLines(this.listOfEditors[editorId]);
        });
    }
    removeEditorUnderLines(editorInstance) {
        for (let underlineId of editorInstance.underlines.keys()) {
            this.removeUnderLineById(editorInstance, underlineId);
        }
    }
    removeUnderLineById(editorInstance, underlineId) {
        if (!editorInstance.underlines.has(underlineId))
            return;
        const underlineInstance = editorInstance.underlines.get(underlineId);
        underlineInstance.remove();
        editorInstance.underlines.delete(underlineId);
    }
    removeAllEditorEventListeners() {
        Object.keys(this.listOfEditors).forEach((editorId) => {
            this.removeEditorEventListeners(this.listOfEditors[editorId]);
        });
    }
    removeEditorEventListeners(editorInstance) {
        editorInstance.listOfEvents.forEach(({ element, event, listener }) => {
            element.removeEventListener(event, listener);
        });
    }
    handleClickEventOnDocument($event) {
        var _a, _b, _c, _d, _e;
        if ($event.target.closest("trinka-popup-cc")) {
            return;
        }
        else {
            const filter = (_d = (_c = (_b = (_a = document.querySelector('trinka-popup-cc')) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('draggable-panel-header-cc')) === null || _c === void 0 ? void 0 : _c.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('.filter');
            (_e = filter === null || filter === void 0 ? void 0 : filter.classList) === null || _e === void 0 ? void 0 : _e.remove('active');
        }
    }
    _updateCKEditor5Attributes(editorInstance) {
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.TRINKA_EDITOR_ID, editorInstance.editorId);
        editorInstance.editor.setAttribute(constants_1.EDITOR_ATTRIBUTES.SPELLCHECK, 'false');
    }
    _handleFocusEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        const spellCheckInterval = setInterval(() => {
            this._updateCKEditor5Attributes(editorInstance);
        }, 100);
        setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
    }
    _handleBlurEvent($event, editorInstance) {
        if (!editorInstance)
            return;
        const spellCheckInterval = setInterval(() => {
            this._updateCKEditor5Attributes(editorInstance);
        }, 100);
        setTimeout(() => { clearInterval(spellCheckInterval); }, 2000);
    }
    handleCKEditorInput(editorInstance) {
        if (!editorInstance)
            return;
        this.triggerEditorInputEventManually(editorInstance);
    }
    handleCKEditorInputWithoutDebounce(editorInstance, isTypingFlag = false) {
        if (!editorInstance)
            return;
        if (isTypingFlag)
            (0, utils_1.addEventToTracingLog)('ckEditorInput');
    }
}
exports.ConsistencyUI = ConsistencyUI;
