"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskInlinePopover = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const constants_1 = require("../../helpers/constants");
const utils_1 = require("src/helpers/utils");
const environment_1 = require("src/env/environment");
const utils_2 = require("src/helpers/utils");
let AskInlinePopover = class AskInlinePopover extends lit_1.LitElement {
    updated(changedProperties) { }
    renderPopoverOptions(options) {
        return (0, lit_1.html) `
      <ul class="subMenu ideasToStartWithSubMenu" style="width:210px;right:-217px; top: 68px">
        ${Object.keys(options).filter(key => key !== 'keep_writing').map((key) => {
            const option = options[key];
            return (0, lit_1.html) `
            <li @click=${() => this.onOptionSelection(option)}>
              <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_809_1292" fill="white">
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                    </mask>
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                    <mask id="path-2-inside-2_809_1292" fill="white">
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                    </mask>
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                </svg>
              ${option.title}
            </li>
          `;
        })}
      </ul>
    `;
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return (0, lit_1.html) `
    <div>
    <div id="askCommandWithLoader" style="display: ${this.isOpen ? 'block' : 'none'}; position: absolute;">
      ${this.loader
            ? (0, lit_1.html) `
            <div class="loader" style="left: ${((_a = this.currentPosition) === null || _a === void 0 ? void 0 : _a.left) - 20}px; top: -${((_b = this.currentPosition) === null || _b === void 0 ? void 0 : _b.height) - 7}px">
              <div></div>
              <div></div>
              <div></div>
            </div>
          `
            : (0, lit_1.html) `
            <div class="command" style="left: ${((_c = this.currentPosition) === null || _c === void 0 ? void 0 : _c.left) - 20}px; top: ${-(((_d = this.currentPosition) === null || _d === void 0 ? void 0 : _d.height) - 7) - 9}px">
              <span>/ask</span>
            </div>
      `}
    </div>

    <div id="askPopoverWrapper" style="display: ${this.isOpen ? 'block' : 'none'}">
      ${this.loader ?
            (0, lit_1.html) `
          <!-- <div class="loader" style="left: ${((_e = this.currentPosition) === null || _e === void 0 ? void 0 : _e.left) - 20}px; top: -${((_f = this.currentPosition) === null || _f === void 0 ? void 0 : _f.height) - 7}px">
            <div></div>
            <div></div>
            <div></div>
          </div> -->
        ` :
            (0, lit_1.html) `
          <!-- <div class="command" style="left: ${((_g = this.currentPosition) === null || _g === void 0 ? void 0 : _g.left) - 20}px; top: ${-(((_h = this.currentPosition) === null || _h === void 0 ? void 0 : _h.height) - 7) - 9}px">
            <span>/ask</span>
          </div> -->
          <div id="askPopover" class="${this.isCloudTrinka ? 'trinkaCloudEditor' : 'trinkaB2BEditor'}">
            <input id="ask-inline-input"
              type="text"
              placeholder="Ask Trinka to generate text"
              .value="${this.inputValue}"
              @input="${this.onInput}"
              @keypress="${this.handleKeyPress}"
              autocomplete="off"
            />
            <button id="aiAskButton"
              @click="${this.submit}"
              ?disabled="${!this.inputValue || this.inputValue.trim() === ''}"
              >
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.74742 3.13775L17.5058 9.27275C18.6091 9.76442 18.6091 11.2353 17.5058 11.7278L3.74742 17.8628C2.50158 18.4186 1.20825 17.1844 1.82575 16.0278L4.45242 11.1078C4.65658 10.7253 4.65658 10.2744 4.45242 9.89275L1.82575 4.97275C1.20908 3.81608 2.50075 2.58108 3.74742 3.13775Z" stroke="#9CA3AF" stroke-width="1.25"/>
                <path d="M4.5835 10.499H17.9168" stroke="#9CA3AF" stroke-width="1.25"/>
              </svg>
            </button>
          </div>

          ${!this.inputValue ? (0, lit_1.html) `
            <div id="listOfActions" class="textBoxAskOption askWithCommand">
              <div class="labsTag">
                <h5>Write</h5>
                <span data-no-translate>
                  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34114 8C7.31864 5.4465 6.12514 4.7735 6.12514 3.5V2H6.37514C6.44144 2 6.50503 1.97366 6.55191 1.92678C6.5988 1.87989 6.62514 1.8163 6.62514 1.75V1.25C6.62514 1.1837 6.5988 1.12011 6.55191 1.07322C6.50503 1.02634 6.44144 1 6.37514 1H3.87514C3.80883 1 3.74524 1.02634 3.69836 1.07322C3.65148 1.12011 3.62514 1.1837 3.62514 1.25V1.75C3.62514 1.8163 3.65148 1.87989 3.69836 1.92678C3.74524 1.97366 3.80883 2 3.87514 2H4.12514V3.5C4.12514 4.7735 2.93164 5.4465 1.90914 8C1.58364 8.8125 0.747638 10 5.12514 10C9.50264 10 8.66664 8.8125 8.34114 8ZM7.53264 8.8755C7.18164 8.9805 6.48314 9.1 5.12514 9.1C3.76714 9.1 3.06864 8.9805 2.71764 8.8755C2.59314 8.8385 2.54464 8.694 2.58864 8.5615C2.69864 8.2265 2.90614 7.6475 3.29414 7.001C4.24214 5.4215 5.22564 7.2495 6.04414 7.2495C6.86264 7.2495 6.63814 6.469 6.95614 7.001C7.2486 7.49332 7.48523 8.01672 7.66164 8.5615C7.70564 8.694 7.65714 8.838 7.53264 8.8755Z" fill="white"/>
                  </svg>
                  Labs
                </span>
              </div>
              <ul>
                <li @click=${() => this.onOptionSelection(this.options.write.keep_writing)}>
                  <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_809_1292" fill="white">
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                    </mask>
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                    <mask id="path-2-inside-2_809_1292" fill="white">
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                    </mask>
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                  </svg>
                  ${this.options.write.keep_writing.title}
                </li>
                <li>
                  <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_809_1292" fill="white">
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                    </mask>
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                    <mask id="path-2-inside-2_809_1292" fill="white">
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                    </mask>
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                  </svg>
                  Ideas to start with
                  <svg class="rightArrow" width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.71191 5.32031L0.961913 9.07031C0.856241 9.17598 0.712919 9.23535 0.563476 9.23535C0.414033 9.23535 0.27071 9.17598 0.165038 9.07031C0.0593659 8.96464 1.11344e-09 8.82132 0 8.67187C-1.11344e-09 8.52243 0.0593659 8.37911 0.165038 8.27344L3.51707 4.92234L0.165976 1.57031C0.113652 1.51799 0.0721467 1.45587 0.0438294 1.38751C0.0155121 1.31914 0.000937343 1.24587 0.000937343 1.17187C0.000937342 1.09788 0.0155121 1.02461 0.0438294 0.956241C0.0721467 0.887877 0.113652 0.82576 0.165976 0.773437C0.218299 0.721113 0.280416 0.679608 0.34878 0.651291C0.417145 0.622973 0.490417 0.608398 0.564413 0.608398C0.63841 0.608398 0.711682 0.622973 0.780046 0.651291C0.84841 0.679608 0.910527 0.721113 0.962851 0.773437L4.71285 4.52344C4.76523 4.57576 4.80676 4.6379 4.83507 4.70631C4.86338 4.77471 4.87791 4.84804 4.87783 4.92207C4.87774 4.9961 4.86304 5.06939 4.83457 5.13773C4.8061 5.20607 4.76441 5.26812 4.71191 5.32031Z" fill="#414E62"/>
                  </svg>
                  ${this.renderPopoverOptions(this.options.write)}
                </li>
                <li>
                  <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_809_1292" fill="white">
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                    </mask>
                    <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                    <mask id="path-2-inside-2_809_1292" fill="white">
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                    </mask>
                    <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                  </svg>
                  Recent Prompt
                  <svg class="rightArrow" width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.71191 5.32031L0.961913 9.07031C0.856241 9.17598 0.712919 9.23535 0.563476 9.23535C0.414033 9.23535 0.27071 9.17598 0.165038 9.07031C0.0593659 8.96464 1.11344e-09 8.82132 0 8.67187C-1.11344e-09 8.52243 0.0593659 8.37911 0.165038 8.27344L3.51707 4.92234L0.165976 1.57031C0.113652 1.51799 0.0721467 1.45587 0.0438294 1.38751C0.0155121 1.31914 0.000937343 1.24587 0.000937343 1.17187C0.000937342 1.09788 0.0155121 1.02461 0.0438294 0.956241C0.0721467 0.887877 0.113652 0.82576 0.165976 0.773437C0.218299 0.721113 0.280416 0.679608 0.34878 0.651291C0.417145 0.622973 0.490417 0.608398 0.564413 0.608398C0.63841 0.608398 0.711682 0.622973 0.780046 0.651291C0.84841 0.679608 0.910527 0.721113 0.962851 0.773437L4.71285 4.52344C4.76523 4.57576 4.80676 4.6379 4.83507 4.70631C4.86338 4.77471 4.87791 4.84804 4.87783 4.92207C4.87774 4.9961 4.86304 5.06939 4.83457 5.13773C4.8061 5.20607 4.76441 5.26812 4.71191 5.32031Z" fill="#414E62"/>
                  </svg>
                  ${this.recentAction.length ? (0, lit_1.html) `
                    <ul class="subMenu" style="width:210px; right:-217px; top: 100px;" data-no-translate>
                      ${this.recentAction.sort((a, b) => {
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();
                return dateB - dateA;
            }).map((action) => {
                return (0, lit_1.html) `
                        <li @click=${() => this.onRecentPrompSelection(action)}>
                          <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-inside-1_809_1292" fill="white">
                            <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z"/>
                            </mask>
                            <path d="M8.38438 2.8457L8.76774 6.0767C9.00229 8.05355 10.5609 9.61216 12.5377 9.84672L15.7688 10.2301L12.5377 10.6134C10.5609 10.848 9.00229 12.4066 8.76774 14.3835L8.38438 17.6145L8.00101 14.3835C7.76646 12.4066 6.20784 10.848 4.231 10.6134L1 10.2301L4.231 9.84672C6.20784 9.61216 7.76646 8.05355 8.00101 6.0767L8.38438 2.8457Z" stroke="#7A28A0" stroke-width="2.02099" mask="url(#path-1-inside-1_809_1292)"/>
                            <mask id="path-2-inside-2_809_1292" fill="white">
                            <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z"/>
                            </mask>
                            <path d="M13.3515 1L13.5409 2.59637C13.6568 3.57309 14.4268 4.34317 15.4036 4.45906L16.9999 4.64847L15.4036 4.83788C14.4268 4.95377 13.6568 5.72385 13.5409 6.70056L13.3515 8.29694L13.1621 6.70056C13.0462 5.72385 12.2761 4.95377 11.2994 4.83788L9.703 4.64847L11.2994 4.45906C12.2761 4.34317 13.0462 3.57309 13.1621 2.59637L13.3515 1Z" stroke="#7A28A0" stroke-width="1.49779" mask="url(#path-2-inside-2_809_1292)"/>
                          </svg>
                          <span>${action.text}</span>
                          <i class="recentPromptTooltip">${action.text}</i>
                        </li>
                      `;
            })}
                    </ul>
                  ` : (0, lit_1.html) ``}
                </li>
              </ul>
            </div>
          ` : (0, lit_1.html) ``}

          <!-- ${!this.inputValue ? (0, lit_1.html) `
            <div id="listOfActions" class="textBoxAskOption">
              <h5>Write</h5>
              ${this.renderPopoverOptions(this.options.write)}
            </div>
          ` : (0, lit_1.html) ``} -->
        `}
    </div>
    </div>
    `;
    }
    constructor(editorId, config, isCloudTrinka) {
        super();
        this.inputValue = '';
        this.isOpen = false;
        this.loader = false;
        this.currentSelectedOption = 0;
        this.activeModule = constants_1.SDK_MODULES.GRAMMAR;
        this.promptsConfig = {
            userId: 'abc',
            userInstructions: '-',
            model: 'LLAMA 3.1',
            authorizationHeader: '',
            prompts: {
                rephrase: {
                    adminPrompt: 'Rephrase the text',
                    description: 'This prompt will rephrase the text'
                },
                simplify: {
                    adminPrompt: 'Simplify the text',
                    description: 'This prompt will simplify the text'
                },
                polish: {
                    adminPrompt: 'Polish the text',
                    description: 'This prompt will polish the text'
                },
                shorten: {
                    adminPrompt: 'Shorten the text',
                    description: 'This prompt will shorten the text'
                },
                enrich: {
                    adminPrompt: 'Enrich the text',
                    description: 'This prompt will enrich the text'
                },
            }
        };
        this.options = constants_1.ASK_INLINE_POPOVER_OPTIONS;
        this.currentPosition = null;
        this.context = [];
        this.recentAction = [];
        this.config = {};
        this.currentLang = 'en';
        this.editorId = editorId;
        this.config = config;
        this.isCloudTrinka = isCloudTrinka;
        const storedRecentActions = localStorage.getItem('recentActions');
        if (storedRecentActions) {
            try {
                this.recentAction = JSON.parse(storedRecentActions);
            }
            catch (error) {
                localStorage.removeItem('recentActions');
                this.recentAction = [];
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    preventScroll($event) {
        $event.preventDefault();
    }
    firstUpdated() {
        this.askPopoverWrapper = this.shadowRoot.querySelector('#askPopoverWrapper');
        this._injectScript(environment_1.environment.TRANSLATION_SDK);
    }
    _injectScript(src) {
        var _a;
        const script = (0, utils_2.injectScript)(src, () => {
            const savedLang = localStorage.getItem('preferredLanguage');
            if (savedLang) {
                this.currentLang = savedLang;
            }
            this.translationService = new TranslationSDK({
                observerDelay: 5000,
                mutationObserverEnabled: true,
                lang: this.currentLang,
                rootElement: this.shadowRoot.firstElementChild
            });
        });
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(script);
    }
    onInput(event) {
        this.inputValue = event.target.value;
    }
    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.submit();
        }
    }
    updateRecentActions(appId, text) {
        this.recentAction.push({ appId, text, date: new Date().toISOString() });
        let temp = this.recentAction.sort((a, b) => {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();
            return dateB - dateA;
        });
        if (this.recentAction.length > 5) {
            temp.pop();
        }
        localStorage.setItem('recentActions', JSON.stringify(temp));
    }
    onRecentPrompSelection(selectedOption) {
        this.inputValue = `${selectedOption.text} `;
        this.requestUpdate();
        (0, utils_1.triggerAmplitudeEvent)('ask_input_recent_prompt', {
            input_text: selectedOption.text,
            appId: selectedOption.appId,
            user_plan: this.config.userPlan,
            right_panel: this.activeModule
        });
        const input = this.shadowRoot.querySelector('#ask-inline-input');
        if (input) {
            input.focus();
        }
    }
    async onOptionSelection(selectedOption) {
        this.currentSelectedOption = selectedOption.appId;
        this.inputValue = `${selectedOption.userPrompt} `;
        this.requestUpdate();
        const input = this.shadowRoot.querySelector('#ask-inline-input');
        if (input) {
            input.focus();
        }
        if (selectedOption.appId === 285) {
            this.loader = true;
            const body = {
                "inputs": [
                    {
                        "name": "",
                        "type": "text",
                        "placeholder": "",
                        "hint": "",
                        "ai": {
                            "enabled": false
                        },
                        "value": this.context.join(',')
                    }
                ],
                "model": "LLAMA 3.1",
                "app": this.currentSelectedOption,
                "raw": true
            };
            (0, utils_1.triggerAmplitudeEvent)('ask_input', {
                usecase: 'Keep writing',
                user_plan: this.config.userPlan,
                right_panel: this.activeModule
            });
            const response = await this.makeApiCall(body);
            this.loader = false;
            if (!response) {
                if (selectedOption.appId === 285) {
                    this.inputValue = '';
                }
                return;
            }
            this.inputValue = '';
            this.dispatchEvent(new CustomEvent('tsdk-on-ask-inline-response-event', {
                bubbles: true,
                composed: true,
                detail: {
                    editorId: this.editorId,
                    text: response
                }
            }));
            this.dispatchEvent(new CustomEvent('tsdk-ask-inline-on-submit-event', {
                bubbles: true,
                composed: true,
                detail: {
                    editorId: this.editorId,
                    text: response
                }
            }));
        }
        else {
            (0, utils_1.triggerAmplitudeEvent)('ask_input_start_idea', {
                title: selectedOption.title,
                appId: selectedOption.appId,
                user_plan: this.config.userPlan,
                right_panel: this.activeModule
            });
        }
    }
    async submit() {
        if (!this.inputValue)
            return;
        this.loader = true;
        const body = {
            "inputs": [
                {
                    "name": "",
                    "type": "text",
                    "placeholder": "",
                    "hint": "",
                    "ai": {
                        "enabled": false
                    },
                    "value": this.inputValue
                }
            ],
            "model": "LLAMA 3.1",
            "app": this.currentSelectedOption === 0 ? 295 : this.currentSelectedOption,
            "raw": true
        };
        this.updateRecentActions(body.app, this.inputValue);
        (0, utils_1.triggerAmplitudeEvent)('ask_input', {
            usecase: 'Text input',
            appId: this.currentSelectedOption === 0 ? 295 : this.currentSelectedOption,
            user_plan: this.config.userPlan,
            right_panel: this.activeModule
        });
        const response = await this.makeApiCall(body);
        this.loader = false;
        if (!response)
            return;
        this.inputValue = '';
        this.dispatchEvent(new CustomEvent('tsdk-on-ask-inline-response-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                text: response
            }
        }));
        this.dispatchEvent(new CustomEvent('tsdk-ask-inline-on-submit-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                text: response
            }
        }));
    }
    close($event) {
        this.inputValue = '';
        this.currentSelectedOption = 0;
        this.isOpen = false;
    }
    waitTillAskpopoverLoaded() {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.shadowRoot.querySelector('#askPopoverWrapper')) {
                    clearInterval(interval);
                    this.askPopoverWrapper = this.shadowRoot.querySelector('#askPopoverWrapper');
                    resolve(true);
                }
            }, 500);
        });
    }
    async open(activeModule = constants_1.SDK_MODULES.GRAMMAR, position = null, context = []) {
        this.activeModule = activeModule;
        return new Promise(async (resolve, reject) => {
            await this.waitTillAskpopoverLoaded();
            this.currentPosition = position;
            this.context = context;
            if (this.askPopoverWrapper && position) {
                setTimeout(() => {
                    this.discardAction();
                    const askCommandWithLoader = this.shadowRoot.querySelector('#askCommandWithLoader');
                    if (askCommandWithLoader) {
                        askCommandWithLoader.style.top = `${position.top}px`;
                        askCommandWithLoader.style.left = `${0}px`;
                    }
                    this.askPopoverWrapper.style.top = `${position.top}px`;
                    this.askPopoverWrapper.style.left = `${0}px`;
                    const input = this.shadowRoot.querySelector('#ask-inline-input');
                    if (input) {
                        input.focus();
                    }
                    resolve(true);
                }, 0);
                this.isOpen = true;
            }
        });
    }
    discardAction() { }
    onError(message) {
        this.dispatchEvent(new CustomEvent('tsdk-ask-popover-on-error-event', {
            bubbles: true,
            composed: true,
            detail: {
                editorId: this.editorId,
                message
            }
        }));
    }
    async makeApiCall(body) {
        const url = environment_1.environment.ASK_URL;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${this.config.token}`,
                    'user-plan': `${this.config.userPlan}`,
                    'source': 'ASK-JS'
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const result = await response.json();
                return result && result.content ? result.content : '';
            }
            else if (response.status === 403) {
                const responseBody = await response.json();
                if (responseBody.error.toLowerCase() === "usage limit exceeded") {
                    console.error("Usage limit exceeded. Please try again later.");
                    const limitExhaustEvent = new CustomEvent("limitExhausted", {
                        bubbles: true,
                        composed: true,
                        detail: {
                            isLimitExhausted: true,
                            fromClick: false,
                            ask: true
                        },
                    });
                    document.dispatchEvent(limitExhaustEvent);
                    return null;
                }
                else {
                    console.error("Forbidden: " + responseBody.error);
                    this.onError('Forbidden error');
                    return null;
                }
            }
            else if (!response.ok) {
                console.error(`Error: ${response.status} ${response.statusText}`);
                this.onError(`Error: ${response.status} ${response.statusText}`);
                return null;
            }
        }
        catch (error) {
            this.onError('Network error: Unable to reach the server.');
            return null;
        }
    }
};
exports.AskInlinePopover = AskInlinePopover;
AskInlinePopover.styles = (0, lit_1.css) `
  #askPopoverWrapper {
    position: absolute;
    // max-width: 600px;
    // width: calc(100% - 10px);
    right: 0;
    // margin: auto;
    width: 64%;
    z-index: 5;
    margin: auto;
  }

  #askPopover {
    width: 100%;
    pointer-events: all;
    margin-top: 15px;
  }
  #askPopover #ask-inline-input{
    border: 1px solid rgba(122, 40, 160, 0.5);
    // box-shadow: 0px 4px 10px 0px rgba(122, 40, 160, 0.2);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(17, 26, 39, 1);
    height: 47px;
    width: calc(100% - 83px);
    padding: 0 50px 0 20px;
  }
  #aiAskButton{
    position: absolute;
    left: auto;
    right: 20px;
    background: transparent;
    border: none;
    top: 29px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  #aiAskButton:hover svg path,
  #aiAskButton svg path{
    stroke: rgba(122, 40, 160, 1);
  }
  #aiAskButton:disabled{
    cursor: default;
    transition: all 0.5s ease;
  }
  #aiAskButton:disabled:hover svg path{
      stroke: #9CA3AF;
  }
  #aiAskButton:disabled svg path{
      stroke: #9CA3AF;
  }
  #askPopover #ask-inline-input:focus{
    // box-shadow: 0px 4px 10px 0px rgba(122, 40, 160, 0.2);
    outline: none;
    border: 1px solid rgba(122, 40, 160, 1);
  }
  #askPopover #ask-inline-input:placeholder{
    color: rgba(156, 163, 175, 1);
  }
  #stage-2-options {
    margin-top: 7px;
    border-radius: 10px;
    pointer-events: all;
    border: 1.17px solid #EEEEEE;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.2);
  }
  // #askPopover.trinkaB2BEditor{
  //   box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
  //   // height: calc(100% - 100px);
  // }
  #askPopoverHeader {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: #321F41;
    position: relative;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
  }
  #askPopoverHeader svg{
    margin-right: 10px;
  }
  #askPopoverHeader h3 {
    margin: 0;
    color: #FFFFFF;
    font-size: 18.78px;
    font-weight: 600;
    line-height: 28.17px;
    text-align: left;

  }
  #askPopoverBody {
    position: relative;
    padding: 0 10px;
  }
  #askPopoverBody h3 {
    margin-top: 0px;
  }
  #listOfActions{
    position: relative;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(31, 41, 55, 0.2);
    max-width: fit-content;
    margin-top: 7px;
    cursor: pointer;
    z-index: 999;
    pointer-events: auto;
  }
  #listOfActions ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  #listOfActions ul li {
    cursor: pointer;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 2px 4px;
    border-radius: 4px;
    transition: all 0.5s ease;
    color: #111A27;
  }
  #listOfActions ul li:last-child{
    margin-bottom: 0px;
  }
  #listOfActions ul li svg{
    margin-right: 8px
  }
  #listOfActions ul li .rightArrow{
    margin: 0;
    position: absolute;
    left: auto;
    right: 20px;
  }
  #listOfActions ul li:hover .rightArrow path{
    fill: #7A28A0;
  }
  #listOfActions ul li:hover{
    background: rgba(122, 40, 160, 0.1);
    transition: all 0.5s ease;
    color: #7A28A0;
    cursor: pointer;
  }
  #listOfActions ul li ul.subMenu li{
    position: relative;
  }
  #listOfActions ul li:hover ul.subMenu li{
      align-items: center;
  }
  #listOfActions ul li:hover ul.subMenu li span{
    width: calc(100% - 36px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #listOfActions ul li ul.subMenu li .recentPromptTooltip{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background: #414E62;
    width: fit-content;
    padding: 2px 4px;
    padding-top: 2px;
    padding-right: 4px;
    padding-bottom: 2px;
    padding-left: 4px;
    border-radius: 4px;
    font-size: 12px;
    color: #FFF;
    font-style: normal;
    display: none;
    top: auto;
    bottom: 30px;
    z-index: 9;
    max-width: 180px;
  }
  #listOfActions ul li ul.subMenu li .recentPromptTooltip::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #414E62;
    border-width: 4px;
    margin-left: -4px;
    transform: rotate(180deg);
  }
  #listOfActions ul li:hover ul.subMenu li:hover .recentPromptTooltip{
    display: block;
  }
  #listOfActions ul li svg{
    margin-right: 4px;
  }
  #listOfActions.textBoxAskOption{
    box-shadow: 0px 4px 10px 0px rgba(122, 40, 160, 0.1);
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(206, 213, 222, 1);
    border-radius: 8px;
  }
  #listOfActions.textBoxAskOption h5{
    margin: 0 0 4px 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(153, 162, 178, 1);
    text-transform: capitalize;
  }
  #listOfActions.textBoxAskOption.askWithCommand{
    min-width: 207px;
    cursor: default;
  }
  #listOfActions.textBoxAskOption.askWithCommand h5{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #71717A;
    margin: 0 0 8px 0;
    cursor: default;
    text-transform: capitalize;
  }
  #stage-1{
    position: relative;
    // padding: 0 14px 0px 14px;
  }
  #stage-1 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  #stage-1 ul li {
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 4px 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
  }
  #stage-1 ul li:last-child{
    margin-bottom: 14px;
  }
  #stage-1 ul li:hover{
    background: rgba(122, 40, 160, 0.1);
    transition: all 0.5s ease;
  }
  #stage-1 ul li svg{
    margin-right: 4px;
  }
  #stage-2 {
    display: none;
  }
  .spinner {
    background-color: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }
  .textLoader{
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .whatToWriteSection{
    position: relative;
    padding: 0 12px 12px;
  }
  .whatToWriteSection p{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #414E62;
    margin-bottom: 6px;
  }
  .whatToWriteSection input{
    background: #FFFFFF;
    padding: 7px 10px 7px 32px;
    border: 1px solid #D6D6D6;
    border-radius: 7.83px;
    width: 186px;
    transition: all 0.5s ease;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #414E62;
  }
  .whatToWriteSection input:focus{
    transition: all 0.5s ease;
    border: solid 1px #7A28A0;
    box-shadow: none;
    outline: none;
  }
  .whatToWriteSection input:placeholder{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #BAC3CD;
  }
  #stage-2 input{
    background: #FFFFFF;
    padding: 7px 10px 7px 32px;
    border: 1px solid #D6D6D6;
    border-radius: 7.83px;
    width: 100%;
    transition: all 0.5s ease;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #414E62;
  }
  #stage-2 input:focus{
    transition: all 0.5s ease;
    border: solid 1px #7A28A0;
    box-shadow: none;
    outline: none;
  }
  #stage-2 input:placeholder{
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #BAC3CD;
  }
  .whatToWriteSection svg{
    position: absolute;
    left: 26px;
    top: 40px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .prcessedWtihText{
    position: relative;
    padding: 0 12px;
  }
  .prcessedWtihText p{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 16px;
    color: #414E62;
    max-height: 210px;
    overflow-y: auto;
    margin: 0 0 16px 0;
  }
  .prcessedWtihText p::-webkit-scrollbar{
    width: 4px;
  }
  .prcessedWtihText p::-webkit-scrollbar-track{
    background: white;
    border: solid 1px #e4e4e4;
    border-radius: 5px;
  }
  .prcessedWtihText p::-webkit-scrollbar-thumb{
    background: #e4e4e4;
  }
  .searchMoreText{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .searchMoreText .searchMoreInputBox{
    position: relative;
    width: 100%;
  }
  #stage-2 .searchMoreText .searchMoreInputBox input{
    width: calc(100% - 60px);
  }
  .searchMoreText .searchMoreInputBox svg{
    position: absolute;
    left: 12px;
    top: 11px;
  }
  .searchMoreText button{
    padding: 10px 14px;
    background: #7A28A0;
    border: 0;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    cursor: pointer;
  }
  .searchMoreText button:focuse{
    box-shadow: none;
    outline: none;
  }
  .searchMoreText button.disabled{
    background:rgba(122, 40, 160, 0.5);
    cursor: default;
  }
  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .loader div {
    width: 10px;
    height: 10px;
    background-color: rgba(122, 40, 160, 1);
    border-radius: 50%;
    animation: grow-shrink 1s infinite ease-in-out;
  }

  .loader div:nth-child(1) {
    animation-delay: 0s;
  }

  .loader div:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loader div:nth-child(3) {
    animation-delay: 0.4s;
  }

  .command {
    position: absolute;
    background: rgba(122, 40, 160, 1);
    padding: 2px 8px;
    border-radius: 5px;
  }

  .command span {
    color: rgba(255, 255, 255, 1);
  }

  @keyframes grow-shrink {
    0%, 100% {
      transform: scale(0.5);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
  }
  .subMenu{
    position: absolute;
    background: #FFFFFF;
    padding: 0;
    border-radius: 6px;
    left: auto;
    right: -190px;
    bottom: auto;
    top: 40px;
    width: 200px;
    transition: all 0.5s ease;
    border: 1px solid #CED5DE;
    padding: 16px;
    display: none;
    z-index: 9;
    cursor: default;
  }
  .subMenu ul li{
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 4px 12px;
    border-radius: 4px;
    transition: all 0.5s ease;
    color: #111A27 !important;
  }
  .textBoxAskOption ul li:hover .subMenu{
    display: block;
    padding: 8px !important;
    transition: all 0.5s ease;
    box-shadow: rgba(122, 40, 160, 0.1) 0px 4px 10px 0px;
  }
  .textBoxAskOption ul li .subMenu li{
    color: #111A27 !important;
  }
  .textBoxAskOption ul li .subMenu li:hover{
    color: #7A28A0 !important;
  }
  .labsTag{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 8px 0;
  }
  .labsTag h5{
    margin: 0 !important;
  }
  .labsTag span{
    position: relative;
    background-color: #F1305A;
    color: #FFFFFF;
    /* width: 39px; */
    /* height: 13px; */
    border-radius: 2px;
    font-size: 8px;
    font-weight: 700;
    line-height: 10.89px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 1px 4px;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskInlinePopover.prototype, "inputValue", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AskInlinePopover.prototype, "isOpen", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], AskInlinePopover.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskInlinePopover.prototype, "currentSelectedOption", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskInlinePopover.prototype, "activeModule", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], AskInlinePopover.prototype, "promptsConfig", void 0);
exports.AskInlinePopover = AskInlinePopover = __decorate([
    (0, decorators_js_1.customElement)('ask-inline-trinka-popup'),
    __metadata("design:paramtypes", [Object, Object, Object])
], AskInlinePopover);
