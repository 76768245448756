"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logAmplitudeEvent = exports.initAmplitude = void 0;
const amplitude = require("@amplitude/analytics-browser");
let user_id = '';
const initAmplitude = (amplitudeKey = 'd6d0919d33fe750608c491612d7bcd97', localUserId = '') => {
    amplitude.init(amplitudeKey, {
        defaultTracking: {
            sessions: true,
            pageViews: true,
        },
    });
    user_id = localUserId;
};
exports.initAmplitude = initAmplitude;
const logAmplitudeEvent = (eventName, eventObj = {}) => {
    let identify = new amplitude.Identify().setOnce("Domain Name", atob(user_id).split("@")[1]);
    amplitude.identify(identify);
    amplitude.logEvent(eventName, eventObj);
};
exports.logAmplitudeEvent = logAmplitudeEvent;
