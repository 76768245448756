"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DraggablePanel = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
require("./alerts-panel");
require("./sentence-wise-alerts-panel");
require("./track-changes-panel");
require("./no-alerts-panel");
require("./draggable-panel-header");
require("./draggable-panel-footer");
require("./alerts-panel-loader");
const constants_1 = require("../../helpers/constants");
let DraggablePanel = class DraggablePanel extends lit_1.LitElement {
    updated(changedProperties) {
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return (0, lit_1.html) `
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap">
    <div id="draggablePanel" class="${this.config.isCloudTrinka ? 'trinkaCloudEditor' : 'trinkaB2BEditor'}" style="display: ${this.isOpen ? 'block' : 'none'}">
      <draggable-panel-header
      editorId="${this.editorId}"
      .filters="${this.filters}"
      .alertCounter="${this.filteredAlertList.length}"
      .sentenceWiseAlertCounter="${this.sentenceWiseFilteredAlertList.length}"
      .viewMode="${this.viewMode}"
      .isLegal="${this.isLegal}"
      .showDraggablePanelHeader="${this.showDraggablePanelHeader}"
      .config="${this.config}"
      .currentWritingMode="${this.currentWritingMode}"
      .currenDocumentType="${this.currenDocumentType}"
      .showOfflineModel="${this.showOfflineModel}"
      .langCode="${(_a = this.trinkaEditorConfig) === null || _a === void 0 ? void 0 : _a.langCode}"></draggable-panel-header>

      <!--
      <div>
        viewMode: ${this.viewMode}
      </div>

      <div>
        filteredAlertList length: ${this.filteredAlertList.length}
      </div>

      <div>
        sentenceWiseFilteredAlertList length: ${this.sentenceWiseFilteredAlertList.length}
      </div>

      <div>
        sentenceWiseFilteredAlertList check: ${this.viewMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE}
      </div> -->

      ${this.viewMode === constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL
            ? (0, lit_1.html) `
          ${this.filteredAlertList && this.filteredAlertList.length
                ? (0, lit_1.html) `<alerts-panel editorId="${this.editorId}" .filteredAlertList="${this.filteredAlertList}" .trinkaEditorConfig="${this.trinkaEditorConfig}" .isCloudTrinka="${this.config.isCloudTrinka}" .limitExhausted="${this.limitExhausted}" .userUpgradeLink="${(_b = this.cloudConfig) === null || _b === void 0 ? void 0 : _b.upgradeURL}" .globalGroupedObj="${this.globalGroupedObj}"></alerts-panel>`
                : !this.loader ? (0, lit_1.html) `<no-alerts-panel .isEditorEmpty="${this.isEditorEmpty}" .userType="${(_c = this.trinkaEditorConfig) === null || _c === void 0 ? void 0 : _c.userType}" .limitExhausted="${this.limitExhausted}" .userUpgradeLink="${(_d = this.cloudConfig) === null || _d === void 0 ? void 0 : _d.upgradeURL}"></no-alerts-panel>` : (0, lit_1.html) `<alerts-panel-loader .isCloudTrinka="${this.config.isCloudTrinka}"></alerts-panel-loader>`}
        `
            : (0, lit_1.html) ``}

      ${this.viewMode === constants_1.GRAMMAR_VIEW_MODES.SENTENCE_WISE
            ? (0, lit_1.html) `
          ${this.sentenceWiseFilteredAlertList && this.sentenceWiseFilteredAlertList.length
                ? (0, lit_1.html) `<sentence-wise-alerts-panel editorId="${this.editorId}" .sentenceWiseFilteredAlertList="${this.sentenceWiseFilteredAlertList}" .trinkaEditorConfig="${this.trinkaEditorConfig}" .isCloudTrinka="${this.config.isCloudTrinka}" .limitExhausted="${this.limitExhausted}" .userUpgradeLink="${(_e = this.cloudConfig) === null || _e === void 0 ? void 0 : _e.upgradeURL}"></sentence-wise-alerts-panel>`
                : !this.loader ? (0, lit_1.html) `<no-alerts-panel .isEditorEmpty="${this.isEditorEmpty}" .userType="${(_f = this.trinkaEditorConfig) === null || _f === void 0 ? void 0 : _f.userType}" .limitExhausted="${this.limitExhausted}" .userUpgradeLink="${(_g = this.cloudConfig) === null || _g === void 0 ? void 0 : _g.upgradeURL}"></no-alerts-panel>` : (0, lit_1.html) `<alerts-panel-loader .isCloudTrinka="${this.config.isCloudTrinka}"></alerts-panel-loader>`}
        `
            : (0, lit_1.html) ``}

      ${this.viewMode === constants_1.GRAMMAR_VIEW_MODES.TRACK_CHANGES
            ? (0, lit_1.html) `
          <track-changes-panel editorId="${this.editorId}" .trinkaEditorConfig="${this.trinkaEditorConfig}" .isCloudTrinka="${this.config.isCloudTrinka}" .limitExhausted="${this.limitExhausted}" .userUpgradeLink="${(_h = this.cloudConfig) === null || _h === void 0 ? void 0 : _h.upgradeURL}"></track-changes-panel>
        `
            : (0, lit_1.html) ``}

      <draggable-panel-footer
      .limitExhausted="${this.limitExhausted}"
      .loginRequired="${this.loginRequired}"
      .loader="${this.loader}"
      .userType="${(_j = this.trinkaEditorConfig) === null || _j === void 0 ? void 0 : _j.userType}"></draggable-panel-footer>
    </div>
    `;
    }
    constructor(editorId, config) {
        super();
        this.config = {};
        this.isEditorEmpty = false;
        this.alertList = [];
        this.filteredAlertList = [];
        this.sentenceWiseFilteredAlertList = [];
        this.limitExhausted = false;
        this.loginRequired = false;
        this.loader = true;
        this.isOpen = false;
        this.filters = {};
        this.trinkaEditorConfig = {};
        this.isLegal = false;
        this.showDraggablePanelHeader = true;
        this.currentWritingMode = 'Power Mode';
        this.currenDocumentType = 3;
        this.viewMode = constants_1.GRAMMAR_VIEW_MODES.INDIVIDUAL;
        this.showOfflineModel = false;
        this.cloudConfig = undefined;
        this.globalGroupedObj = {};
        this.boundHandleClickEvent = ($event) => this.handleClickEvent($event);
        this.boundHandleMouseDownEvent = ($event) => this.handleMousedownEvent($event, this.draggablePanel);
        this.editorId = editorId;
        this.config = config;
        this.waitTillAlertsPanelLoaded().then((panel) => {
            panel.openFirstAlert();
        });
    }
    waitTillAlertsPanelLoaded() {
        return new Promise((resolve, reject) => {
            let counter = 0;
            const interval = setInterval(() => {
                if (this.alertsPanel && this.alertsPanel.filteredAlertList && this.alertsPanel.filteredAlertList.length) {
                    resolve(this.alertsPanel);
                    clearInterval(interval);
                }
                counter++;
                if (counter > 100) {
                    clearInterval(interval);
                    console.warn('Can not open first alert');
                }
            }, 1000);
        });
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.draggablePanel.removeEventListener('mousedown', this.boundHandleMouseDownEvent);
    }
    firstUpdated() {
        this.draggablePanel = this.shadowRoot.querySelector('#draggablePanel');
        this.draggablePanel.addEventListener('mousedown', this.boundHandleMouseDownEvent);
        this.draggablePanel.addEventListener('click', this.boundHandleClickEvent);
    }
    handleMousedownEvent($event, draggablePanel) {
        if (this.showDraggablePanelHeader) {
            let offsetX = $event.clientX - draggablePanel.getBoundingClientRect().left;
            let offsetY = $event.clientY - draggablePanel.getBoundingClientRect().top;
            const dragMove = ($event) => {
                let x = $event.clientX - offsetX;
                let y = $event.clientY - offsetY;
                const maxX = window.innerWidth - draggablePanel.offsetWidth - 40;
                const maxY = window.innerHeight - draggablePanel.offsetHeight - 20;
                x = Math.max(40, Math.min(x, maxX));
                y = Math.max(20, Math.min(y, maxY));
                draggablePanel.style.left = `${x}px`;
                draggablePanel.style.top = `${y}px`;
            };
            const dragEnd = () => {
                document.removeEventListener('mousemove', dragMove);
                document.removeEventListener('mouseup', dragEnd);
            };
            document.addEventListener('mousemove', dragMove);
            document.addEventListener('mouseup', dragEnd);
        }
    }
    handleClickEvent(event) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (event.target.closest("draggable-panel-header")) {
            return;
        }
        else {
            const filter = (_c = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('draggable-panel-header')) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.filter');
            (_d = filter === null || filter === void 0 ? void 0 : filter.classList) === null || _d === void 0 ? void 0 : _d.remove('active');
            const powerModeDropDown = (_g = (_f = (_e = this.shadowRoot) === null || _e === void 0 ? void 0 : _e.querySelector('draggable-panel-header')) === null || _f === void 0 ? void 0 : _f.shadowRoot) === null || _g === void 0 ? void 0 : _g.querySelector('.powerModeDropDown');
            (_h = powerModeDropDown === null || powerModeDropDown === void 0 ? void 0 : powerModeDropDown.classList) === null || _h === void 0 ? void 0 : _h.add('hide');
            const trackChangesDropDown = (_l = (_k = (_j = this.shadowRoot) === null || _j === void 0 ? void 0 : _j.querySelector('draggable-panel-header')) === null || _k === void 0 ? void 0 : _k.shadowRoot) === null || _l === void 0 ? void 0 : _l.querySelector('.viewSuggestionDorpDown');
            (_m = trackChangesDropDown === null || trackChangesDropDown === void 0 ? void 0 : trackChangesDropDown.classList) === null || _m === void 0 ? void 0 : _m.add('hide');
        }
    }
    close($event) {
        if (this.alertsPanel) {
            this.alertsPanel.closeAllCards();
        }
        this.isOpen = false;
    }
    open() {
        this.isOpen = true;
    }
    scrollToElementById(alertId) {
        if (!this.alertsPanel)
            return;
        this.alertsPanel.scrollToElementById(alertId);
    }
    sentenceWiseScrollToElementById(alertId) {
        if (!this.sentenceWiseAlertsPanel)
            return;
        this.sentenceWiseAlertsPanel.scrollToElementById(alertId);
    }
    closeAllCards() {
        if (!this.alertsPanel)
            return;
        this.alertsPanel.closeAllCards();
    }
    sentenceWiseCloseAllCards() {
        if (!this.sentenceWiseAlertsPanel)
            return;
        this.sentenceWiseAlertsPanel.closeAllCards();
    }
    sentenceWiseToggleCardState(alertId) {
        if (!this.sentenceWiseAlertsPanel)
            return;
        this.sentenceWiseAlertsPanel.toggleCardState(alertId);
    }
    blockOrUnblockSentenceWiseAlertsPanel(state) {
        this.sentenceWiseAlertsPanel.blockOrUnblockSentenceWiseAlertsPanel(state);
    }
    openNextAlert(index) {
        if (!this.alertsPanel)
            return;
        this.alertsPanel.openNextAlert(index);
    }
    sentenceWiseSelectNextAlert(index) {
        if (!this.sentenceWiseAlertsPanel)
            return;
        this.sentenceWiseAlertsPanel.sentenceWiseSelectNextAlert(index);
    }
    openFirstAlert() {
        if (!this.alertsPanel)
            return;
        this.alertsPanel.openFirstAlert();
    }
    openSentenceWiseFirstAlert() {
        if (!this.sentenceWiseAlertsPanel)
            return;
        this.sentenceWiseAlertsPanel.openSentenceWiseFirstAlert();
    }
    setRightPanelForCloud() {
        setTimeout(() => {
            this.draggablePanel.style.right = '0px';
            this.draggablePanel.style.top = '72px';
            this.draggablePanel.style.width = '395px';
        });
    }
    manualRequestUpdate() {
        let header = this.shadowRoot.querySelector('draggable-panel-header');
        if (header) {
            header.manualRequestUpdate();
        }
    }
};
exports.DraggablePanel = DraggablePanel;
DraggablePanel.styles = (0, lit_1.css) `
  #draggablePanel {
    position: fixed;
    top: 45px;
    right: 80px;
    background: #EEEEEE;
    box-shadow: inset 8px -5px 4px -5px rgba(0,0,0,.075);
    border-radius: 3px;
    width: 395px;
    height: calc(100% - 46px);
    pointer-events: all;
    z-index: 12;
    display: none;
    // transform: translate(460px, 0);
    // transition: 0.3s ease-out;
  }
  #draggablePanel.trinkaB2BEditor{
    top: 137px !important;
    right: 0px !important;
    z-index: 10;
    display: none;
  }
  #draggablePanel.trinkaB2BEditor{
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
    height: calc(100% - 46px);
    font-family: 'Open Sans', sans-serif;
  }
  `;
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "isEditorEmpty", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "alertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "filteredAlertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Array }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "sentenceWiseFilteredAlertList", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "limitExhausted", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "loginRequired", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "loader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "isOpen", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "filters", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "trinkaEditorConfig", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "isLegal", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "showDraggablePanelHeader", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "currentWritingMode", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Number }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "currenDocumentType", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "viewMode", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "showOfflineModel", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object }),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "cloudConfig", void 0);
__decorate([
    (0, decorators_js_1.property)(),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "globalGroupedObj", void 0);
__decorate([
    (0, decorators_js_1.query)('alerts-panel'),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "alertsPanel", void 0);
__decorate([
    (0, decorators_js_1.query)('sentence-wise-alerts-panel'),
    __metadata("design:type", Object)
], DraggablePanel.prototype, "sentenceWiseAlertsPanel", void 0);
exports.DraggablePanel = DraggablePanel = __decorate([
    (0, decorators_js_1.customElement)('trinka-popup'),
    __metadata("design:paramtypes", [Object, Object])
], DraggablePanel);
